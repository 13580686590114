import { Badge, Tooltip, Typography } from "antd";
import { CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import cx from "classnames";
import "../AppHeader.scss";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const status = ({
  isOutboxEmpty,
  isSyncingData,
  imagesToUploadCount,
  imagesToDownloadCount,
  outboxData,
  isOnline,
}) => {
  const statusIndicator = (type, length) => (
    <Badge count={length}>
      <Tooltip title={type} placement="top">
        <div className="status-indicator"></div>
      </Tooltip>
    </Badge>
  );

  if (!isOnline && !isSyncingData) {
    return statusIndicator("Offline", outboxData.length);
  }

  if (
    !isOutboxEmpty ||
    isSyncingData ||
    imagesToUploadCount ||
    imagesToDownloadCount ||
    outboxData
  ) {
    let labelText;

    if (imagesToUploadCount) {
      labelText = `${imagesToUploadCount} image${
        imagesToUploadCount === 1 ? "" : "s"
      }`;
    }

    if (imagesToDownloadCount) {
      labelText = `${imagesToDownloadCount} image${
        imagesToDownloadCount === 1 ? "" : "s"
      }`;
    }
    if (outboxData.length > 0) {
      labelText = `${outboxData.length} item${outboxData.length === 1 ? "" : "s"}`;
    }

    return (
      <>
        {statusIndicator("Live", outboxData.length)}
        {((!isOutboxEmpty || imagesToUploadCount > 0) && (
          <CloudUploadOutlined className="cloudPulse" />
        )) ||
          ((isSyncingData || imagesToDownloadCount > 0) && (
            <CloudDownloadOutlined className="cloudPulse" />
          ))}
        <Typography.Text className="label">{labelText}</Typography.Text>
      </>
    );
  }

  return statusIndicator("Live", outboxData.length);
};

export default function OnlineStatusIndicator() {
  const {
    syncStatusHook: {
      isOutboxEmpty,
      isSyncingData,
      imagesToUploadCount,
      imagesToDownloadCount,
      outboxData,
      isOnline
    }
  } = useEntityData()

  return (
    <div
      className={cx("online-status", {
        online: isOnline || isSyncingData,
        offline: !isOnline && !isSyncingData,
      })}
    >
      {status({
        isOutboxEmpty,
        isSyncingData,
        imagesToUploadCount,
        imagesToDownloadCount,
        outboxData,
        isOnline,
      })}
    </div>
  );
}
