import { useEntityData } from "EntityDataContext/EntityDataContext";

export default function Authorize({ children, allowedRoles }) {
  const { userData } = useEntityData()

  allowedRoles = allowedRoles.map((role) => role.toLowerCase());
  let isAllowed = false;

  userData.roles?.forEach((userRole) => {
    if (allowedRoles.includes(userRole.toLowerCase())) {
      isAllowed = true;
    }
  });
  return isAllowed ? children : null;
}
