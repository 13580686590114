const entityObject = (entities, counts) => {
  return Object.keys(entities).reduce((acc, e) => {
    acc[e] = counts[e]
    return acc
  }, {})
}

export const facilityFloorAndSpaceCount = ({
  floors = [], 
  spaces,
  entities = {},
}) => {
  const counts = {floors: floors.length, spaces: 0}

  if (!entities.spaces) return {floors: counts.floors}

  for (let i = 0; i < floors.length; i++) {
    counts.spaces += spaces.groupByFloor[floors[i]]?.length || 0
  }

  return entityObject(entities, counts)
}

export const facilityAssetCount = (facilityTree = {}) => {
  let count = 0
  const floorSpacesAndAssets = Object.values(facilityTree)

  floorSpacesAndAssets.forEach((val) => {
    const spacesAndAssets = Object.values(val)
    
    spacesAndAssets.forEach(v => {
      count += v.length
    }) 
  })

  return count
}

export const floorAssetCount = (floorTree = {}) => {
  const spacesAndAssets = Object.values(floorTree)

  let count = 0
  
  spacesAndAssets.forEach(v => {
    count += v.length
  }) 

  return count
}

export const floorAssetIds = (floorTree = {}) => {
  const spacesAndAssets = Object.entries(floorTree)

  let result = []

  spacesAndAssets.forEach(([spaceId, spAndAssets]) => {
      // if (spaceId === "5492a5c6-2ea4-4766-9873-618821056768") {
        result = result.concat(spAndAssets)
      // }
    }
  )

  return result
}

export const facilityFabricAssets = (assets, facilityId) => {
  const fAssets = []

  const spacesAndAssets = Object.values(assets?.assetTree?.[facilityId]?.nothing || {})
  
  spacesAndAssets.forEach(assetArray => {
    assetArray.forEach(assetId => {
      fAssets.push(assets.items[assetId])
    })
  }) 

  return fAssets
}

export const floorFabricAssets = (assets, floor) => {
  if (!floor) return []

  const fAssets = []

  assets?.assetTree?.[floor.facilityId]?.[floor.id]?.nothing
  ?.forEach((assetId) => {
    fAssets.push(assets.items[assetId])
  })
  
  return fAssets
}

export const spaceAssets = ({assets, space}) => (
    assets.assetTree?.[space.facilityId]?.[space.floorId]?.[space.id] || []
)
