import { useEffect, useState } from "react";
import { Tooltip, Switch } from "antd";
import { set as localDbSet, get as localDbGet } from "idb-keyval";
import { deviceImages, HANDLE_REMOVE_FACILITY } from "helpers/deviceImages";
import { MAX_ACTIVE_FACILITIES } from "config";
import { fetchHierarchyManifest, hierarchyExists, updateHierarchy } from "helpers/hierarchyHelpers";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const handleHierarchyClick = async (tenantId) => {
  const exists = await hierarchyExists(tenantId)
  if (!exists) {
    await updateHierarchy(tenantId)
    return;
  }
  const manifest = await fetchHierarchyManifest()
  const lastDownloadedDate = new Date(manifest.lastDownloaded)
  if (new Date() - lastDownloadedDate > 5*60*1000)  {
    await updateHierarchy(tenantId)
  }
}

const FacilitySwitcher = (props) => {
  const {
    setFacilityCountWarning,
    facility,
  } = props;
  const {
    syncStatusHook: {
      isOnline,
      isSyncingData,
      isOutboxEmpty,
    },
    setFacilitiesToDownload, 
    facilitiesToDownload
  } = useEntityData()
  
  const atFacilityLimit = facilitiesToDownload.length >= MAX_ACTIVE_FACILITIES

  let notEnabled = !facilitiesToDownload?.includes(facility.id)

  const disabled = !isOnline ||
    (!isOutboxEmpty && facilitiesToDownload?.includes(facility.id)) ||
    (atFacilityLimit && notEnabled)
  
  const [facilityEnabled, setFacilityEnabled] = useState();

  useEffect(() => {
    if (!isSyncingData) {
      setFacilityEnabled(null);
    }
  }, [isSyncingData]);


  return (
    <Tooltip
      key="facility"
      title={
        isSyncingData && facilityEnabled === facility.id
          ? "This Facility is active and on your device and all facility data has been downloaded but can not be inactivated until all your data has synced"
          : !notEnabled
          ? "This Facility is active and on your device and all facility data has been downloaded"
          : atFacilityLimit 
          ? isOnline ? `Active facility limit of ${MAX_ACTIVE_FACILITIES} reached, please de-activate an active facility to select another`
          :  `Active facility limit of ${MAX_ACTIVE_FACILITIES} reached, please sync data and de-activate an active facility to select another`
          : "This Facility is not active and no facility data is available on your device"
      }
    >
      <Switch
        className={disabled ? "switch-disabled" : ""}
        loading={isSyncingData && facilityEnabled === facility.id}
        checked={
          facilityEnabled == null && facilitiesToDownload?.includes(facility.id)
        }
        data-test-selector="facility-switch"
        onClick={(_, e) => {
          e.stopPropagation();
          if (disabled) return

          setFacilityEnabled(facility.id);
          handleHierarchyClick(window.tenantId)
        }}
        onChange={async (checked) => {
          if (disabled) {
              if (checked && atFacilityLimit) {
                setFacilityCountWarning()
              }
              
              return
          }

          let facilitiesToDownloadFromDb =
            (await localDbGet("facilitiesToDownload")) || {};
          let mustDeleteImages = false;
          let newFacilitiesToDownload = facilitiesToDownloadFromDb;

          const facilitiesToDownloadFromDbOldFormat = Array.isArray(
            facilitiesToDownloadFromDb
          );

          const tenantItems = facilitiesToDownloadFromDbOldFormat
            ? facilitiesToDownloadFromDb
            : facilitiesToDownloadFromDb[window.tenantId] || [];

          let facilityIds = facilitiesToDownloadFromDbOldFormat
            ? facilitiesToDownloadFromDb
            : tenantItems.map((i) => i.facilityId);

          if (checked) {
            deviceImages.enableFacility(facility.id)

            if (facilityIds.includes(facility.id)) return

            if (facilitiesToDownloadFromDbOldFormat) {
              let prevActiveFacilities = facilitiesToDownloadFromDb.map((x) => {
                return {
                  regionId: undefined,
                  siteId: undefined,
                  facilityId: x,
                };
              });
              newFacilitiesToDownload = [
                { [window.tenantId]: prevActiveFacilities },
              ];
            } else {
              facilityIds.push(facility.id);
              tenantItems.push({
                regionId: facility.regionId,
                siteId: facility.siteId,
                facilityId: facility.id,
              });
              newFacilitiesToDownload[window.tenantId] = tenantItems;
            }
          } else {
            deviceImages.disableFacility(facility.id)

            const unassignFacility = tenantItems.filter(
              (x) => x.facilityId !== facility.id
            );
            facilityIds = facilityIds.filter((f) => f !== facility.id);
            newFacilitiesToDownload[window.tenantId] = unassignFacility;
            mustDeleteImages = true;
          }
          localDbSet("facilitiesToDownload", newFacilitiesToDownload);
          setFacilitiesToDownload(facilityIds);
          window.targetFacilities = facilityIds;
          if (mustDeleteImages) {
            deviceImages.addToQueue(HANDLE_REMOVE_FACILITY, facility.id)
          }
        }}
      />
    </Tooltip>
  );
};

export default FacilitySwitcher;
