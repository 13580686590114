import React from "react";
import { Link, useNavigate } from "react-router-dom";
import cx from "classnames";
import { HomeIcon } from "components/Icons";

import "./Breadcrumbs.scss";
import { Typography, Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useEntityData } from "EntityDataContext/EntityDataContext";

export default function Breadcrumbs({ breadcrumbs }) {
  const { copyAssets } = useEntityData()

  const navigate = useNavigate();

  const [listCopy, setListCopy] = copyAssets

  async function confirmLeavePage(e, URL) {
    e.stopPropagation();
    Modal.confirm({
      title: "Copy Assets is not complete. Navigating away from this space will clear the remaining copy assets.",
      icon: <QuestionCircleOutlined />,
      okText: "Clear Assets",
      cancelText: "Cancel",
      onOk: async () => {
        setListCopy([])
        navigate(URL)
      },
    });
  }

  return (
    <div className="breadcrumbs">
      <div className="breadcrumb-item home-item" 
            onClick={(e) => {
              if (listCopy.length > 0) confirmLeavePage(e, '/')
              if (listCopy.length === 0) navigate('/')
            }}>
        <div className="home-icon">
          <HomeIcon />
        </div>
        <Typography.Text className="breadcrumb-label">Home</Typography.Text>
      </div>
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.url}>
          <div className="breadcrumb-separator">/</div>
          <div
            className={cx("breadcrumb-item", {
              "last-item": index === breadcrumbs.length - 1,
            })}
            onClick={(e) => {
              if (listCopy.length > 0) confirmLeavePage(e, breadcrumb.url)
              if (listCopy.length === 0) navigate(breadcrumb.url)
            }}
          >
            <Typography.Text className="breadcrumb-label">
              {breadcrumb.label}
            </Typography.Text>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
