import React from 'react'
import classNames from 'classnames'
import { Menu } from 'antd'
import noop from 'lodash/noop'
import { Tooltip, Dropdown } from 'antd'
import Cookie from 'js-cookie'

import { Analytics, ConditionSurvey, Energy, GridDots, InfoCircle, Management, Phone, UAVDrone } from 'components/Icons'

import MenuItemWithClickableComponent from './MenuItemWithClickableComponent'

import { ROLES, hasRole } from './helpers/roles'
import { ENV_MAPPING, APP_ENV } from 'config'
import './AppSwitcher.scss'
import { useEntityData } from 'EntityDataContext/EntityDataContext'

export const ApplicationSwitcher = (props) => {
  const { className, popupContainer } = props
  
  const { userData } = useEntityData()

  const FEATURE_FLAG_MOBILE_LITE = window.FEATURE_FLAG_MOBILE_LITE || false
  if (FEATURE_FLAG_MOBILE_LITE) console.log('FEATURE_FLAG_MOBILE_LITE', FEATURE_FLAG_MOBILE_LITE)

  const applicationSwitcherListItems = () => {
    const { roles, adminModuleAccess } = userData

    const pageName = window.location.host
    const isLocalhost = pageName === 'localhost'
    const hasPort = pageName.includes(':3000') || pageName.includes(':8000')

    const getAppModuleLink = (route) => {
      let targetURL = `http://${window.location.hostname}:8000`

      if (!isLocalhost && !hasPort) {
        targetURL = Cookie.get('main-app-url')
      }

      return targetURL += route
    }

    const getEnvModuleLink = (env) => {
      if (!isLocalhost && !hasPort) return ENV_MAPPING[env]

      return ENV_MAPPING["devone"]
    }

    const getTest1Link = () => {
      const queryStringData = Cookie.get("queryStringData");
      let targetURL = `https://test1.eliasam.com/?data=${queryStringData}`

      return targetURL
    }

    const test1UserIds = [
      'adam.stickland@icloud.com', // adam stickland
      'pete_vears@yahoo.co.uk', //pete vears
      'terryhunt69@hotmail.co.uk', // terry hunt
      'alastair.reid@realestateams.com', // alastair reid
      'gate@realestateams.com', // admin live
      'admin@realestateams.com' // admin dev
    ]

    const switcherListItems = [
      {
        key: 2,
        title: 'Analytics',
        icon: 'analytics',
        tooltipText: 'ELIAS reporting, analytics, Cap Ex and PPM planning tools',
        isEnabled:
          (hasRole(ROLES.CUSTOMER, roles) || hasRole(ROLES.SUPPLIER, roles) || hasRole(ROLES.ADMIN, roles)),
        clickAction: () =>
          (hasRole(ROLES.CUSTOMER, roles) || hasRole(ROLES.SUPPLIER, roles) || hasRole(ROLES.ADMIN, roles))
            ? window.location.href = getAppModuleLink('/home')
            : noop,
      },
      {
        key: 0,
        title: 'Inspections',
        icon: 'conditionSurvey',
        tooltipText: 'ELIAS surveyor tools to enable Condition and Asset Verification surveys',
        isEnabled: hasRole(ROLES.SUPPLIER, roles) || hasRole(ROLES.SURVEYOR, roles) || hasRole(ROLES.FABRIC, roles) || hasRole(ROLES.ADMIN, roles),
      },
      {
        key: 1,
        title: 'Mobile lite',
        icon: 'phone',
        tooltipText: 'Mobile lite estate asset management.',
        isEnabled: FEATURE_FLAG_MOBILE_LITE &&
          (hasRole(ROLES.MOBILE_LITE, roles) || hasRole(ROLES.SUPPLIER, roles) || hasRole(ROLES.ADMIN, roles)),
        externalLinkTo: getEnvModuleLink(APP_ENV)
      },
      {
        key: 5,
        title: 'UAV surveys',
        icon: 'uavDrone',
        tooltipText: 'UAV and Drone Survey visualisation and inspection',
        isEnabled: true,
        externalLinkTo: 'https://uav.eliasam.com/',
      },
      {
        key: 4,
        title: 'Knowledge Centre',
        icon: 'infoCircle',
        tooltipText: `${`Training materials, help guides, FAQ's and how to's.`}`,
        isEnabled: true,
        externalLinkTo: 'https://help.eliasam.com/',
      },
      {
        key: 3,
        title: 'Syrius',
        icon: 'energy',
        tooltipText: `IoT, Energy & Predictive Analytics (coming soon)`,
        isEnabled: false,
      },
    ]

    const adminListItem = {
      key: 6,
      title: 'Administration',
      icon: 'management',
      tooltipText: 'ELIAS account and project management',
      isEnabled:
        hasRole(ROLES.ADMIN, roles) ||
        (hasRole(ROLES.SUPPLIER, roles) && adminModuleAccess) ||
        hasRole(ROLES.PROJECT_MANAGER, roles),
      clickAction: () =>
        hasRole(ROLES.ADMIN, roles) ||
          (hasRole(ROLES.SUPPLIER, roles) && adminModuleAccess) ||
          hasRole(ROLES.PROJECT_MANAGER, roles)
          ? window.location.href = getAppModuleLink('/admin/home')
          : noop,
    }

    if (hasRole(ROLES.ADMIN, roles) || hasRole(ROLES.SUPPLIER, roles) || hasRole(ROLES.PROJECT_MANAGER, roles)) {
      switcherListItems.push(adminListItem)
    }

    if (test1UserIds.includes(userData.id)) {
      switcherListItems.push(
        {
          key: -1,
          title: 'Inspections UAT',
          icon: 'conditionSurvey',
          tooltipText: 'Inspections UAT Environment',
          isEnabled: hasRole(ROLES.SUPPLIER, roles) || hasRole(ROLES.SURVEYOR, roles) || hasRole(ROLES.FABRIC, roles) || hasRole(ROLES.ADMIN, roles),
          clickAction: () => window.location.href = getTest1Link()
        })
    }

    return switcherListItems.sort((a, b) => a.key - b.key)
  }

  const getMenuItemIcon = (listItem) => {
    const iconClass = classNames(
      'applicationSwitcherListItemIcon',
      !listItem.isEnabled && 'disabledApplicationSwitcherListItemIcon',
    )

    const MENU_ICONS = {
      analytics: <Analytics className={iconClass} />,
      conditionSurvey: <ConditionSurvey className={iconClass} />,
      energy: <Energy className={iconClass} />,
      infoCircle: <InfoCircle className={iconClass} />,
      management: <Management className={iconClass} />,
      phone: <Phone className={iconClass} />,
      uavDrone: <UAVDrone className={iconClass} />,
    }

    return MENU_ICONS[listItem.icon]
  }

  const DROPDOWN_HOVER_TYPE = {
    BACKGROUND: 'background',
    TEXT: 'text',
  }

  const menuItemBody = (listItem) => (
    <div
      className={classNames(
        'applicationSwitcherListItemWrapper',
        !listItem.isEnabled && 'disabledApplicationSwitcherListItem',
      )}
      role='button'
      tabIndex='0'
      onClick={listItem.clickAction}
      onKeyDown={listItem.clickAction}
    >
      <div className='applicationSwitcherListItemLabel'>
        {getMenuItemIcon(listItem)}
        <span>{listItem.title}</span>
      </div>
    </div>
  )

  const renderListItemDependOfType = (listItem) => {
    if (listItem.externalLinkTo) {
      return (
        <a href={listItem.externalLinkTo} target='_blank' rel='noopener noreferrer'>
          {menuItemBody(listItem)}
        </a>
      )
    }
    return menuItemBody(listItem)
  }

  const menu = (
    <>
      <div className='applicationSwitcherMenuTitle'>
        <span>Switch to</span>
      </div>
      <Menu className='applicationSwitcherListItemsMenu' selectable={false}>
        <>
          {applicationSwitcherListItems().map((listItem) => (
            <MenuItemWithClickableComponent
              key={listItem.key}
              menuItemClassName='applicationSwitcherListItem'
            >
              <Tooltip
                overlayStyle={{ zIndex: '999999' }}
                placement='right'
                title={listItem.tooltipText}
              >
                {renderListItemDependOfType(listItem)}
              </Tooltip>
            </MenuItemWithClickableComponent>
          ))}
        </>
      </Menu>
    </>
  )

  return (
    <Dropdown
      placement='bottomLeft'
      overlay={menu}
      overlayClassName={classNames(
        'applicationSwitcherFixedDropdown',
        'dropdown',
        [`hover-type-${DROPDOWN_HOVER_TYPE.BACKGROUND || DROPDOWN_HOVER_TYPE.TEXT}`],
      )}
      trigger={['click']}
      getPopupContainer={popupContainer ? () => popupContainer : undefined}
    >
      <div className={classNames('applicationSwitcherDropdown', className)}>
        <GridDots alt='Switch tenant' className='applicationSwitcherIcon' />
      </div>
    </Dropdown>
  )
}

export default ApplicationSwitcher
