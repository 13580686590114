import { Drawer, Button, Checkbox, Row, Col, Divider } from "antd";
import { useState } from "react";
import { Typography } from "antd";

const CopySpaceDrawer = ({ onClose, open, assets = [] }) => {
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const plainOptions = assets.map((a) => ({
    value: a.id,
    label: a.assetType.description,
    description: a.notes?.description,
  }));

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions.map((v) => v.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onCloseDrawer = (asset = undefined) => {
    setCheckedList([]);
    setIndeterminate(false);
    setCheckAll(false);
    if (asset) onClose(asset);
    if (!asset) onClose();
  };
  return (
    <Drawer
      title="Assets to be copied"
      placement="right"
      onClose={() => {
        onCloseDrawer();
      }}
      visible={assets.length > 0 && open}
      className="asset-type-drawer"
      closable={false}
      destroyOnClose
    >
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        Copy All Assets
      </Checkbox>
      <Divider />
      <Checkbox.Group value={checkedList} onChange={onChange}>
        <Row>
          {plainOptions.map((o) => (
            <Col span={24} key={o.value}>
              <Checkbox value={o.value} style={{ width: "100%" }}>
                {o.label}{" "}
                {o.description && (
                  <Typography.Text
                    style={{ maxWidth: 300 }}
                    ellipsis={{
                      tooltip: true,
                    }}
                  >
                    {o.description ? ` - ${o.description}` : null}
                  </Typography.Text>
                )}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Divider />
      <div className="form-buttons">
        <Button
          type="transparent"
          onClick={() => {
            onCloseDrawer();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onCloseDrawer(checkedList);
          }}
        >
          Copy
        </Button>
      </div>
    </Drawer>
  );
};

export default CopySpaceDrawer;
