export function pruneChildren(parents) {
    parents = parents
      .filter((parent) => parent.found)
      .map((parent) => {
        if (parent.children) {
          parent.children = pruneChildren(parent.children);
        }
        return parent;
      });

    return parents;
  }