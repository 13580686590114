import { Col } from "antd";
import {
  EllipsisText,
  LocationResult,
  itemDivider,
} from "components/AppHeader/GlobalSearch/globalSearchHelpers";
import { floorTypesDictionary } from "helpers/floorTypes";

const FloorResults = ({ item }) => (
  <>
    <Col span={24}>
      <EllipsisText
        text={itemDivider(item?.name, floorTypesDictionary[item?.floorType])}
      />
    </Col>
    <LocationResult item={item} />
  </>
);

export default FloorResults;
