const manufacturerList = [
  "--",
  "---",
  "----",
  "-----",
  "()",
  "(KONE) CURRENT OWNERSHIP",
  "-(obscured)",
  "(PUMPS) PEDROLLO",
  ",KJBGFC",
  ".",
  "?",
  "??",
  "@JHUA",
  "0",
  "0berg",
  "10 TON CRANE (THE FLYER)",
  "11111",
  "12 inch",
  "12 Rung",
  "123",
  "123 Manu",
  "1234",
  "124",
  "170LT",
  "1DELFIELD",
  "1ST CHOICE",
  "1ST CITY",
  "1st security shutters",
  "1ST4THURST",
  "2 VV",
  '2"A"s LTD',
  "2000LT",
  "2000LT LM3",
  "2000LT LM4",
  "2009",
  "21 DEGREES LTD",
  "21*",
  "2100LP",
  "2100TP",
  "222",
  "2222222222",
  "23",
  "2342",
  "234234",
  "234234234",
  "2468",
  "28w 2d",
  "2As Ltd",
  "2A's LTD.",
  "2N",
  "2VV",
  "3",
  "3 COM",
  "3 M",
  "3 way Electrical Services",
  "333",
  "33333333",
  "360 VISION",
  "3D",
  "3DX-Ray",
  "3G CONTROLS LTD",
  "3GS",
  "3L ELECTRICAL",
  "3M",
  "3M WATER FILTRATION",
  "3-MOTOR",
  "4",
  "4 inch",
  "4 TEC",
  "4 TEC SECUITY LTD",
  "4000",
  "4312",
  "444444",
  "460 VISION",
  "8 Section",
  "9?Itergra",
  "931",
  "9b389",
  "A",
  "A & E",
  "A & E fire equipment Ltd",
  "A & J GUMMERS LTD",
  "A + CLASS",
  "a and a electrical",
  "A Belco",
  "A Box",
  "A Box D+H",
  "A C PLASTICS",
  "A C Tech",
  "A Cambbell",
  "A Campbell",
  "A Campbell ?",
  "A D SMITH",
  "A F CONTROLS",
  "A F SWITCHGEAR AND CONTROL PANELS LTD",
  "A Hanson",
  "A J METAL",
  "A J METAL PRODUCTS",
  "A J METAL PRODUCTS LTD",
  "A J Power",
  "A LIFELINE",
  "A M",
  "A MILES SHUTTERS",
  "A O Smith",
  "A&A",
  "A&A ELECTRICAL  DIST. LTD",
  "A&A ELECTRICAL DIST. LTD",
  "A&A Sprinklers",
  "A&E FIRE EQUIPMENT LTD",
  "A&J Gummers Ltf",
  "A&M Security",
  "A. C. Automation",
  "A. C. PLASTIC INDUSTRIES LTD.",
  "A. F. SWITCHGEAR",
  "A. O. SMITH",
  "A.B. Svensk Varmaematning",
  "A.C. PLASTIC INDUSTRIES LTD",
  "A.C. Plastics",
  "A.CAMPBELL CONTROL SERVICES",
  "A.D.C",
  "A.D.C. FANS LTD",
  "A.D.T",
  "A.F. Switchgear",
  "A.F.SWITCHGEAR",
  "A.F.TECHNICAL",
  "A.FOX INDUSTRIAL DOORS",
  "A.J. Metal Products Ltd",
  "A.J.METAL PRODUCTS",
  "A.L.P. ELECTRICAL (MAIDENHEAD) LTD.",
  "A.M.",
  "A.O SMITH",
  "A.O SMITHKLINE",
  "A.O. Smith",
  "A.O.SMITH",
  "A.O.SMITH WATER PRODUCTS COMPANY",
  "A.S.C. LTD",
  "A/S OSO",
  "A/S OSO (NORWAY)",
  "A1",
  "A1 BRIDGE FLUE SYSTEMS",
  "A1 BRIDGE FLUES",
  "A1 FLUE SYSTEMS",
  "a1 refridgeration",
  "A1 REFRIGERATION AND AIRCONDITIONING",
  "A1 Shutters",
  "A1A",
  "A1S",
  "A2A",
  "A6",
  "AA DIRECT",
  "AA FIRST",
  "AA First Water fountain",
  "AA First",
  "AA1000",
  "AA1100",
  "AA3300S",
  "AA3300X",
  "AA4400X",
  "AAA",
  "AAB",
  "Aabel",
  "AABLE",
  "AABLE FORTRESS",
  "Aable fortress soor systems",
  "AAC",
  "AADGARD A/S",
  "AAF",
  "aaf easdale",
  "AAF EASDALE AIR HANDLING SYSTEM",
  "AAF EASDALE AIR HANDLING SYSTEMS",
  "AAF EASEDALE AIR HANDLING SYSTEM",
  "AAF LTD - EASDALE II",
  "AAGARD A/S",
  "AAI Security Systems Ltd.",
  "AAichi Tokei",
  "AAICON",
  "AAON",
  "Aardee",
  "AAW Control Systems",
  "A-B",
  "AB ARBOGA MASKINER",
  "AB Electrics",
  "AB ORWAK",
  "AB POWER SYSTEM",
  "AB POWER SYSTEMS",
  "ABAC",
  "ABAC Air Compressors",
  "Abacus",
  "ABACUS SHUTTERS LTD",
  "ABAD",
  "Abarron",
  "ABB",
  "ABB / Mallard",
  "ABB CONTROL LTD",
  "ABB KENT TAYLOR",
  "ABB KENT-TAYLOR",
  "ABB Mallard",
  "ABB METERS",
  "ABB MOTORS",
  "ABB OY",
  "ABB PROTECTA COMPACT",
  "ABB SIEMENS",
  "ABB STEWARD LTD.",
  "abb unikit",
  "ABB VENTILATION PRODUCTS",
  "ABB WILLIAMS STEWARD",
  "ABBEY",
  "ABBEY HEAT TRANSFER LTD",
  "ABBEY LANGHAM",
  "ABBOT",
  "Abbot & Co",
  "ABBOT AND Co (NEWARK LTD)",
  "ABBOT AND CO NEWARK LTD",
  "Abbot & Co Air Tank",
  "ABBOTT",
  "Abbott & Co",
  "ABBOTT & CO.",
  "ABBOTT & CO. (NEWARK) LTD",
  "ABBOTT & CO. LTD",
  "ABBOTT AND CO",
  "ABB-SAPPHIRE CONTROLS LTD.",
  "ABB-UNIGEAR",
  "ABC",
  "ABC INDUSTRIAL DOORS",
  "abc industrial doors ltd",
  "ABC LIFTING",
  "ABC LIFTING ENGINEERS LTD",
  "abcindustrial doors",
  "ABEGG",
  "Abel",
  "ABEL ALARMS",
  "Abel Group",
  "ABEL JMD DORMA",
  "A-BELCO",
  "Ability",
  "Ability Projects",
  "ABK",
  "abk stratos",
  "abk stratos ventilation",
  "ABL.SURSUM",
  "Ablaze",
  "Ablaze BUILDING SOLUTIONS",
  "Ablaze BUILDING SOLUTIONS LTD",
  "Able",
  "ABLE ROLLER SHUTTERS",
  "ABLEAIRE",
  "Abloy",
  "ABLOY SECURITY GROUP",
  "ABM",
  "ABN",
  "Abode",
  "ABOVE CEILING TILE",
  "ABOVE OVEN",
  "ABR SURSUM",
  "Abru",
  "ABRU PROMASTER",
  "ABS",
  "ABS PUMPS",
  "ABS PUMPS LTD.",
  "ABS WATER HEATERS",
  "ABSCO",
  "ABSI",
  "Absolute",
  "ABSOLUTE SECURITY",
  "Absorber",
  "ABUS",
  "ABUS 20 TON",
  "ABX",
  "ABX STRATOS VENTILATION",
  "AC & V LTD.",
  "AC 12M",
  "AC CONTROLS LTD",
  "AC CONTROLS LTD.",
  "AC ELECTRICAL",
  "AC Hydraulic",
  "AC Hydraulic AS",
  "AC PLASTIC",
  "AC PLASTIC IND LTD",
  "AC PLASTIC INDUSTIRES LTD",
  "AC PLASTIC INDUSTRIES",
  "AC PLASTIC INDUSTRIES LTD",
  "AC Plastic Industries LtdJ",
  "AC PLASTICS INDUSTRIAL LT.",
  "AC Plastics Industries",
  "AC PLASTICS INDUSTRIES LTD",
  "AC PLASTICS INDUSTRY LTD.",
  "AC PRICE ENGINEERING",
  "AC SHOPFRONTS",
  "ac watthour",
  "AC&V LTD",
  "AC/DC LIGHTING LTD",
  "ACALA DE HENARES",
  "Acaris",
  "ACC COMPRESSORS",
  "ACCEL LIFTS LTD",
  "Accelerated compost ltd",
  "Accent",
  "Accenta",
  "ACCENTS",
  "ACCESS",
  "ACCESS 8800",
  "ACCESS AUTOMATION",
  "ACCESS CONTROL",
  "ACCESS CONTROL SERVICES",
  "ACCESS CONTROL SERVICES LTD",
  "Access Door Systems ltd",
  "ACCESS ESCAPE",
  "ACCESS FOR ALL",
  "ACCESS ID",
  "Access Premier",
  "ACCESS PROTECTION",
  "ACCESS PROTECTION LTD",
  "ACCESS SOLUTIONS NORTHERN LTD",
  "ACCESSPROTECTION LTD",
  "Acclaim",
  "ACCONTROLS LTD",
  "Accord",
  "ACCORD LIFT SERVICES LTD",
  "ACCORD LIFT SERVICES LTD.",
  "Acctiva",
  "Acctive",
  "ACD FANS",
  "ACE",
  "Ace Bueller",
  "Ace Control Systems",
  "Ace Equip",
  "ACE LIFTS",
  "ACE LTD",
  "ACE MIDLANDS LTD",
  "Ace Resteraunt Equipment",
  "ACE SHUTTER",
  "ACE SYTEMS LTD",
  "Acel",
  "ACEM",
  "Acer",
  "ACES",
  "ACET UK",
  "ACETI",
  "ACI PROJECTS",
  "ACI PROJECTS LTD",
  "ACIME",
  "ACIS",
  "ACK",
  "Ackerman",
  "ACL",
  "ACL DRAYTON",
  "ACL Electrical",
  "ACL Electrical Ltd",
  "Acl Lifestyle",
  "ACM ALLEN MARTIN",
  "AC-MAGNETIC",
  "ACME",
  "ACME CONTROLS",
  "Acme door co",
  "ACME TECHNICAL SERVICES LTD.",
  "ACMEX",
  "Acmex Doors Ltd",
  "Aco",
  "Acon",
  "ACORN",
  "Acorn Fire and Security",
  "ACORN POWELL",
  "Acorn Powell ltd",
  "acoustic solutions",
  "Acova",
  "ACOVEN",
  "ACP",
  "ACP AIRCONDITIONING LTD",
  "ACP AURCONDITIONING LTD",
  "ACP WATER TREATMENT",
  "Acqua",
  "ACRASTYLE",
  "ACRASTYLE LTD",
  "ACROKOOL",
  "Acrol",
  "Acrow",
  "ACS",
  "ACS LIMITED",
  "ACS Ltd",
  "Acson",
  "ACT",
  "ACT PRO 120",
  "ACT PRO 200",
  "ACT PRO 2000",
  "ACT. Access",
  "Actais",
  "ACTANTS",
  "Actar",
  "ACTARIA",
  "ACTARIS",
  "Actarius",
  "ACTARTS",
  "ACTARUS",
  "ACTEON",
  "AC-TIMELOCK",
  "Action Air",
  "ACTION AIR EQUIPMENT LTD",
  "ACTION AIR LTD",
  "ACTION AUR",
  "ACTION DOOR SERVICES LTD",
  "ACTION PUMPS",
  "ACTION PUMPS LTD",
  "ACTION SHUTTER LTD",
  "ACTION SHUTTERS",
  "Action Shutters Ltd",
  "Action Air",
  "Actionair",
  "ACTIONAIR EQ LTD",
  "ACTIONAIRE",
  "Active Alarm",
  "Active energy",
  "Active energy solutions",
  "ACTIVE POWER",
  "ACTIVE SECURITY GROUP",
  "ACTON SHUTTERS LTD",
  "ACTPRO 1200",
  "ACTRONIC",
  "ACTSRIS",
  "ACTVARIS",
  "ACU",
  "acuasystem",
  "ACV",
  "ACV Bovey Tracey",
  "ACV International",
  "ACV Ltd",
  "acxon",
  "AD",
  "AD SPRINKLER PROTECTION LTD.",
  "AD SWITCHGEAR & CONTROL PANELS LIMITED.",
  "ADA HYDRAULIC",
  "ADAIR BROS KTD",
  "ADAM",
  "ADAM KUHLERFABRIK",
  "ADAMG RITE",
  "Adams Hydraulic",
  "Adams Hydraulics",
  "Adams Rite",
  "Adamslite",
  "Adamson and green & co Ltd",
  "Adamson Green & Co Ltd",
  "Adande",
  "Adastra",
  "ADATA",
  "ADATTABLA",
  "ADC",
  "ADC American",
  "ADC FANS (HOWDEN)",
  "ADCOCK",
  "ADCOCK SHIPLEY TEXTRON",
  "Adcrest",
  "ADD VENT",
  "ADDA",
  "ADDFLOW",
  "Addicott",
  "Addicott Electrical Services",
  "Addressable",
  "addvant",
  "ADDVENT",
  "ADE GROUP",
  "ADE LTD",
  "Adecmo",
  "ADELLE",
  "ADELPHI COLDSTREAM",
  "Adeltd.Co.Uk",
  "ADEMCO",
  "ADEMCO + MICROTECH",
  "ADEMCO MICROTECH",
  "ADEMO-MICROTECH",
  "ADEXSI UK",
  "ADEY",
  "ADF",
  "ADFORD",
  "ADG",
  "ADG Systems",
  "ADG SYSTEMS PLC.",
  "ADI",
  "ADISA",
  "ADK",
  "ADL LIFT SERVICES LTD",
  "ADLANDE",
  "ADLOR DOORS",
  "ADLORUK",
  "ADM",
  "ADMECO MICROTECH",
  "ADMIRAL",
  "Adpro",
  "ADR",
  "ADRAD",
  "ADRS DOORS",
  "ADRS Solutions",
  "ADS",
  "ADS   ALLDALES",
  "ADS  ALLDALES",
  "ADS LTD",
  "ADS WORLDWIDE",
  "ADT",
  "ADT / Brunel",
  "ADT / IRIS",
  "ADT / TYCO",
  "ADT Bandit",
  "ADT Fire and Security",
  "ADT Honeywell",
  "adt minerva",
  "ADT Tyco",
  "ADT/CHUBB",
  "ADT/HONEYWELL",
  "ADVANCE",
  "Advance Door Engineering",
  "advance door systems",
  "Advance doors",
  "Advance fire systems",
  "Advance Lifts",
  "Advance Lifts Mobility Limited",
  "ADVANCE SERVICES PLC",
  "ADVANCED",
  "Advanced / MP Fire & Security",
  "ADVANCED AIR (UK) LTD",
  "ADVANCED AIR/HONEYWELL",
  "Advanced Automated Access UK",
  "Advanced control systems Ltd",
  "ADVANCED DETECTION SYSTEMS",
  "ADVANCED DIESEL ENG LTD",
  "Advanced Diesel Engineering",
  "ADVANCED DIESEL ENGINEERING LTD",
  "ADVANCED DOORS",
  "ADVANCED DOORS LTD",
  "ADVANCED ELECTRICAL SERVICES",
  "ADVANCED ELECTRICAL SERVICES (YKS) LTD",
  "ADVANCED ENERGY PROSUCTS LTD",
  "Advanced Fire & Security Services",
  "Advanced Ground Systems Ltd",
  "Advanced Handling",
  "ADVANCED LIFTS",
  "Advanced MP Fire & Security",
  "ADVANCED RADIOTECH CORP",
  "Advanced Safety Communications",
  "ADVANCED SERVICES",
  "ADVANCED SHOWERS",
  "ADVANCED TECHNICAL SERVICES",
  "Advantage",
  "Advantage Controls",
  "Advantage Controls",
  "Advent",
  "ADVENT PRO",
  "Adventys",
  "ADVENY",
  "ae",
  "A-E AIR HANDLING EQUIPMENT LTD.",
  "AE_2",
  "AE2",
  "AE-2",
  "AEC",
  "AEC Power",
  "AEEN",
  "AEES",
  "AEFD",
  "AEG",
  "AEG ELECTROLUX",
  "AEG Power Systems",
  "AEGIS",
  "AEGIS ALARM SYSTEMS",
  "AEGIS WATER TREATMENT LTD",
  "AEI",
  "AEI REDIFFUSION",
  "AEL ELECTRICAL",
  "AENOR",
  "Aercology Commercial",
  "Aerial Facilities Ltd",
  "AERMAX FELLOWES",
  "AERMAX PROFFESIONAL",
  "AERMEC",
  "Aermec Riello",
  "Aermotor",
  "AERO DOCK LTD",
  "AEROCOM",
  "Aerodock",
  "Aeroflex",
  "Aeroflex/ Parmiter Hope and Sugden Ltd",
  "AEROFORM LTD",
  "AEROKIT",
  "Aerostar",
  "Aertesi",
  "AERZENER MASCHINEN FABRIC GKBH",
  "AES",
  "AES ACTIVE ENERGY SOLUTIOS LTD",
  "AES Control sSystems",
  "AES CONTROL SYSTEMS",
  "AES CONTROL SYSTEMS TREND",
  "AES Controls",
  "AES LTD",
  "AES/Trend",
  "AESA",
  "AESC",
  "aeson",
  "AET",
  "AETP",
  "AEV Stokvis",
  "Aewoo",
  "AEX Heat",
  "AF Switchgear",
  "AF SWITCHGEAR 630A",
  "AF SWITCHGEAR LIMITED",
  "AF SWITCHGEAR LTD",
  "AF SWITCHGER",
  "AF TECH SERVICES LTD",
  "AF TECHNICAL",
  "AFA",
  "AFA Minerva",
  "AFA- Minerva",
  "AFDS",
  "AFF",
  "AFF LTD. SC NELSON",
  "AFINOX",
  "AFOS",
  "AFR  refrigeration LTD",
  "AFR Refrigeration",
  "AFRISO",
  "AFRISO-EURO",
  "AFRISON",
  "AFS SYSTEMS",
  "AFSS",
  "AFT  SP27/05",
  "AFT GmbH",
  "AFT ULTRA",
  "AG",
  "AG Power Generation",
  "AGA",
  "AGATA",
  "AGATE",
  "AGE",
  "AGENT",
  "AGFA",
  "AGGORA",
  "AGION",
  "AGIP BLADIA",
  "AGIP BLASIA",
  "AGL",
  "aguilera electronica",
  "aguliera electronica",
  "AGUZZANI",
  "AH LTD",
  "AH SEMSTAR",
  "aha",
  "AHB",
  "AHB SERVICES",
  "ahd",
  "Ahrend",
  "AHS LTD.",
  "AHT",
  "AHT  TOTAL REFRIGERATORS",
  "AHT COOLING SYSTEMS",
  "AHT SCANFROST",
  "AHU",
  "AI Phone",
  "AIA",
  "Aiax",
  "AICHI TOKEI",
  "Aichi Toki",
  "AICHI TOKIE",
  "AICHI TPKEI",
  "AICOM",
  "AID ALARM",
  "Aid Call",
  "Aidalarm",
  "AIDELKE LOOVENT",
  "AIDELLE",
  "AIDELLE LOOVENT",
  "AIEFOLW",
  "AIEHL-ABEGG KG",
  "AIGEON",
  "AIM Engineering",
  "AIPHONE",
  "air",
  "AIR AND FIRE CONTROL",
  "Air Bench",
  "Air Bench ?",
  "AIR BLOC",
  "AIR BLOCK",
  "AIR BLUE",
  "AIR CARE",
  "AIR COM",
  "AIR CON",
  "AIR CONDITIONING ACCESSORIES",
  "AIR CONTROL LTD",
  "AIR CONTROLS INDUSTRIES",
  "AIR CUISINE",
  "Air Design",
  "AIR EQUIPMENT",
  "AIR equipos",
  "Air Flow",
  "AIR FORCE",
  "AIR FURY",
  "Air handelng proucts",
  "Air Handers",
  "AIR HANDLER",
  "Air Handlers",
  "air handlers ltd",
  "Air Handlers Manchester",
  "AIR HANDLING",
  "AIR HANDLING AYSTEMS LTD.",
  "AIR HANDLING CONTROL PANEL",
  "AIR HANDLING EQUIPMENT",
  "AIR HANDLING EQUIPMENT LTD",
  "Air handling products",
  "AIR HANDLING SYSTEMS",
  "AIR HANDLING SYSTEMS LTD",
  "AIR HANDLING SYSTEMS LTD.",
  "AIR HANDLING UNITS LTD.",
  "Air Ind. Developements Ltd",
  "Air Industrial Equipment",
  "AIR ION TECHNOLOGY LTD",
  "AIR MACHINES",
  "air plants",
  "AIR PLANTS LTD",
  "Air Power",
  "AIR POWER CENTRE",
  "AIR PRODUCTS",
  "AIR RECEIVERS LTD",
  "AIR SCIENCE USA LLC",
  "AIR SEP CORPORATION",
  "Air Solution Ltd",
  "AIR SOLUTIONS COMPANY",
  "AIR SOURCE",
  "air steril",
  "Air Stream",
  "Air tech",
  "AIR TECH CONTROLS LTD",
  "AIR TECH SERV",
  "Air tube",
  "Air Tube Conveyors",
  "AIR TUBE CONVEYORS LTD.",
  "Air vent",
  "Air Vent technology",
  "Air Product LTD",
  "Air trak",
  "Airap",
  "AIRBL8C",
  "AIRBLOC",
  "airblock",
  "AIR-BLUE air conditioning",
  "AIRBUS",
  "AIR-CALL",
  "AIRCARE LTD",
  "airco",
  "AIRCOGEN",
  "AIRCOGEN CHP SOLUTIONS",
  "AIRCOGEN LTD.",
  "AIRCOIL PRODUCTS LTD.",
  "Airconaire",
  "AIRCONDITIONINGCENTRE",
  "AIRCRAFT AIR HANDLING LTD.",
  "AIRCROSS",
  "AIRDAI",
  "AIRDALE",
  "Airdale - New Generation AHU Extract",
  "Airdale - New Generation AHU Supply",
  "AIRDALE group",
  "AIRDALE WALL MOUNTED SPLIT",
  "Airdale/Dakin",
  "AIRDAPTER LTD",
  "Airdor",
  "AIRDRI",
  "AIRDRI LTD",
  "AIRDRY LTD.",
  "AIRE",
  "Aired",
  "Airedale",
  "AIREDALE CATERING EQUIPMENT",
  "Airedale Chemical Company",
  "Airedale Environmental Srvs.",
  "AIREDALE GROUP",
  "Airedale Master",
  "AIREDALE",
  "airf;ow",
  "AIRFAN",
  "airfield lighting systems",
  "Airfix P",
  "AIRFLO",
  "AIRFLOE",
  "Airflow",
  "Airflow Developments",
  "Airforce",
  "AIR-FORCE",
  "airforce arger",
  "Airform",
  "Airforn",
  "AIRHAND",
  "Airius",
  "AIRLINK TRANSFORMERS",
  "airmark",
  "AIRMASTER",
  "Airmate",
  "Airmax",
  "AIRMEC",
  "AIRONE",
  "AIRONIC",
  "AIR-O-STEAM",
  "AIRPEL FILTEATION",
  "Airphone",
  "Airpower",
  "Airpower Centre",
  "Airquick",
  "AIRQUICK / NOCCHI",
  "AIRQUICK/VEEDER-ROOT",
  "Airquipment",
  "AIRSCREW FAN",
  "AIRSCREW FANS",
  "Airsense Technologies Ltd",
  "AIRSOURCE",
  "Airstream",
  "Airstream 2000",
  "AIRSTREAM 5000",
  "Airstrean",
  "Airtech",
  "Airtech Controls Ltd",
  "AIRTECH PRODUCTS LTD",
  "AIRTECHNICS",
  "Airtecnics",
  "AIRTEONICS",
  "AIRTRACK",
  "AIR-TRACK",
  "Airtrak",
  "AIRTUBE",
  "Airtube Technologies",
  "AIRVAC",
  "airvent",
  "Airvent Systems",
  "AIRVENT SYSTEMS SERVICES LTD",
  "AIRWAYS",
  "AIRWELL",
  "AIRWELLS",
  "Aisle master",
  "aitflow",
  "AJ ACCESS",
  "AJ ANTUNES & CO",
  "AJ Cummings",
  "AJ Metal Products",
  "AJ POWER",
  "Ajax",
  "AJAX CHUBB VAREL",
  "AJH",
  "AJH Gryphon",
  "AJH tools",
  "AK",
  "AKAI",
  "AKE",
  "Akhi Tokei",
  "Akiele",
  "Akira",
  "AKON",
  "Akron",
  "AKSA",
  "AKVATERM",
  "AKW",
  "Ala Williams & Co",
  "Alan",
  "Alan Aqua Systems Ltd",
  "ALAN ECTRICAL LTD",
  "Alan Electrical",
  "ALAN ELECTRICAL L4D",
  "ALAN ELECTRICAL LTD",
  "Alan Fry Controls",
  "Alan West",
  "Alan William & Co",
  "Alan williams",
  "Alan Williams & Co",
  "ALAN WLECTRICAL LTD",
  "ALANTIC",
  "Alaris",
  "ALARM CENTRE",
  "Alarm service group",
  "Alarmolite Controls",
  "Alarmtec",
  "ALARMTRONICS LTD",
  "alba",
  "ALBANY",
  "ALBANY DOORSYSTEMS",
  "ALBANY PUMPS",
  "ALBERTO SASSI",
  "Albion",
  "Albion Water Heaters",
  "ALBION WHITE WOLF",
  "ALC",
  "ALCAD",
  "Alcala de Henares",
  "Alcan",
  "Alco",
  "ALCO -BELDAN",
  "ALCO SYSTEMS",
  "Alcom",
  "Alcon",
  "Alcon Solenoids",
  "Alcumus",
  "Alda",
  "ALDES",
  "ALDOS",
  "Aldous & Stamp",
  "Aldous & Stamp Ltd",
  "ALDOUS & STAMP LTD.",
  "ALDOUS AND STAMP",
  "ALDOUS AND STAMP LIMITED",
  "ALDOUS STAMP LTD",
  "Alecto",
  "Alejandria",
  "Alerto",
  "Alerton",
  "ALEXANDER CONTROL",
  "ALEXANDER CONTROLS",
  "Alexander Controls Ltd.",
  "Alexandra Controls",
  "Alfa",
  "ALFA LAVAL",
  "ALFA-LAVAL",
  "ALFRISO",
  "ALGAE-X",
  "ALGEN GILGDEN DOOR SYTEMS",
  "ALGI",
  "ALGI ALFRED",
  "ALGO - TEC",
  "Algo Tec",
  "ALGOTEC",
  "Algo-Tec",
  "ALHUA",
  "ALIBERINOX",
  "ALIBERNOX",
  "ALIENHERO",
  "Ali-Omega Limited",
  "Alke",
  "AL-KO",
  "ALKON",
  "ALL AROUND HEATING",
  "ALL FREON SERVICES LTD.",
  "ALL GOOD TRIO",
  "all pump solutions",
  "ALL PUMP SOLUTIONS LTD",
  "all shutter",
  "ALLAN AQUA SYSTEMS",
  "ALLAN AQUA-SYSTEMS",
  "ALLAN WEST.",
  "ALLAWAY ACOUSTICS",
  "ALLAWAY ACUSTICS",
  "ALLBROOKS",
  "Allcorn Engineering",
  "ALLDAES",
  "Alldales",
  "ALLDALES (ABB)",
  "ALLDALES DRIVE DYSTEMS",
  "ALLDALES DRIVE SYSTEMS",
  "ALLDALES DRIVE SYSTEMS LTD",
  "ALLDALES DRUVE SYSTEMS LTD",
  "ALLDALES LTD.",
  "ALLDALES SOUTHERN LTD",
  "Alldoors",
  "Alldos",
  "ALLDOS Ltd",
  "Allen",
  "allen  bradley",
  "ALLEN - MARTIN",
  "ALLEN AND YGENIS",
  "ALLEN AND YGNIA",
  "ALLEN BRADLEY",
  "ALLEN BRADLEY (CAE)",
  "ALLEN WEST",
  "ALLEN WEST & CO. LTD.",
  "ALLEN WEST &CO",
  "ALLEN YGNIS",
  "Allen-Bradley",
  "ALLENWEST ELECTRICAL LIMITED",
  "Allerton",
  "Allgood",
  "ALLHABO",
  "ALLIA",
  "ALLIANCE",
  "ALLIANCE  LAUNDRY SYSTEM LLC",
  "Alliance Door Engineering Ltd",
  "Alliance international",
  "ALLIANCE LAUNDRY SYSTEM",
  "ALLIANCE LAUNDRY SYSTEM LLC",
  "ALLIANCE LAUNDRY SYSTEMS LLC.",
  "Allianz",
  "Allibert Helix",
  "ALLIED LIFTS UK LTD",
  "ALLIED TELESTYN",
  "alligator",
  "Allis - Chalmers",
  "ALLMET",
  "Allsee",
  "ALLTYPE PUMPS LTD",
  "ALLUP",
  "ALLUSER INDUSTRIE",
  "alluser industries",
  "Allweiler AG",
  "ALM",
  "Alma",
  "Almet",
  "ALMET DOORS LTD",
  "ALMEX",
  "ALMIG",
  "Almison",
  "ALP Electrical Ltd.",
  "ALPAK",
  "Alpar Electronics",
  "Alpek",
  "ALPENINOX",
  "ALPH LAVAL",
  "Alpha",
  "ALPHA 1",
  "Alpha Controls",
  "ALPHA FIRE ENGINEERING",
  "ALPHA LAVAL",
  "Alpha Laval Lund",
  "Alpha Lifting Services",
  "Alpha Peerless",
  "ALPHA PEERLESS FIRE STSTEM LTD",
  "Alpha Pump",
  "ALPHA SAFE",
  "ALPHA TRACE",
  "Alpha1",
  "Alpha-laval",
  "Alphingdale",
  "Alphone",
  "Alphone door camera aystems",
  "Alpine",
  "ALPINE ELECTRICAL",
  "ALPRO",
  "ALPRO?",
  "ALS",
  "Alsim secure construction",
  "Alson Controls",
  "ALSOUS &STAMP",
  "ALSTOM",
  "ALSTON",
  "ALTAIR",
  "ALTAIR SAPPEL",
  "ALTEC",
  "ALTECH",
  "ALTECNIC",
  "ALTECNIC LTD",
  "ALTERNA",
  "ALTERNATIVE SYSTEMS PROTECTION",
  "ALTEX",
  "AL-TEXICOM",
  "ALTIGA",
  "ALTMANN & BOHNING",
  "Alto",
  "ALTOMETERS",
  "Alton",
  "AltoShaam",
  "ALTO-SHAAM",
  "ALTOSHAM",
  "ALTO-SHAM",
  "ALTO-SHAMM",
  "ALTO-SHASM",
  "ALTP",
  "ALTREX",
  "ALTRO",
  "Altus",
  "ALU TRAP",
  "Aluha Technology",
  "ALUMINO",
  "ALUP",
  "Alutrap",
  "AM",
  "AM AUTOMETERS",
  "AM ROOF UNITS",
  "Amal Sweden",
  "Amam",
  "AMAM AUTO METERS",
  "AMAN",
  "AMANA",
  "AMANA COMMERCIAL",
  "Amarinth",
  "AMARYLLIS",
  "AMAX",
  "AMAZON",
  "AMAZON LAUNDRY SYSTEM",
  "AMB",
  "AMBACH",
  "Ambassador",
  "AMBASSADOR SECURITY",
  "AMBASSADOR SHUTTERS CO.",
  "AMBER",
  "Amber Doors",
  "AMBER ELECTRICAL",
  "AMBER INDUSTRIAL DOORS",
  "AMBER LEV",
  "AMBI RAD",
  "Ambi- Rad",
  "Ambi Rads",
  "AMBI_RAD",
  "AMBIANO",
  "AMBIENT AIR",
  "AMBIFLEX",
  "AMBIGAS",
  "AMBIRAB",
  "AMBIRAD",
  "Ambi-Rad",
  "AMBI-RAD LTD.",
  "AMBIRADS",
  "AMBIRAID",
  "AMBIRAND",
  "AMBITAD",
  "Ambrex Switchgear",
  "AMC & E CONTROLS",
  "Amcor",
  "AMD",
  "AMDEGA LTD",
  "Amenity lighting",
  "AMERCARE",
  "AMEREX",
  "AMEREX CORP.",
  "amerian dynamics",
  "AMERICAM DYNAMICS",
  "AMERICAMN DYNAMICS",
  "American Coolair",
  "AMERICAN DIANAMICS",
  "American Drier Company",
  "AMERICAN DYAMICS",
  "AMERICAN DYDAMICS",
  "AMERICAN DYMANICS",
  "American Dynaics",
  "AMERICAN DYNAM7CS",
  "American Dynamic",
  "American Dynamics",
  "AMERICAN DYNAMICS-ADT",
  "AMERICAN DYNAMUCS",
  "AMERICAN DYNANAMICS",
  "AMERICAN METER COMPANY",
  "American Proline",
  "AMERICAN WATER HEATERS",
  "AMERICN DYNAMICS",
  "AMEX",
  "AMEXAET",
  "AMF VENNER",
  "Amflow",
  "AMG",
  "AMI",
  "AMICA",
  "AMICO",
  "AMIKA",
  "AMIM",
  "AMO",
  "amona",
  "AMOS",
  "Amp",
  "AMP AUTOMATION",
  "AMP Engineers",
  "Amp Pumps",
  "AMPAC",
  "ampetronic",
  "AMPHIBIA",
  "Amphitech+",
  "AMPLETRONIC",
  "amptronic",
  "AMPU",
  "AMPY & METERING",
  "AMPY AUTOMATION",
  "AMPY AUTOMATIONS",
  "AMPY METERING",
  "AMS",
  "AMS / BSS",
  "AMS LTD",
  "AMS Pump",
  "AMSS BSS STOCK FITTINGS LTD",
  "AMSS Ground Support",
  "AMSS Ground Support Equipment",
  "AMSSL",
  "AMSTRAD",
  "Amtec",
  "AMTECH",
  "Amtecs",
  "AMTROL",
  "Amtron",
  "AMX",
  "AMXAET",
  "AMXCA",
  "AMZ",
  "Anaco",
  "ANACUS SHUTTERS LTD",
  "ANALOX",
  "Analox Sensor Technologies",
  "ANAYL",
  "ANB",
  "ANCHORPAC",
  "AND",
  "Anda",
  "Anda ltd",
  "Anda Products",
  "andal",
  "Andec Fire Systems",
  "ANDEL FLOOD ALARM SE",
  "ANDEL FLOOD ALARM SYSTEMS",
  "ANDEL LTD",
  "Andell",
  "ANDER LTD",
  "Anderco",
  "ANDERSONS",
  "Andover",
  "ANDOVER CONTINUUM",
  "Andover Control",
  "Andover Controls",
  "ANDRES",
  "ANDREW",
  "ANDREW JAMES",
  "Andrew Jones",
  "Andrew Sykes",
  "ANDREWS",
  "ANDREWS AIR CONDITIONING",
  "ANDREWS ELECTRICS LTD.",
  "ANDREWS JAMES",
  "Andrews Sykes",
  "ANDREWS WATER HEATER",
  "ANDREWS WATER HEATERS",
  "ANDREWS-WEATHERFOIL LTD.",
  "ANDROSS ELECTRICS",
  "ANDYTRUC",
  "Anell",
  "anerican dynamics",
  "Anets",
  "ANFIM",
  "Angel",
  "Angel Mir",
  "Angel solutions ltd",
  "Angel Springs Holdings Ltd",
  "ANGEL0",
  "ANGELANTONI",
  "ANGELO PO",
  "ANGLIA DOOR SYSTEMS",
  "Anglian Burglar Alarms",
  "Anglo Lift",
  "Anglo Nordic",
  "ANGLO PUMPS LIMITED",
  "Angus",
  "ANGUS AIR",
  "ANGUS FIRE",
  "ANGUS FIRE ARMOUR LTD",
  "ANGUS FIRE VALVE",
  "ANIMEO",
  "Aniston",
  "ANIXTER FASTENERS",
  "ANLAF",
  "ANLAZE",
  "ANNKE",
  "ANOLIS",
  "Anord",
  "Anoxa",
  "ANS",
  "Ansel",
  "ANSIL",
  "ANSON",
  "ANSTON",
  "ANSTON LIFTS LTD",
  "Ansul",
  "ANSUL INCORPORATED",
  "ANSUL R-102 WET SYSTEM",
  "ANSUL-PIRAHNA",
  "ANTARCTIC",
  "antiference",
  "Antipest",
  "ANTRIEBSTECHNIK",
  "ANTUNES",
  "ANYTRONICS LTD.",
  "AO Smith",
  "AO SMITH LTD",
  "AO SMITH",
  "AOC",
  "AOIP",
  "AOSmtl",
  "AP",
  "AP SOLUTIONS",
  "Apart",
  "Apator Powagaz",
  "APC",
  "APC Environmetal",
  "APCC",
  "APC-SCHNEIDER ELECTRICS",
  "APDS",
  "APE",
  "APE ALARMS",
  "APE FIRE & SECURITY",
  "APE fire&security",
  "APENGROUP",
  "APEX",
  "APEX AUDIO SYSTEMS LTD",
  "APEX AUDIO SYSTEMS SALES LTD",
  "Apex Lifts",
  "APEX LIGHTING CONTROLS",
  "APEX LIGHTING SYSTEM",
  "APEX PUMPS",
  "APEXL7GHTING CONTROLS",
  "API Fire and Safety",
  "apinox",
  "APL ENVIRONMENTAL",
  "APL LTD",
  "APL PLANTS LTD",
  "Apled Thermal Control",
  "APLEX",
  "APLIED POWER ENGINEERING",
  "APMG LTD",
  "APOGEE",
  "Apollo",
  "APOLLO LIFT",
  "APOLLO LIFTS",
  "Apple",
  "APPLIANCE TECNIK",
  "APPLICATION SOLUTIONS AND SECURITY LTD",
  "APPLICATION SOLUTIONS LTD",
  "APPLIED AIR PLANT",
  "APPLIED AIR PLANT LTD.",
  "applied energy products",
  "APPLIED ENERGY PRODUCTS LIMITED",
  "Applied Energy Products Ltd",
  "Applied Energy Products.",
  "Applied Energy Systems",
  "Applied poer engineering",
  "Applied Power Engineering",
  "Applitech",
  "Applo",
  "APPOLLO",
  "Appolo",
  "approved power supply",
  "approved power suupply",
  "APRECO",
  "Aprimatic",
  "APROVIS",
  "APS",
  "APS PUMP SOLUTIONS",
  "APT",
  "APT Controls",
  "APT TECHNOLOGIES",
  "APT TECHNOLOGIES LTD",
  "APT TYPE",
  "APTC",
  "Aptum",
  "APUR0",
  "APV",
  "APV Anda",
  "APV HALL",
  "APVH HALL",
  "aqialp",
  "AQP",
  "Aqua",
  "AQUA AID FRANCHISING LTD",
  "AQUA ALARM",
  "Aqua and Air Systems",
  "Aqua Armour",
  "AQUA CONTROL SERVICES LTD",
  "Aqua Controls",
  "Aqua cool",
  "Aqua Cooler",
  "AQUA CUBIC",
  "AQUA CURE",
  "AQUA DOSA",
  "aqua electric",
  "AQUA FRANCHISING LTD",
  "AQUA GALAXY",
  "Aqua Group",
  "AQUA METER",
  "Aqua Metro",
  "AQUA MOTION",
  "AQUA PACK",
  "AQUA SENTRY",
  "Aqua system",
  "Aqua tank",
  "Aqua Tech",
  "AQUA TECH LTD",
  "AQUA TECH PRESSMAIN",
  "AQUA UR",
  "Aqua Matic",
  "Aqua Press",
  "AQUAAID FRANCHISING LTD",
  "Aquaco",
  "Aquacold",
  "Aquacompact",
  "Aquacool",
  "AQUACURE",
  "Aquada Uv",
  "AQUADIS",
  "AQUAFIL",
  "Aquafill",
  "AQUAFLEX",
  "AQUAID",
  "Aquaid",
  "AQUAID FRANCHSING LTD",
  "Aquaius",
  "AQUAJET",
  "AQUAJOY BATHLIFTS",
  "Aqualarm",
  "Aqualeader",
  "Aqualeak",
  "AQUALEAK DETECTION",
  "Aqualeak Detection Ltd",
  "AQUALECTRA",
  "AQUALINE",
  "Aqualisa",
  "Aquality",
  "Aqua-lity",
  "Aquamaster",
  "Aquamatic",
  "AQUAMOTION",
  "aquapack",
  "Aquapoint",
  "Aquapress",
  "AQUAREAD",
  "AQUARECH LTD",
  "Aquarious",
  "Aquarius",
  "AQUARIUSXI",
  "aquarve",
  "Aquasave",
  "Aquasentry",
  "aquaservice",
  "AQUASRNTRY",
  "Aquastat",
  "Aquastat Ltd",
  "AQUASTEM",
  "AQUASTOW",
  "Aquasur",
  "AQUASYSTEM",
  "Aquatanks",
  "AQUATEC",
  "Aquatec Ltd",
  "AQUATECH 2000PLUS",
  "AQUATECH Limited.",
  "AQUATECH LTD",
  "AQUATECH PRESSMAN",
  "AQUATECH Pressman.",
  "Aquatek",
  "AQUATEVH LTD",
  "Aquatherm",
  "Aquatherm Heat Transfer Ltd",
  "aquatics",
  "AQUATOR",
  "aquatrev",
  "AquaTron",
  "AQUATRONIC",
  "Aquatrust",
  "AQUAVANTAGE",
  "AQUAWASH",
  "Aquazur",
  "AQUILA",
  "AQUILA CONTROL LTD",
  "AQUILA SWITCHGEAR",
  "AQUILA SWITCHGEAR LTD.",
  "AQUILAR",
  "Aquiton",
  "AquiTron",
  "AQULARM",
  "AQUTECH",
  "ar",
  "AR TWIGG AND SON",
  "AR TWIGG SON",
  "Arad",
  "ARAD LTD DALIA",
  "ARAK LIFT LTD.",
  "ARAMA",
  "Arata",
  "ARB",
  "ARBOGA",
  "ARBOGA GRINDER",
  "ARBONIA",
  "ARBOURNE",
  "ARC",
  "ARCABOA",
  "Arcelik",
  "ARCHDALE",
  "Archer",
  "ARCHITECTURAL S/S SWIMMING POOL PRODUCTS LTD",
  "ARCHITECTURAL STAINLESS LTD",
  "Architectural Stainless Swimming Pool Products",
  "ARCHITEN LANDRELL ASSOCIATES LTD.",
  "Arcluce",
  "ARCNET",
  "ARCO",
  "ARCONTROL",
  "ARCOTRONICS",
  "Arctaris",
  "Arctic",
  "ARCTIC CHILL",
  "arctic circle",
  "ARCTICA",
  "ARCTICIA COOLERS",
  "ARCTIKO",
  "Arctronics",
  "ARDECA",
  "ARDEN CONTROLS & ELECTRICAL LTD.",
  "ARDEY MAGNA CLEAN",
  "Ardion Doors",
  "ARE",
  "AREM SAS",
  "AREOFOIL",
  "Areotronics",
  "Areta",
  "AREVA",
  "AREX",
  "ARFINOX",
  "ARG",
  "ARGANO WINCH",
  "ARGENT BUILDING SERVICES",
  "ARGIS ALARM SYSTEM",
  "Argo",
  "ARGONITE",
  "Argos",
  "Argus",
  "ARGUS FIRE",
  "ARI",
  "Arial Facilities Lto",
  "Arian",
  "ARICON",
  "ARIES",
  "ARIES POWER PLANT LTD",
  "ario",
  "arisco",
  "Aristom",
  "Ariston",
  "Ariston 15",
  "Aritco",
  "ARITCO LIFT AB",
  "ARITCO LIFTS",
  "ARITCO/GARTEX",
  "aritec",
  "ARITECH",
  "ARITECH Products",
  "ariton",
  "Arjo",
  "ARJO HUNTLEIGH",
  "ARJOHUNTLEIGH",
  "ARK MEASUREMENT & CONTROL",
  "ARK MEASUREMENT & CONTROLS LTD.",
  "ARK MEASUREMENTS & CONTROL LTD.",
  "ARKA LIFT LTD",
  "ARKAS LTD.",
  "arkoni",
  "Arlington",
  "ARM LTD",
  "ARMADSHIELD",
  "Armagh Switchgear",
  "Armah",
  "Armah switch gear",
  "Armah switchgear",
  "ARMAH SWITCHGEAR LTD",
  "ARMAR SWITCHGEAR",
  "ARMARINTH",
  "ARMEX",
  "Armitage",
  "ARMITAGE SHANKS",
  "Armitage Ware",
  "Armitage Shanks",
  "ARMOUR POST LTD",
  "Armstorng Holden Brooke Pullen",
  "ARMSTRONG",
  "ARMSTRONG BROOK CROMPTON",
  "ARMSTRONG DARLING",
  "Armstrong Diesel",
  "armstrong dura",
  "ARMSTRONG EXPANSION TANK",
  "ARMSTRONG FIRE PUMPS",
  "Armstrong HBP",
  "ARMSTRONG Holden & Brooke Pullen",
  "ARMSTRONG HOLDEN & BROOKE PULLEN LTD.",
  "ARMSTRONG HOLDEN BRO",
  "Armstrong Holden Brook Pullen",
  "ARMSTRONG Holden Brook Pullen.",
  "ARMSTRONG HOLDEN BROOKE",
  "Armstrong Holden Brooke Oullen",
  "ARMSTRONG HOLDEN BROOKE PULLEN",
  "Armstrong -Holden Brooke Pullen",
  "ARMSTRONG HOLDEN BROOKE PULLEN.",
  "ARMSTRONG HOLDEN PULLEN",
  "ARMSTRONG HOLSENBROOKE PULLEN",
  "ARMSTRONG PPS",
  "Armstrong Pullen",
  "ARMSTRONG PUMOS LTD.",
  "ARMSTRONG PUMPS LIMITED.",
  "ARMSTRONG PUMPS LTD",
  "ARMSTRONG PUMPS LTD.",
  "ARMSTRONG HOLDEN BROOKE PULLEN",
  "ARMSTRONG PUMPS LTD",
  "ARMSTRONG/HOLDEN BROOKE",
  "ARMSTRONG/HOLDEN BROOKE PULLEN",
  "ARMTAGE SHANKS",
  "Arnec",
  "Arneg",
  "Aroma-Safe",
  "ARON",
  "Aron elec meter",
  "ARON ELECTRICITY METERS LTD.",
  "Aros",
  "AROSITON",
  "ARP SIGNAL",
  "ARP SIGNALS",
  "ARPOKO",
  "ARROW",
  "Arrow valve",
  "ARROW VALVES",
  "ARROWW VALVES LTD",
  "Arsenal",
  "Arstrong",
  "ARSTRONG HOLDEN BROOKE PULLEN",
  "ArT",
  "ArtCoustix",
  "Artec",
  "ARTHUR MCKAY",
  "Arthur Ring Ltd",
  "ARTIC",
  "artic circle",
  "ARTIC FUSE & ELEC MFG LTD",
  "ARTIC FUSE & MFG CO. LTD",
  "Artica",
  "ARTICA COOLER",
  "articas",
  "ARTICO",
  "Artico Gartec",
  "ARTICO LIFT",
  "Artico Lift AB",
  "ARTICO LIFT AB,SWEDEN",
  "artisan",
  "ARTISAN SECURITY DOORS",
  "Artizan",
  "Artizan Security",
  "ARTROMICK",
  "Artron",
  "ARTRON TOWER PUMPS",
  "ARTSTRONG",
  "arua",
  "Arun",
  "ARV UK LTD",
  "ARXCIS",
  "AS",
  "AS INSTALLED",
  "ASA",
  "ASALBERTO SASSI",
  "ASBER",
  "ASC",
  "ASC lt",
  "ASC LTD",
  "Asca",
  "ASCENDOR",
  "Asciugamani",
  "ASCO",
  "Asco (UK)",
  "Ascom",
  "ASCON",
  "Ascon international",
  "Ascot",
  "Ascot Bannerman",
  "Ascot Doors",
  "Ascot doors ltd",
  "ASCOT INDUSTRY DOORS LTD.",
  "ASD",
  "ASD - Applied Security Designs",
  "Asda",
  "Asda new rename",
  "ASE",
  "Asec / Mul-T-Lock",
  "ASEC SECURITY",
  "ASEC SECURTY",
  "ASEC/SECURITY",
  "ASF LTD",
  "ASF THOMAS",
  "asfrqe",
  "ASHDOWN CONTROL SERVICES",
  "Ashdown Controls",
  "ASHE ALARMS",
  "Ashland",
  "ASHLAND UK LIMITED",
  "ASHLEY",
  "ASHOWN CONTROL SERVICES",
  "ASHRIDGE ENGINEERING",
  "ASHWOOD FIRE PROTECTION",
  "ASI",
  "ASL",
  "ASLOTEL",
  "ASN",
  "aspect",
  "ASPEN",
  "Aspen Pumps",
  "ASPEN/A D SMITH",
  "ASPERA",
  "Aspira",
  "ASPIRANTE",
  "ASPLRA",
  "ASPULL CATERING",
  "ASPULL CATERING EQUIPMENT AND SERVICES",
  "ASSA",
  "ASSA ABLOY",
  "Assa Abloy Besam",
  "ASSA ABLOY TRAKKA",
  "assc",
  "Asset Unseen",
  "Associated Electrical Industries",
  "ASSOCIATED SECURITY",
  "Association Power And Light",
  "ASSTRPLAST",
  "ASSURED SYSTEMS",
  "AST",
  "ASTA",
  "ASTEC",
  "ASTEC MICROFLOW",
  "ASTEC SYSTEMS LTD",
  "ASTECAIR",
  "ASTMODULAR",
  "ASTON",
  "ASTON-DOVE LTD",
  "ASTOR BANNERMAN",
  "Astoria",
  "Astra",
  "ASTRAL",
  "astral control services",
  "Astral Control Services Ltd",
  "Astral controls ltd",
  "Astral Pool",
  "ASTRAL POOLS",
  "Astralite",
  "astralpool",
  "ASTRAPOOL",
  "Astro",
  "Astronoi m",
  "ASTROPHYSICS",
  "ASTROPLAST",
  "ASUS",
  "Asv",
  "ASVS",
  "ASX",
  "AT HEIGHT",
  "At High Level",
  "ATA",
  "ATACK ELECTRICAL CO LTD",
  "ATAG",
  "ATAIN",
  "Ataq",
  "ATAV",
  "ATB",
  "ATB FENDER",
  "ATBB",
  "ATC",
  "ATC Systems",
  "Atc Silcon",
  "ATD",
  "Ateis",
  "ATEIS ?",
  "ATF Gmbh",
  "ATG",
  "ATG TECHNOLOGY",
  "ATG UV TECHNOLOGY",
  "ATG-WILLAND",
  "ATHENA",
  "Atholl",
  "ATI",
  "ATI SERIES",
  "Atkinson",
  "ATL-ANDROMEDA TELEMATICS LTD.",
  "Atlant",
  "ATLANTA",
  "ATLANTA ENT. LTD",
  "ATLANTA GENERATORS",
  "Atlanta Manufacturing",
  "ATLANTIC",
  "ATLANTIC BOILERS",
  "Atlantis",
  "ATLAS",
  "ATLAS CLEAN AIR LTD",
  "ATLAS CLEAN AIR LTD.",
  "Atlas Cooci",
  "ATLAS COPA",
  "Atlas Copco",
  "ATLAS COPCO AIRPOWER",
  "Atlas Copco/Motivair",
  "ATLAS FIRE ENGINEERING LTD",
  "Atlas metal ind.",
  "ATLAS SOUND",
  "Atlas Tanks",
  "Atlas Wright",
  "Atlas-Copco",
  "Atlis Metal Ind.",
  "ATM",
  "ATMOSAIR LTD",
  "Atos",
  "ATOSA",
  "Atrium Gantrys",
  "ats",
  "ATSA",
  "Attenborough",
  "ATWELL INTERNATIONAL",
  "ATX",
  "AUBOX",
  "AUCO",
  "AUDAC",
  "AUDIENCE SYSTEMS",
  "Audio",
  "AUDIO-TECHNICA.",
  "AUDIX",
  "AUNKNOWN",
  "Aunsul",
  "Auomaic Sysems Group IER",
  "Aura",
  "AURDRI",
  "AURORA",
  "Aurora Pump",
  "AURORALIFTS",
  "AUSIMIX",
  "AUSTCO",
  "AUSTRALIAN MONITOR",
  "AUTEC",
  "AUTINUMIS",
  "AUTO DIESEL",
  "Auto Diesels",
  "AUTO DOORMAN",
  "Auto Hand Dryers",
  "AUTO METERS",
  "Autobar",
  "AUTOFILL",
  "Autoflame",
  "AUTOFLOW",
  "AUTOFRY",
  "Autogate Systems Ltd",
  "Autoglym",
  "AUTO-LIFT UK LTD.",
  "AUTOLUMIS",
  "Automat",
  "Automate",
  "Automated Controls Limited",
  "Automated Dluid & Effluent",
  "Automated Door Systems",
  "Automated Enviromental Systems",
  "Automated Logic",
  "Automated Security Design",
  "Automated Systems",
  "AUTOMATED WATER & EFFLUENT",
  "Automated Water & Effluent Ltd",
  "Automatic",
  "AUTOMATIC ACCESS SYSTEMS",
  "AUTOMATIC CONTROL SYSTEMS",
  "Automatic Doors Ltd",
  "AUTOMATIC HAND DRIER T2000",
  "automatic hand dryer",
  "AUTOMATIC PROTECTION LIMITED",
  "AUTOMATIC SMOKE CONTRL",
  "AUTOMATIC SPRINKLER",
  "AUTOMATIC SYSTEM IER GROUP",
  "Automatic Systems",
  "AUTOMATIC SYSTEMS GROUP",
  "Automatic Temperature Controls Ltd",
  "AUTOMATICS",
  "AUTOMATION & LIFT",
  "AUTOMATION & PROCESS CONTROLS LTD",
  "AUTOMATION CONTROLS",
  "AUTOMET FILTRATION LTD.",
  "AUTOMETER",
  "AUTOMETER LTD",
  "AUTOMETERS",
  "AUTOMETERS LTD",
  "Autometers System",
  "AUTOMETERS SYSTEMS",
  "AUTOMUNIS",
  "Autonnica",
  "AUTONUIS",
  "AUTONUMIE",
  "AUTONUMIS",
  "AUTONUMIS LTD",
  "Autopa",
  "AUTORA",
  "Autoroll uk ltd",
  "AUTO-SHAM",
  "Autotrack",
  "AUTOTROL",
  "AUTOUMIS",
  "Autowest",
  "Auutomation Controls",
  "AV access",
  "AV BIRCH",
  "AV SERVICES",
  "Avalanche",
  "Avalon",
  "Avalon doors and shutters ltd",
  "avamore",
  "AVAYA",
  "Avaya/Cisco",
  "AVBH",
  "AVC",
  "AVC Ltd",
  "Avel",
  "AVELAIR",
  "Aventure",
  "Avenue",
  "AVERNUE",
  "Avery",
  "Avery Berkel",
  "Avery Hardoll",
  "AVERY HARDOLL FORECOURT EQUIPMENT",
  "Avery Hardoll Ltd",
  "avf",
  "Avigilion",
  "Avigilon",
  "Aviglon",
  "AVILIGON",
  "Avion",
  "AVIS",
  "AVK",
  "AVK (CBS GROUP LTD)",
  "AVK POWER SOLUTIONS",
  "AVK SEG UK LTD",
  "AVK UK LTD",
  "AVK UPS SYSTEMS",
  "AVK/SEG",
  "AVK/SEG UK LTD.",
  "AVK-BROADCROWN",
  "AVL Meters",
  "Avon",
  "Avon Alarms",
  "Avon Armpur Ltd.",
  "Avon Barrier Co.",
  "AVON BARRIER COMPANY",
  "AVON BARRIER COMPANY LIMITED",
  "AVON BARRIER COMPANY LTD",
  "AVON BARRIER COMPANY LYD",
  "Avon Barrier Corporation",
  "AVON DOORS",
  "AVON GILGON DOOR SYSTEMS",
  "Avon Industrial Doors",
  "Avonsoft",
  "AVOVA",
  "AVR",
  "AVRS SYSTEMS LTD.",
  "AVS",
  "AVX",
  "AWR Instruments",
  "AWS",
  "AWUASENTRY",
  "AX AIR",
  "AX10",
  "AXA",
  "AXA POWER",
  "AXA POWER a/s",
  "AXA POWER APS",
  "AXA Power",
  "Axair",
  "AXAIR FANS",
  "AXAIR FANS UK LTD",
  "AXAIR FANS UK LTD.",
  "AXAIR UK LTD.",
  "Axair-fans",
  "AXEL JOHNSON",
  "Axell",
  "axell wireless",
  "AXESS",
  "Axess All",
  "AXESS FOR ALL",
  "Axess Lifts",
  "AXESSID",
  "AXFLOW LTD",
  "AXIA FANS-CRAWLEY",
  "Axial",
  "AXIAL FANS LTD",
  "AXIL",
  "Axim",
  "Axio",
  "Axiom",
  "Axis",
  "AXIS SECURITY",
  "Axis Stanley",
  "Axminster",
  "AXMINSTER PLUS POWER TOOL CENTER",
  "AXMINSTER TRADE",
  "AXON",
  "Axtec",
  "AXTM",
  "axtralis",
  "Axus",
  "AXXESS",
  "AXXESS ID",
  "Axxessor",
  "AYLESBURY FIRE SYSTEM",
  "azkoyen",
  "AZOTO",
  "AZTEC CONTROLS",
  "B & P CONTROLS",
  "B & P MACKLIN",
  "B & P Security",
  "b & q",
  "B & R",
  "B & S Design",
  "B & S GROUP",
  "B & S GROUP LTD",
  "B & V WATER TREATMENT",
  "B & V WATER TREATMENTS",
  "B A Engineering ?",
  "B AND T SPARKS",
  "B Dixon-Bate Ltd",
  "B Engineering",
  "B J CONTROLS PANEL",
  "B J CONTROLS PANEL LTD",
  "B Mc Donnell",
  "B MCDONALD LTD",
  "B McDonell",
  "B McDonnell",
  "B METER",
  "B Meters",
  "B VENT",
  "B&F ELECTRICAL DISTRIBUTORS",
  "B&J Controls",
  "B&P CONTROLS",
  "B&S CARTWRIGHT",
  "B&S DESIGN SERVICES LTD",
  "B&S Group",
  "B&T",
  "B&V",
  "B&W Lift Services",
  "B.B.I. CONTROLS LTD.",
  "B.C",
  "B.E.S",
  "B.R.S PARCELS LTD",
  "B@SIX",
  "ba",
  "BA ?",
  "BA ENGINEERING",
  "BA Enginering ?",
  "BA ENGINRING",
  "BA.M.E",
  "BABACOCK WANSON",
  "Babcock",
  "BABCOCK ROBEY Ltd",
  "Babcock transformers ltd",
  "babcock wanson",
  "BABCOCK WANSON LTD",
  "BABCOCK WANSON UK",
  "BABCOCK WANSON UK LTD",
  "Babcock-BSH",
  "BABCOCKS",
  "BABEL",
  "Babiche",
  "BABS",
  "BABY BLISS",
  "Baby Star Cat 1",
  "BABYBELL",
  "BAC",
  "BAC A Graisse",
  "BAC COOLING TOWERS",
  "BAC VIEW2",
  "Bacco",
  "BACK TEKNIGAS",
  "BACKER",
  "BACKER BACKERSAFE",
  "Back-UPS",
  "BACO",
  "BACON",
  "BACVIEW",
  "BAD",
  "BADGER",
  "Badger contols ltd",
  "Badger Control",
  "Badger Controls",
  "Badger Controls Ltd",
  "BADGER CONTROLS LTD.",
  "BAE Berliner",
  "BAEGE",
  "BAGER",
  "BAHCO",
  "BAHCO AHU4",
  "BAHCO VENTILATION",
  "BAHCO VENTILATION LTD",
  "Bailey",
  "BAILEY & MACKEY LTD.",
  "Bailey Sereg",
  "Bailey.",
  "BAIMATIC",
  "BAKE & BITE",
  "Bake Master",
  "Baker Pride Oven",
  "BAKERMAT",
  "Bakers Pride",
  "Baldor",
  "BALDWIN",
  "baldwin & boxall",
  "BALDWIN AND FRANCIS",
  "Baldwin Boxall",
  "BALDWIN BOXALL (AKUSTA IFE LTD.)",
  "BALDWIN BOXALL COMMUNICATION",
  "BALDWIN BOXALL VIGIL",
  "Baldwin LL",
  "BALDWIN BOXALL",
  "Baldwinbox",
  "Balfarfelds",
  "BALFOUR KILPATRICK",
  "BALFUR",
  "Ballard Electric",
  "Balmatc",
  "balmatic",
  "BALMATIC Lift",
  "BALMIRALMTANKS",
  "Balmoral",
  "Balmoral Chemical Tanks",
  "Balmoral Section Tanks",
  "BALMORAL SYSTEMS LTD.",
  "Balmoral Tanks",
  "BALMORL TANKS",
  "BALS",
  "Bals Electrical",
  "Bals Industrial",
  "BALTAIRCO LTD",
  "BALTICARE",
  "Baltimore Aircoil",
  "BALTIMORE AIRCOIL INTERNATIONAL",
  "BALTIMORE AIRCOIL LTD.",
  "Baltur",
  "BAM",
  "Bambi",
  "Bambi Air Compressors",
  "Bambi Air Compressors Ltd",
  "BAMBI AIR COMPRESSORS LTD.",
  "BAMBI AIR COMPRESSORSLTD.",
  "BAMBI SILENT COPRESSOR",
  "Banaco",
  "Banco",
  "BANCO VENTILATION",
  "BANDALL",
  "BANDINI SCALDABAGNI",
  "BANDIT",
  "Bandit uk",
  "BANDITUK",
  "BANELEC",
  "Banham",
  "Banic",
  "BANICO",
  "BANIDINI SCALDABAGNI",
  "BANINCA",
  "Bankell",
  "BANNER",
  "BANNICO",
  "BAPPEL",
  "Bar Aid",
  "Bar Line",
  "bar o matic",
  "bar tec",
  "BARACADE",
  "BARBEA COLMAN COMPANY",
  "Barber coleman",
  "Barber Colman",
  "BARBER COLMAN COMPANY",
  "Bardeau",
  "BARDECK LIFT LTD",
  "BARDECK LIFTS",
  "Bardic",
  "BARDIC BY HONEYWELL",
  "BARDIC EQUPIMENT",
  "Bardic System",
  "Bardic Systems",
  "BARDIC SYSTEMS LTD.",
  "BARDUCT",
  "BARDUCT BUSBAR SYSTEMS",
  "BARDUCT LTD",
  "Barduct UK Ltd",
  "Barductn",
  "Barfield",
  "BARFIELD AIR AND WATER TREATMENT",
  "BARFIELD AIR AND WATER TREATMENTS",
  "BARFIELD WATER TREATMENT",
  "BARFLO",
  "baric",
  "BARIC PUMP LTD",
  "Baric Pumps",
  "BARIC PUMPS LTD",
  "Baric Thermopak 2000",
  "BARKEL",
  "BARKELL LTD",
  "Barker",
  "BARKER & EVANS",
  "BARKNELL",
  "Barkway",
  "BARKWELL",
  "BARLASS ROLLER SHUTTERS",
  "BARLETT",
  "barlf",
  "Barline",
  "Barlow Hepburn",
  "Barloworld",
  "Barnes and Elliott",
  "BARNFIELD",
  "Baro",
  "Baro lighting",
  "BARR & WRAY",
  "BARR & WRAY LTD",
  "barr + wray ltd",
  "barr+wray",
  "BARRACADE",
  "Barrel",
  "Barrell",
  "Barrhead",
  "Barricade",
  "BARRICADE ROLLER SHUTTERS",
  "Barrier",
  "Barron & Shepherd Ltd.",
  "Barrow & Brooks",
  "BARROW ELECTRICAL",
  "Barry Bros Security",
  "BARSON LIFTS",
  "BART",
  "Bartec",
  "BAR-TEC",
  "BARTLETT",
  "BARTLETT & SON LTD",
  "Bartlett Catering",
  "BARTLETT CATERING EQUIPMENT LTD",
  "BARTLETT REFRIGERATION",
  "Bartoli",
  "Bartsche",
  "BARTSCHER",
  "BARWIT",
  "BARWIT CONTROL SYSTEM",
  "BARWIT CONTROL SYSTEMS",
  "BARWITT",
  "BARWITT CONTROLS",
  "BARY POWER SYSTEMS LTD",
  "BARYLETT",
  "BAS",
  "BAS MASTER",
  "basam",
  "Base",
  "BASE CONTROL SYSTEMS",
  "Base lighting and fire",
  "BASITA UMBRA",
  "BASKIN ROBINS",
  "BASRR & WRAY",
  "BASSINOX",
  "bassman",
  "Basta",
  "BASTIA UMBRA",
  "BASTIA UMBRA (PG) ITALY",
  "Basts",
  "BATT-C-MICROPOWER",
  "BATTERIE RADIANT",
  "BATTERY SERVICES INTERNATIONAL",
  "batton lampholder",
  "BAUCH",
  "Bauer",
  "Bauknecht",
  "Baumatic",
  "Baumuster",
  "Baunacht",
  "Bavak",
  "Bax",
  "Baxall",
  "BAXI",
  "Baxi Bermuda",
  "Baxi Combi",
  "Baxi Commercial",
  "BAXI-POTTERTON",
  "Bayham Ltd",
  "BAYHAM LTD.",
  "Bayley",
  "Baymak",
  "BAYROL",
  "BBA",
  "BBC",
  "BBC ELETTROPOMPE",
  "BBC FIRE PROTECTION LTD",
  "BBI Controls",
  "BBM",
  "bbm weston",
  "BBR",
  "BBT",
  "BBURROWS ELECTRICAL",
  "BC Lifts",
  "BC TECHNOLOGY",
  "BCA",
  "BCAS",
  "BCC",
  "BCET LIMITED",
  "BCS",
  "BCS LTD",
  "BCT",
  "bctechnology",
  "BCU",
  "BD RMG",
  "BDH",
  "BE",
  "BE Fire & security",
  "BE WATCH",
  "beacon",
  "Beacon & B.T.H Motor",
  "BEACON CONTROL ASSOCIATES LTD",
  "Beacon Madaes",
  "Beacon Medaes",
  "BEACONMEDAES",
  "BEAM BAKING SYSTEMS LTD.",
  "BEAMA",
  "BEAMLITE",
  "Bear Wodschow",
  "BEARWOID ENGINEERING LTD",
  "BEARWOOD ENGINEERING LTD",
  "BEASAM",
  "Beasham",
  "Beasham ?",
  "Beaston Heating Ltd",
  "BEATSON",
  "Beaufort",
  "Beaumont",
  "Beaumont (UK) Ltd",
  "Bebinca",
  "Beck Hall",
  "Beck Ind",
  "Becker",
  "BECKER (SHJ HOSPITAL PIPELINES)",
  "Becker Driver 27A",
  "BECKER EQUIPMENT & LIFTS LTD",
  "BECKER GERMANY",
  "BECKER LIFTS",
  "BECKER LIFTS LTD",
  "BECKETT",
  "Becko",
  "BECKTECH",
  "BEDE TRANSFORMER",
  "Bede Transformer Co.",
  "BEDE TRANSFORMET Co. Ltd",
  "Bee Tee",
  "BEE TEE ALARMS",
  "Bee Tee Alarms Ltd",
  "BEEL INDUSTRIAL BOILER PLC",
  "BEEL INDUSTRIAL BOILERS",
  "BEEL INDUSTRIAL BOILERS LTD",
  "BEER",
  "BEER MASTER CLIMATE CENTRE",
  "Beesam",
  "BEESTON",
  "BEESTON HEATING",
  "BEESTON HEATING LTD.",
  "Beeston Heatong",
  "BEEWOOD FANS",
  "Beghelli",
  "beh",
  "Beha",
  "BEHIND CLOSED DOORS LTD",
  "BEIJER ELECTRONICS",
  "Bejam",
  "BEKO",
  "BEKOMAT",
  "BEKOSPLI",
  "BELAIRE HEATING & AIR CONDITIONING",
  "Belcher",
  "BELGCAST",
  "belgicast",
  "Belgrado",
  "Belimo",
  "BELIMO CONTROL WITH SEITRON INDICATOR",
  "BELKIN",
  "Bell & Gossett",
  "Bell 3",
  "Bell Co",
  "Bell Control Solutions",
  "Bell Control Solutions Ltd",
  "Bell Flow Systems",
  "Bell issimo",
  "BELL SECURITY",
  "Bell&Gossett",
  "BELLA VISTA",
  "Bellco Biotechnology",
  "Belle",
  "Belling",
  "BELLING-LEE",
  "BELLINI",
  "BELLINNO",
  "BELLISSIMO",
  "BELLOWS",
  "Bellringer",
  "BELLS ATALANTA",
  "Bellview",
  "BELLWATER CONTRACTS LTD",
  "BELLWRAS",
  "BELMONT",
  "BELO & GOSSETT",
  "BELOW GORUND",
  "BELOW GROUND",
  "BELT SANDER UNKNOWN MAKE",
  "Belton Brady",
  "Beluk",
  "BELUK POWER QUALITY",
  "BEMCO",
  "BEMIS",
  "BEMS Ltd",
  "Bemson",
  "Benchmark",
  "Benden",
  "Bender",
  "BENDIX",
  "Benefit",
  "Benham",
  "BENHAM & SONS LTD.",
  "BENICA",
  "Benin CG",
  "Beninca",
  "BENINCAY",
  "Bening",
  "Beninka",
  "BENNETT & FOUNTAIN",
  "BENNICA TECHNOLOGY",
  "BENNIE",
  "Benning Power Electronics",
  "BENNING POWER ELECTRONICS UK LTD",
  "Bennings",
  "Benq",
  "BENROSS",
  "Benson",
  "BENSON HEATING",
  "BENSONS",
  "BENSONS (AMBIRAD)",
  "BENT AXIA",
  "BENT AXIS",
  "Bental Security",
  "BENTE SYSTEMS LTD",
  "BENTLEY FILTERS LTD",
  "BENTLY",
  "BENTONE",
  "Benzing",
  "bepa",
  "Beresford",
  "BERESFORD PUUMPS LTD",
  "Berg",
  "BERG & SPITZEN",
  "BERGAIR",
  "BERGDORF",
  "Beringer",
  "BERINGER HYDAULIK AG",
  "Beringer Hydraulic AG",
  "BERINGER HYDRAULICS",
  "BERKEL",
  "BERKELEY",
  "Berkeley control group",
  "BERKELEY ENTERPRISES",
  "BERKELEY ENVIRONMENTAL SYSTEMS",
  "BERKELEY SERVICES",
  "Berkeley System Fantini Cosmi",
  "Berkely Environmental Systems",
  "BERKLEY ENVIRONMENTAL SYSTEMS LTD.",
  "BERKSHIRE CONTROL SYSTEMS LTD",
  "BERLINGER",
  "Berlino",
  "Bermad",
  "BER-MER",
  "Bermuda",
  "BERONWORTH",
  "BERONWORTH STANDBY SYSTEMS LTD",
  "BERRIDGE",
  "Berry",
  "BERRY & WARMINGTON",
  "BERRY SERVICES",
  "BERTELLO",
  "BERTOLOTTI S.P.A",
  "Bertone",
  "BERTO'S",
  "BES",
  "BES BERKLEY ENVIRONMENTAL SYSTEMS PLC.",
  "BES LTD",
  "BES LTD.",
  "BES Midlands",
  "BES.  BERKELEY ENVIRONMENTAL SYSTEMS LTD",
  "BESAM",
  "BESAM ABLOY",
  "BESAM ASSA ABLOY / GEZE SLIMDRIVE",
  "Besam autodoor",
  "BESAM/ASSA ABKOY",
  "BESAM/ASSA ABLOY",
  "BESAM-ASSA ABLOY",
  "besan",
  "Besem",
  "BESHAM",
  "besooke",
  "Bespkoe",
  "Bespok",
  "Bespoke",
  "Bespoke aits inside a box",
  "BESPOKE STAIRWAY",
  "Bespokge",
  "Bessam",
  "BESSAM /ASSA ABLOY",
  "bessm",
  "Best",
  "BEST POWER",
  "BESTHATCH",
  "Beston",
  "BESTPOKE",
  "BESTWAYS",
  "Besum",
  "BETA ALARM SYSTEMS",
  "BETAFENCE",
  "BetzDearborn",
  "BEVEILIGEN TEGEN",
  "BEVENT RASCH",
  "BEVI",
  "Bevi Wright",
  "BEWATEOR",
  "Bewater",
  "BEWATIR",
  "Bewator",
  "Bewleys",
  "Bexo",
  "BF Meters",
  "BFI",
  "BFT",
  "BFY lighting",
  "bg",
  "BG CONTROLS",
  "BG ENERGY SOLUTIONS LTD",
  "BH Sala",
  "B'ham Air Conditioning",
  "BHD",
  "BHI",
  "BHM Medical",
  "BHM Medical / Arjo",
  "BI FOLD ROLFE LTD",
  "BI PARC LTD",
  "Bianco",
  "biasi",
  "BIB COCHRAN LTD",
  "BICC",
  "BICC Bryce capacitors Ltd",
  "BICKNELL CONTROL SYSTEMS LEEDS",
  "BID FROUP",
  "BID Group",
  "BIDDEL",
  "BIDDELS",
  "BIDDLE",
  "BIDDLE AIR SYSTEMS",
  "BIDDLES",
  "Bidex",
  "Bifinetti",
  "Bi-Fold Rolfe Ltd",
  "big brute",
  "BIG DIPPER",
  "BIG HANNA",
  "BIGG",
  "BIGNEAT LTD",
  "BIL",
  "BILCO",
  "BILCO UK LTD",
  "Bilfinger",
  "Bi-Line",
  "BILL",
  "BILL MASTERLINK",
  "Bill Silverlink",
  "bill sovereign",
  "BILL SWITCHGEAR LTD.",
  "BILL TALISMAN PLUS",
  "BILL WWITCH GEAR",
  "Bill630",
  "BILLI",
  "BILLOTTERMILL",
  "Bilson",
  "Bimeters",
  "Binder",
  "Bingham rail",
  "BIO",
  "BIO COLD",
  "Bio Lab",
  "BIOCOLD",
  "biocote",
  "Biodex",
  "BIODRIER",
  "biodrier.com",
  "Bioenergy Technology Ltd",
  "BIOMAT 2",
  "BIOME",
  "Bionaire",
  "BIOQUELL",
  "BIOQUELL (UK) LTD",
  "Bira",
  "Biral",
  "BIRAL PUMPS",
  "Birchall",
  "BIRCHALLS",
  "BIRCHER",
  "Birchtold",
  "Birchwood",
  "Bird",
  "Birky",
  "Birla",
  "Birmingham",
  "BIRMINGHAM AIR COND LTD",
  "Birmingham Air Conditioning",
  "Birmingham Catering Equipment Ltd",
  "BIRMINGHAM FANS UK LTD.",
  "BIRMINGHAM PUMP SUPPLIES LTD",
  "BIRMINGHAM TRANSFORMERS",
  "BIS DOOR SYSTEMS",
  "BISHAMON",
  "Bishmon",
  "BISHOP LIFTING BRISTOL",
  "BISLEY",
  "Bitcino",
  "BITRON VIDEO",
  "BITZER",
  "BITZER INTERNATIONAL",
  "BITZER UK",
  "BI-WAY",
  "BJ",
  "BJBC CONTROLS",
  "bk",
  "BK FUSSIONS",
  "BK SWITCHCONTROLS LTD",
  "BkackTeknigas",
  "BKAKLEY",
  "BKG",
  "BK-G4",
  "BKI",
  "BKO",
  "BLA",
  "Black",
  "Black & Decker",
  "BLACK & WHITE",
  "Black and Decker",
  "BLACK AUTO CONTROLS",
  "Black Automatic Controls",
  "Black Automatic Controls Ltd",
  "Black Controls",
  "BLACK POWER SEAT",
  "BLACK SPUR HOUSE WARES",
  "BLACK TECHNIC",
  "BLACK TECHNIGLASS",
  "Black technique",
  "Black Teknica",
  "Black Teknicas",
  "BLACK TEKNIGAS",
  "BLACK TEKNIGN",
  "BLACK TEKNING",
  "BLACK TLEKNIGAS",
  "Black UPS",
  "BLACK/Tecnigas",
  "BLACK/TEKNIGAS",
  "BLACKBIRD STARLING ENGINEERING",
  "BLACKBURN STARKING ENTINEERING",
  "BLACKBURN STARLING",
  "BLACKBURN STARLING ENGINEERING",
  "BLACKBURN STARLING ENGINEERING LTD",
  "BLACKENHEATH LIGHTING",
  "Blackey",
  "BLACKGAS",
  "blackheart",
  "Blackheat",
  "BLACKLEY",
  "BLACKLEY ELECTRICS",
  "blacklight",
  "Blackman",
  "Blackmer",
  "Blacks",
  "Blacks Power Valve",
  "BLACKS TECHNIGAS",
  "Blacks Teknigas",
  "BLACKTEC",
  "BLACK-TEKINIGAS",
  "BLACK-TEKNIGAS",
  "BLAEKLEY",
  "Blagdon",
  "Blagdon pump",
  "BLAIKESLEE",
  "BLAIN",
  "BLAK TEKNIGAS",
  "BLAKE",
  "Blakeley",
  "Blakell",
  "Blakely",
  "Blakeslee",
  "BLAKEY",
  "BLAKLEU",
  "BLAKLEY ELECTRICA",
  "BLAKLEY ELECTRICAL",
  "BLAKLEY ELECTRICS",
  "BLAKLEY ELECTRICS LTD.",
  "BLAKLEY ELECTRICS.",
  "BLAKLEY ELECTRONICS",
  "BLANCO",
  "BLANDFORD",
  "Blane Valley",
  "BLASI",
  "BLAST CHILLER",
  "BLAUBERG",
  "BLE",
  "BLE SMOKE AND FIRE CURTAINS LTD",
  "BLE SMOKE&FIRE CURTAINS",
  "bleakley electric",
  "Blektcgas",
  "BLENDER COMMERCIAL",
  "BLENDTEC COMMERCIAL",
  "BLICK",
  "BLINDEISTUNGSREGLER",
  "Blitzer d'automatismes temporises",
  "Blitzer kuhmaschinebau. GMBH",
  "BLIZZARD",
  "blkley",
  "BLOCK",
  "BLODGET",
  "Blodgett",
  "Blomberg",
  "BLOOMFIELD",
  "BLOUNT",
  "Blount shutters ltd",
  "BLOWTHERM",
  "Blue",
  "Blue and Lid",
  "BLUE BOX",
  "BLUE BOX GROUP",
  "Blue Composite Pentaire",
  "BLUE GIANT",
  "Blue Giant Equip.",
  "Blue Line Locking / Vidi-coms Data rack",
  "Blue Mountain",
  "Blue Pentaire",
  "Blue Ray",
  "BLUE RIVER CONTROLS LTD",
  "BLUE SEAL",
  "BLUE SEAL/MOFFAT",
  "Blue Serl",
  "BLUE STAR",
  "BLUE STEEL",
  "BLUEBIRD",
  "BLUEBIRD SECURITY SYSTEMS",
  "BLUEBOX",
  "BLUEDRY",
  "Blueline",
  "BLUEMAX",
  "Blue-Point",
  "BLUERAY",
  "BLYSS",
  "BM Controls",
  "BM Electronics",
  "BMC",
  "BMEM",
  "BMeters",
  "B-METERS",
  "BMI Engineering",
  "BMM",
  "BMM HEATERS",
  "BMM WESTON",
  "BMS",
  "BMS CONTROLLED SOLUTIONS",
  "BMSi",
  "BN THEMIC",
  "BN Thermatic",
  "BN THERMIC",
  "BOAS",
  "BOAS 12/2.7",
  "Bobeck",
  "BOBPICK",
  "Bobrick",
  "BOC",
  "BOC ENERGY LTD",
  "BOC GASSESS",
  "BOCCIOLONE ANTINCENDIO",
  "Boddingtons",
  "boddingtons electrical",
  "BODET",
  "BODS",
  "BODYGUARD",
  "Body-Solid",
  "Bofa",
  "BOGE",
  "Boge Kompressor",
  "BOHN",
  "BOILER",
  "BOILER AXUILLARY SERVICES",
  "BOITHAM MECHCANICAL HANDLING YORK",
  "Boitier",
  "BOKO",
  "Bolero",
  "Boll",
  "Bolt & Brady",
  "BOLT GATE",
  "BOLTON",
  "BOLTON & BRADY",
  "BOLTON & BRADY LTD.",
  "BOLTON AND BEADY",
  "bolton and brady",
  "BOLTON BRADY",
  "BOLTON BRADY INDUSTRIAL DOORS",
  "Bolton Door",
  "BOLTON DOORS",
  "BOLTON GARE CO",
  "BOLTON GATE",
  "Bolton Gate Co",
  "BOLTON GATE CO.",
  "BOLTON GATE CO. LTD",
  "BOLTON GATE COMPANY",
  "BOLTON GATES Co.",
  "Bolton Shutter Door",
  "Bolton Shutter Doors",
  "BOLTON SHUTTERS",
  "Bolton Sutter Door",
  "Bolton And Bradley",
  "Bolzoni",
  "Bona Long & Co",
  "BONANI",
  "BONAR BRAY LTD",
  "Bonar Long",
  "BONFIGLIOLI GROUP",
  "BONFIGLIOLI RIDUTTORI",
  "BONGIOANNI CUNEO",
  "BONIOHANI",
  "BONNET",
  "BONNETT",
  "Bonni",
  "BONORA",
  "BONUS",
  "Bonza",
  "BONZER",
  "BOOM AND WADE",
  "boom edem",
  "Boomerang",
  "BOON ADAMS",
  "BOON EDAM",
  "Boon Edam Ltd",
  "Boon Edam",
  "BOORI",
  "BOOSAN",
  "BOOST",
  "Boost-A-Break",
  "booster-a-break",
  "BOOTH",
  "Booth Imperial Limited",
  "BOOTH TRANSFORMERS LTD",
  "BOOTHAM MECHANICAL HANDLING YORK",
  "BOOTON GATE",
  "BOPPAS",
  "Bora",
  "BORAG LTD",
  "BORAL",
  "BORCIET",
  "Border city windows",
  "Bordet",
  "BORER",
  "borg",
  "BORG & OVERSTREAM",
  "BORG & OVERSTRÖM",
  "BORG AND NORDSTROM",
  "BORG AND OVERSTROM",
  "BORG AND OVERSTRÖM",
  "BORG OVERSTROM",
  "BORG&OERERSTROM",
  "BORG&VERSTOM",
  "BORGELOCKS.com",
  "BORGER",
  "Borglocks",
  "BORGLOCKS.com",
  "BOROLABS",
  "BORRI",
  "Borrick",
  "BORRO",
  "Borsatec",
  "BOSCH",
  "Bosch Classixx",
  "BOSS",
  "BOSS   18 LITRE   BOSS PART CODE 52210028",
  "BOSS   5LTR",
  "boss 111s",
  "Boss 11L",
  "BOSS AMS PPS",
  "Boss DN 25",
  "boss mbc",
  "BOSS Pressfill PF",
  "BOSS THERM",
  "BOSS/DAB",
  "Bossmix",
  "Bostwick",
  "Bostwick Doors",
  "BOSWICK DOORS",
  "BOT",
  "Bottle Type Maestro",
  "BOUGHTON",
  "BOULTER",
  "BOULTER BOILERS",
  "BOULTER BUDERUS",
  "BOUNDARY GATE AND BARRIER (CONTRACTS)",
  "Bourgeat",
  "Bouter Boilers",
  "bovelenta",
  "Bovey Tracey ACV",
  "BOVOLENTA",
  "BOWDEN",
  "Bower Fuller",
  "Bowers",
  "BOWERS ELECTRICAL",
  "BOWERS ELECTRICAL LTD",
  "BOWERS ELECTRICALS",
  "BOWLING",
  "BOWMATIC",
  "Bowthorpe Emp",
  "BOX LOCKED",
  "BOXED IN CUPBOARD",
  "Boxed in not visable",
  "Boxed in not visiable",
  "BOXER LABORTORY EQIUPMENT LTD",
  "BOXFORD",
  "BOXX",
  "BOYSEE INDUSTRIES",
  "BP",
  "BP POWER PLUS",
  "BP SECURITY",
  "BP SOLAR",
  "BPC",
  "BPG",
  "BPS INTEGRATED SECURITY SYSTEMS",
  "bpt",
  "BPT Automation",
  "BPT GROUP",
  "BPT SECURITY SYSTEMS LTD",
  "BQT",
  "bqt.",
  "BR",
  "BRABANTIA",
  "BRACKENHEATH BRITICENT",
  "BRADBURY",
  "BRADBURY SECURITY",
  "BRADFORD HEATING LTD",
  "BRADFORD LIFTS",
  "BRADFORD-WHITE",
  "bradley",
  "BRADLEY ENGINEERING",
  "BRADSHAW",
  "Bradshaw Electric",
  "BRADY AND CO,MANCHESTER",
  "BRADY AND CO,MANCHESTER.",
  "Braithwait",
  "BRAITHWAITE",
  "Braithwaite Engineers Ltd",
  "BRAITHWAITHE ENG LTD",
  "BRAKEL",
  "BRAKEL AIRVENT LTD",
  "Brakes",
  "Bramidan",
  "BRANDEAS",
  "Branded Tipperary",
  "Branded Tipperary Natural Mineral water",
  "Branded Wild about Water",
  "Brandenburg",
  "BRANDES",
  "BRANDMELDCENTRALE",
  "BRANDMELDETECHINK",
  "Brandon Medical",
  "BRANDON MEDICAL",
  "brand-rex",
  "BRANDSCHUTZTECHNIK",
  "BRANDT",
  "BRASILIA",
  "Brasilllia",
  "Brass",
  "BRAUER ?",
  "BRAVI",
  "Braviilor Bonamet",
  "BRAVILOR",
  "BRAVILOR BONAMART",
  "Bravilor Bonamat",
  "BRAVILOR BONAMAT   VHH",
  "Bravilor Bonimat",
  "BRAVILOR-BONAMAT",
  "Bravo",
  "bravolor bonamat",
  "BRAY POWER SYSTEMS",
  "BRAY POWER SYSTEMS LTD.",
  "Brdic",
  "Breakell lifts ltd",
  "BREEZA",
  "BREEZA FAN.",
  "Breezair",
  "BREFCO",
  "BREFCO (UK) LIMITED",
  "Breidert",
  "BREMA",
  "Bremco Enginering",
  "BREMEHA",
  "BREMMER",
  "Bremshey",
  "Brendeck",
  "Brentford",
  "Brentford Electric",
  "BRETA",
  "Bretford Electric",
  "BREVILLE",
  "BRI PARC LTD",
  "BRIA",
  "brian donkin rmg",
  "Bricknell control systems limited",
  "BRIDGE CATERING FABRICATION",
  "Bridge Flue",
  "Bridge Flue System",
  "Bridge Flue Systems",
  "BRIDGEHOUSE LTD",
  "Bridgeport",
  "BRIDGESTONE",
  "BRIDGMAN",
  "BRIDGMAR",
  "Bridgport",
  "BRIDHE FLUE SYSTEMS",
  "BRIDHESTONE",
  "BRIDON",
  "Bridon Ropes",
  "BRIERLEY MACHIME TOOLS",
  "Briggs & Forrester",
  "BRIGGS of BURTON",
  "Bright Water",
  "BRIGHTSIDE",
  "Briglec Ltd",
  "BRIGNONE",
  "BRIGTON",
  "Brimar",
  "Brimar Plastics",
  "BRIMAR TANKS LIMITED",
  "Brimar tanks ltd",
  "BRIMSAR",
  "Brisol Maid",
  "Bristan",
  "Bristol",
  "BRISTOL BABCOCK",
  "Bristol Industrial & Research Associates",
  "Bristol Maid",
  "Bristol Main",
  "Bristol Management System.",
  "BRISTOL MANAGEMENT SYSTEMS",
  "Bristol Transformers Ltd",
  "Briston",
  "BRIT",
  "BRIT AIR",
  "BRITA",
  "BRITA WATER TECHNOLOGY",
  "BRITAINIC LIFTS",
  "Britania",
  "britanic",
  "Britannia",
  "Britannia engineering resource",
  "BRITANNIA FIRE LTD",
  "BRITANNIA FIRE TECHNOLOGY",
  "BRITANNIA HYGIENE",
  "Britannia Kitchen Ventilation",
  "BRITANNIA LIFT LTD",
  "BRITANNIA LIFT LTD.",
  "Britannic Lift Company",
  "BRITANNICK ENGINEERING",
  "BRITEWATER LTD",
  "BRITEX",
  "briticent",
  "Britih central electrical",
  "BRITISH CENTRAL ELECTRICAL LTD",
  "BRITISH ECLECTIC METERS",
  "BRITISH ELECTRICAL",
  "British Gas",
  "BRITISH GAS AND OIL BURNERS",
  "BRITISH GAS AND OIL BURNERS LTD",
  "BRITISH GENERAL",
  "BRITISH ORGAN BLOWING",
  "British Standard Security Control",
  "BRITISH STEEL",
  "BRITISH TURNTABLE CO. LTD.",
  "Britmac",
  "BRITON",
  "Britony",
  "BRITRUCK",
  "BRITTA",
  "Brittania ?",
  "Brittania Kitchen Ventilation",
  "BRITTANIA SECURITY SYSTEMS LTD",
  "Brittanic",
  "BRITTANY COMBI",
  "britton",
  "brittony",
  "BRITVIC",
  "BRMEHA",
  "Broadcrown",
  "BROADCROWN LTD",
  "BROADFEN",
  "BROAG LTD",
  "Broag-Remeha",
  "BROCKHOUSE",
  "BROCOL",
  "BRODERICKS",
  "Brodex",
  "BROEN",
  "Broga-Remeha",
  "broline",
  "BROMINATOR",
  "BROMWADE",
  "BRON",
  "Bronx",
  "Bronze",
  "Bronzoni",
  "BROOK",
  "Brook & Holden",
  "Brook &Crompton",
  "BROOK CROMPTON",
  "BROOK CROMPTON CONTROLS",
  "BROOK CROMPTON INVENSYS",
  "Brook Crompton Motor",
  "Brook Crompton Parkinson",
  "BROOK CROMPTON PARKINSON MOTOR",
  "BROOK CROMPTON PARKINSON MOTORS",
  "BROOK CROMPTON PARKINSON MOTORS.",
  "BROOK CROMTON",
  "BROOK HANSEN",
  "Brook Holden",
  "BROOK INVENSYS CROMPTON",
  "Brook motors ltd",
  "Brook Security",
  "Brook Crompton",
  "Brook Crompton Parkinson",
  "Brook Crompton/Airscrew Holden",
  "BROOKE COMPTON",
  "BROOKE CROMPTON",
  "Brooke Crompton Huddersfield Uk",
  "BROOKE CROMPTON.",
  "BROOKE HANSEN",
  "BROOKE HOLDEN PULLEN LTD",
  "Brooke Hansen",
  "BROOKENES",
  "BROOKES",
  "BROOKES (OLDBURY) LTD",
  "Brookes Crompton",
  "BROOKES JENN AIR",
  "Brookes Supplies & Service Ltd",
  "BROOKFIELD INDUSTRIES",
  "BROOKHIRST",
  "BROOKHURST",
  "Brooklyn",
  "Brooklyn / McDonnell",
  "Brooklyn ?",
  "BROOKNES",
  "BROOKNES LTD",
  "BROOKNES LTD.",
  "BROOKNESN LTD.",
  "BROOKNESS LTTD",
  "BROOKS",
  "Brooks & Myson",
  "BROOKS CROMPTON",
  "Brooks Hanson",
  "Brooks Jennair",
  "BROOKS MOTORS",
  "Brooks Motors Ltd",
  "BROOKS PUMPS",
  "BROOKS VENTILATION",
  "Broom & Wade",
  "BROOM AND WADE",
  "BROOM WADE",
  "BROOMWADE",
  "BROTHER",
  "BROUGHTON",
  "BROUGHTON CONTROLS LTD",
  "BROWN BOVERI",
  "BROWNINGS",
  "BRSPOKE",
  "BRUNATA",
  "Brunel",
  "Brunel Control System",
  "Brunel Control Systems",
  "BRUNEL CONTROL SYSTEMS LTD",
  "Brunel Controls",
  "BRUNELL SWITCHGEAR",
  "Bruner",
  "BRUNNER & FECHER",
  "BRUNO GENERATORS",
  "Brunswick,Technetics",
  "BRUSA",
  "Brush",
  "BRUSH ELECTRICAL ENGINEERING CO.",
  "BRUSH Switchgear",
  "BRUSH SWITCHGEAR LIMITED",
  "Brush transformers",
  "Brush Transformers Ltd",
  "BRUSH TRANSFORMERS LTD.",
  "BRUYNZEEL",
  "Bryan Dankin RMG",
  "BRYAN DONKIN",
  "BRY-KOL",
  "BRY-KOL AC LTD",
  "BS2",
  "BSB",
  "BSB DAMPERS",
  "BSC",
  "BSF",
  "BSL",
  "bspoke",
  "BSS",
  "BSS (UK) LTD",
  "BSS GROUP PLC",
  "BSS SENTINEL",
  "Bss Uk Ltd",
  "BSS/AMS PUMPS",
  "BSTL",
  "BSW",
  "bsxi",
  "Bt",
  "BT RED CARE",
  "BTI",
  "BTICCINA",
  "BTICINO",
  "BTP",
  "BTR ENVIRONMENTAL",
  "BTU COIL COMPANY",
  "BUCHANAN & CURWEN SECURITY",
  "BUCHER HYDAULICS",
  "BUCHER HYDRAULICS",
  "BUCHER HYDRAULICS AG",
  "BUCK AND HICKMAN",
  "BUCKINGHAM SWIMMING POOLS LTD",
  "Bucon",
  "BUDDLE",
  "BUDERUS",
  "Budorus",
  "Buffalo",
  "Buffalo Forge",
  "buffalo howden",
  "BUFFALOE",
  "BUFFLO",
  "BUGHES & COYLE",
  "Building Automation Solutions",
  "Building Automation Solutions Limited.",
  "Building Automation Solutions Ltd",
  "Building Automation Solutions Ltd.",
  "Building Automation Solutions.",
  "BUILDING CONTROL SPECIALIST",
  "Building Environment Control",
  "BUILDING ENVIRONMENTAL CONTROL",
  "BUILDING SERVICE CONTROLS",
  "building services ltd",
  "BUILDING TECH SYSTEMS",
  "BUILDING TECHNOLOGY SYSTEMS LTD",
  "Building Technology Systemsn/a",
  "Built Environment Engineering",
  "BULEX",
  "BULL DOOR",
  "BULL MOTORS LTD",
  "Bulldog",
  "bulzer",
  "Bunco",
  "BUNDED FUEL TANKS",
  "BUNDMAN",
  "Bunn",
  "BUNNIE",
  "Bunzl Lockhart",
  "BUNZUL LOCKHART",
  "BUOMO",
  "BURCO",
  "Burco Boiler",
  "BURGO",
  "Burlodge",
  "BURMINGHAM FAN LTD",
  "Burnell",
  "BURNELL CONTROL",
  "BURNELL CONTROL SYSTEMS",
  "BURNELL CONTROLS",
  "BURNELL SWITCHGEAR & CONTROL",
  "BURNS WASTE RECYCLING",
  "burnt",
  "Burodge Ltd",
  "BURROW ELECTRICAL",
  "Burrow Electrical Ltd",
  "Burrows",
  "Burrows Electrical",
  "Burrows Electrical Ltd",
  "BURROWS ELECTRICAL LTD.",
  "BURTON SAFES",
  "BURTONWOOD",
  "BURTONWOOD GENERATORS",
  "BURTOWS ELECTRICAL",
  "BUSCH",
  "Bush",
  "BUSH ENGINEERING",
  "Bush Nelson",
  "Busmann",
  "Bussman",
  "BUSSMANN",
  "BUTROWS ELECTRICAL",
  "BUTTERS",
  "BUTTERWORTH",
  "BUTYL",
  "BUTYL DIAPHRAM",
  "Butzbach",
  "BUTZBATCH",
  "BVENT",
  "BW FABRICATION",
  "BWB",
  "BWH MANUFACTURING LTD",
  "BWS",
  "BWS Fire systems",
  "BWS Security",
  "BWT",
  "BWT CLIFF",
  "BWT UK LTD.",
  "BX",
  "By Amex",
  "BY GEORGE",
  "BYBELL",
  "Byco",
  "byran Donkin",
  "BYT Electrical Services",
  "Bywater",
  "C",
  "C - Tec",
  "C & C CATERING EQUIPMENT",
  "C & C INSTALECT LTD",
  "C & K",
  "C & N Control Systems",
  "C & S Control.",
  "C & S Controls",
  "C & S Controls Ltd",
  "C & S CONTROLS LTD.",
  "C and C Catering equipment",
  "c and s",
  "C and S CONTROLS",
  "C L Electrical",
  "c l electrical controls",
  "C MATIC",
  "C POWER SUPPLY.",
  "C R REMEHA",
  "c tec",
  "C&C CATERING",
  "C&C INSTALECT LTD",
  "C&D",
  "C&G PUMPS",
  "C&K",
  "C&K LIFTS",
  "C&M Fire alarms",
  "C&M Lifts",
  "C&N Control Systems Ltd",
  "C&S CONTROLS LTD",
  "C&S CONTROLS LTD.",
  "C&S Shutters",
  "C&W UPS",
  "C.B.Bacon & Son Ltd",
  "C.C.I.A.A",
  "C.C.JENSEN A/S",
  "C.CURE i STAR",
  "C.CURE ISTAR",
  "C.E",
  "C.E.C. LTD",
  "C.F.TAYLOR",
  "C.H. SUPPLIES LTD.",
  "C.H.S. SWITCHGEAR",
  "C.H.S. SWITCHGEAR LTD",
  "C.L. ELECTRICAL",
  "c.tec",
  "c/l electrical controls",
  "C2",
  "C3S SECURITY SYSTEMS",
  "CAAD SYSTEMS",
  "CAB",
  "CAB ITALY",
  "CAB SPA",
  "Cabot industrial doors",
  "CABSPA",
  "CACTUS",
  "CADAMP",
  "CADAMP LTD",
  "CADBURY",
  "CADILLAC",
  "CAE",
  "CAE ELECTRONICS LTD",
  "CAE ELECTRONICS LTD.",
  "CAE ELECTRONICS LTD. (ABB)",
  "CAE ELECTRONICS LTD. (KLOCKNER MOELLER)",
  "CAE ELECTRONIS LTD.",
  "CAERIER",
  "CAF 120 / C2414",
  "CAF 140 / C2414",
  "CAFE BAR",
  "CAFE BAR INTERNATIONAL",
  "Café collection",
  "Cafe d' antone",
  "CAFE D'ATURE",
  "CAFE ESSPRESSO",
  "Café Touch",
  "CAFEBAR",
  "cafemattino",
  "CAFFE D'AUTORE",
  "CAFFÈ D'AUTORE",
  "Caffini Cipriano",
  "Cafina",
  "Cafina Alpha",
  "Caice",
  "Cairn cross civil engineering",
  "Cairn Cross Solutions",
  "Cake-bake uk",
  "cal mag",
  "caladium",
  "CALAMAX",
  "CALBRE",
  "CALDAIE IVAR",
  "caldera",
  "CALDURA",
  "CALDWELL LOCKING",
  "CALE BRIPARC",
  "CALEDONIAN",
  "Caleffi",
  "Calefi",
  "CALIBRA PUMPS",
  "CALL",
  "Call ?",
  "CALL SYSTEMS TECHNOLOGY",
  "CALLAID UK",
  "CALLARD",
  "Calm Control Systems",
  "Calm Control Systems Ltd",
  "CALMAC MANUFACTURING CORPORATION",
  "Calmag",
  "Calmu 3",
  "CALMU3",
  "CALMUS",
  "Calomax",
  "CALOR",
  "Calorex",
  "Calorex Century",
  "CALOREX HEAT PUMP LTD",
  "CALORSCHWANK",
  "CALPEDA",
  "Calpeda Ltd",
  "CALPEDA PUMPS",
  "CALPELA",
  "Calpera Pumps",
  "Calpida",
  "caltech",
  "CALTRONIC",
  "CALVERLEY CONTROLS INSTALATIONS",
  "Cam Industries",
  "Cam Tech Security Ltd.",
  "Cam Tech Security Systems",
  "Cama",
  "Camac",
  "CAMAK",
  "CAMAX",
  "CAMBIRAD",
  "Cambridge Audio",
  "CAMBRIDGE INSTRUMENTS",
  "Cambridge Instruments Ltd",
  "Cambridge Process Controls",
  "Camco",
  "CAME",
  "Camel",
  "CAMELEON UK",
  "Cameo",
  "Cameron Security",
  "Camex",
  "CAMFIL",
  "CAMLAB",
  "CAMPA",
  "CAMPBELL",
  "Campbell control services",
  "Campbell Controls",
  "CAMS FIRE & SECURITY",
  "CAMTECH",
  "CANAIR LTD",
  "CANALETAS",
  "Canalis",
  "Canalis (Schneider)",
  "CANATAL",
  "CANATRON",
  "CANBERRA",
  "CANCEL",
  "Candy",
  "Canham",
  "Canham Controls",
  "CANHAM CONTROLS LTD",
  "CANNON",
  "CANNON HYGIENE",
  "CANNONJT",
  "CANNOT GET ACCESS TO LIFT EQUIP",
  "CANNOT GET ACCESS TO ROOF",
  "Canon",
  "Canon Hygiene",
  "CANSTANT POWER SERVICES",
  "Cant",
  "CANTECH",
  "CAPARI",
  "CAPE",
  "Cape Duraston",
  "CAPITAL",
  "Capital Compactor",
  "CAPITAL COMPACTORS",
  "CAPITAL COOLING",
  "Capital freezer",
  "CAPITAL METERS",
  "CAPITAL SERIES",
  "Capital Water Treatment Ltd.",
  "CAPLE",
  "CAPPA",
  "CAPRI MECHANICAL SERVICES LTD",
  "Capstan motor",
  "CAPTAIR",
  "Caradon Garador Limited",
  "Caradon Heating",
  "Caradon Ideal",
  "CARADON MIRA LTD",
  "Caravaile",
  "CARAVEL",
  "CARAVELL",
  "Caravelle",
  "CARBIS FILTRATION",
  "Carbolite",
  "CARDAX",
  "CARDAX FT CONTROLLER",
  "CARDEN CONTROLS & ELECTRICAL LTD.",
  "CARDEV",
  "CARDIAC SCENCE",
  "CARDIAC SCIENCE",
  "Cardiff Lift Co.",
  "Cardinal Scale",
  "Cardkey",
  "CARE - TECH",
  "CARE ENG.",
  "CARE TECH",
  "CAREL",
  "CAREL HUMI.TECH",
  "CAREL PORKKA",
  "CAREQUICK LTD",
  "Caresafe",
  "CARESTREAM",
  "Caretech",
  "CARE-TECH",
  "CARGO FLEET ENGLAND",
  "Carier",
  "CARILLION",
  "CARILLION BREATHE",
  "CARIMALI",
  "CARLA",
  "CARLEY DRIVES",
  "carlisle refrigeration",
  "CARLISLE SECURITY",
  "CARLL",
  "Carlo Gavazzi",
  "carlton",
  "CARLTON LIFT",
  "CARLTON TECHNOLOGIES",
  "Carlyle",
  "CARMALI",
  "CARMEL POWER PERFECTOR",
  "Carmen",
  "Carnes",
  "CARO",
  "CAROLL & MEYNELL",
  "Caroll & Meyner",
  "CARPANELLL",
  "CARPIGIANA",
  "CARRARA MARTA",
  "CARRARA MATTA",
  "CARRELL",
  "CARRIER",
  "CARRIER COMFORT NETWORK",
  "Carrier.  HOLLAND HEATING.",
  "Carrier-Lift",
  "CARRIER-SANYO",
  "CARRIER-SML",
  "Carrington door engineer",
  "Carrington door engineering",
  "Carrob Controls",
  "CARROB CONTROLS & SERVICES LTD",
  "Carrol & Meynell",
  "carrol &meynell",
  "carrol and meynell",
  "carrol meynoll",
  "CARROL& MEYNELL",
  "Carroll",
  "carroll &  meynell",
  "CARROLL & MENVILLE",
  "CARROLL & MEVNELL",
  "CARROLL & MEYNELL",
  "CARROLL & MEYNELL LTD",
  "CARROLL & MEYNELL LTD.",
  "carroll & newell",
  "CARROLL AND MEYNELL",
  "CARROLL MEYNELL",
  "Carroll&Maynell",
  "Carron",
  "CARTER",
  "Carter / Searle",
  "CARTER HOFFMAN",
  "Carter origin",
  "CARTER REFRIGERATION",
  "Carter synergy ltd",
  "CARTER VOCE ACCESS CONTROL LTD",
  "CARTER-VOCE",
  "CARTIER",
  "CARTWELL DOORS AND SECURITY SOLUTIONS LTD",
  "Carville",
  "CARVILLE SWITCHGEAR",
  "CARVILLE SWITCHGEAR KTD",
  "CARVILLE SWITCHGEAR LIMITED",
  "CARVILLE SWITCHGEAR LTD",
  "CARVILLE SWITCHGEAR LTD. (MEM)",
  "Carville Swithgear",
  "Carvillle Switchgear",
  "CARYAIRE",
  "CAS",
  "CASADIO",
  "Casaire",
  "Casaire Ltd",
  "casalift hoist",
  "CASCADE",
  "CASCATA",
  "CASE",
  "CASE GROUP",
  "Case Security",
  "CASIO",
  "Casit",
  "Caslec",
  "CASS ELECTRONICS LTD",
  "CASTEL",
  "CASTEL FRANCO",
  "Castell",
  "CASTELL SAFETY INTERNATIONAL LTD.",
  "CASTELLAN",
  "castle",
  "Castle Cylinder Company",
  "Castlec",
  "Cat nic ltd",
  "CATA",
  "CATCHPOINT UK",
  "CATCHPOINT UK LTD",
  "CATER ENVIRONMENTAL",
  "Cater zap",
  "CATER-BAKE",
  "CATERFORM",
  "CATERING PRODUCTS LTD",
  "Caterline",
  "CATERLITE",
  "Caterlox",
  "Caterlux",
  "Caterpillar",
  "Caterpiller/piller",
  "CATERSAVE",
  "Caterware",
  "CATERWATCH",
  "CATERZAP",
  "cathederal",
  "Catnic ltd",
  "CAUDA",
  "Caydor",
  "CAYSON ELECTRICS",
  "cb200",
  "cb2000",
  "CBC Design",
  "CBC SECURITY",
  "CBI",
  "CBISS",
  "CBL",
  "cbre",
  "CBS GROUP LTD",
  "CBS-S2H",
  "CC",
  "CC UK",
  "CC-59-3",
  "CCA",
  "CCD PUMP LTD",
  "CCD PUMPS",
  "CCD PUMPS LTD",
  "CCL",
  "CCM Glasgow",
  "CCO STREAM",
  "CCOWN",
  "CCS",
  "CCS REFRIDGERATION LTD",
  "CCT",
  "CCTV",
  "cctv computer",
  "C-Cure",
  "ccv",
  "CDA",
  "CDK",
  "CDL LIFTS",
  "CDR",
  "CDTECHNO",
  "CDV",
  "CDV UK LTD",
  "CDVI",
  "CE",
  "CE GRAND FOS",
  "CE LIFTS",
  "CE REMEHA",
  "CEA",
  "ceab",
  "CEANE",
  "CEASE FIRE",
  "CEATON",
  "CEATON/DORMA",
  "CEB",
  "CEBORA",
  "CEBTRAL",
  "CEC",
  "CECIL W.TYZACK LTD",
  "CED",
  "CED Fabrication",
  "CED Fabrications",
  "CED Fabrications Ltd",
  "CED SF 100",
  "cee",
  "CEE Ltd",
  "CEE NORM",
  "CEE NORM LTD",
  "CEE NOTM",
  "CEE NOTN",
  "CEE UK Ltd",
  "CEEGO",
  "CEENORM",
  "CEF",
  "CEG",
  "CEGELEC",
  "CEH",
  "CEIA",
  "ceia (smiths technology)",
  "CEL",
  "CEL (CONTROL EQUIPMENT LTD.)",
  "CEL ME",
  "Celcius",
  "CELECT",
  "CELELEC",
  "CELL SECURITY LTD",
  "CELLAR KING",
  "CELLARKING",
  "Cellartemp",
  "CELLGUARD",
  "Cello",
  "Celltherm",
  "Celme",
  "Celsius",
  "Celsius Doors",
  "CELSIUS ENERGY CONTROL",
  "Celsius International",
  "CELSUS DRINKS",
  "CelTEL COMPACT",
  "CEM Industries",
  "CEM Systems",
  "CEMA",
  "CEMA Compact",
  "CEMS",
  "census",
  "CENT RESTORE",
  "CENTAB",
  "Centaur",
  "CENTAURO",
  "Center",
  "CENTER BOUND",
  "CENTER BRAND",
  "Center Store",
  "CENTERSTORE",
  "Centra controls ltd",
  "CENTRAL BAYYERY SYSTEM",
  "CENTRAL FAN COLASIT LTD",
  "CENTRAL FANS",
  "CENTRAL FANS - COLASIT LTD",
  "CENTRAL FANS COLASIT",
  "CENTRAL FANS COLASIT LTD.",
  "CENTRAL FANS-COLASIT LTD",
  "CENTRAL FIRE SYSTEMS",
  "Central Lifting Services UK Ltd",
  "CENTRAL NETWORKS",
  "CENTRAL SWITCHGEAR",
  "CENTRALE TECHNIQUE",
  "CentralFans Colasit Ltd",
  "CENTRALINE",
  "CENTRALISED VACUUM SYSTEMS",
  "CENTRE",
  "Centre Brand",
  "CENTRE LINE",
  "CENTRE SECURITY",
  "Centre Store",
  "Centre Switchgear",
  "CENTRE TANK SERVICES",
  "Centric",
  "CENTRIFUGAL",
  "CentriMaster",
  "CENTRIQUIP",
  "Centronic",
  "CENTRONICS",
  "Centrotherm",
  "Centurian Safety Services",
  "Centurion",
  "Centurion 2000",
  "Centuriow",
  "CENTURY",
  "Centutuon",
  "cenvair",
  "CEO HAND DRYERS UK",
  "cep",
  "CER",
  "CERAN",
  "Cerberus",
  "CERBURUS",
  "Cerebrus",
  "cerlikon",
  "Certex",
  "CERTIKEN",
  "CERTIKIN",
  "CERTKIN",
  "CERTKIN INTERNATIONAL",
  "CERTUS",
  "CERTUSS",
  "CERTUSS (UK) LIMITED",
  "CERTUSS (UK) LTD",
  "Cerva",
  "CES",
  "CES GENFAB LTD",
  "CES LTD",
  "cetetherm",
  "CETETHERM/HRS/HAVAC LTD.",
  "CETRONIC",
  "Cetronic Ltd",
  "CETRONIC POWER PRODUCTS",
  "CEWE",
  "CEWE Prometer.",
  "cf roberts",
  "CFE BARTLET",
  "cfpseries",
  "CFS",
  "CFUJITSU",
  "CFW",
  "CG",
  "CGALLENGER",
  "CGD",
  "CGE",
  "CH GRIFITHS & SON",
  "CH Harrington &Co",
  "CH SUPPLIES LTD",
  "CHABERLAIN & HOOKHAM LTD",
  "CHAFFATEAUX AND MAURY",
  "CHAFFOTEAUX",
  "Chaffoteaux & Maury",
  "CHAFFOTEAUX AND MAURY",
  "Chaffoteaux et Maury",
  "CHAFLOTEMAIX ET AUX",
  "Chain and Rooe Supply Co",
  "chalbrook",
  "CHALENGER HANDLING",
  "CHALLENGE",
  "CHALLENGER",
  "CHALON CONTROLS",
  "Chamberlain & Hookam",
  "CHAMBERLAIN & HOOKHAM LTD",
  "Chambrair",
  "Chamelean",
  "CHAMELEON",
  "Chameleon Dx2",
  "CHAMELSON",
  "Champ Design",
  "Champ Design Co",
  "CHAMP DESIGN CO LTD",
  "CHAMP DESIGN COMPANY",
  "Champ Design Co Ltd",
  "Champion",
  "Champion?",
  "CHAMPIONSTREET",
  "CHANA",
  "chanel",
  "Chanel Safety Systems",
  "Chanel security systems",
  "Changshu Tongrun Auto",
  "CHANNEL",
  "CHANNEL SABRE",
  "Channel Safety",
  "CHANNEL SAFETY SECURITY SYSTEMS LTD.",
  "Channel Safety System",
  "Channel Safety Systems",
  "CHANNEL SAFETY SYSTEMS LTD",
  "CHANNEL SAFETY SYSTES",
  "CHANNEL SYSTEMS",
  "channelplus",
  "CHANT",
  "CHAPEE",
  "CHAPER",
  "CHAPPEE",
  "CHAPTER CONTROLS LTD",
  "CHARDER ELECTRONIC CO LTD",
  "CHARGE MASTER",
  "CHARGEMASTER",
  "Chargetec",
  "Charging Solutions",
  "Charles Collinge",
  "Charles Industries",
  "CHARNVEL",
  "CHARNVEL LTD",
  "CHARNVELL",
  "CHARNVELL LTD",
  "CHARNWOOD LIFTS",
  "Charnwood Lifts Ltd",
  "CHARNWOOD LTD",
  "Charot",
  "Charter",
  "CHARTWELL LTD",
  "CHARVEL LTD",
  "CHARVELL",
  "CHARVET",
  "Charwood Lifts",
  "Chase",
  "CHASSIJET",
  "Chassis jet",
  "CHATEAD EAU",
  "Chateaux et Maunay",
  "Chateaux et Moury",
  "Chatwood",
  "CHATWOOD MILNER",
  "CHAUS",
  "Chauvin Arnoux",
  "CHAYSOL",
  "CHE",
  "CHECK PEST CONTROL",
  "CHECKPOINT",
  "CHECKPOINT PLUS",
  "CHEF MASTER",
  "chef quip",
  "Chefair",
  "CHEFMASTER",
  "chefquip",
  "chefquip limeted",
  "CHEFQUIP limited",
  "cheiftain",
  "CHELA",
  "CHELTENHAM CONTROLS",
  "Cheltenham Electrical Control Systems",
  "CHEM AQUA",
  "CHEM RESIST EUROPE",
  "CHEM-AQUA",
  "CHEM-AQUQ",
  "Chemetron",
  "CHEMICAL FANS LTD, SER No 1719",
  "CHEMICAL SUPPORT SYSTEMS LTD",
  "ChemRay",
  "Chemtech electronics ltd",
  "Chemtronic",
  "CHERGUI",
  "Cherry Tree",
  "CHERRY TREE MACHINES LTD",
  "CHERRY/NEC",
  "Cheshire chain",
  "CHESHIRE INDUSTRIAL DOORS",
  "CHESIL",
  "CHESTER",
  "CHESTER CHAIN CO",
  "CHESTERFIELD",
  "CHEVALIER",
  "CHEVNAL",
  "CHIBB",
  "CHICAGO BLOWER",
  "CHICK MASTER",
  "CHIDLOW",
  "CHIEFTAIN",
  "chigo",
  "CHILL MASTER",
  "Chiller",
  "CHILLI",
  "Chilli Pro",
  "CHILLMASTER",
  "chiloride gent",
  "CHILTERN",
  "CHILTERN INVADEX",
  "CHILTON",
  "CHIM",
  "CHINNOR SYSTEM DESIGN",
  "Chint",
  "CHINT SHIELD",
  "chioride gent",
  "CHL Technical",
  "Chloride Bardic",
  "CHLORIDE GENT",
  "Chloride industrial bateries ltd",
  "CHLORIDE INSTALLATION SYSTEMA",
  "Chloride Legg Ltd",
  "Chloride Motive Power",
  "CHLORIDE POWER ELECTRICS",
  "CHLORIDE POWER ELECTRONICS",
  "CHLORIDE POWER PRODUCTION",
  "chloride power protection",
  "Chloride Spegel",
  "CHLORIDE TUDOR",
  "CHLORIDE",
  "Chloridis",
  "Chlorie Legg Ltd",
  "CHLOROXY",
  "CHLOROXY - TECH LTD",
  "Chloroxy Tech Ltd",
  "Chloroxy-Tech Ltd",
  "chlton",
  "CHOICE",
  "CHOICE FIRE & SECURITY PROTECTION",
  "Choise",
  "CHOTU",
  "CHRIST-GmbH",
  "Christie",
  "CHRISTY BROS",
  "CHRISTY LIGHTING LTD.",
  "CHRNVEL LTD",
  "CHROME TOWEL RAIL",
  "CHRYSLER CORPORATION",
  "CHS SWITCH GEAR LTD",
  "CHS Switchgear",
  "CHS SWITCHGEAR LTD",
  "CHS SWITCHGEAR LTD.",
  "CHUBB",
  "CHUBB ALARMS",
  "Chubb Control",
  "Chubb Fire & Security",
  "Chubb Safes",
  "Chubb/Initial",
  "CHUNGSIN",
  "CHURCHES",
  "Churches Fire",
  "CHURCHILL",
  "Churchill ?",
  "CHURCHILL CONTROL SYSTEM",
  "CHURCHILL CONTROL SYSTEMS",
  "CHURCHILL CONTROL SYSTEMS LTD",
  "CHURCHILL CONTROLS SYSTEMS",
  "CHURCHILL ENVIRONMENTAL LTD",
  "CHURCHILL ENVIRONMENTAL SERVICES",
  "Churchill Systems",
  "CHURCHILL-BLENHEIM",
  "CIA",
  "Cianesi Edilio",
  "CIAR",
  "Ciat",
  "CIB Burners",
  "CIB UNIGAS BURNERS",
  "CIBES",
  "CIBES - HISSEN",
  "CIBES LIFT AB",
  "CIBIN",
  "cicsco / dell",
  "CICULATING PUMPS LTD",
  "CID",
  "Cidelcem",
  "Ciebherr",
  "CIG- ARRETE",
  "CIK",
  "Cilmate Electical",
  "CIM",
  "CIMA",
  "CIMBALI",
  "CIMM",
  "CIMM  ESPA",
  "CIMM ESPA",
  "Cimm Grundfos",
  "CIN Systems",
  "CINAUXA",
  "Cintropur",
  "CIR",
  "CIRCONTROL",
  "Circuit Control Services",
  "Circulating Pumps",
  "Circulating pumps ltd",
  "CIRCULATING PUMPS LTD.",
  "CIRCULATINGPUMPS",
  "Circum Ltd.",
  "CIRCUTOR",
  "Cirius",
  "CIRQUA",
  "Cirrus",
  "Cisco",
  "Cissel",
  "Cistermiser",
  "CISTERN",
  "Citadel",
  "CITEC",
  "CITEDEL",
  "CITRAL",
  "CIVCO",
  "Civulation pumps",
  "CJ SECURITY",
  "CJC",
  "CJEC",
  "CK",
  "CK INTERNATIONAL",
  "CL Electrica controls",
  "CL Electrical",
  "cl electrical control",
  "CL Electrical Controls",
  "CL Electrical controls ltd",
  "CL ELECTRICALCONTROLS LTD",
  "CL Electrics",
  "Cl100",
  "CLACK",
  "Clack Coporation",
  "Clack Corp",
  "CLACK DUPLEX REGENERATION SYSTEM 1044-WSI-TT",
  "CLADDED",
  "CLAGE",
  "clake",
  "CLAMITE CONTROL",
  "clarck",
  "Clarion Data Communications Ltd",
  "CLARIS",
  "Clark",
  "Clark door",
  "Clarke",
  "CLARKE AIR",
  "Clarke air industrial",
  "Clarke Air Movement",
  "CLARKE INDISTRIAL",
  "Clarke Instruments",
  "Clarke Instruments Ltd",
  "CLARKE INTERNATIONAL",
  "CLARKE METALWORKER",
  "CLARKE PUMP",
  "Clarke UK",
  "CLARKE WELD",
  "Clarkson",
  "CLARKSON CONTRILS LTD",
  "Clarkson Controls",
  "CLARKSON SAFETY SERVICES",
  "CLASS EQ",
  "CLASSEQ",
  "CLASSFIRE",
  "CLASSIC",
  "Classic basic",
  "CLASSIC COOLING",
  "CLASSIC COOLING LTD.",
  "CLASSIC LIFTS",
  "CLASSIC SPEGAL",
  "CLAUDE LYONS",
  "CLAUDE LYONS LTD",
  "claudegen",
  "CLAUDGEN",
  "CLAUGEN",
  "Clayton",
  "CLAYTON CRANE & HOISTS CO. LTD.",
  "CLAYTON CRANE & JOIST LTD",
  "CLAYTON THERMAL PRODUCTS",
  "CLCE 04--4",
  "CLCE 05-05",
  "CLD FENCING SYSTEMS",
  "Clean Air",
  "CLEAN AIR LIMITED",
  "CLEAN AIR LTD",
  "Clean Vent",
  "CLEANAIR LTD",
  "Cleanwell",
  "Clear Screw",
  "Clearvision",
  "CLEARWATER",
  "Clearwater Technology",
  "CLEAVLAND",
  "Clement Clarke",
  "CLENAWARE SYSTEMS",
  "CLEVELAND",
  "CLEVELAND BIOTEC",
  "Click Medical",
  "cliff",
  "cliff wrappa",
  "CLIFTON",
  "CLIMA",
  "Climaster",
  "CLIMATE",
  "CLIMATE CENTRE",
  "CLIMATE CONTROL",
  "CLIMATE CONTROLS EUROPE",
  "Climate Electric",
  "Climate Electrical",
  "climate electricsl",
  "CLIMATE EQUIPMENT LTD.",
  "CLIMATE MASTER",
  "Climate Services",
  "CLIMATEC",
  "climatic controls",
  "Climavenata-Powermaster",
  "CLIMAVENETA",
  "Climavent",
  "CLIMEVENETA",
  "CLIPPER",
  "CLIPPER AHU LTD.",
  "CLIPPER AIR HANDLING UNITS",
  "CLIPPER AIR HANDLING UNITS LTD",
  "CLIPPER AIR HANDLING UNITS LTD.",
  "Clipsal",
  "Clipsel",
  "CLIPTEC CONTROLS",
  "CLIPTEC-CONTROLS",
  "CLIVET",
  "clleveland",
  "clmaventa",
  "CLMM",
  "CLMM ESPA",
  "Clockworks",
  "Clohus",
  "CLORIDE",
  "Clorious",
  "Cloris",
  "Clorius",
  "Clorius Ltd",
  "Clorius Metering",
  "CLOS MAT",
  "cloud",
  "Cloud Neon",
  "cloudgen",
  "Clouris",
  "CLOUZET",
  "Clover",
  "Clover Co",
  "CLOVER CONTROLS LTD",
  "Clover Ltd",
  "CLOVER WATER COOLER",
  "CLOW GROUP",
  "CLOW LADDERS",
  "Clp",
  "CLP AIR HANDLING SYSTEMS",
  "CLP AIR HANDLING SYSTEMS LTD",
  "CLP AUR HANDLING SYSTEMS LTD",
  "Clttenborg",
  "CLUAUDGEN",
  "Clyde",
  "CLYDE & BOOTH",
  "CLYDE & BOOTH 60 TON",
  "CLYDE BOILERS",
  "CLYDE BOOTH 200 TON",
  "Clyde Combinations",
  "Clyde Combustion",
  "Clyde Combustions",
  "CLYDE CRANE AND BOOTH LTD",
  "CLYDE FUEL COMBUSTION LTD",
  "Clymac",
  "cm",
  "C-MATIC SYSTEMS LTD",
  "CMC",
  "CMD",
  "CML233/1113F",
  "CMM",
  "CMP BATTERIES",
  "CMP2100",
  "CMR CONTROLS LTD.",
  "CMS",
  "CMT",
  "CN",
  "CO OP GROUP ENERGY",
  "Coastal Lifts",
  "Coates-Cam Industries Inc",
  "Coats",
  "COBOLT",
  "COBRA",
  "coburn",
  "COCA COLA",
  "COCA COLA ENTERPRISES LTD",
  "COCA COLA LTD",
  "COCA SECURITY GATES",
  "Coca Cola",
  "COCACOLA",
  "COCA-COLA",
  "COCA-COLA ENTERPRISES LTD",
  "COCHRAN BOILERS",
  "COCHRANBOILERS",
  "Cockworks",
  "Cocnelius",
  "CODECALL",
  "CODELINE",
  "CODELOCK",
  "codelock.com",
  "Codelocks",
  "Codelocks.com",
  "CODEM",
  "CODEM II",
  "Codem Static system",
  "CODEMLON",
  "Codemlon Call",
  "CODEX",
  "CODICE",
  "CODIGEL",
  "Codmetal",
  "Codmetel",
  "COE",
  "COEX",
  "Cofely",
  "COFFEE ART PLUS",
  "Coffee Celebration",
  "COFFEE FACTORY",
  "COFFEE SHOP",
  "COFFEE SLOUTION",
  "Coffee Vito",
  "COFFEE WRKS",
  "COFFEE-CO.COM",
  "COFFETEK LTD",
  "Cofley",
  "Cogenco",
  "Coil master",
  "Coiltech",
  "COILTECH AB",
  "Coke",
  "COKE COLA",
  "COLASIT LTD",
  "COLBROOK",
  "Colchester",
  "Colchster",
  "Cold tap",
  "COLD TECH",
  "COLDMAN MODUCEL",
  "Coldstream",
  "COLDURA",
  "COLEBOURN",
  "Coleman",
  "Coleman Moducel",
  "Coleman Moducēl",
  "COLEMAN MODUCELL",
  "ColemanModucel",
  "COLGED",
  "Colin & Peacock",
  "COLIN PEACOCK",
  "COLIN PEACOCK LTD",
  "Coline",
  "COLLETTE",
  "COLLINS WALKER",
  "COLMAN MODUCEL",
  "COLMAN MODUCEL",
  "COLMAN-MODUCEL",
  "Coloma",
  "COLORMAX",
  "Colour Fan",
  "COLOURQUAD, ECLIPSE NURSE CALL SYSTEM.",
  "COLSEC",
  "Col-secure",
  "Colston",
  "COLT",
  "colt / belimo",
  "Colt HH Airpower",
  "Colt Int Ltd",
  "COLT INTERNATIONAL",
  "Colt International Ltd",
  "COLTS",
  "COLZONE",
  "COM AP SYSTEMS",
  "Com Plete",
  "ComAmp Limited.",
  "Coman Fluid Power",
  "COMAP",
  "COMAP SYSTEMS",
  "COMAR CONDENSATORI",
  "comar fluid power",
  "Comat",
  "Comax",
  "COMBAL",
  "COMBAL?",
  "COMBAT",
  "combi",
  "Combi Steel",
  "COMBIMAT",
  "COMBIMATE",
  "combimeter",
  "COMBINAT",
  "COMBINED CATERING SERVICES",
  "COMBINED ENERGY SYSTEMS",
  "COMBINED POWER LTD",
  "COMBINED POWER SYSTEMS",
  "COMBINED SERVICES",
  "CombiSteel",
  "Comclit",
  "ComeFri",
  "Comelit",
  "Comelt",
  "Comenda",
  "Comercia",
  "Comet",
  "COMETA",
  "COMETAL GREENWORKS",
  "Comfort",
  "Comfort Controls",
  "COMFORT CONTROLS (SIEMENS)",
  "COMFORT-4",
  "COMINFO",
  "COMMAND",
  "COMMANDER EDGE",
  "COMMANDER EXECUTIVE",
  "Commax",
  "COMMEND",
  "COMMEND UK",
  "Commercial",
  "Commercial Ovens",
  "COMMERCIAL REFURBISHING SYSTEM LTD",
  "Common",
  "Common Care",
  "COMMS ROOM SERVICES",
  "COMMTECH CONTROLS LTD.",
  "COMMUNICARE",
  "COMP AIR   REAVELL",
  "COMP AIR BROOMWAFE",
  "COMP. AIR",
  "Compac",
  "Compact",
  "COMPACT (UK) Ltd",
  "Compact circline",
  "Compact Steam",
  "COMPACT UK Ltd",
  "Compactair",
  "COMPAGNIA TECNICA MOTORI",
  "COMPAIR",
  "CompAir Broomwade",
  "CompAir Brown Wade",
  "CompAir Kellogg",
  "CompAir Ltd",
  "COMPAIR UK LTD",
  "Company Care",
  "Company Care Systems Ltd",
  "COMPAP SYSTEMS",
  "Compaq",
  "Compare",
  "Compassociates",
  "Compco",
  "Complete Lab Installstion",
  "COMPLETE SHUTTER",
  "Complete Shutter Services",
  "Complete shutters",
  "COMPLEX",
  "complus",
  "COMPLUS TELTRONIC",
  "COMPO FIRE SYSTEMS LTD",
  "Composite",
  "COMPREHENSIVE CONTROL SYSTEMS",
  "COMPRESSEURS D'AIR",
  "COMPRI HX",
  "comptec",
  "Compton",
  "Compton Brook",
  "COMPTON PARKINSON",
  "Compus Teltronic",
  "COMREF",
  "COMSAFE",
  "Comserve",
  "COMSET",
  "COMSET PROJECTS",
  "COMSHE PROJECTS LTD",
  "COMSITE",
  "COMSITE CONTROLS",
  "comsite projects",
  "COMSITE PROJECTS LTD",
  "COMSITENPROJECTS",
  "COMTHERM",
  "comunicate",
  "CON",
  "Con Serv",
  "Cona",
  "CONABEARE ACCOUSTICS",
  "CONAIR CHURCHILL",
  "Concept",
  "CONCEPT 2",
  "CONCEPT ENERGY",
  "Concept Fire Alarm",
  "Concept Pro",
  "Concept Prop",
  "CONCEPT-PRO",
  "CONCORD",
  "CONCORD INTEGRATED SERVICES",
  "CONCORD TEMP. CONTROLS LTD.",
  "CONCORD TEMPERATURE CONTROLS",
  "Concord Temperature Controls Ltd",
  "Concorde",
  "CONCORDE TEMPERATURE CONTROLS LTD.",
  "Concrete",
  "Condair",
  "Conder",
  "CONDER ENVIROMENTAL",
  "CONDER ENVIRONMENTAL SOLUTIONS",
  "Condor",
  "CONECTA",
  "CONEX",
  "CONGENCO",
  "Connaught",
  "Connaught Compliance",
  "Connaught Compliance Ltd",
  "connect",
  "CONNECT A.D",
  "CONNECT AD",
  "Connect Group",
  "Connect Groupe",
  "CONNECTA",
  "CONNEVANS",
  "Connolly",
  "Connolly Refrigeration",
  "CONOTHERM",
  "Conserve",
  "Consol Control Solutions",
  "Consort",
  "CONSORT AIR TECHNOLOGY",
  "CONSORT LST",
  "Consort Southern Ltd",
  "CONSTANT",
  "CONSTANT AIR SYSTEMS",
  "CONSTANT POWER SERVICES",
  "CONSTANT POWER SERVICES LTD",
  "CONSTANT POWER SERVICES/MRELIN GERIN",
  "Constant Power Solutions",
  "Consul",
  "Consult lift service",
  "CONSUMER UNIT",
  "Consys",
  "Consys Controls",
  "CONTACA",
  "Contact fire & security",
  "CONTACT ROLLER SHUTTERS",
  "Contact Security",
  "CONTACT SHUTTERS",
  "CONTACTA",
  "CONTACTOR",
  "Contactor Switchgear",
  "CONTACTOR SWITCHGEAR LTD",
  "CONTACTOR SWITCHGEAR LYD",
  "CONTACTUM",
  "CONTACTUM-COSTER",
  "CONTAINED AIR SOLUTIONS",
  "CONTARDO",
  "Contech",
  "Conti",
  "Continental",
  "CONTINENTAL SHUTTERS",
  "CONTINENTAL SHUTTERS LTD.",
  "CONTINENTALSHUTTERS LTD",
  "CONTOGROUP",
  "Contoil",
  "Contolled Air Solutions",
  "Contorgroup",
  "Contour",
  "Contour 7000",
  "CONTRA - FLEX",
  "Contra flex",
  "Contract",
  "Contract Fire",
  "Contract Fire & Security",
  "Contract Fire Security",
  "CONTRACT FIRE SYTEMS",
  "CONTRACTOR",
  "CONTRAFLEX",
  "CONTRA-FLEX",
  "CONTRAPLAN",
  "Contrast",
  "Control",
  "CONTROL & ELECTRICAL SOLUTIONS LTD",
  "CONTROL & POWER SYSTEMS LTD",
  "Control AER",
  "Control Center",
  "CONTROL CO",
  "control company ltd",
  "Control Design",
  "CONTROL DESIGN (A/C) LTD",
  "CONTROL DESIGN (AC) LTD",
  "CONTROL DESIGN A/C",
  "CONTROL ENERGY PANEL",
  "Control Equipment",
  "CONTROL EQUIPMENT LTD",
  "CONTROL EQUIPMENT LTD.",
  "Control Fire",
  "CONTROL GEAR DIRECT LTD",
  "Control Master",
  "Control Master 600",
  "Control Panel",
  "CONTROL PANEL ENERGY",
  "Control Panel Services",
  "CONTROL PANEL SERVICES LTD",
  "Control panels ltd",
  "CONTROL SYSTEMS",
  "CONTROL TECH LTD",
  "CONTROL TECHNIQUES",
  "Control Technology",
  "CONTROLCO",
  "CONTROLCO LTD",
  "ControlDesign",
  "controlled air solutions",
  "Controlled Energy",
  "CONTROLLED FLAME BOILERS LTD",
  "Controlled Ltd.",
  "Controlled power",
  "CONTROLLED VAPOUR TECHNOLOGY",
  "CONTROLLI",
  "Controlmaster",
  "Controlsoft",
  "CONTROMEC",
  "Contromec Limited",
  "CONTROMEC LTD",
  "Contromec Services",
  "Contromec Services Ltd",
  "CONTROMEC SYSTEMS",
  "CONTROMEC SYSTEMS LTD.",
  "CONTRONICS",
  "CONVAIR",
  "Convault",
  "CONVEPT",
  "CONVEREX",
  "CONVERSION",
  "CONVERTEC POWER ELECTRONICS",
  "Convotherm",
  "CONWOC",
  "CONYX",
  "COOK",
  "COOK & ELLINGTON",
  "Cook Works",
  "COOK WORKS FOR ARGOS",
  "COOKE & LEWIS",
  "Cookology",
  "COOKS",
  "COOKSHACK",
  "COOKSO & ZINN",
  "COOKSON & ZINN",
  "COOKSON & ZINN (PLT) LTD.",
  "Cookson & Zinn (PTL) Ltd",
  "COOKSON AND ZINN",
  "COOKSON AND ZINN  SUFFOLKENGLAND",
  "Cookson and Zinn (PTL) Ltd",
  "COOKSON AND ZINN OF  SUFFOLK ENGLAND",
  "COOKSON AND ZINN OF SUFFOLK EGLAND",
  "COOKSON AND ZINN OF SUFFOLK ENGLAND",
  "CookTek",
  "COOKWARE LTD",
  "COOKWORKS",
  "Cool it",
  "Cool Springs",
  "Cool Technologies",
  "COOL TECHNOLOGY",
  "COOL WATER DIRECT",
  "Cool Technology Ltd",
  "Coolair",
  "CoolBreeze",
  "COOLCUBE",
  "Coole",
  "cooler aid",
  "COOLERAID",
  "COOLERS & CONDENSERS",
  "COOLERS & CONDENSERS LTD",
  "Coolers and Condensers Ltd",
  "COOLFACTOR",
  "COOLFACTOR GROUP",
  "COOLFAST",
  "Coolguard",
  "COOLING COMPANY",
  "Cooling Services",
  "Coolpoint",
  "COOLTHERM INSTALLATION SERVICES",
  "COOLZINE",
  "COOLZONE",
  "Coomber Fire & Security",
  "Coombes Fire & Security",
  "COOP",
  "COOP ENERGY",
  "COOPER",
  "Cooper / JSB",
  "Cooper Controls",
  "COOPER FIRE",
  "COOPER FIRE AND SMOKE",
  "COOPER HEAT",
  "COOPER KIGHTING AND SAFETY",
  "Cooper lighting",
  "Cooper Lighting & Safety",
  "Cooper lighting & saftey",
  "COOPER LIGHTING & SECURITY",
  "Cooper Lighting and Safety",
  "Cooper lighting and security",
  "COOPER MENVIER",
  "COOPER MENVIER CENT",
  "Cooper Security",
  "Cooper Seurity",
  "Cooper Lighting & Security",
  "Cooperheat",
  "COOPERS",
  "COOPERS FIRE & SMOKE",
  "COOPERS SMOKE BLINDS",
  "COOPET",
  "CO-Ordinated control systems",
  "COOZONE",
  "COP",
  "COP SECURITY",
  "Copas Onrols",
  "Copeland",
  "Copeland Door Engineering",
  "COPELAND SCROLL & EMERSON",
  "COPLE",
  "Coppas",
  "Coppas Controls Ltd",
  "Copper",
  "COPPERAD",
  "Coppercraft",
  "Coppered",
  "copperform",
  "CORAY RADIANT",
  "CORAY VAC",
  "CORD CONTROLS",
  "Corden Bleu",
  "Cordivari",
  "Cordon Bleu",
  "Coreco",
  "CORIOLISS",
  "CORNELIOUS",
  "CORNELIUS",
  "CORNELIUS BEVERAGE TECHNOLOGIES LTD",
  "Cornerstone Systems",
  "cornerstone systems ltd",
  "Cornwall Garage Door Services",
  "Corona",
  "CORPORATE EXPRESS",
  "Corrigan Pumps Ltd",
  "Corsair",
  "Corsair Ltd",
  "Corsair Manufacturing",
  "CORSAIR MANUFACTURING LTD",
  "CORSAIR MANUFACTURING LTD.",
  "Corso",
  "CORUS",
  "CORVETTE",
  "COS",
  "Cosaf",
  "CoSaf Environments",
  "COSFI",
  "COSMETAL",
  "Cosmetal Italy",
  "Cosmetel",
  "COSMOTEC",
  "cosort",
  "Costa",
  "COSTAN",
  "Costas",
  "COSTER",
  "COTAG",
  "COTERS AND JACKSON CLOSER",
  "Cotes",
  "COTHERM LTD.",
  "Cots",
  "Cotswold",
  "Cotswold Hygiene",
  "COTSWOLD LIFTS",
  "Cotswold Lifts Ltd",
  "Cotswolds",
  "COTTRELL METER CO.",
  "Cougar",
  "COULD NOT LOCATE",
  "Counterline",
  "Countryman",
  "Countryman Power Plant",
  "COUNTRYMAN POWER PLANT LTD",
  "COUNTY DOOR SOLUTIONS",
  "County Shutters",
  "COUNTY SHUTTERS & GRILLES",
  "COUNTY SHUTTERS & GRILLES LTD.",
  "COURTNEY THORNE",
  "Courtney-Thorpe",
  "Cova",
  "COVA SECURITY GATES",
  "COVA SECURITY GATES LTD",
  "COVA SECURITY LTD",
  "COVA SECURIY GATES",
  "COVAC",
  "COVES",
  "COVESS",
  "COVOTHERM",
  "COVRA",
  "COVRAD",
  "COWIE FIRE SAFETY &SECURITY LTD",
  "COX GOMYL",
  "COXREEL",
  "Coxreels",
  "Coystant Power Services",
  "CP electronics",
  "CPA Electrical",
  "CPC Refrigerant & Gas Detection",
  "CPE",
  "CPELAIR",
  "Cpex",
  "cpm",
  "CPN",
  "CPS",
  "CPS CATERING",
  "CPS Ltd",
  "CPS. UPS MST 40 AO CPS",
  "CPX PRECISION UK LTD",
  "CR",
  "CR Remeha",
  "CR REMEHA ?",
  "CR2  Grundfos.",
  "CRABRESS",
  "Crabtree",
  "Crabtree FUSE STAR",
  "CRABTREE POLESTAR",
  "Cradle Runways Ltd",
  "CRADLECRAFT ENGINEERING LTD.",
  "CRADLECRAFT ENGINEERS LTD",
  "CRAIG & DERRICOT",
  "CRAIG & DERRICOTT LTD",
  "CRAIG &DERRICOTT",
  "craig and derricot",
  "CRAIG AND DERRICOT LTD",
  "CRAIG AND DERRICOTT",
  "Craig Derricott Ltd",
  "CRAIG ROLLER SHUTTERS",
  "Craig & Derricot",
  "CRAIG&DERRICOTT",
  "Craigalan Controls",
  "CRAINE",
  "CRANBORNE",
  "Crane",
  "Crane Building Services & Utilities",
  "CRANE FLUID SYSTEMS",
  "CRANE MERCHANDISING",
  "CRANE MOPUMP",
  "Crane Process Flow Technologies",
  "CRANE PSS",
  "CRANFORD CONTROL SYSTEMS",
  "CRANTREE POLESTAR",
  "Crawford",
  "Crawford Amber",
  "Crawford Amber.",
  "CRAWFORD DOORS",
  "CRAWFORD ELECTRIC CONTROLS LTD",
  "Crawley Trebetron",
  "Crayton Peerless",
  "Creda",
  "Creole",
  "Cressall",
  "Crestchic Loadbanks",
  "Crestchic Ltd",
  "CRESTFOLD",
  "Crevsen",
  "CREWFORD",
  "CRIOCABIN",
  "CRITAIR",
  "Critall",
  "CRITERION CONTROLS TD.",
  "Crittal",
  "CRN",
  "CROMAR",
  "CROMARTIE",
  "Cromptom",
  "CROMPTON",
  "Crompton Brooke",
  "CROMPTON BROOKS",
  "CROMPTON PARKINSON",
  "CROPTON CONTROLS",
  "Cross",
  "Cross electrcal",
  "CROSS ELECTRICAL LTD",
  "Cross Refrigeration",
  "CROSSE ENG LTD",
  "Crossflow",
  "Croughtree",
  "CROUZET",
  "CROWCOM",
  "Crowcomm",
  "Crowcon",
  "CROWN",
  "CROWN COMPUTING",
  "CROWN FIRE",
  "CROWN HOUSE",
  "CROWN HOUSE ENGINEERING LTD.",
  "CROWN MONO",
  "CROWN WATER HEATER LTD",
  "CROWN WATER HEATERS",
  "CROWNCON",
  "CRPTO PEERLESS",
  "CRS",
  "CRU PRIMA",
  "Crusader",
  "CRVPTO PEERLESS",
  "CRYDOM",
  "CRYO-KING",
  "CRYOQUIP",
  "CRYOSERVICE",
  "Crypto",
  "Crypto Peer less",
  "CRYPTO PEERLESS",
  "Crypto Pereles",
  "Crypton",
  "CRYSTAL FOUNTAIN",
  "CRYSTAL MOUNTAIN",
  "CS Electrical",
  "CS LILIN",
  "CSB",
  "CSB Battery",
  "csc",
  "CSG",
  "CSI Aurope",
  "CSI EUROPE",
  "csl",
  "CSM MOTORI",
  "CSS",
  "CST",
  "CST VULCAN",
  "CSTS LTD",
  "CTA",
  "C-Tec",
  "CTH",
  "CTH.   /HP COMPAQ",
  "CTI",
  "CTMM",
  "CTS",
  "CTS CENTRE TANK ALARM",
  "CTS DIRECT",
  "CTS Engineering",
  "CTS LTD",
  "CTS TANK SERVICES",
  "CTU",
  "cuadros electricos",
  "Cubb",
  "CUBD",
  "Cube",
  "CUBE 56",
  "Cubex",
  "CUBIC",
  "CUBIC TRANSPORTATION LTD.",
  "CUBIC TRANSPORTATION SYSTEM",
  "Cuble",
  "CUCINA",
  "CUISINART",
  "CUISINE",
  "CULLIGAN",
  "Culligan International (UK) Ltd",
  "CULLIGEN",
  "CULUS",
  "CUMBRIA HEATING",
  "CUMBRIA HEATING COMPONENTS",
  "Cummin",
  "CUMMING",
  "Cummings",
  "Cummins",
  "CUMMINS POWER GENERATION",
  "Cummins/Power Generator",
  "Cuno",
  "CUPPONE",
  "CURAIM LTD",
  "CURFEE DOORSV& SHUTTERS LTD",
  "CURFEW DOORS & SHUTTERS LTD",
  "Current Technology",
  "CURRIES",
  "CURRIGAN",
  "Currys",
  "Curry's",
  "currys essential",
  "CURRYS Essentials",
  "currys special",
  "currysessentials",
  "Curshier Ind doors",
  "Curti",
  "CURTI LIFTS",
  "Curtis",
  "CURTIS INSTRUMENTS (UK) LTD",
  "CURTIS INSTUMENTS (UK) LTD.",
  "CURTIS LIFTS SW LTD",
  "CUSPO",
  "CUSROM MADE",
  "CUSTO MADE",
  "Custom Build",
  "custom built",
  "CUSTOM LITE",
  "CUSTOM MADE",
  "CUSTOM PANEL (NO  MANUFACTURER)",
  "CUTLER",
  "CUTLER - HAMMER",
  "CUTLER & HAMMER",
  "Cutler Hammer",
  "Cutler_Hammer",
  "CUTLER-HAMMER EUROPA",
  "Cutler-Hammer Ottermill",
  "Cutlery Polisher",
  "Cutlery Polisher Company",
  "CUTTING",
  "CUTTINGS",
  "C-VAC",
  "CVAP",
  "CVL",
  "CWC",
  "CWC Quartz",
  "CWP",
  "CWS",
  "CWY",
  "CX",
  "cx access",
  "cxaccess",
  "Cybe",
  "Cyber Power",
  "CYBIE",
  "Cybie Mibus",
  "CYBLE",
  "Cyble Mbus",
  "CYBLE RF",
  "CYCLEORAMA",
  "Cyclone",
  "CYCLONE CRANES",
  "Cyde",
  "Cygner",
  "CYGNET",
  "Cylinda",
  "CYLINDER INSULATED",
  "CYLON",
  "CZERWENY",
  "d",
  "D & H",
  "D & L MEDICAL",
  "D B SECURITY",
  "D BENSON (CONTROLS) LTD",
  "D Benson Controls",
  "D Benson Controls Ltd",
  "D Connend",
  "D LIFT STORE",
  "D Line",
  "D S S",
  "D SQUARED",
  "D&A",
  "D&A SYSTEMS",
  "D&HE/Hortling",
  "D&S Control Systems",
  "D. BENSON (CONTROLS) LTD",
  "D.BENSON CONTROLS (HONEYWELL)",
  "D.E.S Rollinson Ltd",
  "D.H.Morris",
  "D.K.Autowick",
  "D+A LIFTS",
  "D+H",
  "d+he",
  "D+L MEDICAL",
  "DA SUPPLIES",
  "DA SUPPLIES LTD",
  "DA SYSTEM",
  "DA SYSTEMS",
  "DA YE ELECTRIC MACHINERY CO. LTD.",
  "DAAB",
  "DAAB INDUSTRIAL DOORS",
  "DAAB PORTTEKNIK",
  "Daalderop",
  "DAB Pumps",
  "DAB WATER TECHNOLOGY",
  "DACONAIR",
  "DACS",
  "DACS LTD",
  "DAEMON",
  "Daewoo",
  "DAEWOO ELECTRONICS",
  "DAIHATSU",
  "DAIHATSU ENTRANCE SYSTEMS",
  "DAIKEN VRV 1V",
  "DAIKIN",
  "Daikin applied Uk ltd",
  "DAIKIN EUROPE N.V.",
  "DAIKIN INDUSTRIES CZECH REPUBLIC",
  "DAIKIN INDUSTRIES LTD",
  "DAIKIN SKY AIR",
  "Daikin VRV",
  "DAIKIN/MCQUAY",
  "DAIMAIN MERSEY",
  "DAIREI",
  "daitem",
  "Daiya",
  "Dakal",
  "DAKIN EUROPE",
  "Dakin Inverter",
  "DAKRO",
  "Dalair",
  "DALAIR AIR HANDLING EQUIPMENT",
  "DALAIR LTD",
  "DALAIR PRODUCTS",
  "DALAIR PRODUCTS LTD.",
  "DALAIRE",
  "DALBY",
  "Dale",
  "DALE & BURTONWOOD",
  "DALE CRANES",
  "DALE ELECTRIC GENERATOR, VOLVO ENGINE",
  "Dale Fabrications",
  "DALE LIFTING AND HANDLING",
  "Dale Power Solutions",
  "DALE POWER SOLUTIONS LTD.",
  "DALE POWER SOLUTIONS.",
  "DALE POWER SYSTEMS",
  "Daleline",
  "DALEMANS",
  "Dales",
  "Dali",
  "DALIR",
  "DALIR LTD",
  "dalkin",
  "DALL MEIER",
  "DALLMEIER",
  "Dalton Brady",
  "Daltons",
  "DALTONS WADKIN",
  "DAMIXA",
  "DAMTECH",
  "DAN",
  "DAN CHAMBERS",
  "Dan doors",
  "DAN DRYER",
  "Dan Foss",
  "Dan Tec",
  "DAN TECH",
  "Danam",
  "DAN-doors",
  "DANE",
  "DANECH",
  "DANFOSS",
  "DANFOSS - SEMCO",
  "DANFOSS - SEMCO FIRE PROTECTION",
  "DANFOSS VLT AQUADRIVE",
  "DANFOSS-SEMCO",
  "Danham Bush",
  "DANNAN",
  "DANSON",
  "Dantec Electronic Engineering.",
  "DANTECH",
  "Dantech Eng.",
  "Dantech/Synectics",
  "DANTHERM",
  "Danube",
  "DANVENT",
  "dar",
  "Daray",
  "Daray Dental",
  "DARCY PRODUCTS LTD",
  "DARCY SPILLCARE",
  "Darcy spillcare manufacture",
  "DARDUCT",
  "Darfen",
  "Darfield motor controls",
  "Darroch insulation services ltd.",
  "DART VALLEY SYSTEMS",
  "Dartpoint",
  "DARTPOINT LTD",
  "DARTPOINT LTD.",
  "DASCO",
  "DASCO ENTRANCE TECHNOLOGY LIMITED",
  "Data Aire Inc.",
  "Data klimat",
  "DATA MANAGEMENT",
  "DATA MANAGER",
  "DATA PLATE COVERED",
  "DATA PLATE MISSING",
  "data safe",
  "Data Video",
  "Data Worn",
  "DATAPOINT LTD",
  "DATASCHALT LUBECK",
  "DATSONS",
  "DAUB",
  "DAULIT",
  "Davenam switchgear",
  "DAVENHAM ENG LTD",
  "Davenham Engineering",
  "Davenham swich gear ltd",
  "Davenham Switchgear",
  "DAVENSET DEAKIN",
  "Davey set",
  "Davidson",
  "DAVIDSON & CO LTD BELFAST",
  "DAVIS OF DERBY (DD)",
  "DAVY MORRIS",
  "Daw med Clinic",
  "DAWMEC",
  "Dawmed",
  "Dawmed Clinic",
  "Dawson",
  "Dawson Dale",
  "Dawson Keith",
  "DAWSON- KEITH LTD",
  "DAWSON MMP LTD.",
  "Dawson-keith",
  "DAWSONKEITH LTD",
  "Dayton",
  "DBA",
  "DBI SALA",
  "DBM",
  "DBT",
  "DC emergency systems ltd",
  "DC Power",
  "DC Products",
  "DCE",
  "DCE UNIMASTER",
  "DCI GROUP",
  "DCS",
  "DCTU",
  "DDA",
  "DDA Access",
  "DDA ACCESS Ltd",
  "DDC",
  "DDC/MTU",
  "DDDDDDDD",
  "DDEL",
  "DDMA",
  "DDR LTD",
  "DDR LTD.",
  "DDS",
  "DDSL",
  "de Dietrich",
  "DE DIETRICH",
  "DE LONGI",
  "DE MIN WATER",
  "DE REUS",
  "de strat fan",
  "De Dietrich",
  "De Longhi",
  "Dea",
  "DEAF ALERT",
  "DEAF ALERT PLC",
  "deaf alerter",
  "DEAF ALERTER PLC",
  "DEAF ALERTER.",
  "deaf alterer",
  "DEAKIN",
  "DEAL UPS SYSTEMS",
  "DEAN & WOOD LTD.",
  "DEAN AND BYBALL",
  "DEAN SMITH AND GRACE",
  "Dean Smith",
  "DEANE",
  "DEAYTON",
  "DEBA",
  "DEBCO",
  "DECA",
  "Decca",
  "DECCA PLASTCS",
  "DECCA PLASTIC",
  "DECCA PLASTIC LTD",
  "Decca Plastics",
  "DECCA PLASTICS LTD",
  "DECENT DOOR SHUTTERS",
  "Decker",
  "Ded Micros",
  "Dedicated Engines",
  "Dedicated Micro",
  "Dedicated Micros",
  "Dedicatedengines",
  "DEEP SEA CONTROLS",
  "Deep Sea Electronic",
  "DEEP SEA ELECTRONICS",
  "Deer",
  "DEF",
  "Defence Group",
  "Defence Shutter Services",
  "DEFENCE SHUTTERS",
  "Defence shutters ltd",
  "defender",
  "Defensor",
  "DEFENSOR AG",
  "DEFIBTECH",
  "DEHN",
  "Dehoust",
  "deicated micos",
  "DEIGHTON MANUFACTURING UK LTD",
  "Deister Electronics",
  "DEKTAFLOW",
  "Delabie",
  "DELAIR",
  "DELAIR LTD",
  "DELAIR LTD.",
  "DELAP & WALLER",
  "DELAPENA",
  "DELCHI",
  "DELCO",
  "DELDHI",
  "DELETE",
  "DELETE ASSET",
  "DELETE ITEM",
  "DELFEILED",
  "Delfield",
  "DELFIELD SADIA",
  "DELFIELD SADIA REFRIGERATION",
  "Delice",
  "DELICE DE FRANCE",
  "DELL",
  "Dell Precion",
  "DELL PRECISION",
  "Dell Precison",
  "DELL PRECISzION",
  "DELL PRICISION",
  "Dellair",
  "DELLMEIER",
  "DELLPrecision",
  "DELMARK",
  "DELMARK LIFTING EQUIPMENT",
  "DELMARK LIFTING EQUIPMENT LTD",
  "DELMATIC LIGHTING MANAGEMENT",
  "DELONGHI",
  "Delonghi Nespress",
  "Delongho",
  "Delox",
  "DELPHYS",
  "Delta",
  'Delta "T"',
  "DELTA BMS",
  "DELTA CONTROL SYSTEMS LTD",
  "DELTA D3",
  "Delta Design Sysyems",
  "DELTA DS",
  "DELTA ELECTRICAL LTD.",
  "DELTA ELECTRICAL SYSTEM LTD",
  "DELTA ELECTRICAL SYSTEMS",
  "DELTA F",
  "DELTA FLOW TECH",
  "DELTA FLOWTECH",
  "Delta Itron",
  "DELTA NEU",
  "DELTA NEU LTD",
  "Delta Obstruction Lighting",
  "DELTA PLASTICS LTD.",
  "Delta Power",
  "DELTA ROCK NW LTD",
  "DELTA SCHLUMBERGER",
  "DELTA SOUND SECURITY",
  "Delta T",
  "DELTA T TRACE HEATING LTD",
  "DELTA TRACE HEATING LTD",
  "DELTA VENTILATION",
  "DELTA-4",
  "Deltacap 2000",
  "Deltaclima",
  "Deltaflow",
  "DELTANEU",
  "DELTASOL",
  "DELTA-T",
  "DELTAWARE",
  "DELTEC",
  "Deltech",
  "DELVIN",
  "DEM CONTROLS LTD",
  "DEMAG",
  "DEMAG MERCIA LIFTING GEAR",
  "DEMAIN",
  "Demma",
  "Demon",
  "Demon Pressure Washers",
  "DEMPLEX",
  "DENCO",
  "DENCO AIR CONDITIONING",
  "DENCO AIRCON",
  "DENCO HAPPEL",
  "DencoHappel",
  "Dencol",
  "Denley",
  "DENLEY HYDRAULICS",
  "DENMIS",
  "Denn",
  "Denni",
  "Dennis",
  "DENNIS BALDWIN & SON",
  "DENNISON KETT & Co. LTD",
  "DENOM",
  "Denon",
  "DENSEI LAMBDA",
  "DENTAIR",
  "DentalEZ Group",
  "DENVER",
  "DENVO",
  "Deopke",
  "Deper Automatic Doors and Windows",
  "DEPOSITOS COBALLES, S. L.",
  "DEPOSITOSCOBALLES S.L.",
  "DERBY",
  "DERBY UNCI",
  "DERMAN SMITH",
  "DERST",
  "Derungs Waldmann",
  "DERWENT LIFTS",
  "DES",
  "DES FIRE",
  "DES GROUP LTD",
  "Desc",
  "DESCO-AUTO GLYM",
  "DESIGN & DEVELOPMENT",
  "Design And Development Ltd",
  "DESIGN COUNTERS LTD",
  "Design Developments Ltd",
  "DESIGN ENVELOPE",
  "DESIGN LINE",
  "DESIGNCOM TECHNOLOGIES",
  "Desmi",
  "Desmi Rotan",
  "Desmon",
  "DESO",
  "Despro Electronics",
  "DESPRO-MEDIPLAN",
  "Desser",
  "Desso",
  "DESTRATAIR",
  "DET TRONICS",
  "DETA",
  "DETA Electrical Co Ltd",
  "DETA NEU",
  "DETAILS HIDDEN UNDER CASING",
  "detect",
  "Detection Instruments Limited",
  "DETECTOMAT",
  "DETEK",
  "Detroit",
  "DETROIT DEISEL / STAMFORD",
  "Detroit Diesel",
  "Deuhurst",
  "Deutz",
  "DEVA",
  "Devair",
  "DEVAPOR",
  "DEVATEC",
  "DEVATEC SA",
  "Deveron",
  "DeVilBiss",
  "Devleig",
  "DEVON",
  "devon switch",
  "DEVONTECH",
  "Devontech ltd",
  "DEW ELECTRICAL ENGINEERING.",
  "DEW.ELECTRICAL ENGINEERING.",
  "Dewalt",
  "dewey",
  "Dewey Water",
  "Dewey Waters",
  "DEWEY WATERS LTD",
  "dewey waters.",
  "Dewey Water",
  "Dewhurst",
  "DEWRASWITCH",
  "DEWY WATERS",
  "Dextra",
  "DEZE",
  "df",
  "dffusion",
  "dfgdfg",
  "DFI",
  "DFS",
  "DG",
  "DGI Meters",
  "Dgitrace",
  "DHE INDUSTRIAL INSTALLATIONS",
  "Dhelongi",
  "DIA EYE",
  "Diagnostic Electronic",
  "Dialit",
  "Dialysis",
  "Diamante",
  "diamond",
  "Diamond Water Sys.",
  "Diax",
  "Dichroic",
  "Dickson",
  "Didsbury Engineering",
  "dieback and peter",
  "DIEHL SAPPEL",
  "DIELZ",
  "DIESEL DEFENCE",
  "DIESELEC THISTLE",
  "Diesenvale Eng. Co. Lt.",
  "Diesenvale Eng. Co. Ltd.",
  "DIESTER ELECTRONIC",
  "DIETZ",
  "DIFFUSER",
  "diffusiom",
  "DIFFUSION",
  "DIFFUSION HEATING & COOLING",
  "Difusion",
  "DIGICON",
  "DIGIDIM",
  "DIGISTAT",
  "DIGITAC",
  "DIGITAC-RAYTEL SECURITY SYSTEMS",
  "Digital",
  "DIGITAL ADVANCED",
  "DIGITAL ADVANCED CON",
  "DIGITAL ADVANCED CONTROL",
  "DIGITAL DOORLOCK",
  "Digital Energy",
  "Digital Enrergy",
  "DIGITAL LIFT CONTORN",
  "DIGITAL LIFT CONTROL",
  "DIGITAL LIFT CONTROLS",
  "DIGITAL LIFT CONTROLS LTD",
  "Digital Master Control (DMC)",
  "digital.com",
  "DIGITRACE",
  "Digiware",
  "DIHR",
  "DIMENSION",
  "DIMPLE",
  "DIMPLES",
  "Dimplex",
  "DIMS",
  "DIMTEK",
  "Dinack",
  "DINAK",
  "Dinplex",
  "DIOKOS",
  "Diplomat",
  "Diplomatic",
  "Diral",
  "DIRCET REFRIGERATION SERVICES LTD",
  "DIRECT CATERING",
  "DIRECT FIRE",
  "DIRECT PUMPS & TANKS",
  "DIRECT REFRIGERATION SERVICES LTD",
  "DIRECT SUPPLY",
  "Direct Cylinder",
  "DIRECTION FIRE LTD",
  "Diret",
  "DIRT DRIVER",
  "DISABLED CALL SYSTEM",
  "Disano",
  "DISCALD RT",
  "DISCOVERY",
  "Disdsbury",
  "DISHWASHER COMMERCIAL",
  "DISHWASHER PROFESSIONAL",
  "Disitrall",
  "DISKOMAT",
  "Diskomat AB",
  "disposan",
  "dissano",
  "Distech",
  "DISTECH CONTROLS",
  "DISTECH CONTROLS.",
  "DISTFORM",
  "Disys Technologies",
  "DITEC",
  "DITEC ENTREMATIC",
  "DITO SAMA",
  "DITO-SAMA",
  "DITTA SYSTEM",
  "DITTING",
  "DITTING SWISS",
  "DITTO G",
  "Ditton Thermatics",
  "Ditton Thermatics Ltd",
  "DIVERSEY",
  "DIVERSEY LEVER",
  "DIVERSITY",
  "DIVERSY",
  "DIXELL",
  "Dixie Narco",
  "DIXIE-NARCO",
  "DJ Doors",
  "DKC",
  "DKD",
  "DL+H",
  "DLK",
  "DLP TEXAS INSTRUMENTS",
  "DLS",
  "Dm",
  "DM METERING",
  "DMA",
  "DMA CONTROLS LTD",
  "DMC",
  "DMD DRESSER",
  "DMP COMMUNICATION SYSTEM",
  "DMP COMMUNICATIONS",
  "DMP Communications Systems",
  "DMR",
  "dms",
  "DMS CONTROL LTD",
  "DMS Controls",
  "DMS CONTROLS L4D",
  "DMS CONTROLS LTD",
  "DMS METERS",
  "DMTECH",
  "DNS",
  "DOBERMAN SECURITY",
  "Doc-Con Systems",
  "DOCK SOLUTIONS LTD.",
  "DODD GROUP (NORTH WEST) LTD",
  "DOE - O - MATIC",
  "Doenan Smith",
  "DOEPKE",
  "DOERR",
  "DOFA",
  "Dog Bandit",
  "Dok-Tek Systems",
  "DOLCHI",
  "DOLPHIN",
  "DOLPIN VELOCITY",
  "Dom Power",
  "Doma",
  "Domae",
  "Domestic",
  "Dometic",
  "DOMINATOR",
  "Dominic Hunter",
  "DOMINION",
  "Domnick Hunter",
  "DOMNICK HUNTER LTD",
  "Domoe",
  "DOMONICK HUNTER",
  "Domotec",
  "DOMPLEX",
  "Domus Ventilation",
  "DOMUSLIFT",
  "DONALDSON",
  "DONALDSON FILTRATION SOLUTIONS",
  "DONATI",
  "DONGAN",
  "Donkin",
  "DONKIN FANS",
  "DOONSAN",
  "DOOR - WISE LTD",
  "Door & Shutter Services",
  "DOOR ACCESS SOLUTIONS",
  "DOOR ACCESS SOLUTIONS/ALPRO",
  "DOOR CARE",
  "Door o MATIC",
  "Door repair service",
  "DOOR SIPHON",
  "DOOR SYPHON",
  "DOOR SYPHON PSU",
  "DOOR SYPHON SYSTEM",
  "DOOR WISE LTD",
  "DOORFIX",
  "DOORGAURD",
  "doorman/smith",
  "DOOROMATIC",
  "Door-O-MATIC",
  "DOORS & SHUTTERS LTD",
  "DOORSIPHON SYSTEMS",
  "Doors-O-Matic",
  "doorwingear ltd",
  "DOOR-WISE",
  "DOOSAM",
  "DOOSAN",
  "Dooson",
  "DorGard",
  "Dorin",
  "Dorma",
  "DORMA KABA",
  "Dorma?",
  "Dormain Smith",
  "Dorman",
  "DORMAN SMITH",
  "DORMAN SMITH (MATTHEW HALL LTD)",
  "Dorman smith control gear ltd",
  "DORMAN SMITH ENGINEERED SYSTEMS",
  "Dorman smith switchgear",
  "DORMAN SMITH SYSTEMS",
  "DORMAN SMITH/ICW POWER",
  "DORMAN SMOTH",
  "DORMAN SWITCHGEAR",
  "DORMAN/PUMA POWER",
  "Dormanlong",
  "dormansmith loadbank",
  "dormer",
  "DORMS",
  "DORNAN",
  "Dornan smith switchgear",
  "DOROT",
  "dortec",
  "DORTECH",
  "Dortek",
  "DORTEK HYGIENIC DOOR SYSTEMS",
  "DORUNKNOWNMA",
  "DORWINGEAR",
  "DOSAPRO",
  "DOSATRON",
  "DOSE",
  "DOSILET",
  "Dosing Pot Technology",
  "DOSING SOLUTIONS",
  "DOSIVAC",
  "DOSPEL",
  "dotwingear ltd",
  "Doughty",
  "Douglas",
  "DOUGLAS CONTROLS",
  "DOUGLAS JAMES",
  "DOUGLAS LOUTH ENGLAND",
  "DOVER",
  "Dowding & Mills",
  "Dowty",
  "dp DOORS & SHUTTERS",
  "DP Plastics",
  "DPB",
  "DPD",
  "DPX",
  "DR",
  "Dr drives",
  "DR Mach",
  "Dr Vent",
  "DR. MACH",
  "DRACY SPILLCARE",
  "DRAEGER MEDICAL",
  "Drager",
  "Drager Safety",
  "DRAGERWERK",
  "DRAGON FIRE",
  "Dragonair",
  "drainstere",
  "Draper",
  "DRAVO",
  "drax",
  "Drayron",
  "Drayton",
  "Drayton controller ltd",
  "Drayton Controls",
  "Drayton Tanks",
  "DraytonControls",
  "Dream Kitchen",
  "Drehstromzahler",
  "Dremcha 200",
  "Dresor",
  "Dresser",
  "DRESSER-",
  "DRESSER MEASUREMENT DIVISION",
  "DRESSER METERS & INSTRUMENTS",
  "DRESSER ROOTS",
  "DRESSER ROOTS NETER WERIES B4",
  "DRESSER WAYNE PIGONE",
  "DRESSOR",
  "Drewart",
  "Drier",
  "DRILLMASTER",
  "DRINKING WATER SOLUTIONS",
  "DRISTEEM",
  "DRISTEEM HUMIDIFIER COMPANY.",
  "Drive Control",
  "DRIVE700",
  "DRMAN",
  "DRU",
  "DRUG LOO",
  "Drugasar",
  "drugloo",
  "DRUMMOND",
  "Drummond Welding & Steel Works",
  "DRUMMOUND",
  "DRUVA",
  "DRYBLOW",
  "DRYFLOW",
  "Drysenz",
  "DRYTEC SA",
  "DS",
  "DS AND C",
  "DSC",
  "DSD",
  "DSE",
  "DSE (DEEP SEA ELECTRICS)",
  "DSK",
  "DSK CONTROLS",
  "DSK CONTROLS LTD",
  "DSKCONTROLS",
  "DSL",
  "DSMA",
  "DSP",
  "DSS",
  "DSS AUTOMATIC DOORS",
  "DST SEIBU GICKEN",
  "DT",
  "DTC",
  "DTE",
  "D-Tech",
  "Dualit",
  "dualit white",
  "Dualite",
  "Duality",
  "DUALSYSTEMS",
  "Dualt",
  "Ducati",
  "DUCTAIR ELECTRICS",
  "DUCTBUSTER",
  "DUCTBUSTERS",
  "Ductex",
  "Ducting",
  "Dudley",
  "Dudley Industries Ltd.",
  "DUDLEY SAFES",
  "DUDLEY SLIMLINE",
  "Dudley tri shell",
  "dudley trishell",
  "DUETBUSTERS LTD.",
  "DUGALD",
  "DUGARD",
  "DUGS",
  "DUKE MANUFACTURING",
  "DUKES",
  "Dulit",
  "Dumo",
  "Dunavox",
  "DUNCAN LOW LTD.",
  "DUNELM",
  "DUNELM MILL",
  "DUNG",
  "DUNGS",
  "DUNGS technic",
  "DUNGS/ LANDIS & GYR",
  "Dunham",
  "DUNHAM AND BUSH.",
  "DUNHAM BUSH",
  "Dunham Bush APV",
  "DUNHAM BUSH HAVANT LTD",
  "Dunham Switch Gear Ltd",
  "Dunham Switch Gear Sqaure D",
  "Dunham Switch Gear Square D",
  "DUNHAM-BUSH LTD",
  "Dunhan Switch Gear Square D",
  "Dunlop",
  "Dunlow",
  "DUNPHY",
  "DUNPHY INVERTER",
  "dunslop",
  "DUNSLOPE ELITE",
  "dunslope lite",
  "DUNSLOPElite",
  "DUNSLOPE-LITE",
  "DUO",
  "Duomo",
  "DUONET",
  "DUPAR CONTROLS",
  "Duplex",
  "dura pump",
  "DURABRAND",
  "DURACRAFT",
  "Durafan",
  "Duralit",
  "Duravit",
  "DURECOM",
  "Durha Switchgear",
  "Durham",
  "Durham Bush",
  "Durham Square D",
  "Durham Switch Gear",
  "Durham Switch Gear Square D",
  "DURHAM SWITCHGEAR",
  "Durnbury",
  "DURR DENTAL",
  "Durrance",
  "Duscholux",
  "DUSCOVENT",
  "DUSCOVENT ENGINEERING LTD",
  "DUST COLLECTOR",
  "DUST CONTROL EQUIPMENT",
  "Dust Control Solutions",
  "Dust Extraction",
  "Dust Extraction Ltd",
  "Dust Extractors",
  "Dustcheck",
  "DUTCHI MOTORS",
  "Dutchi Pumps",
  "Duty Point",
  "DUTYPOINT",
  "DUTYPOINT SYSTEMS",
  "DUTYPOINT-MIKROFILL SYSTEMS",
  "DUTYPOINT-SYSTEMS",
  "DUVINAGE S.A.R.L.",
  "DV",
  "DVA",
  "DVD",
  "DVD Leeds",
  "DVGW",
  "DVR",
  "dvr and engineering",
  "DVS",
  "DVTEL",
  "DW BLAKLEY",
  "DWA",
  "DWA Elga",
  "DWB",
  "DWEC GROUP",
  "DWEC LIMITED",
  "DWEC Limoted",
  "DWEC LTD.",
  "DWM Copeland",
  "DWS",
  "Dwyer",
  "DWYER INSTRUMENTS MC",
  "DX",
  "DY Designs",
  "DYNA",
  "Dynaco",
  "DYNACOLOR",
  "Dynair",
  "DYNAJET",
  "Dynalite",
  "Dynamic air product",
  "DYNAMIC CONSTRUCTION CO. LTD",
  "DYNAMIC PLASTIC",
  "Dynamics Cooking Systems Inc",
  "DYNASONICS",
  "Dynasty UPS",
  "Dynavert",
  "Dyson",
  "DYSSEY",
  "DZ",
  "DZG",
  "e",
  "E & E",
  "E & E.",
  "E & G",
  "E & I ENGINEERING",
  "E & I ENGINEERING (MERLIN GERIN)",
  "E & I ENGINEERING LTD",
  "E & I ENGINEERING LTD.",
  "E & I ENGINEERING LTD. (DARTPOINT LTD.)",
  "E & I ENGINEERING LTD. (MERLIN GERIN)",
  "E & I ENGINEERING LTD.(MERLIN GERIN)",
  "E & I Switchgear",
  "E & R MOFFATT",
  "E A Foulds Ltd",
  "E AND I ENGINEERING LTD",
  "E APPLEYARD",
  "E C Power",
  "E E I",
  "E F TULL LTD",
  "E Foulds",
  "E G Wilson",
  "E MACHINES",
  "E N Bray",
  "E N BRAY LTD",
  "E R MOFFAT LTD",
  "E SQUARED limited.",
  "E W Gohl",
  "E&G",
  "E&I",
  "E&I  Engineering Ltd",
  "E&I ENGINEERING",
  "E&I Engineering Ltd",
  "E&I ENGINEERING LTD.",
  "E&I ENGINEERINGLTD",
  "E&I ENGINEERUNG LTD",
  "E&R MODFATS",
  "E&R MOFFATS LTD",
  "E&S HEATING VENTILATION",
  "E.A.T",
  "E.G SIMPLEX.",
  "E.M.S",
  "E.N. BRAY LTD",
  "E.ON",
  "E.S.L. (UK) LTD",
  "E.T.M",
  "E.T.N.",
  "e+r moffat",
  "E210T",
  "EA Bundle Ltd",
  "EA FOULDS LTD",
  "Eabel",
  "EAC CONTROL CENTRE",
  "EAC CONTROLS",
  "Eaden",
  "EAE",
  "EAGLE AUTOMATION AND BFT",
  "EAGLE AUTOMATION AND VENTOUSES ELECTROMAGNETIQUES",
  "EAGLE AUTOMATION SYSTEMS LTD",
  "EAGLE SERVICE",
  "EAGLE SERVICES",
  "EAGLE SERVICES TITAN 30",
  "EAIS",
  "Eaitronic",
  "EAM",
  "Eans",
  "EARLSWOOD",
  "EARLSWOOD LIFT GATE AND SHUTTERS",
  "EARS",
  "EARTH SAVE PRODUCTS LTD",
  "Earthing System",
  "EASAT antennas",
  "EASDALE",
  "EASDALE 50",
  "Easeo",
  "EASI",
  "EASI RECYCLING",
  "Easikey",
  "EASIKEY 1000 PLUS",
  "EASILIFT LOADING SYSTEM",
  "Easilift Loading Systems",
  "Easivent",
  "EASIVO PRODUCTS",
  "EASIYO",
  "EAST COAST CONTROLS LTD",
  "EAST HEALTHCARE",
  "EAST MIDLANDS ELECTRICITY",
  "EAST MIDLANDS ELECTROCAL INSTALLATIONS LTD",
  "EAST MILNGAVIE CONTROLS",
  "EAST YORKSHIRE SHUTTERS LTD",
  "EASTEN SWITCHGEAR LTD.",
  "EASTERN ELECTRICITY",
  "EASTERN ELECTRICITY COMPANY",
  "Eastern Switchgear Ltd",
  "EASTERN TRANSFORMERS",
  "Eastern Switchgear",
  "EASTHILL FARADAY LTD",
  "EASTRON",
  "EASY CALL",
  "EASYCOLD",
  "Easy-Heat",
  "easykey",
  "EASYLIFT",
  "Easyzap",
  "eat memshield 3",
  "eater zap",
  "EATON",
  "Eaton / Powerware Sync Panel",
  "EATON 5130",
  "Eaton electric",
  "EATON ELECTRIC LTD",
  "EATON ELECTRICAL",
  "EATON ELECTRICS LTD",
  "EATON ELECTRICS LTD.",
  "EATON EXCEL",
  "Eaton Exel",
  "Eaton Glasgow",
  "EATON MEM",
  "EATON MEM 3",
  "Eaton Mem MEMSHEILDV3",
  "EATON MEM3",
  "EATON Memshield 3",
  "EATON NEM",
  "EATON POWER",
  "Eaton Powerware",
  "EATON WILLIAMS",
  "EATON WILLIAMS GROUP",
  "Eaton??",
  "EATON-ME",
  "EATON-MEM",
  "EATON-WILLIAMS GROUP LIMITED.",
  "EATON-WILLIAMS GROUP LTD.",
  "EATRON",
  "EAU COOLERS",
  "EAU FRAUCHE",
  "EAV",
  "eazeo",
  "EAZYTRAP",
  "EAZYZAP",
  "EB",
  "EB COMMUNICATIONS",
  "EB EQUIPMENT",
  "EB NITRON",
  "EBA",
  "Ebac",
  "EBAC LTD",
  "EBAC WATER COOLERS",
  "Ebana",
  "Ebara",
  "Ebara Pumps",
  "EBCO",
  "EBDS",
  "EBELT",
  "EBERLE",
  "EBES",
  "ebm",
  "Ebm Papst",
  "ebm ziehl",
  "ebmpabst",
  "EBMPAPST",
  "Ebmpast",
  "EBM-ZEIHL",
  "ebn papst",
  "EBRA",
  "EBRACO",
  "EBRAY",
  "EC COILS AND COOLER",
  "EC Power",
  "EC Products",
  "eca",
  "Ecac+Chair",
  "ECB ENVIRONMENTAL SYSTEMS LTD",
  "ECBI Red",
  "ECC",
  "ECC (East Coast Controls Ltd)",
  "ECC Moulded Breakers",
  "ECE",
  "ECE Dorchester",
  "ECE LTD.",
  "ECE Supply and Extract.",
  "ech20",
  "Echelon",
  "ECHO",
  "ECHTOP",
  "echtop motors",
  "Ecko",
  "Eckold",
  "Eclipse",
  "ECLIPSE MAGNETICS",
  "ECM",
  "ECO",
  "ECO AIR",
  "Eco Boiler",
  "ECO coil & coolers",
  "ECO COILS",
  "ECO COILS AND COOLERS",
  "ECO CONTROL SYSTEMS",
  "ECO COOLING",
  "ECO flame",
  "ECO FREEZE",
  "ECO HYGIENE",
  "ECO LAB",
  "Eco power",
  "ECO REFRIGERAZOINE",
  "Eco smart",
  "ECO VENT MAX",
  "ECO ZAP",
  "Eco air",
  "Eco Control",
  "ecoair",
  "ECOBOILER",
  "ECOCAB",
  "ecodan",
  "ECODISC",
  "ECODORA",
  "ECOENERGY",
  "ECOFLAM",
  "Ecofreeze",
  "ECOHEATER",
  "Ecokil",
  "ECOKILL",
  "Ecolab",
  "ECOLEC",
  "Eco-Logic UK",
  "ECOMAX",
  "econ",
  "Econ o Dry",
  "ECONERGY LTD",
  "Econheater",
  "Econic Renewable Energy Solitions",
  "ECONO FLAME",
  "Econ-o-Dry",
  "ECONO-HEAT",
  "ECONO-HERT",
  "ECONOLIGHT",
  "ECONOMAK CONTROL SYSTEMS",
  "econ-o-pck",
  "ECOPOWER",
  "ECOPOWER CONTROL PANEL",
  "Ecosmart",
  "ECOSMARY",
  "ecosoft",
  "ECOSSC",
  "ECOSSE",
  "EcoStar",
  "ECOTECH (UK)",
  "ECOZAD",
  "Ecozap",
  "ECS",
  "ECS Energy",
  "ECS ENERGY CONSERVATION LTD",
  "ECTA Fans",
  "ECU",
  "ECVEX",
  "Ed",
  "ED control panels",
  "ED MI",
  "ed200",
  "EDA",
  "EDAFIM",
  "EDAN",
  "Eddison",
  "EDDY JENKINS",
  "EDELHOFF",
  "EDEN",
  "Eden Brita",
  "Eden Dottle",
  "EDEN SPRINGS",
  "Edenair",
  "EDENAIRE",
  "EDENAIRE (EATON WILLIAMS) LTD.",
  "EDENBRIDGE",
  "Ederal Electric",
  "EDF",
  "EDF ENERGY NETWORKS",
  "EDF Energy Networks Ltd",
  "EDF LTD",
  "EDGAR",
  "EDGARS WATER",
  "EDGB",
  "EDGE",
  "Edge 10",
  "EDGER WATER",
  "Edghill",
  "EDI",
  "EDINCARE",
  "Edirol",
  "Edison",
  "EDL",
  "EDM",
  "EDME",
  "EDMI",
  "EDMI MK10",
  "EDMO",
  "EDMO LIFT",
  "edmu mk10",
  "edpac",
  "EDRON",
  "EDRON CONTROL SYSTEMS LTD",
  "EDRON CONTROL SYSTENS LTD",
  "EDS",
  "EDS GROUP",
  "Education Ducted Fume Cupboard",
  "Edward Dewhurst ltd",
  "Edwards",
  "EDWARDS MECHANICAL BOOSTER",
  "Edwards Modukar Controls",
  "EDWARDS MODULAR CONTROLS",
  "EDWARDS MODULAR CONTROLS LTD",
  "EDWARDS MODULAR CONTROLS LTD.",
  "Edwards Standpipes",
  "EDWARDS TRUEFORM",
  "EDWCA",
  "EE",
  "EEC",
  "EEC Limited",
  "eee",
  "EEF",
  "EELCONTROL",
  "Eemax",
  "EEMIC LTD",
  "EEP",
  "EES",
  "Eface",
  "EFACEC",
  "EFAFLEX",
  "EFAFLU",
  "Efan",
  "EFD",
  "EFEN",
  "EFENSOR",
  "eff",
  "EFFEKTA",
  "Efficient Energy Systems",
  "EFFICIENT ENERGY SYSTEMS LTD",
  "eFlow",
  "E-FOUR CORPORATION",
  "EFSC",
  "EFWN",
  "EG&G",
  "EGART",
  "EGD",
  "EGENIX",
  "EGGSys215",
  "EGM",
  "EGRO",
  "EGS",
  "EI",
  "EIA",
  "EIC",
  "EIMERCER",
  "EIMETER",
  "EINHELL",
  "Eirann Industrial",
  "EIS",
  "Eisele",
  "Eisfink & Rieber",
  "EIS-KALT",
  "EISTER",
  "EIZO",
  "EJYYEK",
  "EK",
  "EKCO",
  "EKECTRO SITE (UK) LTD",
  "EKOM",
  "EKP-COOL",
  "EL BJÖRN",
  "EL COLD",
  "EL WHS Ltd",
  "Elados",
  "ELAN",
  "ELAN LIFTS",
  "ELANA",
  "ELBAC",
  "Elbi",
  "ELBN",
  "ELBZ",
  "Elco",
  "ELCODE",
  "Elcold",
  "Elcom",
  "ELCOMPONENTS",
  "Elcon",
  "ELCONTROL",
  "elcosta SOLZGITTER",
  "ELDOM INVEST",
  "ELDON",
  "ELDON SWEDEN",
  "ELECKTROMATEN",
  "Elecrapac",
  "ELECSERVICE COMPUTER POWER & ENVIRONMENTAL SERVICE",
  "ELECSIS",
  "elect",
  "ELECTO SITE (UK) LTD",
  "Electo Wind",
  "ELECTO WIND LTD",
  "Elector",
  "ELECTOR SITE (UK)LTD",
  "ELECTOSTAR",
  "ELECTR",
  "ELECTR WIND LTD",
  "Electra",
  "ELECTRA EV VITORIA",
  "Electra flora",
  "Electra Switch",
  "Electra Switch Ltd",
  "Electra Vitoria",
  "ELECTRAC",
  "Electract",
  "Electraction",
  "Electrak",
  "ELECTRALITE CONTROL INSTALLATION LTD.",
  "Electralux",
  "Electranet",
  "Electraogas",
  "ELECTRAPAC",
  "ELECTRASITE CONTROL INSTALLATIONS LTD.",
  "electric",
  "Electric ceiling mounted radient panels",
  "ELECTRIC CONTROLS LTD",
  "ELECTRIC DESCALER",
  "Electric ltd",
  "ELECTRIC MOTOR CONTROLS",
  "ELECTRIC WATER HEATING CO",
  "Electric Tribune",
  "ELECTRICAL CONTROL ENGINEERING",
  "ELECTRICAL CONTROLS HUMBERSIDE LTD.",
  "ELECTRICAL DESIGN & CONTROL",
  "Electrical Equipment",
  "ELECTRICAL SWITCHGEAR SERVICES LTD",
  "Electrical Test and Inspection Ltd",
  "Electrical UK Ltd",
  "Electricare",
  "ELECTRICIDAD LASSER",
  "electricshower redring plus",
  "Electrinic",
  "ELECTRIQ",
  "ELECTRISKA",
  "ELECTRIX",
  "ELECTRLUX WASCATOR",
  "Electro",
  "Electro Adda",
  "ELECTRO AUTOMATION",
  "Electro Control",
  "ELECTRO CONTROLS",
  "ELECTRO CONTROLS GB",
  "ELECTRO CONTROLS LTD",
  "Electro gas",
  "Electro Helios",
  "ELECTRO INDUSTRIES",
  "ELECTRO INDUSTRIES GAUGE TECH",
  "ELECTRO ITE (UK) LTD",
  "ELECTRO LIFTS",
  "ELECTRO MAG",
  "ELECTRO MECHANICAL CONSTRUCTION",
  "ELECTRO MECHANICAL CONSTRUCTION LTD.",
  "Electro Mechanical Manufacturing Co Ltd",
  "electro s ite",
  "Electro Scott Transformers",
  "ELECTRO SITE",
  "ELECTRO SITE (IK) LTD",
  "ELECTRO SITE (KU) LTD",
  "ELECTRO SITE (UK) LTD",
  "ELECTRO SITE LTD",
  "ELECTRO SITE UK",
  "ELECTRO VAC",
  "Electro Vap",
  "Electro Win Scotland",
  "ELECTRO WIND LTD",
  "Electro Static",
  "ELECTROBAR",
  "Electrobas",
  "ELECTROBAY",
  "ELECTRODRIVES LTD",
  "Electroflora",
  "electrogas",
  "Electroheat Plc",
  "Electroil Limited",
  "ELECTROL",
  "Electrolite Ltd",
  "ELECTROLUX",
  "ELECTROLUX COMMERCIAL SERVICES",
  "ELECTROLUX PROFESSIONAL",
  "Electrolux Zanussi Industrial s.a.",
  "Electromag",
  "ELECTROMATEN",
  "ELECTROMATEN (GUNNEBO)",
  "Electromatin",
  "Electromeccanica",
  "ELECTROMECH ENGINEERING",
  "Electrometen",
  "ELECTROMISER",
  "ELECTROMO",
  "ELECTROMOTORS LTD",
  "ELECTRON",
  "ELECTRON CORPORATION",
  "ELECTRON SUSSEX LTD",
  "Electronet Intergrated Controls Ltd",
  "Electronic",
  "Electronic Controls",
  "Electronic Devices Ltd",
  "ELECTRONIC FLUID TREATMENT TECHNOLOGY LTD.",
  "ELECTRONIC TECHNOLOGY LTD",
  "Electror",
  "ELECTRO-SCOT TRANSFORMERS",
  "Electro-Serve Controls Ltd",
  "Electrosite",
  "ELECTROSITE IK",
  "ELECTROSITE UK",
  "ELECTROSITE UK LTD",
  "Electrosonic",
  "ELECTROSTAR",
  "Electro-Tech",
  "ELECTROTECH CONTROL DESIGN SERVICES",
  "ElectroVap",
  "Electroway",
  "ELECTRO-WIN SCOTLAND",
  "ELECTROWIND",
  "ELECTRO-WIND",
  "ELECTRO-WIND , SCOTLAND",
  "ELectro-wind Limite",
  "electro-wind limited",
  "ELECTROWIND LTD",
  "ELECTRO-WIND LTD",
  "ELECTRWIND LTD",
  "Eledyne",
  "Eledyne Limited",
  "ELEKTRA",
  "ELEKTRA BECKUM",
  "Elektraction",
  "ELEKTRO GAS",
  "ELEKTRO SYSTEMS BENDER",
  "ELEKTRO-AUTOMATIK",
  "Elektromaten",
  "ELEKTROR",
  "Elektro-Schalt-und-Regenkagen",
  "Elektrosite",
  "ELEMOL",
  "ELENA",
  "elentek",
  "ELEPHANT",
  "Elephante",
  "Elequip",
  "Elero",
  "ELETAC",
  "eleter",
  "Eletromaten",
  "Elettromeccanica di marinate",
  "Elettromeccanica di marnate",
  "Elex",
  "Elextralux",
  "Eley & Gibbons",
  "Elfab aluminium ltd",
  "elga",
  "ELGA process water",
  "Elga Environmental",
  "Elgar",
  "ELGEE",
  "ELGEE-LANDIS & GYR",
  "ELGENTO",
  "Eli Ben Ezra",
  "ELICA",
  "ELICA / COOKER HOODS LTD",
  "ELIMINATOR",
  "ELISON",
  "ELITE",
  "ELITE CONTROLS LTD",
  "ELITE ELEVATOR",
  "Elite Elevators",
  "ELITE SECURITY",
  "ELITEC",
  "Eliwell",
  "ELKA",
  "Elkay",
  "ELKAY FOOD SERVICE PRODUCTS",
  "ELKISON",
  "ELKOSTA",
  "ELKRO GAS",
  "Ellard",
  "ELLARD ELECTRIC",
  "Ellickson",
  "Ellickson Engineering",
  "Elliot",
  "Elliot Williams",
  "ELLIS & MADOUGAL LIFTS LTD",
  "ELLIS LTD",
  "Ellis Tylin",
  "Ellison",
  "Elliwell",
  "Ellner",
  "ELMATIC LTD",
  "ELMC CONTROLS HUDDERSFIELD",
  "ELMDENE",
  "elmdene international",
  "ELMDENE INTERNATIONAL LTD",
  "Elmec",
  "ELMECER",
  "ELMIC CONTROLS",
  "ELMO RIETSCHLE",
  "Elmontage",
  "ELNOR",
  "Elo",
  "Elo Systems",
  "Eloma",
  "ELON",
  "ELP",
  "Elpine",
  "E'LRCTROMAG",
  "els",
  "ELSAN",
  "elsar",
  "Elser",
  "ELSMARK",
  "ELSON",
  "Elson Pear",
  "elson pearl",
  "ELSRER",
  "Elste",
  "Elstec",
  "Elstek",
  "ELSTER",
  "ELSTER (JEAVONS)",
  "Elster / Technolog",
  "elster a1140",
  "Elster Els",
  "ELSTER JEAVONS",
  "ELSTER Krum Schroder",
  "ELSTER METERS",
  "ELSTER Siemens",
  "ELSTER THEMIS",
  "Elstex",
  "ELSTO",
  "Elston",
  "Elstor",
  "elswter a1140",
  "ELSY & GIBBONS",
  "ELTA",
  "ELTA COMPACT 2000",
  "ELTA FAN",
  "ELTA FANS",
  "ELTA FANS LTD.",
  "ELTA FANS.",
  "ELTA REVOLUTION",
  "Elta Select",
  "ELTAFAN",
  "ELTEC",
  "Eltherm",
  "ELTIME",
  "ELTN",
  "ELTRA",
  "Eltri",
  "ELTROBAY",
  "Eltron",
  "Elu",
  "elvacon",
  "Elvira",
  "ELVOX",
  "EM",
  "EM ENTREMATIC",
  "EM PHONE",
  "Em37 test",
  "Emac",
  "Emainox",
  "EmB",
  "Embargo aspera",
  "Embpast",
  "EMBRACO",
  "EMBRACO ASPERA",
  "Embray",
  "EMC",
  "EMC LTD",
  "Emco",
  "Emcolight",
  "EMCON",
  "Emconvent",
  "EMCOR",
  "EMCOR ENGINEERING",
  "EMCOVENT",
  "EMDI",
  "EME INSTALLATIONS LT",
  "EME INSTALLATIONS LTD",
  "EMEA",
  "EMEC",
  "EMEG",
  "emeg chesterfield",
  "EMEG LTD",
  "EMEG M AND E",
  "EMEGILITE",
  "EMELMOL",
  "EMEM",
  "EMERALD",
  "Emergency Gas Valve",
  "EMERGENCY LIGHTING",
  "emergency lights",
  "EMERGENCY POWER SYSTEMS",
  "Emergency Systems",
  "EMERGENGY LIGHTING PRODUCTS",
  "EMERGGI-LITE",
  "Emergi light",
  "EMERGI LITE",
  "EMERGI=LITE",
  "emergilight",
  "Emersion",
  "EMERSON",
  "EMERSON / VOLVO",
  "EMERSON COPELAND",
  "Emerson Electric Co",
  "Emerson Liebert",
  "EMERSON NETWORK POWER",
  "EMERSON NETWORK POWER-CHLORIDE",
  "EMERSON-LEIBERT",
  "Emessem",
  "Emetco",
  "EMEX POWER",
  "EmexPower",
  "EMF LIVE",
  "EMFONE",
  "EMG",
  "EMG ELETTROMECCANICA",
  "EMGR",
  "EMH",
  "EMH FABRICATIONS",
  "EMH Fabrications Ltd",
  "EMH International",
  "EMH International Ltd",
  "EMICO",
  "Emicon",
  "emilight",
  "EMLITE",
  "Emma",
  "EMMAN",
  "EMMERSON",
  "EMMERSON INDUSTRIAL DOORS",
  "Emmi",
  "emocovent",
  "EMOTRON",
  "Empire Controls",
  "EMPIRE INDUSTRIAL DOORS",
  "EMPOWER",
  "emrgilight",
  "EMS",
  "EMS POWER",
  "EMS SYSTEMS LTD",
  "EMS UNIVERSAL RANGE +",
  "EMS T&P Fire",
  "EMTEC",
  "EMU MONITORING SYSTEMS",
  "EM-VENT",
  "En - Radiation Equipment LTD",
  "EN Bray",
  "enamel",
  "ENBRAY",
  "ENCODER ELSTER",
  "Encon",
  "ENCORE",
  "Endenaire",
  "ENDERBY",
  "Endress & Hauser",
  "endress-hauser",
  "ENDRESSN HAUSER",
  "Enduramax",
  "Enduramaxx",
  "Eneo",
  "ENER.G COMBINED POWER",
  "ENERAY",
  "Energ",
  "ENER-G / SIEMENS",
  "ENER-G COMBINED POWER",
  "ENERGAIR",
  "ENERGI",
  "ENERGI.G COMBINED POWER",
  "Energic Plus",
  "Energotech",
  "Energy",
  "Energy Assets",
  "ENERGY ASSETS LTD.",
  "ENERGY CONTROL",
  "ENERGY CONTROL PANEL",
  "ENERGY CONTROLS",
  "ENERGY EFFICIENT CONTROLS",
  "ENERGY MANAGEMENT & CONTRO;",
  "Energy management and control systems",
  "Energy management and enviromental control systems",
  "ENERGY MANAGEMENT LTD",
  "ENERGY MANAGEMENT SYSTEMS",
  "ENERGY TECGNIQUE",
  "Energy Technique",
  "Enerlux",
  "ENERMET",
  "Enerpac",
  "ENERPAC  PRESS",
  "ENERSHIELD EU",
  "EnerSys",
  "ENFORD LIFTS LTD",
  "ENFRIGO",
  "Engart Fans",
  "Engineered Air Treatment",
  "Engineering Appliances",
  "Engliish Electric",
  "English Electric",
  "ENGLISH ELECTRIC C0",
  "ENGLISH ELECTRIC Co. Ltd",
  "English Electric Company",
  "ENGLISH ELECTRIC DIESELS LTD",
  "ENGLISH ELECTRIC DIESELS LTD. DORMAN",
  "English Electrical",
  "ENGLISH ELECTRICAL COMPANY",
  "English Electric",
  "Enigma",
  "ENITRAM",
  "Enlo Systems",
  "ENNA",
  "ENNIS",
  "Ennis Handling Systems",
  "ENODIS",
  "ENODIS ICE O-MATIC",
  "ENODIS VISCOUNT CATERING LTD",
  "ENOFRIGO",
  "ENOMATIC",
  "ENROOTECH",
  "Enstar",
  "ENSTO PUMP SERVICE AND TECHNOLOGY",
  "Ensura systems ltd",
  "ENSYS",
  "ENSYS - Churchill",
  "ENT",
  "ENT AXIA",
  "Enter view 4",
  "Enterprise control engineers",
  "ENTERPRISE CONTROLNGINEERS LTD",
  "ENTERPRISE ECOLOGY",
  "Enterview",
  "Enthermics Medical Systems",
  "ENTITY",
  "Ently",
  "ENTRAL CHEMICAL SERVICES LTD",
  "Entre Matic",
  "entreamaric",
  "Entrematic",
  "Ēntrematic",
  "ENTROTEC LTD.",
  "ENTROVIEW",
  "ENTRVIEW",
  "Entry Building Video display / Audio playback",
  "Entryphone",
  "ENTRYVIEW",
  "ENUX",
  "ENVAIR",
  "ENVAIR LTD",
  "ENVERTON",
  "Envertron",
  "ENVIGIL",
  "ENVIREAU",
  "ENVIRO BULKA",
  "Enviro Chemie",
  "ENVIRO VENT",
  "Envirochemie",
  "ENVIRODOOR",
  "ENVIRODOOR MARKUS",
  "Envirograf",
  "ENVIROHEAT",
  "Enviromental",
  "ENVIROMENTAL CONTROL SYSTEMS LTD",
  "ENVIROMENTAL PRODUCTS",
  "Enviromental water systems",
  "Environ Heat",
  "ENVIRONAIRE",
  "ENVIRONHEAT",
  "ENVIRONMENT",
  "Environmental & gas monitoring",
  "Environmental Automatics Limited",
  "Environmental Automatics Ltd",
  "ENVIRONMENTAL CONTROL SYSTEM LTD",
  "ENVIRONMENTAL CONTROLS",
  "Environmental energy controls",
  "ENVIRONMENTAL ENERGY SERVICES LTD",
  "ENVIRONMENTAL GB LIMITED",
  "ENVIRONMENTAL LTD.",
  "Environmental Products & Services",
  "Environmental Treatment Concepts",
  "Envirotac",
  "envirotank",
  "envirotanks",
  "ENVIROTEC",
  "ENVIROTEC LTD.",
  "Envirotech",
  "Enviro-Tech",
  "Envirotech alarms Ltd",
  "Envirovac",
  "Envirovent",
  "ENVIROWARM",
  "Envirox",
  "Envirtanks",
  "ENVISION",
  "ENVISTAR",
  "envistar compact",
  "envistar flex",
  "Enwa",
  "ENWA WATER TREATMENT",
  "ENWAMATIC",
  "EOGB",
  "EOGB MONOLITE",
  "EOLC",
  "EOLOGICO",
  "EON",
  "EORLD DRYER",
  "EP LTD",
  "EP SYSTEMS LTD",
  "EPC",
  "Epco",
  "EPCS",
  "EPDM",
  "EPH Controls",
  "EPM AIR CONDITIONING",
  "EPOC",
  "EPPENDORF",
  "EPS Systems",
  "EPSILON",
  "EPSOM",
  "Epson",
  "EPTA",
  "EQUIPLINE",
  "Equipment CO of America",
  "Equipment Electrique Industriel",
  "ER MOFFATS",
  "ERA",
  "ERA (top and bottom locks) Chubb (middle lock)",
  "ERANCO",
  "Erara",
  "Erator",
  "Erbauer",
  "Erco",
  "Erco Thermotube",
  "ERCOLLI MARELLI ARGENTINA",
  "EREBUS",
  "erer",
  "Eres",
  "ERG",
  "Ergo",
  "ERGOLINE",
  "ERGON",
  "Erica",
  "Ericsson",
  "ERIF",
  "Eriks",
  "Erin Industrial copper",
  "ERIS",
  "ERL",
  "ERLAB GROUP",
  "ERNITEC",
  "ERNST VOGEL",
  "Eroline",
  "ERP",
  "ERRE",
  "Erreka",
  "ERS",
  "ERSKIN",
  "ERSKINE",
  "ERSKINE SYSTEMS LTD",
  "ERSKINE SYSTEMS LTD.",
  "Erv",
  "ES SYSTEM",
  "ES SYSTEMS",
  "ESA",
  "ESA McIntosh",
  "ESAB",
  "ESAM",
  "Esavian",
  "ESBE",
  "ESC 1000",
  "Escape",
  "ESCAPE MOBILITY COMPANY",
  "ESCHMAN",
  "Eschmann",
  "ESCO",
  "ESDMO",
  "ESE",
  "ESG",
  "ESG ENVIRONMENTAL SCIENTIFICS GROUP",
  "ESG WATERWISE",
  "ESH",
  "ESH Trace Heating",
  "ESH TRACE HEATING LTD.",
  "ESI.S.CONIC",
  "ESITALIANI",
  "ESL",
  "Esl Electrmech ltd",
  "ESL UK",
  "ESL UK LTD. (GEC)",
  "Eslter",
  "ESMI",
  "ESP",
  "Esp ecocent",
  "Espa",
  "ESPA (UK) LTD",
  "ESPA PUMPS (UK) Ltd.",
  "ESPC 3",
  "ESPEX BATTERIES TD",
  "Espresso",
  "ESPRESSO ITALIANO",
  "E-Squared",
  "ESQUIRE",
  "ESS",
  "ESS LTD",
  "Esser",
  "ESSEX",
  "ESSEX DOORS",
  "ESSEX INDUSTRIAL",
  "ESSEX INDUSTRIAL DOORS",
  "ESSEX MECH SERVICES",
  "Essex pneumatics",
  "Essex pneumatics Ltd",
  "ESSEX TECHNOLOGIES CORPORATION",
  "Essex Electrical",
  "EST",
  "EST ecosmart",
  "ESTA",
  "ESTEL",
  "ESTER",
  "Estler",
  "ESTON",
  "ESWGR2",
  "ETA",
  "ETA LINE",
  "ETAG",
  "E-Tail",
  "ETAP",
  "ETATRON",
  "ETATRON DS",
  "ETE SCIM",
  "E-Tec",
  "ETERMA",
  "Eterna",
  "Eterna Lighting",
  "ETERNAL",
  "ETHERINGTON AIR CONDITIONING",
  "ethos",
  "ETI",
  "ETL",
  "ETM MEM",
  "ETN - MEM",
  "ETN MEM",
  "Etna",
  "ETON",
  "ETON MEM",
  "ETON MEMSHEILD 3",
  "ETS CABLE COMPONENTS",
  "ETS CABLE COMPONENTSL",
  "ETS Solutions",
  "ETTH FABRICATIONS",
  "Etw Mem",
  "EU",
  "EU FIRE",
  "Eubanks",
  "EUK CONTROLS",
  "EUNICAT",
  "Euracast",
  "Euramo",
  "EURCAL",
  "EURE ITALY",
  "EUREKA",
  "eureka (short model)",
  "EUREKS",
  "Eurica",
  "EURO AIR",
  "EURO AIR SYSTEMS",
  "Euro Diesel",
  "EURO DIESEL (UK) LTD",
  "euro fire",
  "EURO FIRE EN54",
  "EURO FIRE EN54 EMRGI -LITE",
  "EURO FRIGO",
  "Euro Gas",
  "EURO GAS 1",
  "EURO GAS 4",
  "EURO KELFAL",
  "EURO MERIDIAN",
  "EURO SHUTTERS",
  "Euro Spec",
  "Eurocave",
  "EUROCEL",
  "Eurocell",
  "EUROCHILLER",
  "Eurocoils",
  "EUROCOLD",
  "Eurodial",
  "EUROFIRE",
  "EUROFIRE EN54",
  "Euroflex",
  "Eurofluid",
  "EUROFOUR",
  "EUROFOURS",
  "eurofox",
  "EUROFRED",
  "Eurofyre",
  "Eurogauge",
  "EUROGRILL",
  "Euroguage",
  "EuroKlimat",
  "EUROLINE",
  "Euromax",
  "EURO-MERIDIAN",
  "EUROMETERS LTD",
  "EUROMINI",
  "EUROMO",
  "EUROMOTORI",
  "EUROPA",
  "EUROPA COMPONENTS",
  "EUROPA DIGICAL",
  "Europa-Leda",
  "EUROPEAN EMC PRODUCTS LTD",
  "EUROPEAN PROFILES",
  "EUROPEAN PROFILES LTD",
  "European Vacuum Drainage System",
  "European Vacuum Drainage systems",
  "EUROPEAN WATERCARE",
  "european watercare ltd",
  "EUROPHASE UK LTD.",
  "EUROPLEX",
  "EUROPLEX TECHNOLOGIES",
  "EUROPLEX TECHNOLOGY",
  "EUROPRESS",
  "EUROPROGET",
  "Eurorace",
  "EUROSCALES",
  "euroscreen",
  "Eurosec",
  "EUROSFOUR",
  "Eurotech",
  "EUROTECH FIRE PROTECTION",
  "Eurotherm",
  "Eurotherm Chessell",
  "eurotherm drive",
  "EUROTHERM DRIVES",
  "Eurotrace",
  "EUROTRACE UK LTD",
  "Eurotrol",
  "Eurovalve",
  "Eurovent",
  "EUROWASH 1000",
  "EUROWELD",
  "EUROZAP",
  "EURPEAN PROFILES LTD",
  "EURPLEX",
  "eusenmann",
  "EVA SAFE",
  "EVA+CHAIR INTERNATIONAL LTD.",
  "evac",
  "EVAC R S",
  "EVAC+CHAIR",
  "Evac+Chair Int",
  "Evacu8",
  "Evacuation Chair Equipment.",
  "EVACUATION CHAIRS",
  "EVACUCHAIR",
  "Evans",
  "Evans Lift",
  "EVANS LIFT LTD.",
  "EVANS LIFTS",
  "Evans Lifts Ltd",
  "EVANS LIFTS LTD.",
  "EVANS UNIVERSAL",
  "Evapco",
  "EVARA",
  "EVCO",
  "EVCS",
  "EVENS",
  "EVENS LIFT",
  "Evenwood Engineering",
  "EVER BLU CYBLE",
  "EVER FOCUS",
  "Everbitu",
  "everblu",
  "EVERBLU CYBLE",
  "Everest",
  "EVERHOT",
  "Everpure",
  "EVERSAFE MKTG S.B.",
  "EVERSYS",
  "Everything X-Ray",
  "EVESAFE",
  "EVINOX",
  "evinstar",
  "Eviro Store",
  "Eviromental Automatic Controls",
  "EVL",
  "EVO",
  "EVO +R",
  "EVO+R",
  "EVOLEC",
  "Evolution",
  "EVOLUTION 1402",
  "EVOLUTION 1502",
  "EVOLUTION CAUSTIC 25",
  "Evolve",
  "EVOLVE GROUP",
  "EVOLVEOUP GR",
  "EVOMAX",
  "EVOPLUS",
  "EVOQUA",
  "E-wave",
  "EWB",
  "EWHURST CONTROL",
  "EWHURST CONTROL LTD",
  "EWHURST CONTROL SYSTEMS LTD",
  "EWHURST CONTROLS",
  "EWHURST CONTROLS/TREND",
  "EWM",
  "ews",
  "EWT",
  "EX OR",
  "Exacq Vision ELP",
  "Exal",
  "EXCAL DRYER",
  "Excalibur",
  "Excel",
  "EXCEL DRYER",
  "EXCEL DRYERS",
  "EXCEL GRINDER",
  "Excel Hand Dryer",
  "Excel Machine Tool",
  "Excel Pumps",
  "Excel Tools",
  "Excelsior",
  "exchange engineering",
  "Excide",
  "EXECUTOR",
  "Exel",
  "EXEL DRYERS",
  "EXELIPTZ",
  "exen",
  "Exeway air conditioning",
  "Exexway air conditioning",
  "EXFLOW 90-5 UCVSYSTEM",
  "Exhausto",
  "EXHAUSTO A/S",
  "Exiatalite",
  "exico",
  "EXICUTOR",
  "Exide",
  "EXIDE TECHNOLOGIES GROUP",
  "Exidoor",
  "Exidor",
  "Existalite",
  "Exit guard",
  "exit master",
  "Exit safe",
  "Exitgaurd",
  "EXITGUARD",
  "exitMASTER",
  "EXMI",
  "EXO",
  "EXO DRAFT",
  "EXOCUTOR",
  "Exodraft",
  "EXODUS",
  "EX-OR",
  "EXOTERIC",
  "EXPLAIR",
  "EXPO",
  "EXPO INOX",
  "EXPO INPX",
  "EXPOBAR",
  "Express",
  "express air",
  "express ar",
  "EXPRESS EVANS",
  "express evans (car)",
  "EXPRESS EVANS LIFTS",
  "Express Fire Equipment",
  "EXPRESS LIFT COMPANY",
  "Express Lifts",
  "Express Shutters",
  "EXPRESS SHUTTERS LTD.",
  "EXPRESS TRANSFORMERS",
  "expressair",
  "express-air",
  "EXPRESSO",
  "EXPRESSO ITALIANO",
  "EXQUISIT",
  "EXTECH",
  "EXTINGUISHANT SYSTEMS",
  "EXTINGUISHANT SYSTEMS  H and k",
  "Extra Varem",
  "EXTRACT FAN",
  "Extracted Fan",
  "extractor fan",
  "Extralis",
  "Extravarem",
  "extrema",
  "Extroma",
  "Extron",
  "EYE PRO LTD",
  "EYS",
  "EZ Limo",
  "ez lock",
  "Ezgo",
  "EZH4/LITE",
  "EZIOT",
  "Ezra",
  "EZRA HATTON",
  "EZVISION",
  "F & G",
  "F & S ENGINEERING",
  "F C FROST LTD",
  "F G WILSON (ENGINEERING) LTD.",
  "F G WILSON LTD",
  "F H Biddle",
  "F G Wilson",
  "F&G",
  "F&R PRODUCTS",
  "F. G.Wilson",
  "F.A Control",
  "F.A. FOULDS",
  "F.C. FROST LTD",
  "F.E. WILSON",
  "F.E.M LIMITED",
  "F.E.M LTD",
  "F.G Wilson",
  "F.G. WILSON",
  "F.G.WILSON",
  "F.G.WILSON-PERKINS",
  "f.illi.ferrari",
  "F.J. EDWARDS LTD",
  "F.LLI FERRARI VENTILATIORI INDUSTRIALL",
  "F.R. Electrical Ltd.",
  "F.R.C. PN ITALY",
  "F.S.E systems",
  "F2OS",
  "F4IGADAIRE",
  "FA",
  "FA SYSTEM",
  "FAAC",
  "FAAC / dss autodoors",
  "FAAC SPA",
  "FAAS",
  "FAAST LTD.",
  "FAB",
  "FABCO",
  "FABDEC",
  "FABRICANTE",
  "FABRICANTE DEPOSITOS COBALLES, S.L.",
  "FABRICANTE DEPOSITOS COBALLS, S.L.",
  "FABRICATED",
  "FABRICATED DOSING POT",
  "fabricated productions",
  "FABRICATED PRODUCTS",
  "FABRICATED PRODUCTS 3.5 LTR",
  "FABRICATED PRODUCTS IK",
  "FABRICATED PRODUCTS LTD.",
  "FABRICATED PRODUCTS UK",
  "FABRICATED PRODUCTS UK.",
  "FABRICATED PRODUCTS. 25 LTR",
  "Fabricater Products UK",
  "FABRICATOR RODUCT",
  "FABRICTED PRODUCTS UK",
  "FABS 4",
  "FAC",
  "FACADE CRADLES",
  "FACADE HOIST",
  "Facade Hoists",
  "FACADES HPISTS LTD",
  "FACC",
  "FACE",
  "FACE MACANDA",
  "Facel",
  "FACET INTERNATIONAL",
  "FACON",
  "FACT AIR",
  "FACULTATIEVE TECHNOLOGIES",
  "FADESA",
  "Fadini",
  "FADL",
  "FADN",
  "FAEMA",
  "FAEMA EMBLEMA",
  "FAEMA EMBLEMA GIUGIARO",
  "FAFNIR",
  "FAFS",
  "Faico",
  "faig",
  "Fairford Electronics",
  "FAIRWAY",
  "Fajitsu",
  "Fakcon",
  "Falco",
  "FALCOM",
  "FALCON",
  "FALCON DOMINATOR",
  "FALCON FIRE PROTECTION PRODUCTS",
  "FALCON FOOD EQUIPMENT",
  "falcon G2122",
  "FALCON LANDON KINGSWAY",
  "FALCON PROTECTION",
  "FALCON SYSTEMS LTD.",
  "FALKT",
  "Faltec",
  "Faltech",
  "Fam",
  "Famco",
  "FAN",
  "FAN ASIA",
  "FAN AXIA",
  "Fan B",
  "Fan Engineering",
  "FAN ENGINEERING (MIDLAND) LTD",
  "Fan Engineering (Midlands ) Ltd",
  "fan engineering ltd",
  "fan engineering LTD.",
  "FAN MOTOR-FFD AUSTRIA",
  "Fan speed controller",
  "Fan Systems",
  "Fan Thoruix",
  "FANDIS",
  "FANFASIA",
  "FANFASTA",
  "Fanitsu",
  "FANRICATED PRODUCTS LTD",
  "FANS & BLOWERS",
  "FANS 4",
  "FANS AND BLOWERS",
  "Fans Direct",
  "Fansdirect",
  "Fantech",
  "Fantini",
  "Fantini Cosmi",
  "FANTINNI GOBMI",
  "Fantronix",
  "Fantrow LX",
  "Fap Engineering",
  "FAR",
  "Farfisa",
  "Fargo",
  "Faring",
  "FARRAR",
  "Farrington",
  "fas ltd",
  "FASCO",
  "Faselinic",
  "FASS",
  "Fast",
  "Fast Dry Technology",
  "Fast Freezer Refrigeration Branded",
  "FAST -REGEN",
  "FAST TECHNOLOGIES",
  "FASTDRY",
  "Fastel",
  "FASTEL INK",
  "FASTER",
  "Fastlane",
  "Fastline",
  "FASTNET",
  "fastron",
  "FAURE",
  "FBC",
  "FCF",
  "FCP 1-8",
  "FCX SYSTEMS",
  "FD-1",
  "FDB",
  "FDL",
  "fds",
  "FE",
  "FE ELECTRIC",
  "FE Interlogix",
  "fecon",
  "FED EMG.",
  "fedarel",
  "FEDERA",
  "Federal",
  "Federal Electric",
  "Federal Pioneer",
  "FEEDBACK",
  "Feedwater",
  "FEEDWATER LTD.",
  "FEEHAM",
  "FEERANTI",
  "FEIG",
  "FEL Valves",
  "FEL VLAVES",
  "Felco",
  "FELDER",
  "FELEX",
  "Fellowes",
  "Fem",
  "FEM LTD",
  "Fence & Video Motion Detection (PIDS)",
  "FENNER",
  "Fenroli",
  "Fenwal controls Japan",
  "FER",
  "Feranti",
  "FERCELL",
  "Fercelo",
  "FERGASON MUNRO",
  "FERGUSON MUNRO & PARKER",
  "Ferham",
  "Fermax",
  "Fermex",
  "FERMOD",
  "FERMOND",
  "Fern Howard",
  "Fernham",
  "Ferno",
  "FERNO-WASHINGTON",
  "FERNO-WASHINGTON INC",
  "FERNOX",
  "FERR",
  "Ferranti",
  "Ferranti Hollinwood",
  "FERRANTI MEASUREMENTS",
  "FERRARI",
  "Ferrati",
  "FERRENTI",
  "FERRIER PUMPS LTD",
  "ferrofort",
  "FERROLI",
  "FERRO-MAG",
  "FERRO-MAG ELECTRICAL LTD.",
  "Ferrups FE Series",
  "FERWELL ENGINEERING LTD.",
  "FESTO",
  "Festool",
  "Fetco",
  "FFAAC",
  "fff",
  "FFUUSS",
  "FG WILLSON (PAXMAN)",
  "FG WILSON Caterpillar",
  "FG WILSON LTD",
  "FG WILSON/PERKINS",
  "Fg Wilson",
  "FG-A",
  "FG-SYS",
  "FHD",
  "FIA",
  "Fiac",
  "fiam",
  "Fiamm",
  "FIAMMA",
  "Fibox",
  "Fibre Optic Terminations",
  "Fibre Optic Transceivers",
  "FIBREGLASS",
  "FIBRESAFE",
  "Fichet Bauche",
  "Fickstone",
  "FIDELIX",
  "FIELD",
  "FIELD AND GRANT",
  "FIELD CONTROLS",
  "FIELD IRRIGATION GB LTD",
  "Fielden",
  "Fieldwell Energy",
  "fier tec",
  "fiertec",
  "FIFE ALARMS",
  "FIG",
  "FIGIDAIRE",
  "Fiji",
  "Fike",
  "FILKE",
  "Filli Ferrari",
  "Fillpoint",
  "FILT AIR CORP",
  "Filter Pure",
  "Filterpure",
  "FILTERTECHNIC",
  "FILTERTECHNIK",
  "Filtration Ltd",
  "Filux",
  "FIMAR",
  "FIN COIL",
  "finaflow",
  "FINCHBAC",
  "FINCOIL",
  "Fincol",
  "Finder",
  "FINDLAY, IRVINE LTD",
  "findlay,irvine ltd",
  "Fine apply Ltd",
  "fine elements",
  "Fini",
  "FINI COMPRESSORS",
  "FINN AIR",
  "FINNA PLASTIC FANS",
  "FINNESSE",
  "Finning CAT",
  "FIOCCHETTI",
  "fire",
  "Fire alarm",
  "FIRE ALARM CONTROL",
  "FIRE ALARM CONTROL / CANON FIRE PROTECTION",
  "FIRE ALARM CONTROL PANEL",
  "FIRE ALARM INSTALLATION LTD",
  "FIRE ALARM INSTALLATION LTD.",
  "FIRE ALARM REPEATER PANEL",
  "FIRE ALARM SYSTEM",
  "FIRE ALARM SYSTEMS LTD.",
  "FIRE ALARMS LTD.",
  "fire alert",
  "Fire and Safe Services",
  "FIRE AND SAFETY SERVICES",
  "Fire and Security",
  "FIRE ANGEL",
  "Fire Blanket",
  "FIRE CARE",
  "FIRE CONTROL",
  "Fire Controls & Lighting Ltd",
  "fire detection",
  "FIRE DETECTION SYSTEM",
  "fire detetion system",
  "Fire dex",
  "FIRE DYNAMICS",
  "FIRE DYNAMICS LTD",
  "Fire Elite",
  "Fire Engineering Associates Ltd.",
  "Fire Line Protection",
  "fire panel",
  "Fire Pro",
  "Fire Protect Scotland",
  "FIRE PROTECTION CENTRE",
  "FIRE PROTECTION GROUP",
  "Fire Protection Scotland",
  "Fire Protection Services",
  "FIRE PROTECTION SYSTEMS",
  "FIRE PUMP CONTROLLER",
  "Fire safe",
  "FIRE SAFETY SERVICES",
  "FIRE SAFETY SUPPLIES",
  "Fire scan",
  "Fire Search",
  "Fire Sence",
  "Fire Sense",
  "fire system",
  "FIRE SYSTEMS",
  "FIRE TEC",
  "FIRE TEC EN54",
  "FIRE TECHNICS SYSTEMS LTD",
  "Fire watch",
  "Fire Smoke Damper",
  "Fire/GA/Pegging server",
  "FireAngel",
  "FIREBRAND",
  "FIRECASS",
  "FIRECHECK CONTRACTS LTD",
  "FIRECHEETA",
  "FIRECHIEF",
  "Firechief Megalarm",
  "Fireclass",
  "FIRECO",
  "Firecraft",
  "Firecrest Safety Systems",
  "FIREDEC",
  "FIREDEX",
  "FIREDEX900",
  "Firedexs",
  "Firedmx",
  "FIREDOOR",
  "FIREDOS",
  "Firedriver",
  "FIREGEM VISA",
  "Fireguard",
  "firelex",
  "fireline",
  "Fireline Protection",
  "FIRELINK",
  "firelite",
  "Fire-Lite",
  "FIRELOCK",
  "Firelock NXT",
  "FIREMAIN",
  "FIREMARK",
  "Firemaster",
  "FIRENZE",
  "Firepoint",
  "Firepower",
  "Fireray",
  "FIRERAY 2000",
  "FIRE-RITE",
  "FIRESAFE",
  "FIRESAFE FIRE ALARMS",
  "FIRESENCE",
  "firesense",
  "FIRESTAR",
  "Firestom",
  "FIRESTU",
  "Firesystem",
  "firetc",
  "FireTec",
  "Fire-Tec",
  "FIRETEC UK LTD.",
  "FIRETECH LTD",
  "FIRETECNICS",
  "Firetrace",
  "firetrax",
  "FIREWALL",
  "FIREWATCH",
  "FIREWATCH MK7",
  "fireworks",
  "fireworks hydramist",
  "FIREWORKS LTD",
  "FIREX",
  "FIRE-X UK",
  "FIREYE",
  "FIRMESTONE",
  "firmstine",
  "FIRMSTOE ELECTRICAK LTD",
  "firmstone",
  "FIRMSTONE ELEC",
  "FIRMSTONE ELECTRICAL",
  "FIRMSTONE ELECTRICAL LTD",
  "Firse",
  "First alert",
  "First Choice",
  "FIRST CHOICE COFFEE",
  "First City",
  "FIRST DIRECT",
  "FIRST POWER & SURGE PROTECTION LTD",
  "FIRST TRACE HEATING",
  "First Trace Heating Direct",
  "Firstlight",
  "FIRSY TRACE HEAPTING DIRECT",
  "FISAIR",
  "FISCHBACH",
  "Fischbach Ventilation",
  "Fischer",
  "fisedex 2208",
  "Fisher",
  "Fisher & Paykel",
  "Fisher & Pykel",
  "Fiskars",
  "FISTER",
  "Fitchet",
  "Fitetec",
  "FITTERS MATE",
  "Fitzgerald",
  "FITZPATRICK DOORS LTD.",
  "FJ EDWARDS",
  "FJ Edwards",
  "FKI BRADBURY LTD",
  "FKI Tully",
  "FKI-TULLY",
  "Flack",
  "FlacktWood",
  "Flacon",
  "Flactwood",
  "FLACWOODS",
  "FLAIR SYSTEMS",
  "Flakewoods",
  "FLAKT",
  "FLAKT & WOODA",
  "FLAKT PRODUKTE GMBH",
  "FLAKT WODS",
  "FLAKT WOODS",
  "Flakt Woods AB",
  "Flakt/Wods",
  "FLAKT/WOOD",
  "Flaktfabriken",
  "Flakt-Wods",
  "FLAKTWOIDS",
  "Flaktwoods",
  "FLAKTWOODS. TYPE 56JM/16/4/6/30",
  "FLAKY WOODS",
  "FLAKY/WOODS",
  "FLAMCO",
  "FLAMCO BREFCO",
  "FLAMCO STAG",
  "FLAMCO UK",
  "FLAMCO, 6LTR",
  "FLAMCON",
  "FLAMCOVENT CLEAN",
  "Flame Boilers",
  "FLAME FAST",
  "FLAME GAURD",
  "FLAMEBAR",
  "FLAMEFASTB",
  "FLAMEGAURF",
  "Flamenco",
  "FLAMESHIELD",
  "Flamvent",
  "flamworthy",
  "FLATK WOODS",
  "FLATLINE",
  "Flatwoods",
  "Flavel",
  "FLAVELL",
  "Flavia",
  "FLB",
  "FLECK",
  "FLECK CONTROLS EUROPE",
  "FLECK EUROPE",
  "Fleet",
  "Fleet Engineering",
  "Fleet Engineering Ltd",
  "FLEICON",
  "flender",
  "FLEX WAVE",
  "Flexair",
  "FLEXAIRE",
  "FLEXCO",
  "Flexcon",
  "FLEXCON SOLAR",
  "FLEXCON TOO",
  "FLEXCON. TOP",
  "Flexcon/HOVAL",
  "FLEXCONPAK",
  "FLEXI FORCE",
  "FLEXICOM",
  "FLEXICON",
  "Flexidock",
  "Flexidoor",
  "Flexi-Force-NL",
  "FLEXIGLIDE",
  "FLEXILEVEL",
  "Flexitricity",
  "Flexlec",
  "Flexton",
  "Flexwave",
  "FlexWave International",
  "FLI-BLADE",
  "FLIGHT",
  "FLIR",
  "FLIULINOX",
  "FLKT-WOODS GROUP",
  "FLM",
  "Flo Gas",
  "FLO MECH",
  "FLOCDOS",
  "FLOGAS",
  "Flomag",
  "FLOMECH PRODUCTS LTD.",
  "Flood Guard",
  "FLOODLINE",
  "FloodLine Leak Dect",
  "Florida  heat pump",
  "Flotom",
  "FLOTON",
  "Flotronic Pumps Ltd.",
  "FLOW MECH",
  "Flow Mech Blue",
  "Flow Mech Products",
  "FLOW MECH PRODUCTS LTD",
  "FLOW MECH PRODUCTS LTD.",
  "FLOW MECH PRODUCTS.      10 LTR, 15 BAR",
  "Flow Meh",
  "Flow Save",
  "Flow Tech",
  "Flow tech products ltd",
  "FLOW...",
  "FLOWCON LTD",
  "FLOWCOOL",
  "Flowcrete",
  "FLOWDRY MERCURY",
  "FLOWMECH",
  "FLOWMECH PRODUCTS LTD.",
  "Flowpac",
  "FLOWSAVE",
  "flowserve",
  "FLOWSERVE FLOW CONTRL GB Ltd",
  "FLOWSTAR",
  "FLOWTEC",
  "Flowtech",
  "Flowtel",
  "Flowtronex",
  "FLT TES LTD",
  "flue",
  "FLUE BOOST",
  "FLUEBOIST",
  "FLUEBOOST LTD.",
  "FLUID AUTOMATION",
  "FLUID AUTOMOTION",
  "FLUIDAIR",
  "fluis automation",
  "Fluke",
  "Flumborcer",
  "Fluvent",
  "flxicon",
  "FLY-Air Corp",
  "Flygo",
  "FLYGT ITT / Waste & Water / CEMA",
  "FLYGT LTD",
  "FLYGT PUMPS LTD",
  "FLYGT WATER SOLUTIONS UK LTD",
  "FLYMATIC",
  "FLYZAP",
  "FM",
  "FM ENVIRONMENTAL",
  "FM LUFT",
  "FM TECHNOLOGY",
  "FM200",
  "fm-200 - jjs",
  "FM200 System",
  "FMA",
  "FMC Technologies",
  "FML",
  "FML (A SIEMENS COMPANY)",
  "FML x 2 & GEC x 3 METERS.",
  "FMS",
  "FMS FIRE AND SECURITY LTD",
  "FO",
  "FOAM PROPORTIONING SPECIALISTS",
  "FOATERS",
  "FOBCO",
  "FOC",
  "Focus",
  "FOG BANDIT",
  "FOG BANDUT",
  "FOGBANDIT",
  "Fogel",
  "Fogolin GmbH",
  "FOGTEC",
  "FOGTEC FIRE PROTECTION",
  "FOGTEC WATER MIST TECHNOLOGY",
  "FOGTEC WATER MIST TECHNOLOGY.",
  "FOIREDOOR",
  "FOLD AWAY",
  "FOLING",
  "FOLKNOL LTD",
  "Folknoll",
  "FOLLET",
  "FOLLETT",
  "FOOD SERVICE PRODUCTS",
  "Food warmimg equipment",
  "Food warming equipment",
  "FOODFLOW",
  "Foodservice Equipment",
  "Footers",
  "Forbes",
  "FORBES PLASTICS",
  "Forcar",
  "Force 8",
  "force10",
  "Ford",
  "FORD WATER",
  "FORDHAM",
  "Fordwater",
  "FORDWATER AUTOWASTE",
  "FOREG",
  "FORGE",
  "FORKWAY",
  "FORMA SCIENTIFIC INC.",
  "Formal",
  "FORMAT",
  "Forse",
  "FORSTER",
  "Fortek-MSL",
  "FORTESSA",
  "Fortic",
  "FORTIUS",
  "FORTRESS",
  "FORTRESS DOORS",
  "FOS",
  "fospur",
  "FOSSEWAY",
  "FOSTER COLD ROOM",
  "foster consort",
  "FOSTER HI",
  "FOSTER PROFESSIONAL REFRIGERATION",
  "FOSTER PUMPS",
  "FOSTER REFRIGERATION",
  "FOSTER TRANSFORMERS & SWITCHGEAR LTD",
  "FOSTER TRANSFORMERS AND SWITCHGEAR LTD",
  "Foster Surf Navigation",
  "FOSTER-TECH",
  "FOSTERX",
  "FOSTPUR",
  "FOUR ZONE FIRE PANEL",
  "FOUR ZONE FIRE PANELS",
  "Fourgoup S r I",
  "FOWERLINE",
  "FOWLER SWIMMING POOLS",
  "Fox",
  "Fox & Co",
  "FOX ALUMINIUM SYSTEMS",
  "FOX AND COOPER",
  "FOX INDUSTRIAL DOORS",
  "FOXBORO",
  "FOZMULA",
  "Fozmula Ltd",
  "fp",
  "FPM",
  "FPP",
  "FPVM",
  "fr",
  "fr4204",
  "FRABRICATED PRODUCTIONS UK",
  "Fracino",
  "Fragor",
  "Frako",
  "Framec",
  "FRAMECO",
  "France AIR",
  "France Transfo",
  "FranceAir",
  "FRANCES AND LEWIS LTD",
  "franchill",
  "Franco",
  "FRANCO FIRE DETECTION LTD",
  "FRANCO FIRE SYSTEMS",
  "Frange",
  "Frank Brown",
  "FRANK KNOWLES LTD",
  "Franke",
  "Franke Airtronic",
  "FRANKIE",
  "Franklin",
  "Franklin Hodge",
  "FRANKLIN HODGE FIRESTORE",
  "FRANKLIN HODGE INDUSTRIES",
  "FRANKLIN HODGE INDUSTRIES LTD",
  "Frantonix",
  "Frascold",
  "FRASEN TURBO",
  "FRC",
  "FRE SAFE",
  "Freaston",
  "FRECO FLUID POWER",
  "FRED FOX",
  "FRED WATKINS ENGINEERING LTD",
  "Frederick crowther & son Ld",
  "FREDRICK & PULLEN Co Ltd",
  "FREEDOM AIR CONDITIONING",
  "Freerain",
  "Freescale",
  "FREESTON",
  "FREEWAY",
  "FREGOLAS",
  "FRENGER SYSTEMS",
  "FRENIC LIFT",
  "FRENOX",
  "Frescold",
  "Freshflo",
  "FRESHWATER M&E SERVICES LTD",
  "Fresia",
  "Fresstom",
  "FRGA BOHN",
  "FRGIDAE",
  "FRI JADO",
  "FRIALATOR",
  "FRIALTOR",
  "FRICO",
  "Fridemaster",
  "Fridge",
  "FRIDGE, UNDER SHELF TYPE",
  "FRIDGE,ASTER",
  "FRIDGELAND",
  "Fridgemaster",
  "Friedland",
  "FRIFRI",
  "FRIGA - BOHN",
  "FRIGA BOHN",
  "FRIGABOHN",
  "FRIGA-BOHN",
  "FRIGADAIRE",
  "FRIGADON STS COMPONENTS",
  "frigah bohn",
  "FRIGDAIRE",
  "Frigeidaire",
  "FRIGEMASTER",
  "FRIGERIA",
  "frigid air",
  "FRIGIDAINES",
  "FRIGIDAIRE",
  "FRIGIDAIRE LARDER",
  "FRIGIDAIRES",
  "FRIGO",
  "Frigo Glass",
  "frigomec",
  "Frigorex",
  "FRI-JADO",
  "FRILIXA",
  "FRIMA",
  "FRIMAIR",
  "Frimont",
  "Fringe Electronic",
  "FrireDex",
  "FRISCOLD",
  "FRISIDASE",
  "FRISTAM PUMPEN",
  "Friul Company",
  "FRIULINOX",
  "FROILABO",
  "FROLING",
  "Froment",
  "FRONIUS",
  "Frontier",
  "Frontier Pits Ltd",
  "FRONTIER PITT",
  "Frontier Pitts",
  "FRONTIERPITTS",
  "FRONTLINE",
  "FRONTLINE SAFETY",
  "FRONTLINE SECURITY SOLUTIONS",
  "frontrunner door systems",
  "Frost",
  "FROST TECH",
  "FROSTECHNIC",
  "Frost-Tech",
  "FRUILINOX",
  "FRULINOX",
  "frundfos",
  "Frutina",
  "Frymaster",
  "fs",
  "FS Fire Sense",
  "FSAFARM",
  "FSD",
  "fsdgsdfg",
  "FSE",
  "FSL",
  "FSP",
  "FSS",
  "Fster",
  "FSWGR2",
  "FT FILTER TECHNIK",
  "FT TRANSFORMERS LTD.",
  "FT-800",
  "FTI",
  "FTRETRON",
  "F-tronic",
  "FTS",
  "FTS LTD",
  "FTXS25DV1W",
  "Fuel Efficiency co",
  "Fuel Efficiency Co Ltd",
  "FUEL PROOF LTD.",
  "FUEL STORAGE SOLUTIONS",
  "FUJI",
  "FUJI ELECTRIC",
  "FUJITEC",
  "Fujitsu",
  "FUJITSU     FCU11",
  "fujitsu indoor unit",
  "Fujitsu-GENERAL",
  "FULBOURN MEDICAL",
  "FULLEX WINDOWS",
  "FULLY CLADED",
  "FULTON",
  "FULTON BOILER",
  "Fumagalli",
  "FUMAIR",
  "Fume Cpboard Design",
  "FUME CUPBOARD DESIGN",
  "FUMEX",
  "Fumigali",
  "FUNAI",
  "FUNKE",
  "FURETRON",
  "FURL MATIC",
  "Furse",
  "Furse 415",
  "FURST",
  "FURZE",
  "FUSE BOX",
  "Fusi Electric",
  "FUSION",
  "Fusion Link",
  "Futitsu",
  "Futura",
  "Future",
  "FWA SYSTEMS",
  "fyggu",
  "FYRE TRON",
  "FYRETRON",
  "Fyreye",
  "Fysher & Pycle",
  "G",
  "G & D FIRE PROTECTION",
  "G & M POWER PLANT",
  "G & M TEX",
  "G & S STEEPLEJACKS LTD",
  "g and c",
  "G C HURRELL",
  "G C PILLINGER & CO",
  "G E",
  "g e a searle",
  "G F AGIE CHARMILLES",
  "G H TREBLE LTD",
  "G M TRABLE",
  "G M TREMBLE",
  "G M V",
  "G P Dennis",
  "G R Electrical",
  "G R Electrical Services",
  "G TAG",
  "G tech",
  "G W AXIAL",
  "G&C",
  "G&E",
  "G&M Kay Controls Ltd",
  "G. Brady ltd",
  "G. C. Pillinger",
  "G. C. PILLINGER AND COMPANY LTD",
  "G. M. TREBLE LTD",
  "G. W. AXIAL",
  "G.BRADY &Co. Ltd",
  "G.C.HURRELL",
  "G.D.S JACKSON CONTROLS",
  "G.D.S. JACKSON CONTROLS",
  "G.E Simplex.",
  "G.E.",
  "G.E. MPACT PLUS",
  "G.E. SIMPLEX.",
  "G.E. SINPLEX",
  "G.E.C Transformers",
  "G.E.Simplex",
  "G.E.Simplex.",
  "G.G.S ENGINEERING",
  "G.K.WOODS & SON",
  "G.M Treble",
  "G.M TREBLE LTD",
  "G.M. Treble Ltd",
  "G.M.TRBLE LTD",
  "G.M.TREBBLE",
  "G.M.TREBLE",
  "G.M.TREBLE LTD",
  "G.M.TREBLE LTD.",
  "G.w.axial",
  "G.W.Monson & Sons",
  "G+E",
  "G+LDSWORTH MEDICAL LTD",
  "G2",
  "G4",
  "G4 ENVIRONMENTAL",
  "G4/S",
  "G4S",
  "G601197",
  "G65",
  "GABARRON",
  "GABEXEL",
  "GAC",
  "GAFFEY",
  "GAGGENAU",
  "GAGGIA",
  "GAINSBOROUGH",
  "Gainsbrough",
  "Gaisborough",
  "GAI-TEONICS",
  "GALATREK",
  "Galaxy",
  "Galaxy 96",
  "GALAXY AQUA",
  "Galaxy Aqua Premier",
  "Galaxy components",
  "GALAXY LIFTS LTD",
  "Gales Developments Ltd",
  "GALGLASS",
  "GALGLASS LTD",
  "GALLAGHER",
  "Gallencamp",
  "Gallenkamp",
  "GALLENSCHULTZ",
  "Gallito",
  "Gama Stair Lift",
  "GAMAK",
  "Gamar",
  "GAMATRONIC",
  "GAMATRONIC ELECTRONIC INDUSTRIES LTD",
  "GAMBLE",
  "GAMBRO",
  "GAMCO",
  "Gamed",
  "GAMKO",
  "Gamko holdings",
  "GAMMEX",
  "GANTRY",
  "GANZ",
  "GANZ LITE",
  "GAP DISTRIBUTORS",
  "GARADOR",
  "GARAGE EQUIPMENT CO. LTD",
  "GARAM",
  "GARAVENTA",
  "GARAVENTA GENESIS",
  "Garaventa Lifts",
  "GARBAGE GORGER",
  "Garbutt",
  "GARBUTT HEATER",
  "GARBUTT HEATERS",
  "GARDEN DENVER",
  "Gardener Denver",
  "GARDINER INTRUDER ALARMS",
  "Gardner Air Systems Ltd",
  "GARDNER DENVER",
  "GARDNER DENVER THOMAS",
  "Gardtec",
  "Garenje",
  "Garig",
  "Garisson/Adams rite",
  "GARLAND",
  "Garland Commercial Industries",
  "GARLAND MASTER",
  "Garners",
  "Garo",
  "GAROG",
  "GAROG ACCESS SYSTEMS",
  "Garrog",
  "Gartec",
  "GARTEX",
  "GARVILLE SWITCHGEAR LTD",
  "GAS ALARM SYSTEM LTD",
  "Gas Alarm Systems Ltd",
  "GAS ARC",
  "GAS ARC GROUP",
  "GAS GAURD 1",
  "Gas Measurement Instrument Ltd",
  "Gas Measurement Instruments",
  "GAS NET",
  "GAS SAFETY SYSTEMS",
  "GAS SURE",
  "GASARC",
  "GAS-ARC",
  "GASCOIGNE GUSH & DENT",
  "GASGOIGNE GUSH & KENT",
  "Gasguard",
  "GASGUARD 1",
  "Gasguard IV",
  "GASMASTER",
  "GASNET 640",
  "GAST",
  "Gastrnorm",
  "GASTROMORM",
  "gastronorm",
  "GATE",
  "GATE AND BARRIER SERVICES",
  "Gatehouse and Visits Entry Locking PLC Panel",
  "GATEWAY",
  "Gaurdsman",
  "Gave",
  "Gaylord",
  "Gazalarm",
  "GAZCO",
  "Gazomet",
  "Gazprom",
  "GazyRaa",
  "GB",
  "GB Industrial Doors",
  "GB Lifts",
  "GB LIFTS LTD",
  "GBE",
  "GBG",
  "GBH",
  "GC PILLINGER",
  "GCE",
  "Gclippers",
  "GCRB-36H",
  "GCX",
  "GDC",
  "GDD",
  "GDD GMBH",
  "GDD-GmbH",
  "GDS",
  "GDS CONTROLS",
  "GDS JACKSON",
  "GDS Jackson controls",
  "GDS QUICK BILD",
  "GDS QUICK BUILD",
  "GDS QUICKBILD",
  "GE",
  "GE COMP",
  "GE DIGITAL ENERGY",
  "GE Dressen",
  "GE Fanuc",
  "GE Interlogic",
  "GE INTERLOGIC.",
  "GE Interlogix",
  "GE INTRLOGIC.",
  "GE MEDICAL SYSTEMS",
  "GE METERS",
  "GE Oil & Gas",
  "GE Power Controls",
  "GE POWER CONTROLS",
  "GE Security",
  "GE Zenith",
  "GE.",
  "GEA ECOFLEX",
  "GEA HAPPEL GMBH",
  "GEA Heat Exchangers",
  "GEA PGE STSTEMS",
  "GEA PHE SYSTEMS",
  "GEA SEARLE",
  "GEA WTT",
  "GEA-Denco",
  "Geared Motion",
  "Gearge Wilson Instruments",
  "GEBA",
  "GEBE",
  "Gebhardt",
  "GEBHARDT VENTILATION",
  "GEBHARDT VENTILATOREN",
  "GEBR BECKER",
  "gebruder",
  "GEBRUDER HEYL",
  "GEC",
  "GEC ALSTHOM",
  "GEC ALSTHOM MINIFORM",
  "GEC Alsthon",
  "GEC ALSTOM",
  "GEC ALSTROM",
  "GEC Alstron",
  "GEC ALTHOM",
  "GEC Claudgen",
  "GEC CM",
  "GEC CONTROLS",
  "GEC FUSE GEAR",
  "GEC Fusegear",
  "GEC FUSEGEAR LTD",
  "GEC INDUSTRIAL CONTROLS",
  "GEC INSTALLATION EQUIPMENT LTD",
  "GEC INSTALLATION EQUIPMENT LTD.",
  "GEC LTD",
  "GEC M",
  "GEC MACHINES LIMITED",
  "GEC MACINES LTD",
  "GEC MEASUREMENT",
  "GEC Measurements",
  "GEC Measurements.",
  "GEC METERS",
  "GEC Miniform",
  "GEC MINIFORMS",
  "GEC MOTOR",
  "GEC NDUSTRIAL CONTROLS",
  "GEC RED SPOT",
  "GEC SWITCHGEAR LTD",
  "GEC SWITCHGEAR LTD.",
  "GEC. POWER CONTROLS.",
  "GEC/ENGLISH ELECTRIC",
  "GEC/NORELIGHT",
  "GEC/TREND",
  "GEC-ALSTHOM",
  "GED",
  "Gee & Company",
  "geesink",
  "GEEYES POWEROL",
  "GEF",
  "Gehrice",
  "Gehrke",
  "GEINE",
  "Gem",
  "GEM SYSTEM",
  "Gem/ Potter",
  "GEMELS",
  "GEMINA TERMIX",
  "GEMINI",
  "GEMINI CONTROL",
  "Gemini Fire & Management Ltd",
  "GEMINI II",
  "GEMLOG CONTROLS LTD",
  "GEMM",
  "GEMMELS",
  "Gen Part",
  "GENE",
  "GENELEC",
  "GENERAL",
  "GENERAL ELECTRIC CM",
  "GENERAL ELECTRIC MONOGRAM",
  "GENERAL ELECTRICS",
  "general engineering",
  "GENERAL ENGINEERING CO LTD",
  "GENERAL ENGINEERINGRADCIFFE LTD",
  "GENERAL FIJITSU",
  "GENERAL LIFT Co. LTD",
  "General Lift Company",
  "General Panel Systems",
  "General Surveillance CCTV",
  "GENERATOR CORPORATION",
  "Genergy",
  "generic",
  "Generic copper earth bar",
  "Genesis",
  "Genesis 15 500",
  "GENFROST",
  "Genie",
  "GENIE HIGH RES SYSTEMS",
  "GENIE LIFT",
  "Genie Terex",
  "Genie Uk Ltd",
  "GENISIS",
  "Genius",
  "Geniusplus",
  "GENLAB",
  "GENPART",
  "Genrac",
  "GENSET",
  "Gent",
  "GENT 2015",
  "Gent Chloride",
  "GENT HONEYWELL",
  "GENT-HONEYWELL",
  "GENUS",
  "GENWARE",
  "GENWAY",
  "GENX INTERNATIONAL",
  "GEO",
  "GEO THERMAL INTERNATIONAL",
  "Geoclima",
  "GEOQUIP",
  "GEOQUIP WORLDWIDE",
  "GEORATOR CORPORTATION (NOBRUSH)",
  "GEORG",
  "George",
  "George Angus",
  "GEORGE BAKER",
  "GEORGE BARKER",
  "GEORGE BARKER LEEDS LTD",
  "GEORGE BIRCHALLSSERVICE LTD",
  "George Foreman",
  "GEORGE FORMAN",
  "GEORGE HOME",
  "GEORGE JOHNSON",
  "GEORGE JOHNSON LIFTS LTD",
  "GEORGE WILSON",
  "George Wilson Ind",
  "George Wilson Industries",
  "GEORGE WILSON INDUSTRIES (ENGLAND)",
  "GEORGE WILSON LTD.",
  "Gepruft",
  "Gepruft.",
  "Germax",
  "GEROS",
  "GERTRUEBBAU",
  "GERVASE INSTRUMENTS",
  "GES",
  "GES Ltd",
  "GESTRA",
  "Gestra UK Ltd",
  "GESULP",
  "GET",
  "get plc",
  "GETINGE",
  "GETPLC",
  "GETRIBEAU",
  "GEUTEBRUCK",
  "GEUTENBUCK",
  "GEUTERBROOK",
  "geuterbruck",
  "geuterbruck ?",
  "geuterbrukk",
  "GEWEB",
  "geweiss",
  "GEWES",
  "GEWIED",
  "GEWISS",
  "Gewiss ?",
  "GEWISS/MENEKES",
  "Geze",
  "Gezel",
  "Gezematic",
  "GF LIFT HYDRAULICS",
  "GFA",
  "GFA ELEKTROMATEN",
  "GFD",
  "GFE Bartlett",
  "GGRUPPO CIMBALI",
  "GGS",
  "GHD",
  "GHS ELECTRONICS LTD",
  "GHT-132",
  "Giacom",
  "GIBBONS DRIVE SYSTEMS",
  "GIBBONS SYSTEMS",
  "GIBSON",
  "gibson servces ltd",
  "GIFFEN",
  "GIFFEN AWITCHGEAR",
  "GIFFEN CONTROL PANELS",
  "GIGABYTE",
  "Gigan",
  "Gigen",
  "GIGEN DOOR SYSTEMS",
  "GIGICODE",
  "GIGL8N",
  "GILA",
  "Gilbarco",
  "GILBERT",
  "Gilberts",
  "GILBERTS (BLACKPOOL) LTD.",
  "GILBERTS LTD.",
  "Gilden",
  "GILDEN DOOR SYSTEM",
  "GILDEN DOOR SYSTEMS",
  "GILGEN DOIR SYSTEM",
  "GILGEN DOOR SYSTEMS AG",
  "GILGEN DOR SYSTEM",
  "gilglen",
  "GILGON",
  "Gilkes Pumps",
  "Gilles",
  "GILLET & JOHNSON",
  "GILLGEN",
  "Gillman",
  "GILMAN",
  "Gilman Control Systems Limited",
  "Gilman control systems ltd",
  "Gimson",
  "GIMSON STAIRLIFT",
  "GINGE KERR",
  "GIORIK",
  "GIRBAU",
  "Girbrau",
  "GIRDLESTONE",
  "GIRODIN SAUER",
  "GIROVAC",
  "gis",
  "GIS Hoist",
  "gitachi",
  "GITRAL",
  "GIULIANT ANELLO",
  "GJD",
  "GK SWITCHGEAR LTD",
  "Gl100",
  "Glacial Tech",
  "GLASDON",
  "glasgow",
  "Glasgow Engineering",
  "glass",
  "Glass covered",
  "Glasseq",
  "GLASSHOUSE TECHNOLOGY LIMITED",
  "GLASTAR",
  "Glaxy",
  "gledhil",
  "Gledhill",
  "Gleichrichergerat",
  "GLEN",
  "GLEN DIMPLEX",
  "GLEN DOOR STSTEMS",
  "Glencoe-Radvac",
  "Glendhill",
  "GLENFIELD",
  "Glenhill",
  "GLI",
  "Gliden doors sstems",
  "GLIDERMATIC",
  "Gliderol",
  "GLITE",
  "GLMS",
  "glo worm",
  "GLOBAL",
  "GLOBAL ASAN APPLANCES",
  "GLOBAL ASIAN APPLIANCES LTD",
  "GLOBAL BUILDING CONTROL LTD",
  "Global Building Controls",
  "GLOBAL BUILDING CONTROLS LTD",
  "Global fire and security",
  "GLOBAL LIFT EQUIPMENT",
  "GLOBAL REFRIDGERATION",
  "GLOBAL TRACE HEATING KTD",
  "GLOBAL WATER",
  "GLOBAL WATER SOLUTION LTD",
  "GLOBAL WATER SOLUTIONS",
  "Global Water Solutions Ltd",
  "GLOBAL FINISHING SOLUTIONS UK LTD",
  "Global Water Solutions",
  "GLOBALEC",
  "Globe",
  "GLORY",
  "GLOUCESTER BUILDING CONTROLS LTD",
  "Gloucester rope and tackle",
  "Glouchester Building Controlls",
  "Glover",
  "Glow",
  "GLOWORM",
  "GLUG, GLUG, GLUG",
  "GM",
  "GM AUTOFLOW",
  "GM Treble",
  "GMB",
  "GMDX",
  "GMG",
  "GMI",
  "GMI Ltd",
  "GMP",
  "GMS",
  "GMS Thermal Ltd",
  "GMS THERMAL PRODUCTIONS LTD.",
  "GMS Thermal Products",
  "GMS Thermal Products Ltd",
  "GMSE THERMAL PRODUCTS",
  "GMT",
  "GMV",
  "Gmwied",
  "GNB",
  "GNB INDUSTRIAL POWER",
  "gnfng",
  "GNN",
  "Gnwied",
  "GOBA",
  "GODWIN PUMPS",
  "GODWIN PUPS",
  "GOEDEREN",
  "GOETZE",
  "GOLD",
  "GOLDAIR",
  "GOLDMANN",
  "Goldmann - Sand blaster",
  "GOLDSTAR",
  "Goldsworth",
  "GOMER FIRE",
  "GOOD DIRECTIONS",
  "GOOD WATER",
  "GOOD YEAR",
  "GOOD YEAR TRANSFORMER",
  "GOODFLOW",
  "GOODMAN",
  "GOODMANS",
  "Goodman's",
  "Goodmarriot  & Hursthouse",
  "Goodmarriott & Hursthouse",
  "Goodrich",
  "Goodwater",
  "GOODWE",
  "Goodwin",
  "Goodyear",
  "Goodyear Transformer",
  "GOODYEAR TRANSFORMERS",
  "Goodyear Transformers Ltd",
  "GOODYEAR TRANSFORMERS LTD.",
  "goodyear tx",
  "GORAG",
  "GORENJE",
  "Gorman",
  "Gorman-Rupp",
  "Gorrilla",
  "Gough Kelly",
  "Goulds",
  "GOULDS PUMPS",
  "Gourmet",
  "Governors America Corp.",
  "GOZE",
  "GP BURNERS",
  "GP Burners (CIB) Ltd",
  "GP Dennis Ltd",
  "GP Sangiorgio",
  "GPG HELIX",
  "GPI",
  "GPO",
  "GPO ?",
  "GPO TX",
  "GPS",
  "GPT SECURITY SYSTEMS",
  "gr",
  "gr electrical",
  "GR ELECTRICAL SERVICES",
  "GR ELECTRICAL SERVICES LTD",
  "GR Services",
  "GRA",
  "GRABTREE",
  "Grace - Hollongne",
  "GRACE DEARBORN",
  "GRACO",
  "Gradwood",
  "Grady & Co Ltd",
  "Grady Ltd",
  "Grafik",
  "Graham",
  "Graham metal doors",
  "Graig & Derricatt",
  "Gram",
  "GRANDSTREAM",
  "GRANITALIA",
  "Grant",
  "Grant & Livingston Ltd",
  "Grant & Taylor",
  "GRANT HANDLING",
  "granudos",
  "Granular Disk",
  "Grape Vine",
  "Graslin",
  "GRASSIN 6",
  "GRASSING",
  "Grasslin",
  "GRÄSSLIN",
  "Grassun",
  "GRAVITY RANDALL",
  "GRAYSON",
  "GRAYSONS TELEQUARTZ",
  "GRAZIADIO",
  "GRC pump",
  "GRE",
  "GREASE BETA",
  "GREASE GOBLIN",
  "GREASE GUARDIAN",
  "Grease Gusler",
  "GREASE GUZLER",
  "GREASE GUZZLER",
  "GREASE PAK",
  "GREASE RELEASE",
  "GREASE SHIELD",
  "GreasePak",
  "GREAT WESTERN ELECTRICAL & WINDINGS CO. LTD.",
  "GREATWEST",
  "GREE",
  "Green",
  "Green acre",
  "Green Care",
  "GREEN COMPLIANCE",
  "Green Control",
  "Green Control Systems",
  "Green Controls",
  "Greenberg Glass",
  "Greenbrook",
  "Greencare",
  "GREENCARE FOUNDATION",
  "Greeneood",
  "Greenheck",
  "GREENMOUNT",
  "greenmount fans",
  "greenorks",
  "GREENPRO",
  "greenstar",
  "Greentech Environmental",
  "Greenwood",
  "GREENWOOD AIRVAC",
  "GREENWOON",
  "greenworks",
  "GREGSON & BELL LIFTS",
  "Grensells",
  "GRENSELLS LTD.",
  "GRENWOOD",
  "GRESHAM",
  "GRESSWELL VALVES",
  "Grewn Control Systems",
  "GRIFEN",
  "GRIFFEN",
  "GRIFFIN",
  "Griffin Switchgear",
  "Grigia",
  "GRIND MASTER COOPERATION",
  "GRINDFOSS",
  "Grinnell",
  "Groen",
  "GROHE",
  "Grosvenor Pumps",
  "Groundfos",
  "GROUP 4",
  "GROUP UNIT",
  "Group 4 Securitas",
  "Groupe S.G.D.",
  "GROUPE SCHNEIDER",
  "Grove Williams Ltd",
  "Grow master",
  "GROWERS TECHNICAL SERVICES LTD.",
  "GRP",
  "GRUNBERGER - EST",
  "GRUNDFOS",
  "Grundfos      CR2-110  EA",
  "GRUNDFOS AMTROL",
  "Grundfos Fire Systems",
  "GRUNDFOS/CLARKE",
  "GRUNDFOSS CLASS F IP 44",
  "Grundig",
  "GRUNDVOSS",
  "Grundy",
  "GRUNDY TEDDINGTON",
  "GRUNGOS",
  "GRUNUDFOSS",
  "GRUUNFOSS",
  "Gryphon",
  "GRYPTO PEERLESS",
  "GS",
  "Gs Kitchen Aid",
  "GS4",
  "GSD CONTROLS",
  "GSH",
  "GSH Controls",
  "Gsinsborough",
  "Gsman1",
  "GTAG",
  "G-TAG",
  "GTE LTD",
  "G-TEC",
  "GTECH",
  "GU",
  "GU BKS",
  "Guardall",
  "Guardian",
  "GUARDIAN CRADLE SAFETY SERVICES",
  "GUARDIAN FIRE DETECTION SYSTEMS LTD.",
  "Guardian Locks",
  "GUARDIAN SECURITY",
  "Guardsman",
  "Guentner",
  "Guest & Chrimes",
  "Guest & Grimes",
  "GUETEBRUCK",
  "gueterbruk",
  "Gui",
  "GUIDELINE",
  "GUIDELINE LIFT SERVICES",
  "GUIDELINE LIFTS",
  "GUIDELINES",
  "GUIDLINE",
  "GUILBRENT LTD",
  "guildines",
  "GUILDMANN",
  "GUILIANT ANELLO",
  "Guillot",
  "Guinault",
  "GULDMANN",
  "GULLIGAN INTERNATIONAL",
  "GULMAY MEDICAL LTD",
  "GUNEBO",
  "GUNFOS",
  "Gunnebi",
  "GUNNEBO",
  "GUNNEBO ENTRANCE CONTROL",
  "GUNNEBO MAYER",
  "GUNNEBO MAYOR",
  "Gunnebo Myor",
  "Gunnebo-Mayor",
  "GUNTER",
  "GUNTNER",
  "GUNTNER (UK) LIMITED",
  "GUNTNER (UK) LTD.",
  "GUNTNER UK LTD",
  "GUNZL LOCKHART - CHEF MASTER",
  "GUSTAV NOLTING GMBH",
  "GUSTO",
  "GUTHRIE DOUGLAS",
  "Guyson",
  "Guzzini",
  "gve",
  "GVI",
  "GW AXIAL FANS",
  "GWB",
  "GWEB",
  "GWF",
  "GWF ENERGY",
  "GWF LUZERN",
  "GWi",
  "GWIBS LTD",
  "GWIEB",
  "GWS",
  "H & C COILS",
  "H & C CONTRACTS",
  "H & C Dover",
  "H & C LIFTS",
  "H & C MOORE (ELECTRICAL) LTD",
  "H & K",
  "H & K ENGINEERING",
  "H & K FIRE ENGINEERING",
  "H & S",
  "H A ONEIL",
  "H A O'Neil",
  "H and K  Extinguishant controls",
  "H AND S",
  "H C Crediton",
  "H E C",
  "H F BIDDLE LTD.",
  "H G & A WRIGHT LTD",
  "H G HIGGINSON",
  "H&C COILS LTD",
  "H&C Coils",
  "H&K",
  "H&K FIRE ENGINEER",
  "H&K Fire Engineering",
  "H&K Fire Enginerring",
  "H. J. & A. WRIGHT",
  "H.B CONTROL PANELS LTD.",
  "H.B. CONTROL PANELS LTD.",
  "H.C.COILS",
  "H.D.HOWDEN",
  "H.G HIGGINSON",
  "H.G HIGHINSON",
  "H.G. HIGGINSON",
  "H.H. Robertson (UK) Ltd",
  "H.KOENIG",
  "H.PHILIP ROCKHILL & ASSOCIATES",
  "h/p/cosmos",
  "H+C LIFTS",
  "H+C Limited",
  "H+M Shutters",
  "H20 NATIONWIDE LTD",
  "H264",
  "ha oneil ltd",
  "HA/GH",
  "haacon",
  "HAAR",
  "Haas",
  "HAATO",
  "HACH",
  "Hackbridge Faraday Electrics",
  "HACKSON LEWIS CONTROLS LTD",
  "HADA",
  "HADEF",
  "Haden",
  "HADEN DUCOST",
  "HADEN DUCOST SYSTEMS.",
  "Haden Pumps",
  "Haden Young",
  "Hadrian air conditioning & refrigeration co ltd",
  "HAES",
  "HAES SYSTEM",
  "HAES Systems",
  "HAES SYSTEMS FUSION",
  "Hag",
  "Hagar",
  "Hage",
  "Hagel",
  "Hagen",
  "HAGEN MYER",
  "Hager",
  "HAGER/CASTLEC",
  "Hagger",
  "Haglock",
  "Hagon",
  "Hagr",
  "HAIEE",
  "Haier",
  "HAIER BIOMEDICAL",
  "HAIGH",
  "HAIGH ENGINEERING CO. LTD.",
  "HAILO",
  "HALE",
  "Haler",
  "Hales Systems",
  "HALIFAX FAN",
  "HALIFAXFAN",
  "HALIGON",
  "HALL &  KAY",
  "Hall & Kay",
  "HALL & KAY FIRE ENGINEERING LTD",
  "HALL AND KAY",
  "HALL AND KAY FIRE ENGINEERING",
  "Hall Brixton",
  "HALL MARK FIRE",
  "Hall & Kay Fire ENG",
  "Hallde",
  "HALLMARK",
  "HALLMARK FIRE",
  "HALLMARK FIRE LTD.",
  "Hallmark Fire Systems",
  "Halls Fire",
  "Halls Fire Protection",
  "HALLSON",
  "HALO",
  "Halo Heat",
  "halogen",
  "HALOS DEVELOPMENTS",
  "Halsey Taylor",
  "HALSION LTD / T.F. HULL LTD",
  "HALSTEAD",
  "HALSYS-JA LAUKAISUKESKUS",
  "Haltco",
  "HALTON",
  "HALTON FOOD SERVICES LTD",
  "Halu",
  "Halwi",
  "Halycon",
  "Hamblin",
  "Hambrair",
  "Hamech",
  "HAMEORTHY",
  "HAMEORTHY BOILER HEATING PRIDUCT",
  "HAMILTON",
  "Hamilton Automatic",
  "Hamilton Beach",
  "HAMILTON REID",
  "HAMMER",
  "HAMMOND",
  "HAMMOND & CHAMPNEYS",
  "HAMMOND + CHAMPNEYS",
  "Hammond and Champneys",
  "Hammond Mfg",
  "HAMMWORTHY",
  "Hamorthy",
  "HAMWOR4HY",
  "Hamworthy",
  "Hamworthy ?",
  "HAMWORTHY ENG.LTD.",
  "Hamworthy Heating",
  "Hamworthy Heating Ltd",
  "HAMWORTHY HEATING PRODUCTS",
  "HAMWORTHY/HORTON HN5.0",
  "HAN SPREE",
  "HANAOKA SHARYO CO LT",
  "HANAULUX",
  "Hand Dry",
  "Hand dryer",
  "Hand dryer Not known",
  "Hand-dryers",
  "Handi Move International",
  "Handmade",
  "Handon Engineering",
  "Handsworth",
  "Handvia",
  "handy",
  "HANDY DRI",
  "HANDY DRYERS",
  "handy flow",
  "HANDYDRI",
  "HANDYDRYERS UK",
  "HANDYFLOW",
  "HANEL BURO-UND LAGERSYSTEME",
  "HANEL LEAN LIFT",
  "Hanes lifter",
  "hanger",
  "HANIKSON",
  "HANKINSON",
  "Hankison",
  "hanley",
  "Hannaford",
  "Hannaford (Nuaire)",
  "Hanner",
  "HANNG",
  "Hanniford",
  "HANNS G",
  "HANNS.G",
  "Hanns-G",
  "Hanova",
  "HANOVER",
  "HANOVIA",
  "HANOVIA UV",
  "HANOVIA UV.",
  "Hans Günter gmbh",
  "HANS GUNTNER GM8H",
  "HANSA",
  "Hanse lifter",
  "Hansen",
  "HANSHAW ADAMSON GREEN",
  "HANSOL",
  "Hanson",
  "Hanworthy",
  "Hanworthy Heating Ltd",
  "Harath",
  "HARATH ENGINEERING SERVICES LTD",
  "HARCO",
  "HARDING",
  "Hardinge",
  "HARGREAVES",
  "HARLAND",
  "Harlech Industrial Doors",
  "HARLEQUIN TANKS",
  "Harlow Group",
  "Harmer & Simmons",
  "Harmer & Seimens",
  "HARMET SIMMONS LTD",
  "Harmill Systems",
  "harmoney",
  "Harmony",
  "Harmsworth",
  "Harnall",
  "Harper Hillingdon controls",
  "Harper Hillington controls",
  "HARRIED AUTOMATION & CONTROLS LTD",
  "Harrier GT",
  "HARRIES",
  "Harrijes",
  "HARRINGTON GENERATORS",
  "Harrison",
  "HARRISON LATHE",
  "HARRY TAYLOR",
  "HART",
  "Hart Door Systems",
  "HART DOORS",
  "HART INDUSTRIES",
  "HART INDUSTRIES  START BUTTONS",
  "HART XL",
  "Harte Electrical",
  "HARTEL",
  "HARTELL",
  "HARTLEY & DARK ELECT. LTD",
  "Hartley & Sugden",
  "Hartley + Sugden",
  "Hartley and Sugden",
  "Hartley Sugden",
  "Hartley And Sugden",
  "HARTWELL",
  "Harvia",
  "HARWI",
  "HAS",
  "HAS SYSTEMS",
  "HASCO",
  "HASCO HYDRAULICS LTD.",
  "Haskins",
  "Hassett Heat Emitters",
  "HATCHELLER",
  "HATCO",
  "HATCO CORPORATION",
  "HATSUYUKI",
  "Hattersley",
  "HATTERSLEY-ANSUL",
  "HATTERSLY",
  "HATWARD",
  "HAULOTTE",
  "HAUPSCHALTER",
  "HAUPTSCHALKER",
  "Haus",
  "HAUSHAHN",
  "HAVELLS",
  "Haven Fire and Security",
  "Haverhill Generators",
  "HAVERKORN",
  "HAVERLAND",
  "HAWCO",
  "HAWHER",
  "HAWKER",
  "Hawker Electronics",
  "HAWKER ENERSYS",
  "HAWKER FUSE GEAR",
  "HAWKER FUSEGEAR",
  "HAWKER FUSEGEAR LTD",
  "Hawker Siddeley",
  "Hawker Siddeley Elec Mtrs",
  "HAWKER SIDDELEY SWITCHGEAR",
  "HAWKER SIDDLEY BRUSH",
  "Hawker Sidley",
  "HAWKER-SIDDELEY SWITCHGEAR",
  "HAWKVAC",
  "HAWLE",
  "Haws",
  "Hayes",
  "Hayes Systems",
  "HAYWARD",
  "HAYWARD AIR",
  "HAYWARD DEHUMIDIFIERS",
  "HAYWARD INDUSTRIES",
  "HAYWARDS LTD",
  "Haywood",
  "HAZE",
  "Hazer",
  "HB",
  "HBVVV",
  "HC GROUP",
  "HCE",
  "HCL",
  "HDA",
  "HDV",
  "HDYCA",
  "He Ven Tac",
  "Heagan",
  "HEALD",
  "HEALD ENGINEERING & SECURITY",
  "HEALD ENGINEERING AND SECURITY",
  "HEALD UK",
  "HEALEY",
  "HEARRE SADIA",
  "heartbeat",
  "HEAT",
  "Heat electric ltd",
  "HEAT EXCHANGE INDUSTRIES",
  "Heat flow",
  "HEAT MAX",
  "Heat Meter",
  "HEAT SADIA",
  "Heat star",
  "HEAT STORE",
  "Heat Trace LTD",
  "HEAT TRANSFER LTD",
  "HEAT TRANSFER PRESSURISATION LTD",
  "HEAT TRANSFER PRESSURISATION LTD.",
  "Heat Store",
  "Heat..",
  "HEAT/VENT",
  "heatay",
  "HEATBETD",
  "HEATCRAFT",
  "HEATEAECSADIA",
  "HEATEAEXSADIA",
  "HEATED BOARD",
  "HEATEE SADIA",
  "HEATEEA SADIA",
  "HEATELECTRIC",
  "Heater Battery",
  "HEATERA SADIA",
  "HEATEX",
  "HEATHCOTE",
  "Heatherose",
  "Heatherose LTD",
  "Heathrose",
  "Heathrose LTD",
  "HEATING & COOLING COILS LTD",
  "HEATING & COOLING LTD",
  "HEATING AND DETECTION SOLUTIONS LIMITED",
  "Heating and detection systems",
  "HEATING AND DETECTION SYSTEMS LIMITED",
  "Heating Control Services",
  "Heat-King",
  "HEATLIGHT TECHNOLOGY",
  "Heatline",
  "Heatlock dampers ltd",
  "HEATMASTER",
  "Heatmax",
  "Heatmeter",
  "Heatmiser",
  "HeatMiser Senior",
  "HEATMISER UK LTD.",
  "HEATMISTER",
  "HEATOVENT",
  "HEATRAE ADDIS",
  "HEATRAE INDUSTRIAL",
  "Heatrae Industrial Ltd",
  "Heatrae Pullen",
  "HEATRAE SADIA",
  "HEATRAE SADIAJGR",
  "HEATRAE SADIW",
  "HEATRAE SAFIA",
  "Heatrae store",
  "HEATRAE SUPREME",
  "heatrea lido",
  "HEATROD",
  "Heatrose Ltd",
  "HEATSADIA",
  "Heatsavr",
  "HEATSE SADIA",
  "HEATSOURCE",
  "HEATSTAR",
  "HEATSTAR LTD",
  "Heatstone",
  "HEATSTORE",
  "heatstream",
  "HEATSTRIP",
  "Heatt store",
  "Heatzone",
  "Heaybeard",
  "Heayberd",
  "HEAYBERD - LONDON",
  "HEAYBERD LONDON",
  "Heaybird",
  "Hebe & Frodetechnik",
  "HECH ENGINEERING LTD",
  "Hechini",
  "Hechlerleunge",
  "HECK ENGINEERING",
  "Heda Gebb.Herberg",
  "HEDA SECURITY",
  "hedinair",
  "Heeat Transfer",
  "Heenan",
  "HEENAN MARLEY",
  "Hegenscheidt",
  "HEGENSCHELDT",
  "HEGENSHEIDT MFD",
  "HEGNER",
  "HEIDENHAIN",
  "HEIGHTSAFE",
  "Heine",
  "HEINEKEN",
  "HEINEKIN",
  "HEINIKIN",
  "Heintech (Heinemann)",
  "Heizer",
  "HEKATRON",
  "Heli",
  "HELIO",
  "HELIOS",
  "HELIOS VENTILATION",
  "helious",
  "HELIPAC",
  "Helix",
  "hellas",
  "Hellios",
  "Hellis",
  "Hellos",
  "HELMER",
  "Helmut Angerer",
  "Helo",
  "Helois",
  "HELPING HAND",
  "HELVA",
  "HELVAR",
  "Hemco power & control systems",
  "Hemsec",
  "HENARI",
  "HENDERSON",
  "Henderson & Bostwick",
  "HENDERSON BOSTWICK",
  "HENDERSON DOOR CO NW",
  "HENDERSON DOOR SYSTEMS",
  "HENDERSON DOORS",
  "Henderson Industrial Doors",
  "HENDERSON SECURITY ELCTRONICS",
  "Henderson-Bostwick",
  "HENDERSON-BOSWICK",
  "HENDERSONS DOORS",
  "HENDON",
  "HENDON INTERNATIONAL",
  "HENDON PRECISION",
  "HENDON PRECISSION",
  "HENGSTLER",
  "HENIKWON",
  "HENKELMAN",
  "HENKO",
  "HENLEY",
  "henleys",
  "HENNY PENNY",
  "HENRY LEWIS",
  "henry williams",
  "Hensel",
  "HERAEUS",
  "HERAS",
  "HERAS SECURITY SYSTEMS",
  "HERATHERM",
  "HERBER MORRIS",
  "HERBERT MORRIS",
  "HERCAL",
  "HERCAL 3",
  "HERCO WASSERTECHNIK",
  "HERCO WASSERTECHNIK GMBH",
  "Hercol",
  "HERCULAG",
  "HERCULES",
  "HERKLE",
  "Hermann Pipersberg",
  "HERMES INCORPORATED",
  "Hermetique",
  "HERR ALAMI",
  "HERTEL",
  "Herz",
  "HES",
  "Heston Apex",
  "HESTRAE SADIA",
  "Hetek",
  "HEVENTAC",
  "HEVENTACC",
  "Hevi-Duty",
  "Hevlar",
  "HEVNTAC",
  "HEWITT SPORTSTURF",
  "HEWLET PACKARD",
  "Hewlett Packard",
  "HEXOTOL",
  "Heyertech",
  "Heyl",
  "HEYWELL",
  "HEZE",
  "HF ELECTRICAL",
  "HFC INSTROMET",
  "hfp",
  "HH AIRPOWER",
  "HI FOG",
  "HI GLO",
  "Hi Level",
  "HI LINE",
  "HI TEC CONTROLS SECURITY BARRIERS AND GATES",
  "HI TEC LIFT SYSTEM",
  "HI TECH CONTROLS",
  "HIACHI",
  "HICKMANS",
  "HID",
  "HIDDEN",
  "Hidrostal",
  "HI-EPCO",
  "HIFLEX",
  "High Cool",
  "High Level",
  "HIGH LEVEL NO ACCESS",
  "HIGH ROLLER DOORS LTD.",
  "HIGH SPEED HAND DRYERS",
  "HIGHCOOL",
  "HIGH-COOL",
  "HIGHES & COYLE",
  "Highland Tank",
  "HIK Vision",
  "Hikvision",
  "Hilclare",
  "HILCON",
  "HILGE PUMPS LTD.",
  "HI-LINE",
  "HILLAND HEATING",
  "Hillhead filtration & engineering",
  "HILLORDEM COLUM",
  "HILL-ROM MEDAES",
  "HILL-ROM/MADAES",
  "Hilox",
  "Hilta",
  "Hilti",
  "Hiltons",
  "HILTONS ELECTRICAL",
  "Himal",
  "Himal Crowther",
  "Himel",
  "Himel Federal Pacific Electric",
  "Himmel",
  "Himoinsa",
  "Hinari",
  "HINARIE",
  "hindi",
  "HINOWA",
  "HIPPO",
  "HIRACHI",
  "HIREF",
  "Hiro Lift",
  "Hiro Lift Bielefeld",
  "HIROSS",
  "HIROSS S.p.A",
  "HIRSCH ELECTRONICS",
  "HISHIZAKI",
  "HISTON",
  "Hitachi",
  "Hitachi Utopia",
  "Hitatschi",
  "HITEC",
  "hitec power",
  "HITEC POWER PROTECTION",
  "Hitec Contardo",
  "HITECH",
  "HITECSA",
  "HITEK",
  "HITEL",
  "HITOX",
  "Hitzinger",
  "Hi-Way",
  "HJ&A WRIGHT LTD",
  "HK (CONTROL PANEL) LTD",
  "hk control panel",
  "HK Refrigeration",
  "HK VISION",
  "HK-1800SRA",
  "HKC",
  "HKVISION",
  "HLA",
  "HMA- SELLA",
  "HMA VENTILATORS LTD",
  "hmbj",
  "HME TECHNOLOGY",
  "HN",
  "HO",
  "HOARE",
  "Hobart",
  "Hobarts",
  "HOC",
  "HOC COMPRESED AIR SYSTEMS",
  "Hochiki",
  "HOCHIKI EUROPE UK LTD.",
  "HOCHIKI EUROPEAN LTD",
  "Hochki",
  "HOCITEC",
  "HOCKER",
  "HOCKIKI",
  "Hodge",
  "HODGE CLEMCO",
  "Hoffman",
  "HOFMAN",
  "Hogmann",
  "Hoier og Vendelbo",
  "Hoist",
  "Hoisting Appliance",
  "HOISTING APPLIANCE C0.LTD.",
  "HOISTING APPLIANCE Co.Ltd.",
  "HOLARIA",
  "HOLDEN & BROOKE",
  "HOLDEN & BROOKE LTD",
  "HOLDEN & BROOKES",
  "HOLDEN & FISHER",
  "HOLDEN AND BROKE",
  "HOLDEN AND BROOKE L4D",
  "HOLDEN AND BROOKE LTD",
  "Holden Brook",
  "Holden Brook Pullen",
  "HOLDEN BROOKE",
  "HOLDEN BROOKE PULLEN",
  "HOLDEN BROOKE PULLEN LTD",
  "HOLDEN BROOKES PULLEN",
  "Holden Brooks",
  "Holden & Brook Pullen",
  "HOLDEN AND BROOKE",
  "HoldeN, Brook PULLEN",
  "HOLDEN-BROOKE-PULLEN",
  "HOLDERNESS WELDING & GEN. ENG LTD",
  "HOLE' HH",
  "HOLEC",
  "HOLEC TRANSFORMERS",
  "HOLEN AND BROOKE",
  "Holfeld",
  "HOLKMERS",
  "Holland",
  "Holland Heating",
  "Holland Lift",
  "Hollinwood",
  "HOLLY TECHNOLOGY LTD.",
  "Holmes Catering Equipment Ltd.",
  "Holodn & Brooks",
  "Holophane",
  "HOLPAK",
  "HOLT",
  "Holten",
  "Holzer",
  "HOMA",
  "HOMANN",
  "HOMARK",
  "HOME",
  "home automation",
  "HOME KING",
  "Homebase",
  "HOMEKING",
  "HOMELITE",
  "Homemade",
  "HOMESAFE",
  "homeywell",
  "homworthy",
  "HONART",
  "Honda",
  "HONETWELL",
  "HONEY WELL SECURITY",
  "Honeyler",
  "HONEYWELL",
  "HONEYWELL / GENT",
  "HONEYWELL ?",
  "HONEYWELL CONTROLS",
  "HONEYWELL GENT",
  "Honeywell Morley",
  "HONEYWELL MORLEY IAS",
  "HONEYWELL Morley IAS",
  "HONEYWELL PROGRAMMER",
  "HONEYWELL SECURITY LTD",
  "HONEYWELL-FIRE",
  "HONEYWELL-GENT",
  "HONEYWELL-MICROTECH",
  "HONEYWELLS",
  "HONEYWELLWLS",
  "HONEYWELS",
  "Hoonved",
  "Hoover",
  "HOOVERHED 120W/1-80",
  "HOPES",
  "HOPKINS",
  "Hopoint",
  "Hoppecke",
  "HORACE GREEN & CO LTD",
  "HORCATEC",
  "Horizon",
  "HORIZON FITNESS",
  "Horizon Lifts",
  "Horman",
  "HORMANN",
  "HORMANN UP AND OVER",
  "HORN",
  "Horne",
  "HORNE ENGINEERING",
  "HORNE",
  "Horoquartz",
  "horris green",
  "Horsley Bridge",
  "HORSMANN",
  "HORSTMAN",
  "Horstmann",
  "Hortis systems",
  "Horton",
  "Horton  Automatic Doors",
  "HORTON AUTOMATIC DOORS",
  "Horton Automatics",
  "HORWICH ELECTRONIC SERVICES",
  "HOSEMAN DOSING POT",
  "HOSEWORTH",
  "Hoshizaki",
  "Hoshizaki Foster",
  "HOSHIZAKI ICE MAKER",
  "HOSON-ZUGBEG",
  "Hoson-Zugbegranzer",
  "Hospital Metal craft",
  "Hostess",
  "Hostmann",
  "Hot Box Heaters",
  "Hot Plus",
  "Hot Point",
  "HOT WATER CYLINDER",
  "HOT[OINT",
  "HOTCHI",
  "Hotchiki",
  "HOTCHKISS",
  "HOTCHKISS LTD",
  "Hotco",
  "Hotec",
  "HOTFOIL",
  "Hotlock",
  "Hotoint",
  "HOTP",
  "Hotpoint",
  "HOTPOINTSHL531XS",
  "HOTSPOT",
  "HOTSTMANN",
  "HOTTON",
  "HOUCHIN",
  "HOUNO",
  "House Unit 6 Matrix & DVR's",
  "HOUSEMAN",
  "HOUSEMAN (BURNHAM) LTD",
  "HOUSEMAN BURNHAM LTD",
  "Houseman Dosage Pot",
  "HOUSEMAN LTD",
  "Houseman Permutit",
  "Houseman Sample Cooler",
  "Housewarm",
  "HOUSMA",
  "HOUSMAN LIMITED",
  "Hovair",
  "HOVAL",
  "HOVAL - FARRAR LTD",
  "HOVAL FARRAR",
  "HOVAL- FARRAR LTD",
  "HOVAL LTD",
  "Hoval-Farrar",
  "HOVAL-FARRAR LTD.",
  "HOVEL",
  "HOVEL-FARRAR",
  "HOVER",
  "Hover Vision Tech",
  "HOW",
  "How Engineering",
  "HOW ENGINEERING SERVICES SOUTHERN LTD.",
  "HOW VALE",
  "HowAir",
  "HOWARD ELECTRICAL LTD",
  "HOWARD ELECTRICAL LTD.",
  "HOWARD GROUP",
  "HOWARD WARNER",
  "HOWARDS AIR CONDITIONING & REFRIGERATION",
  "HOWARD-WARNER",
  "Howarth",
  "Howarth Switchgear",
  "HOWATHERM",
  "Howden",
  "Howden Buffalo",
  "HOWDEN ELECTRIC",
  "HOWDEN ELECTRIC HEATER",
  "HOWE SYSTEMS ENGINEERING",
  "HOWELLS STANWAY LTD",
  "HOWLETT",
  "HOWORTH",
  "HOWORTH AIR TECH",
  "HOWORTH AIR TECHNOLOGY",
  "HOWORTH AURTECH LTD",
  "HOWORTH MEDICAL",
  "HOYER MOTORS",
  "Hozelock",
  "HP",
  "HPC",
  "HPC / Kaeser",
  "HPC COMPRESSED AIR",
  "HPC compressed air dryer",
  "HPC COMPRESSED AIR SYSTEMS",
  "HPC COMPRESSED AIR SYTEMS",
  "HPC Engineering",
  "HPC ENGINEERING LTD",
  "HPC HIROSS",
  "HPC Screw Compressors",
  "HPC. COMPRESSED AIR SYSTEMS",
  "HPV",
  "HR COMMUNICATIONS",
  "HR ELECTRONICS",
  "HR150A",
  "HRG",
  "hron",
  "hrp",
  "HRS HEBAC Ltd",
  "HRS Hevac",
  "HRS HEVAC ltd",
  "HRS HEVAC",
  "HRS HEVSC LTD.",
  "HRS-HEVAC",
  "HRV",
  "HS",
  "Hsc",
  "HSK",
  "HSM",
  "HSM Pressen Gmbh",
  "HSS",
  "HST",
  "Htachi",
  "HTC",
  "HTCO",
  "HTEC POWER PROTECTION",
  "H-TECH UK",
  "HTH EASIFLO",
  "htl doors",
  "HTS",
  "HTT Hochspannungstechnik undo transformatorbau",
  "HUAWEI",
  "HUBBARD",
  "Hubbard Commercial Packaged Units",
  "HUBBARD ICE",
  "HUBBER & RANNER",
  "HUBBRD",
  "Huber",
  "HUBER & RANNER",
  "HUDRA PRODUCTS",
  "HUDSON BROWN LTD",
  "HUEBSCH",
  "HUEHSCH",
  "HUGES SAFETY SHOWERS",
  "HUGGER ELECTRICAL BATH LTD",
  "hugget electrical",
  "HUGGET GROUP",
  "Hugget Marsh",
  "Huggett",
  "Huggett Electrical",
  "huggett electrical bath",
  "HUGGETT ELECTRICAL LTD.",
  "huggetts bath",
  "huggetts electrical bath",
  "HUGHES",
  "HUGHES & COYLE",
  "HUGHES EMERGENCY SHOWERS",
  "HUGHES REDIFFUSION SIMULATION",
  "HUGHES SAFETY SHOWERS",
  "HUGHS",
  "HUGO MULLER",
  "HUMIDECO",
  "HUMIDITECH",
  "HUMID-TECH",
  "HUMI-TECH",
  "HUNNARD",
  "Hunter",
  "HUNTER MECHANICAL HANDLING ENGINEERS",
  "Hunter Plastics",
  "HUNTS",
  "HUNTS HILL STEELWORKS LTD",
  "HUOVA",
  "HUPFER",
  "HUPPE",
  "HURON",
  "HURSEAL",
  "Hurseals Sales Ltd",
  "HURSTMANN",
  "HUSBANDS",
  "HUSH NELSON",
  "HUSHION",
  "Hushon",
  "HUSHON/GREE",
  "HUSKY",
  "Husqvarna",
  "HUSSMAN",
  "HUURRE GROUP",
  "Hv Flur",
  "HVAC",
  "HVAC Control Panels",
  "HVAC TRADING LTD",
  "Hval",
  "HVP",
  "HVP SECURITY SHUTTERS",
  "Hyce",
  "HYCO",
  "HYCO MANUFACTURING LTD",
  "HYCO NOT KNOWN",
  "HYCO TORNADO",
  "HYCO ULTRA WASH",
  "Hydac",
  "HYDRA",
  "Hydra clean",
  "HYDRA FORCE INC",
  "HYDRA MIST",
  "Hydramist",
  "Hydrastore",
  "hydrastotr ltd",
  "HYDRAULIC LIFT PANEL",
  "Hydraulic Transmission Services",
  "Hydravane",
  "Hydrax",
  "HYDRION",
  "Hydro",
  "HYDRO CLASS EQ",
  "HYDRO FLOW",
  "Hydro Geratebau",
  "Hydro Geraterau",
  "HYDRO IONICS LTD",
  "Hydro Mag",
  "HYDRO- MAG",
  "Hydro Press",
  "Hydro PUR",
  "Hydro Sense Water Detection",
  "Hydro Systems",
  "Hydro Systems KG",
  "Hydroboil",
  "Hydrocare",
  "HYDRO-CHEM UK",
  "Hydrodos",
  "Hydroflow",
  "Hydroglass",
  "HYDROION",
  "HYDROLITE",
  "Hydromag",
  "HydroMarque",
  "HYDROMATIK",
  "HYDROMETER ANSBACH",
  "Hydronic",
  "Hydronic Master",
  "Hydropak",
  "Hydropath",
  "Hydropoint",
  "HYDROPOINT 25",
  "Hydropress",
  "HYDROPUR",
  "Hydropure",
  "HYDROSENSE",
  "HYDROSENSE WATER DETECTION",
  "HYDROSERVE",
  "HYDROSET",
  "HYDROSET LTD.",
  "HYDROSTORE TANKS",
  "Hydrotap",
  "Hydrotc",
  "Hydrotec",
  "HYDROTEC (UK) LTD",
  "Hydrotec UK",
  "HYDROTEC UK LTD",
  "HYDROTEC UK LTD.",
  "HYDROTECH",
  "Hydrovaine",
  "Hydrovane",
  "Hydrovant",
  "Hydrovar",
  "Hydrovar flow Mech",
  "hydrovern coolers - evans lifts",
  "hydroware",
  "HYDRVERN LTD",
  "HYFRA INDUSTRIES",
  "Hygea",
  "Hygena",
  "Hygenic",
  "Hygenic Hand Dryers",
  "HYGENNA",
  "HYGIENEGUARD",
  "HYGIENIC",
  "Hygienic concepts",
  "Hygienic Environmental Services",
  "Hygood",
  "Hygromatik",
  "HYGROMETRIC",
  "Hylite",
  "HYMECH INSTALLATIONS",
  "HYMO",
  "HYMO LIFT LTD",
  "HYPER WARWICK",
  "Hypertherm",
  "Hyroflow",
  "Hyross",
  "Hyross - Hi Frame",
  "HYROTEC",
  "Hyster",
  "HYTEC",
  "HYTECK",
  "HYTEK",
  "HYTEK POWER PROTECTION",
  "HYTERA",
  "HYUNDAI",
  "HZ ELECTRICAL SERVICES LTD.",
  "I",
  "I & M CONTROLS",
  "I & M CONTROLS LTE",
  "I & S Industrial Doors",
  "I C W POWER",
  "I C W SWITCHGEAR",
  "I DESIGN",
  "I E S",
  "i light",
  "I ON 16",
  "I&M Control Solutions Limited.",
  "I&M CONTROLS",
  "I&M CONTROLS LTD",
  "I&M CONTROLS.",
  "I.M.I.M",
  "I.R.T.S Ltd",
  "I.T.S.",
  "IAC",
  "IAL",
  "Ian Fellows",
  "IandM Controls",
  "IANUA INDUSTRIAL DOORS",
  "IAPT",
  "IARP",
  "IAS",
  "IAWS Food",
  "IB",
  "Iberital",
  "IBERNA",
  "IBM",
  "IBMS",
  "IBS",
  "IC PRODUCTS",
  "IC REALTIME",
  "IC Rung Street Ladder",
  "Icam",
  "ICAR",
  "ICBT/SIEMENS",
  "ICC",
  "ICE",
  "Ice Box",
  "ICE CORE",
  "ICE KING",
  "ICE MUNTAIN",
  "ICE ONE",
  "ICE Q",
  "ICEKIN",
  "ICEKING",
  "Icel",
  "Iceline",
  "Icenci Triclass",
  "Icenwood",
  "Ice-Omatic",
  "Ice-O-Matic",
  "Icespy",
  "ICI",
  "ICI CALDAIE",
  "ICI CALDAIE BOILER",
  "ICME",
  "ICOM",
  "ICON",
  "ICP Projects",
  "ICS",
  "ICS Cool Energy",
  "iCS Cool Energy Ltd.",
  "ICS GROUP",
  "ICS INDUSTRIAL COOLING",
  "ICW",
  "ICW LTD.",
  "ICW POWER LIMITED",
  "ICW Switchgear",
  "ICW SWITCHGEAR COMPANY LTD",
  "ICW SWITCHGEAR LTD",
  "ICW Power",
  "ID CONTROL SYSTEMS",
  "ID WORN",
  "IDE",
  "idea",
  "Ideal",
  "IDEAL BOILERS",
  "IDEAL C9NCORD",
  "Ideal CLASSIC",
  "IDEAL CLIMA",
  "Ideal De Dietrich",
  "Ideal Standard",
  "Ideal Stelrad",
  "IDEAL MODULAR",
  "IDEAL VICEROY",
  "IDENTEC LTD",
  "IDEOMAT UK",
  "Idependent Lifts Ltd",
  "IDESIGN",
  "Idesit",
  "IDG",
  "IDHT",
  "IDM Engineering",
  "IDMI",
  "IDOMAT UK LTD",
  "Ids",
  "IDS Fire & Security",
  "IDS Manchester",
  "IDYSTRIAL DOOR ENGINEERING",
  "IE Magic",
  "IEAL CONCORD",
  "iEi",
  "IEP TECHNOLOGIES",
  "IER GROUP",
  "ies",
  "IES GROUP",
  "IET",
  "IFENX",
  "IFLO",
  "IFLOW",
  "IFRON",
  "IFV SPA DOMUS LIFT",
  "IGCOM",
  "IGD",
  "IGDEAL",
  "Igenics",
  "IGENIX",
  "IGENIY",
  "IGENNIX",
  "igenu",
  "IGENXI",
  "igloo",
  "IGNEX",
  "Igo",
  "IGRANIC CONTROL SYSTEMS",
  "IGRANIC CONTROL SYSTEMS.",
  "IGRANIC CONTROLS",
  "IGROX LTD",
  "IGTM",
  "IGTM Gas Turbine",
  "Iguzzini",
  "iIDE",
  "IITIAL",
  "IIVARMA",
  "IKEA",
  "ILG",
  "ILG - CORRADI",
  "iLIGHT",
  "i-light",
  "iLIGHT LTD",
  "ILINE",
  "I-LINE",
  "Illuma",
  "illumino ignis Ltd",
  "ILME",
  "iloret",
  "ILS",
  "ILSA",
  "Iltron",
  "Ilusol",
  "Ilyama",
  "IM CORNELIUS (UK) LTD",
  "IMAC",
  "IMAC SYSTEMS",
  "Imac Systems Ltd",
  "IMAX SYSTEMS LTD",
  "IMB",
  "IMC",
  "Imco",
  "IME",
  "IMEDCO AG",
  "Imer",
  "Imera",
  "IMET",
  "Imeter",
  "IMG",
  "IMI",
  "IMI AIR CONDITIONING",
  "IMI AIR CONDITIONING LTD",
  "IMI BAILEY BIAKETT",
  "IMI BAILEY BIRKETT LTD",
  "IMI CORNELIOUS UK LTD.",
  "IMI CORNELIUS",
  "IMI CORNELIUS LTD",
  "IMI MARSTAIR",
  "IMI Range Ltd",
  "IMI Rikroft",
  "IMI RYCO LTD",
  "IMI Rycroft",
  "IMI Rycroft Ltd",
  "IMI RYECROFT",
  "IMI SANTON",
  "IMI TA",
  "i-mini",
  "IMIS",
  "IMITECH / MEICA / TULL",
  "Iml",
  "Immergas",
  "Immersion Htr",
  "imo",
  "IMOFA",
  "IMOFA LTD",
  "IMOFA UK",
  "IMPACT",
  "IMPACT CONTROL SYSTEMS",
  "IMPACT CONTROL SYSTEMS LTD",
  "IMPACT CONTROL SYSTEMS LTD.",
  "IMPACT CONTROLS",
  "IMPAX",
  "Imperial",
  "Imperial locks",
  "IMPERIAL MACHINE COMPANY",
  "IMPERIAL PORCELAIN",
  "Implex",
  "IMPRESS",
  "IMPRESSING",
  "impro",
  "IMPRO ACCESS CONTROL",
  "IMPRO TECHNOLOGIES",
  "IMPULSE",
  "IMQ",
  "IMS",
  "IMTECH MEICA",
  "IMTECH MEICA / T F TULL LTD",
  "IMV",
  "Imy Rycroft",
  "IN BEV",
  "IN CUISINE",
  "IN OMAK",
  "IN SINK ERATRO",
  "In Depth",
  "INA",
  "Inbal Valves",
  "INBEV",
  "INC",
  "INCERT",
  "In-Cuisine",
  "independant",
  "Independant Building Solutions",
  "Independant Drainage",
  "INDEPENDENT ACCESS LTD.",
  "Independent Lift company",
  "INDEPENDENT LIFT LTD",
  "Independent Lifts Ltd",
  "INDESI",
  "INDESIGN",
  "INDESIR",
  "Indesirt",
  "Indesit",
  "Indest",
  "INDEX",
  "INDEX SECURITY",
  "INDEX SECURITY SYSTEMS",
  "indigo",
  "Indigo +",
  "INDIGO VISION RACK",
  "INDIGOVISION",
  "Indoor Climate Systems Ltd",
  "Induced Energy Ltd",
  "Induction",
  "INDUCTOVENT",
  "INDUPART",
  "Industore",
  "INDUSTRAIL DOOR ENGINEERING",
  "INDUSTRAL DOOR ENGINEERING",
  "Industrial Air Cleaner",
  "Industrial Battery Charger",
  "INDUSTRIAL BLOWER SERVICES",
  "INDUSTRIAL CHARGER SERVICES LTD",
  "INDUSTRIAL COOLING",
  "INDUSTRIAL COOLING SYSTEMS (ICS) LTD.",
  "industrial dooor systems",
  "Industrial door company",
  "INDUSTRIAL DOOR EMGINEERING",
  "INDUSTRIAL DOOR ENGINEERING",
  "Industrial Door Engineering.",
  "INDUSTRIAL DOOR ENGINERING",
  "Industrial Door Specialist",
  "Industrial Door Specialists",
  "industrial door systems",
  "industrial door systems ltd",
  "INDUSTRIAL DOORS ENGINEERING",
  "INDUSTRIAL DOORS SERVICES",
  "Industrial Eergy & Contros",
  "Industrial electronic",
  "industrial engineering",
  "Industrial Flow Control Ltd",
  "INDUSTRIAL FRIGO",
  "INDUSTRIAL LADDERS AND STEPS",
  "Industrial Logistics",
  "Industrial Pumps & Process",
  "Industrial solutions",
  "Industrial switchgear services Ltd",
  "Industrias",
  "INDUSTRIE AUTOMATION",
  "INDUSTRIE CBI",
  "INDUSTRIL DOOR ENGINEERING",
  "INDUSTROLITE",
  "INDUSTRONICS",
  "INDUSTTRIE CBI",
  "INDUX",
  "INEPRO METERING",
  "Inergen",
  "INERGEN FIRE EXTINGUISHANT",
  "INERGEN PREMIER",
  "Inergen Premier, Tyco",
  "INERGEN. PREMIER FIRE ALARM CONTROL",
  "INESCT-O-CUTOR",
  "infersoll rand",
  "INFINITY",
  "INFINOVA",
  "info",
  "INFO GRAPHIC SYSTEMS",
  "INFO GRAPHIC SYSTEMS.",
  "INFO GRAPHICS SYSTEM",
  "INFO GRAPHICS SYSTEMS",
  "Infocus",
  "infographic systems",
  "INFORM",
  "Information plate weathered away",
  "INFOSEC",
  "Infotec",
  "INFRA",
  "infra-pod duo",
  "INFRES METHODEX",
  "Infress",
  "INFRI",
  "Infrico",
  "INFRO GRAPHICS SYSTEMS",
  "Infroheat",
  "INGEERSOLL RAND",
  "INGENIX",
  "Ingersoil",
  "INGERSOL",
  "Ingersol Rand",
  "Ingersol Rand",
  "Ingersol-Dresser Pumps",
  "INGERSOLERAND",
  "Ingersoll Dresser",
  "INGERSOLL DRESSER PUMPS",
  "INGERSOLL RAND",
  "Ingersoll/Dresser",
  "INGERSOLL/RAND",
  "Ingersoll-Dresser",
  "INGERSOLLRAND",
  "Ingersoll-Rand",
  "Ingersol-Rand",
  "Ingesoll Rand",
  "INGNIX",
  "INGRSOL RAND",
  "INGRSOLL RAND",
  "INI",
  "INITIAL",
  "Initial ARITECH",
  "INITIAL AUTOMATIC SERVICES",
  "INITIAL CARE",
  "INITIAL FIRE SYSTEMS",
  "Initial Shorrock",
  "INITIAL TEXTILE SERVICES",
  "INITIALCARE",
  "INITIAL-DUDLEY INDUSTRIES",
  "INKA",
  "INKA GELEEN",
  "Inkel",
  "inla galeen",
  "Inline Fan",
  "Inlins",
  "INNERGEN",
  "INNEX",
  "Innov8",
  "INNOVATEC",
  "INNOVATION",
  "Inomak",
  "INOVA",
  "INOVO",
  "INS/ATS",
  "INSAFE",
  "insanta ltd",
  "Insec-o-cutor",
  "Insect A Flash",
  "Insect flash",
  "INSECT KILLER",
  "INSECT O CLEAR",
  "INSECT OCUTER",
  "INSECT OCUTOR",
  "INSECTA CLEAR",
  "INSECTA FLASH",
  "Insecta killer",
  "Insectaclear",
  "Insect-a-clear",
  "insectacutor",
  "insectacutor Titan 300",
  "Insectaflash",
  "Insect-A-Flash",
  "Insecta-Flash",
  "INSECTAZAP",
  "Insecter Flash",
  "INSECTFLASH",
  "INSECTICUTOR",
  "INSECTO CUTOR",
  "INSECT-O-CLEAR",
  "Insect-o-cuter",
  "INSECTOCUTOR",
  "INSECT-O-CUTOR",
  "INSECTOCUTOR - RIWA LTD",
  "INSECTOCUTOR.  RIWA LTD",
  "INSECTOCUTOR.  RIWA LTD.",
  "INSECT-O-CUTROR",
  "Insectoflash",
  "INSECTUCUTOR",
  "insecutaflash",
  "INSESIT",
  "INSIGHT",
  "INSIGHT ACCESS & HANDLING DIVISION",
  "INSINKERATOR",
  "Instal pro",
  "instanmata",
  "Instant",
  "Instant A",
  "Instant Zip-Up Ltd",
  "INSTANTA LTD",
  "Instantia",
  "INSTECH LTE",
  "Instecter Flash",
  "Instonta",
  "INSTROMENT",
  "INSTROMET",
  "INSTRON",
  "Instrument",
  "instrument uk",
  "Instrumet",
  "INSUDOOR",
  "INSULATED",
  "Insutech",
  "INTA",
  "INTAK",
  "INTECH",
  "Integr8",
  "INTEGRA",
  "INTEGRAL",
  "Integral Controls",
  "INTEGRAL FIRE DIVISION",
  "Integrated",
  "INTEGRATED BMS",
  "Integrated building management solution ltd",
  "Integrated building management solutions ltd",
  "INTEGRATED BUILDING MANAGEMENT SYSTEMS LTD",
  "Integrated Control Systems",
  "Integrated Controls",
  "integrated plantroom technologies",
  "INTEGRATED POWER TECHNOLOGY",
  "INTEGRATED POWER TECHONOLGY",
  "INTEGRATED SERVICES",
  "INTEGRATED WATER SERVICES",
  "INTEGRATED WATER SYSTEMS",
  "INTELEX",
  "Intelilite",
  "INTELISYS",
  "Intellevac",
  "Intellex",
  "Intellex DVMS",
  "INTELLICOLD",
  "INTELLIGAS",
  "INTELLIMGENT LOCKING SYSTEM LTD",
  "Intellisense",
  "Intellog",
  "INTELLOWAVE",
  "inter",
  "INTER GUARD",
  "INTER M",
  "INTERACTIVE INTELLIGENCE",
  "Interair",
  "Interalia",
  "INTERCALL",
  "Intercall Secure Systems",
  "INTERCLISA",
  "Interevent",
  "INTERGRA8",
  "INTERGRAL",
  "Intergral Controls",
  "INTERGRATED DESIGN LTD",
  "INTERGRATED POWER SOLUTIONS (SCHNEIDER ELECTRIC)",
  "INTERGRATED POWER TECHNOLOGY",
  "INTERIOR CLIMATE SYSTEMS LTD.",
  "INTERKLIMA",
  "Interleven",
  "INTERLEVIN REFRIGERATION LTD",
  "INTERLOGIC",
  "Interlogix",
  "INTERM",
  "Intermatic Incorporated",
  "INTERMED-PENLON",
  "INTERMP",
  "International Electric Ltd",
  "International Environmental corp.",
  "INTERNATIONAL GAS DETECTION",
  "INTERNATIONAL LIFT EQUIPMENT",
  "INTERNATIONAL LIFTING EQUIPMENT",
  "International transformers",
  "INTERREG 1V B",
  "INTERTEC SYSTEMS",
  "intervarem",
  "Intervent",
  "INTERVIEW",
  "INTOUCH CONTROL SYSTEMS",
  "INTREGRATED POWER TECHNOLOGY",
  "INTROLAND",
  "Introland Ltd",
  "INTROLAND LTD.",
  "Intromet",
  "INTRRNATIONAL FOOD SERVICE EQUIPMENT",
  "Intruder alarm",
  "intruder alarm system",
  "INTUITIVE",
  "INTUITIVE CONTROLER",
  "INTUITIVE CONTROLLER",
  "Invacare",
  "Invalifts Ltd",
  "INVENSYS",
  "INVENT",
  "INVENT WATER TREATMENT LTD",
  "Inventor",
  "INVENT-UK",
  "INVENTUM",
  "INVERTEK",
  "inverter",
  "Inverter Drives",
  "INVIRON LTD",
  "INVOA",
  "inyerpower",
  "IOM",
  "ION",
  "i-On",
  "I-on Alarm",
  "I-on16",
  "IONA VENTILATION",
  "IOWARA",
  "IP ACCESS & CONTROL MONITORING",
  "IPA CONTROLS LTD",
  "IPC Projects Ltd",
  "IPR",
  "IPR ITALIANA PORTE RAPIDE",
  "IPSO",
  "IPU",
  "IPU Group",
  "IQ Engineers",
  "IQ VIEW",
  "iq view 4",
  "IQE",
  "IQE VIEW",
  "IR",
  "IR DOR-O-MATIC",
  "IR INNER RANGE",
  "IR LCN",
  "IR RECOGNITION SYSTEMS",
  "IRCON-BV",
  "IRFORCE",
  "IRINOX",
  "IRIS",
  "IRIS+",
  "Irish Cooling Towers",
  "IRISH LIFT SERVICES",
  "Irish Ventilation and Foltration",
  "Iron",
  "Iron 150",
  "IRS PLANT & MACHINER",
  "IRUMA",
  "Irvine Switch & Control Panels",
  "IS",
  "is air curtains",
  "IS INITIAL",
  "IS INITIAL AUTOMATIC SERVICES",
  "Isa",
  "ISAR",
  "ISARK",
  "ISB",
  "ISCO",
  "ISCOMEC",
  "iseal",
  "ISEAL STANDARD",
  "Iseek bros.",
  "ISHI TOKEI",
  "ISI",
  "ISI LTD",
  "ISI SAFETY",
  "Isisan",
  "ISKAR",
  "ISKARA",
  "ISKRA",
  "Island Units",
  "ISM",
  "ISMET",
  "ISMRA",
  "ISOBAR 4C",
  "Isoflow",
  "isolatorpanel",
  "ISOMATIC",
  "ISOPAD",
  "ISOPAD LTD",
  "ISO-Tech",
  "Isotherm",
  "ISOVEL",
  "ISS",
  "ISS CONCORD",
  "ISS INITIAL",
  "ISS Technical Services",
  "ISTA",
  "iStar",
  "iSTAR PRO",
  "iSTAT Pro",
  "isys",
  "IT JLA",
  "Itab",
  "Itab shop products",
  "ITAL",
  "ITALFARAD",
  "ITALIAN TECHNOLOGY",
  "ITALIANO EXPRESS",
  "ITALKERO",
  "Italservice SRL",
  "Italtecica",
  "ITE",
  "Itec",
  "ITECH",
  "Itegra",
  "ITEON",
  "Itergra",
  "ITFRON",
  "ITI",
  "ITL",
  "Iton",
  "Itrak Wireless",
  "Itrom Schroder",
  "ITRON",
  "ITRON CYBLE",
  "Itron EverBlu Cyble",
  "ITT Controls",
  "ITT FLYGT",
  "ITT HYDROVAR",
  "ITT LOWARA",
  "ITT Raznor",
  "ITT Renzor",
  "ITT Reznor",
  "ITTDGC",
  "iv product",
  "iv products",
  "iv produkt",
  "iv produkts",
  "IVANHOE",
  "IVAR",
  "Iveco",
  "Ives and Kennard",
  "IWAKI",
  "Iwaki Mag Drive Pump",
  "IWAKI METERING PUMP",
  "IWAVE",
  "IWM",
  "IWS",
  "i-ZONE",
  "J & E HALL",
  "J & G Hamilton",
  "J & P DELTA",
  "J & P SUPPLIES",
  "j + e hall",
  "J C WATSON",
  "J E Avons",
  "J E Hall",
  "J E Johnson",
  "J E Johnson & Sons",
  "J E Johnson Ltd",
  "J E Johnson Toddington",
  "J E Stork",
  "J H BARCLAY",
  "J JONES",
  "j kelly",
  "J L Industries",
  "J LEACH & CO LTD",
  "J M D",
  "J P SOUTHERN",
  "J S AIR CURTAINS",
  "J S HUMIDIFIERS",
  "J SEED & CO LTD",
  "J Tomlinson ltd",
  "J W Morris",
  "J W SMART PROCESS",
  "J WATSON",
  "J S Humidifier",
  "J&C Joel Ltd",
  "J&E HALL",
  "J&E HALL INTERNATIONAL",
  "J&E HALL LTD",
  "J&P",
  "J&P DELTA",
  "J. B. ELECTRICAL",
  "J. BAGULEY AND SONS",
  "J. D. FIRE LTD",
  "J. MARSTON ENGINEERS",
  "J.A. Richardson Electrical Ltd",
  "J.B. INDUSTRIAL DOORS",
  "J.B.C CControl Systems LTD.",
  "J.Baguley & Sons Ltd",
  "J.Balan",
  "J.D FIRE LTD",
  "J.D.FIRE LTD",
  "J.E.STORK",
  "J.H. FIRE PROTECTION",
  "J.I.L. LEACH & CO LTD",
  "J.P. SAUER & SONS",
  "J.P.F. SYSTEMS LTD",
  "J.P.SAUER & SONS",
  "J.S. AIR CURTAINS",
  "J.S. HUMIDIFIERS plc",
  "JA ENVIROTANKS",
  "Jabsco",
  "Jabsco utility pump",
  "Jac",
  "Jackdaw",
  "JACKLOC",
  "JACKLOK",
  "Jackson",
  "JACKSON & KELLY",
  "JACKSON AND LEWIS",
  "JACKSON AUTO-GATES",
  "Jackson by Creda",
  "JACKSON CONTROL",
  "Jackson Controls",
  "JACKSON LEWIS CONTROLS",
  "Jackson Lift",
  "JACKSON LIFT GROUP",
  "JACKSON LIFTS",
  "JACKSON LIFTS & LESTER CONTROLS",
  "Jackson Switchgear",
  "JACKSONS",
  "JACKSONS AUOTMATIC GATES",
  "Jacksons Auto Gates",
  "Jacksons Fencing",
  "JACKSONS FENCING SYSTEMS",
  "Jactone",
  "Jacuzzi",
  "Jade",
  "JADES DOORS Ltd",
  "JAEGGI",
  "Jaguar",
  "JAHIB",
  "JAM LTD",
  "JAMES & GRAHAM & CO LTD.",
  "JAMES COLLINS",
  "JAMES GRAHAM",
  "James Graham + Co LTD",
  "James Kilpatrick",
  "James Kilpatrick & Son",
  "JAMES RITCHIE & SONS LTD.",
  "JAMES S GRAYAM",
  "James Dring",
  "Janka",
  "JANUS SYSTEMS",
  "JAOTECH",
  "Jarp",
  "JASMIN CONTROKS",
  "Jasmin Controls",
  "JASMIN CONTROLS LTD",
  "JASPER",
  "JAVELIN",
  "Jazz",
  "JB",
  "JB ELECTRICAL LTD",
  "JBC",
  "JBC CONTROL",
  "JBC CONTROL SYSTEMS LTD",
  "JBC CONTROLS",
  "JBC CONTROLS LTD",
  "JBG",
  "jbikbviyvi",
  "JCB",
  "JCB CONTROLS",
  "JCC",
  "JCI Regelungstechnik",
  "JD Fire Ltd",
  "JD SYSTEMS LTD.",
  "JDM",
  "JE Hall",
  "je johnson",
  "JE JOHNSON LTD",
  "JEAN MULLER",
  "Jeaons",
  "Jeatrange",
  "Jeauons",
  "jeavone",
  "JEAVONS",
  "JEAVONS METER SYSTEMS UK",
  "JEAVUNS",
  "JEBRON",
  "JEDIA",
  "JEL",
  "JEL / SCHNEIDER",
  "JEL ENERGY CONSERVATION SERVICES LTD",
  "Jeld Wen",
  "JELSTAR",
  "JEMI",
  "Jenco",
  "JENCONS",
  "JENELEC",
  "JENELEC INDUSTRAL POWER SOLUTIONS",
  "Jenkins",
  "Jenks",
  "Jenn-Air",
  "jensen",
  "Jenstar",
  "Jenway",
  "JEROME ENGINEERING (VESSELS) LTD.",
  "JES Group",
  "Jesco",
  "JET 3 THERMOSCREEN",
  "JET 6 LTR",
  "Jet chef",
  "JET CLENSE",
  "Jet Contra Flex",
  "JET ENVIRONMENTAL TECHNIQUES LTD",
  "JET HAND DRYER",
  "JET HAND DRYERS",
  "JET HAND SRYER",
  "JET PIPELINE CENTRE LTD.",
  "JET RANGE",
  "jet ring",
  "JET.  SIZE 3.  6LTR",
  "JET. SIZE 2,  3.5LTR",
  "jetmarine",
  "JETSPRAY",
  "JETSTAR",
  "JETSTREAM",
  "JETWAVE",
  "Jeven",
  "Jevons",
  "JEWEL",
  "JEWER DOORS LTD.",
  "Jewers",
  "Jewers Doors Ltd",
  "JEWSON",
  "JF NELSON LTD",
  "Jfk Industri",
  "JG Corporation",
  "JHP",
  "JIB CRANE",
  "JIHAB",
  "JIHN GODDEN LTD",
  "JIMI",
  "JIMI-HEAT",
  "JL",
  "JL Control",
  "JL Industries",
  "JL LINDA LEWIS KITCHENS",
  "JLA",
  "Jla 16",
  "JLA 5",
  "JLA 88",
  "JLA 98",
  "JLA Halifax",
  "Jla16",
  "JLC AUTOMATION",
  "JLC AUTOMATION SERVICES",
  "JLG",
  "JLG Industries",
  "JLG List",
  "JLG MANUFACTURING EUROPE BVBA",
  "JLG TOUCAN",
  "JM3",
  "JMB",
  "jmd",
  "JMD GILGEN DOOR SYSTEMS",
  "JMD LTD",
  "JMO",
  "JMS TRANSFORMERS",
  "JNI Safe Access",
  "JOB",
  "JOB DETECTOMAT",
  "JOC",
  "JOFEL",
  "JOHN BERRY CONTROLS",
  "John Bradshaw",
  "JOHN BRADSHAW LTD",
  "JOHN CARTER",
  "JOHN CONTROLS",
  "John Deer",
  "JOHN DEERE",
  "JOHN DRUMMOND (ENGINEERING) LTD",
  "JOHN DRUMMOND (ENGINEERS) LTD",
  "JOHN DRUMMOND ENGINERS",
  "JOHN DRUMMOND LTD",
  "John Godden",
  "JOHN GODDEN LTD",
  "John Goddon Switchgear",
  "JOHN HEANEY ELECTRICAL",
  "John Hunt",
  "JOHN LAING",
  "John Lewis",
  "John Newton",
  "John Porter",
  "John Smith cranes",
  "John Walker Controls",
  "John Deere",
  "John Kerr LTD",
  "Johnson",
  "Johnson & Baxter",
  "Johnson & Phillips",
  "JOHNSON & PHILLIPS LTD.",
  "JOHNSON & STANLEY",
  "JOHNSON & STARLEY",
  "JOHNSON & STARVEY",
  "JOHNSON BROTHERS",
  "Johnson Control",
  "Johnson Controls",
  "Johnson Ltd",
  "Johnson Pump AB",
  "Johnson Security",
  "Johnson Security Ltd",
  "JohnsonDiversey",
  "JOHNSONDIVERSY",
  "JOHNSONE",
  "JOHNSONS",
  "Johnsons Control",
  "JOHNSTONS CONTROLS",
  "JOHSON CONTROLS",
  "JOLLY",
  "JOMACK",
  "JONES SHIPMAN",
  "Jones & Shipman",
  "Jopuy",
  "Jordao",
  "JORDON",
  "Joseph Ash",
  "JOSEPH ASH & SON LTD",
  "JOSEPH GLEAVE AND SON",
  "Joule",
  "JOVENTA",
  "JP",
  "JP SOUTHERN",
  "JPS FIRE & SECURITY LTD",
  "JRT",
  "JS",
  "JS AIR CURTAIN",
  "JS Air Curtains",
  "JS Aircurtains",
  "JS BMS Controls",
  "JS BMS CONTROLS LTD",
  "JS HEATSCREEN",
  "JS HUMIDIFIERS LTD",
  "JS HUMIDIFIERS PLC.",
  "JS POWER LTD",
  "JS PUMP",
  "JS HUMIDIFIERS",
  "JSB",
  "JSB ELECTRICAL PLC",
  "JSB EMERGENCY LIGHTING",
  "JSP",
  "JSP DELTA",
  "Jss",
  "JTE",
  "JTE ENGINEERIMG LTD",
  "JTP",
  "JUMBOFLUSH",
  "JUMO",
  "Jun Air",
  "JUNAIR",
  "Jun-Air",
  "JUNAIR SPRAYBOOTHS",
  "Junc Pumpen",
  "JUNG",
  "Jung Pumpen",
  "JUNG PUMPS",
  "Jungheinrich",
  "Junior",
  "Junior spraybooths",
  "Juniper",
  "JUNO",
  "JUNO 1 - 3",
  "JURA",
  "Just Coolers",
  "JustEau",
  "JVC",
  'jvc 42"',
  "JW Instruments",
  "Jwc",
  "JWC INTERNATIONAL",
  "JWI INSTRUMENTS",
  "JWS",
  "JWS LTD",
  "K & H",
  "K & H MEDICAL LTD.",
  "K Controls",
  "K COOL",
  "K ELECTRIC",
  "K&F",
  "K&H Medical Ltd",
  "K. J. WATER TREATMENT LTD",
  "K. RUTSCHI AG",
  "K.C. CULMER LTD.",
  "K.E.W. Cleaning Systems",
  "K.K. CONTROLS",
  "K/Patents process refractometers",
  "K0NE",
  "K-3478",
  "K5 EVACUATION CHAIR EQUIPMENT",
  "K5 EVACUATION CHAIRS",
  "KABA",
  "KABA DIRMA",
  "Kaba Door System",
  "KABA DOOR SYSTEMS",
  "Kaba Doors",
  "Kaco",
  "KAERSER (OKS)",
  "KAESAR",
  "Kaeser",
  "KAESER KOMPRESSOR",
  "KAESER KOMPRESSOREN",
  "KAESER KOMPRESSOREN SE",
  "KAESER",
  "KAIR",
  "kait",
  "KALEA",
  "Kalmar",
  "kaloric",
  "Kaloric Heater Co.",
  "Kalstrup",
  "Kamatrup",
  "Kampmann",
  "Kampstrop",
  "Kampstrup",
  "Kamstrip",
  "KAMSTRUM",
  "Kamstrump",
  "Kamstrup",
  "KAMSTUP",
  "Kanalflakt",
  "KANE",
  "Kane Engineering",
  "Kane Int Ltd",
  "Kangarillo",
  "KANGO",
  "Kangrillo",
  "kanstrup",
  "Kantech",
  "Kapman",
  "Kappa",
  "KARA",
  "KARA DOORS",
  "KARCHER",
  "Kardex",
  "Kardex Industriever",
  "Karizma",
  "KARL H.BARTELS GMBH",
  "KARMA",
  "KARTOS",
  "Kartret",
  "Kartret Switchgear",
  "Kasa-Tec",
  "kasser",
  "KATKO",
  "KATSUYUKI",
  "KATT",
  "KAWNEER",
  "KAYANSON",
  "Kaz",
  "KAZ MOBILE AIR CONDITIONER",
  "KB ENGINEERING (MODIFIED 2010)",
  "KBS",
  "KCC",
  "K-CON",
  "KCOOL",
  "KCS",
  "KD",
  "Kd doors",
  "KDE",
  "KDG",
  "KDG MOBREY",
  "Kdg Mowbrey",
  "KDS",
  "KDS SOLUTIONS",
  "KEARNS RICHARDS",
  "Keating",
  "KEC",
  "KEE GUARD",
  "KEEP KEEN CONTROLS",
  "KEEP KEEN CONTROLS LTD",
  "KEEPRITE",
  "KEEPSTOCK",
  "KEESAFETY",
  "KEESTON",
  "KEIBACK&PETER",
  "KEIGHLE LIFTSY",
  "Keighley",
  "KEIGHLEY LIFT",
  "Keighley Lifts",
  "KEIGHLEY LIFTS LTD",
  "Keighley Oleomatic",
  "KEIGHLEY ROLLER",
  "Keistad",
  "KEITH & BLACKMAN",
  "KEITH BLACKMAN",
  "KEITH BLACKMAN LTD",
  "KEITH BLACKMAN LTD.",
  "KEITH BLACLMAN",
  "KEITO",
  "Keldrigg Shutters & Grilles Ltd.",
  "Kele",
  "kelio",
  "KELIO TERMINAL",
  "KELSTON",
  "Kelvion",
  "KEMA",
  "KEMA KEUR",
  "KEMAN",
  "KEMCO SYSTEMS",
  "KEMICO",
  "Kemp Combustions Ltd",
  "Kemper",
  "KEMPI",
  "Kemppi",
  "KEMTEC ELECTRONICS LTD",
  "KEMTECELECTRONICS LTD",
  "KEMTECH LTD",
  "KEN",
  "KEN WHEELER CATERING EQUIPMENT",
  "Kenbay Compaction",
  "Kenco",
  "KENDRO",
  "KENETIC SYSTEMS BRISTOL LTD",
  "Kenff",
  "KENMORE",
  "Kennedy",
  "KENNEL MAID",
  "Kennooa",
  "kensaheatpumps",
  "Kensington",
  "Kenstar",
  "Kent",
  "KENTEC (WESTRONICS LTD.)",
  "KENTEC ELECTRONICS LTD.",
  "Kentec Syncro",
  "KENTEC,  FIRE PRO",
  "Kentecnelectronics",
  "Kentic Electrics",
  "Kenwood",
  "KERAFLO",
  "Keraflow Ltd",
  "Kerdland Security",
  "Kerlin Gergin",
  "kern",
  "KERO- GALLS FUELS",
  "KERRY",
  "kershaw mechanical",
  "Kerstar",
  "KERYS",
  "KES ENERGY SOLUTIONS",
  "KES Installations",
  "KES Power",
  "Kes products",
  "KESCOCOIL",
  "KESON",
  "Kestevan",
  "KESTON",
  "KESTON BOILERS",
  "KESTRAL",
  "KESTRAL BUILDING SERVICES",
  "KESTRAL CONTROLS",
  "KESTRAL CONTROLS LTD",
  "KESTRAL GUATD",
  "Kestrel",
  "Kestrel Building Services & Control Specialists",
  "ketch",
  "KETH BLACKMAN LTD",
  "KETTLER",
  "Kevex",
  "Kewtech",
  "KEY ELEVATORS",
  "Key Lighting",
  "KEY SOURCE",
  "Keyguard",
  "Keylex",
  "KEYPAD",
  "Keypsafe",
  "Keysource",
  "Keystone",
  "Keytor ltd",
  "KEYWAYS",
  "KFC",
  "KFN PILLINGER",
  "KGN",
  "KGN Pilliger",
  "KGN PILLINGER",
  "KGN-PILLINGER",
  "KH",
  "KH Medical",
  "KHL WATER4YOU",
  "KHS",
  "khtd",
  "kiaser",
  "Kickspace 800",
  "Kidde",
  "KIDDE FENWAL",
  "KIDDE FIRE PROTECTION",
  "Kiddie",
  "Kiddie fire protection",
  "Kiddle Fire Protection",
  "Kieach&Peter",
  "KIEBAC & PETER",
  "Kieback & Peter",
  "kieback andpeter",
  "Kieback&Peter",
  "KIEBACKER & PETER",
  "KIEBACKER&PETER",
  "KIEBACKSMETER",
  "kiebeck & peter",
  "KIEBECK AND PETER",
  "KIEBECK&PETER",
  "Kiebell & Peters",
  "KIECHEL AND HAGLETFAR",
  "Killarney",
  "Killarney Plastic",
  "Killarney Plastics",
  "KILLROY SECURITY/TAG",
  "KILN AND FURNACES LIMITED",
  "KILNCARE",
  "kilns and furnacs limited",
  "Kiloheat",
  "Kiloheat Fans",
  "KILOHEAT LTD",
  "KILOHEAT LTD.",
  "KILPATRICK",
  "Kimberly Clark",
  "Kimberly-Clark",
  "KIMESSA SWISS",
  "KIMMON",
  "KIMO",
  "Kine",
  "Kinetic",
  "Kinetic Electronics ltd.",
  "KINETIC SUPPORT SYSTEM LLP",
  "Kinetic Systems",
  "KINETIC SYSTEMS LTD.",
  "Kinetico",
  "Kinetrol",
  "KING",
  "King Edward",
  "KING EXWARD",
  "KING REFRIGERATION LTD",
  "king sgphield",
  "King switch",
  "KING TANKTECHNIC",
  "King Tanktechnic Ltd.",
  "Kingavon",
  "Kingfisher",
  "KINGFISHER INTERNATIONAL PRODUCTS",
  "KINGFISHER MF",
  "Kings",
  "KINGS SECURITY SYSTEMS",
  "KINGS SECURITY SYSTEMS LTD",
  "KINGS SERCURITY",
  "kingsheild",
  "Kingshield",
  "KINGSLAND",
  "kingsley",
  "Kingsmill",
  "KINGSMILL IND (UK) LTD",
  "Kingspan",
  "KINGSPAN - ALBION",
  "KINGSPAN ENVIRONMENTAL",
  "KINGSPAN HOT WATER SYSTEM",
  "KINGSPAN TITAN",
  "KINGSPAN WATER",
  "Kingspanwater",
  "Kingsplan",
  "Kingston",
  "Kingston Controls",
  "Kingsway",
  "Kingsway Landon",
  "Kingsway Langdon",
  "KINGSWAY LONDON",
  "KINGSWAY LTD",
  "KINGWAY LANDON",
  "Kinmatic",
  "KINROSS",
  "Kinross Control Systems",
  "kinross control systems ltd",
  "KINROSS CONTROLS",
  "Kintec electronics",
  "KIRBY",
  "KIRBY GROUP",
  "KIRBY INSURANCE",
  "KIRBY SECURITY",
  "KIRBY SECURITY (UK) LTD",
  "Kirsch",
  "Kirton",
  "KITCHEN AID",
  "KITCHEN AIR",
  "KITCHEN AND WADE",
  "KITCHEN PERFECTED",
  "Kitchen Aid",
  "Kitchenaid",
  "KitchenAide",
  "Kitcheneers",
  "KITCHEN-WALKER",
  "KITTY",
  "KITZ",
  "KIVEX BIOTEC LTD",
  "KIWA",
  "KJ WATER SUPPLIES",
  "KJ Water Treatment",
  "KJ WATER TREATMENT LTD",
  "KJM",
  "kk controls",
  "KKW Kulmbacher",
  "KLARGESTER",
  "KLEEMAN",
  "KLEEMANN",
  "kleemann hellas",
  "KLEENAIR",
  "Kleeneze",
  "Kleenmaid",
  "Kleindienst",
  "KLIGENBURG",
  "KLIK Vending",
  "KLIMA",
  "KLIMAC",
  "Klimasan",
  "Klimatechnik",
  "KLIME-EZEE",
  "Klimsan",
  "KLINGENBURG",
  "klippon",
  "klippon patol",
  "Klix",
  "KLOBEN",
  "KLOBEN SOLR EVLUTION",
  "KLOCKNER MOELLER",
  "Klóckner Moeller",
  "Klockner Moellor",
  "Klockner Moller",
  "Klockner Mueller",
  "Klockner-Moeller",
  "Klokner Moellor",
  "KLS MARTIN",
  "KLS MARTINGROUP",
  "km controls",
  "KMS CONTROL SERVICES",
  "KNBB",
  "KNG Pillinger",
  "Kngsheild",
  "Kngspan",
  "KNIGHT ELECTRICAL LTD",
  "KNIGHT ELECTRICAL LTD.",
  "KNIGHT ELECTRICAL SWITCHGEAR LTD",
  "KNOGHT ELEC SWITCHGEAR",
  "Knuth",
  "kob",
  "KOB HOLZFEEUERUNGEN",
  "KOB HOLZHEIZSYSTEME GMBH",
  "KOB WARME AUS HOLZ",
  "KOBE",
  "KOBI",
  "KOBOL",
  "Kobol Ryan Chill Group",
  "Kohler",
  "KOHLER UNINTERRUPTABLE POWER SUPPLIES LTD",
  "KOHLER-UNINTERRUPTIBLE POWER SUPPLIES LTD",
  "Kolmeks",
  "KOLMENS",
  "Kolpak",
  "KOMPACT",
  "KONCAR",
  "Kone",
  "Kone ?",
  "KONE BRADY",
  "Kone Cranes",
  "KONE CRANES INDUSTRIAL DOORS",
  "KONE-BOLTON BRADY",
  "KONECRANES",
  "Konematic",
  "KONGAR",
  "Kongsberg",
  "KONI B.V",
  "KONR",
  "Kontrollnr",
  "KONVEKTA",
  "Kool",
  "Koolaire",
  "KOOLER KING",
  "KOOLSHADE",
  "kooltech",
  "KOOLTH",
  "KOOTSTERTILLE HOLLAND",
  "KOPAL",
  "KOPPERBUSCH",
  "KORCH SCHRODER",
  "KORINTO",
  "KOROKKA",
  "Koronka",
  "KORONKA LTD",
  "KOSPEL",
  "Kottermann",
  "Koxca",
  "KOXKA",
  "Kpowr perfector",
  "KPRM/SCHRODER",
  "KR0M SCHRODER",
  "KRAFT",
  "KRAL",
  "KRAMER",
  "Kramzle",
  "Kraus & Naimer",
  "KRAUS AND NAIMER",
  "Krea",
  "KRF Metal fabrications Ltd",
  "KRI KLIMATECHNIK S.R.L",
  "KROHM & SCHRODER",
  "KROHNE",
  "Kroll",
  "KROM SCHRADER",
  "KROM SCHROADER",
  "KROM SCHRODER",
  "Krom Schroer",
  "Krom Shroder",
  "Krom//Schroder",
  "Krom/Schroder- Elster",
  "KROMO",
  "KROMSCHRODER",
  "KROMSCHRODER KROMSCHRODER",
  "KRON SCHRODER",
  "Krone",
  "KRONE CRANES",
  "kronka",
  "KRONOS",
  "KRORO SCHRODER",
  "KRPM SCHRODER",
  "KRPM SHRODER",
  "KRPM/SCHRODER",
  "KRPRO Schneider",
  "Krueger",
  "Krueger VAV",
  "KRUGER",
  "krupps",
  "Krups",
  "kryo therm",
  "kryotherm",
  "kryothern",
  "KS",
  "ks test",
  "KSB",
  "KSB MOTORS",
  "KTEC",
  "K-TEC",
  "K-TECH",
  "KUBA",
  "Kubota",
  "Kubus",
  "Kupp",
  "Kuppersbusch",
  "KURACO",
  "KUROMA",
  "KUTZNER- WEBER",
  "KUYL AND  ROTTINGHUIS",
  "KVM-CONHEAT",
  "KW Energie",
  "KW Switchgear",
  "KWB",
  "KWh CUBE",
  "KWIKAYL",
  "KWIKCYL",
  "KYOCERA",
  "KYUNG DONG",
  "L & P ELECTRICAL SYSTEMS",
  "L Becker",
  "L Bernard",
  "L E C",
  "L G",
  "L Heizer",
  "L,UNITE HERMETIQUE",
  "L.A. HUSBANDS",
  "L.A. HUSBANDS LTD",
  "L.B.S. Group",
  "L.E.C",
  "L.G",
  "L.H. REFRIGERATION SERVICES",
  "L.HEIZER",
  "L.P.A Indistries",
  "L.T.HALL ELECTRICAL ENGINEERING LTD",
  "LA",
  "LA BARRIER AUTOMATIQUE",
  "LA CIMBALA",
  "LA CIMBALI",
  "LA CYMBALI",
  "la husbands",
  "LA MARZOCCO",
  "LA PAVONI",
  "LA PIAZALE",
  "La ROCHE CONTROLS",
  "LA SPAZIALA",
  "LA SPAZIALE",
  "LA.33",
  "Lab Cold",
  "LAB COLD BLOOD BANK",
  "labcaire",
  "Labco LTD",
  "Labcold",
  "Labcold Bloodbank",
  "LABCOLD-ADVANCED PHARMACY",
  "LABCOTEC",
  "LABEL",
  "Labex",
  "labgear",
  "LAB-IMPEX SYSTEMS",
  "LABKO FINLAND",
  "Labkotec",
  "LABOLOTEC",
  "LABORATORY THERMAL EQUIPMENT",
  "LABOTEC",
  "LabPlant",
  "LABSPACE",
  "LABSYSTEMS",
  "LABTECH.COM",
  "LAC",
  "LACAZE ENERGIES",
  "LA-CIMBALI",
  "Lacron",
  "Lacron ltd",
  "LACY HULBURT",
  "LADEN",
  "LADIS & GYN / RDL",
  "LADIS GYR",
  "LAE",
  "lae-srl",
  "LAFERT",
  "Lagged",
  "LAGWELL",
  "Laidlaw",
  "Lainox",
  "Laird",
  "LAKAL ACHENBACH GmbH & Co. KG",
  "Lakeside Security Shutters",
  "Lakestyle",
  "LAMAAOCCO",
  "Lamarche",
  "Lamarflo",
  "Lamber",
  "LAMDIS GR",
  "LAMMERS",
  "LAMONA",
  "lamona built under",
  "lamona builtunder",
  "LAMP OWADOBOJCZA",
  "LAMPA OWADOBJCZA",
  "Lamson 2",
  "LAN CRAFT",
  "Lan Elec",
  "Lancashire Crypto",
  "LANCER",
  "LANCER UK LTD.",
  "LANDER LARMS",
  "landis",
  "Landis &  Staefa",
  "LANDIS & GYR",
  "LANDIS & GYR PJW",
  "LANDIS & STAEFA",
  "LANDIS AND GYR",
  "LANDIS AND STAEFA",
  "Landis Gyn",
  "Landis Staffa",
  "LANDIS & GYR",
  "Landis& Staefa Advantage",
  "Landlord",
  "Landmark",
  "Landon",
  "Landon Kingsway",
  "Landon Kingsway.",
  "Landon kingsway",
  "LANDS & GYR",
  "LANDS GYR",
  "Landsmith",
  "Lane control systems",
  "LANE,ARK",
  "LANEMARK",
  "Lang",
  "Langham",
  "LANGHAM LIFTS LTD",
  "Langley",
  "LANGON KINGSWAY",
  "LANSING",
  "LANSING BAGNALL",
  "LAP",
  "LAPAUW",
  "LAPESA",
  "LAPLACE CONTROL SOLUTIONS",
  "Laptronix",
  "Larchway",
  "LARCHWAYS",
  "LARCON FILTER",
  "Large PSU for fire system",
  "Larmona",
  "Larp",
  "Lasd Profyre P2",
  "Laser",
  "Laser Alarms",
  "Laser Alarms Ltd",
  "LASERMET",
  "LASING BAGNALL LTD",
  "LASPANZIALE",
  "Laspazale",
  "LASPAZINA",
  "LASPEZIALLE",
  "latcheay",
  "Latchwats",
  "Latchway",
  "Latchway Plc",
  "LATCHWAYS",
  "LAURENCE SCOT & ELECTROMOTORS LTD",
  "LAURITZEN WATER TREATMENT",
  "LAVAMAC",
  "LAVANTE",
  "LAVAZZA",
  "Lavbo Sarah",
  "LAVENTE",
  "LBS",
  "LBS Group",
  "lca",
  "LCA CONTROLS",
  "ldeal",
  "LDK2-012",
  "LE",
  "LE CIMBALI",
  "LE CRENEAU INDUSTRIAL",
  "Le Grand",
  "LE Group",
  "LE SPEZIALE",
  "LEACH AIR",
  "Leader",
  "LEADER FIRE SYSTEMS",
  "Leakguard",
  "Leaksense",
  "Lean-lift",
  "Learand",
  "Leater Lifts",
  "LEAX",
  "LEAX LIGHTING",
  "LEB",
  "Lebara",
  "LEC",
  "LEC   NORPE",
  "LEC COMMERCIAL",
  "LEC essenChill",
  "LEC GRAM",
  "LEC MEDICAL",
  "LEC Technical",
  "LEC+",
  "LECHMETAL LANDSBERGBGMBH",
  "LECHMETALL",
  "Lechmetall Landsberg GmbH",
  "LECHMETL LANDSBERG GMBH",
  "LEC-MEDICAL",
  "Lecol",
  "LECTROGAURD",
  "LECTROGUARD",
  "LECU",
  "led",
  "led.ie",
  "Led.ir",
  "Ledbury",
  "Ledhill",
  "Lee",
  "LEE ELEC",
  "LEE HOWL",
  "LEEC",
  "Leeds Emergency Lighting",
  "Leeds Transformer Co",
  "LEEMORE LTD.",
  "Leengate valves",
  "Leerey Somer",
  "Leeson",
  "LEEWAY",
  "LeFlam",
  "Lefort Francheteau",
  "LEGEAND",
  "Legend",
  "Legg",
  "LEGG ( INDUSTRIES)",
  "Legge",
  "LEGNANO",
  "Legrand",
  "LEGRAND ELECTRICAL",
  "Lehner",
  "Leicester Switch Control",
  "Leifheit",
  "LEIGH",
  "Leigh Controls Ltd",
  "LEIGH TEC SOLUTIONS",
  "Leigh Tec Solutions Ltd",
  "LEIGH TECH SOLUTIONS",
  "LEIGHTEC SOLUTIONS LTD",
  "LEISTRITZ",
  "Leisure",
  "LEMAC",
  "LEMMER",
  "LEMMER ENGINEERING CO.LTD.",
  "lenel",
  "LENEMARK",
  "LENNOCK",
  "LENNOX",
  "Lennox - CKEANAIR",
  "LENNOX ELITE",
  "Lennox Industries Ltd",
  "LENNOX REFAC",
  "LENNOX REFRAC",
  "Lenovo",
  "Lenox",
  "LENZE",
  "LEONARD BAUER",
  "Leonardna",
  "LEONARDO",
  "LEONARDS LIFTS",
  "LEOROY SOMER",
  "LEP LTD",
  "LEROY - SOMER",
  "leroy somer",
  "leroy somner",
  "leroy somr",
  "Leroy SOMER",
  "Leroy-Somer",
  "LESTER",
  "LESTER CONTROL SYSTEMS LTD",
  "LESTER CONTROLS",
  "LESTER CONTROLS LTD",
  "LESTER CONTROLS LTD.",
  "LETOY SOMER",
  "Lev",
  "Levalift",
  "LEVANTÉ",
  "Level",
  "level 1access",
  "LEVEL ACCES LIFTS",
  "LEVEL ACCESS LIFTS",
  "LEVENTE",
  "LEVENTI",
  "LEVOLUX",
  "Lewden",
  "LEWIS-PLAST",
  "LEWKEN",
  "LEXICOM",
  "LEXICOMM",
  "Lexicon",
  "Lexington",
  "Leyrolle",
  "LG",
  'lg 32"',
  'lg 55"',
  "LG SWITCHGEAR",
  "LG.",
  "Lgh",
  "Lghc",
  "LHFX",
  "LHG Kanal Fläkt AB",
  "LHU",
  "LIBERATOR",
  "LIBERTY",
  "Liberty Industries",
  "LIBRA",
  "LIC SOUND",
  "LID",
  "LIDIARD & SKELTON",
  "lidle",
  "LIEBERT",
  "LIEBERT AC POER SYSTEM",
  "LIEBERT CORPORATION",
  "Liebert Datamate",
  "Liebert Hiross",
  "Liebert Hiross HPM",
  "LIEBERT HPM (EMERSON)",
  "LIEBERT NX       EMERSON NETWORK POWER",
  "Liebherr",
  "LIEHERR",
  "LIENERT",
  "LIFE",
  "LIFE CYCLE GENERIC",
  "Life Fitness",
  "Life Safety",
  "Lifeline",
  "LIFESAVER",
  "Lifescience",
  "LIFESTAR",
  "LIFESTYLE",
  "LifeTech",
  "LIFETECH SYSTEMS",
  "Lifetronics",
  "Liff",
  "LIFF INDUSTRIES",
  "Liff Wrappa",
  "LIFFE",
  "LIFFE ROTAMAG",
  "LIFT",
  "LIFT & ENGINEERING SERVICES",
  "LIFT EQUIP",
  "LIFT LTD",
  "LIFT MATE",
  "LIFT SOURCE",
  "LIFT SOURCE UK",
  "LIFT SOURCE UK LTD",
  "LIFT SPECIALIST LTD",
  "LIFT STORE",
  "Lift Table",
  "Liftec",
  "Liftec Ltd",
  "LIFTECH",
  "LIFTEKNIC",
  "LIFTEQUIP",
  "LIFTINC",
  "Lifting and Crane Ltd",
  "LIFTING GEAR HIRE LTD",
  "LIFTING TACKLE DIRECT",
  "LIFTKNIC",
  "Liftmaster",
  "LIFTON",
  "Liftserv",
  "Liftstyle",
  "Liftworks",
  "LIGHT",
  "LIGHT ALLOY LTD",
  "LIGHT ENGINE",
  "Light Spot",
  "Light Years",
  "LIGHTDUTY INDUCTION TECHNOLOGY EQUIPMENT (LITE)",
  "LIGHTECH",
  "LIGHTING  CONTROLS LTD",
  "Lighting Controls",
  "LIGHTMASTER MODULAR AREA CONTROLLER",
  "Lightning",
  "Lightrak",
  "Lightsafe",
  "Lightspot",
  "Ligman",
  "Ligo",
  "LIKE",
  "Liko",
  "LIKORAIL",
  "LIKORAIL 200",
  "LIKORAIL 242S",
  "LIKORALL",
  "lilco",
  "LILIN",
  "LILLESON ENGINEERING",
  "LIMPET FAN",
  "Limpsfield",
  "Linak",
  "LINCAT",
  "LINCATE",
  "LINCOLN",
  "Lincoln Green",
  "Lincoln Products",
  "LINCOLN SECURITY",
  "LINDA LEWIS",
  "Lindab",
  "LINDCAT",
  "Linde",
  "LINDHURST",
  "LINDLEY THOMP LTD.SON",
  "LINDLEY THOMPSON",
  "LINDLEY THOMPSON LTD",
  "LINDLEY THOMPSON LTD.",
  "Lindley Thomson",
  "LINDSEY",
  "Linear control systems Ltd",
  "linear plus",
  "linear plus systems",
  "LINER",
  "Liners control systems ltd",
  "LINES AND JONES",
  "Linford Security Division",
  "Link",
  "Link 51",
  "Link Controls",
  "LINK CONTROLS LTD.",
  "LINKS CONTROLS (GUNNEBO)",
  "LINKSTATION",
  "Linolite",
  "LINTON FUEL OILS",
  "Linton Services",
  "LINVAR LTD",
  "LION Contols Ltd",
  "LIQUID CONTROKS CORP",
  "LIQUID CONTROL CORP",
  "Liquid Leak Detection Systems",
  "Liquid metering services",
  "Liquidline",
  "LISS SECURITY LTD.",
  "Lister",
  "Lister-Petter",
  "LITE",
  "Lite Spot",
  "Liteplan",
  "Lite-Plan",
  "literon",
  "LITRO",
  "LITTLE GIANT",
  "LITTLE GIANT COMPANY",
  "LITTLE GIANT PUMP COMPANY",
  "Little Giant pumps",
  "LITTLE MULE",
  "litton",
  "Liverpool roller shutter door ltd",
  "LIWERA",
  "LK",
  "LK WLOVERHAMPTON",
  "LLGRET CONTROL SYSTEMS LTD",
  "LLORET",
  "LLORET CONTROL SYSTEM LTD",
  "LLORET CONTROL SYSTEMS",
  "LLORET CONTROL SYSTEMS LTD",
  "LLORET CONTROL SYSTEMS LTD.",
  "Lloret Controls",
  "lloret controls ltd",
  "Lloret controls system",
  "Lloyd Morris",
  "LLOYD MORRIS ELECTRICAL",
  "Lloyd somers",
  "Lloyd Worrali",
  "Lloyd Worrall",
  "LLOYDS",
  "LLOYDS BRITISH",
  "LLOYDS BRITSH SOMER",
  "Lloyds Register",
  "lloytron",
  "LM Electrical Services",
  "lma",
  "LMC",
  "LMEM",
  "LMF",
  "LMI  MILTON ROY",
  "LMI MILTON ROY",
  "LMI MILTON ROY.",
  "LMP Controls",
  "LMS",
  "LMS Controls",
  "LMX",
  "LN SPNZIAL",
  "lncat",
  "LNDIS & GYR",
  "LNSPNZIALE",
  "Load Centre",
  "loadcentre",
  "LOADING SYSTEMS",
  "Loadlimiter",
  "Loadlite",
  "loadstar",
  "LOADSTER",
  "loadswitch",
  "Loadtite",
  "LOARA",
  "LOBA INDUSTRIAL PRODUCTS LTD.",
  "LOC",
  "Local",
  "Locally fabricated",
  "Lochinvar",
  "LOCINOX",
  "LOCK",
  "LOCKART",
  "Locke and Soares",
  "LOCKED",
  "LOCKENHART",
  "Lockey",
  "Lockhart",
  "LOCKINVAR",
  "LOCKINVER",
  "Lockwood Manufacturing Company",
  "LOCOMOTOR",
  "LODIGE",
  "Lodise Industries Ltd",
  "Lodz",
  "LOFT LADDERS",
  "Loft Ladders ltd.",
  "Logic",
  "LOGIC & HONEYWELL",
  "LOGIC +",
  "Logic 4",
  "LOGIC 4NA",
  "Logic control",
  "LOGIC ENERGY",
  "LOGICON",
  "LOGIK",
  "Logiscan Fire and Security",
  "LOGIUDICE FORNI",
  "LOGIX",
  "Logstrup",
  "LOHEAT",
  "Lohenner",
  "LOHENNER GMBH",
  "Loher",
  "LOHER / THYSSEN-AUFZUGE",
  "LOHMEIER",
  "LOHMEIR",
  "Lomac",
  "Lomara",
  "LOMART LTD",
  "Lomona",
  "Londex",
  "LONDEX LTD.",
  "LONDON",
  "LONDON ELECTRICITY BOARD",
  "London Fan",
  "london fan company",
  "LONDON KINGSWAY",
  "LONDON PUMP",
  "LONDON PUMPS",
  "London Shop Fitters",
  "London Transformer Products",
  "London Transformer Products Ltd",
  "London Transformer Products, (L.T.P.)",
  "LONDON WALL",
  "LondonTransformer Products Ltd",
  "Long & Crawford",
  "LONG & CRAWFORD MANCHESTER",
  "LONG AND CRAWFORD",
  "LONG AND CRAWFORD MANCHESTER",
  "LONG AND CRAWFORD OF MANCHESTER",
  "Longcross",
  "LONNE",
  "Lonsdale Electric",
  "Loockhart",
  "LOOVENT",
  "Lorain",
  "LORAN",
  "Loren Cook",
  "Loren Cook Co",
  "LORET",
  "LORETS",
  "Lorient",
  "LORNE STEWART",
  "Lossway",
  "Lostock",
  "Lostock electrical projects co ltd",
  "LOSTOSCK",
  "LOTHION ELECTRIC",
  "LOTIENT",
  "LOTINGA",
  "LOTUS",
  "LOUISVILLE",
  "Loundale Electric",
  "Lounsdale",
  "LounsDale Electric",
  "LOUTH",
  "LOUTH TRANSFORMER CO. LTD",
  "LOUTH TRANSFORMER COMPANY",
  "LOVAIR",
  "Lovato",
  "LOVEAIR",
  "Lovibond",
  "Lowar",
  "Lowara",
  "Lowara 15",
  "Lowara Blue",
  "Lowara Ultra",
  "Loware",
  "LOWARZA",
  "Lowata",
  "Lowe",
  "LOWERA (ZILMET S.P.A)",
  "Lowland Doors",
  "lowland ensor",
  "LOWRI BECK",
  "LOWRY",
  "LOXIT LTD",
  "LOXWARA",
  "LPC",
  "LPC panel",
  "Lpc Switch Gear Ltd",
  "LPC SWITCHGEAR",
  "lpcb",
  "LPG",
  "LPG Tecnicas",
  "LPS",
  "LRDE",
  "LS",
  "LS MOTEURS LEROY FRANCE",
  "LSA ARNHAM",
  "LSC",
  "LSK",
  "LSM",
  "LSM ENGINEERING LTD",
  "LSM LTD",
  "LT",
  "LT LOUTH",
  "LT SWITCH GEAR",
  "LT SWITCHGEAR LTD",
  "LTE (GARDNERS)",
  "L-TEC CONTROLS",
  "LTECNIC",
  "LTG",
  "LTH",
  "LTH Electronics",
  "Lti",
  "LTI FANS LTD",
  "LTP",
  "LTR",
  "LTR LIFTS AND ESCALATORS",
  "LTR LIFTS LTD",
  "LTRON",
  "LTS VENTILATION SYSTEM",
  "LTS VENTILATION SYSTEMS",
  "LU VE",
  "Lubron",
  "LUCATRON",
  "Lucent",
  "Lucy",
  "LUCY & CO. LTD.",
  "LUCY LIGHTING",
  "LUCY OXFORD",
  "LUCY SWITCHGEAR",
  "LUCY ZODION",
  "LUHL",
  "luma",
  "LUMALIER",
  "LUNA",
  "LUNAR",
  "L'unique Hermatique",
  "L'Unire Hermatique",
  "L'UNIT HEMETIQUE",
  "LUNITE",
  "LUNITE HERMETIC",
  "L'UNITE HERMETIQE",
  "L'UNITE HERMETIQUE",
  "L'Unite Hermetque",
  "LUNITE HERMITIQUE",
  "Lupus",
  "LUTRON",
  "Luts-Jesc-GmbH",
  "Luvata",
  "luve",
  "LUV-E",
  "LU-VE    HITEC",
  "LU-VE  CONTARDO",
  "LU-VE (LIEBERT HIROSS)",
  "LU-VE CONTARDO",
  "LU-VE CONTARDO HITEC",
  "LU-VE HITEC",
  "LU-VE-CONTARDO",
  "LUWA",
  "LUX",
  "LUX TRAFFIC CONTROL LTD",
  "LUXAIR",
  "LUXAIR UK",
  "Luxmate",
  "Luxo",
  "Luxo UK",
  "LUXONIC",
  "Luxotronic",
  "Luxotronix",
  "LUZERN",
  "LVD",
  "LVD COMPANY",
  "LVE",
  "LW Lifting",
  "LW LIFTING AND FIXING SOLUTIONS",
  "LX ELEVATORS",
  "Lycetts",
  "Lynotech Engineering",
  "LYRICO",
  "LYRICO SYSTEMS",
  "LYRICO SYSTEMS LTD",
  "Lyte",
  "Lytlec",
  "LYTRON",
  "M",
  "M & M MEDICAL",
  "M & Y",
  "M & Y Air conditioning ltd",
  "M & Y VENTILATION EQUIPMENT LTD.",
  "M AND B TRANSFORMERS NOTTINGHAM",
  "M AND Y VENTILATION EQUIPMENT",
  "M C H ELECTRICAL SERVICES",
  "M FIRE ALARM SYSTEMS",
  "M MEIKO",
  "M Stoner",
  "M T A",
  "M TECS",
  "M&G",
  "M&H",
  "M&L MANUFACTURING",
  "M&M ACCESS LTD",
  "m&y",
  "M&Y Tempair",
  "M&Y VENTILATION EQUIPMENT LTD.",
  "M. GRUE",
  "M.C. Bignell Ltd.",
  "M.E.M ROCHE S.L CUARDOS ELECTRICOS",
  "M.T.A. S.p.A",
  "M.T.A.S.p.A",
  "M+G",
  "M+L MANUFACTURING LTD",
  "M000301",
  "M00049",
  "M00091",
  "M00114",
  "M00133",
  "M00135",
  "M00168",
  "M00181",
  "M00201",
  "M00203",
  "M00204",
  "M00298",
  "M00301",
  "M00312",
  "M00331",
  "M00332",
  "M00351",
  "M00389",
  "M00410",
  "M00442",
  "M00457",
  "M00499",
  "M00534",
  "M00541",
  "M00558",
  "M00562",
  "M00587",
  "M00588",
  "M00663",
  "M00674",
  "M00679",
  "M00723",
  "M00729",
  "M0082",
  "M100",
  "m100p",
  "M161",
  "M2",
  "M3E",
  "M3E AUTOMATION",
  "ma",
  "Maas",
  "Maas International",
  "MAC HELLAS",
  "Macadams",
  "MACALISTER",
  "MACANDA",
  "MACCHIA VALLEY",
  "MACCHIAVALLEY",
  "MACDEE",
  "MACDONALD ENGINEERS",
  "MACDONALD HUMFREY",
  "MACDONALD HUMFREY AITOMATION LTD.",
  "MACDONALD HUMFREY AUTOMATION",
  "MACDONALD HUMFREY AUTOMATION LTD",
  "MACDONALD HUMFREY AUTOMATION LTD.",
  "Mace air",
  "MACEMAIN & AMSTRAD LTD.",
  "MACEY",
  "MACEY CONTROLS",
  "MACEY CONTROLS LTD",
  "MACH",
  "MACH GMbH & Co",
  "Mach-Aire",
  "Machenair Ltd",
  "Machine",
  "Machine mart",
  "MACHINERY SCANDINAVIA",
  "MACIO",
  "Mackay Bowley Press",
  "MACKLIN",
  "Maclaren",
  "Maclin Limited",
  "MACLIN PUMPS",
  "MACNAUGHT",
  "MACQUAY/DAIKIN",
  "Macrco",
  "MACRON SAFETY",
  "macwhirter",
  "MACWOOD",
  "MAD DOG",
  "Madas",
  "maddalena",
  "MADICOB",
  "MADICOR",
  "MADIROL",
  "MADIX",
  "Madrix",
  "MAEDTRO",
  "maesrowave",
  "MAESTER ELECTRONICS",
  "maestro",
  "Maestro Clover",
  "MAESTRO INT",
  "Maestro International",
  "MAESTRO WATER COOLERS",
  "MAESTRO WAVE",
  "Maestrowwawe",
  "MAFIROL",
  "mag",
  "MAGI MIX",
  "Magic Aire",
  "MAGIC HAND",
  "MAGISTER",
  "Magliner",
  "MAGLOCK",
  "Magna",
  "Magnaclean",
  "MAGNAFLUX",
  "magnamax dvr",
  "Magnatech",
  "MAGNEHELIC",
  "MAGNET",
  "Magnetek",
  "Magnetic",
  "MAGNETIC AUTO CONTROL",
  "Magnetic Autocontrol",
  "MAGNOL",
  "MAGNUM",
  "MAGNUM EQUIPMENT",
  "MAGNUM GS",
  "Magnus Power",
  "Magpie BMS Services",
  "Magpie BMS Systems",
  "MAGRA",
  "MAHLE GMBH",
  "Maico",
  "Maid 2 wash",
  "Maid 2 washi",
  "MAID AID HALCYON",
  "MAIDAID",
  "MAIDAID HALCYON",
  "MAIDAIID",
  "maigen",
  "MAIKO",
  "MAIM SSYEMS",
  "MAIM SYSTEMS",
  "Main",
  "MAIN INCOMING BUS BAR",
  "Main line",
  "main multipoint",
  "MAIN ROSE",
  "Main Switch Gear",
  "MAIN SYSTEM",
  "MAIN SYSTEMS",
  "MAIN SYSTEMS LTD.",
  "MAIN SYSYEMS",
  "Mainline",
  "MAINROSE",
  "Mains",
  "Mains Pro",
  "MAINS SYSTEM",
  "mainsheild",
  "MAINTECK",
  "Maintenance Ltd",
  "Maintose",
  "MAINWAY",
  "MAION",
  "MAITEC",
  "MAITOWAC",
  "Majco",
  "MAJESTIC",
  "MAJESTIC TRANSFORMERS",
  "MAKE A WISH",
  "MAKITA",
  "Mala",
  "Malabar",
  "Malagari",
  "Malbar",
  "MALFIROL",
  "mallard",
  "MALLARD AIR SYSTEMS",
  "Mallard Euro Air",
  "MALLARD EUROAIR",
  "MALLARD EUROAIR AHU",
  "MALLARD EURO AIR",
  "Mallsrd",
  "mam",
  "MAMMOTH",
  "MAMOLI",
  "MAN Heiztechnik",
  "MAN ROSE",
  "Man test 1",
  "Man test cl",
  "Manaurco",
  "MANCHESTER CALORIFIER LTD",
  "Manchester Calorifiers",
  "Manchester Calorifiers Ltd",
  "MANCHESTER CALORIFIERS LTD",
  "MANCHESTER CALORIFIERS LTD.",
  "MANCHESTER MAINS",
  "MANCHESTER TINNING CO",
  "MANCO",
  "Mandausch",
  "Mandora industrial doors",
  "MANEI LIFT",
  "manessman demag",
  "MANEUROP",
  "Manfose",
  "Manitou",
  "MANITOWAC",
  "MANITOWER",
  "MANITOWOC",
  "MANITOWOC BEVERAGE SYSTEMS",
  "Manitowoc Cooler",
  "Manitowoc Freezer",
  "MANN CONTROL SYSTEM",
  "MANN CONTROL SYSTEMS",
  "MANNERLY",
  "mannesman",
  "mannesmann",
  "MANNESMANN DEMAG",
  "mannesmann germany",
  "mannesmann germay",
  "MANNHARDT",
  "MANNTECH",
  "MANROAE",
  "Manros",
  "MANROSE",
  "Mansafe",
  "MANSAFE CABLE SYSTEM",
  "MANSFIELD POLLARD",
  "mantest001",
  "mantest002",
  "MANTIS",
  "MANTITOWOC",
  "MANTIWOC",
  "mantose",
  "MANTOWOC",
  "Manuafacturuer",
  "MANUAL",
  "MANUFACT",
  "Manufacturer",
  "MANUTOWOC",
  "Manvier",
  "Manvier Amberlec",
  "Manweb",
  "MANWEB CONTRACTING",
  "MANWEB CUSTOM BUILT",
  "MAP",
  "MAPLIN AIR CONDITIONING",
  "MAPLIN MECHANICAL SERVICES LTD",
  "MAPuk",
  "Marantz",
  "Marathon",
  "MARATHON ELECTRIC",
  "MARCH",
  "March May",
  "Marco",
  "MARCO BEVERAGE LTD",
  "MARCO BEVERAGE SYSTEMS",
  "MARCO BEVERAGE SYSTEMS LTD",
  "MARCO BEVERAGES",
  "MARCOE ELECTRICAL LTD.",
  "MARCUS",
  "MARDEX",
  "MARDIX / FURSE",
  "MARDIX AUTOMATIC CONTROLS LTD.",
  "MARDIX SWITCHGEAR",
  "MARDIX SWITCHGEAR LTD",
  "MARDON",
  "Marelli",
  "MARELLI E.",
  "MARELLI GENERATORS",
  "MARELLIMOTOR",
  "MarelliMotori",
  "MARENO",
  "MARGAN MARINE",
  "Margaux",
  "marian",
  "Marioff",
  "MARION",
  "Marion doors",
  "marisa",
  "MARISTAR",
  "Mark",
  "MARK THOMPSON",
  "Markon",
  "MARKS SHUTTERS",
  "Marlanvi",
  "MARLANVIL",
  "MARLEY",
  "MARLOWE",
  "MARO",
  "MARPOL",
  "MARRAL",
  "MARREN",
  "MARREN MICROWAVE LTD",
  "Marrioff",
  "Marrix Control Solutions",
  "MARRIX CONTROL SOLUTIONS LTD",
  "MARRUTT",
  "Marryat & Scott Ltd",
  "MARRYATS",
  "Mars",
  "Mars Sales",
  "MARSDEN",
  "MARSDON",
  "MARSH MCBIRNEY",
  "MARSH SYSTEMS LTD.",
  "MARSHALL",
  "Marstair",
  "MARSTAIR LTD",
  "Marstairs",
  "MARSTAR",
  "Marston & Co",
  "MARSUI",
  "MARTEL INSTRUMENTS",
  "MARTIN",
  "Martin Ogee",
  "Martin Orgee",
  "MARTIN YALE INTERNATIONAL",
  "Martinair",
  "Martindale",
  "martinyale",
  "MARTIX",
  "MARUKOME",
  "MAS Medical Ltd",
  "Mas security solutions",
  "Masco",
  "MASCOT",
  "MASKOLD",
  "MASON",
  "MASON COPPERAD LTD",
  "MASSEY COGGINS",
  "MASSEY COLBECK",
  "Massey Coldbeck",
  "Mastair",
  "Master",
  "Master Air",
  "Master Aire",
  "MASTER CELLA",
  "MASTER CHEF",
  "MASTER FROST",
  "MASTER JET",
  "MASTER MC",
  "Master Mover",
  "Masterair",
  "MASTERCELLA",
  "MasterChef",
  "Mastercool",
  "MasterFire",
  "MasterMover",
  "MASTERPACT",
  "MASTERVOLT",
  "Masterwash",
  "MASTICNA",
  "MASTNENA",
  "MASTRANA",
  "MASTRENA",
  "Mastroma",
  "Mastrowave",
  "MASTSIR",
  "Masutyo",
  "MAT",
  "Mat Fire",
  "MAT FIRE & SECURITY",
  "MAT FIRE SECURITY",
  "Matag",
  "MATALEX",
  "MATATHON",
  "Matco",
  "Mateer and Nelson",
  "MATFER",
  "Mather & Platt",
  "MATHER & PLATT LTD",
  "MATHER & PLATT LTD.",
  "Mather & Platt",
  "Mathew Hall",
  "Mathews & Yates",
  "Mathews &Tates Ltd.",
  "MATHEWS AND HALL",
  "MATHEWS AND YATES",
  "Mathews Electrical Works",
  "MATLOCK",
  "MATRICOLA",
  "Matrix",
  "Matrix 1 (PIDS)",
  "Matrix Contril Solutions Limited.",
  "Matrix Control",
  "Matrix Control Ltd",
  "Matrix Control Solution Ltd",
  "Matrix Control Solutions",
  "Matrix Control Solutions Kimired.",
  "Matrix Control Solutions Kimured.",
  "Matrix Control Solutions Limired.",
  "Matrix Control Solutions Limited",
  "Matrix Control Solutions Limited.",
  "Matrix Control Solutions Limuted.",
  "MATRIX CONTROL SOLUTIONS LTD",
  "Matrix Control Solutions.",
  "Matrix Controll Solutions Limited.",
  "Matrix Controls",
  "MATRIX CONTROLS SOLUTIONS",
  "MATRIX METERING",
  "Matrix Cont Solutions",
  "Matsui",
  "MatsuiPec",
  "MATTEI",
  "Matter & Platt",
  "MATTERSON",
  "MATTERSON LTD",
  "MATTERSON ROCHDALE",
  "Matthew",
  "MATTHEW AGIE",
  "MATTHEW ALGIE",
  "Matthew Hall",
  "MATTHEWS & YATES LTD",
  "MATTHEWS & YEATS",
  "MATTHEWS AND YATES",
  "Matthews&Yate",
  "Mattino",
  "mattri",
  "MAVICO",
  "Maviflex",
  "MAVIG",
  "mawdsley power conditioning",
  "MaX",
  "MAX GROUP",
  "MAX HOLD",
  "Max Weishaupt Gmbh",
  "Max wright",
  "Max Wright Ltd",
  "Max Wright Ltd ?",
  "MAXAURA LTD.",
  "MAXCOL PISTON TECHNOLOGY",
  "maxcold",
  "MAXGENE",
  "MAXI FILLER",
  "MAXIFILLER",
  "MAXIFLOW",
  "MAXIGENE",
  "MAXIIFILTER",
  "MAXIKOLD",
  "Maxim",
  "MAXIN",
  "MAXITROL",
  "Maxivare US",
  "MAXKOLD",
  "MAXPAC",
  "MAXPAX",
  "Max-Pax",
  "MAXTAG",
  "MAXVIEW",
  "MAY AND CHRISTIE",
  "Mayor Entrance Control",
  "MAYOR ENTRANCE CONTROL SOLUTIONS",
  "MAYOR TURNSTILES",
  "MAYSUI",
  "Maytag",
  "maytag commercial",
  "MAZER LUIGI",
  "Mazza Luigi",
  "MAZZA LUIGI, EXPRESSO ITALIANO",
  "MAZZER",
  "MAZZER LUIGI",
  "MAZZER LUIGI SH",
  "MB",
  "mb phoenix fans",
  "MBB Gelma",
  "MBE",
  "MBE Automation",
  "MBH Pumps",
  "MBK",
  "MBM",
  "MC",
  "MC 2",
  "Mc Bignall",
  "Mc Bignall Ltd",
  "MC CALLUM CALORIFIERS LTD",
  "MC FILTRATION LTD.",
  "Mc JB",
  "MC QUAY",
  "MC500",
  "MCALLUM",
  "McCall",
  "McCallum",
  "McCALLUM & TID LTD.",
  "McCALLUM & TOD LTD.",
  "mccallum calorifiers",
  "McCALLUM MUIR & CO LTD",
  "MCCB",
  "mccomb",
  "McComb MacPherson & Co",
  "McCombe MacPhearson",
  "McCombie MacPherson & Co.",
  "McCombie-MacPherson & Co",
  "McCool Controls",
  "MCD",
  "McDonald",
  "McDonald Engineering",
  "McDonald Engineers",
  "McDonnell",
  "McDonnell / Brooklyn",
  "McDougall Systems",
  "MCFALANE",
  "MCFARLANE",
  "mcg",
  "MCG SWITCHGEAR",
  "MCH",
  "MCH ELECTRCAL SYSTEMS LTD",
  "MCH ELECTRICAL SUPPLIES",
  "MCH ELECTRICAL SYSTEMS",
  "mchline",
  "MCIB",
  "McKenzie & Holland Ltd",
  "McKenzie and Holland",
  "McKibbin Engineering",
  "McKibbon Engineering",
  "Mclean",
  "McNaught",
  "MCNEALY BROWN LTD.",
  "MCP",
  "McQUARY AIR CONDITIONING",
  "McQUAY AIR CONDITIONING",
  "McQuay Easdale",
  "MCQUAY LTD",
  "MCR ENGINEERING",
  "MCS",
  "MCS 120 Hydraulic",
  "MCWHIRTER LTD",
  "MD",
  "MDA",
  "MDA40",
  "MDANFOS",
  "MDH",
  "ME",
  "Mea Exel",
  "meastrowave",
  "MEASUREMENT TECHNOLGY",
  "Measurement technology",
  "MEB",
  "MEB Timer",
  "MEC",
  "Meca Plastics",
  "MECANAIDS",
  "MECC ALTE",
  "Mecc alte spa",
  "Meccalte Spa",
  "MECCANICO",
  "MECE",
  "Mech Doors",
  "Mech Power",
  "Mechan",
  "MECHAN LTD.",
  "Mechan Technology",
  "Mechan transportation",
  "MECHANICAL & ELECTRICAL CONTRACTORS",
  "MECHANICAL SERVICES  LTD",
  "Mechdoors",
  "MECHLIN",
  "MECHLINE",
  "Mech-Power",
  "MECKENBEUREN",
  "MEDADOOR",
  "Medaes",
  "MEDAES",
  "Medding",
  "Meddings",
  "meddings bench grinder",
  "meddings machine tools",
  "meddings pillar drill",
  "Mede,",
  "Medem",
  "Medem uk Ltd",
  "MEDI POINT",
  "MEDIAGON",
  "MEDICAL & INDUSTRIAL MANUFACTURING CO.LTD",
  "MEDICAL AIR TECHNOLOGY",
  "MEDICAL AUR EMPOWER CONTROL SYSTEM",
  "Medical Gas",
  "MEDICAL GAS ALARM",
  "MEDICAL GASES",
  "MEDICAL GASES LTD.",
  "Medical LEC",
  "medical pipeline services ltd",
  "MEDICHEM INTERNATIONAL",
  "Mediclinic",
  "Mediclinics",
  "MEDIFLOW",
  "MEDIPLAN",
  "MEDITECH",
  "MEDITEK",
  "MEDI-TELL",
  "Meditronic",
  "Medlings",
  "Medtronic",
  "MEDWAY",
  "Meerychef",
  "MEGA",
  "Mega flow",
  "Mega Lab",
  "MEGA MISER",
  "MEGA TANK ,SUNDERLAND",
  "MEGABARRE GROUP",
  "MEGACON",
  "MEGADOOR",
  "MEGADOR",
  "MEGAFLO",
  "Megaflo Unvented",
  "MEGAFLO\r\nW",
  "megaflow",
  "Megaflow Heatrae",
  "Megalarm",
  "MEGALOW",
  "MEGAMISER?",
  "Megator",
  "MEIJO",
  "Meilos",
  "MEINECKE",
  "Mel - Tec Ltd.",
  "Melclorite",
  "MELE",
  "Melitta",
  "MEM",
  "MEM (DELTA ELECTRICAL SYSTEMS LTD.)",
  "MEM / SANGAMO",
  "MEM AUTOLINE",
  "MEM Eato",
  "MEM EXEL",
  "Mem MEMERA 200",
  "MEM MEMFORM Delta",
  "MEM MEMORA",
  "Mem memsheild 2",
  "MEM MEMSHEILD 200",
  "MEM MEMSHEILD 3",
  "MEM MEMSHEILDV2",
  "MEM Memshield2-125",
  "MEM MEMWAY",
  "MEM200",
  "MEM630",
  "MEMBER WATER QUALITY",
  "MEMBERS",
  "MEMCO",
  "Memeaton",
  "memera",
  "MEMFORM DeltA",
  "MEMLOK",
  "MEMMERT",
  "MEMOREX",
  "MEMPOWER",
  "memrex",
  "memrex senior",
  "MEMS POWER GENERATION",
  "MEMSHIELD 2",
  "MEMSHIELD 3",
  "Memslield 3 Eaton",
  "MEMVIER",
  "MENCIER",
  "MENERGA",
  "MENNEKES",
  "MENNKES",
  "MENOM",
  "MENONID",
  "MENTIS",
  "MENTISE",
  "Mentle",
  "Mentus",
  "MENU",
  "Menu master",
  "Menu master Commercial",
  "MENU,ASTER",
  "Menumastee",
  "Menumastet",
  "Menumastwr",
  "Menviea",
  "Menvier",
  "MENVIER AMBERLEC",
  "MENVIER AMBERLEY",
  "menvier/tamlight",
  "menvoer",
  "MENVVIER",
  "Menyler Amberlec",
  "Mer",
  "Mercedes",
  "MERCER",
  "Merchanaids",
  "MERCIA DOORS",
  "MERCIA OVERHEAD CRANE",
  "MERCIAN",
  "Mercian Electric",
  "Mercian Electrical co.",
  "Mercian Fluid Handling",
  "Mercury",
  "Mercury Alarm Services Ltd",
  "MERCURY CLIMATE LTD.",
  "Mercury Climatic",
  "Mercury Communications",
  "MERCURY INSTRUMENTS",
  "MERENO",
  "merering solutions",
  "MERICIAN INDUSTRIAL DOORS",
  "Meridan Doors",
  "MERIDEN",
  "Meridian",
  "Meridian Doors",
  "MERIDIAN LIFTS",
  "MERIDIAN TECHNICAL SERVICES / KENTEC ELECTRONICS",
  "Merlen",
  "Merley",
  "MERLIN",
  "MERLIN C GAS SAFETY SYSTEM",
  "MERLIN CONTROL SYSTEMS",
  "Merlin control systems ltd",
  "MERLIN FERIN",
  "MERLIN GAS SAFETY SYSTEM",
  "MERLIN GERIN",
  "MERLIN GERIN (STSSWITCHGEAR)",
  "Merlin Gerin (UK) Ltd   (UPS only)",
  "Merlin GERIN / Schneider Electric",
  "MERLIN GERIN MASTERP",
  "MERLIN GERIN POWERPACT 4",
  "MERLIN GERIN",
  "merln",
  "Merloin Gerwin",
  "MERMAID",
  "Mermotori",
  "Merridale",
  "MERRY CHEF",
  "MERRY CHEF LTD",
  "Merrycher",
  "mersey",
  "Mertech",
  "Mertilus",
  "mertis",
  "MERTIX",
  "MES",
  "MESSER",
  "META",
  "Meta Italy",
  "META LIFTS",
  "METABO",
  "METACRAFT",
  "METACRAFT TANKS",
  "METAFLEX",
  "METAIR",
  "METAIR LONDON LTD",
  "METAIR LTD",
  "metal",
  "METAL DESIGN SERVICES",
  "METALFORM - BREST",
  "Metalic",
  "Metalock",
  "METALOTERM",
  "METAXDOOR",
  "METCAFFE",
  "METCAFT TANKS",
  "Metcal",
  "METCALF",
  "METCALFE",
  "Metcalfe equipment",
  "METCRAFT",
  "Metcraft Tanks",
  "METCRAFT TANKS.",
  "Metedor",
  "METEK",
  "Metel Ducting",
  "METELINE",
  "METEO",
  "METER REMOVED",
  "Meter Solutions",
  "Meter UK",
  "Meter UK Ltd",
  "Metering Solutions",
  "Metering Solutions - Northern design",
  "meterng soluions",
  "METERS IK",
  "METERS LTD",
  "Meters UK",
  "METERS UK LTD",
  "Metfair",
  "metlin gerin",
  "METM",
  "METN",
  "METOREX SECURITY PRODUCTS",
  "METOS",
  "Metpro",
  "METRE",
  "METREEL",
  "METRETEK",
  "METRIC",
  "METRICO SYSTEMS LTD",
  "Metrix",
  "Metro",
  "metro lifts ltd",
  "METRO LINE",
  "Metro-Flex",
  "METRO-FLEX/GAROG",
  "Metrohm",
  "Metroline",
  "Metron",
  "METRON ELEDYNE",
  "Metron Eledyne Limited",
  "METTLER TOLEDO",
  "Metway",
  "MEWLEC",
  "MEXICO",
  "MEYER",
  "MEYERTEC",
  "MEYERTECH",
  "MEYNEL",
  "Meynell",
  "Meyor tech",
  "MEZ",
  "MEZZAR",
  "MF",
  "MF REFRIGERATION",
  "MF REFRIGERATION / SHEPHERD NEAME",
  "MF REFRIGERATION LTD",
  "MF REFRIGERATION LTD.",
  "mfs",
  "MFZOVITOR",
  "MG",
  "MG COMPONENTS S.R.O",
  "MGA",
  "MGE ELECTRIC",
  "MGE UPS Services Inc",
  "MGE UPS System",
  "MGE UPS SYSTEMS LTD",
  "MGF",
  "MGI",
  "MgX",
  "MHA",
  "MHG",
  "MHS",
  "MHS ALDERSHOT",
  "MHS Boiler",
  "MHS BOILERS LTD",
  "MHS BOILERS LTD.",
  "mia",
  "MICAFIL",
  "Micanan",
  "MICFAIL",
  "Michael Smith",
  "MICHAEL SMITH SWITCH",
  "Michael Smith Switchboard",
  "MICHAEL SMITH SWITCHGEAR",
  "MICHAEL SMOTH SWITCHGEAR",
  "michelin",
  "mickrmark",
  "Mico Tindall",
  "Micoair",
  "MICOM",
  "MICRIFILL",
  "MICRO",
  "Micro Gill",
  "MICRO LIFT",
  "Micro Mark",
  "MICRO PAK",
  "MICRO REPORTER",
  "MICRO S",
  "MICRO SEP",
  "Microban",
  "MICROBANK",
  "MICROFIL",
  "MICROFILL SYSTEMS",
  "Microfill Systems Ltd.",
  "MICROFIR",
  "MICROFLOW",
  "MICROLIFF",
  "Microlift",
  "MICROLIGHT",
  "microlocks",
  "MICRO-LOGIC",
  "MICRO-LOK",
  "Micromare",
  "Micromark",
  "MICROMAT",
  "MICRONARK",
  "micronet",
  "MICRONICS ENGINEERED FILTRATION",
  "MICRONICS LTD",
  "MICROPACJ PRESSURISTION UNIT",
  "Micropress",
  "microquartz sigma",
  "Micros",
  "Microsave",
  "Microsmart",
  "MICROSTAR",
  "Microtech",
  "MICROTECH SECURITY",
  "MICRTECH SECURITY",
  "MICS Jely",
  "MICVAC VACUUM TECHNOLOGY",
  "MID COMMS LTD",
  "Mid con",
  "Mid Con System",
  "MID CON SYSTEMS",
  "Mid Con Systems Ltd",
  "MID CON SYSTEMS LTD.",
  "MIDAC",
  "MIDAC BATTERIES",
  "MIDAS",
  "MIDBRAS",
  "Mid-Con System LTD",
  "Mid-Con Systems Ltd",
  "MIDEA",
  "MIDEL",
  "Midi-Break",
  "MIDILIFT SL",
  "Midland Door Services",
  "MIDLAND PANELS LTD",
  "Midland Power & Motion",
  "MIDLANDS PANELS LTD",
  "MIDLANDS TRANSFORMER",
  "Midsaw",
  "Midtronics",
  "MIDVALE",
  "Midway",
  "MIDWAY INDISTRIAL DOORS",
  "MIEKO",
  "Miele",
  "Miele Profession",
  "MIERZSCH",
  "MIETZSCH",
  "MIG",
  "Migatronic",
  "MIGHTY TRAP",
  "MIGTRONIC",
  "Miko",
  "MIKRIFILL",
  "MIKRO",
  "MIKROFIL/REFLEX",
  "Mikrofill",
  "MIKROFILL (WRAS)",
  "MIKROFILL EFD",
  "MIKROFILL SYSTEMS LTD",
  "MIKROFLOW",
  "MIKROPOT",
  "MIL",
  "MIL TEK",
  "MILAN TOAST",
  "MILAN TOASTER",
  "MILANO",
  "MILANTOAST",
  "Mile High Equip",
  "MILLBANK",
  "Millenium Lighting",
  "Millenium Lights",
  "MILLENIUM MEDICAL PRODUCTS LIMITED",
  "MILLENNIUM MEDICAL PRODUCTS LTD.",
  "MILLENS ELECTRICAL CO. LTD.",
  "MILLER",
  "MILLER REFRIGERATION",
  "MILLINS",
  "MILLIPORE",
  "MILLITRONICS",
  "MILLNS",
  "MILLNS (RED SPOT FUSE CARRIER)",
  "MILLNS (USING RED SPOT AS FUSE CARRIER)",
  "Millns Electrical",
  "MILLNS ELECTRICAL LTD",
  "MILLNS ELECTRICAL LTD.",
  "MILLNS ELECTRICAL LTD. (RED SPOT FUSE CARRIER)",
  "MILLS ELECTRICAL LTD",
  "Milltronics",
  "MILMORE",
  "MILNER",
  "MILNER SAFE & Co. LTD.",
  "Milngavie",
  "MILNGAVIE CONTROLS",
  "Milngavie Controls ?",
  "MILNGAVIE CONTROLS LTD",
  "MIL'S",
  "Mil-tec",
  "MIL-TEK",
  "MILTON",
  "Milton Roy",
  "Miltronik",
  "MIM",
  "MIM MEDICAL",
  "MIMEDICAL",
  "Mimic Control Panel",
  "MIMMEDICAL",
  "MIMMEDICAL MEDTECH",
  "Minden",
  "Minder",
  "Minerva",
  "MINERVA (ADT)",
  "MINERVA 4 THORN SECURITY",
  "MINERVA 8",
  "Minerva Thorn",
  "MINERVIA",
  "mini pack",
  "MINIELCOR",
  "MINIFORM",
  "MINIFORMN- GEC INSTALLATION EQUIPMENT LTD",
  "MINIMAX",
  "minipack",
  "MINIROLLER",
  "Minivator",
  "Mink",
  "Minolta",
  "minrva",
  "Mintor ?",
  "MiP",
  "Mira",
  "Mira Exel",
  "MIRAGE",
  "MIRASYS",
  "Mirra",
  "MIRRLEES BLACKSTONE",
  "MIRROR",
  "mirsubishi",
  "mis",
  "MISA",
  "MISCILANIOUS",
  "MISSING",
  "MISSION CONTROL LTD",
  "Mistral",
  "MISTRAL ENERGY PRODUCTS LIMITED",
  "MITDUBISHI ELECTRIC",
  "mith",
  "Mitibishi",
  "MITIE (T F TULL)",
  "MITIE ENGINEERING",
  "MITRE FINCH",
  "MITREFINCH",
  "MITSU",
  "MITSUBISHI",
  "Mitsubishi daiva",
  "Mitsubishi davia",
  "MITSUBISHI ELECTRIC",
  "Mitsubishi Electric CIRY MULTI",
  "MITSUBISHI ELECTRIC CORPORATION",
  "Mitsubishi Electric G INVERTER",
  "Mitsubishi Electric GOT 1000",
  "Mitsubishi Electric K-INVERTER",
  "MITSUBISHI HEAVY",
  "MITSUBISHI HEAVY INDUSTRIES LTD",
  "Mitsubishi SECURE",
  "Mitsui",
  "MITSUTA",
  "MITTON",
  "MIXED EQUIPMENT",
  "Mixeture",
  "Mixture",
  "MJ BUILDING",
  "MJ BUILDING SEVICES",
  "MJ Visual",
  "MJHNHBN",
  "MJL ?",
  "MJN",
  "MK",
  "MK / THEBEN",
  "MK Fire",
  "MK Refrigeration",
  "MK/Hagar",
  "MKHAGER",
  "MKN",
  "MLH HOLDINGS LTD",
  "Mller Refrigeration",
  "MLS",
  "MM",
  "MM MEDICAL LTD.",
  "MMB",
  "MMPI SPECIAL METALS LTD.",
  "MMPL",
  "MMPL UK",
  "MMS",
  "MMUREX",
  "MN Tech",
  "MNR",
  "MNTECH",
  "MOBERY",
  "MOBIFLEX GILLS AND CLOSURES",
  "mobile",
  "Mobile Catering",
  "MOBILE CONTAINING",
  "MOBILE UK LTD",
  "Mobility company",
  "MOBREY",
  "MOBREY SENSALL",
  "MODE LIGHTING",
  "MODE LIGHTING (UK)",
  "Mode Lighting (UK) Ltd",
  "Modele de Contenence",
  "MODENA",
  "Modern",
  "Modern Alarm",
  "Modern Alarms",
  "MODERN DRIVES & CONT",
  "Modern Security Systems",
  "MODINE",
  "MODUCEL",
  "MODUCEL AIR CONDITIONING EQUIPMENT.",
  "MODUCEL AIR HANDLING UNIT",
  "Modula",
  "Modulair",
  "MODULAR",
  "MODULAR HEATING",
  "MODULAR HEATING SALES LTD.",
  "MODULAR HEATING SERVICES",
  "MODULAR HEATING SYSTEMS",
  "Moduline",
  "modunow",
  "MODUS",
  "Modus Fluid Technology",
  "Moeller",
  "Moeschle",
  "Moffat",
  "MOFFAT LTD",
  "MOFFAT LTD.",
  "MOFFATS",
  "MOFFATT",
  "MOFFATT BLUE SEAL",
  "MOFFET",
  "MOLE",
  "MOLIFT",
  "Molitor",
  "Molitron",
  "Moll",
  "Moll Motor",
  "Moller",
  "MONACOR",
  "MONARCH",
  "Monarch Lifts",
  "MONARCH WATER",
  "MONATRIX",
  "moncell",
  "MONDIAL",
  "MONDIAL ELITE",
  "MONDIAL GROUP",
  "MONDO",
  "MONE EQUIPMENT",
  "MONI",
  "MONICON TECHNOLOGY",
  "MONITOR  NOVO   RECORDER UNKNOWN",
  "MONITORING SOLUTIONS",
  "MONMOUTH",
  "Mono",
  "MONO BX ECOTOUCH",
  "MONO BX ECO-TOUCH",
  "MONO DRAGHT",
  "MONO EQUIPMENT",
  "MONO Equipment Ltd",
  "MONO PUMPS",
  "MONO PUMPS LTD",
  "MONO PUMPS LTD.",
  "MONODRAFT",
  "Monodraugh",
  "MONODRAUGHT",
  "MONODRAUGHT LTD",
  "Monoi",
  "MONOLITE",
  "MONOTRON",
  "MONROE",
  "Monsoon",
  "MONSOON UNIVERSAL",
  "Montagaha",
  "montage",
  "Montague",
  "Montair",
  "MONTANARI GIULIO",
  "MONTCEAU",
  "MONTGOMERY REFRIGERATION LTD",
  "MONTPELLIER",
  "Montrose",
  "MOOD MEDIA",
  "MOOG",
  "Moorwood",
  "MOORWOOD Vulcan",
  "MOOS ELEC. & MAC. ENG OED.",
  "MOOVMOR",
  "Morain Cosriv",
  "Morco",
  "MORECLEAN",
  "Morely Fire Systems",
  "MORENO",
  "Morewood Vulcan",
  "MORFITTS",
  "MORGAN AND GRUNDY LTD",
  "MORGAN MARINE",
  "Morgon Rushworth",
  "MORICE",
  "Morin",
  "MORISPAIN",
  "MORITE WINDING CO LTD",
  "MORITE WINDING CO. LTD.",
  "Morley",
  "Morley AIS -Honeywell",
  "Morley AIS-Honeywell",
  "Morley by Honeywell",
  "Morley Fire Systems",
  "MORLEY IAS",
  "MORLEY IAS BY HONEYWELL",
  "MORLEY IAS FIRE SYSTEM",
  "MORLEY IAS FIRE SYSTEMS",
  "MORLEY IAS HOEYWELL",
  "Morley IAS Systems",
  "MORLEY IAS-Honeywell",
  "MORLEY ISS",
  "Morley IAS",
  "MORLEY. IAS",
  "Morley/Honewell",
  "MORLEY/IAS",
  "MORLEY-HONEYWELL",
  "MORLEY-IAS",
  "MORLEY-IAS FIRE SYSTEMS",
  "MORPHT RICHARDS",
  "MORPHY RICHARDS",
  "Morphy Richards",
  "MORRELLIMOTORI",
  "Morris",
  "MORRIS 1 TON",
  "MORRIS 1 TONNE",
  "MORRIS AIIP INDUSTRIAL SERVICES",
  "MORRIS VERMAPORT",
  "Morris VERMAPORT Ltd",
  "MORRISFLEX",
  "MORRISON/VOLVO",
  "MORRISOND",
  "MORRISONS",
  "Morrrisons",
  "MORSO",
  "Mosaic",
  "Moss Elec & Mec Eng Ltd",
  "MOSS SECURITY",
  "Mot",
  "Motala",
  "MOTALA HISSAR",
  "MOTANARI",
  "MOTEURS LEROY SOMER",
  "MOTEURS LEROY-SOMER",
  "motiair",
  "Motion Systems Ltd",
  "MOTIVAIR",
  "Motivair Compressors",
  "MOTIVAIR INTERNATIONAL",
  "MOTIVAR",
  "Motivation",
  "MOTIVEAIR",
  "MOTKVAIR",
  "MOTOR ASYNCRONO",
  "MOTOR IS ENERGY TECHNOLOGY LIST",
  "MOTOR NOT IN SITUE",
  "Motorgear",
  "MOTORISED AIR PRODUCTS (BEVENT & RASCH)",
  "MOTORISED AIR PRUDUCTS",
  "Motorised Tug",
  "Motorola",
  "Mototok",
  "motovario",
  "MOTSUBISHI",
  "MOULINEX",
  "MOUNTAIN BREEZE",
  "moveman",
  "Moveman SKG",
  "MOVEMAN SKG  /        NAMIHISSEN 400 SEE No 5063",
  "Movement",
  "Movement Management",
  "MOVENMAN SKG",
  "MOVENPICK",
  "MOVETECH UK",
  "MOVIN COOL",
  "MOVINCOOL",
  "MOVING",
  "MOVING COOL",
  "Movitec",
  "MOVUM",
  "Mowlem",
  "MOYNE ROBERTS",
  "MP",
  "MP Electronic Fire & Security Ltd",
  "MP LIFTS",
  "MPA NRW",
  "MPBW",
  "MPE",
  "MPR",
  "M-PRO",
  "MPS",
  "MPU",
  "MR",
  "MR DOSAGE",
  "MR FLOW METER HEDLAND",
  "MR SLIM",
  "MR Slim PUHZ - RP1 25Y HAZ",
  "MRC",
  "MRM",
  "MRP-2001",
  "MS",
  "MS Wanden",
  "MSA",
  "MSF",
  "MSH BOILERS",
  "MSI GROUP SOUTH",
  "MSL Fire Protection",
  "MSP PLANET PUMPS",
  "msystems",
  "MTA",
  "MTA SRL",
  "MTA SPA",
  "MTC",
  "MTDS",
  "MTE",
  "MTI",
  "MTO",
  "MTS",
  "mtsubshi",
  "MTU",
  "MTU/MarelliMotori",
  "mubff",
  "Mudson Brown LTD",
  "Mueller",
  "Mulitipower",
  "MULLEN ELECTRICAL CO LTD",
  "Muller",
  "Multi",
  "MULTI 9",
  "MULTI CUBE",
  "MULTI DIALOG",
  "MULTI GUARD",
  "Multi panel",
  "MULTI SYSTEM TECHNOLOGIES",
  "MULTI9",
  "Multi-Aqua 5",
  "Multico",
  "MULTICUBE",
  "MULTICUBE S",
  "MULTIGAS INSTALLATIONS",
  "Multiguard",
  "Multikwik",
  "Multiple -",
  "MULTIPLEX",
  "multipoint",
  "Multipower",
  "Multiserv",
  "Multiserve",
  "Multisignal AB",
  "Multi-Signal AB",
  "Multistack",
  "multitek",
  "multitone",
  "MULTITRAC",
  "Multivac",
  "MULTIVENT LTD",
  "MULTIVENT LYD",
  "MULTIWALL",
  "Mul-T-Lock",
  "Munters",
  "Murco",
  "Murex",
  "Musgrave",
  "Musgrave Generator",
  "MUSGRAVE GENERATORS",
  "Musgrave Wiles",
  "Musgrove",
  "Musketeer",
  "MUSKETEER SERVICES",
  "MUSKETEER SERVICES LTD.",
  "MUSTANG COMMUNICATIONS",
  "Mustgate Ltd",
  "MUSTIGATE WATER DISPLAY LTD",
  "MUTICUBE",
  "MUZZER LUIGI",
  "MV",
  "MVC",
  "MW",
  "mx",
  "Mx Electronic",
  "MX GROUP",
  "MX PRO",
  "Mx Technology",
  "Mxp24",
  "MXPRO",
  "MxPRO5",
  "my ref scroll",
  "MYER",
  "Myers",
  "Myford",
  "Myfridge",
  "mylex",
  "myref",
  "MYRIAD",
  "MYSOH",
  "MYSON",
  "MYSON Brooks",
  "MYSON COMPANY",
  "Myson Coppered",
  "Myson Fans Ltd",
  "MYSON HI LINE",
  "Myson Lo-line",
  "Myson Unit",
  "mzx",
  "MZX Technologies",
  "MZX TECHNOLOGY",
  "N",
  "N & C",
  "N E I",
  "N G Automatics",
  "N ID",
  "N T B",
  "N Power",
  "N&C",
  "N&C AUTO",
  "n&e",
  "N. ROURKE & SON LTD",
  "N/",
  "N/A",
  "N/F",
  "n/k",
  "n/k (entire pump missing)",
  "n/k (plate missing)",
  "n/k4",
  "n/l",
  "N/S",
  "N/W",
  "N0 NAME",
  "N0 NAME PLATE",
  "Naber Therm",
  "NABIC",
  "Nabio",
  "NAC",
  "NAEROK LTD",
  "NAIACS",
  "NAIKO",
  "NAIRE",
  "NALCO",
  "NAME PAINTED OVER",
  "NAME PLATE",
  "NAMENSSCHILDER",
  "Nami Forsaljning",
  "NAMIHISSEN",
  "NAMIHISSEN 400",
  "NAMIHISSEN400",
  "NANIKA",
  "Nanni",
  "nano",
  "NANROSE",
  "NAOMI GRILLS",
  "NAPIER",
  "NARDIC NY HONEYWELL",
  "NARK",
  "Naser",
  "NASH ELMO",
  "Nassau",
  "NASSAU DOORS",
  "NASSUA",
  "Nastec",
  "NAT ENGINEERING",
  "Natalarm",
  "NATALARM STSTEM LTD",
  "NATION LAB",
  "NATIONAL",
  "NATIONAL AIR SYSTEMS",
  "NATIONAL BRITANNIA",
  "National Equipment",
  "NATIONAL FOAM",
  "National Grid Metering",
  "NATIONAL LAB",
  "National safety valves",
  "National Valve",
  "National Vulcan",
  "Nationwide",
  "NATIONWIDE AIR SYSTEMS LTD",
  "NATIONWIDE MANTENANCE LTD",
  "NAVEO",
  "NAVICO",
  "NAXON",
  "NAZZER LUIGI",
  "NB BAILEY",
  "NB BAILEY.",
  "NBE Fire and Security",
  "NC",
  "NC MANUFACTURIG",
  "NC2",
  "NCA MANUFACTURING",
  "NCE SWITCHBOARD",
  "NCE SWITCHGEAR",
  "NCR",
  "ND",
  "ND METER",
  "ND METERING SOLUTIONS",
  "Nderman",
  "NDESIT",
  "ndeva uk",
  "Ne1lec",
  "NEATAFAN",
  "Neataheat",
  "NEBB",
  "NEBB/KONGSBERG",
  "Nebraska",
  "NEC",
  "NEC SWITCHGEAR",
  "NECTA",
  "NECTA KOBALTO",
  "NED AIR",
  "NED Air BV",
  "NEDAP",
  "NEDD",
  "NEDEM",
  "Nederman",
  "NEDERMARI",
  "nedl",
  "NEEDERMAN",
  "needsham controls",
  "NEELEC",
  "Neesha Controls",
  "Neesham",
  "Neesham Bristol",
  "Neesham Controls",
  "Neff",
  "NEGARRA",
  "NEH",
  "NEI",
  "NEI Bruce Peebles ltd",
  "NEI CONTROL SYSTEMS",
  "NEI REYROLLE LTD",
  "NEIDERMAN",
  "NEI-THOMPSON",
  "NEL",
  "NELLA",
  "NELSON",
  "NELSON BUSH",
  "NELSON TANSLEY",
  "Nemand",
  "NEMC",
  "NEMCO",
  "NEMESIS",
  "NEMO",
  "NEMO 96 HD",
  "NENCKI AG",
  "neo",
  "Neoarra",
  "NEOCARE",
  "Neon Integral",
  "NEOTRONICS",
  "NEOVO",
  "Nepaflow",
  "neptune",
  "Nergeco",
  "NERISSIMO",
  "Nesaflow",
  "NESBIT EVANS HEALTHCARE LTD",
  "NESCAFE",
  "Neserman",
  "NESPRESSO",
  "Nespresso Professional",
  "Nestle",
  "Nestlé",
  "NET",
  "Net Box",
  "NETCO",
  "NETCONNECT",
  "NETCRAFT TANKS",
  "NetGear",
  "NETGENIUM",
  "NETRO",
  "Netvu",
  "NETWORK",
  "NETWORK SECURITY",
  "NETWORK SECURITY & FIRE",
  "NETWORK SECURITY SYSTEMS",
  "Network Video Technology",
  "Networker",
  "Neumatex",
  "NEVE",
  "NEVIL SCALES & CO LTD",
  "NEVILLE TUCKER",
  "NEW BRUNSWICK",
  "NEW BRUNWIK SCIENTIFC",
  "NEW GATE",
  "New Gates",
  "New Haden",
  "New Haden Pumping Solutions",
  "New Haden Pumps",
  "NEW HADEN PUMPS LTD",
  "NEW HALDEN",
  "NEW LIFT",
  "New panel - Blue line locking upgrade (2015)",
  "NEW TEAM",
  "New Wave",
  "New World",
  "NEW WORLD DRYERS",
  "NEWAGE",
  "NEWAGE INTERNATIONAL",
  "NEWAGE INTERNATIONAL (STAMFORD)",
  "NEWAGE INTERNATIONAL LTD",
  "NEWAGE INTERNATIONALCO14149/01",
  "newage stamford",
  "NEWAGE/STAMFORD",
  "newaire",
  "NEWARK",
  "NEWARK COPPER CYLINDER",
  "newarkly",
  "NEWAVE",
  "Neway",
  "NEWBURY & THOMAS (LIFT Co.)",
  "newcon",
  "NEWEY & EYRE",
  "newgate",
  "Newgate (Newark) Ltd",
  "NEWGATE (NEWARK) LTD.",
  "NEWGATE (NEWARK)LTD",
  "NEWGATE AIR",
  "Newgate Ltd",
  "Newhome",
  "newing hall",
  "NEWL2X",
  "newlc",
  "NEWLEC",
  "newlec box",
  "Newlev",
  "NEWMAN",
  "NEWMAN INDUSTRIES LTD",
  "NEWMAN SECURITY",
  "Newman Tonks",
  "Neworld",
  "NEWPAN",
  "NEWSCAN",
  "Newsmith",
  "Newson Gale Ltd",
  "Newteam",
  "NEWTECH",
  "Newtech Electrical",
  "NEWTECH ELECTRICAL LTD.",
  "Newtech Engineering",
  "NEWTON DERBY",
  "Newworld",
  "NEX WATCH",
  "NEXT CONTROL SYSTEMS.",
  "NF",
  "NF Line",
  "NF LTD",
  "NF UK",
  "NG",
  "ng & co",
  "NG & Co.",
  "NG AUTOMATIC",
  "NG Automatic doors",
  "NG AUTOMATICS",
  "NG BAILEY",
  "NG BAILEY LIMITED.",
  "NG BAILEY.",
  "NGAUTOMATICS",
  "NGF",
  "NGOUK",
  "NH",
  "NHRE",
  "NIA",
  "NIACS",
  "NIAGARA",
  "Nibco",
  "NIBIG",
  "NIC",
  "NICE",
  "Nice Robus",
  "Nicem",
  "Niche",
  "NICHOLLS AND CLARKE",
  "NICHOLSO PLASTICS",
  "Nicholson",
  "NICHOLSON PLASTIC",
  "NICHOLSON PLASTICS",
  "NICHOLSON PLASTICS LTD",
  "NICHOLSON PLASTICSLTD",
  "Nicholsow Plastics",
  "Nickerson",
  "NICKY CLARKE",
  "Nicolson Plastics",
  "Nicotra",
  "NICOTRA GEBHARDT",
  "NIECO",
  "nied",
  "nies",
  "NIFE",
  "Nifty",
  "NIGLON",
  "NIIGATA POWER SYSTEMS CO., LTD.",
  "NIJL",
  "NIJL Aircraft docking",
  "NIJL AIRCRFT DOCKING",
  "Nikkai",
  "NIKL Aircraft Docking",
  "Nikles",
  "Nilfisk",
  "Nilfisk Alto",
  "NILFISK-ALTO",
  "Nilma",
  "nimbus",
  "Nimo",
  "nin",
  "NIPPON",
  "NISCAYAH",
  "NISSAN",
  "Nistral",
  "Nit Present",
  "Niteq",
  "Nittan & Gent",
  "NJK CONTRACTING LTD",
  "nk",
  "nknown",
  "NL CUPRISC/N",
  "NLACK TEKNIGAS",
  "NLAKEY",
  "NLAKLEY",
  "NMD",
  "NN",
  "NNN",
  "NO",
  "NO ACCESS",
  "NO ACCESS - ABOVE CEILING",
  "NO ACCESS - CABINET LOCKED",
  "NO ACCESS DUE TO INSULATED JACKET",
  "NO ACCESS DUE TO LOCATION BEHIND SINK",
  "no access to loft",
  "NO ACCESS TO TANK",
  "NO ACCESS TONROOM",
  "NO ACCESS, ABOVE CEILING",
  "NO ACCSE",
  "No brand",
  "NO DATA",
  "NO DATA BADGE",
  "No datsils",
  "no details",
  "No details ledgible",
  "No Details on key pad",
  "NO FROST",
  "No I.D",
  "NO ID LABEL",
  "NO ID PLATE",
  "NO ID TO HIGH",
  "NO ID-(UNIT AT DEPTH UNDER COVER)",
  "NO IDENTIFICATION",
  "NO INFO",
  "NO IS",
  "no known",
  "NO MAKERS NAME",
  "NO MANUFACTURERS INFORMATION",
  "NO MANUFACTURERS NAME",
  "no markings",
  "NO NA,E",
  "NO NA,E PLATE",
  "NO NAM2",
  "NO NAME (S)",
  "NO NAME 0LATE",
  "NO NAME OLATE",
  "NO NAME PLATE",
  "NO NAME PLATED",
  "NO NAME PLTE",
  "NO NAMEACH (HIGH LEVEL)",
  "NO NAMELATE",
  "No Plate",
  "No visble ID",
  "no visible marking",
  "no visible markings",
  "NO VISIBLE NAME",
  "NO VISIBLE NAME PLATE",
  "No Way",
  "NO1. PARAGON, NO2.KILNS & FURNACES, NO3. CROMARTIE",
  "NOBB & JONES",
  "Nobbs & Jones",
  "NOBBS & JONES / NORTHERN DESIGN BRADFORD",
  "NOBBS & JONES / NORTHERN DESIGN BRADFORD METERING",
  "NOBBS & JONES LTD",
  "nobbs and jones",
  "NOBBS AND JONES LTD",
  "NOBEL",
  "Nobel Fire Aystems",
  "NOBEL FIRE SYSTEMS",
  "NOBEL FOR SYSTEMS",
  "NOBELGEN",
  "NOBLE ENGINEERING PROJECTS",
  "Noble Fire Systems",
  "NOBLELIFT",
  "Nobo",
  "Noby",
  "Noby448",
  "NOCCHI",
  "Nochi",
  "NOCO",
  "NOIROL",
  "NOKIA",
  "Nokia Siemens",
  "Nomafa",
  "NOMAK",
  "NOMI",
  "non known",
  "NONAME",
  "NONBS AND JONES",
  "None Visible",
  "Nonfrost",
  "noot known",
  "NOPLATE",
  "Nor frost",
  "Nor Ray Vac",
  "Nor way vac",
  "NORACON",
  "Noralsy",
  "Noraly",
  "Noralysy",
  "NORBAR",
  "NORCOOL",
  "NORCOOL LTD",
  "Nord",
  "NORD DRIVE SYSTEMS",
  "NORD GEAR LTD",
  "Nordair",
  "Nordair Niche",
  "Nordair Systems",
  "Nordair Systems Ltd",
  "NORDAIRNICHE",
  "NordAviation",
  "NORDISC VENTILATOR CO.",
  "Nordisk Ventilator",
  "Nordlicht",
  "nordmann",
  "Nordmende",
  "NOR'E",
  "NORELCO",
  "NORELIGHT PANELS LTD.",
  "NORELIGTH NEWMAN",
  "norfi",
  "NORFRIST",
  "NORFROST",
  "Norgren",
  "Norgren ?",
  "Norland",
  "Normond",
  "NORMSTAHL",
  "NORPE",
  "NORPE UK LTD",
  "NORPE VIESSMANN",
  "norris",
  "Norsen",
  "NORTEL & HP & CONNECTIX",
  "NORTH DOWNS",
  "North East Automation",
  "NORTH THAMES GAS BOARD",
  "NORTH WEST SWITCHGEAR",
  "NORTH WEST SWITCHGEAR LTD.",
  "Northcliffe",
  "NORTHEN DOORS",
  "NORTHEN DOORS UK LTD",
  "NORTHEN SWITCHGEAR & CONTROLS",
  "Northern",
  "NORTHERN AIR HANDLERS",
  "NORTHERN COMPUTERS LTD",
  "NORTHERN COMPUTRS INC",
  "NORTHERN DESIGN (ELECTRONICS) LTD.",
  "NORTHERN DESIGN BRADFORD UK",
  "Northern Door Company",
  "northern doors",
  "Northern Doors Ltd",
  "NORTHERN DOORS LTD.",
  "Northern Doors UK Ltd",
  "Northern Electric",
  "Northern Electrical Engineering",
  "Northern fan supplies ltd",
  "NORTHERN KILNS",
  "NORTHERN LIGHT",
  "NORTHERN POWERGRID",
  "northern switchgear",
  "Northern Switchgear & Controls",
  "NORTHERN SWITCHGEAR & CONTROLS.",
  "NORTHERN TECHNICAL & CHEMICAL SERVICES",
  "northern technologies inc",
  "Northern Design",
  "Northernn Doors Ltd",
  "NORTHVALE",
  "NORWEB",
  "NOSS",
  "NOT",
  "Not Accessable",
  "NOT ACCESSABLE - DUE TO LOCATION OF PUMPS",
  "NOT ACCESSIBLE",
  "Not cisible",
  "Not Displaed",
  "Not Displayed",
  "Not Displayed in Void",
  "Not Displayed in zvoid",
  "NOT IDENTIFIED",
  "NOT KNON",
  "Not know",
  "Not known",
  "not known Cooker",
  "not known infa red heater",
  "Not Known",
  "Not Known(TUV)(EMC)??",
  "Not Legible",
  "not lnown",
  "not maeked",
  "not marked",
  "NOT ON PLATE",
  "NOT PRESEN",
  "NOT PRESENT",
  "NOT PRESENT - INSTALLED BY KENTEC ELECTRICS",
  "NOT PRESENT - MAINTAINED/INSTALLED BY SPY ALARMS",
  "Not Present.",
  "NOT PRESENT-UNIT HOUSED & COVERED",
  "NOT PRESSENT",
  "NOT PRSENT",
  "Not viable",
  "Not visable",
  "not visible",
  "not visible due to Insulation",
  "not visible -no access",
  "NOT VISIBLE WITHIN CHAMBER",
  "NOTAVAILBLE",
  "Nothern Design Metering Solutions",
  "NOTHERN SWITCHGEAR & CONTROLS",
  "Noti Fire",
  "NOTIFIER",
  "notknown",
  "Not-Known",
  "NOTKNOWN MANUFACTURER DETAILS TO WEATHERED TO READ",
  "NOTTHERN DOORS",
  "NOTTINGHAM",
  "NOTTINGHAM TRANSFORMER CO LTD",
  "Nottingham Transformers",
  "NOVA",
  "NOVA DRYERS UK LTD",
  "NOVA LIFT CO LTD",
  "NOVA LIFTS",
  "NOVA MAJIK",
  "NOVA MATIK",
  "NOVA TRAK",
  "Novair",
  "NOVAMATIK",
  "Novar",
  "NOVAR 300",
  "NOVATECH",
  "Novec",
  "NOVENCO",
  "NOVENCO AEREX LTD",
  "NOVI",
  "novo ferm",
  "novoferm",
  "NOVOVENT",
  "NOVUM",
  "NOWAX",
  "Noway",
  "NP",
  "NPS",
  "NRI",
  "nron",
  "NRS",
  "NSB",
  "NSB FALSOM",
  "NSF",
  "NSL FIRE & SECURITY",
  "NT",
  "NT ACCESS",
  "NT Door Controls Ltd",
  "NTD",
  "nTMC True",
  "Nu - Air",
  "nu wire",
  "Nu Tool",
  "NU+STAR",
  "NU-AIR",
  "Nuair XS",
  "Nu-Aire",
  "NUAIRE LTD",
  "NUAIRE/ STEEPLE",
  "NUCEM",
  "NUCLEO",
  "NUGULEC",
  "NUKROFIL",
  "NULEC",
  "Numatic",
  "NUMAX",
  "nunway",
  "Nuova Electra",
  "NUOVA ELETTRA",
  "nuova Simonelli",
  "NUOVO PIGNONE",
  "NUOVO PIGNORE",
  "NURSE 24",
  "NURSECALL",
  "NUSIRE",
  "Nu-Star",
  "Nutec CHP Systems",
  "NuTool",
  "Nutools",
  "Nuttall",
  "Nu-Vu",
  "NUWARK",
  "NU-WAT",
  "NUWAY",
  "Nu-Way",
  "Nuway Benson",
  "NUWAY ENERTECH GROUP",
  "NU-WAY LIMITED",
  "Nuway ltd",
  "NU-WAY LTD",
  "NU-WAY LTD.",
  "NV POWERMATIC",
  "Nvaire",
  "NVC",
  "nvc/tamlight",
  "nvirocare ltd",
  "NVM",
  "nvq",
  "NVT",
  "NW",
  "Nwlec",
  "NWM",
  "Nwn",
  "NYBORG",
  "NYC",
  "O & O",
  "O Connell",
  "O J Electronik (UK) Ltd",
  "O Reilly Automation",
  "O&K Lifts Ltd",
  "O,ERRE",
  "O.B.L.",
  "O.ERRE",
  "O.L.E.",
  "O.L.E. (UK) LTD",
  "O.L.E. UK LTD",
  "O.T.S",
  "Oais Aqua Pointe",
  "Oak security systems",
  "Oakland",
  "OAKLAND ELEVATOR LTD",
  "OAKLAND ELEVATORS",
  "OAKLAND ELEVATORS LTD",
  "OAKLAND EXCEISIOR",
  "OAKLAND EXCELSIOR",
  "OAKLAND LIFTS",
  "OAKMOOR INDUSTRIAL CONRROL AND POWER SOLUTIONS",
  "OASE",
  "Oasis",
  "Oasis International",
  "oasys",
  "OB",
  "OBL",
  "OBL??",
  "OBM",
  "O'Brien",
  "O'BRIEN INTERNATIONAL LTD",
  "OBS",
  "obscured",
  "OC SMITH",
  "occo coolers - evans lifts",
  "OCCO COOLERS LTD",
  "Ocean",
  "ocio",
  "Ocrre",
  "ocs",
  "OCS Cradle Runway",
  "OCS Cradle Runways",
  "OCS Engineering",
  "OCTAR",
  "OCTAVE",
  "ODLINGS",
  "ODYSSEE",
  "ODYSSEY",
  "oerre",
  "Oertzen",
  "Oeste Prisma",
  "OEZ",
  "ofatec",
  "OFFCAR",
  "OFPS",
  "OFS",
  "Oftec",
  "OGI",
  "OGI Meters",
  "OHAUS",
  "OHMEDA",
  "OICO",
  "OIL CARE",
  "OIL CONTROL",
  "oil resevior - evans lifts no 1",
  "oil resevior - evans lifts no 2",
  "OILS",
  "OIUSI",
  "OKA GERMANY",
  "okeighley",
  "OKI",
  "OKS",
  "OKS OTTO KIEN",
  "OKS Otto Klein",
  "OKS OTTO KLEIN GMBH",
  "OLAER",
  "Oldam",
  "Oldford",
  "Oldham",
  "OLE (UK) LTD",
  "OLE INSTRUMENTATION",
  "Olefini",
  "Olifini",
  "Olimpia",
  "OLIVER COOKE LTD",
  "OLIVER IGD",
  "Oliver IGO",
  "OLYMPIA",
  "OLYMPIA SPLENDID",
  "OLYMPIAN",
  "OLYMPIAN-CAT",
  "OM",
  "OMAR",
  "OMAR LIFT",
  "Omega",
  "OMEGA ?",
  "OMEGA CITY LIFTS",
  "OMEGA LTD",
  "Omega Red",
  "Omega Red",
  "Omega/ CHOFU",
  "Omega's",
  "omeme",
  "Omer SPA",
  "OMERMILL",
  "OMNI POWER",
  "OMNICARE",
  "OMNICARE VIGIL",
  "omnicron",
  "OMNIPURE",
  "OMP",
  "Omron",
  "ON NAME PLATE",
  "On Stream",
  "ONDA",
  "ONDYNE",
  "ONE",
  "ONE CALL",
  "One Electrical",
  "One zone",
  "ONEGA SWITCHGEAR.",
  "Onis",
  "ONKYO",
  "Onn",
  "ONNLINE",
  "Onspire",
  "ONYX",
  "OPELLA",
  "OPEMED",
  "Open Building Systems",
  "OPEN CONTROL SOLUTIONS",
  "OPEN PROTOCOL TCHNOLOGY",
  "OPEN SOLUTIONS",
  "OPEN TECHNOL0GY UK",
  "OPIDRIVE",
  "Opowermatic",
  "OPPERMAN GEARS UD",
  "OPPERMAN MASTERGEAR",
  "OPT",
  "OPTAIR",
  "Optex",
  "OPTI",
  "OPTIMA",
  "Optima communications technology",
  "Optimum",
  "OPTIMUM CONTROLS SERVICES LTD.",
  "OPTIMUM POWER SERVICES",
  "OPTIMUN POWER SERVICES",
  "Optoma",
  "OPTYMA",
  "OPTYMA SECURITY",
  "Opus",
  "OQEVIEW",
  "OR remeha",
  "ORACSTAR",
  "Orana",
  "ORANGE COUNTY",
  "Orangebox",
  "ORBIK",
  "ORBIK ELECTRONICS LTD.",
  "orbix",
  "ORCLE",
  "ORDERSPEAR LTD",
  "ORENO",
  "ORESSMAIN",
  "ORGA",
  "ORGAN",
  "ORIEME",
  "Orion",
  "Orion Manufacturing",
  "ORIS",
  "ORMANBY",
  "ORMANDBY RYCROFT",
  "ORMANDY",
  "ORMANDY ?",
  "ORMANDY HEAT EXCHANGERS",
  "Ormandy Rycroft",
  "Orona",
  "ORRELL PRECISION",
  "ORTICE",
  "ORTON ELECTRICAL SERVICES",
  "ORTON MECHANICAL SERVICES",
  "ORVARK",
  "Orwak",
  "OSAKI",
  "OSBORNE",
  "OSBORNE REFRIGERATION",
  "OSBORNE REFRIGERATION LTD",
  "OSBORNE REFRIGERATORS",
  "OSBORNE RERIGERATION LTD",
  "OSBOURNE",
  "Osderg",
  "OSHKOSH",
  "OSI",
  "OSILY",
  "OSMAFLOW",
  "OSMAGLASS",
  "OSMAN",
  "OSMOTRON",
  "OSO",
  "Osram",
  "oss",
  "OSTBERG",
  "OSTBERG FANS DIRECT",
  "Oteac",
  "OTERMILL",
  "OTEX",
  "OTIS",
  "OTIS LIFTS",
  "OTIS SPUNKMEYER",
  "OTS",
  "OTTE",
  "otter",
  "OTTER MILL SYSTEMS",
  "Otter Mill",
  "OTTER,ILL",
  "OTTERMALL",
  "OTTERMAN",
  "OTTERMILL LTD.",
  "OTTERMILL SWITCHGEAR LTD",
  "Ottermill/ Cutler Hammer",
  "OTTERMILLSWIRCHGEAR Ltd.",
  "OTTO",
  "OTTO EXPANSOMAT",
  "OTTOMAN",
  "Outrams Solvo",
  "OVA",
  "OVENTION",
  "OVENTION INC",
  "OVENTON",
  "OVENTROP",
  "OVERTON",
  "Overtoom",
  "OWAMAT",
  "OWDEN",
  "Owen brothers",
  "OWEN ROLLER SHUTTERS",
  "OXER LABORTORY EQIUPMENT LTD",
  "OXFORD",
  "Oxford Welding Equipment",
  "OXLEY",
  "OYDI",
  "ozak",
  "Ozonair",
  "OZONAIR ENGINEERING",
  "Ozonaire",
  "OZONE AIR",
  "OZONEAIR",
  "OZONNAIR",
  "Ozznair",
  "P  GIBSON SERVICES",
  "P & B ENGINEERING",
  "P & J",
  "P & J DUST EXTRACTION",
  "P & J DUST EXTRACTION LTD.",
  "P & K Shutter Services Ltd",
  "P & L",
  "P & L SYSTEMS",
  "P + L  SYSTEMS",
  "P + L Systems",
  "P A LIFT SERVICES",
  "P AND L SYSTEMS LTD",
  "P B DESIGN",
  "P E Systems",
  "P G I",
  "P GIBSON SERVICES",
  "p gibson services ld",
  "P GIBSON SERVICES LTD",
  "P J FIRE LTD",
  "P L SYSTEMS",
  "p w allen & co",
  "P& L Systems",
  "P&J",
  "P&J DUST EXTRACT",
  "P&J Dust Extraction",
  "P&J DUST EXTRACTION LTD",
  "P&J EXTRACTION DUST EXTRACTION",
  "P&L",
  "P&L systems",
  "P&L SYSTEMS LTD",
  "P,E Systems",
  "P.F. & F. (RADCLIFFE) LTD",
  "P.GIBSON",
  "P.I.U",
  "P.P.C.",
  "P.S.G. Professional Security Group",
  "P+ L SYSTEMS",
  "P+L",
  "P+L Systems Ltd",
  "p+l systes ltd",
  "P4",
  "PA",
  "PA FIRE",
  "PAAL",
  "PAC",
  "PAC INTERNATIONAL",
  "PAC/ PROTEC",
  "PAC/PROTEC",
  "PACAIR",
  "Pace",
  "Pacific",
  "Paco",
  "Pacom access control",
  "PACOM BELL",
  "PACTFOL",
  "PACTPCL",
  "PAGING SYSTEMS LTD / MULTITONE",
  "PAGNOTTA S.R.L",
  "PAINTED OVER",
  "PAK",
  "Paka Waste",
  "PAKAWASTE LTD",
  "Pakaway Perrymatics",
  "Paktronic",
  "PALACE TECH",
  "PALACE TECH LTD",
  "Palace-Tech Ltd",
  "Palamatic",
  "PALARON",
  "Palazzoli",
  "PALCON",
  "PALCON SERVICES",
  "Palcon Systems",
  "PALCON SYSTEMS LTD.",
  "Palice - Tech Ltd",
  "PALL",
  "PALLET TRUCK WAREHOUSE",
  "Palma",
  "Palmet",
  "PALO",
  "PALON SYSTEMS LTD.",
  "PAN ENGINEERING LTD.",
  "PANACHE",
  "Panasonic",
  "panasonic inverter",
  "PANASONOC",
  "PANAWAY",
  "PANDELCO",
  "PANDELCO  SWITCHGEAR AND CONTROL PANELS",
  "pandleco",
  "Panel Collector",
  "panel fed from dbru/14l 123",
  "Panel Fires.com",
  "Panelmaster",
  "PANELMATIC SYSTEM LTD",
  "Panersonic",
  "Panker Hannifin",
  "PANOASONIC",
  "PANTAIR INTERNATIONAL",
  "PANTHEON",
  "Panther security",
  "PantherGas Direct Ltd",
  "PAR",
  "PAR SCIENTIFIC",
  "PARADIM QPACK DIGITAL DIMMER",
  "PARAGON",
  "PARAGON PLUS",
  "PARAID",
  "PARAID LIMITED",
  "PARALLEL FAN UNIT",
  "Parallel Plus",
  "Paramount Doors",
  "Paramount Security Group",
  "Parasense",
  "PARFAS",
  "PARFAS P&R FIRE AND SECURITY",
  "Parinson Cowan",
  "Park",
  "Park Electrical",
  "Park International",
  "Park International",
  "PARKEON",
  "PARKER",
  "Parker Bath",
  "PARKER BATH COMPANY",
  "PARKER BATH DEVELOPMENTS",
  "Parker Hannifin Corperation",
  "PARKER HIROS",
  "Parker Hiross",
  "PARKER SSD DRIVES",
  "Parkeson Cowen",
  "PARKING FACILITES LTD",
  "PARKING FACILITIES",
  "PARKINGSON COWAN METER",
  "PARKINGSON CROWN",
  "Parkinson",
  "Parkinson Cowal",
  "Parkinson Cowan",
  "Parkinson Cowan Measurement",
  "PARKINSON COWAN METER",
  "PARKINSON COWAN",
  "Parkinson Cowen",
  "Parkinson Crown",
  "PARKINSON MOTORS",
  "Parkinson Cowan",
  "PARKINSONCOWAN",
  "Parkinsonn Cowal",
  "Parkmore Electrical",
  "PARMEKO",
  "Parmiter Hope & Sugden",
  "Paromat Simplex",
  "PARR",
  "PARRY",
  "Parry Group",
  "PARSONAGE",
  "Parsonage Ltd",
  "Parsonage Transformers",
  "Parsons",
  "PARTCO",
  "PARTIZAN",
  "PARTON",
  "PARTON FIBREGLASS",
  "Parton Fibreglass Ltd",
  "PARTQUEST",
  "PARTRIDGE TRANSFORMERS",
  "PARTSWASH UK",
  "Passivent",
  "PASTA KING",
  "PASTA KING LTD",
  "PASTONKALT",
  "Pastorfrigor",
  "Pastorkalt",
  "PASTORKART",
  "PASTRY CASE",
  "Pat Lee",
  "Patay",
  "PATEK PHILIPPE GENEVA",
  "Paterson",
  "PATLEE",
  "patol",
  "patol systems",
  "Patterson",
  "Patterson commercial",
  "PATTERSON-KELLEY",
  "PAUWELS",
  "PAUWELS TRAFO",
  "Pauwels Trafo Belgium",
  "PAUWELS TRAFO-BELGIUM",
  "Pavoni",
  "paw",
  "Paxman",
  "PAXTON ACCESS",
  "PB",
  "PB 13 Design",
  "pb batteries",
  "PB Design",
  "PB DESIGN & DEVELOPMENT",
  "PB DESIGN & DEVELOPMENT LIMITED",
  "PB DESIGN & DEVELOPMENT LIMITED.",
  "PB DESIGN & DEVELOPMENT LTD.",
  "PB DESIGN & DEVELOPMENTS",
  "PB DESIGN & DEVELOPMENTS LIMITED",
  "PB DESIGN & DEVELOPMENTS LIMITED.",
  "PB DESIGN & DEVELOPMENTS LTD",
  "PB DESIGN &DEVELOPMENTS LTD.",
  "Pb Design And Development",
  "PB DESIGN LTD",
  "PB DESIGNS",
  "PB DESING",
  "PB ELECTRICAL",
  "PB POERBOARD",
  "PB POWER BAR",
  "PBE SYSTEM LTD",
  "PBE SYSTEMS",
  "PBE SYSTEMS LTD",
  "PBSC LTD",
  "pc",
  "PC BASED SYSTEM",
  "PC COMPTEURS LTD",
  "PC SPECIALIST",
  "PCE",
  "PCI",
  "PCL Services",
  "PCL Services Ltd",
  "PCM",
  "PCME",
  "PCO",
  "PCS",
  "PCSC",
  "PCSPECIALIST",
  "PCT",
  "PD",
  "PD DESIGN & DEVELOPMENTS LIMITED.",
  "PDA",
  "PDA RANGE",
  "PDA Rank",
  "PDI",
  "PDQ LIFTING LTD",
  "PDQ LTD",
  "PDT",
  "PE GENERATORS",
  "PE SYSTEM",
  "PE Systems",
  "PE SYSTEMS LTD",
  "PEAK INDUSTRIAL",
  "PEAK SCIENTIFIC",
  "PEARL",
  "Pec",
  "PECA",
  "PECAN",
  "PECS",
  "Pedroll",
  "Pedrollo",
  "Peebles",
  "PEEBLES DISTRIBUTION TRANSFORMERS LTD",
  "Peerless",
  "PEFYP100-VMA",
  "Pegasus",
  "PEGLER",
  "PEH",
  "PEIFFER",
  "PEKO",
  "PEKO LAUNDRY PRODUCTS",
  "PEL",
  "Pel Services",
  "PELCO",
  "Pelgrim",
  "PELICAN ROUGE",
  "PELICAN ROUGH",
  "PELIGRO",
  "PELL SYSTEMS LTD",
  "Pelloby",
  "Pelsis",
  "Pemutit",
  "Pen ventilator",
  "PENCO CATERING SYSTEMS",
  "PENEC",
  "Penine",
  "Penley",
  "PENLON",
  "PENLON INTERMED",
  "PENN",
  "PENN-BARRY",
  "PENNINE ACTUATORS",
  "PENNY HYDRAULICS",
  "PENRYN",
  "PENSDOWN POWER CONTROLS",
  "PENSDOWN POWER CONTROLS COMMUNICATION",
  "PENSDOWN POWER CONTROLS COMMUNICATIONS",
  "PENSOTTI",
  "pent-a-boost",
  "PENTAGON",
  "Pentair",
  "PENTAIR INTERNATIONAL",
  "PENTAIR WATER",
  "PENTAIR/WELLMATE",
  "Pentam Composites Ltd",
  "PENTAN",
  "PENTAX",
  "Penton",
  "PEP",
  "PEP Air handlers",
  "Peppers Of Woking",
  "PERCIVAL HEATING LTD",
  "PERECOLO",
  "PEREGO",
  "PERFECT",
  "Perfecta",
  "PERFORMER",
  "PERICOLD",
  "Perimeter Protection",
  "Perkin Elmer",
  "Perkins",
  "PERKINS (F.G.WILSON)",
  "Perkins / Dale",
  "PERKINS INTERNATIONAL",
  "PERMALERT ESP LTD",
  "Permali RP",
  "Permapure",
  "PERMASTORE",
  "Permo",
  "PERMUTIT",
  "PERMUTIT COMPANY LTD",
  "PEROLLO",
  "PERRIN",
  "PERRINS MECHANICAL SERVICES",
  "PERRY & CLARKE PLASTICS LTD",
  "PERRYMATICS",
  "PERRYMATICS (S.A.R.B) LTD.",
  "PERSKE",
  "Persona",
  "PES",
  "PEST PRODUCTS IRELAND",
  "PEST TO KILL",
  "Pest West",
  "Pestokil",
  "PESTOKILL",
  "Pestwest",
  "PEsystems",
  "PET BOW",
  "PETBOW",
  "PETER EVANS (BOLTON) LTD",
  "PETER FENTON POOLS",
  "Peterlee",
  "PETERREINS",
  "PETERRINS",
  "Petra",
  "PETROGEN",
  "PETROX",
  "pf",
  "Pfaff-silberblau",
  "PFC",
  "PFC & Co.",
  "PFC ENGINEERING",
  "PFEIFER",
  "PFEIFFER",
  "PFG",
  "PFG (PARTON FIBRE GLASS LTD)",
  "PFG WATER STORAGE TECHNOLOGY",
  "PGI",
  "PHA",
  "Phark",
  "PHARMAGRAPH LTD.",
  "Phaser Tank Manufacturers",
  "PHD",
  "PHDMALINE",
  "PHE",
  "Philco",
  "PHILIOS",
  "PHILIP",
  "PHILIPS",
  "Philips tubular heater",
  "PHILIPS WHIRLPOOL",
  "PHILIPS-LIGHTMASTER MODULAR AREA CONTROLLER",
  "Phillips",
  "Phillips 1",
  "Phillips 2",
  "Philmar Fabrications LTD",
  "PHILPS",
  "PHLEXICARE",
  "PHOENIX",
  "PHOENIX COMPACTOR LTD",
  "PHOENIX FIRE SERVICES LTD.",
  "Phoenix Lifting Aystems",
  "Phoenix Lifting Systems",
  "PHOENIX LIFTING SYSTEMS LTD",
  "Phoenix Safe Co. ltd.",
  "PHONIC",
  "Photain Controls",
  "PHOTON ENERGY",
  "PHOTURIS",
  "phs",
  "Phs test man1",
  "PHS ULTRADRY",
  "PHS World automatic",
  "PHYSICAL SECURITY SPECIALISTS",
  "PHYSIO CONROL",
  "Pi",
  "PI ELECTRONICS",
  "Pickering",
  "Pickering /Otis",
  "PICKERING LIFTS EUROPE",
  "PICKERING'S",
  "Pickerings Lift",
  "PICKERING'S LIFT",
  "Pickering's Lifts",
  "PickeringsLifts / Otis",
  "PICKSTONE OVENS",
  "PICO FRIALATOR LTD",
  "PICOW",
  "Pierlite",
  "PifCo",
  "Piggot & Whitfield Ltd",
  "PILAR",
  "PILLAR",
  "PILLAR GROUP",
  "PILLAR POWER SYSTEMS GMBH",
  "PILLER (UK) LTD",
  "PILLER PERMIUM POWER SYSTEM",
  "PILLER WEST GERMANY",
  "PILLINGER",
  "PILLINGER MONOBLOCK",
  "PILUX",
  "PIMMS PUMPS LTD",
  "PIMS PUMP LTD",
  "PIMS PUMPS",
  "PIMS PUMPS LTD",
  "PIMS PUMPS LTD.",
  "Pin Point",
  "PINNACLE FIRE PROTECTION LTD",
  "Pinpoint",
  "Pinpoint Advanced",
  "Pinpoint Control",
  "Pinpoint Panel",
  "Pinpoint System",
  "Pinpoint Tester",
  "Pinxton / Birtone",
  "Pioneer",
  "PIONER",
  "Pipe Solution",
  "PIPE SOLUTIONS",
  "PIPE SOLUTIONS LTD",
  "PIPELINE CENTRE",
  "Pipelinecentre",
  "PIR",
  "PIRAM",
  "Pirra",
  "Pisces",
  "PISCES PUMPS LTD",
  "Piset",
  "Pisui Instruments",
  "PITC0",
  "Pitco",
  "PITCO FRIALATOR",
  "pitco friolator",
  "PITCO FRYMASTER",
  "PITOO",
  "PITTS",
  "PITTS WILSON FIRE",
  "PITWAY CORPORATION",
  "PIUS1",
  "Piusi",
  "PIUSI instruments",
  "PIUSU Instruments",
  "PJ DUST EXTRACTION LTD",
  "PJ FIRE LTD",
  "PJB Eng Services",
  "PJB Engineering",
  "PJH",
  "PJW",
  "PJW/GEC",
  "PK Electrics",
  "PKS ENGINEERING LTD",
  "PL Systems",
  "P-L Systems",
  "PLAN",
  "Pland",
  "Planet platforms",
  "PLANET PUMPS",
  "PLANETARY MIXERS",
  "Planetary Mizers",
  "Planmeca",
  "PLANT CONTROL",
  "Plantime",
  "Planus",
  "PLAQUE HEATER",
  "plasma",
  "PLASMOLD",
  "PLASMOLD SAFETY SYSTEMS",
  "PLASMOLD SYSTEMS",
  "PLASTIC CONSTRUCTION LTD",
  "PLASTIC TANKS LTD",
  "Plastic Constructions Ltd",
  "PLASTICA",
  "Plasticon",
  "Plastmetall Värnamo",
  "plate removed",
  "Plate weatered away",
  "Plate weathered away",
  "Platform Company",
  "Platform lift Co",
  "Platform Lift Co.",
  "Platform Lift Company",
  "PLATIMUM",
  "PLATINUM",
  "PLATINUM SECURITY",
  "PLATINUM XTREME PLUS",
  "PLATON",
  "Plattner",
  "PLATT-SCHNIDLER",
  "Pleco",
  "PLEDSEY",
  "PLENA",
  "Plena Bam",
  "PLENO",
  "PLENTY",
  "Plenty Mirrlees",
  "Plessey",
  "PLESSEY COMMUNICATIONS SYSTEMS",
  "PLS Systems",
  "PLS SYSTEMS AB",
  "PLS SYSTEMS AN",
  "PLUG IN SYSTEMS",
  "PLUGIN SYSTEMS",
  "PLUMB CENTRE",
  "PLUMBCENTER",
  "Plumbsoft",
  "Plummer Electrical Engineering",
  "PLUMP CENTER",
  "Plumridge & Peters",
  "Plumridge and Peters",
  "PLUS + ZAP",
  "PLUS ZAP",
  "Plus1 Oc10",
  "PLUSAIR HPC",
  "Plusl",
  "PLUSL(Piusi)",
  "PLUSZAP",
  "PLUS-ZAP",
  "PLUSZAP16",
  "PLYMOTH",
  "Plymovent",
  "PLYMOVENTS",
  "PM LUFT",
  "Pmark",
  "PMCTFCL",
  "PMD Technologies",
  "PMD TECHNOLOGIES LTD",
  "PMD TECHNOLOGIES LTD.",
  "PMD TECNOLOGIED",
  "PMEUMATEX",
  "PMJ INTERNATIONAL",
  "PM-LUFT",
  "pmr",
  "pms",
  "PMS PUMPS LTD",
  "Pndolin Systems",
  "PNE NEPTRONICS",
  "PNEU- DRI",
  "PNEUDRI",
  "PNEUMATE”X",
  "PNEUMATEX",
  "PNEUMATEX  TRANSFERO",
  "PNEUMATEX STATICO",
  "PNEUMATEX ZEPARO",
  "Pneumatic",
  "Pneumatix",
  "PNEUMATIX STATICO",
  "PNG",
  "Pnu Power",
  "pnuumatex",
  "POC Electrical Services",
  "POCL",
  "POD BAR",
  "pod POINT",
  "POD POINT TECHNOLOGY",
  "Podab",
  "POEER CAPACITORS",
  "POIKL",
  "POINTCOOL OY",
  "Pointer Control Systems",
  "POINTER FIRE",
  "POL",
  "POLAR REFRIGERATION",
  "POLARIS",
  "POLARON",
  "polaron controls",
  "POLARON CONTROLS LTD",
  "POLARON. I LIGHT",
  "POLAX UK LTD",
  "polestar",
  "Pollard",
  "POLLARDS INDUSTRIAL DOORS LTD.",
  "Pollet Water Group",
  "POLLOCK",
  "POLLOCK LIFT",
  "POLLOCK LIFTS",
  "POLY",
  "Poly Steel",
  "Polycom",
  "polycon/samsung",
  "POLYMETERS LTD.",
  "Polyphase",
  "Polyphase Energy Meter",
  "Polypipe",
  "Polysec",
  "Polystel",
  "POLYTANK",
  "POMPA YAKO",
  "Pompe Cucchi",
  "POMPES JAPY",
  "Pompes Salmson",
  "Ponche & Fornaux",
  "Pony",
  "Poolcontrol",
  "POOR ACCESS",
  "pop up",
  "POP UP PRODUCTS",
  "POP UP PRODUCTS LTD",
  "POPE SOLUTIONS",
  "Poraramp",
  "PORCELAIN SINK",
  "PORCH LIFT",
  "PORKA",
  "PORKKA",
  "PORN & DUNWOODY",
  "Porn & Dunwoody Lifts",
  "PORN & DUNWOODY LIFTS LTD",
  "PORN & DUNWOODY LIFTS LTD.",
  "PORN AND DUNWOOD",
  "PORN AND DUNWOODY",
  "porn dunwoody",
  "PORN DUNWOODYN",
  "PORN&DUNWOODY",
  "PORT SHEL",
  "PORTA RAMP",
  "Portal",
  "Portal UK",
  "PORTALP",
  "PORTARAMP",
  "PORTARAN",
  "Portek",
  "Porteous",
  "Porter Lancastrian",
  "Porteus",
  "Portiso",
  "PORTLAND",
  "PORTLAND LTD.",
  "Portlani",
  "Portman",
  "PORTMAN DOORS",
  "Portman Doors Ltd",
  "PORTMAN SECURITY SHUTTERS.",
  "PORTSRAMP",
  "POSITIVE CONTROLS",
  "POT BOY",
  "potalp",
  "Potter",
  "POTTER RAX LTD",
  "Pottern",
  "POTTERTON",
  "POTTERTON COMMERCIAL",
  "POTTERTON CONTROLS",
  "Potterton Derwent",
  "Potterton kingfisher",
  "Potterton MYSON",
  "Pottery Crafts",
  "POTTERYCRAFTS LTD",
  "POTTINGTON",
  "POTTS OF LEEDS",
  "Poujoulat",
  "PO-VH",
  "POW",
  "POW LTD",
  "POW Plastics",
  "POW PROCESS",
  "POW PROCESSES OF WATER",
  "POW WOW",
  "Powador",
  "Powec AS",
  "POWELL SYSTEM ENGINEERING",
  "POWELL SYSTEMS ENG./CHAPTER CONTROLS LTD.",
  "POWELL-CHAPTER CONTROLS",
  "POWEMATIC",
  "Power",
  "Power & Control",
  "POWER & CONTROL LTD",
  "Power & Control Solutions",
  "POWER AND CONTROLSOLUTIONS LTD",
  "POWER B",
  "POWER BAR PB",
  "Power Battery",
  "POWER BATTERY COMPANY LTD",
  "Power Best",
  "Power Breaker",
  "POWER CAPACITORS",
  "POWER CAPACITORS LTD",
  "POWER CAPACITORS LTD.",
  "Power Capacitors/Hitachi",
  "Power Cat",
  "POWER CENTRE",
  "POWER CONTROL",
  "POWER CONTROL ENGINEERING",
  "POWER CONTROL LTD",
  "POWER CONTROLLER",
  "POWER DEAT",
  "POWER DEMAND",
  "Power distribution transformers ltd",
  "POWER DRI",
  "POWER ECONOMY",
  "POWER ELECTRICS",
  "POWER ELECTRONICS",
  "POWER EQUIPMENT",
  "Power Flex",
  "POWER FLOW",
  "Power guard",
  "Power Inspired",
  "POWER LIFT",
  "POWER LINE",
  "power logic",
  "Power Mag",
  "Power maite",
  "POWER MASTER",
  "Power Measurement",
  "Power Measurment",
  "power meaurement",
  "POWER MISER",
  "POWER NETWORKS",
  "Power One",
  "POWER PERFECTOR",
  "POWER PLAN",
  "POWER PLANT",
  "power plant services",
  "Power Plec",
  "POWER PLEX",
  "Power -Plex",
  "POWER PLEX INTERNATIONAL",
  "Power plex technologies",
  "POWER PLEX TECHNOLOGIES LTD",
  "POWER PLEX TECHNOLOGY",
  "Power Plus Systems",
  "power pod defender",
  "POWER POINT ELECTRONICS",
  "POWER PREFECTOR",
  "Power Prestige",
  "POWER PRFECTOR",
  "Power Pro UK Ltd",
  "POWER PUMPS",
  "POWER RAIL",
  "POWER RAIL 303",
  "POWER SAFE",
  "POWER SAFE-PLANTE",
  "POWER SAVER LTD",
  "POWER SEAT",
  "POWER SEAT?",
  "POWER SUPPLIES",
  "Power Supply",
  "Power supply / battery",
  "Power Supply Unit",
  "Power Supply Ynit",
  "POWER SUPPPLIES LTD",
  "POWER SYSTEMS",
  "Power Systems LTD",
  "POWER TECHNIQUE",
  "Power tower",
  "POWER TRANSFORMER LTD",
  "Power Transformers",
  "POWER TRANSFORMERS (B'HAM) LTD",
  "POWER TRANSFORMERS (B'HAM) LTD.",
  "POWER TRANSFORMERS B'HAM LTD",
  "POWER TRANSFORMERS BIRMINGHAM LTD",
  "POWER TRANSFORMERS LTD",
  "POWER TRANSFORMERS LTD.",
  "Power Utilities Ltd",
  "POWER VAR",
  "Power Ventilator",
  "POWER WAVE",
  "Power Seat",
  "Power System 200",
  "Power Technique",
  "POWER.",
  "POWERAIL",
  "Poweraire",
  "POWERBAR",
  "POWER-BAR",
  "POWERBOARD",
  "PowerBreaker",
  "POWERCAT",
  "Powercell",
  "powercell classic",
  "POWERCOMM",
  "POWERCOVER SUPPLY LTD",
  "POWERCUBE",
  "Powerdry",
  "POWEREDGE",
  "PowerFlex",
  "Powerflood",
  "POWERGARD",
  "Powergen",
  "POWERGEN ENERGY SOLUTIONS",
  "POWERGEN ITALIA",
  "POWERGEN PROCESSOR",
  "Powergrill",
  "POWERGUARD",
  "POWERHEART AED",
  "POWERLIGHT",
  "Powerline",
  "Powerlink",
  "Powerlite",
  "POWERLITE COMPRESSOR",
  "POWERMAG",
  "POWERMAIN",
  "POWERMAK",
  "POWERMASTER",
  "POWERMATIC",
  "POWERMATIC LIMITED",
  "POWERMATIC LTD",
  "PowerMatics",
  "Powermax",
  "Powerohm",
  "Powerpac 750",
  "Powerplan",
  "POWERPLAN SWITCHES",
  "Powerplex",
  "Power-Plex",
  "Power-plex Technologies",
  "POWERPLUS",
  "PowerPoint",
  "Powerpro",
  "POWERRAIL",
  "Powersafe",
  "Powersaver",
  "Powerscale",
  "Powerseal",
  "Powerseat",
  "POWERSTAR",
  "powerstream",
  "powersure",
  "Powerswing",
  "POWERTEC",
  "POWERTECHNIQUE",
  "POWERTECNIQUE",
  "Powertrol",
  "POWERTRONICS",
  "POWERTRONIX",
  "Powervamp",
  "POWERVAR",
  "Powervent",
  "Powerware",
  "Powerwave",
  "POWERX",
  "POWOBAZ",
  "POWOG",
  "POWOGAS",
  "PoWoGaz",
  "POWOW",
  "POWOWGAZ",
  "Powrmatic",
  "POWRMATIC LTD",
  "POWRMATIC LTD.",
  "POWRMAYIC",
  "POWRTROL",
  "POWWOW",
  "Pozzo-Ginoni",
  "PP Controls",
  "PPL Polution Prevention Division",
  "PPS Controls",
  "PQE",
  "PR GRATINGS LTD",
  "PRACTICAL REFRIGERATION",
  "PRACTICAL REFRIGRATION LTD",
  "Pramac",
  "Pre & Post event recorders",
  "Pre Insulated",
  "Prec",
  "PRECECT",
  "Precept",
  "precision",
  "PRECISION LIFT SERVICES LTD",
  "PRECISION LIFTS LTD",
  "PRECISION REFRIGERATION",
  "PRECISION UK LTD.",
  "PRECOLOR",
  "PRECOLOUR",
  "prefab",
  "prefec",
  "PREISSAG",
  "PREM 1 AIR",
  "prem1er",
  "PREMA",
  "PREMAIR",
  "PREMAIRE",
  "PREMALART ESP",
  "Premdor",
  "PREMERO",
  "Premi",
  "PREMIAIR",
  "Prem-i-air",
  "PREMIAIRE",
  "PREMI-AIRE",
  "Premiair-Nova",
  "Premier",
  "Premier / ADT",
  "PREMIER AIR APPLIANCES LTD",
  "Premier air services",
  "Premier elite 88",
  "PREMIER LABORATORY SYSTEMS",
  "PREMIER SECURE",
  "PREMIER SECURITY",
  "PREMIER SYSTEMS",
  "PREMIER TECHNICAL SERVICES",
  "PREMIER TECHNICAL SERVICES GROUP LTD",
  "PREMIER",
  "PREMIER/SECURE",
  "PREMIERE",
  "PREMIER-SECURE",
  "PREMIS",
  "premium products",
  "PREML",
  "PREMSHIELD",
  "PREMSPEC",
  "Premtec",
  "PRENTO",
  "Prepair",
  "Prepair 180 Battery Conditioner",
  "PREPAIR LTD",
  "PREPAIRED",
  "Prescient",
  "Prescient III",
  "prescold",
  "PRESCOLD-COPELAND",
  "Prescon",
  "Prescor",
  "Presona",
  "Press & svets",
  "PRESS BOOST DIGI CAB",
  "PRESS BOOST DIGI PRESS",
  "PRESSALIT",
  "Presscore",
  "PRESSMAIN",
  "PRESSMAIN PRESURISATION COMPANY LTD",
  "Pressman",
  "PRESSMAN PRESSURISATION CO LTD",
  "PRESSMAN PRESSURISATION COMPANY LTD",
  "Presspak",
  "PRESSUAG FIRE PROTECTION",
  "PRESSURE AIR LTD",
  "Pressurisation Ltd",
  "PRESSVESS",
  "PRESSVESS - AIR DIVISION",
  "Prest Aire",
  "PRESTAIR",
  "PRESTCOLD",
  "PRESTCOLD LTD",
  "PRESTCOLD THEALE BERKS",
  "Prestge",
  "Prestige",
  "PRESTIGE CONTROL SYSTEMS LTD.",
  "Prestige Medical",
  "Prestige Aire Ltd",
  "Presto",
  "PRETCOLD",
  "PREUSSAG",
  "Prevor",
  "PRFROID",
  "PRI LTD",
  "PRI PREMIER",
  "PRI SEC",
  "PRI Winchester",
  "PRICE CASTLE",
  "PRICIPLE",
  "PRILECT",
  "PRIMA",
  "Prima Doors",
  "PRIMAFLOW",
  "Prime",
  "PRIME 500",
  "Prime Lite",
  "PRIME SWITCHGEAR",
  "Prime Switchgear Ltd",
  "Primeline",
  "Primelite",
  "Primero",
  "PRIMETIME",
  "PRIMIER",
  "Priminent Fluid Control",
  "PRIMLINE",
  "Primo",
  "Primus",
  "PRINCE CASTLE",
  "PRINCIPLE LINK",
  "PRINCIPLE-LINK",
  "principl-link",
  "Princple",
  "PRINGLE & CO",
  "Priorclave",
  "PRIS",
  "prism",
  "PRISM POWER LIMITED",
  "PRISM POWER LTD",
  "PRISMA",
  "Pritec",
  "Priva",
  "PRIVA 6.6 TOP CONTROL",
  "Prmeline",
  "Pro",
  "PRO ACTION",
  "Pro Bike",
  "PRO CONSULT",
  "PRO DOOR UK",
  "Pro Elec",
  "pro lite",
  "Pro Marqua",
  "PRO MART MANUFACTURING",
  "PRO TEC",
  "Pro 2000",
  "PROACTION",
  "Probably",
  "Probst",
  "Process heat ltd",
  "PROCESSHEAT",
  "Proclean",
  "Procombi",
  "Procter",
  "PROCTOR",
  "Prodata",
  "Prodis",
  "PRODOOR UK",
  "Pro-Elec",
  "Pro-Electric",
  "PROETEC",
  "PROFESSIONAL CHOICE",
  "Professionals Choice",
  "PROFILE",
  "PROFILE 1",
  "PROFILE INDUSTRIAL DOORS",
  "profroid",
  "PROGENT",
  "PROGENY",
  "PROGENY ACCESS CONTROL",
  "Progeny Assess control Systems",
  "Progny",
  "PROGRAM ELECTRONIC",
  "PROGRESSIVE CONTROL SYSTEMS",
  "PROJECT & DESIGN CO. LTD.",
  "Project Fire Products",
  "PROJECT FIRE PRODUCTS LTD",
  "Projection Design",
  "Prol Lite",
  "Prolec",
  "PROLEX",
  "ProliCosmetel",
  "Proline",
  "Pro-Line",
  "PROLINE (KESA UK)",
  "PROLINE-PETRRREINS",
  "Prolite",
  "PROLOINE",
  "PROMAIGA",
  "Promart",
  "PROMASTER",
  "PROMAT",
  "Promax",
  "PROMINENT",
  "ProMinent Fluid Controls",
  "PROMOTAL",
  "PROMOTION",
  "PROMSTAHL",
  "PRONCE CASTLE",
  "PRONOMAR",
  "Pronova",
  "PROS CON PROCESS & CONTROL SOLUTIONS",
  "PROSAFE",
  "PROSAW",
  "protc",
  "Protec",
  "PROTEC ALGO",
  "PROTEC FIRE DETECTION PLC",
  "PROTEC FIREWORKS",
  "PROTEC/  PAC",
  "PROTEC/ HONEY WELL",
  "PROTEC/ HONEYWELL",
  "PROTEC/HONEYWELL",
  "PROTEC/PAC",
  "Protech",
  "PROTECH ALARMS",
  "PROTECH PRESSURISATION",
  "PROTECK",
  "PROTECT",
  "Protect alarm system",
  "Protecta",
  "Protecta Int",
  "PROTECTION ALARMS LTD.",
  "Protection Scotland",
  "protective environments ltd",
  "Protector",
  "Protector Alarm",
  "Protector Alarms",
  "Protector Alarms (UK) Ltd",
  "Protector Technologies",
  "Protectowire",
  "Protecus",
  "PROTEIS",
  "Protek",
  "Protes",
  "PROTEUS",
  "PROTEUS CENTAUR",
  "Proteus Telford",
  "Protex",
  "PROTHERM",
  "PROTIUS",
  "PROTON",
  "PROVEN AIR",
  "Proven Energy",
  "PROVITA",
  "PROVITA MEDICAL",
  "PROVOICE",
  "Prox",
  "Prox Planmeca",
  "PRS",
  "PRT",
  "prudung",
  "prufung",
  "Pryco",
  "PS LIGHTING",
  "PS&T",
  "PSB",
  "PSG",
  "PSI",
  "PSI PRESSURISATION",
  "PSI SERVICES",
  "PSU",
  "PT",
  "PTC",
  "PTF",
  "Ptotecta / Absorber",
  "Ptroeus",
  "PTSG",
  "PUCKERING LIFTS",
  "PUDSEY DIAMOND",
  "PUIPRESS",
  "Puko",
  "PULDEN",
  "PULLEN",
  "PULLEN / HOMA",
  "PULLEN PUMPS LTD",
  "PULLENS",
  "PULLIN",
  "PULLPRESS",
  "PULS",
  "PULSAIR",
  "Pulsar",
  "Pulsonic technologies ltd",
  "Puma",
  "PUMA MANUFACTURING LTD",
  "PUMA POWER",
  "PUMA POWER PLANT",
  "PUMA POWERPLANT",
  "PUMA PRODUCTS LTD",
  "PUMAFE3505MK3",
  "Pump action limited",
  "Pump House",
  "PUMP NOT IN SITUE",
  "Pump Removed",
  "PUMP SERVICE & TECHNOLOGY",
  "Pump Services",
  "PUMP SUPPLY & REPAIR GROUP",
  "Pump Technical",
  "Pump Technical Services",
  "PUMP TECHNOLOGY",
  "PUMP TECHNOLOGY LIMITED",
  "PUMP TECHNOLOGY LTD",
  "PUMP TECHNOLOGY LTD.",
  "PUMP WISE LTD",
  "PUMP WORKS",
  "PUMPENFABINK ERNST VOGEL",
  "Pumphouse",
  "Pumps & motors uk ltd",
  "PUMPS LTD",
  "Pumps UK",
  "PUMPS UK LTD",
  "PUMPS UK LTD.",
  "Pumpserv",
  "PUMPTEC",
  "Pumptronics",
  "PUP TECHNOLOGY",
  "PUPE SOLUTIONS",
  "PUR",
  "Pur1te",
  "Purafilters",
  "PUR-AIT",
  "PURCELL RADIO SYSTEMS",
  "Pure Clean",
  "PURE COLD LTD",
  "PURE STORE LTD",
  "pure water",
  "PURE WELL",
  "Pure Group",
  "Pureaire",
  "Purelab",
  "Purewater",
  "PUREWATER STORAGE LTD.",
  "Purewell Hamworthy",
  "Purex",
  "PURIFIED AIR",
  "PURITE",
  "PURO",
  "Puroflus",
  "PURPOSE BUILT UNIT",
  "PUW",
  "Pv test man",
  "PVR PRODUCTS",
  "PWG",
  "PWRMATIC",
  "PYE",
  "Pyramid",
  "PYRAMID POLLUTION PREVENTION",
  "Pyramis",
  "PYRAMS",
  "Pyro-Chem",
  "PYRONIX",
  "PYROTEC",
  "Q Fan",
  "q m systems",
  "Q.E.S. Electrical",
  "QA",
  "QANS",
  "QC",
  "Q-Connect",
  "QCR",
  "qewrewrewr",
  "QFS",
  "QILIVE",
  "Qm GROUP",
  "QN",
  "QOU-VADIS",
  "QSC",
  "QUADRANT LIFTS",
  "Quadrant research and Development",
  "QUADRO",
  "QUADRO K",
  "QUADROK",
  "Qualcast",
  "QUALE ENGINEERING",
  "QUALE ENGINEERING LTD",
  "Quali Filtres",
  "QUALIAIR",
  "QUALITAIR",
  "QUALITAIR (IMI)",
  "Qualitaire",
  "QUALITY AIR",
  "QUALITYAIR",
  "qualtair",
  "Qualter & Smith",
  "QUANTAMETER",
  "QUANTEC",
  "Quantec C-Tec",
  "QUANTIMETER",
  "quanto meter",
  "quantom",
  "Quantometer",
  "QUANTRON SYSTEMS LTD",
  "Quantum",
  "Quantz",
  "Quarok",
  "Quart2",
  "QUARTS LTS",
  "quartz",
  "quartz electronic",
  "QUARTZ HEATING SYSTEMS",
  "Quartz Ltd",
  "QUARTZ LTS",
  "QUARTZ SAPPHIRE",
  "Quartzheat",
  "QUASYSTEMS",
  "Quattro",
  "Quatum",
  "QUAUNTUM",
  "Quax",
  "QUBE",
  "queen",
  "Quench",
  "Quest",
  "QUEST II",
  "QUEUE SYSTEMS INC",
  "QUICK BUILD",
  "QUICKCHEF",
  "Quick-dri",
  "Quickline",
  "QUICKZONE",
  "Quincy",
  "QUIPO",
  "QUIREPACE",
  "QUIS  &  DELL",
  "Quiteaire",
  "Quntometer",
  "Quo Vardis",
  "QUOCKER",
  "QUONTOMETER",
  "QUOOKER",
  "QVIS",
  "QVR",
  "QWERTY",
  "QWIK GUARD",
  "QWS",
  "QX TAN",
  "R & B CONTROL SERVICES",
  "R & M",
  "R & S",
  "R & T Engineering",
  "R A Webber Ltd",
  "R AUSMIX",
  "R Baker",
  "R BAKER ELECTRICAL",
  "R BAKER KTD",
  "R BAKER LTD",
  "R BAKR ELECTRUCAL",
  "R C GROUP",
  "R F Winder",
  "R G GROUP",
  "R G ROBLAND",
  "R H HALL",
  "R&B SWITCHGEAR",
  "R&G SIMPSON",
  "R&N",
  "R&S",
  "R&S BUILDING SERVICES LTD",
  "R&S Fire & Security",
  "R. E. DEW & CO. LTD",
  "R. S.",
  "R.B",
  "r.b.l",
  "R.B.L.",
  "R.BAKER LTD",
  "R.C.GIBSON",
  "R.e. dew",
  "R.E. DEW & CO LTD.",
  "R.E.DEW & CO.LTD.",
  "R.E.S. GROUP",
  "R.F.WiNDER Electrical Limited",
  "R.S.",
  "R.S. White",
  "R.W.CURLE",
  "R.X",
  "R+D Coldstore",
  "R1 Electronic",
  "R1ELLO",
  "R5",
  "RA",
  "Raab karcher",
  "rab karcher clorius",
  "RAC",
  "RACAL",
  "RACHEM",
  "RACOMEC",
  "Racroft",
  "Rada",
  "RADCLIFFE-THE AUTOMATIC SPRINKLER Co. LTD.",
  "RADCON",
  "Radford",
  "RADIAL AND AXIAL",
  "RADIAL AXIAL",
  "RADIAL BLADE",
  "RADIALAX LTD.",
  "Radian",
  "RADIANCE",
  "RADIANT",
  "radio design",
  "Radio Rentals",
  "RADIO TELESWITCH",
  "Radiovox Ltd",
  "RADO",
  "RADON",
  "RADON-AWAY",
  "RADWOOD",
  "RAFIKI",
  "Rail",
  "RAINBOW WATER SERVICES",
  "RAINFORD GROUP",
  "Rainham",
  "RAINHAM REFRIGERATION & AIR CONDITIONING LTD",
  "Rainharvesting Systems",
  "RAINSTREAM CONTROL PANEL",
  "Rakt/Woods",
  "Ramsay",
  "RAMSAY AND HOLMES",
  "Ramsay Holmes",
  "RAMSDALE SYSTEM CONTROL",
  "RAMSDALE SYSTEM DESIGN",
  "Ramsdale Control Panel",
  "RAMSEY LADDERS",
  "Ramtech",
  "RANBY",
  "RANCHO",
  "Rancilio",
  "RANCO",
  "Rand equipment",
  "Randall",
  "RANDALLS",
  "RANDEBERG",
  "Randell",
  "RANGE",
  "Range Master",
  "Range Tribune",
  "Rangemaster",
  "RANGER",
  "Rank Strand",
  "RAO Controls Ltd",
  "RAOIDZAP",
  "Rapak",
  "RAPID",
  "RAPID CLIMA",
  "RAPID INDUSTRIAL DOOR",
  "RAPID protection",
  "RAPIDE",
  "Rapide by VES Andover",
  "Rapidor",
  "rapidrop",
  "RAPIDROP UK",
  "Rapidscan",
  "RAPIDZAP",
  "Rapiscan",
  "RAPISCAN SYSTEMS",
  "Rapistan",
  "RAPPEL",
  "RAPPEL ENGINEERING",
  "RAPPEL LTD",
  "RAPPELL",
  "Rappell Switchgear",
  "RAPPELL SWITCHGEAR LTD",
  "Rapscan Security Products",
  "RASCAL",
  "RASDALE",
  "RASSPE",
  "Rational",
  "Rational Tech",
  "Rationalle",
  "RATNER",
  "RATON",
  "RATON MEM",
  "Ratonal",
  "rauchabzug",
  "ravaglioli",
  "Ravenheat",
  "RAVEX",
  "RAVI",
  "RAVTEL",
  "RAX SHUTTER",
  "Ray",
  "Ray Cole Doors",
  "Ray O Vac",
  "Rayburn",
  "Raychem",
  "Raychem HWAT-ECO",
  "RAYCHEM/ELTRACE",
  "Raycroft",
  "Raydon",
  "RAYFIELD ENGINEERING Co LTD",
  "Rayland (M/C)LTD",
  "RAYLEIGH",
  "Rayleigh Instruments",
  "Rayleigh Instruments Ltd.",
  "RayPack",
  "RayPak",
  "Rayrolle",
  "Raysay",
  "RAYSIL",
  "Raystat",
  "Raytec",
  "Raytek",
  "Raytel",
  "RAYTEL SECURITY SYSTEMS",
  "RAYTHERM",
  "Raytrace",
  "razberi",
  "Raznor",
  "RB BAKER",
  "RB EVANS",
  "RBH",
  "RBIK",
  "RBL",
  "RBMS",
  "Rbs test man",
  "RC GIBSON",
  "RC GIBSON& CO",
  "RC GROUP",
  "RCD Composite",
  "RCF",
  "RCM",
  "RCR",
  "Rd meters",
  "RD Power Ltd",
  "RD VENTILATION SYSTEMS",
  "RDL",
  "RDL GREEN BROOK",
  "RDL GREEN BROOK ELECTRICAL PLC",
  "RDL METERS",
  "RDL PAIGNTON",
  "RDM",
  "RDM DATA MANAGER",
  "RDMI",
  "RDN",
  "RDW",
  "RE DEW BUILDING & ENERGY MANAGEMENT SOLUTIONS",
  "RE RIDGE",
  "REA",
  "REACTIVE DOORS",
  "Reading",
  "Real",
  "Real system controls",
  "Real Systems Control",
  "REAL SYSTEMS LTD",
  "REAL WATCH",
  "REAL WATCH PANEL",
  "Reallo",
  "REALM",
  "Rebel",
  "Rebus",
  "RECANATI - ITALY",
  "Recanati _ Italy",
  "RECEPTACLE",
  "RECLAIRE",
  "RECO",
  "RECO-AIR",
  "Recon Air",
  "Record",
  "Record Power",
  "RECOURCE",
  "recupovent",
  "RECUTECH",
  "Red",
  "Red Alert",
  "Red Alert 2",
  "Red Alert Security Technology",
  "RED ALERT SYSTEM",
  "Red Arrow Trading",
  "Red Care Adpro",
  "RED HOT",
  "Red Jacket",
  "RED LION",
  "RED LON",
  "RED ONE",
  "RED OT",
  "Red Slert",
  "red spot",
  "RED SPOT GEC",
  "Red Spot",
  "RedAlert",
  "REDASHE",
  "Redbro",
  "Redding Xpelair Group Ltd",
  "Redhill",
  "REDIFFUSION",
  "REDIFFUSION SIMULATION LTD.",
  "REDIFFUSION SIMULATOON LTD.",
  "REDIFUSION",
  "Redline",
  "Redmal Pneumatic",
  "REDNA PNEUMATICS",
  "rednal",
  "Rednal Pmeumatics UK",
  "Rednal pneumatices Ltd",
  "REDNAL PNEUMATICS",
  "Rednal Pneumatics - GB",
  "REDNAL PNEUMATICS - UK",
  "REDNAL PNUEMATICS",
  "Redone",
  "REDR",
  "Redrinf",
  "REDRING",
  "Redring vortex",
  "REDSPOT",
  "REDSPOT  GEC",
  "Redwall",
  "REDWALL ADT",
  "REDWING",
  "Redwood Pumps",
  "Reebok",
  "Reed Ring",
  "REEF",
  "Reef  Water",
  "Reef Water Systems",
  "REEL",
  "Reelcare",
  "reeline",
  "REES",
  "REES SWITCHGEAR",
  "REES SWITCHGEAR LTD",
  "REES SWITCHGEAR LTD.",
  "Refac BV",
  "Refcon",
  "REFCON MANUFACTURING",
  "Refix",
  "Refix de",
  "reflex",
  "REFLEX NG",
  "REFLEX WINKELMANN GMBH",
  "reflex.",
  "Reflex-N",
  "REFLRX",
  "REFLUX",
  "REFLX",
  "Reforce",
  "Refresh",
  "Refresh U",
  "REFRESH WATER",
  "REFRIGERATION PROFESSIONAL",
  "REFUGE ALERTER",
  "REFURBISHER ATLANTIC TANK",
  "REGA VENTILATION",
  "REGAL FANS LTD",
  "REGAVENT",
  "regency",
  "REGENCY SERIES 1",
  "REGENCY SERIES 2",
  "REGENT",
  "Reggiani",
  "regin",
  "Regspec",
  "REHAU",
  "REHEMA",
  "REHMEHA B.V.",
  "Reid lifting limited",
  "Reillo",
  "reillo burners",
  "REILO BURNERS",
  "REINHARD KRUGER KUNSTSTOFFBAU GmbH",
  "Reintec",
  "REITSCHIE THOMAS",
  "REL REFRIGERATION GROUP",
  "Relax gla",
  "Relcross",
  "RELCROSS LTD",
  "RELEC",
  "Relhor",
  "RELIABLE",
  "Reliable Automatic",
  "RELIABLE AUTOMATIC SPRINKLER CO LTD",
  "Reliable automatic sprinkler Co NY",
  "RELIABLE AUTOMATIC SPRINKLER CO. INC.",
  "Reliable Pre Action Valve",
  "RELIANCE",
  "Reliance Water Control",
  "RELIANCE WATER CONTROLS",
  "Reliance worldwide",
  "Remak",
  "REMCO",
  "Remega",
  "REMEHA",
  "REMEHA (BROAG LTD)",
  "REMEHA ACANTA",
  "remeha avanta",
  "Remeha Broga",
  "Remflex",
  "Remha",
  "Remington",
  "remote device manager",
  "remote devive manager",
  "REMOVED",
  "REMSDAQ STARNET",
  "Rendal pneumatics Ltd",
  "RENDAMAX",
  "Rendamex",
  "RENTALILL",
  "RENTOKIL",
  "RENTOKIL'",
  "Renzacci",
  "Renzor",
  "REPAIR LTD LUTON",
  "REPONSE",
  "Reporter",
  "Reptile",
  "REROTH",
  "RESIDUE",
  "RESISTENCIA HEATING",
  "Resitra",
  "Resoc",
  "RESOL",
  "RESOURCE",
  "Resource Data Management",
  "Responce",
  "Response",
  "Response Co",
  "Response Company",
  "Response Company Ltd",
  "Resstencia Heating",
  "Rest safe",
  "Restsafe",
  "Reta",
  "RETCHAB",
  "RETIGO",
  "Retro",
  "Reval",
  "REVALCO",
  "REVCO TECHNOLOGIES",
  "Revenco",
  "Revnor",
  "REVROLLE",
  "Reward Brand",
  "REX",
  "Rexel",
  "REXROTH",
  "REX-ROYAL",
  "Rey Chem",
  "REYCHEM",
  "REYCOL",
  "Reyroll",
  "Reyrolle",
  "Reznor",
  "REZNOR UK LTD",
  "RFF190GB",
  "rfh fans",
  "RFX",
  "RG",
  "RH IRVING INDUSTRIALS LTD",
  "RHEAVENDORS GROUP",
  "Rheem",
  "Rheem Ruud",
  "RHEEMGLAS",
  "RHF",
  "RHF Fans",
  "RHF Fans Limited",
  "RHF FANS LTD",
  "RHINO",
  "RHINO CHEF",
  "RHINO DOIRS",
  "Rhino Door",
  "rhino doors",
  "RHINO DOORS EUROPEAN PROFILES LTD",
  "Rhino Engineering Ltd",
  "RHINO EQUIPMENT",
  "Rhinowash",
  "Rhoss",
  "RHP FANS",
  "Ri",
  "RI YE ELECTRIC MACHINERY CO. LTD.",
  "RIB",
  "RIBBLE ENVIRO LTD.",
  "Ricelake Weighing System",
  "RICHARD MOZLEY LTD",
  "RICHARD VANSPALL ASSOCIATES LTD",
  "Richard wilson",
  "RICHARD WILSON (DENCOL) LTD",
  "Richard Wilson Dencok",
  "RICHARD WILSON DENCOL",
  "RICHARD WILSON LTD",
  "RICHARDS OF HULL",
  "Richmond Consul",
  "Richo",
  "Ricoh",
  "Ridder",
  "RIDGE ENGINEERING CO. LTD.",
  "Ridge Engineering Ltd",
  "Rieber",
  "RIELLO",
  "RIELLO 40",
  "Riello Legivago",
  "RIELLO LTD",
  "Riello LTD.",
  "RIELLO UPS",
  "RIELLO-040-GS20",
  "RIEMA",
  "RIETSCHIE",
  "Rietschle",
  "RIETSCHLE THOMAS",
  "Rig 542",
  "RIGBY ELECTRICAL",
  "RIGD MEC",
  "rigert Ltd",
  "Rigid",
  "RIJO",
  "RIJO 42",
  "Riley IC products ltd",
  "Rimer Berlec",
  "RINAI",
  "Ring",
  "RING GAURD",
  "RING MASTER",
  "RING-GARD",
  "RINGGRIP",
  "RINGMASTER",
  "Ringrose",
  "Ringtail",
  "RINGTAIL CS8M",
  "RINNAI",
  "RINNAI ENERGYSAVER",
  "RINNAL CORPORATION.",
  "RINNI",
  "RINOX",
  "Rio",
  "Rionte",
  "RIOT",
  "Riovar",
  "RISCO",
  "Rital",
  "Rital New Wave",
  "Ritchie Hart",
  "RITCHIE MACKENZIE CO LTD",
  "RITCHIE MACKENZIE LTD",
  "RITE-VENT LIMITED",
  "RITHERDON",
  "Ritmac",
  "RITTAL",
  "Rittal/Trend",
  "rittel",
  "RITZ NEW HADEN",
  "Ritzenthaler",
  "RIVA COLD",
  "RivacoldUk",
  "Riverford Control Systems",
  "RJH",
  "RJL",
  "RJM",
  "RJS",
  "RJW",
  "RL",
  "RLD",
  "RLE Techologies",
  "RLSTER",
  "RLX",
  "RM CYLINDER LTD",
  "RM Cylinders",
  "Rm Solar",
  "RMC",
  "RMG",
  "RMG Messtechnik GmbH",
  "RMHRMH",
  "RMJ CONSULTANCY LTD",
  "RMS",
  "RMS INDUSTRIAL DOO SUPPLIES",
  "RMS Industrial Door Services",
  "RMS INDUSTRIAL DOORS",
  "RMTD-8004CE",
  "rnid",
  "Ro killer",
  "Roband",
  "ROBATHERM",
  "ROBBINS MYER LTD",
  "ROBELL",
  "ROBER BOSCH HAUSERGERÄTE GmBH",
  "ROBERT & GORDON",
  "ROBERT BOSCH HAUSERGERÄTE GmBH",
  "ROBERT BOSH",
  "Robert C Scott",
  "ROBERT C SCOTT LIMITED",
  "robert c scott ltd",
  "Robert C Scutt",
  "ROBERT C SCUTT LTD",
  "ROBERT C. SCUTT LTD",
  "ROBERT C. SCUTT LTD.",
  "ROBERT GORDON COMBAT",
  "ROBERT SCOTT",
  "ROBERT VOUGUE",
  "Roberts",
  "ROBERTS GORDON",
  "ROBERTS GORDON CONTROLLER",
  "RobertsGordon",
  "Robertshaw",
  "Robertshaw Controls",
  "Robin",
  "Robinson Willey",
  "Robinsons",
  "RoboCoupe",
  "ROBOT CHEF",
  "ROBOT COIPE",
  "Robot Coup",
  "ROBOT COUPE",
  "Robot Couple",
  "ROBOTOCOUPE",
  "robson conveyor ltd",
  "ROBUR",
  "ROBUS",
  "robust",
  "ROBUST UK",
  "Roca",
  "Roccheggiani",
  "ROCHE",
  "ROCHE VENTILATION SERVICES",
  "ROCKET",
  "Rockweld Ltd",
  "Rockwell/V Tech",
  "ROCON",
  "rodelk chimneys ltd",
  "Roderick",
  "RODGE",
  "Ro-Door",
  "RODWELL",
  "Roebuck",
  "ROFLEX DOORS LTD",
  "ROFO",
  "Roger & Russell",
  "Roger Black",
  "Roger technology",
  "Rohill",
  "ROHIS",
  "rohrsystem",
  "ROHS",
  "Rointe",
  "ROKILL",
  "ROL",
  "Rolac",
  "ROLAND V. TETLOW",
  "ROLEC",
  "ROLL OVER",
  "ROLLA LTD",
  "Rollason",
  "ROLLER",
  "ROLLER GRILL",
  "ROLLER GRILL INTERNATIONAL",
  "ROLLER SHUTTER DOORS LTD BIRMINGHAM",
  "ROLLER SHUTTERS DIRECT",
  "ROLLERTEC",
  "ROLLS ROYCE",
  "ROLLWRIGHT",
  "Rolston",
  "ROL-TRAC",
  "ROM Olasrics",
  "ROM Plastics",
  "ROMAEC",
  "ROMAN",
  "Roman system",
  "ROMARSH LTD.",
  "ROMEC",
  "Romec ?",
  "Romec branded",
  "Romec Electronic Service Manchester",
  "ROMEC ELECTRONIC SERVICES MANCHESTER",
  "Romec Security",
  "romer",
  "ROMET",
  "Romet Ltd",
  "ROMET ROTARY METER",
  "ROMFORD INDUSTRIAL DOORS",
  "ROMRSYSTEM TECHNIK",
  "RONALD TRIST CONTROLS LTD",
  "Ronald Trist Ltd",
  "RONDO",
  "ROOF GROUP UNITS",
  "ROOF INITS GROUP",
  "ROOF SAFE ANCHORS",
  "ROOF UNIT GROUP",
  "Roof Unit Groups",
  "ROOF UNITS GROUP",
  "ROOF UNITS GROUP LTD",
  "ROOFING UNIT",
  "Roofsafe",
  "ROOFTOP GROUP",
  "ROON EDAM",
  "ROOR UNITS GROUP",
  "ROOTS",
  "Roots Meter",
  "ROOTS METERS",
  "ROOTS METERS & INSTRUMENT DRESSER INC.",
  "Roper Rhodes",
  "Ropox",
  "ROSEBERY CONTROLS",
  "Rosemex",
  "ROSENBERG",
  "Rosenberg GMBH",
  "ROSENBERG-COLT",
  "ROSENGRENS",
  "ROSIERES",
  "ROSINOX",
  "ROSS & BONNYMAN",
  "ROSS ARCHITECTRUAL",
  "ROSS ARCHITECTURAL",
  "ROSSE SYSTEMS",
  "ROSSELLA",
  "ROSSENDALE GROUP",
  "Rosser & Russell",
  "ROSSER & RUSSELL LTD.",
  "ROSSER RUSSELL",
  "ROSSER RUSSELL LTD.",
  "ROSSI MOTORIDUTTORI",
  "Roster",
  "ROTA",
  "ROTA LTD",
  "rotan",
  "Rotan pump",
  "Rotary",
  "Rotary Equipment Services Ltd",
  "ROTARY MOTION",
  "ROTARY PUMP EH1200",
  "ROTARY SOUTHERN",
  "ROTCE THOMPSON",
  "ROTEX",
  "Roth band",
  "Rothband",
  "ROTHER BOILER",
  "Rother Boiler Co",
  "ROTHER BOILER CO LTD",
  "ROTHER BOILER CO LTD.",
  "ROTHER BOILER CO. LTD.",
  "ROTHER BOILER LTD",
  "ROTHER BOILERS",
  "ROTHER BOLER CO",
  "Rothwell",
  "ROTO PUMP",
  "ROTOMAG",
  "ROTOR",
  "ROTORK",
  "ROTORK ACTUATION",
  "Rototherm",
  "ROTRONIC LOGISTICS",
  "Rottenmann",
  "ROUND UP",
  "ROUNDUP",
  "Rovacabin",
  "ROW REVERSE",
  "ROWA",
  "Rowland Ruttle",
  "ROWLEET RUTLAND",
  "Rowleit",
  "ROWLET RUTLAND",
  "ROWLETT",
  "ROWLETT AND RUTLAND",
  "ROWLETT RUTLAND",
  "Rowlett Rutland LTD",
  "Royal",
  "Royal Doulton",
  "ROYAL INDUSTRIAL DOORS",
  "Royal mail",
  "Royal Venton",
  "ROYALE",
  "ROYCE THOMPSON",
  "ROYCE THOMPSON CONTROLS",
  "ROYCE THOMPSON ELECTRIC LIMITED",
  "ROYCE THOMPSON ELECTRIC LTD",
  "ROYCE THOMPSON ELECTRICAL LIMITED",
  "ROYCE THOMPSON ELECTRICAL LTE",
  "ROYCE THOMPSON LTD",
  "ROYLES",
  "ROYLES ENGINEERS LTD.",
  "ROYLES LTD",
  "ROYLES LTD.",
  "Royston",
  "Royston Diesel Power",
  "RP",
  "RP&C Valve",
  "RPS",
  "RRINGTAIL",
  "RRP",
  "RS",
  "RS COMPONENTS",
  "RS.",
  "RSI",
  "RSR",
  "RST",
  "RTC",
  "RTE",
  "RTE Aerovox",
  "RTE LTD",
  "R-TECH LTD",
  "RTJ Solutions",
  "RTL",
  "RTM",
  "RTS",
  "RUB",
  "Rubax",
  "RUBBERMAID",
  "Rubex Lifts Ltd",
  "Ruck",
  "Rudert",
  "RUELLO",
  "RUKY",
  "RUKY S.R.L.",
  "RULLET",
  "rundum",
  "RUNNING MAN",
  "RUNNING WATER INC",
  "RUNWAY ENGINEERING LTD",
  "RUNWELL",
  "RUSKIN AIR MANUFACTURING",
  "RUSSEL",
  "Russel Hobs",
  "Russel Hobbs",
  "Russelectric",
  "RUSSELL",
  "RUSSELL FINEX",
  "RUSSELL HIBBS",
  "RUSSELL HOBBS",
  "RUSSELL HOBS",
  "RUSSELL REFRIGERATION",
  "Russell Hobbs",
  "RUSSELLHOBB",
  "RUSSSELL REFRIGERATION",
  "RUSTON BOILERS",
  "Rutec",
  "RUUD",
  "RVIK",
  "RVR",
  "RVS",
  "RWC",
  "RWD",
  "RWE PILLER GmbH",
  "RWLETT RUTLAND",
  "RWS",
  "RXG",
  "RXG Ltd",
  "RXPRESS SHUTTERS",
  "rxs50j2v1b",
  "RYCO",
  "RYCO. RYCROFT LTD",
  "Rycon",
  "RYCROF LTD",
  "RYCROFT",
  "RYCROFT CALORIFIERS LTD.",
  "RYCROFT COMPAC INSTANEOUS WATER HEATERS",
  "RYCROFT LTD",
  "RYECROFT",
  "RYEFIELD",
  "RYFIELD ENGINEERING CO. LTD.",
  "RYMECH",
  "RYOBI",
  "S",
  "S & C Electric",
  "S & G Worthington",
  "S & P",
  "S & S",
  "S & S NORTHERN",
  "S & S SOUTHERN",
  "S G & M WORTHINGTON",
  "S G& M WORTHINGTON &CO",
  "S H CONTROL PANELS LTD",
  "S H CONTROLS LTD",
  "S IDEAL CONCORDE",
  "S Type",
  "S&C",
  "S&C NORTHERN",
  "s&ca",
  "S&D",
  "S&D extraction",
  "S&P",
  "S&P Coil products",
  "S&P COIL PRODUCTS LIMITED.",
  "S&P UNELVENT",
  "s&s",
  "S&S Northern",
  "S. G. & M WORTHINGTON",
  "S. M. SERVICES",
  "S. Stephenson ltd",
  "S. T. CONTROL SERVICES LTD",
  "S.C. Pillinger",
  "S.C.I.E",
  "S.E.Controls",
  "S.E.S Bedroom",
  "S.F.S FIRE ENGINEERING",
  "S.F.S. FIRE ENGINEERING",
  "S.G & M WORTHINGTON",
  "S.G WORTHINGTON",
  "S.G. & M WORTHINGTON & CO",
  "S.I.M.E.D.",
  "S.P.E.",
  "S.T.D.M LTD",
  "S/G",
  "S/Steel",
  "S/Steel Rite Vent",
  "S/Systems",
  "S+B",
  "S+H Nokting",
  "S+H NOLTING",
  "S0COMEC",
  "S19XB",
  "S4S",
  "S5",
  "SA Industrial Controls Equipment",
  "SAACKE",
  "SAAKE",
  "Sabiana",
  "SABIEM",
  "Sabien",
  "Sabre",
  "Sabre Channel",
  "SABROE",
  "Sace",
  "SACFOR",
  "SACOMEC",
  "Sadia",
  "SADIA REFRIGERATION",
  "SADIA WATER",
  "SADIA WATER HEATERS",
  "Sadia Water Heaters Ltd.",
  "sadis",
  "SADWONBONG",
  "Saeco",
  "SAFA CARE",
  "Safapac",
  "SAFARM",
  "SAFE",
  "SAFE & SOUND CONTROL SYSTEMS",
  "SAFE & SOUND CONTROL SYSTEMS LTD",
  "SAFE & SOUND CONTROL SYSTEMS LTD.",
  "SAFE AND SOUND CONTROL SYSTEMS",
  "safe clip",
  "SAFE LINK",
  "Safe Power Systems",
  "safeclip",
  "SAFECLIP GEC",
  "Safe-door International Ltd",
  "SAFEGUARD",
  "Safeguard Systems",
  "Safeguardlite",
  "safelab",
  "SAFELEC LTD",
  "Safelift",
  "SAFELINE",
  "SAFELITE",
  "SAFESITE",
  "SafeTeam",
  "Safetech",
  "safetek",
  "SAFETELL",
  "SAFETY",
  "Safety Fire",
  "SAFETY FIRE 4 ZONE",
  "safety gas detection",
  "Safety Kleen",
  "Safety Showers",
  "SafetyKleen",
  "Saffire",
  "SAFI",
  "Safir",
  "Saft",
  "Saftair Induustries",
  "Saftronics",
  "SAFTYCLEAN",
  "Safyre",
  "SAGAMO WESTON LTD",
  "SAGEM",
  "SAGEM/OZAK",
  "SAGI",
  "SAGICOFIM",
  "Sahara",
  "SAIF",
  "SAILAIR",
  "SAIMA SICUREZZA",
  "Sainsburies",
  "SAINSBURYS",
  "Sainsbury's Supermarkets Ltd",
  "Saip",
  "Saiver",
  "Sakura",
  "SAL",
  "SALA",
  "Salamander",
  "SALAMANDER PUMPS",
  "SALDA",
  "SALE",
  "Sale engineering",
  "sale engineering oriducts",
  "SALE ENGINEERING PRODUCTS",
  "Sale Engineering Products Ltd",
  "Salicru",
  "SALISBURY UK",
  "SALIVATE",
  "salivent",
  "SALMSON",
  "SALOPIAN",
  "Salto",
  "SALUS",
  "Salvajor",
  "SALZER",
  "SAMA",
  "SAMAS",
  "Samba",
  "SAME",
  "SAMEGAS",
  "Samet",
  "SAMGAMO ELECTRICITY METERING",
  "Samgamo Wilson",
  "SAMGAS",
  "Sami Power",
  "Samil Power",
  "Sammic",
  "SAMMIC S.L.",
  "Sammic SL",
  "Samoa",
  "Samp",
  "samson",
  "SAMSON CONTROLS",
  "Samsung",
  "SAMSUNG  CISCO",
  'samsung 55"',
  "samsung domestic",
  "SAMSUNG DVD RECORDER",
  "SAMSUNG HEAT PUMP MULTI SPLIT",
  "samsunhg",
  "SAMTON",
  "samuel james",
  "samuel james engineering",
  "Samuel James Engineering Ltd",
  "samuel james ltd",
  "Samuel Janes Engineering",
  "SAMYO",
  "SAn Ace",
  "San Giorgio",
  "San Remo",
  "San Giorgio",
  "SANAMAX",
  "Sandaclad",
  "SANDAMAX",
  "Sandasteel",
  "SANDERMAX",
  "Sanders",
  "Sanders Wednesbury",
  "SANDERS WEDNESDAY",
  "SANDFORD CONTROL SYSTEMS LTS",
  "Sandiacre Electrics Limited.",
  "SANDIMAX",
  "Sandon",
  "Sandstrom",
  "Sanford Weston",
  "Sanfrost",
  "SANGAMO",
  "Sangamo ???",
  "SANGAMO ELECTRIC METERING",
  "SANGAMO electricity metering",
  "Sangamo Metering",
  "Sangamo Schlumberger",
  "SANGAMO WEST0NNLTD",
  "SANGAMO WESTERN",
  "Sangamo Weston Ltd",
  "SANGAMO WESTON LTD.",
  "Sangamo?",
  "sanggjorgio pra",
  "Sangiorgio",
  "SANGIORGIO MEGALUX 90",
  "SANGIORIO PRA",
  "SANGORGIO",
  "Sangsung",
  "Sani",
  "SANI BROYEUR",
  "SANI PLUS",
  "sani pump",
  "sani shower",
  "SANI SLIM",
  "SANI TOP",
  "Sani Vite",
  "SANIBEST",
  "SANIBEST PRO",
  "SANIBROYEUR",
  "SANICON",
  "SANIFL0O 3",
  "SANIFLO",
  "SANIFLO LTD",
  "SANIFLO LTD.",
  "SANIFORM",
  "SAN-I-ICE",
  "Sani-Lav",
  "SANILSV",
  "Sanipack",
  "SANI-PRO-XR",
  "SANIPUMP",
  "SANISHOWER",
  "SANISOLUTIONS",
  "SANISPEED",
  "Sanistrel",
  "SANITOP",
  "Sanitop Saniflow",
  "SANIVET",
  "SANIVITE",
  "Sanivite iii",
  "SANIVITE3",
  "SANIVSHOWER",
  "SANKEY",
  "Sanmic",
  "Sansotec",
  "SANSUNG",
  "Santen",
  "SANTERNO",
  "SANTIN",
  "SANTO",
  "SANTO / APV Anda",
  "SANTON",
  "Santon Aquarius",
  "SANTON ELECTRONIC",
  "SANTON SWITCHGEAR LTD",
  "SANTON/AOV Anda",
  "SANTOP",
  "Sanyo",
  "SANYO ECO i DC INVERTER",
  "SAPAG",
  "SAPEL",
  "Saphir UV Systems",
  "Saphire Controls",
  "Saphire Controls Ltd",
  "SAPOHIRE",
  "SAPOHIRE CONTROLS",
  "SAPPEL",
  "SAPPEL ALTAIR",
  "SAPPEL/ALTAIR",
  "Sappell",
  "Sappghire Controls /ABB",
  "SAPPHIRE",
  "SAPPHIRE CONTOLS LTD",
  "SAPPHIRE CONTROL LTD",
  "Sapphire Controls",
  "Sapphire Controls /ABB",
  "SAPPHIRE CONTROLS LTD",
  "SAPPHIRE CONTROLS LTD.",
  "SAPPHIRE CONTROLS LTD. (SQUARE D)",
  "SAPPHIRE CONTROLS LTE",
  "Sapphire Controls/ABB",
  "SAPPHIRE INDUSTRIAL CONTROL LTD",
  "SAPPHIRE INDUSTRIAL CONTROLS",
  "SARA",
  "SARA LOADING BAY SPECIALISTS",
  "SARABEC",
  "Saracen",
  "SARA-SPRINT",
  "Sarax",
  "Sarchwell",
  "SAREL",
  "SARENA",
  "SARENA LIMITED",
  "Sarena Ltd",
  "SARENA LTD",
  "SARENA mfg. Ltd",
  "Sarena Plastic",
  "SARENA PLASTICS",
  "SARENA TANKS",
  "Sargent & Greenleaf",
  "Sargent & Greenleaf Inc",
  "SARIN",
  "Sarlin",
  "SAS",
  "SAS Caring",
  "SAS CARING TECHNOLOGY",
  "SAS Networks",
  "SAS-CARLING TECHNOLOGY",
  "SASSI",
  "Satchwell",
  "SATCHWELL CONTROL SYSTEMS",
  "SATCHWELL CONTROL SYSTEMS LTD",
  "Satchwell Grant",
  "Satchwell.",
  "SATEC",
  "Satec ltd",
  "SATEK",
  "Satel",
  "SATNCOLD COLDROOMS",
  "Sato",
  "Satshwell",
  "Sauermann",
  "SAULTE3",
  "SAUNIER DUVAL",
  "SAUTER",
  "SAUTER / TREND",
  "SAV SYSTEMS",
  "SAVA CONTROLS",
  "Sava Power",
  "SAVADOR",
  "Saval",
  "SAVAPOWER",
  "SAVASAT",
  "SAVASTAT",
  "SAVAWATT CONTROLS",
  "Saver",
  "SAVER by FERNO",
  "SAVER FANS",
  "SAVER RANGE",
  "Saver Safe",
  "saverahu",
  "SAVERAIR",
  "Savers",
  "SAVERY",
  "SAVEWIRE",
  "SAVILLE",
  "SAVILLE STAINLES",
  "SAVILLE STAINLESS",
  "SAVORTEX",
  "SAVOTEX",
  "Sawmaster",
  "Sax",
  "Saxby",
  "SAXCOM",
  "SAXON LIFTS LTD",
  "SB WELFARE SERVICES DIVISION",
  "SBES",
  "SBES LIFE SAVER ALARM UNIT",
  "SBGI",
  "SBS",
  "SBS LIFTS",
  "SCAE ITALY",
  "Scafor",
  "SCAIOLA",
  "SCALE MASTER",
  "SCALE WATCHER",
  "SCALEMASTER",
  "Scalewatcher",
  "SCAME",
  "SCAN FROST",
  "SCANBOX",
  "Scandia",
  "Scandinova",
  "Scanfrost",
  "Scania",
  "SCANOMAT",
  "Scantech ID",
  "SCANTOOL",
  "Scantronic",
  "Scantronic (Red Alert)",
  "Scantronics-(Red Alert)",
  "Scarff",
  "SCATCI EUROPE LTD",
  "scatco",
  "SCATCO EUROPA",
  "SCATCO EUROPA  LTD.",
  "SCATCO EUROPA LTD",
  "SCATCO EUROPA LTD.",
  "SCATCO EUROPE",
  "SCATCO EUROPE LTD",
  "scci",
  "SCEIDT & BACHMANN",
  "SCH",
  "SCHAEREL",
  "SCHAERER",
  "SCHAERER KOPIE",
  "Schaffner",
  "SCHAFREN",
  "SCHARER",
  "SCHAUMBERG",
  "Scheidel",
  "Scheider",
  "SCHEIDT",
  "SCHEIDT & BACHMANN",
  "SCHEIDT&BACHMANN",
  "scheming",
  "Schenker & Storew",
  "SCHEPPACH",
  "Schi",
  "Schiavi",
  "Schiedel",
  "SCHIEDT&BACHMANN",
  "Schiendler",
  "Schiller",
  "schilling chemie",
  "Schindler",
  "SCHINDLERS",
  "Schipke",
  "SCHIUMBERGER",
  "SCHLEICHER",
  "SCHLETTER",
  "Schlinder",
  "schluber",
  "SCHLUMBERG",
  "SCHLUMBERGE",
  "Schlumberger",
  "SCHLUMBERGET",
  "Schlumger",
  "SCHLUTER",
  "schmidag",
  "SCHNEIDER",
  "Schneider EASY 9",
  "SCHNEIDER ELECTRIC",
  "SCHNEIDER ELECTRIC / SQUARE D",
  "SCHNEIDER ISOBAR 4C",
  "Schneider/SQUARE D",
  "Schneider/Terasaki",
  "SCHNEIDERBELECTRIC",
  "SCHNEIDERMP",
  "SCHNEPPACH",
  "SCHNERER",
  "SCHNRIDER",
  "SCHNURDER",
  "Schodt ceramic",
  "Schott",
  "SCHOTT CERAMICS",
  "SCHOTT CERAN",
  "SCHOTT CLEAN",
  "Schrack",
  "SCHRAEDER",
  "SCHRAERER",
  "Schreiber",
  "SCHRODER",
  "Schroeder",
  "Schuberger",
  "SCHUCO",
  "SCHWANK",
  "SCHWART",
  "Schweider",
  "SCHWEIZ",
  "SCHWINN",
  "SCI",
  "SCIENCEEAITE",
  "SCIENTAIR THERMAL SYSTEMS LTD.A716",
  "SCIENTAIRE",
  "SCIENTAIRE THERMAL SYSTEMS",
  "SCIENTAIRE THERMAL SYSTEMS LIMITED",
  "SCIENTAIRE THERMAL SYSTEMS LTD",
  "SCIENTAIRE THERMAL SYSTEMS LTD.",
  "SCIENTAIRE THERMALS",
  "SCIENTIFIC THERMAL",
  "SCIFIT LTD.",
  "Scimitar",
  "scitek",
  "SCLHUMBERGER",
  "SCLUMBERGER",
  "SCM Group",
  "SCNIEDER",
  "Scobie McIntosh",
  "SCOPE",
  "Scope comunications",
  "SCOPE ELECTRONICS",
  "Scorpion",
  "Scorpion Power Systems",
  "Scorsman",
  "SCOTKLEEN",
  "scotsheild honeywell security",
  "scotsheld",
  "SCOTSHIEL",
  "SCOTSHIELD",
  "Scotsman",
  "SCOTSMAN BEVERAGE SYSTEMS",
  "SCOTSMAN FAIRMONT",
  "SCOTSMAN FRAIMONT",
  "SCOTSMAN FRIMONT",
  "SCOTSMAN PENTAIR",
  "SCOTT",
  "Scott & Sons",
  "SCOTT BENHAM",
  "SCOTTISH AND SOUTHERN",
  "SCOTTISH GAS",
  "SCOTTISH POWER",
  "SCOTTMAN",
  "Scottsman",
  "Scot-Vent",
  "Screwfix",
  "SCRHRODER",
  "SCRME",
  "SCS",
  "SCS CONTROLS",
  "SCS System Controls",
  "Scs Controls",
  "Scumberger",
  "SCWS",
  "SCX Special Projects",
  "SD",
  "SD Advnced",
  "SDC",
  "SDC Varpac",
  "SDC-INDUSTRIES",
  "SDEL",
  "SDF",
  "SDI",
  "sdl",
  "SDMO",
  "SDMO - AVK POWER SOLUTIONS",
  "SDMO INDUSTRIES.",
  "sdsrgsrhs",
  "sdt",
  "SDV ESCALATORS",
  "SE",
  "SE CONTROLS",
  "SE Heat",
  "SEA",
  "Sea Change",
  "Sea Land",
  "SEAGOLD",
  "SEAGOLD Boilers",
  "SEAL SERVICES LTD",
  "SEALER POWER PRODUCTD",
  "Sealey",
  "Sealey Power Products",
  "Sealey power tools",
  "SEALLY POWER WELDERS",
  "Sealspur",
  "Sealspur Electrical Ltd",
  "Sealy",
  "SEALY TOOLS",
  "SEAMA",
  "Searie",
  "Searle",
  "SEARLE BUSH",
  "Searle-bush",
  "Searly",
  "Season Control",
  "SEAT VENTILATION",
  "seatin",
  "Seaton",
  "Seaward",
  "Sec",
  "Sec railtech",
  "Seca",
  "SECALT S.A.",
  "SECAUDIO",
  "Seciri-Power Stanby Systems",
  "SECO",
  "Secom",
  "SECOMAC",
  "SECOMAK",
  "Secomak Ltd",
  "SECTION",
  "Sector",
  "Sector hand dryer",
  "Sector hand rail",
  "Sector IPX4",
  "SECTRON TECHNICAL SERVICES",
  "SECURASOUND LTD",
  "SECURASOUND LTD.",
  "SECURE",
  "SECURE ALARMS",
  "SECURE AROUND LTD",
  "secure fast",
  "Secure Fire",
  "Secure guard",
  "SECURE LINE",
  "Secure Power Systems",
  "SECURE SOUND",
  "SECURE WATCH",
  "Securefast",
  "Securex ?",
  "SECURI FIRE",
  "SECURI PLEX",
  "SECURICOR",
  "SECURICOR GRANLEY",
  "SECURI-DOOR",
  "Securi-fire",
  "Securigaurd",
  "Securi-grille",
  "SECURIKEY",
  "SECURI-LIGHT",
  "Securit",
  "SECURITAS",
  "SECURITECH",
  "SECURITRON",
  "SECURITY",
  "SECURITY ACCESS SYSTEMS",
  "SECURITY ALARM",
  "Security Alarms",
  "SECURITY CONTROL SYSTEM",
  "SECURITY CONTROL SYSTEMS",
  "SECURITY LIMITED",
  "Security Lock & Safe Co. LTD",
  "SECURITY SERVICES GROUP",
  "SECURITY SERVICES LTD",
  "SECURITY SHUTTERS LONDON",
  "SECURITY SOLUTIONS",
  "SECURITY SYSTEM CONTROL",
  "SECURITY SYSTEM CONTROL LTD",
  "SECURITY SYSTEMS CONTROLS",
  "SECURITY SYSTEMS LTD",
  "SECURITYPAC",
  "Secutec",
  "sedegard",
  "SEDGEWICK",
  "SEDGEWICK WOODWORKING MACHENERY",
  "SEDGEWICK WORKWORKING MACHINERY",
  "Sedgwick",
  "SEDIMATE",
  "SEE INDOOR UNITS",
  "SEEBOARD",
  "SEENOARD",
  "Seepex",
  "SEEPEX UK LTD",
  "SEERS MEDICAL",
  "SEF TECH",
  "SEFTON WELLINGTON",
  "SEG",
  "segarde",
  "SEIDAL",
  "Seimens building technologies",
  "SEINENS",
  "SEITRON",
  "SEKO",
  "Sekomak",
  "Sel",
  "sel ltd",
  "SELC",
  "Selec",
  "SELECT",
  "Select garage doors",
  "SELECT SWITCHGEAR & CONTROL",
  "SELECT SWITCHGEAR LTD",
  "SELECTA",
  "SELECTORS",
  "Selectric",
  "Selectronic Redring",
  "Self fabricated",
  "SELKAN",
  "SELKIRK",
  "SELKIRK METALBESTOS",
  "SELL",
  "sella controls",
  "Selmec",
  "Selrad",
  "SELWOOD PUMP",
  "Selwood pump co.",
  "SELWOOD PUMPS LTD.",
  "SEM",
  "Semco",
  "semedgard",
  "SEMMCO",
  "SEMMCO ?",
  "Senate",
  "SENATH",
  "Senator",
  "SENATOR 2",
  "SENER TEC",
  "SENERTEC",
  "Seneva",
  "SENFLOW",
  "Senflow UK LTD",
  "senior",
  "SENIOR AIR SYSTEMS",
  "SENIOR DODUCEL",
  "SENIOR MODUCEL",
  "Sennheiser",
  "SENSAIRE",
  "Sensaphone",
  "SENSE LOGIC",
  "Senseco",
  "SENSOMATIC",
  "Sensor 3",
  "SENSORMATIC",
  "Sensors",
  "SENSOTEC",
  "SENSUD",
  "SENSUS",
  "SENSUS / HOME RIDER",
  "SENSUS MELSTREAM",
  "Sentient",
  "Sentinel",
  "SENTINEL DOOR SYSTEMS LTD",
  "Senton",
  "Sentri",
  "SENTRI TWO",
  "Sentrl",
  "Sentron",
  "SENTRY",
  "sentry 1",
  "SENTRY HT",
  "sentrysafe",
  "Seopa",
  "SEP",
  "SEPAR",
  "SEPPEL",
  "SEPURA",
  "SER TEC",
  "SER TEC SYSTEMS",
  "SERANA TANKS",
  "SERCK HEAT TRANSFER",
  "Serck Services",
  "Serco",
  "SERCON CONTROLS",
  "Serel",
  "SERENA PLASTICS",
  "SERENA TANKS",
  "SERENS TANKS",
  "SERHRA METAL WORKS",
  "SERIE 2000",
  "SERIES",
  "SERIES 2000",
  "Serla",
  "Seropa",
  "SERPOA",
  "SERREL",
  "SER-TEC",
  "SER-TEC SYSTEMS LTD",
  "SER-TEC SYSTEMS LTD.",
  "SERVANDO SYSTEMS ENGINEERING",
  "SERVAQUA",
  "Servcool",
  "server",
  "SERVER LIFT",
  "SERVHEAT",
  "SERVICE",
  "Service Lift UK",
  "Service Lift UK Ltd",
  "SERVICE LIFTS UK",
  "SERVIS",
  "SERV-LIFT",
  "Servo/Clima",
  "SERVOTOMIC LTD",
  "Servowarm",
  "SES",
  "SES Controls",
  "SES SOUTHERN LTD",
  "Sesamo",
  "Sessons",
  "Set",
  "SET ELSA",
  "Setelsa",
  "Setma",
  "SETONLINE",
  "SETPONT",
  "SEUSTER DOORS LTD.",
  "Sever Tec",
  "SEVERELY",
  "SEVERIN",
  "SEVERLEK",
  "SEVERN CONTROLS LTD.",
  "SEVERN TRENT",
  "SEVERN TRENT SERVICES",
  "SEW",
  "SEW EURODRIVE",
  "Sezer",
  "sf",
  "SF Detection",
  "sf fire",
  "SF Fire protection",
  "SF Ltd",
  "SF PROTECTION",
  "SF0406A",
  "SFA",
  "SFACOOL",
  "SFC",
  "SFD",
  "SFD LTD",
  "SFD LTD.",
  "SFEDAN",
  "SFL",
  "SFR",
  "SFS",
  "SFS FIRE ENGINEERING LTD.",
  "SG & M WORTHINGTON",
  "SG WATER",
  "SGB",
  "SGB GERMANY",
  "SGB GMBH",
  "SGB STARKSTROM",
  "SGB Youngman",
  "SGK UK",
  "SH CONTROLS",
  "SH CONTROLS LTD",
  "SHAAM",
  "SHACKLETON",
  "Shamal",
  "SHAMROCK",
  "shanks",
  "Shannon",
  "SHAP",
  "SHAPL",
  "Shapland",
  "Sharp",
  "Sharpe",
  "SHEARFLOW",
  "Shearflw",
  "Shearveil",
  "Shedgard",
  "SHEFFIELD REFRIGERATION",
  "Sheld",
  "SHENTON GROUP",
  "SHEPARD  FIRE & SECURITY",
  "SHEPARDS FIRE & SECURITY",
  "Sheppard",
  "SHERWOOD",
  "SHIELD",
  "SHIELD ENERGY SERVICES",
  "shields",
  "SHIELDS SERVICES",
  "SHIM COMMUNICATIONS",
  "SHIMGE",
  "Shindler",
  "Shinsei Seiki",
  "SHIRE",
  "SHIRE CONTROLS",
  "SHIRE CONTROLS LTD",
  "SHIRE CONTROLS LTD.",
  "Shires",
  "Shires Lynx",
  "SHITHS ELECTRIC VEHICLE",
  "SHJ",
  "SHJ HOSPITAL LINES",
  "SHJ HOSPITAL PIPELINE",
  "SHJ HOSPITAL PIPELINES LTD",
  "SHJ HOSPITAL PIPELINES LTD.",
  "Shlumber",
  "shlumberger",
  "SHM",
  "SHM COMMUNICATIONS",
  "Shop",
  "SHOP ALERT",
  "shopper 2",
  "SHORE CONTROLS LTD",
  "Shoreline",
  "SHORETEL",
  "Shorock Ltd",
  "SHORROCK",
  "Shorrock Fire",
  "Shorrock Security",
  "SHORROCKS FIRE",
  "Shoti",
  "Showcase",
  "SHOWER",
  "Showermate",
  "SHS HANDLING SOLUTIONS",
  "SHUCO",
  "SHURE",
  "SHUTTER CONTRACTORS LTD",
  "SHUTTER CONTRACTORS LTD.",
  "SHUTTER DOORS LTD",
  "SHUTTER DOORSYSTEMS LTD",
  "SHUTZ",
  "SI MFERMA AUTOMATICAMENTE",
  "SI PUMPS",
  "SIAE MICROELETTRONICA",
  "SIAP",
  "SIARGO LTD",
  "SIAS",
  "SIATA",
  "SIBAL",
  "SICC",
  "SICE",
  "Sicherung",
  "Sicli",
  "SICOR",
  "Sidan Systems",
  "Side SPA",
  "SIDHIL",
  "SIE",
  "SIEBEL",
  "Sieger Ltd",
  "SIEL",
  "SIEL ENERGY SYSTEMS LTD",
  "SIEMEN",
  "SIEMENS",
  "SIEMENS / TELECOM",
  "Siemens Composite",
  "SIEMENS DESIGO PX",
  "Siemens EDMI",
  "Siemens Controls",
  "SIEMENS/GRUNDFOS",
  "SIEMENS-EDMI",
  "SIEMENS-LANDIS & GYR",
  "SIEMES",
  "SIEPOL",
  "SIERRA",
  "SIESTA",
  "SIESTA COMFORT",
  "siex",
  "Sifam",
  "SIFAN SYSTEMS",
  "siflo",
  "SI-GLELCHRICHTER",
  "Sigma",
  "Sigma Si",
  "SIGMAGYR",
  "SIGMATIC",
  "Sigmend Pulsomester Pump LTD",
  "SIGMUND PULSOMETER PUMPS",
  "SIGNA CONTROLS",
  "SIGNA SERV LTD",
  "SIGNAL",
  "Signature",
  "SIGNATURE REFRIGERATOR",
  "Sigram",
  "Sigtel",
  "SigTEL CCU 16",
  "sigTEL COMPACT",
  "sigTELL Compact.",
  "SIISONS BY FRANKE",
  "Silanos",
  "SILAVEN4",
  "SILAVENT",
  'Silavent 6"',
  "SILAVENT ADVENT",
  "SILCII",
  "Silcon",
  "SILE S.P.A CASIER",
  "SILECTRON",
  "SILENSYS",
  "SILENT",
  "SILEVENT",
  "SILICAIR",
  "SILICAIR DRYERS",
  "SILICAIR DRYERS LTD",
  "Silit Werke",
  "SILLAVENT",
  "SILPI",
  "SILVANT",
  "SILVENT",
  "SILVER CREST",
  "SILVER FOX",
  "silver king",
  "SILVER LINK",
  "SILVERCREST",
  "SILVERKING",
  "SILVERLINE",
  "SILVERMINES",
  "SILVERMINES B.E.M.S. GROUP",
  "SILVERMINES B.M. SYSTEMS",
  "SILVERSTONE",
  "SIMAG",
  "SIMBI",
  "Sime",
  "Sime ?",
  "Sime d",
  "SIME EUROMAT",
  "SIME EYROMAT",
  "Simed",
  "SIMEL",
  "Simfer",
  "SIMFLEX GRILLES & CLOSEURES",
  "Simi",
  "SIMI Bailey Birkett Ltd",
  "SIMM TONIC",
  "SIMM TORNIC",
  "SIMM TROMIC",
  "Simm Tronic",
  "Simm Tronic Ltd.",
  "SIMMONDS",
  "SIMMONDS BROS",
  "Simmonds Brothers",
  "SIMMONS BROS",
  "SIMMTONIC",
  "Simmtronic",
  "SIMMTRONIC LIGHTING CONTROLS",
  "SIMMTRONICS",
  "SIMON",
  "SIMONELLI",
  "SIMP'EX",
  "SIMPLEX",
  "Simplex ?",
  "SIMPLEX GRILLS AND CLOSURES LTD",
  "SIMPLEXGRINNELL",
  "SIMPLY WATER COOLERS LTD",
  "SIMRAD TAIYO",
  "SIMTRONIC",
  "SINAX",
  "Sinclair",
  "SINCLAIR STAINLESS FABRICATIONS LTD",
  "Sinergy",
  "SINERGY LTD",
  "Singer",
  "SINGLE PHASE",
  "SINGLE PHASE WATT",
  "SINGLE POINT ANCHOR",
  "SINK AND TAPS",
  "SINKO",
  "SINLE PHASE WATT HOUR",
  "SIP",
  "SIP PRODUCTS",
  "SIPAIR",
  "Siphon",
  "Siphon System",
  "SiplEX",
  "Sirai",
  "SIRIO",
  "SIRMAN",
  "Sirocco",
  "Sirocco M Series",
  "siroco",
  "Sirrus",
  "SIRVA",
  "SIS SECURITY",
  "SIS SECURITY BESPOKE SECURITY SOLUTIONS",
  "SISMA",
  "Sissons",
  "SISSONS BY FRANKE",
  "SISTEMA AGUA NEBULIZADA",
  "sita",
  "SITE BUILT",
  "SITE BUILT NO I.D.",
  "SITE CONSTRUCTED",
  "SITE CONSTRUTED",
  "Site Guard",
  "SITE MANUFACTURED",
  "SITE MASTER",
  "Site Spec",
  "SITE SPECIFIC",
  "Siteco",
  "SITECOM PROJECTS",
  "Sitemaster ?",
  "SITES",
  "Sitesafe Ltd",
  "Sitewatch",
  "SIVER KING",
  "SIXTH SENSE",
  "Size4",
  "SKA",
  "Skanflek",
  "SKANSKA RASHLEIGH WEATHERFOIL",
  "SKB",
  "SKE",
  "SKE NEPTRONIC",
  "Skeppshult press & svets",
  "SKF",
  "SKG",
  "SKG UK",
  "Skidmore",
  "SKIL",
  "SKILL AIR",
  "SKOBERNE",
  "Skot",
  "SKOT TRANSFORMERS",
  "SKRA",
  "SKS",
  "SKUM",
  "SKY TRONIC",
  "SKYCOM",
  "SKYHI",
  "Skyjack",
  "SKYLINE",
  "Skyman",
  "Skymaster",
  "Skyreach",
  "SLATA",
  "Slate",
  "Slatebourn",
  "SLATER",
  "SLATERS",
  "Slaters  Electricals Ltd",
  "Slaters Electrical",
  "Slaters Electricals",
  "SLATERS ELECTRICALS LTD",
  "SLAVENT",
  "SLB Electrical",
  "SLB Electrical Ltd",
  "SLBOLUX",
  "SLC",
  "SLIMCOM",
  "Slimflex",
  "Slimline",
  "slimpack",
  "Slingsby",
  "SLM LTD.",
  "Slopless",
  "SLOT/MF8 EXIT SIGN",
  "SLUCO",
  "SLUICEMASTER",
  "Slumberger",
  "Slumbergr",
  "SLUSH PUPPIE",
  "SLUSH PUPPY",
  "sm power",
  "SM VECTOR",
  "SMA",
  "SMA Solar Technology",
  "SMA SUNNY TRIPOWER",
  "SMA TECHNOLOGIE AG",
  "SMA technology",
  "SMACH",
  "SMALL POWER",
  "SMART BUILD",
  "SMART BUILDINGS",
  "SMART CARE",
  "SMART CONTROL SYSTEMS LIMITED",
  "SMART FLUSH",
  "SMART GROUP",
  "SMART HEAT",
  "Smart Line",
  "Smart Lite Solutions",
  "Smart Lock",
  "Smart meter",
  "SMART PRO",
  "smart process",
  "Smart Squarbo",
  "SMART SYSTEMS UK",
  "Smart Tec Solutions",
  "Smart UPS",
  "Smart Water",
  "SMART WATER COOLERS",
  "Smart wave",
  "Smart & Brown",
  "SMARTACCESS",
  "smartank",
  "SMARTCOMM",
  "SMART-HEAT",
  "Smartherm",
  "smartlunk",
  "SMARTMETER",
  "Smartpack",
  "SMARTSHOP",
  "smart-tech",
  "Smas",
  "SMC",
  "SMC GOLD",
  "SMDO",
  "Smedegaad",
  "SMEDEGAARD",
  "SMEDEGAARD Denmark",
  "SMEDEGAARD PUMPER",
  "smedegar",
  "SMEDEGARD",
  "smeegard",
  "SMEG",
  "Smegard",
  "SMF LIFT No 24",
  "SMG CONTROL SYSTEMS",
  "SMGEC RED SPOT",
  "Smiplex",
  "Smith",
  "Smith & Byford",
  "Smith & Byford Ltd",
  "SMITH & KEIGHLY",
  "SMITH & KEIGHTLEY",
  "SMITH & WELL STOOD",
  "SMITH AND DERBY",
  "SMITH AND KEIGHLEY",
  "SMITH AND LOCKE",
  "Smith And Mte",
  "Smith and Prince",
  "SMITH AUSTIN",
  "SMITH BROS",
  "SMITH BROS & WEBB",
  "SMITH BROS.",
  "Smith Brothers",
  "Smith Brothers Stores Ltd",
  "SMITH DENBY",
  "SMITH DERBY",
  "SMITH DETECTION",
  "Smith meter",
  "Smith meter GmbH",
  "Smith Meter Inc",
  "smith meters",
  "Smith Meters LTD Dunblane",
  "Smith of Derby",
  "SMITH& KEIGHLY",
  "SMITHS",
  "Smiths Detection",
  "Smiths Electric Vehicles",
  "Smiths Electric Vehicles Ltd",
  "SMITHS FAN COIL",
  "Smiths Heimann",
  "SMITHS INDUSTRIES",
  "Smiths Meters",
  "SML",
  "SML (ABB)",
  "SML LTD.",
  "smo",
  "SMOKE CLOAK",
  "SMOKECLOAK",
  "SMOKEETER",
  "Smokeshield",
  "SMP SECURITY LTD",
  "SMS",
  "SMS SERVICES",
  "SMV",
  "SMX",
  "snagamo",
  "Snakky Max",
  "SNCF",
  "SNGAMO",
  "Snow Refrigeration",
  "Snowcap",
  "SNOWFLAKE",
  "Snowhite",
  "Snowpack",
  "SNOWWHITE",
  "SNOW-WHITE",
  "SNS NORTHERN",
  "SO.ERR",
  "SO.ERRE",
  "So.we.bo",
  "socam",
  "Socamel",
  "SOCIETA INDUSTRIAL CONSTRUZIONE RIDUTTORI",
  "Society Services",
  "SOCLA",
  "Socmec",
  "SOCO STS",
  "socomac",
  "SOCOMAC SICON",
  "SOCOMEC",
  "SOCOMEC / RAPPELL",
  "Socomec Diris A20",
  "SOCOMEC DIRIS A40",
  "SOCOMEC SICON",
  "SODECAU",
  "Sodeco",
  "SODEX HEXOTOL",
  "Sodium",
  "soehnle",
  "SOEMENS",
  "Soerre",
  "Sofinor",
  "SOFMAT",
  "SOFTEARE HOUSE",
  "SOFTLINE",
  "SOFTWARE HIUSE",
  "Software House",
  "SOGAM",
  "SOGEVAC",
  "SOGEVAC SV300",
  "SOGEVAL",
  "SOGI",
  "Soham",
  "Soham security products Ltd",
  "Sola Boost",
  "SOLA-BANNER",
  "Solaglass",
  "SOLAR",
  "SOLAR & PALAU LTD.",
  "solar edge",
  "Solar Lake",
  "Solar Quartz",
  "SOLAR RIVER",
  "SOLAR TECHNOLOGY",
  "Solar world",
  "SOLARCYL",
  "Solaris Locking",
  "SolarRiver",
  "SOLE",
  "Solecca",
  "Solectra",
  "SOLENT",
  "SOLER & PALAU",
  "SoliCode",
  "SOLID",
  "SOLIS",
  "Solmetex Hg5 Type 2",
  "SOLMON",
  "SOLMSON",
  "Solo Call",
  "solving",
  "Solyvent-Ventec",
  "SOM",
  "SOMATI",
  "SOMERS",
  "Somers Handling Ltd",
  "Somfy",
  "SOMIC",
  "Sommco GSE",
  "SOMO/MTU",
  "SONA",
  "SONDEX",
  "SONDEX UK",
  "SONDEX UK LTD.",
  "SONDULEURS",
  "SONEVA",
  "SONIC",
  "Sontay",
  "Sontex",
  "Sony",
  "SONYO",
  "SOPRANO",
  "Sormsaver",
  "Sota",
  "SOUCHIER",
  "Sound attenuators industrial Ltd",
  "Sound Attenuators Limited",
  "SOUP WELL",
  "SOUPERCAN",
  "SOURCE BIOSCIENCE",
  "Sousvide",
  "SOUTER",
  "SOUTH  EAST GAS BOARD",
  "SOUTH EASTERN GAS BOARD",
  "South Wales",
  "South Wales Switch Gear",
  "SOUTH WALES SWITCHGEAR",
  "South Wales Switchgear Ltd",
  "SOUTH WALES TRANSFORMERS",
  "South west battery charging systems",
  "southalls",
  "SOUTHALLS LTD",
  "SOUTHCORP",
  "Southern & Refern",
  "Southern and Redfern",
  "SOUTHERN CARE SYSTEMS / SPECTRA",
  "Southern Control Services",
  "SOUTHERN CONTROL SERVICES LTD.",
  "SOUTHERN CONTROLS",
  "SOUTHERN CONTROLS SERVICES LTD",
  "SOUTHERN COUNTY",
  "SOUTHERN COUNTY CARE",
  "Southern Electric",
  "SOUTHERN ELEVATORS LTD",
  "SOUTHERN GAS BOARD",
  "SOUTHERN LIGHTING",
  "Southern Monitoring",
  "SOUTHERN PUMP SERVICES",
  "Southern transformer products",
  "Southgate lighting",
  "SOUTHWALES SWITCHGEAR",
  "Southwest Outdoor Cooling Inc.",
  "SOVEREIGN",
  "SOVEREIGN AIR MOVEMENT",
  "SOVEREIGN HOUSE",
  "soverlec",
  "SP",
  "Space",
  "Space Air Conditioning",
  "Space And Gea Searle Engineering",
  "Space cooling systems",
  "space engineering",
  "SPACE ENGINEERING SERVICES",
  "Space Ray",
  "Spaceguard",
  "SPACE-RAY",
  "SPALOVSKY",
  "Spandau Pumpen",
  "Spanline",
  "Spannfix ?",
  "SPAR",
  "spar food machinery",
  "SPARKPAK",
  "SPART WARTUNG",
  "SPARTAN",
  "Spartan Controls",
  "Spartan Controls Ltd",
  "SPARTAN CONTROLS LTD.",
  "Spartan electrical ltd",
  "Spartan electrical systems ltd",
  "SPARTEN CONTROLS LTD.",
  "SPD Systems Ltd",
  "SPE",
  "SPE Electronica",
  "SPE ELECTRONNICA INDUSTRIALE",
  "SPEAKERBUS",
  "Speakman",
  "SPEC",
  "Specialist Alarm Services",
  "SPECIALIST ALARM SERVICES LTD.",
  "Specialist Control Services",
  "SPECIALIST FLU SERVICE LTD",
  "SPECIALIST POWER SYSTEMS",
  "SPECIALISTS IN SEATING",
  "SPECIALIZED DOOR SERVICES LTD.",
  "Specified",
  "Specified Products",
  "Specified Products Ltd",
  "SPECK PUMPEN",
  "SPECS, SIMMTRONIC",
  "Specta",
  "SPECTAR ENGINEERING LTD.",
  "SPECTAR SWITCHGEAR",
  "SPECTAR SWITCHGEAR LTD",
  "SPECTAR SWITCHGEAR LTD.",
  "SPECTRA",
  "SPECTRE",
  "SPECTRUM",
  "SPEED",
  "speed d",
  "Speed Pulleys",
  "Speed queen",
  "Speedair",
  "SPEEDDOR",
  "SPEEDRY MAGIC MARKER",
  "speedy",
  "SPEERIEDS PRODUCTS/A WEBB CENTRAL DISTRIBUTION",
  "SPEGEL AUTOMATIC",
  "SPEL",
  "Spel Tankstor",
  "Spencer",
  "spenstead",
  "Speroni",
  "Sperrin Switchgear",
  "SPERRIN SWITCHGEAR-(EATON-MEM)",
  "SPERRY VICKERS",
  "SPERRYN",
  "sperryn gas controls",
  "SPF",
  "Spie Trindel",
  "SPILLSTOP SENSOR SYSTEM",
  "SPIN-IT UK LTD.",
  "spiral tube",
  "spiral tube ltd",
  "SPIRAX GIRDLESTONE",
  "SPIRAX GRIDLESTONE",
  "SPIRAX SARCO",
  "SPIRAX SARCO UK",
  "SPIRAX-SARCO",
  "Spirit Folio",
  "SPIRITECH",
  "Spirocombi",
  "SPIROTEC",
  "Spirotech",
  "SPIROVENT",
  "Spirtech",
  "Spl",
  "SPLEEPYEX",
  "SPLICECOM",
  "SPM",
  "Spokane Industries",
  "Spoonshine",
  "SPOT",
  "SPOT LIGHT",
  "SPP",
  "SPP LTD",
  "SPP PUMP LTD",
  "SPP PUMPS",
  "SPP PUMPS LTD",
  "SPRAYBAKE LTD.",
  "SPRAYSAFE",
  "SPRECHER  schuh",
  "SPRECHER +SCHUH",
  "SPRECHER SCHUH",
  "SPRECHER+ SCHU",
  "Spriax Sarco",
  "SPRING GROVE",
  "SPRING GROVE SERVICES LTD",
  "Sprinter",
  "Sprio Tech",
  "Sprite",
  "SPX",
  "Spy Alarms",
  "sq d",
  "SQD",
  "SQOD",
  "SQQ FIRE",
  "SQR",
  "Square",
  "Square Alarms",
  "Square D",
  "SQUARE D LIAD CENTER",
  "SQUARE D LOAD CENTRE",
  "Square D load centre  KQ",
  "Square D loadcentre  KQ",
  "SQUARE D LOADCENTRE 2",
  "SQUARE D LOADCENTRE KQ2",
  "Square D TWINLOCK",
  "Square D TWIN'OCK",
  "SQUARE F",
  "SQUARE D",
  "SquarmeD",
  "Srant",
  "SREET",
  "srgrg",
  "SRI",
  "SRL",
  "SRL COUNTERECH",
  "SRL COUNTERTECH",
  "SRL LTD",
  "SRS",
  "SRS DOOR ENTRY SYSTEMS",
  "SR-TEC SYSTEMS LTD",
  "SS COILS",
  "SS Sink",
  "SSB",
  "SSC",
  "SSD",
  "SSD DRIVES",
  "SSE",
  "SSE ENTERISE SOLUTIONS",
  "SSE ENTERPRISE",
  "SSE ENTERPRISE SOLUTIONS",
  "SSG",
  "SSI",
  "SSI CORP",
  "SSMF",
  "SSNTON",
  "SSO",
  "SSP",
  "SSP PUMPS",
  "ST Pumps",
  "STABILAG",
  "STACOLD COLDROOMS",
  "STADCO COOLING LTD.",
  "Stadium",
  "Staefa",
  "STAEFA & LANDIS",
  "STAEFA CONTROL PANELS.",
  "Staefa Control Systems",
  "STAEFA CONTROLS",
  "Stafco",
  "STAFFORD BRIDGE DOORS LTD.",
  "Stag",
  "Stahl",
  "STAHLBAU ABERSFELDER",
  "STAIC SYSTEMS GROUP",
  "STAILETTE",
  "Stainless Design services Ltd",
  "STAINLESS STEEL",
  "STAINLESS STEEL CYLINDERS LTD",
  "STAINLESS STEEL DESIGN SERVICES LTD.",
  "STAINLESS STEEL SINK AND DRAINER",
  "Stainless Supplies Ltd",
  "STAINTON",
  "Stairmate",
  "STAM FRANCE",
  "Stamford",
  "STAMFORD AC GENERATORS",
  "STAMFORD/VOLVO",
  "STAN BRIDGE",
  "STAN CO",
  "STANAIR",
  "STANAX",
  "STANBAH",
  "STANBRIDGE",
  "STANCO",
  "Stancold",
  "Stancold Coldrooms",
  "standard",
  "Standard & Powtin",
  "Standard Control Systems",
  "STANDBY",
  "Stanford-Avk.Com",
  "Stangard",
  "Stanley",
  "STANLEY HANDLING LTD",
  "Stanley Security",
  "Stanma",
  "STANNA",
  "STANNAH",
  "STANNAH LIFT SERVICES",
  "STANNAH lifting services",
  "Stannah services",
  "Stannah Stairlifts",
  "Stannah/GMV",
  "STANNAX",
  "STANTON",
  "Stanton Aquarias",
  "STANWELL",
  "STANWELL TECHNIC LIMITED",
  "STAP",
  "Star",
  "Star crest",
  "Star May",
  "STAR REFRIGERATION",
  "STARBUCKS",
  "STARBUCKS COFFEE",
  "STARCOLD",
  "STARFLOW",
  "STARING ENTERPRISES",
  "Starite",
  "STA-RITE",
  "STARK",
  "STARKAN",
  "Starkan Holden & Brookes",
  "STARKEN",
  "STARKSTROM GERATEBAU GMBH.",
  "STARKSTROM LTD",
  "STARKSTROM",
  "Starkstrom-Geratebau",
  "Starlight",
  "Starline",
  "STARLING",
  "STARLING ENT",
  "STARLING ENTERPRISE",
  "starling enterprises",
  "starling enterprises ltd",
  "STARLING ENTRPRISESLTD",
  "STARPAK",
  "Starpipe",
  "STARPOWER",
  "Starrett",
  "STARSLUSH",
  "STARTE",
  "Startech",
  "Startright",
  "Startrite",
  "STARTRITE ENGINEERING CO. LTD",
  "startrite meba",
  "STARTRITE MTC",
  "STARWATCH SECURITY SYSTEMS",
  "STATBUCKS",
  "state",
  "State Industries",
  "STATE INDUSTRIES INC.",
  "STATE UK",
  "State Water Heaters",
  "STATEBOURNE CRYOGENICS LTD",
  "Statesman",
  "Static",
  "Static Switch Ltd",
  "Static Switching Ltd",
  "STATIC SWITCHING LTD.",
  "Static System",
  "STATIC SYSTEMS",
  "STATIC SYSTEMS GROUP",
  "STATIC SYSTEMS LTD",
  "STATICO",
  "STATICS",
  "STATICSYSTEMS GROUP",
  "station listed",
  "STATOS MICRA",
  "STATSMAN",
  "Stattscam",
  "STATUC SYSTEMS GROUP",
  "STATUC SYSTEMS TROUP",
  "Statz Benham",
  "STAWETT",
  "Staycold",
  "STAYCOLD (PTY) LTD",
  "STDM LTD",
  "STE",
  "stebbel electron",
  "STEBEL ELECTRON",
  "STEBEL ELTRON",
  "STEDGE",
  "STEEL LINE",
  "Steel Structure",
  "STEELFORM VENTILATION LTD",
  "Steepl",
  "Steeple",
  "Steeplie",
  "Steinall",
  "Steinel",
  "STELLER LTD",
  "STELRAD",
  "Stelrad Doulton",
  "STELRAD GROUP",
  "STELRAD GROUP LTD",
  "STELRAD IDEAL",
  "Stelrade Ideal",
  "STEMS",
  "STENTFON",
  "Stentil Stokvis",
  "Stentofon",
  "Stentorfieed",
  "STENTORFIELD",
  "STEPHEN P WALES",
  "STEPHENS & CARTER LTD",
  "Stephenson controls",
  "STEPHENSON LTD.",
  "STEPHILL",
  "stepless",
  "STERAD DOULTON",
  "STERAD GROUP",
  "Steri Air",
  "STERILIGHT",
  "SteriLoo",
  "Sterling",
  "STERLING AIR CONDITIONING",
  "STERLING FLUID SYSTEMS",
  "Sterling Hydrotech",
  "Sterling Pro",
  "STERLING SPP",
  "STERLING SPP LTD",
  "STERLING SSP LTD",
  "STERLING THERMALS",
  "Stero",
  "STERTIL",
  "STERTIL DOCK PRODUCTS",
  "STERTIL UK LTD.",
  "STERTOL",
  "STETFIELD SEPARATORS",
  "STETFIELD SEPARATORS LTD",
  "Steuergerat",
  "Steve o h",
  "STEVE's COLD ONEs",
  "Stewardt & Stevenson",
  "STEWART & STEVENSON",
  "Stewart Ventilation Services",
  "Stiebel",
  "Stiebel Electron",
  "STIEBEL ELTON",
  "Stiebel Eltron",
  "Stiebel Eltron.",
  "Stiebel Eltran",
  "Stiebel Eltron",
  "Stilc",
  "Still",
  "Still Ecotron",
  "STILMAS",
  "STIR FLOW",
  "stireflow",
  "STIRFLIW",
  "STIRFLOE",
  "STIRFLOUR",
  "STIRFLOW",
  "STOBICH",
  "stock electronics",
  "STOCK ELECTRONICS LTD.",
  "stockbridge airco ltd",
  "STOKES",
  "Stokis",
  "Stokvis",
  "Stokvis 557 KW",
  "stokvis Duplex",
  "Stokvis Econoplate",
  "Stokvls",
  "Stone",
  "STONEWOOD",
  "STOP N SEARCH",
  "Stored Energy Technology Ltd",
  "STORER",
  "Storers",
  "STOREY & PARTNERS",
  "STORFLOW",
  "STORK",
  "Storm Saver",
  "Storm Saver Ltp",
  "STORMSAVER",
  "Storr Benham",
  "Stortevent",
  "STOTT AND BENHAM",
  "Stott Benham",
  "Stotts of Oldham",
  "Stourflex",
  "STOVES",
  "STOVIS",
  "STR",
  "Strak (Elect. Services)",
  "Stranco",
  "Strand Lighting",
  "Strands",
  "Strantrol",
  "Strategic",
  "Stratford",
  "Strath - air",
  "Stratos",
  "Stratos 2",
  "Stratos HSSD",
  "STRATOS MICRA",
  "Stratos Quadra",
  "STRATOS VENTILATION",
  "STRATOS-HSSD",
  "STRATUS",
  "Strauss water",
  "STREAM HYGENE",
  "StreamHygiene",
  "Streamline",
  "STREBEL",
  "StrebelS-CB",
  "STREBELWERK AG",
  "STREET",
  "street crane",
  "STREET CRANE CO LTD",
  "STREET CRANE CO. LTD",
  "STREET CRANE CO. LTD.",
  "STREET CRANE LTD",
  "STREET CRANEXPRESS",
  "Streibel",
  "STREIEBIG",
  "Striebel",
  "Striebig",
  "Strinex",
  "STRIRFLOW",
  "Stromberg",
  "STROUD SWITCHGEAR",
  "Structual",
  "STRUCTURAL",
  "STRUCTURAL GROUP",
  "STS",
  "STS Spectar",
  "STS SPECTAR SWITCHGEAR LIMITED",
  "STS SPECTAR SWITCHGEAR LTD.",
  "STS Switchgear",
  "STS SWITCHGEAR & CONTROLS Ltd.",
  "STS SWITCHGEAR MOTORS LTD.",
  "STSSWITCHGEAR",
  "STSTEMAIR",
  "STTAINLESS STEEL CYLINDERS LTD",
  "STTIC SYSTEMS",
  "STTIC SYTEMS",
  "STUART",
  "STUART TURNER LTD",
  "Stubbe",
  "STUCKLIN & CIE AG",
  "Studio 54",
  "STUL2",
  "STULTS",
  "Stulz",
  "STULZ GmbH HAMBURG",
  "STULZ",
  "STULZ-CHSPL",
  "Sturdy",
  "STURDY ELECTRIC CO LTD",
  "Sturdy Electric Co. Ltd",
  "STURDY ELECTRIC Ltd",
  "STURTEVANT",
  "STUTZ",
  "Stu'tz",
  "STYLEBOILER",
  "STYLVENT",
  "Stystemair",
  "Styx",
  "Sub Zero",
  "Sudney Cabbage",
  "suemens",
  "SUEZ",
  "SULZER",
  "SUMA",
  "SUMA JOHNSON DIVERSITY",
  "sumeko heat",
  "SUMETZBERGER",
  "Sumo",
  "Sump Pump",
  "SUN HOUSE",
  "SUN POWER",
  "SUN??",
  "Sunfan",
  "SUNFLOWER",
  "Sunhoude",
  "sunhouse",
  "SUNNEX",
  "SUNNY BOY",
  "Sunnytripower",
  "SUNPOWER",
  "SUNRAY DOORS & LOUVRES",
  "SUNRISE",
  "Sunrise Medical",
  "Sunroc",
  "SUNSHINE MEDICAL",
  "Sunsstory",
  "SUNSTORE",
  "SUNTEC",
  "Suntech",
  "Suntellite",
  "suntube",
  "SUNVIC",
  "Sunwa",
  "Sunways",
  "Sunwin",
  "Sunwn Fire & Security",
  "SUP LYNX",
  "Supa lux",
  "supalux",
  "supastor",
  "Super B",
  "Super Charger",
  "Super Mixer",
  "SUPER MULTI",
  "Super Stallete",
  "SUPER STAR",
  "SUPER SWITCH",
  "Supercool",
  "Supercups",
  "Superfan",
  "Superior Lifts",
  "Superking",
  "SUPERLOY",
  "SUPERMICRO",
  "superschwank",
  "SUPERSTREAM",
  "Suplied by APL",
  "SUPP CONTROL SYS",
  "Supplied by APL",
  "SUPPLIED BY PENNY HYDRAULICS",
  "Supply Air",
  "Supply Fan",
  "Supply systems 365",
  "SUPRA",
  "Supreme",
  "SUPREME LIFTS LTD.",
  "SURE CONNECT",
  "SURE PUMP",
  "SURE SAFE",
  "Sure Safe Ltd",
  "SURECAST",
  "surefire",
  "Surefire Systems",
  "SUREFLOW",
  "SURELOCK",
  "SURELOCK SECURITY SYSTEMS LTD.",
  "SURELOCK SECURTIY",
  "Surelux",
  "Surespan",
  "Surestart",
  "SureVend",
  "SURF",
  "surf navigation",
  "Surface Finishing Washing Unit.",
  "SURFACE HEATING SYSTEMS LTD",
  "Surge Protection",
  "Surlite",
  "SURLOCK",
  "SURREY",
  "SURREY SECURITY SYSTEMS",
  "surtech",
  "Surveillance Camera Controller",
  "Survery Shutter",
  "SURVEYOR",
  "SURVEYOR EXEL-K",
  "SUSSEX CONTROLS",
  "SUSSEX CONTROLS LTD",
  "SUSSEX LIFT COMPANY",
  "SUTCH",
  "Sutton",
  "Sutton services ltd",
  "SUTTON TRANSFORMERS LTD.",
  "SUZLER",
  "SUZUMO",
  "SVA",
  "SVANDINOVA",
  "SVC",
  "SVC & Perimeter PLC Panel",
  "SVE",
  "SVEBA DAHEN",
  "SVEDAN",
  "Svendex",
  "Svenska Flaktfabriken",
  "SVM",
  "Svm90",
  "SVR",
  "SW",
  "SW CONTROL AND DESIGN LTD",
  "SW CONTROLS",
  "SW CONTROLS LTD",
  "Swale Shutters & Doors",
  "SWAN",
  "Swan Generators",
  "Swan Services",
  "Swanenvio",
  "SWANK",
  "Swansons",
  "SWEGON",
  "SWEGON LTD.",
  "Swel",
  "SWEP",
  "SWEP LTD",
  "SWER",
  "SWIFT",
  "SWIFT AIR",
  "SWIFT FIRE & SECURITY",
  "SWIFT LIFT SERVICES",
  "Swift Security",
  "SWIFT TECH",
  "SwiftAir",
  "SWISS COFFEE SYSTEM",
  "SWISS INSTRUMENTS INC.",
  "SWISSLOG",
  "SWISSLOG TUBE",
  "SWISSPHONE",
  "Switch",
  "switch gear",
  "SWITCH GEAR LTD CROYDON",
  "switch gear of croydon",
  "Switch gear systems",
  "SWITCH START",
  "SWITCHGEAR & CONTROL",
  "SWITCHGEAR AND SYSTEMS LTD",
  "Switchgear Ltd",
  "SWITCHGEAR SERVICES LTD",
  "SWITCHGEAR SERVICES LTD.",
  "SWITCHGEAR SPECIALISTS LTD (DORMAN SMITH)",
  "Switchgear ststems ltd",
  "SWITCHGEAR SYSTEM",
  "SWITCHGEAR SYSTEMS",
  "Switchgear Systems Ltd",
  "SWITCHGEAR SYSTEMS LTD.",
  "SWITCHGEAR TECHNOLOGY LTD",
  "Switchgear systems",
  "switchmaster",
  "SWITCHPLAN",
  "SWITHGEAR SYSTEMS",
  "SWL",
  "SWMS",
  "SWS UK",
  "SYANNAH",
  "SYKES PUMPS LTD",
  "SYLBER FROID",
  "syllvania",
  "Sylvania",
  "Symmetry",
  "Symom",
  "Symon",
  "SYMPHONY LTD",
  "SYNAPSIS",
  "Synchronised syatems",
  "SYNCHRONISED SYSTEMS",
  "SYNCHRONISED SYSTEMS LTD",
  "SYNCHRONISED SYSTEMS SERVICES",
  "SYNCHRONIZED SYSTEMS",
  "SYNCRO",
  "Syncronised",
  "SYNECTICS",
  "Synectics/Vigilant",
  "Synergy",
  "SYNOLOGY",
  "Synthesis",
  "Synthesis Twin",
  "SYOKVIS",
  "Syphon",
  "Syphon Door Access",
  "SYR",
  "SYRATOS",
  "SYSCHRONISED SYSTEMS",
  "SYSCOM Building Management Ltd.",
  "Syson Doors",
  "SYSTAN DOORS",
  "SYSTECH",
  "SYSTEM 2000",
  "SYSTEM 4 SERVICES",
  "SYSTEM 4 SERVICES LIMITED",
  "System 4 Services Ltd",
  "System 5",
  "System 5000",
  "SYSTEM 7703",
  "System Air",
  "System Doors",
  "SYSTEM FIVE",
  "SYSTEM ROLLING SHUTTERS LTD",
  "System Sensor",
  "System T",
  "System Uvex",
  "System Air",
  "SYSTEM-4-SERVICES LTD.",
  "SYSTEM7703",
  "SYSTEMAIR",
  "Systemaire",
  "systemait",
  "SYSTEMS 4 SERVICES",
  "Systems Aire Inc.",
  "Systems Ltd",
  "Systems power engineering",
  "SYSTEMS POWER ENGINEERING LTD.",
  "Systenair",
  "Syston",
  "Syston Door",
  "SYSTON ROLLER SHUTTERS",
  "SYTECQ",
  "Sytem Air",
  "SYXTH SENSE",
  "SyxthSense",
  "Syxthsense Ltd",
  "T",
  "T & D TRIDENT",
  "T & R GENERATOR LTD",
  "T A BOXALL (SCHNEIDER RLECTRICS)",
  "T B Davies",
  "T B Davies Ltd",
  "T CLARK",
  "T CLARKE",
  "T CLARKE EAST",
  "T COM",
  "T E C",
  "T F Tull",
  "T F TULL LTD",
  "T F TULL LTD.",
  "T Fox",
  "T Fox & Son",
  "T Fox & Sons",
  "T Fox &Sons",
  "T G Lynes",
  "T P FIRE LTD",
  "T S Harrison & Sons",
  "T T",
  "T T PUMPS",
  "T W C",
  "T&D",
  "T&P",
  "T,J,Williams",
  "T. F. TULL LTD",
  "T. H.",
  "T. Jolly",
  "t.a.c.",
  "T.B.DAVIES",
  "T.C.W. SERVICES",
  "T.C.W. SERVICES (CONTROLS) LTD",
  "T.CLARK CONTROL SYSTEMS DIVISION",
  "T.CLARK CONTROLS DIVISION",
  "T.CLARKE.EAST",
  "T.E.S.A",
  "T.E.S.A (western) ltd",
  "T.E.S.A.",
  "T.F.Tull Ltd",
  "T.R.A.C",
  "T/E",
  "T+D TRIDENT",
  "T2",
  "T2 DB SS2/F9 Lighting Meter",
  "T2 TECHNOLOGY",
  "T2 TECHNOLOGY FIRE AND SECURITY",
  "t2000",
  "TA",
  "TA CONTROLS LTD",
  "TA HYDRONICS",
  "TA VALVES",
  "TAC",
  "TAC Schneider",
  "TAC SYSTEMS LTD.",
  "Taco",
  "TACOMA LABORATORY SYSTEMS LTD",
  "TAE",
  "TAG",
  "Tagelus",
  "Tagus",
  "taie",
  "Taifun",
  "Taison",
  "Takagi Seiko",
  "Takara Corp",
  "TALEXUS",
  "talisman",
  "TAMBOUR",
  "Tami Bar",
  "tamilite",
  "taml",
  "tamlight",
  "Tamliight",
  "tamlite",
  "Tamlite 1200x600",
  "Tamlite.",
  "TAMTEC",
  "TAMTEC FIRESCAN",
  "TAMURA",
  "Tamute",
  "Tana",
  "TANA WATER",
  "Tana Water Classic",
  "Tangent",
  "Tangent Controls",
  "TANGENT CONTROLS LTD",
  "TANGENT CONTROLS LTD.",
  "TANGYE",
  "TANITA",
  "Tankmatic",
  "tanks  Iseek bros.",
  "TANKS & DRUMS",
  "TANN",
  "Tann Synchanome",
  "Tann Synchronome",
  "Tann Synchronome Ltd",
  "TANNAIRE",
  "TANNOY",
  "TANSUN QUARTZHEAT",
  "TAPESWITCH",
  "Tapflow",
  "TAPWORKS",
  "Taratuma",
  "Taraturna",
  "TARGABOT",
  "TARGHAP BPT",
  "Tarrington House",
  "TAS",
  "TASC",
  "TASC DIGITAL CONTROL SYSTEMS",
  "Tashiba",
  "TASSELLI",
  "TASSO",
  "Tasso justus",
  "Tate",
  "TATE AUTOMATION",
  "Tate Security Systems",
  "TATRAMAT",
  "TATSA",
  "TAU",
  "Taurus",
  "TAYLOR",
  "TAYLOR CROWN",
  "TB Coleman",
  "TB Davies",
  "TBA",
  "TBC",
  "TBD",
  "TBD OWEN HOLLAND",
  "TBD UK",
  "Tbduk",
  "TBH",
  "TBH Flow Meter",
  "TBK",
  "TBX",
  "TC CONTACTORS",
  "Tc Contator",
  "TC Williams",
  "TCHEF",
  "tchibo",
  "TCS",
  "TCW SERVICES (CONTROLS) LTD",
  "TCW SERVICES CONTROLS LTD",
  "TCW SERVICES LTD",
  "td-250",
  "TDS",
  "TDSI",
  "TDSI ACCESS CONTROLS",
  "Te",
  "TEAC",
  "teane",
  "TEANSFORMER SAFETY & RESEARCH CO. LTD.",
  "teansformers & rectifiers ltd.",
  "Teba",
  "Tebetron",
  "TEC",
  "TEC ELECTRIC MOTORS",
  "TEC ELECTRONIC MOTORS",
  "TECALEMIT",
  "Tececom",
  "TECFLUID",
  "TECFRIGO",
  "Tecgas",
  "Tech",
  "TECH MASTER",
  "Techfire",
  "TECHFIRE LTD",
  "TECHFLOW",
  "Techman Head",
  "TECHMANIQUE",
  "TECHN7CAL ALARM SYSTEM",
  "Techna-Ctec",
  "TECHNAIR",
  "Technicair",
  "TECHNICAL  INITIATIVES LTD",
  "TECHNICAL ALARM SYSTEM",
  "Technical blinds",
  "Technical Control Systems",
  "Technical Gas Services",
  "TECHNICAL INIATIVES LTD",
  "TECHNICAL INITIATIVES LTD.",
  "TECHNICAL SUPPLIES",
  "TECHNICAS EN EXTINCION DE INCENDIOS SA",
  "Technics",
  "Techniflow",
  "Technigas",
  "technika",
  "TECHNIQUE",
  "Technistore",
  "TECHNIVORM",
  "TECHNO CONTROL",
  "TECHNO SYSTEM",
  "Technoboxx",
  "Technolect",
  "Technolog",
  "technology",
  "technology fire and security",
  "TECHNOLOGY LTD",
  "TECHNOLOGY SUPPLIES",
  "TECHNOTE",
  "TECHNOTHERM",
  "Techology Ltd",
  "TECHSOFT LASER",
  "TECHSOFT LASER PLUREX",
  "TECHTOP PUMPS",
  "Tecknigas",
  "Tecknikon ltd",
  "TECN ALARM",
  "Tecnair",
  "TECNAIR LB",
  "TECNICA ELEMEC",
  "TECNICAS",
  "Tecni-Coil",
  "TECNIK",
  "Tecnikalight",
  "Tecniplast",
  "Tecnivel",
  "Tecnocontrol",
  "TECNODOM",
  "Tecnolec",
  "TECO",
  "TECO ELEC & MACH",
  "TECO ELEC & MECH",
  "TECO ELECTRIC & MACHINERY CO LTD",
  "Teco Weatinghouse",
  "Teco Westinghouse",
  "TECOMAX",
  "Tecsystem",
  "TECTON",
  "TECTON/NICE/BOSCH/RIELLO",
  "Tecumesh",
  "TECUMSEH",
  "Tecunsek",
  "Teddington-france",
  "TEE",
  "Tee Electrical",
  "TEE Electrical systems",
  "TEEKAY CONTROLS",
  "Tefal",
  "TEFAL AVANTI CLASSIC",
  "TEFCOLD",
  "TEHNICAL SUPPLIES",
  "TEK LTD.",
  "Teka",
  "Teka Filtercure",
  "Tekflo",
  "TEKIMEX",
  "Tekni",
  "Teknigas",
  "Tekno",
  "TEKNO STAMAP",
  "TEKNOWARE",
  "Teksan",
  "Tektronics",
  "Tektroniks",
  "Tekumesek",
  "TELCOLD",
  "TELECOM",
  "Telegan",
  "Teleguard",
  "TELELIFT",
  "TELEMACANIQUE",
  "Telemani Que",
  "TELEMATIC",
  "Telemec Hanique",
  "telemecaique",
  "Telemecanique",
  "TELEMEXANIQUE",
  "Telemonique",
  "TELEPHONE ENTRY CO.",
  "TELEPHONE ENTRY.CO.UL",
  "Telephone Rentals",
  "TELEQUE",
  "TELERGON",
  "teleste",
  "TELESTEPS",
  "TELETRONIC",
  "telford",
  "TELFORD COPPER",
  "TELFORD COPPER CYLINDERS",
  "TELFORD STAINLESS PRODUCTS",
  "TELFORD WATER HEATERS",
  "Telltronics",
  "TELSTAR",
  "Teltronic",
  "TEMCANA",
  "TEMCANA 8C",
  "TEMEANA",
  "Tempcontrol",
  "TEMPERATURE LTD",
  "TEMPERZONE",
  "TEMPEST",
  "TEMPLE LIFTS",
  "TEMPLE LIFTS LTD",
  "Tempmaster",
  "Temptrol",
  "Tempus",
  "Tenby",
  "TEND",
  "Tenfold",
  "Tennant",
  "TensaBarrier",
  "TENSATOR",
  "TENSMITTON",
  "TEOREMA",
  "TER",
  "TER Ltd",
  "TERA SAKI",
  "Terasaki",
  "TERASAKKI",
  "terence baker",
  "TERENCE BARKER",
  "TERENCE BARKER LTD",
  "TERENCE BARKER TANKS",
  "Teresaki",
  "TERMALTECHNIKA KFT",
  "TERMATE",
  "TERMIX",
  "Termolier",
  "TERMOPLUS",
  "TERMOSCREENS",
  "Terrain",
  "TERRANED",
  "TERRANNED",
  "TERRENED",
  "TERRIER",
  "Terruggi",
  "Terrugia",
  "Terry",
  "TERRY GROUP",
  "TERSANO",
  "TES",
  "TES GROUP",
  "TESA WESTERN LTD.",
  "Tesar",
  "TESCO",
  "Teskan",
  "TESLA TRANSFORMERS LTD",
  "test",
  "test 20171211",
  "TEST ASSET",
  "TEST BOILER LTD",
  "TEST BOX INC",
  "test mem",
  "TEST VALLEY",
  "Test Valley Mobility",
  "Test2",
  "testasset",
  "TestAsset2",
  "teste",
  "Testman101",
  "Testo",
  "tesy",
  "tetranode",
  "TEV",
  "TEV Ltd",
  "TEW",
  "Tex Ltd",
  "TEXA",
  "texas instruments",
  "Texcom",
  "TEXCON",
  "TEXECOM",
  "TEXECOM PREMIER",
  "TEXECOM RED ALERT",
  "TEXECON",
  "Texsteam Inc",
  "Texteam inc",
  "Textsteam Inc",
  "Teyfords",
  "TF TULL",
  "TF TULL LTD",
  "Tf.Tull Ltd",
  "TFH",
  "Tf-Tull Ltd",
  "Tftvll Ltd",
  "TFX",
  "TG Lynes",
  "TG PRODUCTS LTD",
  "TGL",
  "TGLYNES",
  "TGM Environmental",
  "TGS",
  "TGW SERVICES",
  "TH 3000",
  "THACKRAY",
  "Thames Valley Controls",
  "THAMES VALLEY LIFTS",
  "THAMES VALLEY PUMPS LTD",
  "thames water",
  "THAMESGATE",
  "Thameside Fire Protection Co. Ltd",
  "THE ANDREW MACHINE CO LTD",
  "THE ANDREW MACHINE CONSTRUCTION CO LTD",
  "The Aqua Group",
  "The Boiling Tap Company",
  "THE BRITANNIC LIFT COMPANY",
  "The challenge cylinder",
  "THE COFFEE SOLUTIONS",
  "THE EASTERN ELECTRICITY BOARD",
  "The Electric Gate Co",
  "THE ELECTRIC HEATING COMPANY",
  "The Electrical Apparatus Co. Ltd.",
  "THE ELECTRICAL APPARATUS LTD",
  "THE ELECTRICAL APPARATUS LTD.",
  "THE ELEVATOR GROUP",
  "THE EUROPEAN",
  "The Express Lift Co",
  "THE EXPRESS LIFT Co. LTD",
  "THE EXPRESS LIFT COMPANY LTD",
  "THE FIELD CONTROLS CO.",
  "The Fire Alarm Panel",
  "The Fire Beam",
  "THE FIRE BEAM PROTECTION SYSTEM",
  "The fountain workshop limited",
  "THE FRIDGE PEOPLE",
  "THE FULHAM POTTERY LTD",
  "The Generator Company",
  "THE GUARDIAN",
  "THE INTELLIGENT PEOPLE LTD",
  "THE IS LIMITED",
  "THE IS LTD",
  "THE KIRBY GROUP",
  "THE KURBY GROUP",
  "THE LIFT & GENERAL ENG CO LTD",
  "The Lift & Hoist Co. Ltd",
  "THE LONDON FAN CO.",
  "The London Fan Co. Ltd",
  "THE MAGNUM MASTER",
  "The Meter Company",
  "THE MTERS Co.",
  "THE NEW YORK BLOWER COMPANY",
  "THE NORTH WESTERN ELECTRICITY BOARD",
  "THE POLYMATIC ENG. CO LTD",
  "THE POWER WORKS",
  "THE PUMP PRACTITIONERS",
  "THE ROBINSON SHUTTER",
  "THE SWIMSUIT DRYER",
  "THE SWITCHGEAR PEOPLE",
  "THE SYNCHRO NOME CO LTD",
  "The talbot",
  "THE TRANSFORMER & ELECTRICAL CO. LTD",
  "The Tube and Bracket Company",
  "THE UTILE ENGINEERING  CO LTD",
  "THE WELCOME FOUNDATION LTD",
  "The Wood Dust Control Company",
  "The Woodwork Dust Control Company",
  "THE YORK CONTROL & MAINTENANCE COMPANY",
  "The zenith electric",
  "Theben",
  "THELCASTLE",
  "THELCASTLE LTD",
  "THEMO SCIENTIFIC",
  "Themoscreen",
  "THEMOZONE",
  "THERAPY EQUIPMENT LTD.",
  "THERM",
  "Therm Plan",
  "Therma",
  "therma tec",
  "THERMACK",
  "Thermaclor",
  "Thermaco",
  "Thermaflow",
  "Thermaglow",
  "Thermal Arc",
  "THERMAL DISHWASHERS",
  "Thermal Dynamics",
  "THERMAL ENERGY INTERNATIONAL",
  "THERMAL ENERGY LTD",
  "THERMAL EXCHANGE",
  "THERMAL SCREENS",
  "THERMAL TECHNOLOGY",
  "THERMAL TECHNOLOGY LTD",
  "THERMAL TECHNOLOGY LTD.",
  "THERMAL TRANSFER",
  "THERMAL TRANSFER TECHNOLOGY",
  "THERMAL TRANSFER TECHNOLOGY LTD",
  "Thermalec",
  "Thermapkan",
  "THERMAQ",
  "Thermatec",
  "Thermatek",
  "Thermatex",
  "Thermax",
  "THERMAX INC.",
  "THERMEX",
  "Therm-Inox T1",
  "THERMO",
  "Thermo contol",
  "THERMO FISHER SCIENTIFIC",
  "THERMO FORMA",
  "THERMO SCIENTIFIC",
  "THERMO SCREEN",
  "Thermoair",
  "THERMO-AIR",
  "Thermobile",
  "THERMOCOLD",
  "ThermoCool",
  "Thermofrost CRYO",
  "THERMOMAX",
  "THERMON",
  "Thermoplan",
  "THERMOR",
  "Thermos",
  "THERMOSCREEN",
  "THERMOSCREEN LTD",
  "THERMOSCREENS",
  "THERMOSCREENS LTD",
  "THERMOSHIELD",
  "THERMOTIC",
  "THERMOTRACE",
  "THERMOWAVE",
  "Thermozone",
  "THERMPLAN",
  "THERMSCREEN",
  "ThermTec",
  "Thermtec Ltd",
  "THERMTEX LTD. (RM CYLINDERS)",
  "THERN INCORPORATED",
  "THEROSCREEN",
  "thetrord",
  "THINK CENTER",
  "THINK CENTRE",
  "Thinkcare",
  "THINKCENTRE",
  "THIR",
  "Thirn",
  "THIRNE & DERRICK",
  "THIRODE",
  "Thirst Aid",
  "Thirst Point",
  "Thirstaid",
  "THISTLE GENERATORS",
  "thmu-04",
  "Thomas & Belts",
  "Thomas & Betts",
  "Thomas collins & co",
  "Thomas Fox",
  "Thomas Fox & co",
  "Thomas Fox & Co Ltd",
  "THOMAS FOX & Co. LTD.",
  "Thomas Fox & Son",
  "Thomas Fox & Sons",
  "THOMAS GLOVER & CO",
  "THOMAS MITCHELL AND SON LTD",
  "Thompson Kirwin",
  "THOMPSON LIFTS",
  "THOMPSONS",
  "THOMSON",
  "Thomson Air Systems",
  "Thor",
  "THORITE",
  "THORLUX LIGHTING",
  "Thorm2",
  "Thormax",
  "Thorn",
  "THORN & DERICK UK",
  "thorn and derrick",
  "Thorn Derrick",
  "Thorn EMI",
  "Thorn EMI INDUSTRIAL BOILERS LTD",
  "Thorn EMI Measurements",
  "THORN EMI, AFA MINERVA",
  "thorn Kenwood",
  "THORN SECURITIES",
  "THORN SECURITY",
  "THORN SECURITY  LTD",
  "Thorn Security Ltd",
  "THORN SECURITY SYSTEMS",
  "Thorne & Derrick",
  "Thorne and Derrick",
  "Thorne Derrick",
  "THOROUGHBRED INDUSTRIAL DOORS",
  "THORP",
  "THORPE",
  "THORPE AND HOWDEN",
  "Thorsman",
  "Thousand & One Lamb Ltd",
  "Thousand & One Lamps",
  "Thousand & One Lamps LTD",
  "Thousand and one Lamps Ltd",
  "THP CONTROLS KFT",
  "THREE POINT ENGINEERIN",
  "THREE SPEED MIXER",
  "Thremosolar",
  "THROUGHOUT THE BUILD",
  "Thyrode",
  "THYSEN",
  "Thyssen (refurbed by Jackson Lifts)",
  "THYSSEN ASCENSEURS",
  "THYSSEN KNUPP",
  "THYSSEN KRUPP",
  "THYSSEN KRUPP ELEVATOR UK. LTD.",
  "THYSSEN KRUPP MONOLIFT",
  "THYSSENKRUPP",
  "THYSSENKRUPP AUFZUGWERKE GMBH",
  "THYSSENKRUPP LIFTS",
  "THYTRONIC",
  "TI",
  "TI BRADBURY",
  "TIDY PLANET",
  "TIFRAN",
  "TIGER",
  "TIGER HOIST CHAIN",
  "Tilton",
  "TIME GUARD",
  "Timecentre",
  "TIMEGUARD",
  "Timeplan",
  "TIMESACE",
  "TIMKEN",
  "TINDALL",
  "TINDALL SECURITY",
  "TINDELL",
  "TINDELL SECURITY SYSTEMS",
  "TIPO",
  "TIPO (S & P)",
  "TIPPERARY",
  "TIPTON JEAVONS",
  "TIRAN ALPHA",
  "TIRFOR",
  "Tirney Turbines",
  "TIRTON",
  "TIS",
  "Tis Teldis",
  "TITA",
  "TITAN",
  "TITAN 1",
  "Titan 30",
  "Titan 300",
  "TITAN ENVIRONMENT LTD.",
  "Titan Environmental",
  "TITAN FIRE",
  "TITAN LIFTS",
  "titan plastech",
  "Titan Alpha",
  "TITANZO",
  "titian",
  "Titon",
  "Titus",
  "Titze",
  "Tivoli",
  "TJ MORRIS",
  "TJ Williams",
  "TKF",
  "TKK UK KTD.",
  "TLC",
  "TLD",
  "TLP",
  "Tm Technologie",
  "TMC",
  "TMC ITALY GROUP",
  "TMC Ltd",
  "TMC Manufacturing",
  "TMC True",
  "TNI",
  "tnm",
  "tnmth",
  "TNT",
  "TO ENVIROMENTAL PLC",
  "TO HIGH TO REACH",
  "TOA",
  "Toastmax",
  "TOBHIBA",
  "Tocsin",
  "TODD RESERCH",
  "Todhibia",
  "TOFCO LTD",
  "TOG",
  "tokeheim",
  "tokheim",
  "TOKHIEM",
  "TOKYO GAS",
  "Tombling",
  "Tomi",
  "Tomlinson",
  "TONKHEIM",
  "Tonon",
  "Tony",
  "Tony Team",
  "TONY TEAM LTD",
  "TOO HIGH TO REACH",
  "Toomeys",
  "Top Line",
  "TOP LITE",
  "TOPAIRE",
  "TOPAZ",
  "TOPBRE",
  "TOPCO LTD",
  "TOPLINE",
  "TOPLINE CHEMICAL TESTING",
  "TOPLINE ELECTRONICS",
  "Topline executive",
  "TOPLINE WATER CHEMISRTY CONTROL SYSTEM",
  "TOPLINE WATER CHEMISTRY CONTR",
  "Topne",
  "TOPSPEC EURO",
  "TORIN Ltd",
  "Tormac",
  "Tormax",
  "TORMAX Automatic",
  "Tormek",
  "Tormex",
  "Tormox",
  "Tormx",
  "TORNADO",
  "TORNADO LITE",
  "TORNATECH",
  "TORQUE MASTER",
  "TOSHIBA",
  "TOSHIBA CARRIER CORPORATION",
  "TOSHIBA LAPTOP OPERATING SATCHWELL BMS",
  "TOSUMI",
  "TOTAL",
  "TOTAL COFFEE SOLUTIONS",
  "Total Containment",
  "Total Containment Solutions",
  "TOTAL CONTROL",
  "TOTAL ENERGY CONTROLS",
  "TOTAL EXTRACTION SOLUTIONS",
  "Total Refrigeration",
  "TOTAL REFRIGERATION LTD",
  "TOTAL SECURITY",
  "TOTALCARE",
  "TOTALINE",
  "Tote",
  "Totton Pumps",
  "TOWCO BUILDING SERVICES ENGINEERING LTD",
  "TOWEL AIRE",
  "TOWELAIRE",
  "TOWER",
  "TOWER LIGHT SRL ITALY (RAM INTERNATIONAL)",
  "Tower plus",
  "TOWLER AND SON",
  "TOYO-AIRCON",
  "TOYOTA",
  "TPR RESISTORS",
  "TPro",
  "TQ ENVIRONMENTAL PLC",
  "TR SERVICES",
  "TR TRANSFORMERS & RECTIFIERS",
  "TRAC",
  "t-r-a-c",
  "TRAC TIME CONTROLS LTD",
  "TRAC TIME CONTROLS LTD.",
  "TRAC TIME FONTROLS LTD",
  "TRACE HEATING CO",
  "Trace heating insrallation",
  "TRACE HEATING INSTALLATION",
  "TRACE HEATING INSTALLATION SERVICES",
  "Trace Tec.",
  "Traceheaters",
  "TraceTec.",
  "Tracetech",
  "Traceteck",
  "TRACETEK",
  "Track Air",
  "tracka",
  "TRACON",
  "Tractel Group",
  "Traction Electrical Services",
  "Tractlet",
  "TRADE FAN",
  "TRADE SAFETY SYSTEMS",
  "TRADE SAVE",
  "TRADE SUPPLIES",
  "TRADEC",
  "TRADEX",
  "TRADEX METER CO LTD",
  "TRADEX METER COMPANY",
  "TRAFO UNION",
  "Trafo-Union",
  "TRAIN LAMPS",
  "traj conveyors",
  "TRAK",
  "trak conveyors",
  "Trak Hupfer",
  "TRAKA",
  "TRAKKA",
  "TRALIFT",
  "TRAND",
  "Trane",
  "TRANE - AQUASTREAM 2",
  "TRANE BVBA",
  "Tranfeta",
  "TRANNS FIRE",
  "Tranrec",
  "trans ASSA ABLOY",
  "trans cube",
  "Trans EK",
  "TRANS POWER ENGINEERING",
  "Transactive Xtra",
  "Transc",
  "TRANSFERO",
  "TRANSFORM",
  "TRANSFORMATORI ELETTRICI",
  "TRANSFORMER",
  "TRANSFORMER CO",
  "TRANSFORMER ELECTRICALS Ltd",
  "Transformer Power",
  "TRANSFORMER SAFETY & RESEARCH CO. LTD.",
  "TRANSFORMERS & RECTIFIERS LTD.",
  "TRANSFORMERS and REVTIFIERS LTD",
  "Translift",
  "TRANSLYFT",
  "Transmitten",
  "Transmitton",
  "TRANSPOWER",
  "TRANSPOWER ENGINEERING",
  "TRANSTHERM",
  "TRANSTHERM COOLING INDUSTRIES",
  "TRANSTHERM COOLING INDUSTRIES L4D",
  "Tranter",
  "TRASFOR",
  "TRASFORMATORI  ELETTRICI",
  "Traulson",
  "TREADWAY FLOW",
  "TREATMENT PLANT CONTRACTS LTD",
  "TREATWASH",
  "TREBLE LTD",
  "TREBLES",
  "TREENWOOD",
  "treg",
  "Treking gas",
  "TRELLIDOR",
  "TREN",
  "TRENCHMAN LIFTING SYSTEMS LTD",
  "TREND CONTROL SYSTEMS",
  "Trend IQ210",
  "Trend/Demma",
  "Trend/Sanyo",
  "Trent 4",
  "Trent Controls",
  "TRENT ENERGY PRODUCTS",
  "TRENT INSTRUMENTS",
  "Trent products",
  "TRENTHAM",
  "TRENTHAM HYDRAULICS",
  "TRENTON",
  "TRI CONTROL SYSTEMS LTD",
  "Tri Palm",
  "Triac",
  "Trianco",
  "TRIANCO LTD.",
  "Triangle",
  "Triangle Intrgrated Systems",
  "Triangle Lift Service",
  "Triangle Lift Services",
  "Triangle lifts",
  "Triax",
  "TRIBUNE",
  "TRIBUNE KINGSPAN",
  "Tricel",
  "TRICITY",
  "TRICITY BENDIX",
  "Tricity Domestic Appliances Limited",
  "Tricity Tiara",
  "Tricity Viscount",
  "Triciy Bendix",
  "TRICOLITE",
  "TRICOOL",
  "TRICOOL LTD.",
  "TRIDENT 4",
  "TRIDENT EQUIPMENT",
  "Trident Refrigeration",
  "Triello",
  "Trilex",
  "TRILOGY",
  "Trilux",
  "TRIMAX SOLAR",
  "trimco",
  "TRIMEC",
  "TRINA SOLAR",
  "Trinity",
  "TRINITY CONTROLS LTD",
  "TRINITY FIRE & SECURITY SYSTEMS",
  "Trinity Protection Systems",
  "Trinkwasserspeicher/Hot water storage",
  "Trinkwasserspiexher/Hot water storage.",
  "TRINSMITTON",
  "Trinton",
  "TRIO PLAN SECURITY",
  "TRIOGEN",
  "TRION",
  "Triple E L Ltd",
  "TRIPLE RED",
  "TRIPLE RED LTD",
  "TRIPLE RED TECHNOLOGY",
  "TRIPP-LITE",
  "TRISLOT",
  "TRISTAR",
  "TRI-STAR",
  "Tristel",
  "Tri-Step 40",
  "Triton",
  "Triton Smokeater",
  "Triton T180",
  "TRITONS",
  "Trivac",
  "TRIVICTOR",
  "TROAX",
  "TROLEX",
  "TROLLEY LIFT",
  "Trolleylift",
  "tron EverBlu Cyble",
  "Tronicair",
  "Tropair Cooling Ltd",
  "TROPICAL MARINE CENTRE",
  "Trotec",
  "Troton",
  "TROX",
  "TROX TECHNIC",
  "TROX TECHNIK",
  "Troy",
  "troyfords",
  "Tru",
  "TRUCYL",
  "TRUE",
  "True Manufacturing Co",
  "True Manufacturing Company",
  "TRUE MANUFACTURING LTD",
  "TRUE MANUFACTURING UK",
  "TRUE REFRIGERATION",
  "TRUE REFRIGERATOR",
  "TRUE REVRIFERATION CO INC",
  "Truereed",
  "TRUMETIC",
  "TRUMPF",
  "Truset",
  "TRUST",
  "TS510",
  "TSAR",
  "TSAR TRANSFORMERS",
  "TSB ELECTRICAL LTD.",
  "TSCALE",
  "TSE",
  "TSI",
  "TSLI",
  "Tsrebel",
  "TSRUMI PUMP",
  "TSS",
  "tst",
  "TSURUMNI PUMPS",
  "TT",
  "TT CONTROLS",
  "T-T CONTROLS",
  "TT GENERGY",
  "TT OFFLINE",
  "TT Pumps",
  "T-T Pumps",
  "TT TRANSFORMER",
  "TTC COIL AS",
  "TTC NORGE AS",
  "TTK",
  "TTK S.A.S.",
  "TTK SAS",
  "TTK UK LTD.",
  "TTL",
  "ttron",
  "TTT",
  "Tube and Bracket",
  "TUBE HEAT LTD",
  "TUBEHEAT LTD",
  "Tubo",
  "Tudor",
  "TUFFA TANK",
  "TUFFA TANKS",
  "TUFFSAFE",
  "TUKE & BELL",
  "TUNDRA",
  "TUNEWELL TRANSFORMER",
  "TUNSTALL SECURITY",
  "Tunturi",
  "Turbo",
  "turbo chef",
  "Turbo Force",
  "TURBOCHEF",
  "Turbo-chef",
  "TURBOCHEF TECHNOLOGIES LTD",
  "TURBOFAN",
  "Turboflush",
  "Turkington Engineering",
  "TURNER",
  "TURNER ENGINE POWERED SERVICES",
  "Turner EPS",
  "TURNER TURBINES LTD",
  "TURNEY",
  "Turney Pumps",
  "TURNEY TURBIES LTD",
  "TURNEY TURBINES",
  "TURNEY TURBINES LTD",
  "TURNEY TURBINES LTD.",
  "Turnkey Refrigeration Ltd",
  "turnover flter company",
  "TURNSTALL",
  "TURNWRIGHT LTD",
  "Tursio",
  "Turton Industrial Doors",
  "TUV",
  "TUV NORD",
  "TV Installation Services",
  "TVC",
  "TVC CONTROLS",
  "TVC Monitoring Systems",
  "T-VERTER",
  "TVF",
  "TWC",
  "Twello",
  "twin",
  "twin break",
  "Twin Fan",
  "TWIN FEX",
  "Twin Flex",
  "Twinflex",
  "TWNSPOT",
  "TWYER",
  "Twyford",
  "TWYFORDS",
  "TWYVER",
  "TWYVER SWITCHGEAR LTD.",
  "TX2B",
  "Tyco",
  "Tyco Electronics",
  "TYCO FIRE",
  "TYCO FIRE & INTEGRATED SOLUTIONS",
  "TYCO FIRE & SECURITY",
  "TYCO FIRE PRODUCTS",
  "TYCO INERGEN",
  "TYCO SAFETY PRODUCT",
  "TYCO SAFETY PRODUCTS",
  "tyfords",
  "TYLO",
  "Tylor",
  "TYME MACHINES",
  "TYNE TEES FORTH",
  "type ménager",
  "Tyrrell  Tanks",
  "Tyssen Krupp",
  "tyvek",
  "TYVEK PRO TECH",
  "TYWFORDS",
  "u",
  "U SYSTEMS",
  "U.G.I.",
  "U.G.I. METERS",
  "u/k",
  "u/k independant not marked",
  "u/k roller screen",
  "U/TEC",
  "U16",
  "UABKE TO REACH (ABOVE CEILING)",
  "UAL",
  "Uasystem",
  "UBB",
  "UBE LIFTEKNIC",
  "ubfp2z",
  "UBFP4Z",
  "UBM",
  "UBM CADEL LTD",
  "UCC",
  "UCC COFFEE",
  "UCI METERS LTD",
  "UDC",
  "UEG",
  "UFAX FIRE ALARM CONTROL SYSTEM",
  "UG Meters",
  "UG1 METERS LTD",
  "UGC",
  "UGI",
  "UGI INDUSTRIES",
  "UGI METER",
  "UGI METER LTD",
  "UGI METER TRUST CO.",
  "UGI Meters Ltd",
  "UGI MEYER",
  "UGI METERS LTD",
  "ugougug",
  "UICAN",
  "UIFLAIR",
  "UIS",
  "UK BUNDED FUEL TANKS",
  "UK Exchangers Ltd",
  "UK EXCHANGERS LTD.",
  "UK Firewatch",
  "UK Hygiene",
  "UK LIFT",
  "UK Lifts",
  "UK REFRIGERATION LTD",
  "UK water",
  "UKNOWN",
  "'UKNOWN",
  "UL",
  "U-Line",
  "Ult",
  "ULTIMA",
  "Ultimate",
  "Ultima-TIG",
  "Ultra",
  "ULTRA ANALYTICS",
  "ULTRA ELECTRINICS",
  "ULTRA FILTER",
  "ULTRA HEAT",
  "ULTRA MAX",
  "ultra sump",
  "Ultra Violet Disinfection System",
  "ULTRACOM",
  "Ultrafilter",
  "Ultraheat",
  "ULTRASAFE",
  "ULTRASEP",
  "ultrasonic",
  "ULTRATROC",
  "Ultrawash",
  "ULTRAWAVE",
  "UMET",
  "UMKNOWNNYC",
  "UMPACT VENTILATION SYSTEMS",
  "UN",
  "UN READABLE",
  "UNABKE TO REACH",
  "UNABLE 4O READ",
  "UNABLE ACCESS DUE TO HEIGHT",
  "UNABLE DUE TO LOCATION",
  "UNABLE READ",
  "UNABLE READABLE",
  "UNABLE SEE DUERO LAGGING",
  "UNABLE TO ACCESS FAN",
  "UNABLE TO R2AD",
  "UNABLE TO REACH",
  "UNABLE TO REACH (ABOVE CEILING)",
  "UNABLE TO REACH (HIGH LEVEL)",
  "UNABLE TO REACH, DUE TO THE AMOUNT OF STUFF STORED",
  "UNABLE TO READ",
  "UNABLE TO READ DUE HIGHT",
  "UNABLE TO READ DUE LAGGING",
  "UNABLE TO READ DUE TO HIGHT",
  "UNABLE TO READ DUE TO LABEL OVER NAME",
  "UNABLE TO READ DUE TO LOCATION OF UNIT",
  "UNABLE TO READ DUE TO LOCATION OF VALVE",
  "UNABLE TO READ, DUE TO LOCATION",
  "UNABLE TO READ, DUE TO LOCATION OF UNIT",
  "UNABLE TO SEE",
  "UNABLE TONREACH",
  "UNABLEB TO READ",
  "UNB",
  "UNBRANDED ENCLOSURE",
  "UNCAT",
  "Under Dawmed",
  "UNDERHILL EHGINEERING",
  "UNDERHILL ENG",
  "Underhill Engineering",
  "UNDERHILL ENGINERING",
  "Underwood",
  "UNDERWOODD",
  "Underwoods",
  "UNDERWOODS ENGINEERED PRODUCTS LTD",
  "UNDERWOOD'S ENGINEERING PRODUCTS LTD",
  "UNDERWOODS ENGINEERING PRODUCTS LTD.",
  "UNDERWOODS SAFECLIP BOARD",
  "UNDERWOODS-GENERAL ELECTRIC",
  "Ungersol RAND",
  "Uni Line",
  "UNIBAR BARRIER",
  "uniblock zanotti",
  "UNIC",
  "UNICAN",
  "UNICAT",
  "Unico Olimpia",
  "Unidar",
  "Unidare",
  "UNIDEN",
  "UNIDENTIFIED",
  "UNIDUCT",
  "Unifax",
  "UNIFLAIR",
  "UNIFLAIR ITALIA",
  "Uniflair LK1",
  "UNIFLIR",
  "UNIFLO",
  "Uniflow",
  "Unifrost",
  "Unigas",
  "UNILEC ROLLS PERKINS",
  "UNILEVER 'BEN & JERRY'",
  "Uniline",
  "UNILINE SAFETY SYSTEMS LTD",
  "UNILOCK",
  "UNIMASTER",
  "UNIMET",
  "uninterrupible power supplies LTD",
  "UNINTERRUPTIBLE POWER SOLUTIONS",
  "Uninterruptible Power Supplies Ltd",
  "UNINTERUPTABLE POWER SUPPLIES LTD.",
  "Union",
  "Union (top lock) Legge (bottom lock)",
  "Union /Adamsrite",
  "UNION CARBIDE",
  "Union Indistries",
  "UNION INDUSTRIES",
  "Unionair",
  "Unionaire",
  "UNISAT",
  "Unison",
  "UNISTAR",
  "UNISYS",
  "UNIT MISSING",
  "UNITAIR",
  "UNITED AIR COILS",
  "UNITED AIR SPECIALISTS",
  "UNITED COFFEE",
  "UNITED COOL AIR",
  "UNITED FILTERS & ENGINEERING LTD",
  "UNITED LIFT",
  "United Technologies",
  "Uniterupted Power Supplies",
  "Unitron",
  "UNITRON CYCLONE",
  "Univa",
  "UNIVAR",
  "Univector",
  "universal",
  "UNIVERSAL GAS VALVES",
  "UNIVERSAL HEAT TRANSFER LTD",
  "Universal home",
  "UNIVERSAL LIFT SERVICES",
  "UNIVERSAL MOTORS",
  "Universal Power Systems",
  "UNIVERSAL SAFETY SYSTEMS",
  "UNIVERSAL SECURITY SYSTEMS",
  "Universo",
  "UNIVEX EXCEL",
  "UNK",
  "unkblod",
  "UNKBOWN",
  "unkfry",
  "UNKGTRAP",
  "unkhoods",
  "UNKN",
  "UNKNKOWN",
  "UNKNNOWN",
  "UNKNOEN",
  "UNKNOW",
  "unknowb",
  "UNKNOWN",
  "UNKNOWN - SEE NOTES",
  "UNKNOWN (UNIT AT HEIGHT)",
  "UNKNOWN (WOODS?)",
  "UNKNOWN ABOVE CEILING",
  "unknown brand",
  "UNKNOWN HEPA FILTER",
  "Unknown in Void",
  "UNKNOWN LAGGED WITH NO VISIBLE DATA PLATE",
  "UNKNOWN LIGHTING CONTROLER",
  "Unknown located in sump",
  "UNKNOWN NO ACCESS",
  "UNKNOWN NO DATA PLATE",
  "unknown possibly protec",
  "Unknown pump at hgh level",
  "UNKNOWN SCRATCHED OFF",
  "UNKNOWN STICKER COVERING NAME",
  "UnknownANZ",
  "UNKNOWNBRAITHWAITE",
  "UNKNOWNFTL",
  "UNKNOWN-LAGGED",
  "unknownmerlin gerin",
  "UNKNOWN-NO ACCESS",
  "unknownPORTMAN DOORS",
  "unknowns",
  "UNKNOWNTOR",
  "UNKNOWWN",
  "unkoven",
  "UNKOWNFTL",
  "UNKOWNGRN",
  "UNKOWNHOU",
  "UNKOWNMX",
  "UNKOWN-UNIT INSULATED NO ID VISIBLE",
  "UNKTOR",
  "Unkwoen",
  "UNLNOWN-BESPOKE",
  "unmarked",
  "unmarked box",
  "UNMNOWN",
  "UNNOWN (PUMP WITHIN TANK)",
  "Unox",
  "UNTEGRATED POWER TECHNOLOGY",
  "Unuaire",
  "UNVRANDED",
  "UP NORTH COOLING LTD",
  "UP Supplies Ltd",
  "upc",
  "Update Technology",
  "UPL",
  "UPO",
  "UPONOR",
  "Upright",
  "UP-RIGHT",
  "UPS",
  "ups housing",
  "UPS Ltd",
  "UPS POWER SUPPLIES LTD",
  "UPS12-475FR",
  "UPS2",
  "UPScale",
  "UPT TRANSCEIVER",
  "Upwright",
  "URBART",
  "Urmet",
  "Urmet Domus",
  "URSA GATES",
  "US Electrical Motors",
  "US Filter",
  "USF",
  "UST 1 FU",
  "ustigate",
  "UTC",
  "UTEC",
  "UTESCO",
  "UTILE ENGINEERING",
  "Utvägen",
  "UVAQ",
  "Uvex",
  "V&H",
  "V2SDA",
  "VAALTO TECHNOLOGIES Ltd",
  "VAC LINE",
  "VACLENSA",
  "VÄCLENSA",
  "VACLENSA SCOTLAND",
  "VACLESNSA",
  "VACON",
  "VAC-ON",
  "Vacublast",
  "VADCOM",
  "Vagon",
  "Vailant",
  "VAILLAMT",
  "VAILLANT",
  "Vaisala",
  "VALAINT",
  "VALBERG",
  "VALCON",
  "Valentine",
  "VALENTINES",
  "Valera",
  "valero",
  "VALIANT",
  "Vallant",
  "Valley Forge",
  "vallient",
  "VALOR",
  "Valor Coventry",
  "VALRADIO",
  "VALUE GE",
  "Valve + Pipe",
  "Valvestock",
  "Vamein",
  "vamp",
  "VAN DEN BERGH",
  "Van Kaik",
  "Van Lien",
  "Van Remmen",
  "VAN SPALL",
  "VAN SPALL ASSOCIATES LTD",
  "VAN SPALL ASSOCITES",
  "VAN WIJK & BOERMA HOLLAND",
  "VANDER VALK SOLAR SYSTEMS",
  "VANDERSIJS",
  "vanguard",
  "VANGUARD PROTECTIVE",
  "Vanguard Shutters",
  "VANQUISH",
  "VANSPLALL",
  "VANT AXIA",
  "Vantage",
  "Vant-Axia",
  "VAPAC",
  "VAPAC HUMIDITY CONTROL",
  "Vapal",
  "VAPORMAT WET BLAST MACHINE",
  "VAR",
  "VAR SERVICES",
  "VAR Services Limited",
  "VAR Services Ltd",
  "VAREM",
  "Varem ?",
  "Varem?",
  "varen",
  "Vareum",
  "VARIAN",
  "variante",
  "VARICON",
  "VARICON (3D) DRIVES LTD",
  "VARIELECTRIC",
  "VARIM",
  "Varimixer",
  "VARIMIXER BEAR",
  "Various",
  "VARISCO",
  "varm",
  "Varsico",
  "VARTA",
  "VASCO",
  "VAUCONSANT",
  "Vauxhall",
  "VC on 6",
  "Vc Sytems",
  "VCH INTERNATIONAL",
  "VCL",
  "VCTOR",
  "VDC",
  "VDS",
  "VE VENT",
  "VEAB",
  "Veam",
  "VEASDA",
  "Veataire",
  "VECT",
  "Vectair",
  "VECTAIR ENVIRONMENTAL",
  "Vectair Electronic",
  "Vectara",
  "vector",
  "VECTOR GROUP",
  "VECTOTHOR",
  "vectsire",
  "VECTURE INVERTER",
  "Veeder Root",
  "VEEDER -ROOT",
  "VEEDER-ROOT",
  "Veedre-Root",
  "Veerder Root",
  "Velmet",
  "Velopex",
  "VELOS",
  "Velox",
  "Veltia",
  "Velux",
  "VEM",
  "Vem motors",
  "VEMER",
  "VEMM",
  "VEMM TEC",
  "VEMMTEC",
  "Vemt Axia",
  "VEN",
  "VEN AXIA",
  "Ven4 Axia",
  "VENDER ROOT",
  "Vendo",
  "VENDUCT LTD",
  "VENM",
  "VENNER",
  "Venr Axia",
  "Vent",
  "VENT AC",
  "VENT AIR",
  "Vent Air Ltd",
  "VENT AXIA",
  "VENT AXIA HEATING",
  "VENT AXIA inc. ROOF UNITS LTD.",
  "Vent Axia Industrial",
  "VENT AXIAB",
  "VENT AXIS",
  "VENT ENGINEERING",
  "VENT MASTER",
  "VENT NASTER",
  "Vent Vision",
  "VENTAC",
  "VENTAC & CO.",
  "Ventair",
  "VENTAM",
  "Vent-Axia",
  "ventcroft",
  "Ventec",
  "VENTILATION DESIGN SERVICES",
  "Ventilux",
  "VENTILUX LTD",
  "Ventmaster",
  "Ventojet",
  "Vents",
  "VENTUS",
  "VENTVAXIA",
  "VENUS",
  "VENUS LEVANTE",
  "VEOLIA",
  "Vequip",
  "VEQUOP",
  "VERCO LTD",
  "VEREM",
  "VEREX TECHNOLOGY",
  "VERICARE",
  "VERIFIER",
  "VERINT",
  "Veritas",
  "Verlinde",
  "Vermacare",
  "VERMATIC",
  "vermax",
  "VERNACARE",
  "Verne Care",
  "VEROSOL",
  "Versatemp",
  "VERTEX",
  "VERTIV",
  "VERTIV INFRASTRUCTURE LTD",
  "VERYEX",
  "VES",
  "VES andover",
  "VES ANDREW",
  "VES ANDOVER LTD",
  "Vesda Systems",
  "Vesfrost",
  "Vessel Technology Ltd",
  "VESSMANN",
  "VEST FROST",
  "vesta",
  "VESTFROST SOLUTIONS",
  "VETTIO",
  "Vevt - Axia",
  "Vevt Axia",
  "Vevtaxia",
  "vexes",
  "VEXO",
  "vez",
  "VHH",
  "VHH NL",
  "VHH. KENCO",
  "Vi",
  "VIADRUS",
  "VIANEN",
  "Vibro",
  "Vic",
  "Viceroy",
  "VICKERS",
  "VICKERS LTD.",
  "vickers/ambirad",
  "VICKERS-AUTOMATED SYSTEM DIVISION",
  "Vicon",
  "VICORIA BAKING OVENS LTD",
  "VICOUNT CATERING",
  "VICOUNT ENODIS",
  "Victaulic",
  "VICTAULIC - BAILEY & MACKEY LTD.",
  "VICTAULIC BAILEY & MACKEY LTD.",
  "VICTAULIC SYSTEMS",
  "Victor",
  "VICTOR MANUFACTURING",
  "Victor Crown",
  "Victoria",
  "Victoria Arduino",
  "Victoria Fans",
  "VICTORIA FANS LIMITED",
  "victoria fans ltd",
  "VICTORIA FANS LTD.",
  "Victorian",
  "victorian backing ovens ltd",
  "VICTORIAN BAKING OVEN",
  "VICTORIAN BAKING OVEN LTD",
  "VICTORIAN BAKING OVEN LTD.",
  "victorian baking ovens ltd",
  "VICTORIAN KITCHENS",
  "Victory",
  "Victron Energy",
  "Vida",
  "VIDEO SWITCH",
  "VIDEOSWITCH",
  "Videotec",
  "VIDEX",
  "Vidicode",
  "VIDID ACOUSTICS",
  "VIDIONICS",
  "VIE MANN",
  "VIESMAN",
  "VIESMANN",
  "VIESSMANN",
  "ViewSonic",
  "Viggen",
  "Vigiion",
  "VIGIL",
  "Vigil Baldwin Box",
  "Vigil Plus",
  "Vigilant",
  "VIGILANT / BOSH",
  "Vigilant Security",
  "Vigilom",
  "Vigilon",
  "Viglion",
  "Viking",
  "Villant",
  "VILLAVENT",
  "VILLAWARE",
  "VILTRIGO SRL",
  "VIMEC",
  "VIMPEX",
  "Vincat",
  "vinci facilities",
  "VINCO",
  "Vindex",
  "VIOLA",
  "VIPER",
  "Viper II",
  "vipond",
  "VIRAMIX",
  "VIRCON",
  "VIRDEE ELECTRO ENGINEERING",
  "VIREE ELECTRO ENGINEERING",
  "Virgo",
  "VIRTICE",
  "Virtual",
  "Virtual Equipment",
  "Virtual Part - Miscellaneous",
  "Virtual Part Company",
  "Virtual Server Room",
  "Virtual Sweden Field",
  "virtual tag",
  "VIS SMANN",
  "Visa Onis",
  "Visage",
  "VISCO",
  "viscomat 70",
  "VISCONOL",
  "Viscount",
  "VISCOUNT CATERING LTD",
  "VISCOUNT CATERING LTD    S/N 94083 03/06",
  "VISCOUNT CATERING LTD ENODIS",
  "VISCOUNT CATERING LTD/ENODIS",
  "Visilynx",
  "VISIO SYSTEMS",
  "Vision",
  "VISION ALUMINIUM",
  "VISION BLUE",
  "VISION FACTORY",
  "vision fire & security",
  "VISION FIRE AND SECURITY",
  "VISION FITNESS",
  "VISION SYSTEM",
  "VISION SYSTEMS",
  "visionsat",
  "VISIONSYSTEMS",
  "visiosat",
  "Visiostag",
  "Visisat",
  "VISON",
  "VISONIC",
  "VISOR",
  "Visosat",
  "Visostat",
  "Vista",
  "VISTSA",
  "Visu",
  "VITA MIX CORP",
  "VITA MIX CORP        S/N 050050130107021282",
  "VITAIRE",
  "VITAMIX",
  "VITA-MIX",
  "VITAMIX CORP",
  "VITA-MIX CORP",
  "VITAMÎX CORP",
  "VITCROSSAL",
  "Vitera",
  "VITERRA",
  "VITO DENS",
  "VITOCROSSAL",
  "VITOMIX",
  "Vitra",
  "VITRAMID",
  "VITRANIX",
  "VITRIFRIGO",
  "VITRO",
  "VITRON ENERGY",
  "VITRONIX",
  "VITUALIC SYSTEMS",
  "Viva",
  "Vivendi Water",
  "Vivid Acoustics",
  "VIVREAU",
  "VIXEN SURFACE TREATMENTS LTD",
  "VIZSTARK LTD",
  "VIZU",
  "VIZUAL",
  "Vlakt Woods",
  "VLT HVAC DRIVE",
  "Vmax",
  "VNT AXIA",
  "VOCAL",
  "VOCAL EVCS",
  "Vocall",
  "VOCE",
  "VOCRICE",
  "Vogar",
  "VOGELSANG LTD.",
  "VOKERA",
  "VOKES",
  "VOKES UNIPAK",
  "vokrs",
  "Vola",
  "Volcera",
  "Volente",
  "VOLERA",
  "Volex",
  "Volex/Crabtree",
  "VOLKPUMP",
  "VOLLRATH",
  "Voltage",
  "VOLTAS",
  "VOLTEX",
  "Voltmaster",
  "VOLUTION",
  "VOLVO",
  "VOLVO PENTA",
  "VOLVOPENTA",
  "VONSHEF",
  "Vortex",
  "VORTICE",
  "vorticw",
  "Vortie",
  "VORTUCE",
  "VOSKAMP",
  "Vossloh Schwabe",
  "Votex",
  "VOTICE",
  "Voyager",
  "VOYEGER",
  "VPAC",
  "vrc",
  "VSG SYSTEMS",
  "VUAQUA",
  "VUESSMANN",
  "Vulcan",
  "VULCAN TANKS",
  "VULCAN TANKS LTD",
  "Vulcana",
  "VW",
  "VWR",
  "VX2001",
  "VYNCIKER",
  "Vynckier",
  "Vypackier",
  "W",
  "W & J",
  "W & L Installations",
  "W & R MOORE LTD.",
  "W ENVIROTRONICS",
  "W ENVIROTRONICS ltd.",
  "W GROVES LTD",
  "W J Furse",
  "W J POLLARD LTD",
  "W YEXLEY",
  "W YEXLEY SECURITY SYSTEMS",
  "w. yexley",
  "W.J Parry",
  "W.J. FURSE",
  "W.S. PARSONS",
  "W.TEXLEY",
  "W.YEXLEY",
  "W.YEXLEY LTD.",
  "W.Yexley Security Ltd",
  "wa903",
  "WACOM",
  "WACS",
  "Wade",
  "Wadkin",
  "WADKIN BURGREEN",
  "Wadkin Burnsgreen",
  "Wadkin Bursgreen",
  "Wadkin Ltd",
  "wadkin/bursgeen",
  "Wadkins",
  "wadsworth",
  "WADSWORTH LIFT",
  "WADSWORTH LIFTS",
  "Wadworth",
  "WAFCO",
  "WAG",
  "WAGNAR & CO",
  "wagner",
  "wago",
  "Waien",
  "WAKEFIELD",
  "WAKEFIELD SECURITY & FIRE",
  "WALCHEM",
  "WALDNER 1200",
  "Waldoor",
  "Wales",
  "WALKER",
  "Walker electronics",
  "Walker Fire",
  "WALKER WADE",
  "Wall 150",
  "Wall Dunam Bush",
  "Wall Durnam Bush",
  "Wall Extract Fan",
  "WALLACTOWN ENG. CO. LTD.",
  "WALLER ROLLER GMBH",
  "Wallgate",
  "wallgate unit",
  "Wallis",
  "WALLS",
  "WALLS ICE CREAM",
  "WALL'S ICE CREAM LIMITED",
  "WALLS REFRIGERATED SOLUTIONS",
  "WALLS REFRIGERATION SOLUTIONS",
  "WALLSEND",
  "WAL-MAN",
  "WALRUS",
  "Walsal",
  "Walsall",
  "Walsall Conduit",
  "Walsall Conduits",
  "Walsall Conduits Ltd",
  "WALSALL ENCLOSURE",
  "WALSALL ENCLOSURES",
  "walter gmbh",
  "WALTER KIDDE CO.",
  "WALTER ROLLER",
  "WALTER ROLLER GMBH",
  "WALTERHALTER",
  "Waltham electronics",
  "Walther",
  "WALTHER CEETYP",
  "WALTONS",
  "Wandsworth",
  "Wandsworth Lifts Bolton",
  "WANSIENG",
  "WANSON",
  "WANZL",
  "Wap (UK) Ltd",
  "Wap Kew Technologies",
  "WARCO",
  "WARD",
  "WARD AND SCOT",
  "WARD COMMERCIAL TREATMENT",
  "WARD COMMERCIAL WATER TREATMENT",
  "WARDOR",
  "Wardpark Gardner",
  "Wardpark Gardner ???",
  "Wardpower",
  "Wardray",
  "WARDS",
  "WARE",
  "WARE WASHING SERIES",
  "Ware Washing System",
  "WARE WASHING SYSTEMS",
  "Warem",
  "WARERLOGIC",
  "waring",
  "WARING COMMERCIAL",
  "Warm Air Curtain",
  "Warmac",
  "WarmaFloor",
  "WARMAIRE",
  "Warman",
  "WARMATIC",
  "Warmco",
  "Warmflow",
  "WARNER",
  "WARNER DERIGRIP BRAKE",
  "Warner Howard",
  "warner Howard limited",
  "WARNER HOWARD-WORLD DRYERS",
  "warner hower group",
  "warnerhoward",
  "WARREN",
  "WARREN JONEE",
  "WARRENS",
  "Warrior",
  "Warwick Dryers",
  "WARWICK/COCA COLA ENGERPRISES",
  "WASH",
  "Wash safe",
  "WASHEX",
  "WASHHAND BASIN",
  "WASHROOM CONTROL SYSTEM",
  "washroom control sysyem",
  "WashTec",
  "WASOL",
  "WASP PURE FUEL SYSTEMS",
  "Wasserpumpe",
  "WASTE KING",
  "waste pump technology",
  "WASTE2O MECHLINE",
  "Wasteflo",
  "Wasteflow",
  "Wastematic",
  "Waste-O-Way",
  "WATCHMAN",
  "Wateau",
  "Water",
  "WATER AID",
  "WATER BOILERS DIRECT",
  "Water BUNNIE",
  "WATER BUNNIES",
  "Water Bunny",
  "WATER CARE TECHNOLOGY",
  "WATER COOL",
  "WATER COOLER Co.",
  "WATER COOLER DIRECT",
  "Water Cooler System",
  "WATER COOLER SYSTEMS",
  "WATER COOLERS DIRECT",
  "WATER COOLERS DIRECT .COM",
  "WATER COOLERS LIMITED",
  "Water engineering solutions",
  "WATER FURNACE",
  "WATER KIDDE",
  "WATER KING",
  "WATER LIGIC",
  "WATER LING",
  "water savers ltd",
  "WATER SOLUTIONS",
  "Water Tech",
  "WATER TECHNIQUES",
  "Water Technology",
  "WATER TECHNOLOGY LTD.",
  "WATER TREATMENTS LTD",
  "WATER WATCH",
  "Water Wise",
  "WATERBUNNIE",
  "WATERBURY",
  "WATERCO",
  "Waterflo",
  "Waterflow",
  "WATERGUARD",
  "WaterKing",
  "WATERKOTRE",
  "WATERKOTTE",
  "WATERLODGIC",
  "Waterlogc",
  "WATERLOGIC",
  "Waterloo",
  "Waterloo Air Management",
  "Waterloo Northstar",
  "WATERLOO-OZONAIR",
  "Watermatic",
  "Watermizer",
  "Water'ogic",
  "WATERPOINT",
  "waterproof",
  "Waterproof fitting",
  "Watersavers",
  "WATERSTAR TANA INDUSTRIES",
  "Watertech",
  "WATERWIDE",
  "WATERWISE TECHNOLOGY LTD.",
  "WATFORD REFRIGERATION",
  "WATFORD REFRIGERATION & AIR CONDITION LTD.",
  "WATFORD REFRIGERATION & AIR CONDITIONING",
  "Wathes",
  "WATKINS",
  "WATLING & HOPE",
  "WATLING &HOPE",
  "WATLING AND HOPE",
  "WATLING HOPE",
  "Watling Switchgear",
  "WATNER HOWARD",
  "WATON MEM",
  "WATROUS",
  "WATT",
  "WATT HOUR METER",
  "WATT MISER",
  "WATT SOLUTIONS",
  "WATT SOLUTIONS LTD",
  "WattMiser/ ABB",
  "WATTS INDUSTRIES",
  "WAVEFORM",
  "Waves",
  "WAVESTORE",
  "Wavin",
  "WAYSCALE",
  "WAYSCALE LTD",
  "WAYSCALE POWER AND DATA CONTROL",
  "WB",
  "WCC",
  "WCD",
  "WDC",
  "WDSE",
  "wdt",
  "WE MASON  1000",
  "WEALD",
  "WEATHER MAKER",
  "Weather matic",
  "WEATHERITE MANUFACTURING LTD",
  "WEB WAY ONE LTD",
  "WEBCO",
  "Weber",
  "WEBSTER FAN",
  "Webway",
  "WEDECO",
  "WEDECO REX",
  "WEDO",
  "WEFCO",
  "WEG",
  "WEG.",
  "WEGER",
  "Weidmuller",
  "Weightlfter Cranes",
  "WEIGHTLIFTER",
  "Weinman",
  "WEIR",
  "WEIR PUMPS",
  "WEIR ROVE",
  "Weishaupt",
  "WEISHAUPT MONARCH",
  "WEISS",
  "WEISSMAN",
  "WEL",
  "Welch Allyn",
  "WELCO",
  "WELDABILITY SIF",
  "Welded Air Receiver",
  "Welded Pressure Vessel",
  "WELDED RECEIVER",
  "WELDING ENGINEERING",
  "WELDING ENGINEERS",
  "Weldon",
  "WeldWork Cargo Systems",
  "Well",
  "Wellco",
  "WELLMAN",
  "Wellman Robey",
  "Wells",
  "WELL-X-TROL",
  "WEMS",
  "WEN PLAST",
  "weo",
  "WERGER",
  "WERKiT",
  "WERLAB GROUP",
  "WERNER",
  "Werth",
  "Werther",
  "Wesex",
  "Wesminter contols",
  "WESPAK",
  "Wesper",
  "Wesrman Systems",
  "WESSEX",
  "WESSEX CONTROLS",
  "Wessex County",
  "Wessex environmental controls ltd",
  "WESSEX INDUSTRIAL DOORS",
  "Wessex Lift",
  "WESSEX LIFT CO",
  "WESSEX LIFT CO. LTD",
  "WESSEX MEDICAL",
  "Wessex Modular",
  "WESSEX PRODUCTS",
  "Wessex Alarms",
  "WESSSEX LTD",
  "WEST COAST CONTROLS",
  "WESTALITE",
  "westat cvf",
  "WESTCOUNTRY SECURITY",
  "wester power",
  "WESTERMO",
  "Western",
  "Western Automation Ltd",
  "WESTERN AVERY",
  "Western Electric",
  "WESTERN ELECTRIC AUSTRALIA",
  "WESTERN POWER DISTRIBUTION",
  "WESTERN THERMAL LTD",
  "WESTFROST",
  "Westhart",
  "Westinghouse",
  "WESTINSTER CONTROLS",
  "Westline",
  "Westman Systems",
  "WESTMID",
  "WESTMINSTER CONTROLS",
  "WESTMINSTER CONTROLS LTD.",
  "Westmount",
  "WESTOMATIC",
  "Westpoint",
  "WESTWARD METER",
  "WESTWAY",
  "Westwood",
  "WESTWOOD METERS",
  "WESTWOOD METERS & TIMERS LTD",
  "WESTWOOD SWITCHGEAR",
  "Wesurail",
  "WET MASTER",
  "wetrock",
  "WETROK",
  "Wexiodisk",
  "Wexley",
  "Weymouth",
  "WG & R",
  "WG R",
  "WGandR ENGNEERING",
  "WGH",
  "WH CYLINDERS",
  "WHARTON",
  "WHELANAPIER",
  "Whipp & Bourne",
  "WHIRLPOOL",
  "WHIRLPOOLWHIRLPOOL",
  "Whitco",
  "WHITCROFT",
  "White",
  "White Beko",
  "white group",
  "WHITE KNIGHT",
  "WHITE KNITE",
  "White Night",
  "WHITE WESTINGHOUSE",
  "WHITE WOLF",
  "WHITECOFT",
  "WHITECROF LIGHTING",
  "Whitecroft",
  "Whitecroft lighting",
  "WHITECROSS ENGINEERING",
  "Whiteknight",
  "Whiteleaf",
  "WHITELY",
  "Whitlenge",
  "Whits",
  "WHITTLE VALVE REPAIRS",
  "WHNTERHALTER",
  "WHURST",
  "whylex",
  "Wibe 1800",
  "WICKEL TECHNOLOGIE",
  "Wickes",
  "WICKHAM AUTO WASH LTD",
  "WICKS",
  "WIDECO",
  "Wideco Sweden",
  "wieland",
  "wiesmann",
  "WIILO",
  "Wiinterhalter",
  "WIJAS",
  "Wiko",
  "Wilco",
  "WILD ABOUT WATER",
  "Wild about water - branded",
  "Wilden Pump",
  "Wilden Pumps",
  "WILI",
  "WILKEN",
  "Wilkes Lighting Ltd",
  "Wilkins",
  "WILKINSON STAR",
  "Wilko",
  "wilks",
  "Will Commercial",
  "willaims",
  "Willaims Refrigeration",
  "William and brown",
  "WILLIAM JAMES LTD.",
  "WILLIAM M WILSON'S SONS INC",
  "WILLIAM MUSGRAVE LTD.",
  "WILLIAM R SELWOOD LTD",
  "WILLIAM STEWARD",
  "Williama",
  "WILLIAMS",
  "Williams & James",
  "WILLIAMS GREENLOGIC",
  "WILLIAMS REFRIGERATION",
  "WILLIAMS REFRIGERATION LTD",
  "WILLIAMS/ZANOTTI",
  "WILLIS & THORNLEY",
  "Willmn Robey",
  "Willow Pumps",
  "Wills",
  "WILMAT",
  "WILO",
  "WILO SALMSON PUMPS - WSP",
  "Wilo Samson pumps",
  "WILO SAMSON PUMPS LTD",
  "Wilo Gold",
  "WILOBCOMFORT PREESURISATION UNIT",
  "WILODP-4O/130.2.2 2/2",
  "Wilson",
  "WILSON PERKINS",
  "Wilson Power Solution",
  "WILSON POWER SOLUTIONS",
  "WILSON/SAACKE",
  "WILTS",
  "WINCHESTER",
  "Windcatcher",
  "WINDCREST",
  "WINDER",
  "WINDER ELECTRICAL",
  "WinderElectrical Limited",
  "Winderest",
  "Window & Door Services Ltd",
  "WINDOW CONCEPTS",
  "WINDOW MASTER",
  "WINDREST",
  "Windsor",
  "WINDY BOY",
  "Winex",
  "WINIA",
  "WINIX",
  "winkelmann",
  "WINKELMANN + PANNHOFF",
  "WINKELMANN + PENNHOFF",
  "WINKLEMAN & PANNHOFF",
  "WINNIA",
  "WINSTON",
  "WinTACS ACCESS SYSTEM",
  "WINTER HALTER",
  "Winter Heat",
  "Winter Warm",
  "WINTERGALTER",
  "WINTERHALTER",
  "Winterhalter-",
  "WINTERHATER",
  "WINTERHHALTER",
  "WINTERHLTER",
  "Winterholter",
  "Winters Electrical Services Ltd.",
  "WINTERWARM",
  "WINTERWARM BV",
  "wintrhalter",
  "wireland",
  "Wireless Alert Solution",
  "WIREMASTER",
  "Wiremek",
  "Wiremek Control Systems",
  "Wiremek Control Systems Ltd",
  "WIREMEK LTD",
  "WIRLD DRYER",
  "WISEMAN",
  "Wispa",
  "Withy Grove Stores",
  "WITRONIC LIFT CONTROLLER",
  "Witt & Sohn",
  "Wittenborg",
  "wittur",
  "WIZARD",
  "WKS / Mul-T-Lock",
  "wllams",
  "WLM",
  "WM. ALLAN SMITH & CO. LTD.",
  "WMEM",
  "WMF",
  "WMT",
  "WNDER",
  "WNG BACK STEPS",
  "wnix",
  "WODEN",
  "WODEN TRANFORMERS CO LTD",
  "WOELS HANS DRYER INC",
  "WOHNER",
  "Wokf",
  "WOLDELY CO.UK.",
  "Wolesley",
  "WOLESLEY CENTRE",
  "Wolf",
  "WOLF (DELRAC)",
  "WOLF ELECTRONIC TOOLS",
  "WOLF KLIM",
  "Wolf Klimatechnik",
  "Wolseley",
  "Wolsey",
  "Wolsey Center",
  "WOLSLEY",
  "Wolter",
  "Woltex",
  "WONBON CO. LTD",
  "Wonbong",
  "WONBONG CO LTD",
  "WONBONG LTD",
  "Wonderlfe",
  "WONGBONG LTD",
  "WOOD AIR MOVEMENT",
  "Wood Air Pac",
  "Wood Airpac",
  "Wood Roof Unit",
  "WOODALL DUCKHAM",
  "Woodborough Control Systems.",
  "Woodborough Technical Services",
  "Woodborough Technical Services.",
  "Woodcock & Wilson",
  "WOODCOCK WILSON",
  "WOODEN",
  "Woodind prod. corp.",
  "Woodleigh",
  "Woodleigh Ventilation",
  "Woodley",
  "WOODS",
  "WOODS (JM AIROFOIL FLAMEPROOF FAN)",
  "WOODS AIR MOVEMENT",
  "Woods Air Pac",
  "WOODS AIRPAC",
  "Woods FLAKT",
  "WOODS OF COLCHESTER LTD",
  "Woods Aerofoil",
  "WOODSIDE",
  "WOODS-OZONAIR",
  "WOODSTAR",
  "WOODSTOCK",
  "WOODSTOCK & WILSON LTD.",
  "WOODWARD",
  "WOODWARD DOOR CONTROLS",
  "WOODWOOD",
  "WOODWOOD /MACWOOD ?",
  "WOOLWORTHS",
  "Worcester",
  "WORCESTER ?",
  "Worcester Bosch",
  "Worchester",
  "WORD DRYERS",
  "WORKD",
  "WORKD DEYER COROORATION",
  "WORKING AT HEIGHT. COM",
  "WORKING ENVIRONMENTS LTD.",
  "WORKY",
  "WORL",
  "WORL HOWARD LTD",
  "WORLD",
  "WORLD BOLLARD SUPPLY",
  "WORLD CRYER",
  "WORLD DEYER",
  "WORLD DRIER.",
  "World Dry",
  "WORLD DRYER",
  "WORLD DRYER COP.",
  "WORLD DRYER COR.",
  "WORLD DRYER CORP",
  "WORLD DRYER CORP LTD",
  "WORLD DRYER CORP. - WARNER HOWARD",
  "WORLD DRYER CORPORATION",
  "WORLD DRYER INC",
  "WORLD DYER",
  "WORLD FORCE",
  "WORLD HAND DRYERS INC",
  "WORLD OF CATERING",
  "WORLD WIDE",
  "WORLD WIDE DRIERS",
  "WORLD WIDE DRYERS",
  "World Wind",
  "World Class Vent",
  "WORLD DRYER",
  "World Dryer CORP",
  "WORLDWIDE",
  "Worldwide Dryer Ground",
  "WORLDWIDE DRYER GROUP",
  "Worldwind",
  "WORLMALD",
  "Wormald",
  "WORMALD FIRE SYSTEMS",
  "WORMALD TANK",
  "Wormald/Atlas Copco",
  "Wormall",
  "WORMLD FIRE SYSTEMS",
  "WORMWA.LD",
  "Worn Data Plate Unknown",
  "WORTHINGTON",
  "WORTHINGTON & SIMPSON",
  "Worthington Creyssensac",
  "Worthington-Creyssensac",
  "WORWERK",
  "WOT",
  "WOT SECURITY GROUP",
  "WOZAIR",
  "WPI",
  "WPI Ltd",
  "WPL",
  "WPL LTD",
  "WPRLD",
  "WPS",
  "Wras",
  "WRAS DELTAFLOW TECH",
  "wrcnsk",
  "Wrg",
  "Wrington",
  "WRITE MASTER",
  "WRITE WATCHER",
  "WRITEMASTER",
  "WRMALD FIRE SYSTM",
  "WS ATKINS",
  "WS CONTROLS LTD",
  "WS7",
  "WSP",
  "WSP WILO Salmon Pumps",
  "WT SERVICES",
  "WTBSERVICES",
  "WWW.TELEPHONE ENTRY.CO.UK",
  "Wychwood",
  "WYCHWOOD WATER SYSTEMS LTD",
  "wyco",
  "Wyexley",
  "Wykamol",
  "WYKAMOL GROUP",
  "WYKO",
  "WYLE",
  "WYLEC",
  "WYLEF",
  "WYLEX",
  "Wylex ?",
  "wylexn",
  "WYLO",
  "WYNLEX",
  "WYNN",
  "wyrex",
  "x tract",
  "xam",
  "XCELERATOR",
  "xdh",
  "Xelorator",
  "XEN",
  "XENEX",
  "XGQ",
  "XHAUS",
  "XIDER",
  "XL Refrigeration",
  "xl refrigerators ltd",
  "Xlerator",
  "XLERATOR EXCEL DRYER",
  "X-Mart",
  "Xodas",
  "XODOS",
  "Xodus No.",
  "XOELAIR",
  "XOP",
  "XP95",
  "Xpekair",
  "XPELAIR",
  "Xpel-Air",
  "Xpelair classic",
  "Xpelait",
  "xplaire",
  "X-POT",
  "X-ray acc",
  "X-Ray Everything",
  "X-ray unit",
  "XTALIS",
  "XTRA",
  "XTRA FOSTER",
  "XTRACT",
  "X-TRACT",
  "XTRAILS",
  "XTRALIS",
  "Xtralis AG Pry Ltd.",
  "XTRALIS VLP",
  "Xtralls",
  "XX",
  "XXX",
  "xxxxx",
  "Xylem",
  "XYLEM       254010",
  "XYLEM FLYGT",
  "Xylem Inc",
  "XYLEM WATER SOLUTIONS",
  "XYNTAX",
  "XYZ",
  "y",
  "Y YEXLEY",
  "Y.YEXLEY",
  "Yale",
  "YALE  UNITED CRANES",
  "yamaha",
  "Yamitsu",
  "YANMAR",
  "YASHICA",
  "YASHIGAMI",
  "Yaskawa",
  "YATES",
  "YBS",
  "Yeates & Son",
  "YEDL",
  "yexley",
  "YITHIN",
  "YML / Sparks",
  "YMS",
  "YOKO",
  "YOKOGAWA",
  "YORK",
  "YORK - JOHNSON CONTROLS",
  "YORK (BALTIMORE AIRCOIL COMPANY",
  "York fitness",
  "YORK INTERNATIONAL",
  "York ISN",
  "YORK UK",
  "YORK/JOHNSON CONTROLS",
  "YORKLEEN",
  "YORKLEEN LTD",
  "Yorkshire",
  "yorkshire  diesel power",
  "Yorkshire diesel power",
  "YORKSHIRE DOOR SYSTEMS",
  "YORKSHIRE ELECTRIC",
  "YORKSHIRE ELECTRICITY BOARD",
  "Yorkshire Lift Services Ltd",
  "YORKSHIRE LIFTS",
  "Yorkshire Switch Gear",
  "Yorkshire Switchgear",
  "Yorkshire Electric",
  "YORKTANK",
  "yorpower",
  "YOU XIN ELECTRIC MACHINERY CO. LTD.",
  "Young Austen & Young",
  "YOUNGERANS",
  "Youngman",
  "YOUNGMANS",
  "YOUNGMANS EASYWAY",
  "Yuasa",
  "YUASA CORPORATION",
  "Yula corp",
  "Yumi",
  "YX ENERGI",
  "Z",
  "z seiger honeywell",
  "Z8",
  "ZABAG",
  "Zallus",
  "ZAMAT",
  "Zancker",
  "ZANDER",
  "Zani",
  "ZANISSI",
  "Zanker",
  "ZANOLLI",
  "ZANOTTI",
  "ZANSSI",
  "Zantec",
  "ZANUSDI",
  "zanuss",
  "Zanussi",
  "ZANUSSI / ELECTROLUX",
  "ZANUSSI ELECTROLUX",
  "ZANUSSO",
  "ZANYSSI",
  "ZAP",
  "ZAPARO",
  "Zapper",
  "ZAREBA",
  "ZAREM",
  "Zarges",
  "ZCB",
  "zdfg",
  "ZEAG",
  "ZEAG (UK) LTD.",
  "Zebra",
  "ZEDLOK",
  "Zehl - Abegg",
  "ZEIHL",
  "ZEIHL -ABEGG",
  "ZEIHL-ABEGG",
  "ZELH-ABEGG",
  "Zellweger Analytics Ltd",
  "ZELMET",
  "ZELTEC",
  "ZENITH HYGIENE SYSTEMS",
  "Zenithair",
  "Zenner",
  "Zepher",
  "ZEPHIR",
  "Zephur",
  "ZEPHYR",
  "Zephyr Water Treatment",
  "ZERICA",
  "ZERO 88 LIGHTING",
  "Zero 88 Lighting Ltd",
  "ZERO CHILLI",
  "Zeron",
  "ZETA",
  "Zeta Alarm System",
  "Zeta Alarms",
  "zetadyn",
  "ZETAOTTO",
  "Zetaplex",
  "Zetrak",
  "Zettler",
  "Zettler-london",
  "ZHIEHL ABEGG",
  "ZIEHL",
  "ZIEHL - ABEGG",
  "ziehl abbegg",
  "ZIEHL ABEGG",
  "ZIEHL- ABEGG",
  "ZIEHL ADEGG",
  "Ziehl_Abegg",
  "Ziehl-Abegg",
  "ZIEHL-ABEGG KG",
  "ZIEHL-EBM",
  "ZIEL ADEGG",
  "ziemet",
  "Zieml Abegg",
  "zigor",
  "zigor ebro",
  "ZIHL-ABEGG",
  "ZILMAT",
  "Zilmeci",
  "ZILMER BOSS",
  "ZILMET",
  "Zilmet Lowara Flowmech",
  "Zilmet Red",
  "ZILMETT",
  "ZILTECT",
  "Zimmerman",
  "Zimmermann",
  "ZION",
  "Zip",
  "ZIP  RCH",
  "ZIP A",
  "Zip Aquaboill",
  "zip cool tap",
  "ZIP DUO 325121",
  "ZIP HEATERS UK",
  "Zip Hydroflo",
  "ZIP HYDROTAP BOOSTER",
  "ZIP Industries",
  "ZIP LINE",
  "ZIP RCH",
  "Zip Tudor",
  "ZIP TUDOR II",
  "Zip Varipoint 2",
  "ZIP XL",
  "ZIP XL WATER HEATER",
  "ZIP-CLAGE",
  "ZIPRCH",
  "ZipXL",
  "zircon",
  "ZIT",
  "Ziton",
  "ZMC HYDRALICS",
  "zodiac",
  "Zodion",
  "ZOELLER",
  "Zoim",
  "zoin",
  "ZOIN REFRIGERATION SRL",
  "ZOLL",
  "Zolmet",
  "ZOLTAC",
  "Zonder Ecosep S30",
  "Zone",
  "ZONE GREEN",
  "zone master",
  "ZONE VIEW",
  "ZONE VU",
  "ZONECHECK",
  "ZONEGREEN",
  "ZONEGUARD",
  "zonherd",
  "ZOOSTOM",
  "ZOOSTORM",
  "ZOP",
  "Zr",
  "ZREFLEX",
  "Zriello UPS",
  "ZRM11",
  "Z-Tec",
  "ZUCCHIN",
  "ZUCCHINI",
  "Zucchni",
  "Zuccini",
  "ZUCKS",
  "ZULZER",
  "ZUMPTOBEL",
  "Zumtobel",
  "Zumtobel Staff",
  "ZUNUSSI",
  "ZUP",
  "Zurn",
  "ZURRER AG",
  "ZYCOMM",
];

export const manufacturers = manufacturerList.map((manufacturer) => {
  return {
    label: manufacturer,
    code: manufacturer,
  };
});
