import { useState, useEffect } from "react";
import { Typography, Menu, Dropdown, Modal, Badge, Tooltip } from "antd";
import {
  LogoutOutlined,
  DownloadOutlined,
  SwapOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { logOut } from "helpers/generalHelpers";
import { AccountIcon, BellIcon, Settings as SettingsIcon } from "components/Icons";
import { VERSION_NUMBER } from "config";
import SearchAPI from "./GlobalSearch/SearchAPI";
import AppSwitcher from "../AppSwitcher/AppSwitcher";
import "./AppHeader.scss";
import { fetchDequeuedItems, forceSync } from "helpers/forceSync";
import OnlineStatusIndicator from "./OnlineStatusIndicator/OnlineStatusIndicator";
import { fetchHierarchyManifest, hierarchyExists } from "helpers/hierarchyHelpers";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const DisplaySettingsMenu = () => {
  const { switchTenantsActive, setShowSwitchAccount, syncStatusHook: {isOnline} } = useEntityData()

  function tooltipText() {
    if (!isOnline) return 'You can\'t switch account whilst offline.'
    if (!switchTenantsActive) return 'You can\'t switch account as you only have access to 1 account.'
    return 'Click here to switch account.'
  }

  const [hierarchyManifest, setHierarchyManifest] = useState()

  const fetchLatestTime = async () => {
    const exists = await hierarchyExists(window.tenantId)
    if (!exists) return
    const manifest = await fetchHierarchyManifest()
    setHierarchyManifest(manifest)
  }
  
  useEffect( () => {
    fetchLatestTime()
  }, [])

  return (
    <Menu>
      <Tooltip
        title={tooltipText()}
        placement={'bottom'}
      >
        <div>
          <Menu.Item
            icon={<SwapOutlined />}
            key="select-account"
            onClick={() => { setShowSwitchAccount((showSwitchAccount) => !showSwitchAccount) }}
            disabled={!isOnline || !switchTenantsActive}
          >
            Select Account
          </Menu.Item>
        </div>

      </Tooltip>

      <Menu.Divider />
      <Menu.Item
        key="select-account"
        onClick={(e) => {
          e.preventDefault()
          fetchLatestTime()
        }}
      >
        {!hierarchyManifest ? 'Hierarchy does not exist' : `Hierarchy Last Updated at: ${hierarchyManifest?.lastDownloaded && (new Date(hierarchyManifest?.lastDownloaded)).toLocaleString()}`}
      </Menu.Item>
    </Menu>
  );
}


export default function AppHeader() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dequeuedCount, setDequeuedCount] = useState(0);

  const { syncStatusHook: {isOnline, isSyncingData}, user, tenantData, floors, spaces, assets, facilities, restartDataStore } = useEntityData()

  useEffect(() => {
    fetchDequeuedItems().then((items) => setDequeuedCount(items.length || 0));
  }, [isSyncingData]);

  function displayAccountMenu() {
    return (
      <Menu>
        <Menu.Item
          icon={<DownloadOutlined />}
          key="force-sync-database"
          onClick={() => {
            Modal.confirm({
              title:
                "If data has become isolated from the Data Lake, please send the file to ELIAS Support.",
              icon: <DownloadOutlined />,
              okText: "Download",
              cancelText: "Cancel",
              onOk: async () => {
                forceSync({ 
                  facilities, 
                  floors: floors.items, 
                  spaces: spaces.items, 
                  assets 
                });
              },
            });
          }}
        >
          Download Isolated Data
        </Menu.Item>
        <Menu.Item
          icon={<RetweetOutlined />}
          key="restart-database"
          onClick={() => {
            Modal.confirm({
              title:
                "Restarting the Data Storage could cause data loss on some devices. Are you sure you want to perform this action?",
              icon: <RetweetOutlined />,
              okText: "Yes",
              cancelText: "Cancel",
              onOk: async () => {
                restartDataStore();
              },
            });
          }}
        >
          Restart DataStore
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="email" disabled>
          Email: {user?.idToken.payload.email}
        </Menu.Item>
        <Menu.Item key="tenant" disabled>
          Tenant: {tenantData.name}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          icon={<LogoutOutlined />}
          key="logout"
          onClick={() => logOut(isOnline)}
        >
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className="app-header">
      <p className="version-number">v{VERSION_NUMBER}</p>
      <div className="menu-button">
        <AppSwitcher />
      </div>
      <div className="menu-button-separator" />
      <Typography.Title
        level={1}
        className="header-title"
        style={{ maxWidth: "100%" }}
      >
        {tenantData.name}
      </Typography.Title>
      <div className="account-and-status">
        <SearchAPI
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
        />
        <div style={{ display: "flex", float: "right" }}>
          <OnlineStatusIndicator />
          <div style={{ paddingRight: dequeuedCount > 0 ? "20px" : undefined }}>
            <Badge count={dequeuedCount}>
              <div className="notification-button">
                <BellIcon />
              </div>
            </Badge>
          </div>
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={<DisplaySettingsMenu />}
          >
            <div className="settings-button">
              <SettingsIcon />
            </div>
          </Dropdown>
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={displayAccountMenu()}
          >
            <div className="account-button">
              <AccountIcon />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
