import React from "react";
import ReactDOM from "react-dom";
import { keys as localDbKeys, del as localDbDelete } from "idb-keyval";

import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import Cookie from "js-cookie";
import { getCurrentSession, isServerOnline } from "helpers/apiHelpers";

import "antd/dist/antd.css";
import "styles/generic.scss";
import "styles/table.scss";
import EntityDataProvider from "EntityDataContext/EntityDataContext";

Amplify.configure({
  ...awsExports,
  // "aws_cognito_identity_pool_id": "eu-west-2:7c309729-ba37-4db5-b044-fc62409b2321",
  // "aws_cognito_region": "eu-west-2",
  // "aws_user_pools_id": "eu-west-2_mg5LjAtOO",
  // "aws_user_pools_web_client_id": "1qkd138setpi803vi8ef132gfj",
  API: {
    graphql_headers: async () => ({
      Authorization: (await Amplify.Auth.currentSession())
        .getIdToken()
        .getJwtToken(),
    }),
  },
});

async function clearOldErrorKeys() {
  //clear any error logs older than 7 days
  const allLocalDbKeys = await localDbKeys();
  const errorKeys =  allLocalDbKeys.filter((key) => key.startsWith("error"));
  errorKeys.forEach((key) => {
    try {
      if (new Date(key.substring(6,30)) < new Date(new Date().setDate(new Date().getDate()-7))) {
        localDbDelete(key);
        console.log('Expired error removed', key)
      }
    }
    catch {
      localDbDelete(key);
    }
  })
}

async function run() {
  clearOldErrorKeys()

  const queryStringParams = new URLSearchParams(window.location.search);
  const queryStringData = queryStringParams.get("data");

  let email = Cookie.get("login-email");
  let password = Cookie.get("login-password");
  let tenantId = Cookie.get("tenantId")
  window.tenantId = tenantId
  
  if (queryStringData) {
    Cookie.set("queryStringData", queryStringData);
    const decodedData = JSON.parse(atob(queryStringData));
    window.tenantId = decodedData.tenantId
    if (decodedData.email) {
      email = decodedData.email;
      password = decodedData.password;

      Cookie.set("login-email", decodedData.email, { expires: 999999 });
      Cookie.set("login-password", decodedData.password, { expires: 999999 });
      Cookie.set("main-app-url", decodedData.referrer, { expires: 999999 });
      Cookie.set("main-app-login-page", decodedData.loginPage, {
        expires: 999999,
      });
      Cookie.set("tenantId", decodedData.tenantId, {
        expires: 999999,
      });
    }
  }
  let isOffline = false;
  if (email && password) {
    try {
      
      let isOnline = await isServerOnline();
      isOffline = !Boolean(isOnline)
    } catch (e) {
      isOffline = true;
      try {
        await getCurrentSession();
        alert("Offline mode, proceeding without login");
      } catch (e) {
        alert("Application is offline, but there is no user session");
        return;
      }
    }

    if (!isOffline) {
      try {
        const response = await Amplify.Auth.signIn(email, password);
        console.log("sign in response = ", response);
      } catch (e) {
        alert("Please log in via the main app");
        console.error("Failed to log in:", e);
      }
    }
  } else {
    alert("Please log in via the main app");
  }

  ReactDOM.render(
    <React.StrictMode>
      <EntityDataProvider online={!isOffline}>
        <App />
      </EntityDataProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

run();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
