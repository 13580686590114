import {
  conditionValidation,
  installDateValidation,
  barcodeValidation,
  statusInaccessibleValidation,
  conditionDescriptionValidation,
} from "./formValidationHelpers";

export function formValidationRules({
  RegExp,
  byPassKey,
  validation,
  form,
  item,
  images,
  assetDefinition,
  isMandatory,
  key,
  barcodes,
  label,
  facetDefinition,
  validationRules,
  dependsOn,
  assetIdCharLimit,
  tenantData,
}) {
  if (validation?.allowByPass) {
    validationRules.push({
      validator: async (_, fieldValue) => {
        if (fieldValue !== undefined) {
          return;
        }

        const byPassFieldValue = form.getFieldValue(byPassKey);
        if (!byPassFieldValue) {
          throw new Error(`Please input the ${label}!`);
        }
      },
    });
  } else if (validation?.mandatory) {
    if (facetDefinition.facetId !== "uf10") {
      validationRules.push({
        required: isMandatory,
        message: `Please input the ${label}!`,
      });
    }
  }

  let commonValidationParams = {
    item,
    form,
    images,
    assetDefinition,
  };

  switch (key) {
    case "facets_condition":
      validationRules.push({
        required: true,
        validator: async (_, fieldValue) => {
          await conditionValidation({ ...commonValidationParams, fieldValue });
        },
      });
      break;

    case "facets_install-date":
      validationRules.push({
        required: true,
        validator: async (_, fieldValue) => {
          await installDateValidation({
            ...commonValidationParams,
            fieldValue,
          });
        },
      });
      break;

    case "facets_barcode":
      validationRules.push({
        required: true,
        validator: async (_, fieldValue) => {
          barcodeValidation({
            ...commonValidationParams,
            fieldValue,
            barcodes,
            label,
            assetIdCharLimit,
            tenantData,
          });
        },
      });
      break;

    case "status":
      validationRules.push({
        required: true,
        validator: async (_, fieldValue) => {
          statusInaccessibleValidation({
            ...commonValidationParams,
            fieldValue,
          });
        },
      });
      break;

    case "notes_condition":
      validationRules.push({
        required: true,
        validator: async (_, fieldValue) => {
          conditionDescriptionValidation({
            ...commonValidationParams,
            fieldValue,
          });
        },
      });
      break;
  }

  if (validation?.isRegEx) {
    validationRules.push({
      required: true,
      validator: async (_, fieldValue) => {
        if (fieldValue && !new RegExp(validation.value).test(fieldValue)) {
          throw new Error(validation.errorMessage);
        }
      },
    });
  }

  if (validation?.allowByPass) {
    dependsOn.push(byPassKey);
  }

  if (facetDefinition.validation?.dependsOn) {
    dependsOn.push(...facetDefinition.validation?.dependsOn);
  }
}
