import React, { useState } from "react";
import { Drawer, Button, Form } from "antd";
import {DataStore} from '@reams/elias-store';
import { Asset } from "models";
import "styles/drawer.scss";
import SpacePicker from "./SpacePicker/SpacePicker";
import { useEntityData } from "EntityDataContext/EntityDataContext";

export default function MoveAssetDrawer(props) {
  const { asset, onClose, visible, parents, complete} = props;

  const { user, spaces } = useEntityData()

  const { space: currentSpace, floor, facility } = parents;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [space, setSpace] = useState();

  async function onSubmit() {
    const userEmail = user.idToken.payload.email;
    setIsLoading(true);
    if (asset && space) {
      const original = await DataStore.query(Asset, asset.id);
      const facets = JSON.stringify({
        ...original.facets,
        [`validation-status`]: "Asset updated",
      });
      await DataStore.save(
        Asset.copyOf(original, (updated) => {
          const chosenSpace = spaces.items[space.key]
          updated.updatedBy = userEmail;
          updated.spaceId = chosenSpace.id;
          updated.floorId = chosenSpace.floorId;
          updated.facilityId = chosenSpace.facilityId;
          updated.siteId = chosenSpace.siteId;
          updated.regionId = chosenSpace.regionId;
          updated.facets = facets;
        })
      );
      complete({ name: original.assetType.description, space: space.title });
    }

    onClose();
    setIsLoading(false);
  }

  return (
    <Drawer
      title={`Move asset ${asset.assetType.description}`}
      placement="right"
      onClose={onClose}
      visible={visible}
      className="move-asset-drawer"
      forceRender
      closable={false}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
      >
        <>
          <div className="form-group">
            <div className="checkbox-textarea-group">
              <SpacePicker
                onChange={setSpace}
                space={space}
                facility={facility}
                currentSpace={currentSpace}
                facilityLevel
              />
            </div>
          </div>
        </>
        <div className="form-buttons">
          <Button type="transparent" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={!space}
          >
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}
