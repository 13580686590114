import React from "react";
import { useState, useEffect } from "react";
import { Tag, Input } from "antd";
import "./style.scss";
const { CheckableTag } = Tag;

export default function FormTagsHelper({
  setFocus,
  onBlurTimeout,
  form,
  forceUpdate,
  item,
  tags,
}) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsToUse, setTagsToUse] = useState([]);

  const handleChange = ({ tag, checked, desc }) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);

    if (tag) {
      form.setFieldsValue({
        facets_conditionTags: nextSelectedTags,
      });
      return;
    }

    form.setFieldsValue({
      notes_condition: desc?.length === 0 ? undefined : desc,
    });
  };

  const conditionRating = form.getFieldValue("facets_condition");

  useEffect(() => {
    setTagsToUse(tags[conditionRating]);
    setSelectedTags([]);
  }, [conditionRating]);

  useEffect(() => {
    let assetTags = form.getFieldValue("facets_conditionTags");
    if (Array.isArray(assetTags)) {
      setSelectedTags(assetTags);
    }
  }, []);

  return (
    <>
      <Input.TextArea
        className="condition_description"
        defaultValue={item ? form.getFieldValue("notes_condition") : null}
        onFocus={() => setFocus(true)}
        onBlur={() => onBlurTimeout()}
        onChange={(description) => {
          handleChange({ desc: description.target.value });
          form.validateFields(["notes_condition"]);
          forceUpdate();
        }}
      />
      {tagsToUse && (
        <>
          <div className="tags-container">
            {tagsToUse.map((tag, index) => {
              return (
                <CheckableTag
                  className="checkable-tag"
                  key={index}
                  checked={selectedTags?.indexOf(tag.label) > -1}
                  onChange={(checked) => {
                    handleChange({ tag: tag.label, checked });
                    form.validateFields(["notes_condition"]);
                    forceUpdate();
                  }}
                >
                  {tag.label}
                </CheckableTag>
              );
            })}
          </div>
          {selectedTags && (
            <div className="tag-and-description">
              {tagsToUse
                .filter((f) => selectedTags?.includes(f.label))
                .map((tagData, i) => {
                  const { label, desc } = tagData;
                  return (
                    <p key={i} style={{ margin: "0px" }}>
                      <strong> {`${label} `} </strong> {desc}
                    </p>
                  );
                })}
            </div>
          )}
        </>
      )}
    </>
  );
}
