export function getFabricElementGroupSections({ category, fabricAssets }) {
  // console.log("category = ", category, "fabricAssets = ", fabricAssets);
  let sections = [];
  category.children.forEach((section) => {
    let fabricAssetsInSection = fabricAssets.filter((asset) =>
      asset.facets?.selectorId.startsWith(`${section.selectorId}.`)
    );
    if (fabricAssetsInSection.length > 0) {
      sections.push({
        ...section,
        fabricAssets: fabricAssetsInSection,
        asset: fabricAssets.find(
          (asset) => asset.facets?.selectorId === section.selectorId
        ),
      });
    }
  });

  sections.forEach((section) => {
    let sectionGiaPercentage = section.asset?.facets?.giaPercentage;
    if (sectionGiaPercentage !== undefined && sectionGiaPercentage !== null) {
      section.percentageOfParent = sectionGiaPercentage;
    } else {
      section.percentageOfParent = sections.length === 1 ? 100 : 0;
    }
    section.percentageCompleted = section.fabricAssets.reduce(
      (sum, fabricAsset) => {
        return sum + fabricAsset.facets.giaPercentage;
      },
      0
    );

    let validationStatus;
    if (section.percentageCompleted === 0) {
      validationStatus = "WHITE";
    } else if (section.percentageCompleted < 100) {
      validationStatus = "RED";
    } else if (section.percentageOfParent === 0) {
      validationStatus = "RED";
    } else if (section.percentageCompleted > 100) {
      validationStatus = "AMBER";
    } else if (section.percentageCompleted === 100) {
      validationStatus = "GREEN";
    }
    section.validationStatus = validationStatus;

    section.priority = section.fabricAssets[0].facets.priority;
    section.condition = section.fabricAssets[0].facets.condition;

    for (let fabricAsset of section.fabricAssets) {
      if (fabricAsset.facets.condition > section.condition) {
        section.condition = fabricAsset.facets.condition;
      }
      if (fabricAsset.facets.priority < section.priority) {
        section.priority = fabricAsset.facets.priority;
      }
    }
  });
  return sections;
}

export function getGroupValidationStatus({ sections, category }) {
  let validationMessages = [];
  let sumOfSections = sections.reduce(
    (sum, section) => sum + section.percentageOfParent,
    0
  );
  let allAreGreen = sections.every(
    (section) => section.validationStatus === "GREEN"
  );

  let anyAreRed = sections.some(
    (section) => section.validationStatus === "RED"
  );

  let anyAreAmber = sections.some(
    (section) => section.validationStatus === "AMBER"
  );

  let sectionsWithBrokenValidationRules = category.children.filter(
    (section) => {
      let sectionIsMandatory = section.validation?.mandatory;
      let sectionIsPresent = sections.some(
        (section) => section.selectorId === section.selectorId
      );
      if (sectionIsMandatory && !sectionIsPresent) {
        section.validationNotes =
          section.validationNotes || `You must choose ${section.type}`;
        return true;
      }
      if (section.validation?.andOr) {
        let atLeastOneAndOrSectionIsPresent = false;
        section.validation.andOr.forEach((selectorId) => {
          sections.forEach((section) => {
            if (section.selectorId === selectorId) {
              atLeastOneAndOrSectionIsPresent = true;
            }
          });
        });
        return !atLeastOneAndOrSectionIsPresent;
      }
    }
  );

  sectionsWithBrokenValidationRules.forEach((section) => {
    if (!validationMessages.includes(section.validationNotes)) {
      validationMessages.push(section.validationNotes);
    }
  });

  let overallValidationStatus;

  if (sectionsWithBrokenValidationRules.length === 0 && sections.length === 0) {
    overallValidationStatus = "WHITE";
  } else if (sumOfSections < 100) {
    overallValidationStatus = "RED";
  } else if (anyAreRed) {
    overallValidationStatus = "RED";
  } else if (sectionsWithBrokenValidationRules.length > 0) {
    overallValidationStatus = "RED";
  } else if (anyAreAmber) {
    overallValidationStatus = "AMBER";
  } else if (sumOfSections > 100) {
    overallValidationStatus = "AMBER";
  } else if (allAreGreen && sumOfSections === 100) {
    overallValidationStatus = "GREEN";
  } else {
    overallValidationStatus = "WHITE";
  }
  return { overallValidationStatus, validationMessages };
}
