import { useState } from "react";
import { Table, Typography } from "antd";
import { DEFAULT_NUMBER_OF_ITEMS_IN_VIEW } from "config";
import DataViewSwitch from "components/DataViewSwitch/DataViewSwitch";
import { BulbOutlined, WalletOutlined } from "@ant-design/icons";
import GridView from "components/GridView/GridView";
import { FloorIcon, SpaceIcon, AssetIcon } from "components/Icons";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const ItemTableGrid = ({
  items,
  title,
  columns,
  actions,
  render,
}) => {
  const {tableViewState} = useEntityData()
  const [itemCountInView, setItemCountInView] = useState(
    DEFAULT_NUMBER_OF_ITEMS_IN_VIEW
  );
  const [dataSwitch, setDataSwitch] = tableViewState;
  return (
    <>
      <div className="table-header">
        <div style={{ display: "inline-flex" }}>
          <div className="listing-header-icon">
            {(title === "Spaces" && <SpaceIcon />) ||
              (title === "Floors" && <FloorIcon />) ||
              ((title === "Assets" || "All Assets") && <AssetIcon />)}
          </div>
          <Typography.Title className="subtitle" level={3}>
            {title}
            <Typography.Text className="results">
              {" "}
              - {items.length} results
            </Typography.Text>
          </Typography.Title>
        </div>

        <DataViewSwitch onChange={setDataSwitch} option={dataSwitch} />
      </div>
      {dataSwitch === "table" && (
        <Table
          scroll={{ x: "max-content" }}
          rowKey="id"
          dataSource={items}
          showSorterTooltip={false}
          columns={columns}
          pagination={{
            onChange: (curent, size) => {
              setItemCountInView(size);
            },
            pageSize: itemCountInView,
            pageSizeOptions: [10, 20, 30, 50],
            showSizeChanger: true,
          }}
        />
      )}
      {dataSwitch === "grid" && (
        <GridView
          records={items}
          actions={actions}
          render={render}
          displayCover
          title={(a) => {
            return (
              <>
                {a?.assetType?.virtual === true && a?.assetType ? (
                  <BulbOutlined />
                ) : (
                  <WalletOutlined />
                )}{" "}
                {a.name}
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default ItemTableGrid;
