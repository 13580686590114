import { Menu } from "antd";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import cx from "classnames";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import ButtonPair from "components/ButtonPair/ButtonPair";
import { FoldIcon } from "components/Icons";
import "./PageHeader.scss";
import { useEntityData } from "EntityDataContext/EntityDataContext";
import { useMemo } from "react";
import { getLocationTreeIds } from "helpers/locationTreeHelpers";

export default function PageHeader({
  pageHistory,
  removePagesFromHistory,
  isCollapsed,
  setIsCollapsed,
}) {
  const { facilities, spaces, floors } = useEntityData()
  const { facilityId, floorId, spaceId } = useParams();
  const navigate = useNavigate();
  const matchAssets = useMatch("/assets");
  
  const {space, floor, facility} = useMemo(() => getLocationTreeIds((spaceId || facilityId || floorId), facilities, floors, spaces), [floorId, spaceId, facilityId, spaces, floors, facilities])
  function displayBreadcrumbs() {
    let breadcrumbs = [];
    if (matchAssets) {
      breadcrumbs.push({
        url: `/assets`,
        label: "All Assets",
      });
    }

    if (facility) {
      breadcrumbs.push({
        url: `/facility/${facility.id}`,
        label: facility.name,
      });
    }
    if (floor) {
      breadcrumbs.push({
        url: `/floor/${floor.id}`,
        label: floor.name,
      });
    }
    if (space) {
      breadcrumbs.push({
        url: `/space/${space.id}`,
        label: space.name,
      });
    }

    if (!breadcrumbs || breadcrumbs.length === 0) {
      return <div className="breadcrumbs-placeholder" />;
    }

    return <Breadcrumbs breadcrumbs={breadcrumbs} />;
  }

  function displayHistoryOptions() {
    if (!pageHistory || pageHistory.length <= 1) {
      return;
    }

    return (
      <Menu>
        {pageHistory.slice(1).map((pageName, index) => (
          <Menu.Item
            key={index}
            onClick={() => {
              removePagesFromHistory(index + 1);
              navigate(-(index + 1));
            }}
          >
            {pageName}
          </Menu.Item>
        ))}
      </Menu>
    );
  }

  return (
    <div className={cx("page-header", { "fold-icon-collapsed": isCollapsed })}>
      <div style={{ display: "flex" }}>
        <div
          className="fold-icon"
          onClick={() => setIsCollapsed((isCollapsed) => !isCollapsed)}
        >
          <FoldIcon />
        </div>
        {displayBreadcrumbs()}
      </div>
      <ButtonPair
        label="Back"
        disabled={!pageHistory?.length || pageHistory.length <= 1}
        onClick={() => {
          removePagesFromHistory(1);
          navigate(-1);
        }}
        options={displayHistoryOptions()}
      />
    </div>
  );
}
