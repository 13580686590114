import React, { useState, useEffect } from "react";
import {
  getAntTreeFavouriteSpaces,
  getAntTreeFromFacilities,
} from "helpers/hierarchyHelpers";
import GoButton from "./GoButton/GoButton";
import "./LocationSearch.scss";
import SearchableTree from "components/SearchableTree/SearchableTree";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const LocationSearch = ({
  space: currentSpace,
}) => {
  const {favouriteSpacesState, floors, spaces } = useEntityData()

  const [favouriteSpaces] = favouriteSpacesState;
  const [treeData, setTreeData] = useState();
  const [selected, setSelected] = useState();

  const { facilities, facilitiesToDownload } = useEntityData()
  
  function onChange(r) {
    const x = {...r[0] }
    // get id from favourite spaces
    if (x.key.includes('favourite-')) {
      x.key = x.key.substring('favourite-'.length)
    }
    setSelected(x);
  }


  useEffect(() => {
    const facilitiesArray = facilitiesToDownload
      .map(fId => facilities?.[fId] || null)
      .filter(x => x)
    if (!Object.keys(spaces.items || {}).length || 
      !facilitiesArray.length || 
      !floors.items 
    ) return

    let tree = getAntTreeFromFacilities(
      facilitiesArray,
      spaces,
      currentSpace,
      floors
    )

    let s = [];
    if (favouriteSpaces.length !== 0) {
      s = getAntTreeFavouriteSpaces(favouriteSpaces, spaces.items)
    }

    tree = [...s, ...tree];
    setTreeData(tree);
    
  }, [
    spaces,
    facilities,
    floors,
    currentSpace,
    facilitiesToDownload,
    favouriteSpaces,
  ]);

  return (
    <>
      <SearchableTree
        onChange={(node) => onChange(node)}
        value={selected?.title}
        label={"Location Select"}
        treeData={treeData}
        searchType={"field"}
        variant={"filter"}
        type={"single"}
        searchLabel={"Search Location..."}
      />
      <GoButton selected={selected} />
    </>
  );
};

export default LocationSearch;
