import React, { useEffect, useRef, useState } from "react";
import { Input, Tree, Typography, Tag } from "antd";
import cx from "classnames";
import { ChevronDownIcon, ChevronLeftIcon } from "components/Icons";

import "./SearchableTree.scss";
import { pruneChildren } from "helpers/parentChildHelpers";
import _ from "lodash";

let tempExpandedKeys = [];

function useCloseDropdown(ref, setIsOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

/**
 *
 * @callback onChange
 * @param {string | string[]} selectedData
 * @returns {void}
 */

/**
 *
 * @callback onCloseTag
 * @param {string} id
 * @returns {void}
 */

/**
 * @typedef TSearchableTree
 * @property { undefined | 'typerhead' | 'field' } searchType
 * @property { 'single' | 'multiple' } type
 * @property { 'filter' | 'form' } variant
 * @property {string} label
 * @property {string} searchLabel
 * @property {onChange} onChange
 * @property {onCloseTag} onCloseTag
 */

/**
 *
 * Searchable Tree component
 *
 * @param {TSearchableTree} TSearchableTree
 * @returns {React.FC} SearchableTree
 */
export default function SearchableTree({
  variant = "form",
  type = "single",
  searchType,
  searchLabel = "Search...",
  onChange,
  onCloseTag,
  icon,
  value,
  label,
  defaultValue,
  multiple = false,
  children,
  treeData,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [searchResults, setSearchResults] = useState();
  const [expandedKeys, setExpandedKeys] = useState([]);

  const wrapperRef = useRef(null);
  useCloseDropdown(wrapperRef, setIsOpen);

  useEffect(() => {
    if (!treeData) {
      return;
    }
    if (searchValue.length <= 3) {
      setExpandedKeys([]);
      setSearchResults(treeData);
      return;
    }
    performSearch(treeData);
  }, [searchValue, treeData]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedKeys(value.map((v) => v.id));
      return;
    }
    if (value) {
      setSelectedKeys([value]);
      return;
    }
    setSelectedKeys([]);
  }, [value]);

  function performSearch(td) {
    let searchValueLowerCase = searchValue.toLowerCase();
    const annotatedTree = _.cloneDeep(td);
    
    tempExpandedKeys = [];
    annotatedTree.forEach((parent) =>
      annotateTree({ parent, searchValueLowerCase, parents: [] })
    );
    setExpandedKeys(tempExpandedKeys);
    const results = pruneChildren(annotatedTree);

    setSearchResults(results);
  }

  function childrenFound(children) {
    children = children.map((child) => {
      if (child.children) {
        child.children = childrenFound(child.children);
      }
      child.found = true;
      return child;
    });
    return children;
  }

  function annotateTree({ parent, searchValueLowerCase, parents }) {
    if (
      searchValueLowerCase.length >= 3 &&
      (typeof parent.title === "string" ? 
        parent.title : 
          typeof parent.title.props?.children?.[1] === "string" ? 
          parent.title.props?.children?.[1] : "")
        .toLowerCase()
        .includes(searchValueLowerCase)
    ) {
      parent.found = true;
      if (parent.children) {
        tempExpandedKeys.push(parent.key);
      }
      parents.forEach((x) => {
        tempExpandedKeys.push(x.key);
        x.found = true;
      });
      if (parent.children) {
        parent.children = childrenFound(parent.children);
      }
    } else {
      if (!parent.found) {
        parent.found = false;
      }
    }

    if (parent.children) {
      parent.children.forEach((child) =>
        annotateTree({
          parent: child,
          searchValueLowerCase,
          parents: [...parents, parent],
        })
      );
    }
  }

  function onSelect(selectedKeys, { selected, selectedNodes }) {
    setIsOpen(false);
    onChange(selectedNodes);
  }

  function onExpand(_, { node, expanded }) {
    if (expanded) {
      expandNode(node);
    } else {
      collapseNode(node);
    }
  }

  function collapseNode(node) {
    setExpandedKeys((keys) => keys.filter((key) => key !== node.key));
  }

  function expandNode(node) {
    setExpandedKeys((keys) => [...keys, node.key]);
  }
  return (
    <div className="searchable-tree-container" ref={wrapperRef}>
      {label && variant === "form" && (
        <Typography.Text className="label">{label}</Typography.Text>
      )}
      <div
        className={
          variant === "form"
            ? cx("dropdown", "form-variant")
            : cx("dropdown", "filter-variant")
        }
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <Typography.Text>
          {!Array.isArray(value)
            ? value || label
            : value.map((v) => (
                <Tag closable key={v.id} onClose={() => onCloseTag(v.id)}>
                  {v.description}
                </Tag>
              ))}
        </Typography.Text>
        {icon ? icon : <ChevronDownIcon />}

        {isOpen && (
          <div
            className="searchable-tree"
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            {searchType === "field" && (
              <Input
                autoFocus
                className="search-input"
                value={searchValue}
                placeholder={searchLabel}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            )}
            <Tree
              expandedKeys={expandedKeys}
              defaultSelectedKeys={selectedKeys}
              selectedKeys={selectedKeys}
              multiple={multiple}
              switcherIcon={
                <div>
                  <ChevronLeftIcon />
                </div>
              }
              onSelect={onSelect}
              onExpand={onExpand}
              treeData={searchResults}
            />
            {children}
          </div>
        )}
      </div>
    </div>
  );
}
