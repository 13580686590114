import { keys as localDbKeys } from "idb-keyval";
import AmplifyConfig from "../aws-exports";
import { API } from "aws-amplify";
import {DataStore} from '@reams/elias-store';
import { Asset, Space, Floor, Facility } from "models";
import md5 from "md5";
import {
  get as localDbGet,
  set as localDbSet,
  del as localDbDelete,
} from "idb-keyval";
import { makeImageLocalKey } from "helpers/imageHelpers";

const GetModelFromName = {
  Asset: Asset,
  Space: Space,
  Floor: Floor,
  Facility: Facility,
};

const MAX_RANDOM_NUMBER_GENERATED = 1000000;
const BYTES_IN_KILOBYTE = 1024;
const STANDARD_IMAGE_SIZE_KB = 30;
const STANDARD_IMAGE_UPLOAD_TIME_MILLISECONDS = 4500;
const FACILITY_MODEL_NAME = "Facility";
const UNDEFINED_DATA_URI_ERROR = "dataUri undefined";

class ImageOutbox {
  processing = false;
  totalPending = 0;

  constructor() {
    if (ImageOutbox._instance) {
      return ImageOutbox._instance;
    }

    ImageOutbox._instance = this;

    this.start();
  }

  async start() {
    this.resume();
  }

  async getPendingImageIds() {
    const allLocalDbKeys = await localDbKeys();

    // get all keys that start with mutation for temporary backward compatability
    return allLocalDbKeys.filter((key) => key.startsWith("mutation"));
    
    //return allLocalDbKeys.filter((key) => key.startsWith("mutationv2"));
  }

  async getPendingImageTotal() {
    const pendingImagesIds = await this.getPendingImageIds();
    const total = pendingImagesIds.length;
    this.totalPending = total;
    return total;
  }

  async uploadImage(currentPendingImageKey, key, tenantId, facilityId, dataUri) {

    let itemUploaded = false
    let continueUpload = true


    let itemId = key.split('/')[0];
    let fileName = key.split('/')[1];

    const estimatedUploadTime = this.getEstimatedUploadTime(
      dataUri
    );
    const t0 = performance.now();
    let t1 = null;

    await API.post("rest", "/items/image", {
      body: {
        tenantId,
        key: fileName,
        dataUri,
        id: itemId,
      },
      response: false,
    })
      .then(async () => {

        itemUploaded = true
        // Image successfully uploaded to S3
        t1 = performance.now();

        await localDbDelete(currentPendingImageKey);

        console.info("Image uploaded to S3");

        this.totalPending = this.totalPending - 1;

        if (t1 - t0 > estimatedUploadTime) {
          continueUpload = false;
          console.info(
            "Slow connection detected. Pausing upload for later: ",
            itemId
          );
        }
      })
      .catch(async (e) => {
        // Image unsuccessful in uploading to S3

        if (e.message === "Network Error") {
          throw e
        }
        console.error(
          `Failed to update ${itemId} images. S3 Responded with error ${e}`
        );
        continueUpload = false;
      });

      return {itemUploaded, continueUpload}
    
  }

  async resume() {
    if (this.processing) {
      return;
    }

    await this.getPendingImageTotal();

    this.processing = true;

    let pendingImagesIds = await this.getPendingImageIds();

    if (!pendingImagesIds || !pendingImagesIds.length) {
      this.pause();

      return;
    }

    do {
      const currentPendingImageId = pendingImagesIds[0];

      if (!currentPendingImageId.startsWith("mutationv2")) {
        // legacy mutation which needs to be converted to v2
        const { item, modelName } = JSON.parse(await localDbGet(currentPendingImageId));
        const { env } = JSON.parse(await localDbGet("get-tenant-data"));

        const facilityId = modelName === FACILITY_MODEL_NAME ? item.id : item.facilityId;
        const model = GetModelFromName[modelName];
        const original = await DataStore.query(model, item.id);

        let updatedImages = [];

        if (item.images) {
          for (let i = 0; i < item.images.length; i +=1) {
            const currentPendingImage = item.images[i];
            const fileName= `${item.id}_${Date.now()}${Math.floor(
              Math.random() * MAX_RANDOM_NUMBER_GENERATED
            )}.jpeg`;
            
            const newImageParams = {
              facilityId,
              hash: currentPendingImage.hash,
              key: `${item.id}/${fileName}`,
              tenantId: item.tenantId,
            }
          
            await localDbSet(
              `mutationv2-${fileName}`,
              JSON.stringify(newImageParams)
            );

            updatedImages.push({
              hash: newImageParams.hash,
              picture: {
                bucket: `${env}-tenant-${item.tenantId}`,
                key: newImageParams.key,
                region: AmplifyConfig.aws_user_files_s3_bucket_region,
              },
            });
          }

          await localDbDelete(currentPendingImageId)
        }

        await DataStore.save(
          model.copyOf(original, updated => {
            updated.images = updatedImages;
          })
        );
        
        // get new images pending count
        pendingImagesIds = await this.getPendingImageIds();
        console.info("Pending uploads left: ", pendingImagesIds.length);

        continue;
      }

      const { facilityId, key, hash, tenantId } = JSON.parse(
        await localDbGet(currentPendingImageId)
      );

      try {

        const currentPendingImageKey = makeImageLocalKey({
          type: "pending",
          hash,
          facilityId,
        });

        const dataUri = await localDbGet(currentPendingImageKey);
        if (!dataUri) {
          throw new Error(UNDEFINED_DATA_URI_ERROR);
        }

        const {continueUpload, itemUploaded} = await this.uploadImage(currentPendingImageKey, key, tenantId, facilityId, dataUri)

        // if image has uploaded delete it
        if (itemUploaded) {
          await localDbDelete(currentPendingImageId);
        }

        // if we will stop uploading for whatever reason
        if (!continueUpload) {
          this.pause();
          continue;
        }

      } catch (e) {
        if (e.message === UNDEFINED_DATA_URI_ERROR) {
          // we can't find image locally so delete and forget about it
          await localDbDelete(currentPendingImageId);
        }
        if (e.message === "Network Error") {
          this.pause();
        }
      }

      // Get all remaining pending images
      pendingImagesIds = await this.getPendingImageIds();
      console.info("Pending uploads left: ", pendingImagesIds.length);
    } while (pendingImagesIds.length > 0 && this.processing);

    this.pause();
  }

  getEstimatedUploadTime(dataUri) {
    // Minimum upload speed of 1Mbps should allow 30kb of data to be uploaded in 4500ms
    const dataUriUploadSize = new Blob([dataUri]).size / BYTES_IN_KILOBYTE;
    const estimatedUploadTime = Math.ceil(
      (dataUriUploadSize / STANDARD_IMAGE_SIZE_KB) *
        STANDARD_IMAGE_UPLOAD_TIME_MILLISECONDS
    );

    return estimatedUploadTime;
  }

  pause() {
    this.processing = false;
  }
}

export default ImageOutbox;
