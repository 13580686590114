import React from "react";
import { Drawer } from "antd";
import AppSidebarContent from "./AppSidebarContent";

import "./AppSidebar.scss";

export default function SidebarAssetDraw(props) {
  const { notCollapsed, onClose, sidebarContentProps } = props;

  return (        
    <Drawer
      className="app-sidebar-drawer"
      closable={false}
      visible={notCollapsed}
      placement={"left"}
      onClose={onClose}
    >
      <div className="app-sidebar" style={{ maxWidth: "fit-content" }}>
        <AppSidebarContent
          {...sidebarContentProps}
        />
      </div>
    </Drawer>
  );
}
