export const floorTypesDictionary = {
  B000: "Basement",
  B001: "Basement 1",
  B002: "Basement 2",
  B003: "Basement 3",
  B004: "Basement 4",
  LGNDF: "Lower ground floor",
  GNDF: "Ground floor",
  "01FL": "1st floor",
  "02FL": "2nd floor",
  "03FL": "3rd floor",
  "04FL": "4th floor",
  "05FL": "5th floor",
  "06FL": "6th floor",
  "07FL": "7th floor",
  "08FL": "8th floor",
  "09FL": "9th floor",
  "10FL": "10th floor",
  "11FL": "11th floor",
  "12FL": "12th floor",
  "13FL": "13th floor",
  "14FL": "14th floor",
  "15FL": "15th floor",
  "16FL": "16th floor",
  "17FL": "17th floor",
  "18FL": "18th floor",
  "19FL": "19th floor",
  "20FL": "20th floor",
  "21FL": "21st floor",
  "22FL": "22nd floor",
  "23FL": "23rd floor",
  "24FL": "24th floor",
  "25FL": "25th floor",
  "26FL": "26th floor",
  "27FL": "27th floor",
  "28FL": "28th floor",
  "29FL": "29th floor",
  "30FL": "30th floor",
  "31FL": "31st floor",
  "32FL": "32nd floor",
  "33FL": "33rd floor",
  "34FL": "34th floor",
  "35FL": "35th floor",
  "36FL": "36th floor",
  "37FL": "37th floor",
  "38FL": "38th floor",
  "39FL": "39th floor",
  "40FL": "40th floor",
  ROOF: "Roof",
  EXTERNAL_AREAS: "External Areas",
};

export const floorTypes = Object.keys(floorTypesDictionary).map((key) => ({
  label: floorTypesDictionary[key],
  code: key,
}));
