import cx from "classnames";
export const EditIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cx("icon", className)}
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
    <line x1="16" y1="5" x2="19" y2="8"></line>
  </svg>
);

export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
  >
    <g>
      <path d="M9,11l3,3,8-8"></path>
      <path d="M20,12v6a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V6A2,2,0,0,1,6,4h9"></path>
    </g>
  </svg>
);

export const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className=""
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
  </svg>
);
export const StarFilledIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className=""
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path
      d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
      fill="currentColor"
    ></path>
  </svg>
);

export const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x="8" y="8" width="12" height="12" rx="2"></rect>
    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
  </svg>
);

export const MoveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M6 18h6a3 3 0 0 0 3 -3v-10l-4 4m8 0l-4 -4"></path>
  </svg>
);

export const AccountIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <circle cx="4" cy="4" r="4" transform="translate(8 3)"></circle>
    <path d="M6,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2"></path>
  </svg>
);

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="4" y1="7" x2="20" y2="7"></line>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
  </svg>
);

export const BellIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="22.677"
    height="24.97"
    viewBox="0 0 22.677 24.97"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2px"
    stroke="currentColor"
  >
    <g transform="translate(1 1)">
      <path
        d="M21.73,9.892a6.892,6.892,0,1,0-13.784,0C7.946,17.933,4.5,20.23,4.5,20.23H25.177s-3.446-2.3-3.446-10.338"
        transform="translate(-4.5 -3)"
      ></path>
      <path
        d="M19.379,31.5a2.3,2.3,0,0,1-3.974,0"
        transform="translate(-7.054 -9.675)"
      ></path>
    </g>
  </svg>
);

export const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="currentColor"
    focusable="false"
    strokeWidth="2px"
    width="1em"
    height="1em"
    fill="currentColor"
    ariahidden="true"
    className="icon"
  >
    <path
      d="M0,0H24V24H0Z"
      fill="none"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M6,9l6,6,6-6"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export const ChevronLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M0,0H24V24H0Z" fill="none" />
    <path
      d="M9,6l6,6L9,18"
      fill="none"
      stroke="#444f56"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
    />
  </svg>
);

export const FilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    focusable="false"
    className="icon"
  >
    <path
      d="M5.5,5h13A1,1,0,0,1,19,6.5L14,12v7l-4-3V12L5,6.5A1,1,0,0,1,5.5,5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
    ></path>
  </svg>
);

export const SearchIcon = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className="icon"
    data-icon="search"
    fill="currentColor"
    ariahidden="true"
  >
    <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
  </svg>
);

export const FloorIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    strokeWidth="1.5"
    strokeLinejoin="round"
    stroke="currentColor"
    fill="none"
  >
    <path
      d="M9.085,35.547v2.264c0,.13.145.237.321.237H22.45"
      transform="translate(-5.086 -21.784)"
    ></path>
    <path
      d="M22.451,12.27H9.085V8.949L17.165,4l7.92,4.949V21.7a.31.31,0,0,1-.321.3H9.406a.31.31,0,0,1-.321-.3v-1.92"
      transform="translate(-5.086 -1)"
    ></path>
  </svg>
);

export const SpaceIcon = () => (
  <svg
    className="icon"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    strokeLinejoin="round"
    stroke="currentColor"
    fill="none"
  >
    <g>
      <path
        d="M7.369,9.024v8.018a.981.981,0,0,0,.5.85L14.874,21.9a1,1,0,0,0,.991,0l7.009-4.009a.98.98,0,0,0,.5-.85V9.024a.98.98,0,0,0-.5-.85L15.864,4.164a1,1,0,0,0-.991,0L7.864,8.174A.981.981,0,0,0,7.369,9.024Z"
        transform="translate(-3.606 -1.521)"
      ></path>
      <path
        d="M39.53,22.633V17.542l2.5,1.384v5.116"
        transform="translate(-24.88 -9.542)"
      ></path>
      <line x2="7" y2="4" transform="translate(12 12.013)"></line>
      <line y1="4.323" x2="7.505" transform="translate(4.495 12.013)"></line>
      <line y2="8.775" transform="translate(12 3.238)"></line>
    </g>
  </svg>
);

export const AssetIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
    width="100%"
    height="100%"
  >
    <path d="M4,7V6A2,2,0,0,1,6,4H8"></path>
    <path d="M4,17v1a2,2,0,0,0,2,2H8"></path>
    <path d="M16,4h2a2,2,0,0,1,2,2V7"></path>
    <path d="M16,20h2a2,2,0,0,0,2-2V17"></path>
    <line y2="5" transform="translate(6 10)"></line>
    <line y2="5" transform="translate(18 10)"></line>
    <line y2="4" transform="translate(9 10)"></line>
    <line y2="4" transform="translate(12 10)"></line>
    <line y2="4" transform="translate(15 10)"></line>
  </svg>
);

export const TaskListIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <path d="M3.5,5.5,5,7,7.5,4.5"></path>
    <path d="M3.5,11.5,5,13l2.5-2.5"></path>
    <path d="M3.5,17.5,5,19l2.5-2.5"></path>
    <line x2="9" transform="translate(11 6)"></line>
    <line x2="9" transform="translate(11 12)"></line>
    <line x2="9" transform="translate(11 18)"></line>
  </svg>
);

export const FacilityIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="3" y1="21" x2="21" y2="21"></line>
    <line x1="9" y1="8" x2="10" y2="8"></line>
    <line x1="9" y1="12" x2="10" y2="12"></line>
    <line x1="9" y1="16" x2="10" y2="16"></line>
    <line x1="14" y1="8" x2="15" y2="8"></line>
    <line x1="14" y1="12" x2="15" y2="12"></line>
    <line x1="14" y1="16" x2="15" y2="16"></line>
    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16"></path>
  </svg>
);

export const MenuIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2px"
    stroke="currentColor"
  >
    <circle cx="1" cy="1" r="1" transform="translate(4 4)"></circle>
    <circle cx="1" cy="1" r="1" transform="translate(11 4)"></circle>
    <circle cx="1" cy="1" r="1" transform="translate(18 4)"></circle>
    <circle cx="1" cy="1" r="1" transform="translate(4 11)"></circle>
    <circle cx="1" cy="1" r="1" transform="translate(11 11)"></circle>
    <circle cx="1" cy="1" r="1" transform="translate(18 11)"></circle>
    <circle cx="1" cy="1" r="1" transform="translate(4 18)"></circle>
    <circle cx="1" cy="1" r="1" transform="translate(11 18)"></circle>
    <circle cx="1" cy="1" r="1" transform="translate(18 18)"></circle>
  </svg>
);

export const WarningIcon = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className="icon"
    data-icon="exclamation-circle"
    fill="currentColor"
    ariahidden="true"
  >
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
    <path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
  </svg>
);

export const TagIcon = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className="icon"
    data-icon="tag"
    width="1em"
    height="1em"
    fill="currentColor"
    ariahidden="true"
  >
    <path d="M938 458.8l-29.6-312.6c-1.5-16.2-14.4-29-30.6-30.6L565.2 86h-.4c-3.2 0-5.7 1-7.6 2.9L88.9 557.2a9.96 9.96 0 0 0 0 14.1l363.8 363.8c1.9 1.9 4.4 2.9 7.1 2.9s5.2-1 7.1-2.9l468.3-468.3c2-2.1 3-5 2.8-8zM459.7 834.7L189.3 564.3 589 164.6 836 188l23.4 247-399.7 399.7zM680 256c-48.5 0-88 39.5-88 88s39.5 88 88 88 88-39.5 88-88-39.5-88-88-88zm0 120c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path>
  </svg>
);

export const FoldIcon = () => (
  <svg
    className="icon"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path fill="none" stroke="none" d="M0,0H24V24H0Z"></path>
    <line
      fill="none"
      x1="10"
      transform="translate(10 12)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
    ></line>
    <line
      fill="none"
      x1="4"
      y2="4"
      transform="translate(16 12)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
    ></line>
    <line
      fill="none"
      x1="4"
      y1="4"
      transform="translate(16 8)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
    ></line>
    <line
      fill="none"
      y2="16"
      transform="translate(4 4)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
    ></line>
  </svg>
);

export const HomeIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2px"
    stroke="currentColor"
  >
    <path d="M27 12.349a4.188 4.188 0 00-1.664-3.109l-8.672-5.782a3.255 3.255 0 00-3.328 0L4.664 9.24A4.188 4.188 0 003 12.349v12a2.006 2.006 0 002 2h3.762a2.006 2.006 0 002-2v-7a2.006 2.006 0 012-2h4.47a2.006 2.006 0 012 2v7a2.006 2.006 0 002 2h3.765a2.006 2.006 0 002-2z"></path>
    <path
      d="M27 12.349a4.188 4.188 0 00-1.664-3.109l-8.672-5.782a3.255 3.255 0 00-3.328 0L4.664 9.24A4.188 4.188 0 003 12.349v12a2.006 2.006 0 002 2h3.762a2.006 2.006 0 002-2v-7a2.006 2.006 0 012-2h4.47a2.006 2.006 0 012 2v7a2.006 2.006 0 002 2h3.765a2.006 2.006 0 002-2z"
      strokeMiterlimit="10"
    ></path>
  </svg>
);

export const GridIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <rect width="6" height="6" rx="1" transform="translate(4 4)" />
    <rect width="6" height="6" rx="1" transform="translate(14 4)" />
    <rect width="6" height="6" rx="1" transform="translate(4 14)" />
    <rect width="6" height="6" rx="1" transform="translate(14 14)" />
  </svg>
);

export const TableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <rect width="16" height="16" rx="2" transform="translate(4 4)" />
    <line x2="16" transform="translate(4 10)" />
    <line y2="16" transform="translate(10 4)" />
  </svg>
);

export const ArrowDown = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M0,0H24V24H0Z" fill="none" stroke="none"></path>
    <line
      x2="14"
      transform="translate(5 12)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fill="none"
    ></line>
    <line
      y1="4"
      x2="4"
      transform="translate(15 12)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fill="none"
    ></line>
    <line
      x2="4"
      y2="4"
      transform="translate(15 8)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fill="none"
    ></line>
  </svg>
);

export const Analytics = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    fill="none"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
    strokeMiterlimit="10"
  >
    <rect width="5.625" height="16.25" rx="1.5" transform="translate(3.75 10)" />
    <rect width="5.625" height="12.5" rx="1.5" transform="translate(20.625 13.75)" />
    <rect width="5.625" height="22.5" rx="1.5" transform="translate(12.187 3.75)" />
  </svg>
)

export const ConditionSurvey = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    fill="none"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <path d="M3.5,5.5,5,7,7.5,4.5" />
    <path d="M3.5,11.5,5,13l2.5-2.5" />
    <path d="M3.5,17.5,5,19l2.5-2.5" />
    <line x2="9" transform="translate(11 6)" />
    <line x2="9" transform="translate(11 12)" />
    <line x2="9" transform="translate(11 18)" />
  </svg>
)

export const Energy = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <defs>
      <style>{`.cls-1,.cls-2,.cls-3{fill:none;}.cls-1{stroke:#ff1945;}.cls-1,.cls-2{stroke-linecap:round;stroke-linejoin:round;}.cls-2{stroke:#444f56;}`}</style>
    </defs>
    <g id="Warstwa_2" data-name="Warstwa 2" style={{ stroke: 'none', strokeWidth: '2px' }}>
      <g id="Warstwa_1-2" data-name="Warstwa 1">
        <g id="Energy_64">
          <polygon
            className="cls-1"
            points="31.98 22.73 23.58 35.93 31.97 35.93 31.97 44.23 40.37 31.27 31.98 31.27 31.98 22.73"
          />
          <rect className="cls-2" x="16.71" y="6.96" width="29.86" height="53.04" rx="3.72" />
          <path className="cls-2" d="M26.73,7v-2A.93.93,0,0,1,27.66,4H36a.93.93,0,0,1,.93.93V7" />
          <line className="cls-2" x1="21.09" y1="53.87" x2="46.57" y2="53.87" />
          <line className="cls-2" x1="16.71" y1="13.05" x2="42.31" y2="13.05" />
          <path className="cls-1" d="M43,44a15.87,15.87,0,0,1-22.43,0" />
          <path className="cls-1" d="M20.61,21.53a15.87,15.87,0,0,1,22.43,0" />
          <rect className="cls-3" width="64" height="64" />
        </g>
      </g>
    </g>
  </svg>
)

export const GridDots = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2px"
    stroke="currentColor"
  >
    <circle cx="1" cy="1" r="1" transform="translate(4 4)" />
    <circle cx="1" cy="1" r="1" transform="translate(11 4)" />
    <circle cx="1" cy="1" r="1" transform="translate(18 4)" />
    <circle cx="1" cy="1" r="1" transform="translate(4 11)" />
    <circle cx="1" cy="1" r="1" transform="translate(11 11)" />
    <circle cx="1" cy="1" r="1" transform="translate(18 11)" />
    <circle cx="1" cy="1" r="1" transform="translate(4 18)" />
    <circle cx="1" cy="1" r="1" transform="translate(11 18)" />
    <circle cx="1" cy="1" r="1" transform="translate(18 18)" />
  </svg>
)

export const InfoCircle = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <circle cx="9" cy="9" r="9" transform="translate(3 3)" />
    <line x2="0.01" transform="translate(12 8)" />
    <path d="M11,12h1v4h1" />
  </svg>
)

export const Management = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
    fill="none"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
    strokeMiterlimit="10"
  >
    <rect width="5.625" height="5.625" rx="1.5" transform="translate(20.625 12.188)" />
    <rect width="5.625" height="5.625" rx="1.5" transform="translate(20.625 20.625)" />
    <rect width="5.625" height="5.625" rx="1.5" transform="translate(12.188 20.625)" />
    <rect width="5.625" height="5.625" rx="1.5" transform="translate(3.75 20.625)" />
    <rect width="22.5" height="5.625" rx="1.5" transform="translate(3.75 3.75)" />
    <rect width="14.063" height="5.625" rx="1.5" transform="translate(3.75 12.188)" />
  </svg>
)

export const Phone = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="none"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
    strokeMiterlimit="10"
  >
    <defs>
      <style>
        {'.prefix__b{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'}
      </style>
    </defs>
    <rect className="prefix__b" width={10} height={16} rx={1} transform="translate(7 4)" />
    <path className="prefix__b" d="M11 5h2M12 17v.01" />
  </svg>
)

export const UAVDrone = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="none"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
    strokeMiterlimit="10"
  >
    <defs>
      <style>
        {'.prefix__a{fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.5px}'}
      </style>
    </defs>
    <path
      className="prefix__a"
      d="M10.723 8.505a1.229 1.229 0 012.458 0M9.711 15.25a1.131 1.131 0 01-1.122-1.141v-2.541a1.089 1.089 0 00-1.079-1.1h-.7a.86.86 0 010-1.72h10.313a.86.86 0 010 1.72h-.7a1.089 1.089 0 00-1.079 1.1v2.567a1.1 1.1 0 01-1.1 1.115"
    />
    <path
      className="prefix__a"
      d="M7.584 12.86h-.843a1 1 0 00-1 1v6.282M16.327 12.86h.843a1 1 0 011 1v6.282M18.199 9.7h2M5.8 9.7h-2M3.7 9.7V7.2M20.301 9.7V7.2M2.2 6.5h3M18.8 6.5h3"
    />
    <rect
      className="prefix__a"
      width={4.53}
      height={2.555}
      rx={1}
      transform="translate(9.703 14.601)"
    />
  </svg>
)

export const World = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="12" r="9" />
    <line x1="3.6" y1="9" x2="20.4" y2="9" />
    <line x1="3.6" y1="15" x2="20.4" y2="15" />
    <path d="M11.5 3a17 17 0 0 0 0 18" />
    <path d="M12.5 3a17 17 0 0 1 0 18" />
  </svg>
);

export const MapPin = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="11" r="3" />
    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
  </svg>
)

export const Building = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5px"
    stroke="currentColor"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="3" y1="21" x2="21" y2="21" />
    <line x1="9" y1="8" x2="10" y2="8" />
    <line x1="9" y1="12" x2="10" y2="12" />
    <line x1="9" y1="16" x2="10" y2="16" />
    <line x1="14" y1="8" x2="15" y2="8" />
    <line x1="14" y1="12" x2="15" y2="12" />
    <line x1="14" y1="16" x2="15" y2="16" />
    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
  </svg>
)

export const Stack = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 26 26" 
    stroke-width="1.5" 
    stroke="#000000" 
    fill="none" 
    stroke-linecap="round" 
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <polyline points="12 4 4 8 12 12 20 8 12 4" />
    <polyline points="4 12 12 16 20 12" />
    <polyline points="4 16 12 20 20 16" />
  </svg>
)

export const Settings = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2px"
    stroke="currentColor"
  >
    <g transform="translate(-2 -2)">
      <path d="M12.767,4.756a2.3,2.3,0,0,1,4.467,0,2.3,2.3,0,0,0,3.431,1.421,2.3,2.3,0,0,1,3.16,3.16,2.3,2.3,0,0,0,1.42,3.429,2.3,2.3,0,0,1,0,4.467,2.3,2.3,0,0,0-1.421,3.431,2.3,2.3,0,0,1-3.16,3.16,2.3,2.3,0,0,0-3.429,1.42,2.3,2.3,0,0,1-4.467,0,2.3,2.3,0,0,0-3.431-1.421,2.3,2.3,0,0,1-3.16-3.16,2.3,2.3,0,0,0-1.42-3.429,2.3,2.3,0,0,1,0-4.467A2.3,2.3,0,0,0,6.177,9.336a2.3,2.3,0,0,1,3.16-3.16,2.3,2.3,0,0,0,3.429-1.42Z" />
      <circle cx="3" cy="3" r="3" transform="translate(12 12)" />
    </g>
  </svg>
)