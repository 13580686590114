import { useEffect, useRef, useState } from "react";
import { Form, Radio } from "antd";
import { displayFormLabel } from "./formHelpers";
import { manufacturers } from "./manufacturers";
import { BYPASS_OPTIONS, BYPASS_OPTIONS_BARCODE } from "./drawerData";
import { formValidationRules } from "./formValidationRules";
import cx from "classnames";
import fetchFormControl from "./form/formControl";
import { useEntityData } from "EntityDataContext/EntityDataContext";
const conditionTags = require("../core/conditionTags.json");

function calculateBypassOptions(key) {
  if (key === "facets_barcode") {
    return BYPASS_OPTIONS_BARCODE;
  }
  return BYPASS_OPTIONS;
}

export function searchAndPrependList(setList, list, val) {
  const o = list.filter((o) =>
    o.label.toLowerCase().includes(val.toLowerCase())
  );
  if (!o.find((ob) => ob.code === val && val !== "")) {
    o.unshift({ label: val, code: val });
  }
  o.unshift({ label: "", code: "" });
  setList(o);
}

export function noPrefixOptions(barcodePrefixes) {
  if (barcodePrefixes === undefined) return true;
  return barcodePrefixes?.length < 1;
}

export function DisplayFieldForFacet({
  form,
  itemDefinition,
  facetDefinition,
  forceUpdate,
  images,
  assetDefinition,
  item,
}) {
  const {tenantData, assets: {barcodes}} = useEntityData()
  const [focus, setFocus] = useState(false);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const barcodePrefixes = tenantData?.barcodePrefixes;
  const arePrefixOptions = noPrefixOptions(barcodePrefixes);
  const assetIdCharLimit = tenantData?.barcodePrefixRegex;
  const blurTimeout = useRef(null);

  let validationRules = [];
  let dependsOn = [];

  useEffect(() => {
    if (type === "AutoBox") {
      const value = form.getFieldValue(key);
      if (!value) {
        setManufacturerOptions(manufacturers);
        return;
      }
      searchAndPrependList(setManufacturerOptions, manufacturers, value);
    }

    return () => {
      if (blurTimeout.current) {
        clearInterval(blurTimeout.current);
      }
    }
  }, []);

  if (!itemDefinition?.facets?.includes(facetDefinition?.facetId)) {
    return null;
  }

  let {
    type,
    key,
    options,
    label,
    validation,
    step,
    default: defaultValue,
  } = facetDefinition;
  let originalKey = key;

  if (facetDefinition.element && facetDefinition.element.length > 0) {
    key = `${facetDefinition.element}_${key}`;
  }

  let isDisabled = facetDefinition.disabled;
  let hasLabel = true;
  let isMandatory = validation?.mandatory;
  let valuePropName = undefined;


  function onBlurTimeout() {
    blurTimeout.current = setTimeout(() => {
      setFocus(false);
    }, 6000);
  }

  const value = form.getFieldValue(key);
  const formControl = fetchFormControl({
    type,
    item,
    key,
    options,
    step,
    forceUpdate,
    barcodePrefixes,
    form,
    facetDefinition,
    isMandatory,
    isDisabled,
    hasLabel,
    valuePropName,
    label,
    value,
    itemDefinition,
    manufacturerOptions,
    setManufacturerOptions,
    setFocus,
    onBlurTimeout,
    conditionTags,
  });
  const byPassKey = `byPass_${originalKey}`;

  formValidationRules({
    RegExp,
    byPassKey,
    validation,
    form,
    item,
    images,
    assetDefinition,
    isMandatory,
    key,
    barcodes,
    label,
    facetDefinition,
    validationRules,
    dependsOn,
    assetIdCharLimit,
    tenantData,
  });

  const displayTags = (key, item, arePrefixOptions) => {
    if (key === "notes_condition") return "tags-and-condition-fields";

    if (!item && !arePrefixOptions && key === "facets_barcode")
      return "wide-group-fields";
    else return "form-element";
  };

  return (
    <div className={cx(displayTags(key, item, arePrefixOptions))} key={key}>
      {hasLabel && displayFormLabel({ label, isDisabled, isMandatory })}
      <Form.Item
        name={key}
        hasFeedback
        valuePropName={valuePropName}
        shouldUpdate={dependsOn.length === 0}
        dependencies={dependsOn}
        rules={validationRules}
        initialValue={defaultValue}
      >
        {formControl}
      </Form.Item>
      {((validation?.allowByPass && focus) ||
        form.getFieldValue(byPassKey)) && (
        <div className="bypass-options">
          <Form.Item name={byPassKey} shouldUpdate key={byPassKey}>
            <Radio.Group>
              {calculateBypassOptions(key).map((option, index) => {
                return (
                  <Radio
                    value={option}
                    onClick={() => {
                      const oldFieldValue = form.getFieldValue(byPassKey);
                      if (option === oldFieldValue) {
                        form.setFieldsValue({ [byPassKey]: undefined });
                      }
                    }}
                    key={index}
                  >
                    {option}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </div>
      )}
    </div>
  );
}
