import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

export const mappingStatusText = {
  NOT_STARTED: "Not started",
  IN_PROGRESS: "In Progress",
  DONE: "Completed",
  INACCESSIBLE: "Inaccessible",
};

export const mappingStatusVisualIndicator = {
  NOT_STARTED: (
    <i className="anticon" data-test-selector="NOT_STARTED">
      <svg
        viewBox="0 0 64 64"
        width="12"
        stroke="#444f56"
        style={{ verticalWlign: "text-top" }}
      >
        <circle cx="32" cy="32" r="28" strokeWidth="6" fill="transparent" />
      </svg>
    </i>
  ),
  IN_PROGRESS: <ClockCircleOutlined data-test-selector="IN_PROGRESS" />,
  DONE: (
    <CheckCircleOutlined
      style={{ backgroundColor: "#baf9ba", borderRadius: "50%" }}
      data-test-selector="DONE"
    />
  ),
  INACCESSIBLE: (
    <ExclamationCircleOutlined
      style={{ backgroundColor: "#F69898", borderRadius: "50%" }}
      data-test-selector="INACCESSIBLE"
    />
  ),
};

export const Status = ({ status }) => (
  <>
    <div style={{ display: "block" }}>
      {mappingStatusVisualIndicator[status]}
      {` ${mappingStatusText[status]}`}
    </div>
  </>
);
