export const facilityTypes = [
  {
    label: "Assembly and recreation building",
    code: "Assembly and recreation building",
  },
  { label: "Commercial building", code: "Commercial building" },
  { label: "Factory", code: "Factory" },
  { label: "Hospital", code: "Hospital" },
  { label: "Industrial building", code: "Industrial building" },
  { label: "Leisure centre", code: "Leisure centre" },
  { label: "Retail building", code: "Retail building" },
  { label: "School", code: "School" },
  { label: "Shopping centre", code: "Shopping centre" },
  { label: "Stadium", code: "Stadium" },
  { label: "Student accommodation", code: "Student accommodation" },
  { label: "Train station", code: "Train station" },
  { label: "Warehouse", code: "Warehouse" },
];
