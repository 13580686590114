import React from 'react'
import { Menu } from 'antd'
import classNames from 'classnames'
import noop from 'lodash/noop'

const MenuItemWithClickableComponent = ({ children, noDivider, menuItemClassName }) => (
  <>
    <Menu.Item
      onClick={noop}
      onHover={noop}
      onMouseLeave={noop}
      className={classNames('ant-dropdown-menu-item', menuItemClassName)}
      role="menuitem"
    >
      {children}
    </Menu.Item>
    {!noDivider && <Menu.Divider className={'ant-dropdown-menu-item-divider'} />}
  </>
)
export default MenuItemWithClickableComponent
