import React from 'react'
import cx from 'classnames'

import Icon  from '@ant-design/icons'
import { GridIcon, TableIcon } from '../Icons'
import styles from './DataViewSwitch.scss'

export const DataViewSwitch = (props) => {
  const { className, option, onChange } = props
  return (
    <div className={cx(className, styles.dataViewSwitch)}>
        <Icon
          key={'table'}
          className={cx('option', 'table' === option && 'selected')}
          onClick={(e) => {
            e.stopPropagation()
            onChange('table')
          }}
          component={TableIcon}
          data-test-selector={'tableswitch'}
        />
        <Icon
          key={'grid'}
          className={cx('option', 'grid' === option && 'selected')}
          onClick={(e) => {
            e.stopPropagation()
            onChange('grid')
          }}
          component={GridIcon}
          data-test-selector={'gridswitch'}
        />
    </div>
  )
}

export default DataViewSwitch
