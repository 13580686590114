import { Alert } from "antd";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const CopyAssetCompleteMessage = ({ copyAssetMessage, setCopyAssetMessage, space }) => {
    const {spaces, facilities, floors, assets} = useEntityData()

    const asset = assets.items[copyAssetMessage]
    const facility = facilities[asset.facilityId].name
    const floor = floors.items[asset.floorId].name
    const spacePrev = spaces.items[asset.spaceId].name

    return (
        <Alert
            style={{marginTop: "16px"}}
            message={`Asset copy complete`}
            description={`You have completed the copy asset review from ${`${facility} / ${floor} / ${spacePrev}`} to ${space?.name}.`}
            type="success"
            showIcon
            afterClose={() => {
                setCopyAssetMessage();
            }}
            closable
        />)
}

export default CopyAssetCompleteMessage