import { createContext, useState } from 'react'

export const barcodeScannerContext = createContext()

const BarcodeScannerContextProvider = (props) => {
    const [isStreaming, setIsStreaming] = useState(false)
    const [streamTracks, setStreamTracks] = useState([])

    const values = {
        isStreaming,
        setIsStreaming,
        streamTracks,
        setStreamTracks,
    }

    return (
        <barcodeScannerContext.Provider value={values}>
            {props.children}
        </barcodeScannerContext.Provider>
    )
}

export default BarcodeScannerContextProvider
