import { Button, Dropdown, Menu, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { EllipsisOutlined, MinusOutlined } from "@ant-design/icons";

export function visibleActions(actions) {
  return actions.map((action, index) => {
    return (
      <div key={index}>
        {action.dataSwitch === "grid" && action.render()}
        <Tooltip key={index} title={action.label}>
          {action?.link && (
            <Link to={action.link}>
              {action.icon && (
                <Button
                  type="clear"
                  icon={action.icon}
                  onClick={action?.onClick}
                  disabled={action?.disabled}
                />
              )}
            </Link>
          )}
          {action?.onClick && (
            <Button
              type="clear"
              icon={action.icon}
              onClick={action?.onClick}
              disabled={action?.disabled}
            />
          )}
        </Tooltip>
      </div>
    );
  });
}

export function HiddenActionsInDropdown({ actions }) {
  if (actions.length > 3) {
    return (
      <>
        {visibleActions(actions.slice(0, 2))}
        <MinusOutlined
          rotate={90}
          style={{ scale: "1", paddingBottom: "1px" }}
        />
        <Dropdown
          trigger={"hover"}
          overlay={
            (
              <Menu>
                {actions.slice(2).map((action, index) => {
                  return (
                    <Menu.Item
                      key={action?.key || index}
                      className="row-actions"
                      type="clear"
                      onClick={action.onClick}
                      disabled={action?.disabled || false}
                      icon={action.icon}
                    >
                      {action.label}
                    </Menu.Item>
                  );
                })}
              </Menu>
            ) || <></>
          }
        >
          <Button
            type="clear"
            icon={<EllipsisOutlined />}
            style={{ scale: "1", paddingBottom: "10px" }}
          />
        </Dropdown>
      </>
    );
  } else {
    return <>{visibleActions(actions)}</>;
  }
}
