import { useMemo } from "react";
import { Dropdown, Button, Menu } from "antd";
import { ChevronDownIcon, FilterIcon } from "components/Icons";
import SearchableTree from "components/SearchableTree/SearchableTree";
import ButtonPair from "components/ButtonPair/ButtonPair";
import SortButton from "components/SortButton/SortButton";
import { SORT_ORDERS } from "constants";

import "./FilterHeader.scss";

export default function FilterHeader({
  buttonPair,
  sortOrder,
  sortBy,
  setSortOrder,
  setSortBy,
  sortByOptions,
  filterOnChange,
  filterOnCloseTag,
  filterTreeData = [],
  filterValue = [],
}) {
  const selectedOption = useMemo(() => sortByOptions?.find(
    (option) => option.dataIndex === sortBy
  ), [sortByOptions, sortBy]);

  function filterToKey(filters) {
    filterOnChange(filters.map((f) => f.key))
  }

  return (
    <div className="filter-header">
      <div className="filters">
        <div className="status-filter">
          <SearchableTree
            onChange={(node) => filterToKey(node)}
            treeData={filterTreeData}
            value={filterValue.length !== 0 ? filterValue : undefined}
            label={"Filters"}
            onCloseTag={(id) => filterOnCloseTag(id)}
            searchType={"field"}
            variant={"filter"}
            searchLabel={"Search"}
            icon={<FilterIcon />}
            multiple={true}
          />
        </div>
        <Dropdown
          className="sort-by-filter"
          placeholder="Sort by last edited"
          suffixIcon={<ChevronDownIcon />}
          showSearch
          overlay={
            <Menu>
              {sortByOptions
                ?.filter((option) => option.dataIndex)
                .map((option, i) => {
                  return (
                    <Menu.Item
                      key={option.dataIndex}
                      value={option.dataIndex}
                      onClick={() => setSortBy(option.dataIndex)}
                    >
                      {option.key}
                    </Menu.Item>
                  );
                })}
            </Menu>
          }
        >
          <Button className="dropdown-button">
            Sort by {selectedOption?.key.toLowerCase()}
            <ChevronDownIcon />
          </Button>
        </Dropdown>
        <SortButton
          sortOrder={sortOrder}
          onClick={() =>
            setSortOrder((direction) =>
              direction === SORT_ORDERS.ASCENDING
                ? SORT_ORDERS.DESCENDING
                : SORT_ORDERS.ASCENDING
            )
          }
        />
      </div>
      {buttonPair && (
        <ButtonPair className="back-button-pair" {...buttonPair} />
      )}
    </div>
  );
}
