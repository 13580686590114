import React, { useContext, useEffect, useRef } from "react"
import { BrowserMultiFormatReader } from "@zxing/library"
import { barcodeScannerContext } from "components/BarcodeScannerContextProvider"

const Scanner = ({ open, setBarcode, setOpen }) => {
  const scannerContext = useContext(barcodeScannerContext)
  const codeReader = new BrowserMultiFormatReader()
  const videoRef = useRef(null)

  const { isStreaming, setIsStreaming, streamTracks, setStreamTracks } = scannerContext

  const stopStream = () => {
    console.info('stopping stream')
    if (!streamTracks) return

    codeReader.stopContinuousDecode()
    streamTracks.forEach(track => {
      track.stop()
    })

    setStreamTracks([])
  }

  useEffect(() => {  
    if (!isStreaming) {
      stopStream()
    }
  }, [isStreaming])

  useEffect(() => {
    if (open) {
      decodeContinuously()

      return
    }
  }, [open])

  setIsStreaming(true)

  const decodeContinuously = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then(stream => {
        const tracks = stream.getTracks()

        setStreamTracks(tracks)

        if (videoRef.current) {
          codeReader.decodeFromStream(stream, "video", (result, err) => {
            if (result) {
              setIsStreaming(false)
              setBarcode(result.text)
              setOpen(false)
            }
          })
        } else {
          setStreamTracks([])
        }
      })
      .catch(error => {
        console.error(`Video error: ${error}`)
      })
  }

  return (
    <>
        <video id="video" width="100%" ref={videoRef}/>
    </>
  )
}

export default Scanner
