import { Button, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import cx from "classnames";
import "./ButtonPair.scss";

export default function ButtonPair({
  label,
  onClick,
  size,
  options,
  disabled,
  loading,
}) {

  return (
    <div className={cx("button-pair", `button-pair-${size}`)}>
      <Button type="primary" htmlType="submit" onClick={onClick} size={size} disabled={disabled} loading={loading}>
        {label}
      </Button>
      <Dropdown
        trigger={"click"}
        overlay={options || <></>}
        disabled={!options || disabled}
      >
        <Button icon={<EllipsisOutlined />} size={size} />
      </Dropdown>
    </div>
  );
}
