import { useState, useEffect, useMemo } from "react";
import { Modal } from "antd";
import {DataStore} from '@reams/elias-store';
import { Helmet } from "react-helmet";
import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import ItemTableGrid from "components/ItemTableGrid/ItemTableGrid";
import { EditIcon, DeleteIcon } from "components/Icons";
import ItemDrawer from "components/ItemDrawer/ItemDrawer";
import FilterHeader from "components/FilterHeader/FilterHeader";
import {Asset} from "models";
import Spinner from "components/Spinner/Spinner";
import { visibleActions } from "components/ButtonPair/ActionsDropdown";
import { ASSET_COLUMN, useRenderGrid } from "../../helpers/asset/assetTableHelpers"
import { fetchFilterDescription, filterItem } from "helpers/filters/itemFilterHelpers";
import { buildFilterTreeFromHierarchy, buildFilterableTreeByFacet, buildLocationFilterableTree, filterableOptions, treeObject } from "helpers/filters/assetFilterHelpers";
import _ from "lodash";
import { useEntityData } from "EntityDataContext/EntityDataContext";
import { getAssetDefinition } from "helpers/hierarchyHelpers";

export default function AllAssetsDetailsPage({
  pageHeader,
}) {
  const hierarchy = window.hierarchy;
  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("descend");
  const [selectedAsset, setSelectedAsset] = useState();
  const [parents, setParents] = useState();
  const [filters, setFilters] = useState([]);

  const { facilities, assets, spaces, floors } = useEntityData()
  
  const setFiltersNode = (val) => {
    let desc = val;

    desc = fetchFilterDescription({filter: desc, facets: window.facets, facilities, floors: floors.items, spaces: spaces.items});
    
    const x = { id: val, description: desc };

    setFilters((old) => {
      if (old.find((o) => o.id === x.id)) {
        return [...old];
      }
      return [...old, x];
    });
  };

  const onFiltersChange = (node) => {
    // from select fields
    if (Array.isArray(node)) {
      node.map((node) => setFiltersNode(node));
      return;
    }

    // from option fields
    setFiltersNode(node.value);
  };

  const onFilterClose = (filter) => {
    setFilters((old) => {
      return old.filter((f) => f.id !== filter);
    });
  };

  const allAssets = useMemo(() => Object.values(assets.items)?.map((asset) => {
      const assetDefinition = getAssetDefinition(
        asset.assetType?.eliasID,
        hierarchy
        );

      return {
        ...asset,
        name: assetDefinition?.knownAs || asset.assetType?.description,
        barcode: `${asset.facets?.barcode || ''} ${asset.notes?.barcode?.status ? `[${asset.notes?.barcode?.status}]` : ''}` ,
        quantity: asset.facets?.quantity,
        installDate: asset.facets && asset.facets["install-date"],
        condition: asset.facets?.condition,
        manufacturer: asset.facets?.manufacturer,
      };
    }), [assets]);

  const filterTreeData = useMemo(() => {
    // search through facets.json and find appropriate facets
    const filterableFacets = window.facets.filter((i) => i.filterable);

    const nonRatingGroupFacets = filterableFacets.filter(i => !i.ratingGroup)
    const ratingGroupFacets = filterableFacets.filter(i => i.ratingGroup)

    const nonRGF = nonRatingGroupFacets.map((i) => {
      return buildFilterableTreeByFacet(allAssets, i, onFiltersChange, filters, onFilterClose);
    })

    const uniqueRGs = _.uniq(ratingGroupFacets.map((f) => f.ratingGroup))

    const rgTrees = uniqueRGs.map(rg => treeObject(rg, rg, ratingGroupFacets.filter((rgf) => rgf.ratingGroup === rg).map((i) => {
      return buildFilterableTreeByFacet(allAssets, i, onFiltersChange, filters, onFilterClose);
    }).filter((f) => f))).filter((rg) => rg.children && rg.children.length > 0)

    const RGF = rgTrees.length > 0 ? [treeObject('Rating', 'Rating', rgTrees)] : []

    const LFT = treeObject(
      'Location', 
      'Location', 
      buildLocationFilterableTree(
        assets,
        facilities, 
        floors, 
        spaces
      )
    )
    
    // build System filter
    const treeData = buildFilterTreeFromHierarchy(window.hierarchy, allAssets)
    const SF = treeObject('System', 'System', treeData)

    // build Asset Class filter
    const ACF = treeObject('Asset Class', 'Asset Class', filterableOptions(allAssets, { key: 'Asset Class' }))

    return [SF, LFT, ACF, ...nonRGF, ...RGF];
  }, [allAssets, filters]);

  const fetchParents = async (selectedAsset) => {
    const space = spaces.items[selectedAsset.spaceId]
    const facility = facilities[selectedAsset.facilityId]
    const floor = floors.items[selectedAsset.floorId]
    setParents({ space, facility, floor });
  };

  useEffect(() => {
    if (!selectedAsset) return;

    fetchParents(selectedAsset);
  }, [selectedAsset]);


  const enhancedAssets = useMemo(() => {

    return filterItem(
      allAssets, 
      filters, 
      window.facets
    )
  }, [allAssets, filters]);


  const actions = (asset) => {
    return [
      {
        label: "Edit",
        key: "edit",
        onClick: () => setSelectedAsset(asset),
        icon: <EditIcon />,
      },
      {
        label: "Delete",
        key: "delete",
        onClick: () => confirmDeleteAsset(asset),
        icon: <DeleteIcon />,
      },
    ];
  };

  async function confirmDeleteAsset(asset) {
    Modal.confirm({
      title: "Are you sure to delete this asset?",
      icon: <QuestionCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        const itemToDelete = await DataStore.query(Asset, asset.id);
        await DataStore.delete(itemToDelete);
      },
    });
  }

  const columnTitles = {
    installDate: "Install Date",
    barcode: "Asset Id",
    updatedAt: "Last Edited",
  };

  const tableColumnTitleProps = {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    columnTitles,
  };

  const ASSET_COLUMNS = ASSET_COLUMN(
    tableColumnTitleProps,
    sortBy,
    sortOrder,
    (asset) => (visibleActions(actions(asset)))
  )



  if (!allAssets) {
    return <Spinner />;
  }

  return (
    <div className="space-details-page">
      <Helmet title={"All Assets"} />
      {pageHeader}
      <div className="content-header">
        <FilterHeader
          filterTreeData={filterTreeData}
          filterOnChange={(change) => {
            onFiltersChange(change);
          }}
          filterOnCloseTag={(closeTag) => onFilterClose(closeTag)}
          filterValue={filters}
          sortByOptions={ASSET_COLUMNS}
          {...tableColumnTitleProps}
        />
      </div>

      <ItemTableGrid
        title="All Assets"
        items={enhancedAssets}
        columns={ASSET_COLUMNS}
        onClick={(a) => setSelectedAsset(a)}
        actions={actions}
        render={useRenderGrid}
      />

      {selectedAsset && parents && (
        <ItemDrawer
          onClose={() => {
            setSelectedAsset(false);
            setParents();
          }}
          visible={selectedAsset}
          item={selectedAsset}
          itemType="asset"
          parents={parents}
        />
      )}
    </div>
  );
}
