import { Col } from "antd";
import {
  EllipsisText,
  LocationResult,
  itemDivider,
} from "components/AppHeader/GlobalSearch/globalSearchHelpers";

const ManufacturerResult = ({ item }) => {
  if (!item?.assetType?.virtual) {
    return (
      <Col span={24}>
        <EllipsisText
          text={itemDivider(
            item?.notes?.description,
            itemDivider(
              item?.facets?.manufacturer,
              item?.facets?.model
            )
          )}
        />
      </Col>
    );
  }
  return null;
};

const AssetResult = ({ item }) => (
  <>
    <Col span={24}>
      <EllipsisText
        text={itemDivider(
          item?.facets?.barcode,
          item.assetType.description
        )}
      />
    </Col>
    <ManufacturerResult item={item} />
    <LocationResult item={item} />
  </>
);

export default AssetResult