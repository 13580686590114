export const UNIT_OF_MEASURE = {
  PERCENT: "%",
  M: "m",
  M2: "m2",
  NR: "Nr.",
};

export const mappingElementStatusVisualIndicator = {
  WHITE: (
    <svg
      viewBox="0 0 64 64"
      className="validation-status-icon"
      data-test-selector="NOT_STARTED"
    >
      <circle
        cx="32"
        cy="32"
        r="28"
        stroke="currentColor"
        strokeWidth="6"
        fill="transparent"
      />
    </svg>
  ),
  RED: (
    <svg
      viewBox="0 0 64 64"
      className="validation-status-icon"
      data-test-selector="NOT_STARTED_INVALID"
    >
      <circle
        cx="32"
        cy="32"
        r="28"
        stroke="currentColor"
        strokeWidth="6"
        fill="#F69898"
      />
    </svg>
  ),
  GREEN: (
    <svg
      viewBox="0 0 64 64"
      className="validation-status-icon"
      data-test-selector="DONE"
    >
      <circle
        cx="32"
        cy="32"
        r="28"
        stroke="currentColor"
        strokeWidth="6"
        fill="#baf9ba"
      />
    </svg>
  ),
  AMBER: (
    <svg
      viewBox="0 0 64 64"
      className="validation-status-icon"
      data-test-selector="CANT_VALIDATE"
    >
      <circle
        cx="32"
        cy="32"
        r="28"
        stroke="currentColor"
        strokeWidth="6"
        fill="#ffe2b4"
      />
    </svg>
  ),
};
