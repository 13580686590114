import { throttledRefreshImages } from 'helpers/imageHelpers'
import { DataStore } from '@reams/elias-store'
import { Asset } from "models"
import { cloneDeep } from "lodash"

export const initialAssetState = {
  items: {},
  barcodes: [],
  assetCount: 0,
  assetTree: {}
  // DEVNOTE asset tree structure for reference
  // assetTree: {
  //   facility1Id: {
  //     floorId: {
  //       spaceId1: ["asset1Id", "asset2Id"],
  //       spaceId2: ["asset3Id"]
  //     }
  //   },
  //   facility2Id: {
  //     floorId2: {
  //       spaceId3: ["asset4Id", "asset5Id"],
  //     },
  //     floorId3: {
  //       spaceId4: ["asset6Id"],
  //       spaceId5: ["asset7Id"]
  //     }
  //   }
  // }
}

const addAssetToTree = (assetObj, asset) => {
  if (!assetObj[asset.facilityId]) {
    assetObj[asset.facilityId] = {}
  }

  if (!assetObj[asset.facilityId][asset.floorId]) {
    assetObj[asset.facilityId][asset.floorId] = {}
  }

  if (!assetObj[asset.facilityId][asset.floorId][asset.spaceId]) {
    assetObj[asset.facilityId][asset.floorId][asset.spaceId] = []
  }

  assetObj[asset.facilityId][asset.floorId][asset.spaceId].push(asset.id)
}

const handleIncomingAssets = (incomingAssets, setAssets) => {
  const assetObj = cloneDeep(initialAssetState)
  const assetImages = []
  const assetTree = {}
  
  incomingAssets.forEach(asset => {
    assetObj.items[asset.id] = {
      ...asset, 
      name: asset.assetType?.description,
      barcode: asset.facets?.barcode,
      quantity: asset.facets?.quantity,
      installDate: asset.facets && asset.facets["install-date"],
      condition: asset.facets?.condition,
      manufacturer: asset.facets?.manufacturer
    }

    if (asset.images?.length) {
        assetImages.push({
          facilityId: asset.facilityId,
          images: asset.images
      })
    }

    assetObj.assetCount++

    if (asset.facets?.barcode !== undefined) {
      assetObj.barcodes.push(asset.facets.barcode)
    }

    addAssetToTree(assetTree, asset)
  })

  assetObj.assetTree = assetTree

  if (assetImages.length) {
    throttledRefreshImages(assetImages)
  }

  setAssets(assetObj)
}

export const getDataStoreAssets = ({setAssets, tenantId, facilitiesToDownload}) => (
  DataStore.observeQuery(Asset, (x) => 
    x.tenantId("eq", tenantId)
    .or(x => facilitiesToDownload
      .reduce((acc, id) => acc.facilityId('eq', id), x)
    ))
  .subscribe(async({items, isSynced}) => {
    if (isSynced) {
      handleIncomingAssets(items, setAssets);
    }
  })
)