import {
    get as localDbGet,
    set as localDbSet,
  } from "idb-keyval";

export const getFavouriteSpaces = async () => {
    const fs = await localDbGet('favourite-space');
    return fs
}

export const addFavouriteSpace = async (fs, id) => {
    fs = [...fs, id]
    await localDbSet('favourite-space', fs)
    return fs
}

export const removeFavouriteSpace = async (fs, id) => {
    fs = fs
    fs = fs.filter((s) => s !== id)
    await localDbSet('favourite-space', fs)
    return fs
}

export const saveFavouriteSpaces = async (setFavouriteSpaces, favouriteSpaces, spaceId) => {
    let fs = []
    if (favouriteSpaces.includes(spaceId)){
      fs = await removeFavouriteSpace(favouriteSpaces, spaceId)
    } 
    if (!favouriteSpaces.includes(spaceId)){
      fs = await addFavouriteSpace(favouriteSpaces, spaceId)
    }
    setFavouriteSpaces(fs)
  }