import {
  Form,
  Input,
  Checkbox,
} from "antd";

export function displayFooterCheckboxes({
    form,
    forceUpdate,
    itemType,
    options,
  }) {
    const enabledOptions = [];
    for (let optionName in options) {
      let enabledForItemTypes = options[optionName];
      if (enabledForItemTypes.includes(itemType)) {
        enabledOptions.push(optionName);
      }
    }
    if (enabledOptions.length === 0) {
      return null;
    }
  
    return (
      <div className="form-group">
        {enabledOptions.includes("asbestos") && (
          <div className="checkbox-textarea-group">
            <Form.Item
              key="checkboxes_asbestos"
              name="checkboxes_asbestos"
              valuePropName="checked"
              shouldUpdate
            >
              <Checkbox
                onChange={(e) => {
                  form.setFieldsValue({
                    checkboxes_asbestos: e.target.checked,
                  });
                  forceUpdate();
                }}
              >
                Asbestos
              </Checkbox>
            </Form.Item>
  
            {form.getFieldValue("checkboxes_asbestos") === true && (
              <Form.Item key="notes_asbestos" name="notes_asbestos" shouldUpdate>
                <Input.TextArea />
              </Form.Item>
            )}
          </div>
        )}
        {enabledOptions.includes("hsIssue") && (
          <div className="checkbox-textarea-group">
            <Form.Item
              key="checkboxes_hsIssue"
              name="checkboxes_hsIssue"
              valuePropName="checked"
              shouldUpdate
            >
              <Checkbox
                onChange={(e) => {
                  form.setFieldsValue({
                    checkboxes_hsIssue: e.target.checked,
                  });
                  if (!form.getFieldValue("checkboxes_hsIssue")) {
                    form.setFieldsValue({
                      notes_hsIssue: undefined,
                    });
                  }
                  forceUpdate();
                }}
              >
                Health {"&"} Safety
              </Checkbox>
            </Form.Item>
            <Form.Item
              key="notes_hsIssue"
              name="notes_hsIssue"
              hidden={form.getFieldValue("checkboxes_hsIssue") !== true}
              shouldUpdate
            >
              <Input.TextArea allowClear />
            </Form.Item>
          </div>
        )}
        {enabledOptions.includes("reminder") && (
          <div className="checkbox-textarea-group">
            <Form.Item
              key="checkboxes_reminder"
              name="checkboxes_reminder"
              valuePropName="checked"
              shouldUpdate
              rules={[
                {
                  required:
                    form.getFieldValue("status") === "INACCESSIBLE" ? true : false,
                  message: "Status Inaccessible, please add a Note",
                },
              ]}
            >
              <Checkbox
                onChange={(e) => {
                  form.setFieldsValue({
                    checkboxes_reminder: e.target.checked,
                  });
                  if (!e.target.checked) {
                    form.setFieldsValue({
                      notes_reminder: undefined,
                    });
                  }
                  forceUpdate();
                }}
              >
                Notes
              </Checkbox>
            </Form.Item>
            <Form.Item
              key="notes_reminder"
              name="notes_reminder"
              hidden={form.getFieldValue("checkboxes_reminder") !== true}
              shouldUpdate
              rules={[
                {
                  required:
                    form.getFieldValue("status") === "INACCESSIBLE" &&
                    form.getFieldValue("checkboxes_reminder")
                      ? true
                      : false,
                  message: "Please insert a Note",
                },
              ]}
            >
              <Input.TextArea allowClear />
            </Form.Item>
          </div>
        )}
      </div>
    );
  }
  