export function getLocationTreeIds(id, facilities, floors, spaces) {
    const space = spaces?.items?.[id]
    const facility = facilities?.[id]
    const floor = floors?.items?.[id]
    if (space) {
      return {space, floor: floors?.items?.[space.floorId], facility: facilities?.[space.facilityId]}
    }
  
    if (floor) {
      return {space: undefined, floor, facility: facilities?.[floor.facilityId]}
    }
  
    if (facility) {
      return {space: undefined, floor: undefined, facility}
    }
  
    return {space, floor, facility}
  
  }