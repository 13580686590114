import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Typography, Menu, Modal, Descriptions, Alert } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {DataStore} from '@reams/elias-store';
import { Helmet } from "react-helmet";
import { Floor, Space, Asset } from "models";
import FilterHeader from "components/FilterHeader/FilterHeader";
import ItemDrawer from "components/ItemDrawer/ItemDrawer";
import { SpaceIcon, DeleteIcon, CheckIcon, EditIcon } from "components/Icons";
import { renderUpdatedAt } from "helpers/generalHelpers";
import { floorTypesDictionary } from "helpers/floorTypes";
import { Status } from "helpers/statusHelpers";
import TableColumnTitle from "components/TableColumnTitle/TableColumnTitle";
import Spinner from "components/Spinner/Spinner";
import FabricGroups from "components/FabricGroups/FabricGroups";
import ItemTableGrid from "components/ItemTableGrid/ItemTableGrid";
import { HiddenActionsInDropdown } from "components/ButtonPair/ActionsDropdown";
import Authorize from "components/Authorize/Authorize";

import "./FacilityDetailsPage.scss";
import "../../styles/customLinks.scss";
import { customFacetList, floorDefinition } from "helpers/drawerData";
import { buildFilterableTreeByFacet } from "helpers/filters/assetFilterHelpers";
import { fetchFilterDescription, filterItem } from "helpers/filters/itemFilterHelpers";
import { facilityFabricAssets, floorAssetCount, floorAssetIds, spaceAssets } from "helpers/asset/assetHelpers";
import { useEntityData } from "EntityDataContext/EntityDataContext";

export default function FacilityDetailsPage({
  addPageToHistory,
  pageHeader,
}) {
  const { facilities, assets, spaces, floors } = useEntityData()

  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("descend");
  const [isCreatingNewFloor, setIsCreatingNewFloor] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(false);
  const { facilityId } = useParams();

  const facility = facilities[facilityId]

  const [filters, setFilters] = useState([]);

  const setFiltersNode = (val) => {
    let desc = val;
    desc = fetchFilterDescription({filter: desc, facets: facets});
    const x = { id: val, description: desc };

    setFilters((old) => {
      if (old.find((o) => o.id === x.id)) {
        return [...old];
      }
      return [...old, x];
    });
  };

  const onFiltersChange = (node) => {
    // from select fields
    if (Array.isArray(node)) {
      node.map((node) => setFiltersNode(node));
      return;
    }

    // from option fields
    setFiltersNode(node.value);
  };

  const onFilterClose = (filter) => {
    setFilters((old) => {
      return old.filter((f) => f.id !== filter);
    });
  };

  const facets = useMemo(() => {
    return customFacetList
    .filter((facet) => floorDefinition.facets?.includes(facet.facetId))
  }, [customFacetList, floorDefinition])

  const facilityFloorIds = floors.groupByFacility?.[facility.id] || []
  const facilityFloors = facilityFloorIds.map(id => floors.items[id])

  const enhancedFloors = useMemo( () => {
    if (!facilityFloors) return []

    let tmp = facilityFloors.map(floor => {
      let count = 0
      
      spaces.groupByFloor[floor.id]?.forEach(spaceId => {
        count += spaceAssets({
          assets, 
          space: spaces.items[spaceId]
        }).length
      })

      return {
        ...floor,
        assetCount: count,
        spaceCount: spaces.groupByFloor?.[floor.id]?.length || 0,
      }
    })

    return filterItem(tmp, filters, facets)
  }, [facilityFloors, filters, assets, spaces.groupByFloor])


  useEffect(() => {
    if (!facility) {
      return;
    }
    addPageToHistory(facility.name);
  }, [facility]);

  async function confirmDeleteFloor(floor) {
    Modal.confirm({
      title: "Are you sure to delete this floor?",
      icon: <QuestionCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        const itemToDelete = await DataStore.query(Floor, floor.id);
        await DataStore.delete(itemToDelete);

        const spacesToDelete = await DataStore.query(Space, (x) =>
          x.floorId("eq", floor.id)
        );
        const assetsToDelete = await DataStore.query(Asset, (x) =>
          x.floorId("eq", floor.id)
        );

        for (let i = 0; i < spacesToDelete.length; i++) {
          await DataStore.delete(spacesToDelete[i]);
          console.log(`Deleted asset: ${spacesToDelete[i].name}`);
        }

        for (let i = 0; i < assetsToDelete.length; i++) {
          await DataStore.delete(assetsToDelete[i]);
          console.log(`Deleted asset: ${assetsToDelete[i]}.name`);
        }
      },
    });
  }

  const actions = (floor) => {
    return [
      {
        label: "Spaces",
        key: "spaces",
        link: `/floor/${floor.id}`,
        icon: <SpaceIcon />,
      },
      {
        label: "Edit",
        key: "edit",
        onClick: () => {
          setSelectedFloor(floor);
        },
        icon: <EditIcon />,
      },
      {
        label: "Complete",
        key: "complete",
        onClick: () => {},
        icon: <CheckIcon />,
        disabled: true,
      },
      {
        label: "Delete",
        key: "delete",
        onClick: () => confirmDeleteFloor(floor),
        icon: <DeleteIcon />,
      },
    ];
  };

  const filterTreeData = useMemo(() => {
    if (!facilityFloors) return []

    // search through facets.json and find appropriate facets
    const filterableFacets = facets.filter((i) => i.filterable);

    return filterableFacets.map((i) => {
      return buildFilterableTreeByFacet(facilityFloors, i, onFiltersChange);
    })
  }, [floors]);

  const fabricAssets = facilityFabricAssets(assets, facilityId)

  const columnTitles = {
    floorType: "Floor Id",
    assetCount: "Assets",
    spaceCount: "Spaces",
    updatedAt: "Last Edited",
  };

  const tableColumnTitleProps = {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    columnTitles,
  };

  const FLOOR_COLUMNS = [
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="name" />,
      dataIndex: "name",
      key: "Name",
      fixed: "left",
      render: (name, floor) => (
        <Link
          className="table-name-link"
          to={`/floor/${floor.id}`}
        >
          {name}
        </Link>
      ),
      sorter: (a, b) =>
        a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1,
      sortOrder: sortBy === "name" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="floorType" />
      ),
      dataIndex: "floorType",
      key: "Floor Id",
      render: (floorType) => floorTypesDictionary[floorType],
      fixed: "left",
      sorter: (a, b) => (a.floorType < b.floorType ? -1 : 1),
      sortOrder: sortBy === "floorType" ? sortOrder : false,
    },
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="status" />,
      dataIndex: "status",
      key: "Status",
      render: (status) => <Status status={status} />,
      sorter: (a, b) => (a.status < b.status ? -1 : 1),
      sortOrder: sortBy === "status" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="spaceCount" />
      ),
      key: "Spaces",
      dataIndex: "spaceCount",
      sorter: (a, b) => (a.spaceCount < b.spaceCount ? -1 : 1),
      sortOrder: sortBy === "spaceCount" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="assetCount" />
      ),
      key: "Assets",
      dataIndex: "assetCount",
      sorter: (a, b) => (a.assetCount < b.assetCount ? -1 : 1),
      sortOrder: sortBy === "assetCount" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="updatedAt" />
      ),
      dataIndex: "updatedAt",
      key: "Last Edited",
      sorter: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
      sortOrder: sortBy === "updatedAt" ? sortOrder : false,
      render: (_, item) => {
        return renderUpdatedAt(item);
      },
    },
    {
      title: "",
      fixed: "right",
      render: (floor) => {
        return (
          <div className="row-actions">
            <HiddenActionsInDropdown actions={actions(floor)} />
          </div>
        );
      },
    },
  ];

  if (!facility) {
    return <Spinner />;
  }

  const render = (floor) => (
    <Descriptions
      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      size={"small"}
    >
      <Descriptions.Item label="Status">
        <Status status={floor.status} />
      </Descriptions.Item>
      <Descriptions.Item label="FloorType">
        {floorTypesDictionary[floor.floorType]}
      </Descriptions.Item>
      <Descriptions.Item label="Spaces">{floor.spaceCount}</Descriptions.Item>
      <Descriptions.Item label="Assets">{floor.assetCount}</Descriptions.Item>
      <Descriptions.Item label="Last Edited">
        {renderUpdatedAt(floor)}
      </Descriptions.Item>
    </Descriptions>
  );

  function displayFabricValidationMessage() {
    if (!facility) {
      return null;
    }
    let fabricFields = [
      { fieldName: "facility-footprint", label: "Facility Footprint (m2)" },
      { fieldName: "facility-height", label: "Facility Height (m)" },
      { fieldName: "facility-roof-height", label: "Roof Height (m)" },
      { fieldName: "facility-shape-ratio", label: "Facility Shape Ratio" },
    ];
    let missingFabricFields = fabricFields.filter(
      (field) =>
        !facility.facets.hasOwnProperty(field.fieldName) ||
        facility.facets[field.fieldName] === "" ||
        facility.facets[field.fieldName] === null
    );

    if (missingFabricFields.length === 0) {
      return null;
    }
    return (
      <Alert
        className="validation-issues"
        style={{ backgroundColor: "white" }}
        message="Validation issues"
        description={
          <Typography.Paragraph>
            The following attributes are missing for this facility:
            <ul>
              {missingFabricFields.map((field, i) => (
                <li key={i}>{field.label}</li>
              ))}
            </ul>
          </Typography.Paragraph>
        }
        type="warning"
        showIcon
      />
    );
  }

  return (
    <div className="facility-details-page">
      <Helmet title={facility?.name || "Facility details"} />
      {pageHeader}
      <Authorize allowedRoles={["admin", "fabric"]}>
        {displayFabricValidationMessage()}
      </Authorize>
      <div className="content-header">
        <Typography.Title className="subtitle" level={1}>
          Facility details - {facility.name}
        </Typography.Title>
        <FilterHeader
          filterTreeData={filterTreeData}
          filterOnChange={(change) => {
            onFiltersChange(change);
          }}
          filterOnCloseTag={(closeTag) => onFilterClose(closeTag)}
          filterValue={filters}
          buttonPair={{
            label: "Add Floor",
            onClick: () => setIsCreatingNewFloor(true),
            options: (
              <Menu>
                <Menu.Item
                  key="edit-floor"
                  onClick={() => setSelectedFacility(true)}
                >
                  Edit facility
                </Menu.Item>
                <Menu.Item key="complete-floor">Complete facility</Menu.Item>
              </Menu>
            ),
          }}
          sortByOptions={FLOOR_COLUMNS}
          {...tableColumnTitleProps}
        />
      </div>

      <Authorize allowedRoles={["admin", "fabric"]}>
        <FabricGroups
          parentType="facility"
          parents={{ facility }}
          fabricAssets={fabricAssets}
        />
      </Authorize>

      <ItemTableGrid
        title="Floors"
        items={enhancedFloors}
        columns={FLOOR_COLUMNS}
        onClick={(a) => setSelectedFloor(a)}
        actions={actions}
        render={render}
      />

      {(selectedFloor || isCreatingNewFloor) && (
        <ItemDrawer
          onClose={() => {
            setIsCreatingNewFloor(false);
            setSelectedFloor(false);
          }}
          visible={selectedFloor || isCreatingNewFloor}
          item={selectedFloor}
          parents={{ facility }}
          itemType="floor"
        />
      )}

      {facility && selectedFacility && (
        <ItemDrawer
          onClose={() => setSelectedFacility(false)}
          visible={facility}
          item={facility}
          itemType="facility"
        />
      )}
    </div>
  );
}
