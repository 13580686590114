import { floorTypes } from "./floorTypes";
import { facilityTypes } from "./facilityTypes";
import { spaceTypes } from "./spaceTypes";

export const BYPASS_OPTIONS = [
  "Not Present",
  "No Access",
  "High Level",
  "Data Worn",
];

export const BYPASS_OPTIONS_BARCODE = ["No Access", "High Level"];

export const spaceDefinition = {
  facets: [
    "name",
    "spaceId",
    "localName",
    "department",
    "spaceType",
    "status",
    "spaceUse",
    "conditionNotes",
    "occupancy",
    "gia",
    "wallHeight",
    "temperature",
  ],
};

export const floorDefinition = {
  facets: ["name", "floorId", "status", "giaWithoutGroup", "description"],
};

export const facilityDefinition = {
  facets: [
    "facilityId",
    "facilityName",
    "facilityType",
    "facilityUse",
    "facilityLocalName",
    "facilityOverview",
    "facilityGia",
    "facilityFootprint",
    "facilityHeight",
    "facilityRoofHeight",
    "facilityShapeRatio",
    "facilityBuildPeriod",
    "facilityBuildDate",
    "facilityLastRefurbDate",
    "facilityCurrentReplacementCostValue",
    "facilityOccupancyNormal",
    "facilityOccupancyPeak",
    "facilityListedStatus",
    "facilityGeoLat",
    "facilityGeoLong",
    "facilityWhat3words",
    "facilityAddress",
    "facilityPostcode",
    "facilityContactName",
    "facilityContactJobTitle",
    "facilityContactOrganisation",
    "facilityContactTelephone",
    "facilityContactMobile",
    "facilityContactEmail",
  ],
};

export const customFacetList = [
  {
    facetId: "name",
    key: "name",
    label: "Name",
    type: "TextBox",
    group: "Information",
    order: 1,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "spaceId",
    key: "externalId",
    label: "Space Reference",
    group: "Information",
    type: "TextBox",
    order: 2,
  },
  {
    facetId: "floorId",
    key: "floorType",
    label: "Floor Id",
    group: "Information",
    type: "ComboBox",
    order: 2,
    options: floorTypes,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "localName",
    key: "localName",
    label: "Local Name",
    group: "Information",
    type: "TextBox",
    order: 3,
  },
  {
    facetId: "department",
    key: "department",
    label: "Department/Zone",
    group: "Information",
    type: "TextBox",
    order: 4,
  },
  {
    facetId: "spaceType",
    key: "type",
    label: "Type",
    type: "ComboBox",
    group: "Information",
    filterable: true,
    order: 5,
    options: spaceTypes,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "status",
    key: "status",
    label: "Status",
    type: "ComboBox",
    group: "Information",
    order: 6,
    filterable: true,
    validation: {
      mandatory: true,
    },
    defaultValue: "NOT_STARTED",
    options: [
      {
        label: "Not started",
        code: "NOT_STARTED",
      },
      {
        label: "Inaccessible",
        code: "INACCESSIBLE",
      },
      {
        label: "In Progress",
        code: "IN_PROGRESS",
      },
      {
        label: "Completed",
        code: "DONE",
      },
    ],
  },
  {
    facetId: "spaceUse",
    key: "spaceUse",
    label: "Current use of space",
    type: "MultiLineTextBox",
    order: 7,
    group: "Additional space info",
    fabricOnly: true,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "conditionNotes",
    key: "conditionNotes",
    label: "Condition notes",
    type: "MultiLineTextBox",
    order: 8,
    group: "Additional space info",
    fabricOnly: true,
  },
  {
    facetId: "occupancy",
    key: "occupancy",
    label: "Occupancy",
    type: "Numeric",
    order: 9,
    group: "Additional space info",
    fabricOnly: true,
  },
  {
    facetId: "gia",
    key: "gia",
    label: "Gia (m²)",
    type: "Numeric",
    order: 10,
    group: "Additional space info",
    fabricOnly: true,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "giaWithoutGroup",
    key: "gia",
    label: "Gia (m²)",
    type: "Numeric",
    group: "Information",
    order: 10,
    fabricOnly: true,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "description",
    key: "description",
    label: "Description",
    type: "MultiLineTextBox",
    group: "Information",
    order: 11,
    element: "notes",
  },
  {
    facetId: "wallHeight",
    key: "wallHeight",
    label: "Average wall height (m)",
    type: "Numeric",
    order: 11,
    group: "Additional space info",
    fabricOnly: true,
  },
  {
    facetId: "temperature",
    key: "temperature",
    label: "Temperature (centigrade)",
    type: "Numeric",
    order: 12,
    group: "Additional space info",
    fabricOnly: true,
  },
  {
    facetId: "facilityName",
    label: "Facility name",
    key: "name",
    group: "Information",
    order: 101,
    type: "TextBox",
    disabled: true,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "facilityId",
    label: "Facility ID",
    key: "externalId",
    group: "Information",
    order: 102,
    disabled: true,
    type: "TextBox",
  },
  {
    facetId: "facilityType",
    label: "Type",
    element: "facets",
    key: "facility-type",
    group: "Information",
    order: 103,
    type: "ComboBox",
    options: facilityTypes,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "facilityUse",
    label: "Use",
    element: "notes",
    key: "facility-use",
    group: "Information",
    order: 104,
    type: "MultiLineTextBox",
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "facilityLocalName",
    label: "Local name",
    element: "notes",
    key: "facility-local-name",
    group: "Information",
    order: 105,
    type: "TextBox",
  },
  {
    facetId: "facilityOverview",
    label: "Overview",
    element: "notes",
    key: "facility-overview",
    group: "Information",
    order: 106,
    type: "MultiLineTextBox",
  },
  {
    facetId: "facilityGia",
    label: "GIA (m²)",
    element: "facets",
    key: "facility-gia",
    group: "Information",
    order: 107,
    type: "Numeric",
  },
  {
    facetId: "facilityFootprint",
    label: "Facility Footprint (m2)",
    element: "facets",
    key: "facility-footprint",
    group: "Information",
    order: 107.1,
    type: "Numeric",
    fabricOnly: true,
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "facilityHeight",
    label: "Facility Height (m)",
    element: "facets",
    key: "facility-height",
    group: "Information",
    order: 107.2,
    type: "Numeric",
    fabricOnly: true,
    validation: {
      // mandatory: true,
    },
  },
  {
    facetId: "facilityRoofHeight",
    label: "Roof Height (m)",
    element: "facets",
    key: "facility-roof-height",
    group: "Information",
    order: 107.3,
    type: "Numeric",
    fabricOnly: true,
    validation: {
      // mandatory: true,
    },
  },
  {
    facetId: "facilityShapeRatio",
    label: "Facility Shape Ratio",
    element: "facets",
    key: "facility-shape-ratio",
    group: "Information",
    order: 107.4,
    type: "ComboBox",
    fabricOnly: true,
    validation: {
      // mandatory: true,
    },
    options: [
      {
        label: "1/1",
        code: "1/1",
      },
      {
        label: "1/2",
        code: "1/2",
      },
      {
        label: "1/3",
        code: "1/3",
      },
      {
        label: "1/4",
        code: "1/4",
      },
      {
        label: "1/5",
        code: "1/5",
      },
    ],
  },
  {
    facetId: "facilityBuildPeriod",
    label: "Build period",
    element: "facets",
    key: "build-period",
    group: "Information",
    order: 108,
    type: "ComboBox",
    options: [
      { label: "Pre 1900", code: "Pre 1900" },
      { label: "1900 to 1930", code: "1900 to 1930" },
      { label: "1931 to 1950", code: "1931 to 1950" },
      { label: "1951 to 1970", code: "1951 to 1970" },
      { label: "1971 to 1980", code: "1971 to 1980" },
      { label: "1981 to 1990", code: "1981 to 1990" },
      { label: "1991 to 2000", code: "1991 to 2000" },
      { label: "2001 to 2010", code: "2001 to 2010" },
      { label: "2011 to 2020", code: "2011 to 2020" },
      { label: "Post 2020", code: "Post 2020" },
    ],
    validation: {
      mandatory: true,
    },
  },
  {
    facetId: "facilityBuildDate",
    label: "Build date",
    element: "facets",
    key: "build-date",
    group: "Information",
    order: 109,
    type: "TextBox",
  },
  {
    facetId: "facilityLastRefurbDate",
    label: "Last refurb date",
    element: "facets",
    key: "last-refurb-date",
    group: "Information",
    order: 110,
    type: "TextBox",
    fabricOnly: true,
  },
  {
    facetId: "facilityCurrentReplacementCostValue",
    label: "Current replacement cost value",
    element: "facets",
    key: "current-replacement-cost-value",
    group: "Information",
    order: 111,
    type: "Numeric",
  },
  {
    facetId: "facilityOccupancyNormal",
    label: "Occupancy normal",
    element: "facets",
    key: "occupancy-normal",
    group: "Information",
    order: 112,
    type: "Numeric",
    fabricOnly: true,
  },
  {
    facetId: "facilityOccupancyPeak",
    label: "Occupancy at peak",
    element: "facets",
    key: "occupancy-peak",
    group: "Information",
    order: 113,
    type: "Numeric",
    fabricOnly: true,
  },
  {
    facetId: "facilityListedStatus",
    label: "Listed status",
    element: "facets",
    key: "listed-status",
    group: "Information",
    order: 114,
    type: "ComboBox",
    fabricOnly: true,
    options: [
      { label: "None", code: "None" },
      { label: "Grade I listed", code: "Grade I listed" },
      { label: "Grade II* listed", code: "Grade II* listed" },
      { label: "Grade II listed", code: "Grade II listed" },
    ],
  },
  {
    facetId: "facilityGeoLat",
    label: "Geo lat",
    element: "facets",
    key: "location.lat",
    type: "TextBox",
    group: "Contact details",
    order: 215,
  },
  {
    facetId: "facilityGeoLong",
    label: "Geo long",
    element: "facets",
    key: "location.lon",
    type: "TextBox",
    group: "Contact details",
    order: 216,
  },
  {
    facetId: "facilityWhat3words",
    label: "What3words location code",
    element: "notes",
    key: "what3words",
    type: "TextBox",
    group: "Contact details",
    order: 217,
  },
  {
    facetId: "facilityAddress",
    label: "Address",
    element: "notes",
    key: "address",
    type: "MultiLineTextBox",
    group: "Contact details",
    order: 218,
  },
  {
    facetId: "facilityPostcode",
    label: "Postcode",
    element: "facets",
    key: "postcode",
    type: "TextBox",
    group: "Contact details",
    order: 219,
  },
  {
    facetId: "facilityContactName",
    label: "Contact name",
    element: "facets",
    key: "contact-name",
    type: "TextBox",
    group: "Contact details",
    order: 220,
  },
  {
    facetId: "facilityContactJobTitle",
    label: "Contact job title",
    element: "notes",
    key: "contact-job-title",
    type: "TextBox",
    group: "Contact details",
    order: 221,
  },
  {
    facetId: "facilityContactOrganisation",
    label: "Contact organization",
    element: "notes",
    key: "contact-organization",
    type: "TextBox",
    group: "Contact details",
    order: 222,
  },
  {
    facetId: "facilityContactTelephone",
    label: "Contact telephone",
    element: "notes",
    key: "contact-telephone",
    type: "TextBox",
    group: "Contact details",
    order: 223,
  },
  {
    facetId: "facilityContactMobile",
    label: "Contact mobile",
    element: "notes",
    key: "contact-mobile",
    type: "TextBox",
    group: "Contact details",
    order: 224,
  },
  {
    facetId: "facilityContactEmail",
    label: "Contact email",
    element: "notes",
    key: "contact-email",
    type: "TextBox",
    group: "Contact details",
    order: 225,
  },
];
