import { Building, MapPin, World } from "components/Icons";
import {
  uniqueFilteredKeys,
  getKeyFromFilter,
  FILTERSPLITCHAR,
  getValueFromFilter,
  getKeyLocation,
} from "./assetFilterHelpers";

const iconChooser = {
  region: (classes) => (<World className={classes}/>),
  site:(classes) => (<MapPin className={classes}/>) ,
  facility: (classes) => (<Building className={classes}/>) ,
}

export const generateIconTitle = ({title, type, Component, classes = "filterIcon"}) => {
  const lowerType = type.toLowerCase()

  if (!['region', 'site', 'facility'].includes(lowerType)) return title

  return (
    <>
      {Component ? <Component className={classes}/> : iconChooser[lowerType](classes)}
      {title}
    </>
  )
}

export const fetchFilterDescription = ({
  filter,
  facets,
  facilities = {},
  floors,
  spaces = {},
  regions,
  sites
}) => {
  const key = getKeyFromFilter(filter);
  const filterValue = getValueFromFilter(filter)

  const facet = facets.find((f) => f.key === key);
  if (facet) {
    if (filterValue === key) {
      return `${facet.label}`;
    }
    if (facet.options) {
      const options = facet.options.find((o) => o.code === filterValue)
      return `${facet.label}: ${options?.label || options?.description }`;
    }
    return `${facet.label}: ${filterValue}`;
  }

  switch(key) {
    case "System":
      return `System: ${filterValue.split(";").join(" / ")}`;

    case "Facility":
      return generateIconTitle({
        title: `Facility: ${
          facilities[filterValue]?.name
        }`, 
        type: "facility", 
        classes: "filterIcon filterHeaderIcon",
        Component: Building
      })

    case "Region":
      return generateIconTitle({
        title: `Region: ${regions[filterValue].name}`, 
        type: "region", 
        classes: "filterIcon filterHeaderIcon",
        Component: World
      })

    case "Site":
      return generateIconTitle({
        title: `Site: ${sites[filterValue].name}`, 
        type: "site", 
        classes: "filterIcon filterHeaderIcon",
        Component: MapPin
      })

    case "Floor":
      return `Floor: ${
        floors[filterValue].name
      }`;

    case "Space":
      return `Space: ${
        spaces[filterValue]?.name
      }`;

    default:
      return `${filter[0].toUpperCase()}${filter.slice(1)}`
        .replace("_", ": ")
        .replace("-", " ");
  }
};

export const filterItem = (items, filters, facets) => {
  let tempItems = items;

  const uniqueFilters = uniqueFilteredKeys(filters);
  uniqueFilters.forEach((filter) => {
    const foundFilters = filters.filter(
      (f) => getKeyFromFilter(f.id) === filter
    );

    const filterKey = getKeyFromFilter(foundFilters[0].id)

    if (
      ["Facility", "Floor", "Space", "Region", "Site"].includes(
        filterKey
      )
    ) {
      tempItems = filterItemByLocation(
        tempItems,
        foundFilters,
        filterKey
      );
      return;
    }

    // IF SYSTEM FILTER e.g. (System_XXXX)
    if (filterKey === "System") {
      tempItems = filterItemBySystem(tempItems, foundFilters);
      return;
    }

    // IF RATING RACET (they wont include a filter split char)
    if (
      foundFilters.length === 1 &&
      !foundFilters[0].id.includes(FILTERSPLITCHAR)
    ) {
      tempItems = filterItemByRatingFacet(tempItems, filter, facets);
      return;
    }

    // IF FACET
    tempItems = filterItemByFacet(tempItems, foundFilters, filter, facets);
  });
  return tempItems;
};

const filterItemBySystem = (items, filter) => {
  const trees = filter.map((filter) => {
    const systemval = getValueFromFilter(filter.id);
    return systemval.split(";");
  });

  return items.filter((asset) => {
    let matches = false;

    trees.forEach((tree) => {
      let depth = 0;
      tree.forEach((leaf) => {
        if (leaf === asset.assetType.tree[depth] && depth === tree.length - 1) {
          matches = true;
        }
        depth = depth + 1;
      });
      if (matches) {
        return;
      }
    });

    return matches;
  });
};

const filterItemByLocation = (items, foundFilters, filter) => {
  const filterValues = foundFilters
  .map((ff) => getValueFromFilter(ff.id));

  return items.filter((asset) =>
      filterValues.includes(
        asset[
          filter === "Facility" ? 
          "id" : `${filter.toLowerCase()}Id`
        ]
      )
  );
};

const filterItemByRatingFacet = (items, filter, facets = window.facets) => {
  return items.filter((asset) => getKeyLocation(asset, filter, facets));
};

const filterItemByFacet = (
  items,
  foundFilters,
  filter,
  facets = window.facets
) => {
  if (foundFilters.length === 1) {
    return items.filter(
      (asset) =>
        getKeyLocation(asset, filter, facets) ===
        getValueFromFilter(foundFilters[0].id)
    );
  }

  if (foundFilters.length > 1) {
    return items.filter((asset) =>
      foundFilters
        .map((ff) => getValueFromFilter(ff.id))
        .includes(getKeyLocation(asset, filter, facets))
    );
  }
};
