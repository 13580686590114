import { Alert } from "antd";

const commonProps = {
    showIcon: true,
    style:{ backgroundColor: "white", marginBottom: 20}}

export const ShortList = () => {
  return (
    <Alert
      message="You are using a shortened list of the most commonly used assets. More asset types are available for selection."
      type="warning"
      {...commonProps}
    />
  );
};

export const FullList = () => {
  return (
    <Alert
      message="You are using the expanded asset list containing all asset types in ELIAS. The list can be reduced to the most commonly used"
      type="info"
      {...commonProps}
    />);
};
