import { Button, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { EditIcon, DeleteIcon } from "components/Icons";
import { Status } from "helpers/statusHelpers";
import { mappingElementStatusVisualIndicator } from "../utils";

import "./FabricElementsGroupHeader.scss";

const FabricElementsGroupHeader = ({
  current,
  onAdd,
  isOpen,
  sections,
  category,
  overallValidationStatus,
}) => {
  return (
    <div
      className="fabric-elements-group-header"
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="validation-status-icon">
          {mappingElementStatusVisualIndicator[overallValidationStatus]}
        </div>
        <span>{current}</span>
      </div>

      <Button
        type="clear"
        icon={<EditIcon className="edit-icon" />}
        onClick={(e) => {
          if (isOpen) {
            e.stopPropagation();
          }
          e.preventDefault();
          onAdd();
        }}
      />
    </div>
  );
};

export default FabricElementsGroupHeader;
