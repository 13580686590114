import { ArrowDown } from "components/Icons";
import cx from "classnames";

import "./SortButton.scss";

export default function SortButton({ sortOrder, onClick }) {
  return (
    <div
      onClick={onClick}
      className={cx("sort-button", {
        "sort-up": sortOrder === "ascend",
        "sort-down": sortOrder === "descend",
        "sort-none": !sortOrder,
      })}
    >
      <div className="arrow-up">
        <ArrowDown />
      </div>
      <div className="arrow-down">
        <ArrowDown />
      </div>
    </div>
  );
}
