import md5 from "md5";
import { getLocalDbKeysSet } from "helpers/generalHelpers";
import { ADD_PENDING, BATCH, deviceImages } from "./deviceImages";
import { THROTTLE_INTERVAL_FETCH_ASSETS_MILLISECONDS } from "config";
import { throttle } from "lodash";

export const throttledRefreshImages = throttle(
  refreshImages,
  THROTTLE_INTERVAL_FETCH_ASSETS_MILLISECONDS,
  {
    trailing: true,
  }
);

export async function refreshImages(items) {
  if (!deviceImages.isInitialised) {
    const dbKeySet = await getLocalDbKeysSet();

    await deviceImages.initialiseImages(dbKeySet)
  }

  if (items) {
    await queueImageDownloadsForItems(items)
  } 
}

const processItems = async ({items, handleImage, imagesToDownload}) => {

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const facilityId = item.facilityId || item.id;

    if (!window.activeFacilities[facilityId]) {
      continue;
    }
    
    if (!item.images?.length) {
      continue;
    }

    if (typeof imagesToDownload === "object" && !imagesToDownload[facilityId]) {
      imagesToDownload[facilityId] = []
    }

    for (let j = 0; j < item.images.length; j++) {
      const imageDetails = item.images[j];

      if (!imageDetails.picture) {
        continue;
      }

      const imageHash = md5(imageDetails.picture.key)

      const localKey = makeImageLocalKey({
        hash: imageHash,
        facilityId,
      })

      const existingImage = 
        deviceImages.downloaded[facilityId]?.[localKey] 
        || deviceImages.pendingDownload[facilityId]?.[localKey]

      if (existingImage) {
        continue;
      }

      await handleImage({
        imageData: {
          key: imageDetails.picture.key,
          bucket: imageDetails.picture.bucket
        }, 
        localKey, 
        facilityId,
      })
    }
  }
}

const addPendingImagesBatch = (items) => {
  const imagesToDownload = {};
    
  const addToQueue = function(imageData) {
    imagesToDownload[imageData.facilityId].push(imageData);
  }
  
  processItems({
    items, 
    handleImage: addToQueue, 
    imagesToDownload
  })

  Object.values(imagesToDownload).forEach((data) => {
    if (data.length) {
      deviceImages.addToQueue(BATCH, {
        action: ADD_PENDING, 
        data
      })
    }
  })
}

const handleProcessImagesPending = async (items) => {
  deviceImages.isProcessing = true

  await processItems({
    items, 
    handleImage: (imageData) => deviceImages.addPendingDownload(imageData), 
  })

  if (deviceImages.pendingDownloadCount > 0) {
    await deviceImages.handlePendingImages()
  } else {
    deviceImages.isProcessing = false
  }
}

async function queueImageDownloadsForItems(items) {
  if (
    deviceImages.isProcessing || 
    deviceImages.processingQueue.length
  ) {
    addPendingImagesBatch(items) 
  } else {
    handleProcessImagesPending(items) 
  }
}

export function makeImageLocalKey({ type = "downloaded", hash, facilityId }) {
  let prefix = "downloadedImage";
  if (type === "pending") {
    prefix = "pendingImage";
  }
  return `${prefix}-${facilityId}-${hash}`;
}