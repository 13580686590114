import React from "react";
import cx from "classnames";
import { buildURL } from "helpers/generalHelpers";
import { Link } from "react-router-dom";
import "../../../styles/customLinks.scss";

export default function AssetLocationLinks({
  project = {},
  facility,
  floor,
  space,
  className,
}) {
  return (
    <div className={cx("asset-location-links", className)}>
      {project && facility && (
        <>
          <Link to={buildURL({ project, facility })}>
            {facility.name}
            {floor && ","}
          </Link>
          {floor && (
            <>
              <Link to={buildURL({ project, facility, floor })}>
                {floor.name}
                {space && ","}
              </Link>
              {space && (
                <>
                  <Link to={buildURL({ project, facility, floor, space })}>
                    {space.name}
                  </Link>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
