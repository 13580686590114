import { useState, useEffect } from "react";
import { keys as localDbKeys, set as localDbSet } from "idb-keyval";
import { Hub, API } from "aws-amplify";
import md5 from "md5";
import {
  PING_INTERVAL_MILLISECONDS,
  PING_TIMEOUT_MILLISECONDS,
  SUCCESSFUL_CONSECUTIVE_REQUESTS_REQUIRED,
} from "config";
import { deviceImages } from "helpers/deviceImages";
import ImageOutbox from "helpers/imageOutbox";
import { getCurrentSession } from "helpers/apiHelpers";

let pingTimeout;
let errorTimeout;
let lastRequestId;

const useDataStoreSyncStatus = ({ restartDataStore, online }) => {
  const [isOutboxEmpty, setIsOutboxEmpty] = useState();
  const [outboxData, setOutboxData] = useState([]);
  const [isSyncingData, setIsSyncingData] = useState(online || true);
  const [isOnline, setIsOnline] = useState(online || true);
  const [imagesToUploadCount, setImagesToUploadCount] = useState(0);
  const [imagesToDownloadCount, setImagesToDownloadCount] = useState(0);
  const [pingSuccessCount, setPingSuccessCount] = useState(SUCCESSFUL_CONSECUTIVE_REQUESTS_REQUIRED);

  useEffect(() => {
    const removeListenerDataStore = Hub.listen("datastore", onDataStoreEvent);
    checkImagesToUploadCount();
    checkImagesToDownloadCount();
    pingServer();

    if (online) {
      window.isOnline = true
    }

    return () => {
      removeListenerDataStore();

      if (pingTimeout) {
        clearTimeout(pingTimeout);
      }

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }
    };
  }, []);

  useEffect(() => {
    async function updateOnlineStatus() {
      if (pingSuccessCount >= SUCCESSFUL_CONSECUTIVE_REQUESTS_REQUIRED) {
        if (!isOnline) {
          dispatchEvent(new Event("online"));
          setIsOnline(true);
          window.isOnline = true
          try {
            await getCurrentSession();
          } catch (e) {
            console.log("Datastore Auth issue", e);
          }
          restartDataStore();
        }
        if (isOnline) {
          dispatchEvent(new Event("online"));
        }
      } else {
        if (isOnline) {
          dispatchEvent(new Event("offline"));
          setIsSyncingData(false)
          setIsOnline(false);
          window.isOnline = false
        }
      }
    }

    updateOnlineStatus();
  }, [pingSuccessCount, isOnline, restartDataStore]);

  useEffect(() => {
    if (!isOnline) {
      setPingSuccessCount(0);
    }
  }, [isOnline]);

  async function pingServer() {
    let requestId = Date.now();
    lastRequestId = requestId;
    let errorTimeout;
    try {
      let startTime = Date.now();

      errorTimeout = setTimeout(async () => {
        await setPingSuccessCount(0);
      }, PING_TIMEOUT_MILLISECONDS);

      await ping();

      clearTimeout(errorTimeout);

      let endTime = Date.now();
      let duration = endTime - startTime;
      // console.log(`ping finished: ${duration / 1000} seconds`);
      if (duration > PING_TIMEOUT_MILLISECONDS) {
        setPingSuccessCount(0);
      } else {
        if (requestId === lastRequestId) {
          setPingSuccessCount(prevPingCount => prevPingCount + 1);
        }
      }
    } catch (e) {
      setPingSuccessCount(0);
    }

    pingTimeout = setTimeout(pingServer, PING_INTERVAL_MILLISECONDS);
  }

  async function checkImagesToUploadCount() {
    const outbox = new ImageOutbox();

    setImagesToUploadCount(await outbox.getPendingImageTotal());

    setTimeout(checkImagesToUploadCount, 1000);
  }

  async function checkImagesToDownloadCount() {
    const pendingDownloadCount = deviceImages.pendingDownloadCount

    setImagesToDownloadCount(pendingDownloadCount)

    if (pendingDownloadCount) {
      setTimeout(checkImagesToDownloadCount, 1000);
    } else {
      setTimeout(checkImagesToDownloadCount, 3000);
    }
  }

  async function onDataStoreEvent(hubData) {
    const { event, data } = hubData.payload;
    // console.log(event, data)
    if (event === "networkStatus") {
      // setIsAmplifyOnline(data.active);
    } else if (event === "outboxStatus") {
      setIsOutboxEmpty(data.isEmpty);
      setOutboxData(data.idsInOutbox);
    } else if (event === "syncQueriesStarted") {
      setIsSyncingData(true);
    } else if (event === "syncQueriesReady") {
      setIsSyncingData(false);
    } else if (event === "outboxMutationDequeued") {
      await localDbSet(
        `dequeued-${data.element.id}`,
        JSON.stringify({ data, name: data.model })
      );
    } else if (event === "ready") {
      setIsSyncingData(false);
    }
  }

  return {
    isOnline,
    isSyncingData,
    isOutboxEmpty,
    outboxData,
    imagesToUploadCount,
    imagesToDownloadCount,
  };
};

export default useDataStoreSyncStatus;

// var request_image = function (url) {
//   return new Promise(function (resolve, reject) {
//     var img = new Image();
//     img.onload = function () {
//       console.log("SUCCESS from image");
//       resolve(img);
//     };
//     img.onerror = function (e) {
//       console.log("error :", e);
//       console.log("ERROR from image");
//       reject(url);
//     };
//     img.src =
//       url +
//       "?random-no-cache=" +
//       Math.floor((1 + Math.random()) * 0x10000).toString(16);
//   });
// };

// var ping = function (url, multiplier) {
//   return new Promise(function (resolve, reject) {
//     var start = new Date().getTime();
//     var response = function () {
//       var delta = new Date().getTime() - start;
//       delta *= multiplier || 1;
//       resolve(delta);
//     };
//     request_image(url).then(response).catch(response);

//     // Set a timeout for max-pings, 5s.
//     setTimeout(function () {
//       reject(Error("Timeout"));
//     }, PING_TIMEOUT_MILLISECONDS);
//   });
// };

async function ping() {
  await API.get("rest", "/items/ping", {
    response: false,
  });
}
