export const spaceTypes = [
  {
    label: "#Fabric - Building Externals",
    code: "#Fabric - Building Externals",
  },
  { label: "#Fabric - Whole Building", code: "#Fabric - Whole Building" },
  { label: "Accommodation", code: "Accommodation" },
  { label: "Balcony", code: "Balcony" },
  { label: "Banking Hall", code: "Banking Hall" },
  { label: "Boiler House/Room", code: "Boiler House/Room" },
  { label: "Car Park", code: "Car Park" },
  { label: "Cashiers Counter", code: "Cashiers Counter" },
  { label: "Catering Store", code: "Catering Store" },
  { label: "Ceiling Area", code: "Ceiling Area" },
  { label: "Changing Room", code: "Changing Room" },
  { label: "Classroom", code: "Classroom" },
  { label: "Cleaners Room / Store", code: "Cleaners Room / Store" },
  { label: "Cloakroom", code: "Cloakroom" },
  { label: "Corridor", code: "Corridor" },
  { label: "Cupboard", code: "Cupboard" },
  { label: "Dining Area/Restaurant", code: "Dining Area/Restaurant" },
  { label: "Electrical Substation", code: "Electrical Substation" },
  { label: "Electrical Switchroom", code: "Electrical Switchroom" },
  { label: "En-Suite", code: "En-Suite" },
  { label: "Entrance Area", code: "Entrance Area" },
  { label: "External Area", code: "External Area" },
  { label: "Floor Area", code: "Floor Area" },
  { label: "Gym", code: "Gym" },
  { label: "Hall", code: "Hall" },
  { label: "It/Comms Room", code: "It/Comms Room" },
  { label: "Kitchen", code: "Kitchen" },
  { label: "Laboratory", code: "Laboratory" },
  { label: "Library", code: "Library" },
  { label: "Lift Room", code: "Lift Room" },
  { label: "Lobby", code: "Lobby" },
  { label: "Mail Room", code: "Mail Room" },
  { label: "Medical Room", code: "Medical Room" },
  { label: "Meeting Room", code: "Meeting Room" },
  { label: "Office", code: "Office" },
  { label: "Plant Room", code: "Plant Room" },
  { label: "Pool Area", code: "Pool Area" },
  { label: "Quiet Room", code: "Quiet Room" },
  { label: "Reception Area", code: "Reception Area" },
  { label: "Room", code: "Room" },
  { label: "Service Riser", code: "Service Riser" },
  { label: "Shower Room", code: "Shower Room" },
  { label: "Staff Accommodation", code: "Staff Accommodation" },
  { label: "Stairwell", code: "Stairwell" },
  { label: "Steam/Sauna Room", code: "Steam/Sauna Room" },
  { label: "Store", code: "Store" },
  { label: "Switch Room", code: "Switch Room" },
  { label: "Tank Room", code: "Tank Room" },
  { label: "Theatre", code: "Theatre" },
  { label: "Toilet (Unisex)", code: "Toilet (Unisex)" },
  { label: "Toilet Disabled", code: "Toilet Disabled" },
  { label: "Toilet Gents", code: "Toilet Gents" },
  { label: "Toilet Ladies", code: "Toilet Ladies" },
  { label: "Toilet Public", code: "Toilet Public" },
  { label: "Utilities Cupboard", code: "Utilities Cupboard" },
  { label: "Various Areas", code: "Various Areas" },
  { label: "Walkway", code: "Walkway" },
  { label: "Workshop", code: "Workshop" },
];
