// @ts-check
import { initSchema } from '@reams/elias-store';
import { schema } from './schema';

const SpaceStatus = {
  "NOT_STARTED": "NOT_STARTED",
  "INACCESSIBLE": "INACCESSIBLE",
  "IN_PROGRESS": "IN_PROGRESS",
  "DONE": "DONE"
};

const { Site, Region, Facility, Floor, Space, Asset, Doc, AssetImage, S3Object, AssetType } = initSchema(schema);

export {
  Site,
  Region,
  Facility,
  Floor,
  Space,
  Asset,
  SpaceStatus,
  Doc,
  AssetImage,
  S3Object,
  AssetType,
};