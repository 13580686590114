import { DataStore } from '@reams/elias-store'
import { Site } from "models"


const handleIncomingSites = (incomingSites, setSites) => {
  const siteObj = {}

  incomingSites.forEach(site => {
    siteObj[site.id] = site
  })
  
  setSites(siteObj)
}

export const getDataStoreSites = ({ setSites, tenantId }) => (
  DataStore.observeQuery(Site, (x) =>
    x.tenantId("eq", tenantId))
  .subscribe(async({items}) => {
    if (items) {
      handleIncomingSites(items, setSites)
    }
  })
)