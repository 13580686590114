import { Row, Col } from "antd";
import Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { iconChooser } from "components/AppHeader/GlobalSearch/globalSearchHelpers";
import {
  AssetResult,
  FacilityResult,
  SpaceResult,
  FloorResult,
} from "./ResultsTypes";

const SearchResultsSwitch = ({ item, type }) => {
  if (type === "asset")
    return <AssetResult item={item} />;
  if (type === "facility")
    return <FacilityResult item={item} />;
  if (type === "floor")
    return <FloorResult item={item} />;
  if (type === "space")
    return <SpaceResult item={item} />;
  return null;
};

const handleNavigation = ({ item, type, navigate }) => {
  if (type === "asset")
    navigate(
      `/space/${item.spaceId}`, {state : { 
        asset: item
      }}
    );
  if (type === "facility") navigate(`/facility/${item.id}`);
  if (type === "floor")
    navigate(`/floor/${item.id}`);
  if (type === "space")
    navigate(
      `/space/${item.id}`
    );
};

const SearchResult = ({ item }) => {
  const navigate = useNavigate();
  return (
    <>
      <Row
        className="result"
        style={{ padding: 10, cursor: "pointer" }}
        onClick={() =>
          handleNavigation({ item, type: item.itemType, navigate })
        }
      >
        <Col span={2} style={{ marginRight: "5px"}}>
          <Icon
            style={{ width: "1.5rem", height: "1.5rem" }}
            component={iconChooser[item?.itemType]}
          />
        </Col>
        <Col span={20}>
          <SearchResultsSwitch
            item={item}
            type={item.itemType}
          />
        </Col>
      </Row>
    </>
  );
};

export default SearchResult;
