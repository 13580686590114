import { DataStore } from '@reams/elias-store'
import { Floor } from "models"


const handleIncomingFloors = (incomingFloors, setFloors) => {
  const floorObj = {
    items: {},
    groupByFacility: {}
  }

  incomingFloors.forEach(floor => {
    floorObj.items[floor.id] = floor

    if (!floorObj.groupByFacility[floor.facilityId]) {
      floorObj.groupByFacility[floor.facilityId] = {}
    }

    // store as object to handle duplicates
    floorObj.groupByFacility[floor.facilityId][floor.id] = true
  })

  const facilities = Object.keys(floorObj.groupByFacility)

  // convert to array for less memory use and easier handling in app
  for (let i = 0; i < facilities.length; i++) {
    const facilityId = facilities[i]

    const facilityFloors = floorObj.groupByFacility[facilityId]

    const facilityFloorsArray = Object.keys(facilityFloors)

    floorObj.groupByFacility[facilityId] = facilityFloorsArray
  }
  
  setFloors(floorObj)
}

export const getDataStoreFloors = ({setFloors, tenantId, facilitiesToDownload}) => (
  DataStore.observeQuery(Floor, (x) => 
    x.tenantId("eq", tenantId)
    .or(x => facilitiesToDownload
    .reduce((acc, id) => acc.facilityId('eq', id), x)
  ))
  .subscribe(async({items}) => {
    if (items) {
      handleIncomingFloors(items, setFloors)
    }
  })
)