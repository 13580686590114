/* eslint-disable default-case */
import {
  get as localDbGet,
  keys as localDbKeys,
} from "idb-keyval";
import { makeImageLocalKey } from "./imageHelpers";

export const fetchDequeuedItems = async () => {
  const allLocalDbKeys = await localDbKeys();

  return allLocalDbKeys.filter((key) => key.startsWith("dequeued"));
};

const fetchPendingImages = async (hash, facilityId) => {
  const pendingImageKey = makeImageLocalKey({
    type: "pending",
    hash,
    facilityId,
  });
  const dataUri = await localDbGet(pendingImageKey);

  return dataUri;
};
export const forceSync = async ({ facilities: _facilities, floors: _floors, spaces:_spaces, assets:_assets }) => {
  const dequeuedItems = await fetchDequeuedItems();

  const assets = [];
  const spaces = [];
  const floors = [];
  const facilities = [];
  const pendingImages = {};

  await Promise.all(
    dequeuedItems.map(async (id) => {
      const item = JSON.parse(await localDbGet(id));

      let fetchedItem

      switch (item.name) {
        case 'Facility':
          fetchedItem = _facilities[item.data.element.id]
          facilities.push(fetchedItem)
        break

        case 'Floor':
          fetchedItem = _floors[item.data.element.id]
          floors.push(fetchedItem)
        break

        case 'Space':
          fetchedItem = _spaces[item.data.element.id]
          spaces.push(fetchedItem)
          break
        
        case 'Asset':
          fetchedItem = _assets.items[item.data.element.id]
          assets.push(fetchedItem)
          break
      }

      if (!fetchedItem) return

      await Promise.all((fetchedItem?.images || []).map(async (image) => {
        
        if (!image.picture) {
          const dataUri = await fetchPendingImages(image.hash, fetchedItem.facilityId || fetchedItem.id)
          pendingImages[image.hash] = dataUri
        }
        return null
      }))
    })
  );

  const isolated = {assets, spaces, floors, facilities, pendingImages}

  let filename = `Isolated Data Export - ${new Date().toISOString()}.json`;
  let contentType = "application/json;charset=utf-8;";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob(
      [decodeURIComponent(encodeURI(JSON.stringify(isolated)))],
      { type: contentType }
    );
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement("a");
    a.download = filename;
    a.href =
      "data:" +
      contentType +
      "," +
      encodeURIComponent(JSON.stringify(isolated));
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
