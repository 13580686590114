import {
  Collapse,
  Typography,
  Button,
  Dropdown,
  Menu,
  Modal,
  Alert,
} from "antd";
import { useState } from "react";
import cx from "classnames";
import {
  UpOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { EditIcon, DeleteIcon } from "components/Icons";
import FabricElementsGroupHeader from "../FabricElementsGroupHeader/FabricElementsGroupHeader";
import {DataStore} from '@reams/elias-store';
import { mappingElementStatusVisualIndicator } from "../utils";
import {
  getFabricElementGroupSections,
  getGroupValidationStatus,
} from "helpers/fabricHelpers";

import "./FabricElementsGroup.scss";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const FabricElementsGroup = ({
  category,
  onAdd,
  fabricAssets,
  onEditAsset,
  onEditSection,
}) => {
  const [open, setOpen] = useState(false);
  const onExpandChange = () => setOpen(!open);
  const {assets} = useEntityData()

  let sections = getFabricElementGroupSections({
    category,
    fabricAssets,
  });
  let { overallValidationStatus, validationMessages } =
    getGroupValidationStatus({
      sections,
      category,
    });

  function displayContent() {
    let sectionsWithNoPercentageOfParent = sections.filter(
      (section) => !section.percentageOfParent
    );
    let messages = [];
    if (sectionsWithNoPercentageOfParent.length > 0) {
      messages.push(
        <Typography.Paragraph>
          Please specify the surface area for:
          <ul>
            {sectionsWithNoPercentageOfParent.map((section, i) => (
              <li key={i}>{section.type}</li>
            ))}
          </ul>
        </Typography.Paragraph>
      );
    }
    messages = [...messages, ...validationMessages];

    return (
      <>
        {messages.length > 0 && (
          <Alert
            className="validation-issues"
            style={{ backgroundColor: "white" }}
            message="Validation issues"
            description={
              <div>
                {messages.map((message, i) => (
                  <Typography.Paragraph key={i}>{message}</Typography.Paragraph>
                ))}
              </div>
            }
            type="warning"
            showIcon
          />
        )}

        {sections.map((section, i) => {
          return (
            <div className="section-container" key={i}>
              <div className="section-header">
                {mappingElementStatusVisualIndicator[section.validationStatus]}

                <Typography.Text className="section-name">
                  {section.type}
                </Typography.Text>
                <Typography.Text
                  className={cx("percentage", {
                    "not-specified": section.percentageOfParent === 0,
                  })}
                >
                  {section.percentageOfParent}%
                </Typography.Text>
                <Typography.Text className="priority">
                  {section.priority}
                </Typography.Text>
                <Typography.Text className="condition">
                  {section.condition}
                </Typography.Text>
                <Typography.Text className="selector-id">
                  {section.selectorId}
                </Typography.Text>

                <Button
                  type="clear"
                  onClick={() => {
                    onEditSection(section);
                  }}
                >
                  <EllipsisOutlined />
                </Button>
              </div>
              {section.fabricAssets.map((fabricAsset) => {
                return (
                  <div className="fabric-asset-container" key={fabricAsset.id}>
                    <Typography.Text className="fabric-asset-name">
                      {fabricAsset.assetType.description}
                      {fabricAsset.facets.gia !== null &&
                      fabricAsset.facets.gia !== undefined
                        ? ` (${fabricAsset.facets.gia} m²)`
                        : ""}
                    </Typography.Text>
                    <Typography.Text className="percentage">
                      {fabricAsset.facets.giaPercentage}%
                    </Typography.Text>
                    <Typography.Text className="priority">
                      {fabricAsset.facets.priority}
                    </Typography.Text>
                    <Typography.Text className="condition">
                      {fabricAsset.facets.condition}
                    </Typography.Text>
                    <Typography.Text className="selector-id">
                      {fabricAsset.facets.selectorId}
                    </Typography.Text>
                    <Dropdown
                      trigger="click"
                      overlay={
                        <Menu>
                          <Menu.Item
                            icon={<EditIcon />}
                            key="edit"
                            onClick={() => {
                              onEditAsset(category, fabricAsset);
                            }}
                          >
                            Edit
                          </Menu.Item>
                          <Menu.Item
                            key="delete"
                            icon={<DeleteIcon />}
                            onClick={() => {
                              Modal.confirm({
                                title: "Are you sure to delete this element?",
                                icon: <QuestionCircleOutlined />,
                                okText: "Yes",
                                cancelText: "No",
                                onOk: async () => {
                                  const itemToDelete = assets.items[fabricAsset.id]
                                  if (itemToDelete) {
                                    await DataStore.delete(itemToDelete);
                                  }
                                },
                              });
                            }}
                          >
                            Delete
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button type="clear">
                        <EllipsisOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div style={{ marginBottom: 20 }} className="fabric-elements-group">
      <Collapse
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined type="up" rotate={isActive ? 180 : 0} />
        )}
        onChange={onExpandChange}
        activeKey={open ? "singlePanel" : undefined}
      >
        <Collapse.Panel
          key="singlePanel"
          header={
            <FabricElementsGroupHeader
              current={category.type}
              onAdd={(e) => {
                onAdd(category);
              }}
              isOpen={open}
              sections={sections}
              category={category}
              overallValidationStatus={overallValidationStatus}
            />
          }
        >
          {displayContent()}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default FabricElementsGroup;
