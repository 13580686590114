import { Col } from "antd";
import {
  EllipsisText,
  LocationResult,
  itemDivider,
} from "components/AppHeader/GlobalSearch/globalSearchHelpers";

const FacilityResult = ({ item }) => (
  <>
    <Col span={24}>
      <EllipsisText
        text={itemDivider(
          item?.name,
          item?.facets?.["facility-type"]
        )}
      />
    </Col>
    <LocationResult item={item} />
  </>
);

export default FacilityResult;
