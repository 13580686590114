import React from 'react'
import Webcam from 'react-webcam'
import {  CameraOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

const videoConstraints = {
    facingMode: { exact: "environment" },
    // facingMode: 'user',
};

const CaptureImage = ({ onCapture }) => {

    const [open, setOpen] = React.useState(false)
    const webcamRef = React.useRef(null);

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        
        console.info(imageSrc)
        onCapture(imageSrc)
        setOpen(false)
    }

    return (
        <>
            <Modal visible={open} onCancel={() => setOpen(false)} onOk={capture} okText={'Capture Image'} destroyOnClose>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    forceScreenshotSourceSize={true}
                    screenshotQuality={1}
                    width={'100%'}
                />
            </Modal>
            <Button
                icon={<CameraOutlined />}
                className="camera-button"
                onClick={()=> setOpen(true)}
            >
                Photo
            </Button>
        </>
    );
};

export default CaptureImage