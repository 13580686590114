import { DataStore, syncExpression } from '@reams/elias-store';
import { set as localDbSet, del as localDbDelete } from "idb-keyval";
import { Asset, Space, Floor, Facility, Site, Region } from "models";
import { makeImageLocalKey } from "helpers/imageHelpers";
import awsExports from "../aws-exports";
import md5 from "md5";
import ImageOutbox from "./imageOutbox";
import { API, Auth } from 'aws-amplify';
import {Hub} from '@aws-amplify/core'

export async function configureDataStore() {
  DataStore.configure({
    ...awsExports,
    errorHandler: (error) => {
      console.error("Unrecoverable DataStore error", { error });
      if (error.errorType === "ConditionalCheckFailedException") {
        console.log(
          "Caught error ConditionalCheckFailedException, simulating an update"
        );
        setTimeout(() => handleFailedCreate(error), 500);
      }
      else {
        localDbSet(
          `error-${new Date().toISOString()}-${error.errorType}`,
          JSON.stringify(error)
        );
      }
    },
    maxRecordsToSync: 1000000,
    syncPageSize: 1000,
    fullSyncInterval: 60*14, // minutes
    pollOffline: { enabled: true, interval: 60000 },
    syncExpressions: [
      syncExpression(Region, async () => {
        return (region) => region.tenantId("eq", window.tenantId);
      }),
      syncExpression(Site, async () => {
        return (site) => site.tenantId("eq", window.tenantId);
      }),
      syncExpression(Facility, async () => {
        return (facility) => facility.tenantId("eq", window.tenantId);
      }),
      syncExpression(Asset, async () => {
        return (asset) => {
          return asset.tenantId("eq", window.tenantId).or((asset) => {
            let condition = asset;
            if (
              !window.targetFacilities ||
              window.targetFacilities.length === 0
            ) {
              condition = condition.facilityId("eq", "nothing");
              return condition;
            }

            for (let i = 0; i < window.targetFacilities.length; i++) {
              const facilityId = window.targetFacilities[i];
              condition = condition.facilityId("eq", facilityId);
            }
            return condition;
          });
        };
      }),
      syncExpression(Space, async () => {
        return (space) => {
          return space.tenantId("eq", window.tenantId).or((space) => {
            let condition = space;
            if (
              !window.targetFacilities ||
              window.targetFacilities.length === 0
            ) {
              condition = condition.facilityId("eq", "nothing");
              return condition;
            }
            
            for (let i = 0; i < window.targetFacilities.length; i++) {
              const facilityId = window.targetFacilities[i];
              condition = condition.facilityId("eq", facilityId);
            }

            return condition;
          });
        };
      }),
      syncExpression(Floor, async () => {
        return (floor) => {
          return floor.tenantId("eq", window.tenantId).or((floor) => {
            let condition = floor;
            if (
              !window.targetFacilities ||
              window.targetFacilities.length === 0
            ) {
              condition = condition.facilityId("eq", "nothing");
              return condition;
            }
            
            for (let i = 0; i < window.targetFacilities.length; i++) {
              const facilityId = window.targetFacilities[i];
              condition = condition.facilityId("eq", facilityId);
            }

            return condition;
          });
        };
      }),
    ],
  }, Auth, API, Hub);
}

async function handleFailedCreate(error) {
  let model;
  let { localModel } = error;
  if (localModel.spaceId) {
    model = Asset;
  } else if (localModel.floorId) {
    model = Space;
  } else if (localModel.facilityId) {
    model = Floor;
  } else if (localModel.siteId) {
    model = Facility;
  }
  const original = await DataStore.query(model, localModel.id);
  console.log("original =", original);
  try {
    const r = await DataStore.save(
      model.copyOf(original, (updated) => {
        updated.randomNumber = Math.floor(Math.random() * 1000000);
      })
    );
  } catch (error) {
    console.log(error.message);
  }
}

export async function onDataStoreEvent(hubData) {
  const { event, data } = hubData.payload;
  if (event === "networkStatus") {
    if (data.active) {
      new ImageOutbox().resume();
    }
  }
  if (event === "outboxMutationProcessed") {
    onMutationProcessed({
      item: data.element,
      modelName: data.model.name,
    });
  }
}

async function onMutationProcessed({ item, modelName }) {
  let facilityId = item.facilityId;

  if (modelName === "Facility") {
    facilityId = item.id;
  }
  if (item._deleted) {
    if (item.images) {
      setTimeout(async () => {
        for (let i = 0; i < item.images.length; i++) {
          const image = item.images[i];
          await localDbDelete(
            makeImageLocalKey({
              hash: md5(image.picture.key),
              facilityId,
            })
          );
        }
      }, 2000); // we want to do this a bit later to not interfere with checking for whether the images have been downloaded
      return;
    }
  }
  const outbox = new ImageOutbox();
  outbox.resume();
}
