export const SORT_ORDERS = {
  ASCENDING: "ascend",
  DESCENDING: "descend",
};

export const RESTRICTED_IMG_OPTIONS = [
  "Restricted Area",
  "No Access",
  "High Level",
  "Denied Request"
]