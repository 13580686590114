import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Input, Button } from "antd";
import "./QuantityIncreaser.scss";

const QuantityIncreaser = (props) => {
  const { disabled, inputValue, increase, decrease, onChange } = props;

  const [value, setValue] = useState();

  useEffect(() => {
    setValue(inputValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx("button-pair")}>
      <Button
        disabled={value <= 1}
        onClick={() => {
          setValue((qty) => qty - 1);
          decrease(value);
        }}
      >
        <span>-</span>
      </Button>
      <Input
        type="number"
        value={value}
        onClick={(e) => {
          e.stopPropagation();
        }}
        min={1}
        onChange={(e) => {
          setValue(Number.parseInt(e.target.value));
          onChange(Number.parseInt(e.target.value));
        }}
        autoComplete="off"
        className={"input-incrreaser"}
      />
      <Button
        disabled={disabled}
        onClick={() => {
          setValue((qty) => qty + 1);
          increase(value);
        }}
      >
        <span>+</span>
      </Button>
    </div>
  );
};

export default QuantityIncreaser;
