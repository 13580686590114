import { useEffect, useState } from "react";
import { Typography, Collapse } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import cx from "classnames";
import { DisplayFieldForFacet } from "./DisplayFieldForFacet";
import AssetLocationLinks from "components/ItemDrawer/AssetLocationLinks/AssetLocationLinks";
import { displayFooterCheckboxes } from "./displayFooterCheckboxes";
import DrawerPhotoSection from "components/Form/DrawerPhotoSection/DrawerPhotoSection";

import "../styles/drawer.scss";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const { Panel } = Collapse;
export const makeEoL = (installDate, lifecycleYears = 15) =>
  installDate
    ? moment(installDate).add(lifecycleYears, "years").format("YYYY-MM-DD")
    : null;

export function stripAsset(copy) {
  const x = { ...copy };
  const facets = { ...x.facets };
  const notes = { ...x.notes };

  delete facets["validation-status"];
  delete notes.asbestos;
  delete notes.hsIssue;
  delete notes.reminder;
  delete notes.image;
  if (!x.assetType.virtual) {
    delete facets.barcode;
    delete facets.condition;
    delete facets["existing-asset-number"];
    delete notes.condition;
    delete notes.description;
    delete notes.notes;
    delete x.images;
  }
  x.facets = facets;
  x.notes = notes;

  return x;
}

export function DisplayFormFields({
  assetDefinition,
  images,
  setImages,
  form,
  itemDefinition,
  facets,
  forceUpdate,
  item,
  itemType,
  isFabricSection,
  setIsAssetTypeDrawerVisible,
  parents,
  errors,
}) {
  const { userData }= useEntityData()

  const [activeGroups, setActiveGroups] = useState();
  let groups = [];
  let facetDefinitions = [];

  useEffect(() => {
    openGroupOnValidationError(groups, errors);
  }, [errors]);

  if (!itemDefinition || !itemDefinition.facets) {
    return null;
  }

  const FACETS_TO_SKIP = ["uf19", "uf20"];

  itemDefinition.facets
    .filter((facetId) => !FACETS_TO_SKIP.includes(facetId))
    .forEach((facetId) => {
      const facetDefinition = facets.find((facet) => facet.facetId === facetId);
      if (facetDefinition === undefined) {
        return;
      }
      if (!facetDefinition.group) {
        facetDefinition.group = "";
      }

      if (facetDefinition) {
        if (
          facetDefinition?.fabricOnly &&
          !userData.roles.includes("fabric") &&
          !userData.roles.includes("admin")
        )
          return;

        facetDefinitions.push(facetDefinition);
        const newGroup = fullGroupsWithKeys(facetDefinition, groups);

        if (newGroup) {
          groups.push(newGroup);
        }

        if (!newGroup) {
          const groupIndex = groups.findIndex(
            (x) => x.label === facetDefinition.group
          );
          groups[groupIndex] = {
            ...groups[groupIndex],
            key: [
              ...groups[groupIndex].key,
              facetDefinition?.element
                ? `${facetDefinition.element}_${facetDefinition.key}`
                : facetDefinition.key,
            ],
          };
        }
      }
    });

  function fullGroupsWithKeys(facetDefinition, groups) {
    groups.sort((a, b) => (a.order < b.order ? -1 : 1));

    if (groups.map((group) => group.label).includes(facetDefinition.group))
      return false;

    return {
      label: facetDefinition.group,
      key: [
        facetDefinition?.element
          ? `${facetDefinition.element}_${facetDefinition.key}`
          : facetDefinition.key,
      ],
      order: Number.parseInt(facetDefinition?.order.toString().slice(0, 1)),
    };
  }

  function addNotesCheckboxValidation(groups) {
    if (groups.length === 0) return groups;
    const tempGroups = groups;
    tempGroups[groups.length - 1].key.push(
      "notes_reminder",
      "notes_asbestos",
      "notes_hsIssue"
    );

    return tempGroups;
  }

  function openGroupOnValidationError(groups, errors) {
    if (errors) {
      const activeErrors = errors.map((error) => error.name[0]);

      const imageGroup = {
        label: "Images",
        key: ["checkbox_imageRestricted"]
      }

      const foundGroups = [imageGroup, ...groups].filter((group) => {
        const keys = group.key.filter((key) => activeErrors.includes(key));

        if (keys.length === 0) return false;

        if (keys.length !== 0) return true;
      });

      setActiveGroups(foundGroups.map((fg) => fg.label));
      return foundGroups;
    }
  }

  return (
    <>
      <Collapse
        bordered={false}
        defaultActiveKey={[...groups.map((group) => group.label), "Images"]}
        activeKey={
          activeGroups || [...groups.map((group) => group.label), "Images"]
        }
        className="site-collapse-custom-collapse"
        onChange={(x) => {
          setActiveGroups(x);
        }}
      >
        {itemType !== "floor" && !isFabricSection && (
          <Panel header={"Images"} key={"Images"} style={{ fontSize: "16px" }}>
            <DrawerPhotoSection
              form={form}
              forceUpdate={forceUpdate}
              images={images}
              setImages={setImages}
              itemType={itemType}
            />
          </Panel>
        )}

        {addNotesCheckboxValidation(
          groups.sort((a, b) => (a.order < b.order ? -1 : 1))
        ).map((group, i) => {
          const facetsInGroup = facetDefinitions
            .filter((facetDefinition) => facetDefinition.group === group.label)
            .sort((a, b) => (a.order < b.order ? -1 : 1));

          return (
            <>
              <Panel
                header={group.label}
                key={group.label}
                style={{ fontSize: "16px" }}
              >
                <div>
                  {group.label === "Identification" && itemType === "asset" && (
                    <div className="wide-group-fields">
                      {item && !isFabricSection && (
                        <div className="asset-type">
                          <Typography.Text className="asset-type-tree">
                            {form.getFieldValue("assetType") &&
                            form.getFieldValue("assetType").tree
                              ? form.getFieldValue("assetType").tree.join(" / ")
                              : ""}
                          </Typography.Text>
                          <Typography.Text
                            className="open-asset-type-drawer"
                            onClick={() => setIsAssetTypeDrawerVisible(true)}
                          >
                            (change)
                          </Typography.Text>
                        </div>
                      )}

                      {form.getFieldValue("assetType") && !isFabricSection && (
                        <AssetLocationLinks
                          facility={parents.facility}
                          floor={parents.floor}
                          space={parents.space}
                        />
                      )}
                    </div>
                  )}

                  <div className="group-fields">
                    {facetsInGroup.map((facetDefinition, index) => (
                      <DisplayFieldForFacet
                        key={index}
                        images={images}
                        assetDefinition={assetDefinition}
                        form={form}
                        itemDefinition={itemDefinition}
                        facetDefinition={facetDefinition}
                        forceUpdate={forceUpdate}
                        item={item}
                      />
                    ))}
                  </div>
                  {i === groups.length - 1
                    ? (itemType === ("asset" || "space") &&
                        !form.getFieldValue("assetType")) ||
                      isFabricSection
                      ? null
                      : displayFooterCheckboxes({
                          form,
                          itemType,
                          forceUpdate,
                          options: {
                            asbestos: ["floor", "space", "asset"],
                            reminder: ["floor", "space", "asset"],
                            hsIssue: ["floor", "space", "asset"],
                          },
                        })
                    : null}
                </div>
              </Panel>
            </>
          );
        })}
      </Collapse>
    </>
  );
}

export function displayFormLabel({ isDisabled, isMandatory, label }) {
  return (
    <label className={cx({ disabled: isDisabled, mandatory: isMandatory })}>
      {label}
    </label>
  );
}

export function displayErrors({
  areErrorsVisible,
  setAreErrorsVisible,
  errors,
}) {
  if (!areErrorsVisible) {
    return null;
  }

  return (
    <div className="form-errors">
      <Typography.Title level={3}>Validation issues</Typography.Title>
      <div
        className="close-form-errors-button"
        onClick={() => setAreErrorsVisible(false)}
      >
        <CloseOutlined />
      </div>
      {errors.map((errorField) => {
        const error = errorField.errors[0];
        let fieldName = errorField.name[0];

        if (fieldName.includes("_")) {
          fieldName = fieldName
            .split("_")
            .slice(1)
            .join("")
            .split("-")
            .join(" ");
        }

        fieldName = `${fieldName[0].toUpperCase()}${fieldName.substring(1)}`;
        return (
          <Typography.Text className="error-item" key={errorField.name}>
            {fieldName}: {error}
          </Typography.Text>
        );
      })}
    </div>
  );
}

export function getNestedFieldFromForm({ formFields, fieldName }) {
  let fieldData = {};
  for (let key in formFields) {
    if (key.startsWith(`${fieldName}_`)) {
      fieldData[key.replace(`${fieldName}_`, "")] = formFields[key];
    }
  }
  if (Object.keys(fieldData).length === 0) {
    return undefined;
  }
  return fieldData;
}

function initialValueOverrides({ initialValues }, item, facetKey) {
  if (facetKey === "install-date") {
    initialValues[`facets_${facetKey}`] = moment(item.facets[facetKey]);
  }

  // Facility lat long
  if (facetKey === "location") {
    initialValues[`facets_${facetKey}.lat`] = item.facets[facetKey].lat;
    initialValues[`facets_${facetKey}.lon`] = item.facets[facetKey].lon;
  }
}

export function getInitialValues(item) {
  let initialValues = {};
  if (item) {
    for (let facetKey in item.facets) {
      initialValues[`facets_${facetKey}`] = item.facets[facetKey];
      initialValueOverrides({ initialValues }, item, facetKey);
    }

    for (let noteKey in item.notes) {
      let noteValue = item.notes[noteKey];
      if (typeof noteValue === "object" && noteValue?.status) {
        initialValues[`byPass_${noteKey}`] = noteValue.status;
      } else {
        initialValues[`notes_${noteKey}`] = noteValue;
      }
    }

    if (item.notes?.image?.description) {
      initialValues.checkbox_imageRestricted = true;
      initialValues.restricted_image_reason = item.notes.image.description;
    }

    if (item.notes?.hsIssue) {
      initialValues.checkboxes_hsIssue = true;
    }

    if (item.notes?.asbestos) {
      initialValues.checkboxes_asbestos = true;
    }

    if (item.notes?.reminder) {
      initialValues.checkboxes_reminder = true;
    }
  }

  return initialValues;
}
