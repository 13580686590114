import { Button, Input, Tooltip } from "antd";
import { ScanOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import SearchResult from "./SearchResult";
import { Divider, Row, Col, Checkbox, Dropdown } from "antd";
import Mark from "mark.js";
import BarcodeScannerContextProvider from "components/BarcodeScannerContextProvider";
import BarcodeScanner from "components/BarcodeScanner";
import "./style.scss";

export default function SearchMenu({
  searchTextAPI,
  searchResults,
  includeExistingBarcodeAPI,
  displayMenu,
  resultsNumber,
  setResultsNumber,
  displaySearch
}) {
  const [includeExistingBarcode, setIncludeExistingBarcode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [scannerOpen, setScannerOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(true);

  const searchRef = useRef()


  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus()
    }
    

  }, [displaySearch])

  useEffect(() => {
    if (scannerOpen || dropdownOpen) {
      displayMenu(true);
    }

    if (!scannerOpen && !dropdownOpen) {
      displayMenu(false);
      setSearchText('')
    }
  }, [dropdownOpen, scannerOpen]);

  useEffect(() => searchTextAPI(searchText), [searchText]);
  useEffect(
    () => includeExistingBarcodeAPI(includeExistingBarcode),
    [includeExistingBarcode]
  );

  const [markInstance, setMarkInstance] = useState(
    new Mark(document.querySelector("#search-node"))
  );
  useEffect(() => {
    const newMark = new Mark(document.querySelector("#search-node"));
    setMarkInstance(newMark);
    markInstance.unmark({
      done: () => {
        markInstance.mark(searchText);
      },
    });
  }, [searchText, searchResults, resultsNumber]);

  const menu = (
    <div
      aria-hidden
      onMouseDown={(e) => e.preventDefault()}
      style={{
        border: "1px solid #e8e8e8",
        borderRadius: "5px",
        backgroundColor: "white",
        zIndex: 10,
        width: 550,
      }}
    >
      <div id="search-node" className="search-results">
        {searchResults?.slice(0, resultsNumber).map((item, index) => (
          <div
            onClick={() => {
              setScannerOpen(false);
              setDropdownOpen(false);
            }}
          >
            <SearchResult
              item={item}
              key={index}
            />
          </div>
        ))}
        {searchResults?.length !== 0 && (
          <div className="moreButtonContainer">
            <Button
              className="btnLoadMore"
              disabled={searchResults.length < resultsNumber}
              onClick={() =>
                searchResults.length > resultsNumber
                  ? setResultsNumber((resultsNumber += 10))
                  : null
              }
            >
              More
            </Button>
          </div>
        )}
      </div>
      {searchText.length > 2 && searchResults?.length === 0 && (
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ margin: 10 }}>{`${searchText} has no matches in your estate.`}</Col>
        </Row>
      )}
      {searchResults?.length > 0 && <Divider style={{ margin: "4px 0" }} />}
      <div style={{ padding: "8px 8px", cursor: "pointer" }}>
        <Checkbox
          onChange={(e) => setIncludeExistingBarcode(e.target.checked)}
          checked={includeExistingBarcode}
        >
          Include the asset Existing Barcode field
        </Checkbox>
      </div>
    </div>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        visible={dropdownOpen  && searchText.length > 0}
        overlayStyle={{ zIndex: 10 }}
      >
        <Input.Group compact style={{ display: "flex" }}>
          <Input
            autoFocus
            className="searchMenu"
            placeholder="Search..."
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            onBlur={() => {
                setDropdownOpen(false);
            }}
            onFocus={() => {
              setDropdownOpen(true);
            }}
            ref={searchRef}
            suffix={<ScanOutlined />}
          />

          <Tooltip
            key="ScanToolTip"
            title={"Scan the bar/QR code using your devices camera"}
          >
            <Button
              icon={<ScanOutlined />}
              onMouseDown={(e) => {
                e.preventDefault();
                searchRef.current.focus()
                setSearchText("");
                setScannerOpen(true);
                setDropdownOpen(false);
              }}
              style={{
                borderWidth: 1,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                borderColor: "black",
                borderStyle: "solid",
                borderLeftWidth: 0,
              }}
            />
          </Tooltip>
        </Input.Group>
      </Dropdown>
      <BarcodeScannerContextProvider>
        <BarcodeScanner
          open={scannerOpen}
          setOpen={(open) => setScannerOpen(open)}
          setBarcode={(text) => {
            setSearchText(text);
            setDropdownOpen(true);
            searchRef.current.focus()
          }}
        />
      </BarcodeScannerContextProvider>
    </>
  );
}
