import { Loading3QuartersOutlined } from "@ant-design/icons";
import cx from "classnames";

import "./Spinner.scss";

export default function Spinner({ className }) {
  return (
    <div className={cx("spinner-container", className)}>
      <div className="spinner">
        <Loading3QuartersOutlined />
      </div>
    </div>
  );
}
