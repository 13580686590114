import { Row, Col } from "antd";
import { useState, useEffect } from "react";

import fabricHierarchy from "helpers/fabricHierarchy.json";
import ItemDrawer from "components/ItemDrawer/ItemDrawer";
import FabricElementsGroup from "./FabricElementsGroup/FabricElementsGroup";
import {
  convertGlobalHierarchyToDictionary,
  enrichFabricHierarchy,
} from "helpers/hierarchyHelpers";

import "./FabricGroups.scss";

export default function FabricGroups({
  parentType,
  parents,
  fabricAssets,
}) {
  const [visibleCategories, setVisibleCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSectionForEdit, setSelectedSectionForEdit] = useState();
  const [selectedAsset, setSelectedAsset] = useState();

  let globalHierarchyDictionary = convertGlobalHierarchyToDictionary({
    parent: { children: window.hierarchy },
    result: {},
  });

  enrichFabricHierarchy({
    parent: { children: fabricHierarchy },
    globalHierarchyDictionary,
  });

  useEffect(() => {
    setVisibleCategories(
      fabricHierarchy.filter(
        (item) => item.presence && item.presence[parentType]
      )
    );
  }, []);

  function onAdd(category) {
    setSelectedCategory(category);
  }

  function onEditSection(section) {
    setSelectedSectionForEdit(section);
  }

  function onEditAsset(category, fabricAsset) {
    setSelectedCategory(category);
    setSelectedAsset(fabricAsset);
  }

  if (!visibleCategories || !fabricAssets) {
    return null;
  }

  return (
    <>
      <Row gutter={24} type="flex">
        {visibleCategories.map((category, i) => {
          return (
            <Col key={i} xs={24} sm={24} md={24} lg={12} xl={12}>
              <FabricElementsGroup
                category={category}
                onAdd={onAdd}
                onEditSection={onEditSection}
                onEditAsset={onEditAsset}
                fabricAssets={fabricAssets?.filter((asset) =>
                  asset.facets.selectorId?.startsWith(`${category.selectorId}.`)
                )}
              />
            </Col>
          );
        })}
      </Row>

      {selectedCategory && (
        <ItemDrawer
          onClose={() => {
            setSelectedAsset();
            setSelectedCategory();
          }}
          visible={true}
          item={selectedAsset}
          parents={parents}
          itemType="asset"
          hierarchy={selectedCategory.children}
          isFabric
        />
      )}
      {selectedSectionForEdit && (
        <ItemDrawer
          onClose={() => {
            setSelectedSectionForEdit();
          }}
          visible={true}
          item={selectedSectionForEdit.asset}
          parents={parents}
          itemType="asset"
          hierarchy={[selectedSectionForEdit]}
          isFabric
          isFabricSection
        />
      )}
    </>
  );
}
