import { useState, useEffect } from "react";
import {
  MAX_WIDTH_SIDEBAR_COLLAPSE_ON_DESKTOP,
  MAX_WIDTH_SIDEBAR_COLLAPSE_ON_TABLET,
} from "config";

const useScreenOrientationChecker = () => {
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const updateWindowOrientation = (event) => {
      const DPR = window.devicePixelRatio;
      const windowWidth = window.innerWidth;
      (windowWidth <= MAX_WIDTH_SIDEBAR_COLLAPSE_ON_TABLET && DPR == 2) ||
      (windowWidth <= MAX_WIDTH_SIDEBAR_COLLAPSE_ON_DESKTOP && DPR == 1)
        ? setIsPortrait(true)
        : setIsPortrait(false);
    };

    matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`).addEventListener(
      "change",
      updateWindowOrientation,
      { once: true }
    );

    window.addEventListener("resize", updateWindowOrientation);
    return () => {
      window.removeEventListener("resize", updateWindowOrientation);
    };
  });

  return isPortrait;
};

export default useScreenOrientationChecker;
