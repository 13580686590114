import React, { useContext } from "react"
import { Modal, Button } from "antd"
import Scanner from "./Scanner"
import { barcodeScannerContext } from "components/BarcodeScannerContextProvider"

const BarcodeScanner = ({ open, setBarcode, setOpen }) => {
  const scannerContext = useContext(barcodeScannerContext)
  const { setIsStreaming } = scannerContext

  const handleCloseModal = () => {
    setIsStreaming(false)
    setOpen(false)
  }

  return (
    <>
      <Modal visible={open} onCancel={() => handleCloseModal()} title='Barcode Scanner' footer={[
          <Button onClick={() => handleCloseModal()}>
            Close
          </Button>
        ]} destroyOnClose={true}>
        <Scanner open={open} setBarcode={setBarcode} setOpen={setOpen} />
      </Modal>
    </>
  )
}

export default BarcodeScanner
