import { useState, useEffect, useMemo } from "react";
import { Typography, Menu, Modal, Descriptions } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {DataStore} from '@reams/elias-store';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Space, Asset } from "models";
import { Helmet } from "react-helmet";
import {
  customFacetList,
  spaceDefinition,
} from "helpers/drawerData";
import ItemDrawer from "components/ItemDrawer/ItemDrawer";
import FilterHeader from "components/FilterHeader/FilterHeader";
import {
  AssetIcon,
  StarIcon,
  DeleteIcon,
  StarFilledIcon,
  EditIcon,
} from "components/Icons";
import { renderUpdatedAt } from "helpers/generalHelpers";
import { Status } from "helpers/statusHelpers";
import TableColumnTitle from "components/TableColumnTitle/TableColumnTitle";
import Spinner from "components/Spinner/Spinner";
import ItemTableGrid from "components/ItemTableGrid/ItemTableGrid";
import { saveFavouriteSpaces } from "helpers/favouriteSpaceHelpers";
import { Floor } from "models";
import FabricGroups from "components/FabricGroups/FabricGroups";
import Authorize from "components/Authorize/Authorize";
import { HiddenActionsInDropdown } from "components/ButtonPair/ActionsDropdown";

import "../../styles/customLinks.scss";
import { buildFilterableTreeByFacet } from "helpers/filters/assetFilterHelpers";
import { fetchFilterDescription, filterItem } from "helpers/filters/itemFilterHelpers";
import { spaceAssets, floorFabricAssets } from "helpers/asset/assetHelpers";
import { useEntityData } from "EntityDataContext/EntityDataContext";

export default function FloorDetailsPage({
  pageHeader,
  addPageToHistory,
}) {
  const { favouriteSpacesState, facilities, assets, spaces, floors } = useEntityData()

  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("descend");
  const [favouriteSpaces, setFavouriteSpaces] = favouriteSpacesState;
  const [isCreatingNewSpace, setIsCreatingNewSpace] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState();
  const { floorId } = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState([])
  

  const floor = floors.items?.[floorId]

  const facility = useMemo(() => {
    if (!floor) return undefined

    const facility = facilities?.[floor.facilityId]
    return facility
  }, [floor, facilities])


  const setFiltersNode = (val) => {
    let desc = val;
    desc = fetchFilterDescription({filter: desc, facets: facets});
    const x = { id: val, description: desc };

    setFilters((old) => {
      if (old.find((o) => o.id === x.id)) {
        return [...old];
      }
      return [...old, x];
    });
  };

  const onFiltersChange = (node) => {
    // from select fields
    if (Array.isArray(node)) {
      node.map((node) => setFiltersNode(node));
      return;
    }

    // from option fields
    setFiltersNode(node.value);
  };

  const onFilterClose = (filter) => {
    setFilters((old) => {
      return old.filter((f) => f.id !== filter);
    });
  };

  const facets = useMemo(() => {
    return customFacetList
    .filter((facet) => spaceDefinition.facets?.includes(facet.facetId))
  }, [customFacetList, spaceDefinition])
  
  const floorSpaces = spaces.groupByFloor[floor?.id]?.map((spaceId) => spaces.items[spaceId]) || []
  
  const enhancedFloorSpaces = useMemo( () => {
    let tmpspaces = floorSpaces
    .map((space) => {

      return {
        ...space,
        assetCount: spaceAssets({
          assets, 
          space
        }).length,
      };
    }); 

    return filterItem(tmpspaces, filters, facets)
  }, [spaces, filters])

  const fabricAssets = floorFabricAssets(assets, floor)

  useEffect(() => {
    if (!floor) {
      return;
    }

    addPageToHistory(floor.name);
  }, [floor]);

  const actions = (space) => {
    return [
      {
        label: "Assets",
        key: "assets",
        link: `/space/${space.id}`,
        icon: <AssetIcon />,
      },
      {
        label: "Edit",
        key: "edit",
        onClick: () => {
          setSelectedSpace(space);
        },
        icon: <EditIcon />,
      },
      {
        label: "Favourite",
        key: "favourite",
        onClick: () => {
          saveFavouriteSpaces(setFavouriteSpaces, favouriteSpaces, space.id);
        },
        icon: favouriteSpaces.includes(space.id) ? (
          <StarFilledIcon />
        ) : (
          <StarIcon />
        ),
      },
      {
        label: "Delete",
        key: "delete",
        onClick: () => confirmDeleteSpace(space),
        icon: <DeleteIcon />,
      },
    ];
  };

  async function confirmDeleteSpace(space) {
    Modal.confirm({
      title: "Are you sure to delete this space?",
      icon: <QuestionCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        const itemToDelete = await DataStore.query(Space, space.id);
        await DataStore.delete(itemToDelete);

        const assetsToDelete = await DataStore.query(Asset, (x) =>
          x.spaceId("eq", space.id)
        );
        for (let i = 0; i < assetsToDelete.length; i++) {
          await DataStore.delete(assetsToDelete[i]);
          console.log(`Deleted asset: ${assetsToDelete[i.name]}`);
        }
      },
    });
  }

  async function confirmDeleteFloor(floor) {
    Modal.confirm({
      title: "Are you sure to delete this floor?",
      icon: <QuestionCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        const itemToDelete = await DataStore.query(Floor, floor.id);
        await DataStore.delete(itemToDelete);

        const spacesToDelete = await DataStore.query(Space, (x) =>
          x.floorId("eq", floor.id)
        );
        const assetsToDelete = await DataStore.query(Asset, (x) =>
          x.floorId("eq", floor.id)
        );
        for (let i = 0; i < spacesToDelete.length; i++) {
          await DataStore.delete(spacesToDelete[i]);
          console.log(`Deleted asset: ${spacesToDelete[i].name}`);
        }
        for (let i = 0; i < assetsToDelete.length; i++) {
          await DataStore.delete(assetsToDelete[i]);
          console.log(`Deleted asset: ${assetsToDelete[i]}.name`);
        }
        navigate(`/facility/${floor.facilityId}`);
      },
    });
  }

  const columnTitles = {
    localName: "Local name",
    assetCount: "Assets",
    updatedAt: "Last Edited",
  };

  const tableColumnTitleProps = {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    columnTitles,
  };

  const SPACE_COLUMNS = [
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="name" />,
      dataIndex: "name",
      key: "Name",
      fixed: "left",
      render: (name, space) => (
        <Link
          className="table-name-link"
          to={`/space/${space.id}`}
        >
          {name}
        </Link>
      ),
      sorter: (a, b) =>
        a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1,
      sortOrder: sortBy === "name" ? sortOrder : false,
    },
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="status" />,
      dataIndex: "status",
      key: "Status",
      render: (status) => <Status status={status} />,
      sorter: (a, b) => (a.status < b.status ? -1 : 1),
      sortOrder: sortBy === "status" ? sortOrder : false,
    },
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="type" />,
      dataIndex: "type",
      key: "Type",
      sorter: (a, b) => (a.type < b.type ? -1 : 1),
      sortOrder: sortBy === "type" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="assetCount" />
      ),
      key: "Assets",
      dataIndex: "assetCount",
      sorter: (a, b) => (a.assetCount < b.assetCount ? -1 : 1),
      sortOrder: sortBy === "assetCount" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="localName" />
      ),
      dataIndex: "localName",
      key: "Local name",
      sorter: (a, b) =>
        a.localName.toLowerCase() < b.localName.toLowerCase() ? -1 : 1,
      sortOrder: sortBy === "localName" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="department" />
      ),
      dataIndex: "department",
      key: "Department",
      sorter: (a, b) =>
        a.department?.toLowerCase() < b.department?.toLowerCase() ? -1 : 1,
      sortOrder: sortBy === "department" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="updatedAt" />
      ),
      key: "Last Edited",
      dataIndex: "updatedAt",
      render: (_, item) => {
        return renderUpdatedAt(item);
      },
      sorter: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
      sortOrder: sortBy === "updatedAt" ? sortOrder : false,
    },
    {
      title: "",
      fixed: "right",
      render: (space) => {
        return (
          <div className="row-actions">
            <HiddenActionsInDropdown actions={actions(space)} />
          </div>
        );
      },
    },
  ];

  const filterTreeData = useMemo(() => {
    // search through facets.json and find appropriate facets
    const filterableFacets = facets.filter((i) => i.filterable);
    return filterableFacets.map((i) => {
      return buildFilterableTreeByFacet(floorSpaces, i, onFiltersChange);
    })
  }, [floorSpaces]);

  if (!facility || !floor) {
    return <Spinner />;
  }

  const render = (space) => (
    <Descriptions
      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      size={"small"}
    >
      <Descriptions.Item label="Status">
        <Status status={space.status} />
      </Descriptions.Item>
      <Descriptions.Item label="Space Type">{space.type}</Descriptions.Item>
      <Descriptions.Item label="Assets">{space.assetCount}</Descriptions.Item>
      <Descriptions.Item label="Last Edited">
        {renderUpdatedAt(space)}
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <div className="floor-details-page">
      <Helmet title={floor?.name || "Floor details"} />
      {pageHeader}
      <div className="content-header">
        <Typography.Title className="subtitle" level={1}>
          Floor details - {floor?.name}
        </Typography.Title>
        <FilterHeader
          filterTreeData={filterTreeData}
          filterOnChange={(change) => {
            onFiltersChange(change);
          }}
          filterOnCloseTag={(closeTag) => onFilterClose(closeTag)}
          filterValue={filters}
          buttonPair={{
            label: "Add Space",
            onClick: () => setIsCreatingNewSpace(true),
            options: (
              <Menu>
                <Menu.Item
                  key="edit-floor"
                  onClick={() => setSelectedFloor(true)}
                >
                  Edit floor
                </Menu.Item>
                <Menu.Item key="complete-floor">Complete floor</Menu.Item>
                <Menu.Item
                  key="delete-floor"
                  onClick={() => confirmDeleteFloor(floor)}
                >
                  Delete floor
                </Menu.Item>
              </Menu>
            ),
          }}
          sortByOptions={SPACE_COLUMNS}
          {...tableColumnTitleProps}
        />
      </div>
      <Authorize allowedRoles={["admin", "fabric"]}>
        <FabricGroups
          parentType="floor"
          parents={{ facility, floor }}
          fabricAssets={fabricAssets}
        />
      </Authorize>
      <ItemTableGrid
        title="Spaces"
        items={enhancedFloorSpaces}
        columns={SPACE_COLUMNS}
        onClick={(a) => setSelectedSpace(a)}
        actions={actions}
        render={render}
      />

      {(selectedSpace || isCreatingNewSpace) && (
        <ItemDrawer
          onClose={() => {
            setIsCreatingNewSpace(false);
            setSelectedSpace(false);
          }}
          visible={selectedSpace || isCreatingNewSpace}
          item={selectedSpace}
          parents={{ floor, facility }}
          itemType="space"
        />
      )}
      {floor && selectedFloor && (
        <ItemDrawer
          onClose={() => setSelectedFloor(false)}
          visible={floor}
          item={floor}
          parents={{ facility }}
          itemType="floor"
        />
      )}
    </div>
  );
}
