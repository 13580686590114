import React, { useEffect, useState } from "react";
import { Row, Col, Card, Empty, Skeleton, Pagination } from "antd";
import { fetchLocalImages } from "helpers/drawerHelpers";
import { DEFAULT_NUMBER_OF_ITEMS_IN_VIEW } from "config";
import { visibleActions } from "components/ButtonPair/ActionsDropdown";
import "./GridView.scss";

const GridCard = ({ record, render, actions, displayCover, title }) => {
  const [images, setImages] = useState([]);
  const getImages = async () => {
    const x = await fetchLocalImages({
      item: record,
      facilityId: record.facilityId || record.id,
    });
    setImages(x);
  };

  useEffect(() => {
    if (!record) {
      return;
    }
    getImages();
  }, [record]);

  return (
    <Col
      data-test-selector="gridlist_column"
      xs={24}
      sm={24}
      md={12}
      lg={8}
      xl={8}
      key={record.id}
    >
      <Card
        className="grid-list-card"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
        cover={
          displayCover && (
            <div style={{ height: 200, width: "100%", overflow: "hidden", padding: "1px"  }}>
              {!images[0] && (
                <Skeleton.Image
                  style={{
                    height: 200,
                    width: "100%",
                    borderRadius: "16px 16px 0 0",
                  }}
                  className="grid-skeleton"
                />
              )}
              {images[0] && (
                <img
                  style={{ borderRadius: "16px 16px 0 0" }}
                  width={"100%"}
                  src={images[0].dataUri}
                />
              )}
            </div>
          )
        }
        actions={visibleActions(actions( record ))}
      >
        <Card.Meta
          title={title ? title(record) : record.name}
          description={render(record)}
        />
      </Card>
    </Col>
  );
};

const GridView = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_NUMBER_OF_ITEMS_IN_VIEW);
  const records = props.records.slice(
    (page - 1) * pageSize,
    (page - 1) * pageSize + pageSize
  );

  return (
    <>
      <Row gutter={[16, 16]} type="flex" style={{ display: "flex" }}>
        {records.length > 0 ? (
          records.map((record) => {
            return <GridCard {...props} record={record} key={record.id} />;
          })
        ) : (
          <Col xs={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        )}
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 32,
        }}
      >
        <Pagination
          current={page}
          defaultCurrent={page}
          showSizeChanger={true}
          pageSizeOptions={[10, 20, 30, 50]}
          total={props.records.length}
          onChange={(pages, pageSize) => {
            setPage(pages);
            setPageSize(pageSize);
          }}
        />
      </div>
    </>
  );
};

export default GridView;
