import React, { useRef, useState } from "react";
import { readAndCompressImage } from "browser-image-resizer";
import { DeleteOutlined } from "@ant-design/icons";
import { Form, Select, Button, Checkbox, Typography, Alert } from "antd";
import md5 from "md5";
import {
  MAX_IMAGE_UPLOAD_WIDTH,
  MAX_IMAGE_UPLOAD_HEIGHT,
  IMAGE_UPLOAD_QUALITY,
} from "config";
import { Carousel } from "react-responsive-carousel";
import { RESTRICTED_IMG_OPTIONS } from "constants";
import CaptureImage from "./CaptureImage/CaptureImage";
import Authorize from "components/Authorize/Authorize";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./DrawerPhotoSection.scss";
import { v4 as uuidv4 } from "uuid";
import { imageValidation } from "helpers/formValidationHelpers";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const { Option } = Select;

function DrawerPhotoSection({
  form,
  forceUpdate,
  setImages,
  images,
  itemType,
}) {
  const { userData, tenantData } = useEntityData()
  const [deletedMessage, setDeletedMessage] = useState(false);
  const [deleteTimeout, setDeleteTimeout] = useState();
  const oldDescription = form.getFieldValue("restricted_image_reason");
  const isImageRestricted = form.getFieldValue("checkbox_imageRestricted");

  if (
    !RESTRICTED_IMG_OPTIONS.includes(oldDescription) &&
    oldDescription !== undefined
  ) {
    RESTRICTED_IMG_OPTIONS.unshift(oldDescription);
  }

  const photoInputRef = useRef();
  const photoCarouselRef = useRef();

  function onCameraButtonClick() {
    photoInputRef.current.click();
  }

  async function updateFormFields(reason) {
    const conditionValue = await form.getFieldValue("facets_condition");
    form.setFieldsValue({
      facets_condition: "",
      restricted_image_reason: reason,
    });
    form.setFieldsValue({ facets_condition: conditionValue }); // add real value

    const installDateValue = await form.getFieldValue("facets_install-date");
    form.setFieldsValue({
      "facets_install-date": "",
      restricted_image_reason: reason,
    });
    form.setFieldsValue({ "facets_install-date": installDateValue });

    forceUpdate();
  }

  async function onPhotoInputChange(e) {
    if (!e.target.files) {
      return;
    }

    let newFormImages = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const originalImage = e.target.files[i];
      let resizedImage = await readAndCompressImage(originalImage, {
        quality: IMAGE_UPLOAD_QUALITY,
        maxWidth: MAX_IMAGE_UPLOAD_WIDTH,
        maxHeight: MAX_IMAGE_UPLOAD_HEIGHT,
        mimeType: originalImage.type,
      });
      const imageDataUri = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            // convert image file to base64 string
            resolve(reader.result);
          },
          false
        );

        if (resizedImage) {
          reader.readAsDataURL(resizedImage);
        } else {
          reject();
        }
      });

      newFormImages.push({
        dataUri: imageDataUri,
        hash: md5(`${uuidv4()}${new Date().toTimeString()}`),
        takenBy: JSON.stringify({
          givenName: userData?.givenName || "",
          familyName: userData?.familyName || "",
          email: userData?.email || "",
          date: new Date()
        })
      });
    }
    setImages([...(images || []), ...newFormImages]);
    updateFormFields();
    forceUpdate();
  }

  function deleteImage(imageIndex) {
    photoCarouselRef.current.moveTo(0, false);
    setImages(images.filter((_, index) => index !== imageIndex));
    setDeletedMessage(true);
    clearTimeout(deleteTimeout);
    const timeout = setTimeout(() => {
      setDeletedMessage(false);
    }, 5000);
    setDeleteTimeout(timeout);
  }

  function onCapture(image) {
    setImages([
      ...(images || []),
      {
        dataUri: image,
        hash: md5(image),
        takenBy: JSON.stringify({
          givenName: userData?.givenName || "",
          familyName: userData?.familyName || "",
          email: userData?.email || "",
          date: new Date()
        })
      },
    ]);
    updateFormFields();
    forceUpdate();
  }

  return (
    <div className="drawer-photo-section">
      <div className="photos-section">
        <CaptureImage onCapture={onCapture} />
        <Authorize allowedRoles={["admin", "supplier"]}>
          <input
            type="file"
            className="photo-input"
            accept="image/*"
            ref={photoInputRef}
            onChange={onPhotoInputChange}
            multiple
          />
          <Typography.Text
            className="upload-button"
            onClick={() => onCameraButtonClick()}
          >
            Upload
          </Typography.Text>
        </Authorize>
      </div>
      <Carousel
        ref={photoCarouselRef}
        className="photo-list-container"
        dynamicHeight
        emulateTouch
        showStatus={false}
        showThumbs={false}
        showIndicators={true}
      >
        {images.map((image, index) => {
          return (
            <div key={index} className="image-item">
              <img src={image.dataUri} />
              <Button
                icon={<DeleteOutlined />}
                onClick={() => deleteImage(index)}
              >
                Delete
              </Button>
            </div>
          );
        })}
      </Carousel>

      {deletedMessage && (
        <Alert
          style={{ backgroundColor: "white", marginTop: 20 }}
          message="Image deleted"
          type="info"
          showIcon
          closable
          onClose={() => setDeletedMessage(false)}
        />
      )}

      {itemType === "asset" && (
        <div style={{ marginTop: "1rem" }}>
          {
            <Form.Item
              style={{ marginBottom: 0 }}
              key="checkbox_imageRestricted"
              name="checkbox_imageRestricted"
              shouldUpdate
              rules={[{
                validator: async (_,value, callback) => {
                  const assetType = form.getFieldValue("assetType"); 
                  const reason = form.getFieldValue("restricted_image_reason");
                  if (value && !reason){
                    throw new Error("Select an image note from the dropdown below.")
                  }
                  imageValidation({ form, images, tenantImageValidation: tenantData?.config?.imageValidation || 'DEFAULT', virtual: assetType.virtual })
                }
              }
            ]}
            >
              <Checkbox
                defaultChecked={isImageRestricted}
                onChange={(e) => {
                  form.setFieldsValue({
                    checkbox_imageRestricted: e.target.checked,
                  });
                  if (!e.target.checked) {
                    // remove option on uncheck
                    form.setFieldsValue({ restricted_image_reason: undefined });
                  }
                  forceUpdate();
                }}
              >
                Image can not be added
              </Checkbox>
            </Form.Item>
          }
          <Form.Item
            className="form-element"
            key="restricted_image_reason"
            name="restricted_image_reason"
            label="Image note"
            colon={false}
            hidden={!isImageRestricted}
            shouldUpdate
            initialValue={oldDescription}
          >
            <Select
              placeholder="Select a option from the dropdown"
              allowClear
              onChange={(reason) => {
                updateFormFields(reason);
              }}
            >
              {RESTRICTED_IMG_OPTIONS.map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      )}
    </div>
  );
}

export default React.memo(DrawerPhotoSection);
