import { useEffect, useState } from "react";
import { Select } from "antd";
import { get as localDbGet, set as localDbSet } from "idb-keyval";
import { noPrefixOptions } from "helpers/DisplayFieldForFacet";
import BarcodeInput from "./BarcodeInput";

export default function FormBarcodeHelper({
  form,
  setFocus,
  onBlurTimeout,
  barcodePrefixes,
  forceUpdate,
}) {
  const arePrefixOptions = noPrefixOptions(barcodePrefixes);
  const [prefix, setPrefix] = useState();

  const addDefaultPrefix = async (prefix) => {
    if (!arePrefixOptions) {
      await localDbSet("default-prefix", prefix);
    }
    return prefix;
  };

  const getDefaultPrefix = async () => {
    const defPrefix = await localDbGet("default-prefix");

    const dp = defPrefix
      ? defPrefix
      : await addDefaultPrefix(barcodePrefixes[0]);
    form.setFieldsValue({ barcodePrefix: dp });
    setPrefix(dp);
  };

  useEffect(() => {
    getDefaultPrefix();
  }, []);

  return (
    <div className="barcode-prefix-group">
      <Select
        onChange={(prefixOption) => {
          form.setFieldsValue({ barcodePrefix: prefixOption });
          setPrefix(prefixOption);
          addDefaultPrefix(prefixOption);
          form.validateFields(["facets_barcode"]);

          if (prefixOption === undefined) {
            form.validateFields(["facets_barcode"]);
          }
          forceUpdate();
        }}
        value={prefix}
      >
        {barcodePrefixes?.map((prefix, index) => (
          <Select.Option value={prefix} key={index} />
        ))}
      </Select>
      <BarcodeInput
        prefix={prefix}
        setFocus={setFocus}
        onBlurTimeout={onBlurTimeout}
        inputKey={"facets_barcode"}
        form={form}
        forceUpdate={forceUpdate}
      />
    </div>
  );
}
