import { Input, Select, InputNumber, DatePicker, Checkbox } from "antd";
import { manufacturers } from "../manufacturers";
import { ChevronDownIcon } from "components/Icons";
import { searchAndPrependList } from "helpers/DisplayFieldForFacet";
import FormBarcodeHelper from "./formBarcodeHelper";
import { noPrefixOptions } from "helpers/DisplayFieldForFacet";
import BarcodeInput from "./BarcodeInput";
import FormTagsHelper from "./formTagsHelper";
const ratingsDescription = require("../../core/conditionRatingDescription.json");

function formControl({
  type,
  item,
  key,
  step,
  options,
  forceUpdate,
  barcodePrefixes,
  form,
  setFocus,
  onBlurTimeout,
  facetDefinition,
  manufacturerOptions,
  setManufacturerOptions,
  isMandatory,
  isDisabled,
  hasLabel,
  valuePropName,
  label,
  value,
  itemDefinition,
  conditionTags,
}) {
  let formControl = null;
  const arePrefixOptions = noPrefixOptions(barcodePrefixes);
  const ascendingSort = (arr) => arr.sort((a, b) => (a.code < b.code ? -1 : 1));

  switch (type) {
    case "AutoBox":
      formControl = (
        <Select
          suffixIcon={<ChevronDownIcon />}
          showSearch
          allowClear
          defaultActiveFirstOption={false}
          placeholder={facetDefinition.label}
          getPopupContainer={(trigger) => trigger.parentNode}
          onSearch={(val) => {
            searchAndPrependList(setManufacturerOptions, manufacturers, val);
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => onBlurTimeout()}
        >
          {manufacturerOptions?.map((option) => (
            <Select.Option key={option.code} value={option.code}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      );
      break;
    case "ComboBox":
      formControl = (
        <Select
          suffixIcon={<ChevronDownIcon />}
          showSearch={options.length > 10 ? true : false}
          allowClear={facetDefinition.allowClear === undefined ? true : facetDefinition.allowClear}
          placeholder={facetDefinition.label}
          getPopupContainer={(trigger) => trigger.parentNode}
          filterOption={(inputValue, option) => {
            return (
              option.children
                ?.toLowerCase()
                .includes(inputValue.toLowerCase()) ||
              option.value.toLowerCase().includes(inputValue.toLowerCase())
            );
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => onBlurTimeout()}
          onChange={(reason) => {
            if (key === "facets_condition") {
              form.setFieldsValue({ facets_condition: reason });
              form.setFieldsValue({
                facets_conditionTags: [],
              });
              forceUpdate();
            }
            if (key === "status") {
              forceUpdate();
            }
          }}
        >
          {(key === "facets_refrigerant-type"
            ? ascendingSort(options)
            : options
          )?.map((option) => {
            const displayValue = ratingsDescription[option.code]?.label;
            return (
              <Select.Option key={option.code} value={option.code}>
                {key === "facets_condition"
                  ? `${option.code}: ${displayValue}`
                  : (option.label || option.description)}
              </Select.Option>
            );
          })}
        </Select>
      );
      break;
    case "ComboBoxYear":
      formControl = (
        <DatePicker
          picker="year"
          getPopupContainer={(trigger) => trigger.parentNode}
          disabledDate={(currentDate) => {
            const startDate = new Date("01 January 1000 00:00:00 UTC"); //anydate in 1975 works
            const endDate = new Date();

            return currentDate < startDate || currentDate > endDate;
          }}
          onChange={() => {
            if (key === "facets_install-date") {
              forceUpdate();
            }
          }}
        />
      );
      break;
    case "TextBox":
      formControl = (
        <Input
          placeholder={facetDefinition.label}
          disabled={facetDefinition.disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => onBlurTimeout()}
        />
      );
      break;
    case "BarcodeBox":
      formControl = (
        <BarcodeInput
          setFocus={setFocus}
          onBlurTimeout={onBlurTimeout}
          inputKey={key}
          form={form}
          forceUpdate={forceUpdate}
        />
      );
      break;

    case "BarcodePreFixBox":
      formControl =
        !item && !arePrefixOptions ? (
          <FormBarcodeHelper
            form={form}
            setFocus={setFocus}
            onBlurTimeout={onBlurTimeout}
            barcodePrefixes={barcodePrefixes}
            forceUpdate={forceUpdate}
          />
        ) : (
          <BarcodeInput
            setFocus={setFocus}
            onBlurTimeout={onBlurTimeout}
            inputKey={key}
            form={form}
            forceUpdate={forceUpdate}
          />
        );
      break;

    case "EditQuantityBox":
    case "QuantityBox":
      isMandatory = false;
      isDisabled = !itemDefinition.allowMultiple && !itemDefinition.virtual;
      formControl = (
        <InputNumber
          value={isDisabled ? 1 : value}
          disabled={isDisabled}
          min={1}
          onFocus={() => setFocus(true)}
          onBlur={() => onBlurTimeout()}
        />
      );
      break;
    case "Numeric":
      formControl = (
        <InputNumber
          placeholder={facetDefinition.label}
          min={0}
          step={step}
          onFocus={() => setFocus(true)}
          onBlur={() => onBlurTimeout()}
        />
      );
      break;
    case "MultiLineTextBox":
      formControl =
        key === "notes_condition" ? (
          <FormTagsHelper
            setFocus={setFocus}
            onBlurTimeout={onBlurTimeout}
            forceUpdate={forceUpdate}
            form={form}
            item={item}
            tags={conditionTags}
          />
        ) : (
          <Input.TextArea
            onFocus={() => setFocus(true)}
            onBlur={() => onBlurTimeout()}
          />
        );
      break;
    case "CheckBox":
      hasLabel = false;
      valuePropName = "checked";
      formControl = <Checkbox>{label}</Checkbox>;
    default:
      break;
  }

  return formControl;
}

export default formControl;
