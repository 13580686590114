
import { Tooltip } from "antd"
import Icon, { QuestionCircleOutlined }  from '@ant-design/icons'
import "./style.scss";

const HelpIcon = ({text}) => {


    return(
        <Tooltip title={text} overlayClassName={'tooltip'} placement="left">
          <QuestionCircleOutlined />
        </Tooltip>)

}

export default HelpIcon