import { DataStore } from '@reams/elias-store'
import { Region } from "models"


function handleIncomingRegions(items, setRegions) {
  const regionsIdArr = [];

  const regionsObj = items.reduce((acc, region) => {
    regionsIdArr.push(region.id);
    acc[region.id] = region;
    return acc;
  }, {});

  setRegions({ ...regionsObj, ids: regionsIdArr });
}

export const getDataStoreRegions = ({ setRegions, tenantId }) => (
  DataStore.observeQuery(Region, (x) =>
    x.tenantId("eq", tenantId)
  ).subscribe(async({items}) => {
    if (items) {
      handleIncomingRegions(items, setRegions)
    }
  })
)