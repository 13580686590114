import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import cx from "classnames";
import SidebarAssetDraw from "./AppSidebarDrawer";
import AppSidebarContent from "./AppSidebarContent";
import useScreenOrientationChecker from "./ScreenOrientationCheck";

import "./AppSidebar.scss";
import { useEntityData } from "EntityDataContext/EntityDataContext";
import { getLocationTreeIds } from "helpers/locationTreeHelpers";

export default function AppSidebar({
  isCollapsed,
  setIsCollapsed,
}) {
  const { facilities, floors, spaces } = useEntityData()

  const { facilityId, floorId, spaceId } = useParams();

  const {space, floor, facility} = useMemo(() => getLocationTreeIds((spaceId || facilityId || floorId), facilities, floors, spaces), [floorId, spaceId, facilityId, spaces, floors, facilities])

  const isPortrait = useScreenOrientationChecker();

  useEffect(() => {
    setIsCollapsed(isPortrait);
  }, [isPortrait]);

  const sidebarContentProps = {
    facility: facility,
    floor: floor,
    spaceId: spaceId,
    space: space,
    setIsCollapsed: setIsCollapsed,
    isCollapsed: isCollapsed,
    isPortrait: isPortrait,
  };

  return (
    <div
      className={cx("app-sidebar", {
        "app-sidebar-collapsed": !isPortrait && isCollapsed,
      })}
    >
      {!isPortrait && (
        <AppSidebarContent
        {...sidebarContentProps}
        />
      )}
      {isPortrait && (
        <SidebarAssetDraw
          notCollapsed={!isCollapsed}
          onClose={() => {
            setIsCollapsed(true);
          }}
          sidebarContentProps={sidebarContentProps}
        />
      )}
    </div>
  );
}
