import moment from "moment";
import { makeEoL } from "helpers/formHelpers";
import { EOL_YEARS_LIMIT, CONDITION_A_LIMIT_YEARS } from "config";

export function imageValidation({ form, images, tenantImageValidation, virtual = false }) {
  const imageBypassReason = form.getFieldValue("restricted_image_reason");
  if (imageBypassReason) return;
  if (tenantImageValidation === "DEFAULT") {
    return;
  }

  if (tenantImageValidation === "CORE" && !virtual && images.length === 0 ) {
    throw new Error("Core Assets require Images, Please add an image!");
  }
  
  if (tenantImageValidation === "VIRTUAL" && virtual && images.length === 0 ) {
    throw new Error("Virtual Assets require Images, please add an image!");
  }
  if (tenantImageValidation === "ALL" && images.length === 0 ) {
    throw new Error("Please add an image!");
  }
}

export async function conditionValidation({ form, images, fieldValue }) {
  const imageBypassReason = form.getFieldValue("restricted_image_reason");

  if (images.length !== 0 || imageBypassReason) return;

  if (
    !["A", "B"].includes(fieldValue) &&
    (images.length === 0 || !imageBypassReason) &&
    fieldValue
  ) {
    throw new Error("Value is C or below. Please add an image!");
  }
}

export async function installDateValidation({
  form,
  images,
  fieldValue,
  assetDefinition,
}) {
  const imageBypassReason = form.getFieldValue("restricted_image_reason");
  const checkEoLDate = makeEoL(
    form.getFieldValue("facets_install-date"),
    assetDefinition?.lifecycle
  );
  const selectedConditionIsA = form.getFieldValue("facets_condition") === "A";
  const checkEoLYears =
    new Date(checkEoLDate).getFullYear() - new Date().getFullYear();
  const currentYear = new Date().getFullYear();
  const olderThanTwoYears =
    currentYear - moment(fieldValue).get("year") > CONDITION_A_LIMIT_YEARS
      ? true
      : false;

  if (olderThanTwoYears && selectedConditionIsA) {
    throw new Error(
      `${CONDITION_A_LIMIT_YEARS}+ years old asset can't be condition A!`
    );
  }

  if (
    EOL_YEARS_LIMIT <= checkEoLYears ||
    images.length !== 0 ||
    imageBypassReason
  )
    return;
  if (
    EOL_YEARS_LIMIT > checkEoLYears &&
    (images.length === 0 || !imageBypassReason) &&
    fieldValue
  ) {
    throw new Error(`EOL is less than 5y. Please add an image!`);
  }
}

function barcodeMatch(barcodes, initialBarcode, finalBarcode) {
  const existingMatch = barcodes.find((a) => a === finalBarcode);
  const barcodeIsTaken = barcodes
    .filter((asset) => asset !== initialBarcode)
    .find((a) => a === finalBarcode);

  return {
    existingMatch,
    barcodeIsTaken,
  };
}

export function barcodeValidation({
  form,
  item,
  fieldValue,
  barcodes,
  label,
  assetIdCharLimit,
  tenantData,
}) {
  const initialBarcode =
    item?.facets?.barcode !== undefined ? item.facets.barcode : false;
  const prefix = form.getFieldValue("barcodePrefix");
  const suffix = fieldValue?.toString();
  const finalBarcode = prefix ? `${prefix}${suffix}` : fieldValue;
  const emptyField = fieldValue === "";
  const barcodePrefixes = tenantData?.barcodePrefixes;

  const suffixLength =
    parseInt(fieldValue?.length) === parseInt(assetIdCharLimit);
  const { existingMatch, barcodeIsTaken } = barcodeMatch(
    barcodes,
    initialBarcode,
    finalBarcode
  );

  if (!item && fieldValue && !/^[0-9]+$/.test(fieldValue)) {
    throw new Error("Please input only numbers!");
  }

  if (barcodePrefixes?.length > 0 && prefix === undefined && !item)
    throw new Error("Please select a Prefix!");

  if (!fieldValue && emptyField) {
    throw new Error(`Please input the ${label}!`);
  }

  if (
    ((!initialBarcode && existingMatch) ||
      (initialBarcode && barcodeIsTaken)) &&
    fieldValue
  ) {
    throw new Error("Invalid barcode, it has already been used!");
  } else if (!item && !suffixLength && fieldValue) {
    throw new Error(`Your barcode must contain ${assetIdCharLimit} digits!`);
  }
}

export function statusInaccessibleValidation({ form, fieldValue }) {
  if (
    fieldValue === "INACCESSIBLE" &&
    !form.getFieldValue("checkboxes_reminder") &&
    !form.getFieldValue("notes_reminder")
  ) {
    throw new Error("For Inaccessible, please add a Note!");
  }
}

export function conditionDescriptionValidation({ fieldValue,form }) {
  const tags = form.getFieldValue("facets_conditionTags")
  if (!fieldValue && tags?.length === 0) {
    throw new Error("Please insert a Description or select a Tag!");
  }
}
