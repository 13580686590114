import SortButton from "components/SortButton/SortButton";
import { SORT_ORDERS } from "constants";
import { INITIAL_SORT_ORDER_PER_COLUMN } from "config";

export default function TableColumnTitle({
  dataIndex,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  columnTitles,
}) {
  let title =
    columnTitles[dataIndex] ||
    `${dataIndex[0].toUpperCase()}${dataIndex.substring(1).toLowerCase()}`;

  // console.log(`${dataIndex} params =`, params);

  let columnSortOrder = sortBy === dataIndex ? sortOrder : undefined;
  return (
    <div className="column-title">
      {title}
      <SortButton
        sortOrder={columnSortOrder}
        onClick={() => {
          setSortBy(dataIndex);
          if (columnSortOrder) {
            setSortOrder((direction) =>
              direction === SORT_ORDERS.ASCENDING
                ? SORT_ORDERS.DESCENDING
                : SORT_ORDERS.ASCENDING
            );
          } else {
            setSortOrder(INITIAL_SORT_ORDER_PER_COLUMN);
          }
        }}
      />
    </div>
  );
}
