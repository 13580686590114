import React from "react";
import {
  SpaceIcon,
  FacilityIcon,
  FloorIcon,
  AssetIcon,
  SearchIcon,
} from "components/Icons";
import { Col, Tooltip } from "antd";
import { useEntityData } from "EntityDataContext/EntityDataContext";

export const itemDivider = (item1, item2) => {
  if (item1 && item2) return `${item1} | ${item2}`;
  if (item1 || item2) return `${item1 || item2}`;
  return undefined;
};

export const iconChooser = {
  facility: FacilityIcon,
  asset: AssetIcon,
  floor: FloorIcon,
  space: SpaceIcon,
  search: SearchIcon
};

export const EllipsisText = ({ text }) => (
  <Tooltip
    title={text}
    placement="bottom"
    getPopupContainer={(trigger) => trigger}
    mouseEnterDelay={1}
  >
    <span>
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "block",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
    </span>
  </Tooltip>
); 

export const LocationResult = ({ item }) => {
  const { floors, facilities, spaces } = useEntityData() 

  const space = spaces.items[item?.spaceId]
  const floor = floors.items[item?.floorId]
  const facility = facilities[item?.facilityId]

  return (
    <Col span={24}>
      <EllipsisText
        text={itemDivider(
          facility?.name,
          itemDivider(floor?.name, space?.name)
        )}
      />
    </Col>
  );
};
