import { Menu, Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ButtonPair from "components/ButtonPair/ButtonPair";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CopySpaceDrawer from "./CopySpaceDrawer/CopySpaceDrawer";
import { useParams } from "react-router-dom";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const GoButton = ({ selected }) => {
  const { copyAssets, spaces, assets } = useEntityData()
  const navigate = useNavigate();
  const { spaceId } = useParams();

  const [showCopyDrawer, setShowCopyDrawer] = useState(false)
  const [mountCopyDrawer, setMountCopyDrawer] = useState(false)
  const [listCopy, setListCopy] = copyAssets;
  const space = spaces?.items?.[selected?.key] || {}
  const selectedAssets = (assets.assetTree?.[space.facilityId]?.[space.floorId]?.[space.id] || []).map(assetId => {
    return assets.items[assetId]
  })

  useEffect(() => {
    setListCopy([]);
  }, [spaceId]);

  const onClose = () => {
    setShowCopyDrawer(false)

    setTimeout(() => {
      setMountCopyDrawer(false)
    }, 300)
  }

  const redirect = () => {
    if (selected.facilityId && selected.floorId && selected.key) {
      navigate(`/space/${selected.key}`);
    }
    if (selected.facilityId && selected.key && !selected.floorId) {
      navigate(`/floor/${selected.key}`);
    }
    if (selected.key && !selected.facilityId && !selected.floorId) {
      navigate(`/facility/${selected.key}`);
    }
  };

  const options = () => {
    return (
      <Menu>
        {" "}
        <Menu.Item
          onClick={() => {
            setMountCopyDrawer(true)
            setTimeout(() => {
              setShowCopyDrawer(true)
            }, 10);
          }}
          disabled={
            selectedAssets.length === 0 || !spaceId || !(selected.facilityId && selected.floorId && selected.key)
          }
        >
          Copy Assets
        </Menu.Item>
      </Menu>
    );
  };

  async function confirmLeavePage(e) {
    e.stopPropagation();
    Modal.confirm({
      title: "Copy Assets is not complete. Navigating away from this space will clear the remaining copy assets.",
      icon: <QuestionCircleOutlined />,
      okText: "Clear Assets",
      cancelText: "Cancel",
      onOk: async () => {
        setListCopy([]);
        redirect();
      },
    });
  }

  return (
    <>
      <ButtonPair
        label="Go"
        disabled={!selected}
        onClick={listCopy.length > 0 ? confirmLeavePage : redirect}
        options={options}
      />
      {mountCopyDrawer ? 
        <CopySpaceDrawer
        assets={selectedAssets}
        open={showCopyDrawer}
        onClose={(assets) => {
          if (assets) {
            setListCopy(assets);
          }
          onClose()
        }}
        spaceId={spaceId}
        /> : null
      }
    </>
  );
};

export default GoButton;
