import React, { useContext, useEffect, useState } from 'react'
import { configureDataStore, onDataStoreEvent } from "helpers/dataStoreHelpers";
import { AUTO_LOGOUT_TIMER_MILLISECONDS } from 'config';
import useDataStoreSyncStatus from 'helpers/hooks/DataStoreSyncStatus';
import { DataStore } from '@reams/elias-store';
import { Hub, API } from "aws-amplify";
import Cookie from "js-cookie";
import { get as localDbGet, set as localDbSet } from "idb-keyval";
import { logOut } from "helpers/generalHelpers";
import { getCurrentSession } from "helpers/apiHelpers";
import { deviceImages } from "helpers/deviceImages";
import Facets from "helpers/facets.json";
import { getDataStoreAssets, initialAssetState } from './assetsHandler';
import { getDataStoreSpaces, initialSpacesState } from './spacesHandler';
import { getDataStoreFloors } from './floorsHandler';
import { getDataStoreFacilities } from './facilitiesHandler';
import { getDataStoreSites } from './sitesHandler';
import { getDataStoreRegions } from './regionsHandler';
import { fetchHierarchy, hierarchyExists, updateHierarchy } from 'helpers/hierarchyHelpers';

window.targetFacilities = undefined;
window.activeImageDownloadOperationId = undefined;
window.facets = Facets;

let subscriptionAsset;
let subscriptionSpace;
let subscriptionFloor;
let subscriptionFacility;
let subscriptionSite;
let subscriptionRegion;
let logoutTimer;

const EntityDataContext = React.createContext()

const useEntityData = () => useContext(EntityDataContext)

const EntityDataProvider = ({online, children}) => {
  const [user, setUser] = useState();
  const [facilitiesToDownload, setFacilitiesToDownload] = useState([]);
  const favouriteSpacesState = useState([]);
  const setFavouriteSpaces = favouriteSpacesState[1];
  const [floors, setFloors] = useState({});
  const [facilities, setFacilities] = useState();
  const [sites, setSites] = useState({});
  const [regions, setRegions] = useState({});
  const [assets, setAssets] = useState(initialAssetState);
  const [spaces, setSpaces] = useState(initialSpacesState);
  const [userData, setUserData] = useState();
  const [tenantData, setTenantData] = useState();
  const [allocatedFacilities, setAllocatedFacilities] = useState();
  const copyAssets = useState([]);
  const tableViewState = useState("table");
  const [switchTenantsActive, setSwitchTenantsActive] = useState(false);
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);

  const syncStatusHook = useDataStoreSyncStatus({
    restartDataStore,
    online,
  });

  useEffect(() => {
    onInitialLoad();

    var activityEvents = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
      "click",
      "keyup",
      "input"
    ];
    activityEvents.forEach(function (eventName) {
      window.addEventListener(eventName, resetLogoutTimer);
    });

    const removeListenerDataStore = Hub.listen("datastore", onDataStoreEvent);

    return () => {
      activityEvents.forEach(function (eventName) {
        window.removeEventListener(eventName, resetLogoutTimer);
      });
      removeListenerDataStore();
    };
  }, []);

  function resetLogoutTimer() {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }

    logoutTimer = setTimeout(() => {
      if (window.location.hostname === "localhost") {
        alert("you would be logged out now");
      } else {
        logOut(syncStatusHook.isOnline);
      }
    }, AUTO_LOGOUT_TIMER_MILLISECONDS);
  }

  async function setHierarchy(tenantId){
    const exists = await hierarchyExists(tenantId)
    
    if (!exists) {
      await updateHierarchy(tenantId)
      return;
    }
  
    const hierarchy = await fetchHierarchy()
    window.hierarchy = hierarchy
}

async function fetchFromAPIOrLocalDB(api, body, localDB){
  let data;
  try {
    data = await API.post("rest", api, {
      body,
      response: false,
    });
    await localDbSet(localDB, JSON.stringify(data));
  } catch (e) {
    let userDataString = await localDbGet(localDB);
    if (userDataString) {
      data = JSON.parse(userDataString);
    }
  }
  return data
}

async function calculateFacilitiesToDownloadFromAppSync({allocatedItems, tenantId}) {
  const { sites, regions, facilities } = allocatedItems;

  const facilitiesToDownload =
  (await localDbGet("facilitiesToDownload")) || {};
  
  const isOldFormat = Array.isArray(
    facilitiesToDownload
  );

  /**
   * v1 format stores facilities in a flat array
   */
  if (isOldFormat) {
    const prevActiveFacilities = facilitiesToDownload.map((x) => {
      return { regionId: undefined, siteId: undefined, facilityId: x };
    }).filter((f) =>
      facilities.includes(f.facilityId)
    );

    await localDbSet("facilitiesToDownload", {
      [tenantId]: prevActiveFacilities,
    });
    return prevActiveFacilities.map((f) => f.facilityId);
  } 
  
  /**
   * v2 format to be stored in an object of tenantId and locations in array of regions, sites, facilities
   */
  const previousFacilitiesData = facilitiesToDownload;
  let currentTenantData = facilitiesToDownload[tenantId] || [];

  const removeIncompleteFacility = currentTenantData.filter(
    (x) =>
      x.facilityId && x.siteId !== undefined && x.regionId !== undefined
  );
  if (currentTenantData.length !== removeIncompleteFacility.length) {
    await localDbSet("facilitiesToDownload", {
      ...previousFacilitiesData,
      [tenantId]: removeIncompleteFacility,
    });
  }

  if (
    sites.length !== 0 ||
    regions.length !== 0 ||
    facilities.length !== 0
  ) {
    currentTenantData = currentTenantData.filter(
      (x) =>
        allocatedItems.sites.includes(x.siteId) ||
        allocatedItems.regions.includes(x.regionId) ||
        allocatedItems.facilities.includes(x.facilityId)
    );
  }
  return removeIncompleteFacility.map((f) => f.facilityId);
}

  async function onInitialLoad() {
    let email = Cookie.get("login-email");
    const userSession = await getCurrentSession();

    const userData = await fetchFromAPIOrLocalDB("/items/get-user-data", { email, useDynamoDB: true  }, 'get-user-data')
    const userTenants = await fetchFromAPIOrLocalDB("/items/get-user-tenants", {}, 'get-user-tenants')

    const cookieTenantId = Cookie.get("tenantId") || undefined
    const foundTenant = userTenants.find((tenant) => tenant.tenantId === cookieTenantId)
    const tenantId = foundTenant ? foundTenant.tenantId : userTenants[0].tenantId

    const tenantData = await fetchFromAPIOrLocalDB("/items/get-tenant-data", { tenantId, useDynamoDB: true }, 'get-tenant-data')
    const userAccess = await fetchFromAPIOrLocalDB("/items/get-user-access", { tenantId, email }, 'get-user-access')
  
    await setHierarchy(tenantId);
    window.tenantId = tenantId;

    const allocatedItems = userAccess.locations || { sites: [], regions: [], facilities: [] };
    const filteredFacilityIds = await calculateFacilitiesToDownloadFromAppSync({allocatedItems, tenantId})

    const favouriteSpaces = (await localDbGet("favourite-space")) || [];
    window.targetFacilities = filteredFacilityIds;
    window.activeFacilities = {}
    filteredFacilityIds.forEach(f => {
      deviceImages.enableFacility(f)
    })

    setSwitchTenantsActive(userTenants.length > 1)
    setFacilitiesToDownload(filteredFacilityIds);
    setFavouriteSpaces(favouriteSpaces);
    setAllocatedFacilities(allocatedItems);
    setUser(userSession);
    setTenantData(tenantData);
    setUserData(userData);

    resetLogoutTimer();
  }

  useEffect(() => {
    if (allocatedFacilities || !tenantData) {
      restartDataStore();
    }
  }, [allocatedFacilities, tenantData, facilitiesToDownload]);

  async function restartSubscriptions() {
    subscriptionAsset?.unsubscribe();
    subscriptionSpace?.unsubscribe();
    subscriptionFloor?.unsubscribe();
    subscriptionFacility?.unsubscribe();
    subscriptionSite?.unsubscribe();
    subscriptionRegion?.unsubscribe();

    if (facilitiesToDownload.length) {
      subscriptionAsset = getDataStoreAssets({
        setAssets, 
        tenantId: tenantData.tenantId, 
        facilitiesToDownload,
      })
       
      subscriptionSpace = getDataStoreSpaces({
        setSpaces, 
        tenantId: tenantData.tenantId, 
        facilitiesToDownload  
      })

      subscriptionFloor = getDataStoreFloors({
        setFloors, 
        tenantId: tenantData.tenantId, 
        facilitiesToDownload
      })
    } else if (assets.assetCount > 0) {
      setAssets(initialAssetState)
      setFloors({})
      setSpaces({})
    }

    subscriptionFacility = getDataStoreFacilities({
      setFacilities, 
      tenantId: tenantData.tenantId, 
      allocatedFacilities
    })

    subscriptionSite = getDataStoreSites({
      setSites, 
      tenantId: tenantData.tenantId, 
    })

    subscriptionRegion = getDataStoreRegions({
      setRegions, 
      tenantId: tenantData.tenantId, 
    })
  }

  async function restartDataStore() {
    if (!allocatedFacilities || !tenantData) return;

    await configureDataStore();
    await DataStore.stop();
    restartSubscriptions();
  }

  return (
    <EntityDataContext.Provider
      value={{
        restartDataStore,
        facilitiesToDownload, setFacilitiesToDownload,
        assets,
        spaces,
        facilities,
        sites,
        regions,
        floors,
        setAllocatedFacilities,
        allocatedFacilities,
        syncStatusHook,
        favouriteSpacesState,
        user,
        userData,
        tenantData,
        assetCount: assets.assetCount,
        copyAssets,
        tableViewState,
        switchTenantsActive, 
        setSwitchTenantsActive,
        showSwitchAccount, 
        setShowSwitchAccount
      }}>
      {children}
    </EntityDataContext.Provider>
  )
}

export default EntityDataProvider

export { useEntityData }
