import { useEffect, useState } from "react";
import {
    Table,
    Button,
    Tooltip,
    Typography,
} from "antd";
import { DeleteIcon, EditIcon } from "components/Icons";
import { DEFAULT_NUMBER_OF_ITEMS_IN_VIEW } from "config";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const CopyAssetPane = ({ ASSET_COLUMNS, setCopyAsset, setCopyAssetMessage }) => {
    const {copyAssets, assets: assetData} = useEntityData()

    const [listCopy, setListCopy] = copyAssets
    const [assets, setAssets] = useState([])
    const actions = (asset) => [
        <Tooltip title="Edit" key="edit">
            <Button
                type="clear"
                icon={<EditIcon />}
                onClick={(e) => {
                    e.stopPropagation();
                    setCopyAsset(asset);
                }}
            />
        </Tooltip>,
        <Tooltip title="Cancel" key="cancel">
            <Button
                type="clear"
                onClick={(e) => {
                    if (listCopy.length === 1) setCopyAssetMessage(listCopy[0])
                    setListCopy(listCopy.filter((a) => a !== asset.id))
                }}
                icon={<DeleteIcon />}
            />
        </Tooltip>,
    ];

    const action =
    {
        title: "",
        fixed: "right",
        render: (asset) => {
            return <div className="row-actions">{actions(asset)}</div>;
        },
    }

    const copyAssetsList = async () => {
        if (listCopy.length <= 0) {
            setAssets([])
            return
        }

        const enhancedAssets = listCopy.map((assetId) => {
          const asset = assetData.items[assetId]

            return {
                ...asset,
                name: asset.assetType?.description,
                quantity: asset.facets?.quantity,
                installDate: asset.facets && asset.facets["install-date"],
                manufacturer: asset.facets?.manufacturer,
            }
        })

        setAssets(enhancedAssets)
    }

    useEffect(() => {
        copyAssetsList()
    }, [listCopy])


    const newColumns = [ASSET_COLUMNS[0], ASSET_COLUMNS[1], ASSET_COLUMNS[3], ASSET_COLUMNS[5], action]

    return (<>
        <div className="table-header" style={{ paddingTop: 20 }}>
            {assets.length > 0 && <Typography.Title className="subtitle" level={1}>
                Copy Asset Review
                <Typography.Text className="results">
                    {" "}
                    - {assets.length} remaining to review
                </Typography.Text>
            </Typography.Title>}
        </div>
        {assets.length > 0 && (
            <Table
                scroll={{ x: "max-content" }}
                rowKey="id"
                dataSource={assets}
                showSorterTooltip={false}
                columns={newColumns}
                pagination={{ pageSize: DEFAULT_NUMBER_OF_ITEMS_IN_VIEW, hideOnSinglePage: true }}
                style={{ paddingBottom: 20 }}
            />
        )}</>
    )
}

export default CopyAssetPane