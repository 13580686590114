import { Col } from "antd";
import {
  EllipsisText,
  LocationResult,
  itemDivider,
} from "components/AppHeader/GlobalSearch/globalSearchHelpers";

const SpaceResult = ({ item }) => (
    <>
      <Col span={24}>
        <EllipsisText text={itemDivider(item?.name, item?.localName)} />
      </Col>
      <LocationResult item={item} />
    </>
  )
  
  export default SpaceResult