import React, { useEffect, useState } from "react";
import { Drawer, Button } from "antd";
import SearchableTree from "components/SearchableTree/SearchableTree";

import "./AssetTypeDrawer.scss";
import { FullList, ShortList } from "./AssetTypeMessaging";

export default function AssetTypeDrawer({
  onClose,
  onSubmit,
  visible,
  defaultValue,
  treeData,
  children,
  eightyTwenty,
  ASSET_FAST_MOVERS_FEATURE_FLAG
}) {
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    setSelectedItem(defaultValue);
  }, []);

  function onSearchableTreeChange(selectedTreeNode) {
    const [treeNode]= selectedTreeNode
    const newSelectedItem = {
      ...(selectedItem || {}),
      description: treeNode.title,
      tree: treeNode.tree,
      eliasID: treeNode.eliasID,
      legacyId: treeNode.legacyId,
      virtual: treeNode.virtual,
      nrm: treeNode.nrm,
      sfgCode: treeNode.sfgCode,
      knownAs: treeNode.knownAs,
    };
    setSelectedItem(newSelectedItem);
  }

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      className="asset-type-drawer"
      forceRender
      closable={false}
    >
      {ASSET_FAST_MOVERS_FEATURE_FLAG ? eightyTwenty? <ShortList /> : <FullList /> : null}
      <SearchableTree
        onChange={onSearchableTreeChange}
        value={selectedItem?.description}
        searchType={'field'}
        label={"Select asset type"}
        defaultValue={defaultValue.eliasID}
        treeData={treeData}
        variant={"form"}
        type={'single'}
        searchLabel={"Search asset type..."}
        children={children}
      />
      <div className="form-buttons">
        <Button type="transparent" onClick={onClose}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => onSubmit(selectedItem)}>
          Update
        </Button>
      </div>
    </Drawer>
  );
}
