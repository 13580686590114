import { throttledRefreshImages } from 'helpers/imageHelpers'
import { DataStore } from '@reams/elias-store'
import { Space } from "models"

export const initialSpacesState = {
  items: {},
  groupByFloor: {}
}

const groupByFloor = {}

const handleIncomingSpaces = (incomingSpaces, setSpaces) => {
  const spaceObj = { ...initialSpacesState }

  incomingSpaces.forEach(space => {
    spaceObj.items[space.id] = space

    if (!groupByFloor[space.floorId]) {
      groupByFloor[space.floorId] = {}
    }

    // store as object to handle duplicates
    groupByFloor[space.floorId][space.id] = true
  })

  const floors = Object.keys(groupByFloor)

  // convert to array for less memory use and easier handling in app
  for (let i = 0; i < floors.length; i++) {
    const floorId = floors[i]
    const floorSpaces = groupByFloor[floorId]
    const floorSpacesArray = Object.keys(floorSpaces)
    spaceObj.groupByFloor[floorId] = floorSpacesArray
  }

  setSpaces(spaceObj)
}

export const getDataStoreSpaces = ({setSpaces, tenantId, facilitiesToDownload}) => (
  DataStore.observeQuery(Space, (x) =>
    x.tenantId("eq", tenantId)
    .or(x => facilitiesToDownload
      .reduce((acc, id) => acc.facilityId('eq', id), x)
    ))
  .subscribe(async({items, isSynced}) => {
    if (!items) return

    if (isSynced) {
      throttledRefreshImages(items);
    }
    
    handleIncomingSpaces(items, setSpaces)
  })
)